import React, { createRef, useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { accountAPI, entitiesAPI } from '../../../../api';
import { Entity, UserRole } from '../../../../constants';
import { useUserRole } from '../../../../hooks';
import { isSystemInitialisedSelector } from '../../../../state/ui/selectors';
import { mapOptions } from '../../../../utils/googleMapsHelpers';
import { AuxiliaryPanel } from '../../../common';
import { centerMap } from '../helpers';
import { entityMapFilter } from './helpers';
import styles from './MapMobile.module.scss';

const DATA_CY = 'map-mobile';

const MapMobile = () => {
  const { entity } = useParams();
  const role = useUserRole();
  const mapRef = createRef();
  const [map, setMap] = useState(null);
  const isSystemInitialised = useSelector(isSystemInitialisedSelector);

  const { data } = useQuery(
    ['audits'],
    () => entitiesAPI.fetchAll(Entity.AUDITS),
  );

  const { data: fetchedAuditor } = useQuery(
    ['myAuditorAccount'],
    accountAPI.fetchAuditorAccount,
    { enabled: role === UserRole.AUDITOR },
  );

  const initMap = useCallback(() => {
    if (mapRef.current && !map) {
      const googleMap = new window.google.maps.Map(mapRef.current, mapOptions({ maxZoom: 15 }));
      setMap(googleMap);
    }
  }, [map, mapRef]);

  useEffect(() => {
    if (map && data) {
      entityMapFilter({ entity, map, data, fetchedAuditor });
      centerMap({ map, data });
    }
  }, [map, data, fetchedAuditor, entity]);

  useEffect(() => {
    if (isSystemInitialised) {
      initMap();
    }
  }, [isSystemInitialised, initMap]);

  return (
    <AuxiliaryPanel
      condensed
      content={
        <div className={styles.mapPanel}>
          <div ref={mapRef} className={styles.map} />
        </div>
      }
      dataCy={DATA_CY}
      header={(
        <span>
          {'Audit\'s Map'}
        </span>
      )}
      showCollapse={false}
    />
  );
};

export default MapMobile;

import PropTypes from 'prop-types';
import React from 'react';

import styles from './FormFieldHelp.module.scss';

const FormFieldHelp = ({ dataCy, help }) => help && (
  <div
    className={styles.help}
    data-cy={`${dataCy}-help`}
  >
    {help}
  </div>
);

FormFieldHelp.defaultProps = {
  dataCy: 'form-field',
  help: null,
};

FormFieldHelp.propTypes = {
  dataCy: PropTypes.string,
  help: PropTypes.string,
};

export default FormFieldHelp;

import { useFeatureFlag } from 'configcat-react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { Divider } from 'semantic-ui-react';

import { auditorsAPI, legalAPI } from '../../../../../api';
import getFieldAuditorStatus from '../../../../../fieldLogic/auditor';
import { enumOptionsSelector } from '../../../../../state/constants/selectors';
import checkGender from '../../../../../utils/filterOutGender';
import {
  FormFieldCheckboxWrapper,
  FormFieldDateTimeWrapper,
  FormFieldImageUploadWrapper,
  FormFieldSelectWrapper,
  FormFieldWrapper,
} from '../../../../common';

const auditorStatusOptionsSelector = state => enumOptionsSelector(state, 'auditor_status_type');
const genderOptionsSelector = state => enumOptionsSelector(state, 'gender_type');

const EditAuditorProfileTab = ({ role, mode }) => {
  const { values, setFieldValue } = useFormikContext();

  const auditorStatusOptions = useSelector(auditorStatusOptionsSelector);
  const genderOptions = useSelector(genderOptionsSelector).filter(item => checkGender(item.value));
  const { value: auditorApplicationUpgrade } = useFeatureFlag('auditorApplicationUpgrade', false);

  const { mutate: updateNotifications } = useMutation(auditorsAPI.enableNotifications);
  const { mutate: updateFacialImagesConsent } = useMutation(legalAPI.updateFacialImagesConsent);
  const { mutate: updateSpecialCategoryConsent } = useMutation(legalAPI.updateSpecialCategoryConsent);
  const { mutate: updateCommunicationsConsent } = useMutation(legalAPI.updateCommunicationsConsent);

  const handleNotificationToggle = async checked => {
    setFieldValue('enable_notifications', checked);
    updateNotifications(checked);
  };

  const handleFacialImagesToggle = async checked => {
    setFieldValue('facial_images_consent', checked);
    updateFacialImagesConsent(checked);
  };

  const handleSpecialCategoryToggle = async checked => {
    setFieldValue('special_category_consent', checked);
    updateSpecialCategoryConsent(checked);
  };

  const handleCommunicationsToggle = async checked => {
    setFieldValue('communications_consent', checked);
    updateCommunicationsConsent(checked);
  };

  return (
    <>
      <FormFieldWrapper
        inline
        label="First name"
        name="first_name"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />
      <FormFieldWrapper
        inline
        label="Middle Name"
        name="middle_name"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />
      <FormFieldWrapper
        inline
        label="Last Name"
        name="last_name"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />

      <Divider />

      <FormFieldDateTimeWrapper
        inline
        label="Date of Birth"
        name="date_of_birth"
        time={false}
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />
      <FormFieldSelectWrapper
        inline
        label="Gender"
        name="gender"
        options={genderOptions}
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />

      <Divider />

      <FormFieldWrapper
        inline
        label="E-mail Address"
        name="email"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />
      <FormFieldWrapper
        inline
        label="Mobile number"
        name="mobile_number"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />
      {!auditorApplicationUpgrade && (<>
        <FormFieldWrapper
          inline
          label="Home number"
          name="home_number"
          onStateHandler={getFieldAuditorStatus(role, mode)}
        />
      </>)}

      <Divider />

      <FormFieldSelectWrapper
        inline
        label="Status"
        name="auditor_status"
        options={auditorStatusOptions}
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />

      <Divider />

      <FormFieldImageUploadWrapper
        inline
        help="Files must be less than 100 MB. Allowed file types: .jpg, .png, .gif"
        label="Recent Photograph (please take a selfie)"
        name="image"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />
      <FormFieldImageUploadWrapper
        inline
        help="Files must be less than 100 MB. Allowed file types: .jpg, .png, .gif"
        label="Scanned Copy of Passport"
        name="passport"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />
      <FormFieldImageUploadWrapper
        inline
        help="Files must be less than 100 MB. Allowed file types: .jpg, .png, .gif"
        label="Scanned Copy of ID"
        name="identification"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />

      {(['GB', 'IE'].includes(values?.nationality) ? null : (
        <FormFieldImageUploadWrapper
          inline
          help="Files must be less than 100 MB. Allowed file types: .jpg, .png, .gif"
          label="Scanned Copy of Visa"
          name="visa"
          onStateHandler={getFieldAuditorStatus(role, mode)}
        />
      ))}

      <Divider />

      <FormFieldCheckboxWrapper
        inline
        toggle
        label="Enable Notifications"
        name="enable_notifications"
        onChange={(_, { checked }) => handleNotificationToggle(checked)}
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />

      <>
        <Divider />

        <FormFieldWrapper
          inline
          readOnly
          label="GDPR"
          name="gdpr"
        />

        <FormFieldCheckboxWrapper
          inline
          toggle
          help="I consent to Serve Legal using my facial images (and meta data) and other personal data and sharing such data with their clients for the purpose of facial recognition verification and in relation to the services Serve Legal provides."
          label=""
          name="facial_images_consent"
          onChange={(_, { checked }) => handleFacialImagesToggle(checked)}
          onStateHandler={getFieldAuditorStatus(role, mode)}
        />

        <FormFieldCheckboxWrapper
          inline
          toggle
          help="I consent to Serve Legal using my special category data such as my ethnicity and occupational health information and sharing such data with their clients for the purpose of facial recognition verification and in relation to the services Serve Legal provides."
          label=""
          name="special_category_consent"
          onChange={(_, { checked }) => handleSpecialCategoryToggle(checked)}
          onStateHandler={getFieldAuditorStatus(role, mode)}
        />

        <FormFieldCheckboxWrapper
          inline
          toggle
          help="Please check this box to confirm that you consent to receiving communications from us regarding your Serve Legal employment, job opportunities, and marketing initiatives."
          label=""
          name="communications_consent"
          onChange={(_, { checked }) => handleCommunicationsToggle(checked)}
          onStateHandler={getFieldAuditorStatus(role, mode)}
        />
      </>
    </>
  );
};

EditAuditorProfileTab.propTypes = {
  mode: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
};

export default EditAuditorProfileTab;

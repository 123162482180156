export const SET_TAXONOMY = 'SET_TAXONOMY';
export const FETCH_TAXONOMY = 'FETCH_TAXONOMY'; // saga
export const UPDATE_TAXONOMY = 'UPDATE_TAXONOMY'; // saga

export const FETCH_ENUMS = 'FETCH_ENUMS'; // saga
export const SET_ENUMS = 'SET_ENUMS';

export const FETCH_USER_INFO = 'FETCH_USER_INFO';
export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_USER_ROLE = 'SET_USER_ROLE';
export const SET_USER_PROMPTS = 'SET_USER_PROMPTS';
export const CLEAR_AUTH = 'CLEAR_AUTH';

import { FieldArray, useFormikContext } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Segment } from 'semantic-ui-react';

import getFieldStatus from '../../../../../fieldLogic/audit';
import FieldStatus from '../../../../../fieldLogic/fieldStatus';
import { useUserRole } from '../../../../../hooks';
import { enumOptionsSelector } from '../../../../../state/constants/selectors';
import {
  FormFieldSelectWrapper,
  FormFieldWrapper,
} from '../../../../common';
import { uuidType } from '../../../../types';

const reportTypeSelector = state => enumOptionsSelector(state, 'report_type');
const defaultSurveyResultTypeSelector = state => enumOptionsSelector(state, 'report_trigger_type');

const AuditDetailsReports = ({ revisionId }) => {
  const { values } = useFormikContext();
  const reportTypeOptions = useSelector(reportTypeSelector);
  const defaultSurveyResultOptions = useSelector(defaultSurveyResultTypeSelector);

  const role = useUserRole();

  const reportsStatus = getFieldStatus(role, values.status, revisionId)('email_report_distribution_list');

  return reportsStatus === FieldStatus.HIDDEN ? null : (
    <FieldArray name="email_report_distribution_list">
      {({ push, remove }) => (
        <>
          {
            values.email_report_distribution_list.map((_item, index) => (
              <Segment key={index + 1}>
                <FormFieldSelectWrapper
                  display={reportsStatus === FieldStatus.READONLY}
                  label="Survey Result"
                  name={`email_report_distribution_list.${index}.survey_result`}
                  options={defaultSurveyResultOptions}
                />
                <FormFieldSelectWrapper
                  display={reportsStatus === FieldStatus.READONLY}
                  label="Report Type"
                  name={`email_report_distribution_list.${index}.report_type`}
                  options={reportTypeOptions}
                />
                <FormFieldWrapper
                  display={reportsStatus === FieldStatus.READONLY}
                  label="Emails"
                  name={`email_report_distribution_list.${index}.emails`}
                />
                {reportsStatus === FieldStatus.EDITABLE ? (
                  <Button onClick={() => remove(index)}>
                    {'Delete'}
                  </Button>
                ) : null}
              </Segment>
            ))}
          {reportsStatus === FieldStatus.EDITABLE && values.email_report_distribution_list.length < 6 ? (
            <Button onClick={() => push({ survey_result: '', report_type: '', emails: '' })}>
              {'Add new'}
            </Button>
          ) : null}
        </>
      )}
    </FieldArray>
  );
};

AuditDetailsReports.defaultProps = {
  revisionId: null,
};

AuditDetailsReports.propTypes = {
  revisionId: uuidType,
};

export default AuditDetailsReports;

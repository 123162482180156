import { StatusCodes } from 'http-status-codes';

import { appConfig } from '../config';
import axios from './axiosInstance';
import { getAuthorization, handlingResponse, logError } from './utils';

export const fetchUserPrompts = () => axios
  .get(`${appConfig.backendUrl}/api/v1/prompts/me`, { headers: { Authorization: getAuthorization() } })
  .then(handlingResponse([StatusCodes.OK], 'Error fetching prompts'))
  .catch(logError);

export const storePromptRead = promptId => axios.post(
  `${appConfig.backendUrl}/api/v1/users/prompts/${promptId}`,
  null,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], 'Error storing prompt as read'))
  .catch(logError);

import classnames from 'classnames';
import { Formik } from 'formik';
import localforage from 'localforage';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Confirm, Form, Icon } from 'semantic-ui-react';

import { AuditingSteps, QuestionType } from '../../../../constants';
import {
  updateAuditAnswers,
  updateAuditCurrentQuestionId,
  updateAuditStep,
} from '../../../../state/audits/actionCreators';
import {
  auditAnswersSelector,
  auditCurrentQuestionIdSelector,
} from '../../../../state/audits/selectors';
import AuditingQuestion from '../AuditingQuestions/AuditingQuestion.component';
import styles from './AuditingQuestionnaire.module.scss';
import { answerValidationMessage, defaultAnswer, submitAnswer } from './helpers';

const DATA_CY = 'auditing-questionnaire';

const AuditingQuestionnaire = ({ next, data }) => {
  const dispatch = useDispatch();
  const [validationModalMessage, setValidationModalMessage] = useState(null);

  const answersSelector = state => auditAnswersSelector(state, data.id);
  const auditAnswers = useSelector(answersSelector);

  const questionIdSelector = state => auditCurrentQuestionIdSelector(state, data.id);
  const auditCurrentQuestionId = useSelector(questionIdSelector);

  useEffect(() => {
    if (auditCurrentQuestionId === null) {
      next();
    }
  }, [auditCurrentQuestionId, next]);

  const nextQuestion = useCallback(async values => {
    let validationMessage = null;
    if (values.validation_confirmed === false) {
      validationMessage = answerValidationMessage({
        answers: auditAnswers,
        audit: data,
        currentAnswerValue: values.answer,
        currentQuestion: values.question,
      });
    }

    if (validationMessage) {
      setValidationModalMessage(validationMessage);
    } else {
      // First calculate amended answers
      const amendedAnswers = submitAnswer({
        answers: auditAnswers,
        audit: data,
        currentAnswerValue: values.answer,
        currentQuestion: values.question,
      });

      // Save / replace images for image type questions
      if (values.answer_files !== null) {
        const imagesLocalforageId = `${data.id}:${values.question.id}`;
        const answerIndex = amendedAnswers.findIndex(value => value.id === values.question.id);
        let updatedValue;

        if (!values.answer_files.length) {
          await localforage.removeItem(imagesLocalforageId);
          updatedValue = null;
        } else {
          await localforage.setItem(imagesLocalforageId, values.answer_files);
          updatedValue = imagesLocalforageId;
        }

        if (answerIndex !== -1) {
          amendedAnswers.splice(answerIndex, 1, {
            ...amendedAnswers[answerIndex],
            value: updatedValue,
          });
        }
      }

      dispatch(updateAuditAnswers({
        id: data.id,
        answers: amendedAnswers,
      }));

      if (values.amend) {
        // If the user wants to amend, go to the review step
        dispatch(updateAuditStep({
          id: data.id,
          step: AuditingSteps.REVIEW,
        }));
      } else {
      // If the user wants to go to the next question, check which one should be the next
        const questionIndex = data.revision_questions.findIndex(
          question => question.id === values.question.id,
        );

        const nextQuestions = data.revision_questions.slice(questionIndex + 1);
        const foundNextQuestion = nextQuestions.find(question => {
          const foundAnswer = amendedAnswers.find(answer => answer.id === question.id);
          return foundAnswer?.hidden === false;
        });

        dispatch(updateAuditCurrentQuestionId({
          id: data.id,
          currentQuestionId: foundNextQuestion !== undefined ? foundNextQuestion.id : null,
        }));
      }
    }
  }, [auditAnswers, data, dispatch]);

  const previousQuestion = useCallback(() => {
    const questionIndex = data.revision_questions.findIndex(
      question => question.id === auditCurrentQuestionId,
    );

    const previousQuestionsReversed = data.revision_questions.slice(0, questionIndex).reverse();
    const foundPreviousQuestion = previousQuestionsReversed.find(question => {
      const foundAnswer = auditAnswers.find(answer => answer.id === question.id);
      return foundAnswer?.hidden === false;
    });

    dispatch(updateAuditCurrentQuestionId({
      id: data.id,
      currentQuestionId: foundPreviousQuestion.id,
    }));
  }, [auditCurrentQuestionId, auditAnswers, dispatch, data]);

  if (auditCurrentQuestionId === null) {
    return null;
  }

  const currentQuestionIndex = data.revision_questions.findIndex(q => q.id === auditCurrentQuestionId);
  const currentQuestion = data.revision_questions[currentQuestionIndex];
  const currentAnswerValue = auditAnswers.find(
    a => a.id === auditCurrentQuestionId,
  )?.value;

  let currentAnswerOriginalURLs = null;
  if (currentQuestion.type === QuestionType.IMAGE) {
    currentAnswerOriginalURLs = data.survey_answers_urls?.find(item => item.id === currentQuestion.id)?.urls || null;
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        question: currentQuestion,
        answer: currentAnswerValue || (currentQuestion && defaultAnswer(currentQuestion)),
        answer_original_urls: currentAnswerOriginalURLs,
        validation_confirmed: false,
        answer_files: null,
        amend: false,
      }}
      onSubmit={values => nextQuestion(values)}
    >
      {({ handleSubmit, setFieldValue }) => {
        const handleAmend = () => {
          setFieldValue('amend', true);
          handleSubmit();
        };

        return (
          <>
            <Form noValidate className={styles.form}>
              <button
                className={classnames(
                  styles.back,
                  { [styles.hidden]: (currentQuestionIndex === 0) },
                )
                }
                data-cy={`${DATA_CY}-back`}
                type="button"
                onClick={previousQuestion}
              />

              <div className={styles.innerForm}>
                <p className={styles.activePageNumber}>
                  {`${currentQuestionIndex + 1} of ${data.revision_questions.length}`}
                </p>

                <AuditingQuestion />

                <div className={styles.buttons}>
                  <Button
                    icon
                    secondary
                    data-cy={`${DATA_CY}-amend-button`}
                    labelPosition="right"
                    onClick={handleAmend}
                  >
                    {'View all questions'}
                    <Icon name="check" />
                  </Button>

                  <Button
                    icon
                    primary
                    data-cy={`${DATA_CY}-ok-button`}
                    labelPosition="right"
                    onClick={handleSubmit}
                  >
                    {'OK'}
                    <Icon name="arrow right" />
                  </Button>
                </div>
              </div>

              <button
                className={classnames(
                  styles.next,
                  { [styles.hidden]: currentQuestionIndex === data.revision_questions.length - 1 },
                )}
                data-cy={`${DATA_CY}-next`}
                type={'button'}
                onClick={handleSubmit}
              />
            </Form>

            <Confirm
              content={validationModalMessage}
              open={validationModalMessage !== null}
              onCancel={() => {
                setFieldValue('answer', null);
                setValidationModalMessage(null);
              }}
              onConfirm={() => {
                setFieldValue('validation_confirmed', true);
                setValidationModalMessage(null);
                handleSubmit();
              }}
            />
          </>
        );
      }}
    </Formik>
  );
};

AuditingQuestionnaire.propTypes = {
  data: PropTypes.object.isRequired,
  next: PropTypes.func.isRequired,
};

export default AuditingQuestionnaire;

import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from 'semantic-ui-react';

import styles from './ExternalLink.module.scss';

const ExternalLink = ({ href, icon, label }) => (
  <a
    href={href}
    rel="noopener noreferrer"
    target="_blank"
  >
    {label}
    <Icon
      className={styles.icon}
      name={icon}
    />
  </a>
);

ExternalLink.defaultProps = {
  icon: 'external',
};

ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

export default ExternalLink;

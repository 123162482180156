import { handleActions } from 'redux-actions';

import * as actions from './actionCreators';
import initialState from './initialState';

export default {
  device: handleActions(
    {
      [actions.storeDeviceToken]: (state, action) => ({
        ...state,
        token: action.payload,
      }),
    },
    initialState.device,
  ),
};

import { Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import { useMutation, useQuery } from 'react-query';
import { Button, Divider, Form, Header, Icon, Input, Menu, Modal } from 'semantic-ui-react';
import * as Yup from 'yup';

import { taxonomyAPI } from '../../../../../api';
import { DocumentsConst, UserRole } from '../../../../../constants';
import { useUserRole } from '../../../../../hooks';
import buildDocumentsTree from '../../../../../utils/buildDocumentsTree';
import { FormFieldFileWrapper, FormFieldWrapper } from '../../../../common';
import DropDownTable from '../../../../common/DropDownTable/DropDownTable.component';
import GenericModal from '../../../../common/GernericModal/GenericModal.component';
import WaitPlaceholder from '../../../../common/WaitPlaceholder/WaitPlaceholder.component';
import styles from './TaxonomyDetailsDocuments.module.scss';

const DATA_CY = 'taxonomy-documents';

const TaxonomyDetailsDocuments = () => {
  const role = useUserRole();
  const alert = useAlert();

  const [documentsTree, setDocumentsTree] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [folderSelected, setFolderSelected] = useState(null);
  const [loadedLink, setLoadedLink] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [documentNameFilter, setDocumentNameFilter] = useState('');
  const [actionRequired, setActionRequired] = useState({
    doc: null,
    actionRequired: null,
    confirmationMessage: '',
  });

  const loadedLinkRef = useRef(null);

  const canEdit = (role === UserRole.DATA || role === UserRole.CLIENT_SERVICES);

  const getDocumentById = ({ id, docsArr }) => {
    const output = [];
    docsArr.forEach(d => {
      if (d.id === id) {
        output.push(d);
      }
      if (d.subRows) {
        d.subRows.forEach(sr => {
          if (sr.id === id) {
            output.push(sr);
          }
        });
      }
    });
    return output[0];
  };

  const fileNameValidation = name => {
    if (!name || name.trim().length === 0) return false;

    let subTree = documentsTree;
    if (folderSelected) {
      const folder = documentsTree.find(d => d.text === folderSelected);
      subTree = folder?.subRows || documentsTree;
    }

    return subTree.find(item => item.text.toLowerCase() === name.toLowerCase()) === undefined;
  };

  const openModalForFolder = (folder = null) => {
    setFolderSelected(folder?.name || null);
    setModalOpen(true);
  };

  const existInFolder = ({ folder, name }) => folder.subRows.filter(sr => sr.text === name).length;

  const startRemovingFile = doc => {
    setActionRequired({
      doc,
      action: DocumentsConst.Action.REMOVE,
      confirmationMessage: `Are you sure you want to ${DocumentsConst.Action.REMOVE} this file?`,
    });
  };

  const startRenamingFile = doc => {
    setActionRequired({
      doc,
      action: DocumentsConst.Action.RENAME,
      confirmationMessage: `Are you sure you want to ${DocumentsConst.Action.RENAME} this file?`,
    });
  };

  const startReplacingFile = doc => {
    setActionRequired({
      doc,
      action: DocumentsConst.Action.REPLACE,
      confirmationMessage: `Are you sure you want to ${DocumentsConst.Action.REPLACE} this file?`,
    });
  };

  const resetAction = () => {
    setActionRequired({
      doc: null,
      action: null,
      confirmationMessage: '',
    });
  };

  const toggleFolderExpanded = doc => {
    const newTree = documentsTree.map(item => (item.id === doc.id ? { ...item, expanded: !item.expanded } : item));
    setDocumentsTree(newTree);
  };

  const {
    data,
    isLoading: isFetchLoading,
    refetch,
  } = useQuery(
    'get-documents',
    () => taxonomyAPI.fetchAllDocuments(),
  );

  useEffect(() => {
    if (data) {
      const tree = buildDocumentsTree({
        docs: data.items,
        docNameFilter: documentNameFilter,
      });

      setDocumentsTree(oldTree => tree.map(item => {
        if (item.subRows) {
          const oldItem = oldTree?.find(d => d.id === item.id);
          return ({
            ...item,
            expanded: oldItem ? oldItem.expanded : true,
          });
        }

        return item;
      }));
    }
  }, [data, documentNameFilter]);

  const {
    mutate: getDetails,
    isLoading: isGetDetailsLoading,
  } = useMutation(
    taxonomyAPI.getDocumentDetails,
    {
      onSuccess: ({ url }) => {
        if (window.navigator.userAgent.match(/iPad|iPhone|iPod/)) {
          setLoadedLink((
            <a ref={loadedLinkRef} className={styles.linkButton} href={url} rel="noreferrer" target="_blank">
            CLICK HERE TO OPEN
            </a>
          ));
          loadedLinkRef.current.scrollIntoView();
        } else {
          window.open(url);
        }
      },
      onError: error => {
        alert.error(`Error getting document details: ${error.message}`);
      },
    },
  );

  const {
    mutate: uploadDocument,
    isLoading: isUploadDocumentLoading,
  } = useMutation(
    taxonomyAPI.uploadDocument,
    {
      onSuccess: () => {
        alert.success('Document uploaded!');
        refetch();
      },
      onError: error => {
        alert.error(`Error uploading document: ${error.message}`);
      },
    },
  );

  const {
    mutate: removeDocument,
    isLoading: isRemoveDocumentLoading,
  } = useMutation(
    taxonomyAPI.removeDocument,
    {
      onSuccess: () => {
        alert.success('Document removed!');
        refetch();
      },
      onError: error => {
        alert.error(`Error removing document: ${error.message}`);
      },
      onSettled: () => resetAction(),
    },
  );

  const {
    mutate: renameDocument,
    isLoading: isRenameDocumentLoading,
  } = useMutation(
    taxonomyAPI.renameDocument,
    {
      onSuccess: () => {
        alert.success('Document renamed!');
        refetch();
      },
      onError: error => {
        alert.error(`Error renaming document: ${error.message}`);
      },
      onSettled: () => resetAction(),
    },
  );

  const {
    mutate: replaceDocument,
    isLoading: isReplaceDocumentLoading,
  } = useMutation(
    taxonomyAPI.replaceDocument,
    {
      onSuccess: () => {
        alert.success('Document replaced!');
        refetch();
      },
      onError: error => {
        alert.error(`Error replacing document: ${error.message}`);
      },
      onSettled: () => resetAction(),
    },
  );

  useEffect(() => {
    setLoading(
      isFetchLoading
      || isGetDetailsLoading
      || isUploadDocumentLoading
      || isRemoveDocumentLoading
      || isRenameDocumentLoading
      || isReplaceDocumentLoading,
    );
  }, [isFetchLoading,
    isGetDetailsLoading,
    isUploadDocumentLoading,
    isRemoveDocumentLoading,
    isRenameDocumentLoading,
    isReplaceDocumentLoading]);

  const dragEnd = ({ destination, source }) => {
    if (!canEdit) {
      alert.error('You don\'t have permissions to move documents.');
      return;
    }
    if (!destination) {
      return;
    }
    const targetFolder = getDocumentById({ id: destination?.droppableId, docsArr: documentsTree });
    const sourceName = getDocumentById({ id: source?.droppableId, docsArr: documentsTree })?.text;
    if (existInFolder({ folder: targetFolder, name: sourceName })) {
      alert.error('This file name already exist in the target folder');
      return;
    }
    const targetFolderName = targetFolder?.text;
    renameDocument({
      documentId: source.droppableId,
      payload: {
        name: `${targetFolderName ? `${targetFolderName}/` : ''}${sourceName}`,
      },
    });
  };

  const handleListItemClick = item => {
    if (item.subRows) {
      toggleFolderExpanded(item);
    } else {
      getDetails(item.id);
    }
  };

  return (
    <>
      {isLoading && <WaitPlaceholder />}
      {canEdit && (
        <>
          <Menu secondary className={styles.menuContainer}>
            <Menu.Item>
              <Header as="h4">
                {'Documents'}
              </Header>
            </Menu.Item>
            <Menu.Menu position="right">
              <Menu.Item>
                <Button secondary data-cy="add-doc-item-list" onClick={() => openModalForFolder()}>
                  <Icon name="add" />
                  {'Add new Document'}
                </Button>
              </Menu.Item>
            </Menu.Menu>
          </Menu>
          <Divider />
        </>
      )}
      {!canEdit && (
        <div className={styles.containerSearch}>
          <Input
            fluid
            defaultValue={documentNameFilter}
            icon="search"
            iconPosition="left"
            placeholder="Search"
            type="text"
            onChange={e => setDocumentNameFilter(e.target.value)}
          />
        </div>
      )}

      {loadedLink || null}

      { documentsTree
      && <DropDownTable
        canEdit={canEdit}
        dataCy={DATA_CY}
        dragEnd={dragEnd}
        handleListItemClick={handleListItemClick}
        itemsTree={documentsTree}
        openModalForFolder={openModalForFolder}
        startRemovingItem={startRemovingFile}
        startRenamingItem={startRenamingFile}
        startReplacingItem={startReplacingFile}
      // eslint-disable-next-line react/jsx-closing-bracket-location
      />
      }

      <Modal open={isModalOpen}>
        <Formik
          enableReinitialize
          initialValues={{
            folder: folderSelected,
          }}
          validationSchema={Yup.object({
            text: Yup.mixed().test(
              'test',
              'Required and unique',
              value => fileNameValidation(value),
            ),
            file: Yup.mixed().test('file', 'Required', value => value?.name?.length > 0),
          })}
          onSubmit={values => {
            setModalOpen(false);
            const formData = new FormData();
            formData.append('name', `${values.folder ? `${values.folder}/` : ''}${values.text}`);
            formData.append('file', values.file);
            uploadDocument({
              payload: formData,
            });
            setFolderSelected(null);
          }}
        >
          {({ handleSubmit }) => (
            <>
              <Modal.Header>
                {'Upload new document'}
              </Modal.Header>
              <Modal.Content>
                <Form>
                  <FormFieldWrapper
                    required
                    label="Text"
                    name="text"
                  />
                  <FormFieldWrapper
                    label={`Folder${!folderSelected ? ' (optional)' : ''}`}
                    name="folder"
                    readOnly={Boolean(folderSelected)}
                  />
                  <FormFieldFileWrapper
                    required
                    label="File"
                    name="file"
                  />
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={() => {
                  setFolderSelected(null);
                  setModalOpen(false);
                }}
                >
                  {'Cancel'}
                </Button>
                <Button primary type="submit" onClick={handleSubmit}>
                  {'Upload'}
                </Button>
              </Modal.Actions>
            </>
          )}
        </Formik>
      </Modal>

      <GenericModal
        buttons={[
          { label: 'Cancel', func: resetAction },
          { label: 'Accept', func: () => removeDocument(actionRequired.doc.id) },
        ]}
        open={actionRequired.action === DocumentsConst.Action.REMOVE}
        onDismiss={resetAction}
      >
        <h4>
          {actionRequired.confirmationMessage}
        </h4>
      </GenericModal>

      <Modal open={actionRequired.action === DocumentsConst.Action.RENAME}>
        <Formik
          initialValues={{
            fileName: actionRequired?.doc?.text,
          }}
          validationSchema={Yup.object({
            fileName: Yup.mixed().test(
              'fileName',
              'Required and not the same',
              value => value && value.trim().length > 0 && value !== actionRequired.doc.text,
            ),
          })}
          onSubmit={({ fileName }) => {
            const { folder: parentFolder } = actionRequired.doc;
            renameDocument({
              documentId: actionRequired.doc.id,
              payload: {
                name: `${parentFolder ? `${parentFolder}/${fileName}` : fileName}`,
              },
            });
          }}
        >
          {({ handleSubmit }) => (
            <>
              <Modal.Header>
                {actionRequired.confirmationMessage}
              </Modal.Header>
              <Modal.Content>
                <Form>
                  <FormFieldWrapper
                    required
                    label="New name"
                    name="fileName"
                  />
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={() => resetAction()}>
                  {'Cancel'}
                </Button>
                <Button primary type="submit" onClick={handleSubmit}>
                  {'Accept'}
                </Button>
              </Modal.Actions>
            </>
          )}
        </Formik>
      </Modal>

      <Modal open={actionRequired.action === DocumentsConst.Action.REPLACE}>
        <Formik
          enableReinitialize
          initialValues={{
            file: null,
          }}
          validationSchema={Yup.object({
            file: Yup.mixed().test('file', 'Required', value => value?.name?.length > 0),
          })}
          onSubmit={values => {
            const formData = new FormData();
            formData.append('file', values.file);
            replaceDocument({
              documentId: actionRequired.doc.id,
              payload: formData,
            });
          }}
        >
          {({ handleSubmit }) => (
            <>
              <Modal.Header>
                {'Replace document'}
              </Modal.Header>
              <Modal.Content>
                <Form>
                  <FormFieldFileWrapper
                    required
                    label="File"
                    name="file"
                  />
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={resetAction}>
                  {'Cancel'}
                </Button>
                <Button primary type="submit" onClick={handleSubmit}>
                  {'Upload'}
                </Button>
              </Modal.Actions>
            </>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default TaxonomyDetailsDocuments;

import { Formik } from 'formik';
import _debounce from 'lodash/debounce';
import _pickBy from 'lodash/pickBy';
import PropTypes from 'prop-types';
import React from 'react';
import { useQuery } from 'react-query';
import { Form } from 'semantic-ui-react';

import { archivesAPI } from '../../../../api';
import { DEBOUNCE_MS, Entity } from '../../../../constants';
import { FormFieldSelectWrapper } from '../../../common';
import { archivesFilterDefaultValues, archivesFilterValidationSchema, daysOption, monthsOption, yearsOption } from './helpers';

const DATA_CY = 'archives-table-filter';

const ArchivesTableFilter = ({ onChange }) => {
  const { data } = useQuery(
    [Entity.ARCHIVES],
    () => archivesAPI.sitesArchived(),
  );

  const siteNameMap = () => (data?.items ? data.items.map(item => (
    {
      text: item.site_name,
      value: item.site_id,
    }
  )) : []);
  const siteNameOptions = ([
    { value: '', text: 'No value' },
    ...siteNameMap(),
  ]);

  return (
    <Formik
      initialValues={archivesFilterDefaultValues}
      validationSchema={archivesFilterValidationSchema}
      onSubmit={values => onChange(_pickBy(values, value => value !== null && value !== undefined))}
    >
      {({ handleSubmit, values }) => {
        const debounceSubmit = _debounce(handleSubmit, DEBOUNCE_MS);

        const handleFieldChange = () => {
          debounceSubmit();
        };

        return (
          <Form inverted data-cy={DATA_CY}>
            <Form.Group widths="equal">
              <FormFieldSelectWrapper
                label="Site Name"
                name="siteId"
                options={siteNameOptions}
                onChange={handleFieldChange}
              />
              <FormFieldSelectWrapper
                disabled={!values.siteId}
                label="Year"
                name="year"
                options={yearsOption}
                onChange={handleFieldChange}
              />
              <FormFieldSelectWrapper
                disabled={!values.year}
                label="Month"
                name="month"
                options={monthsOption}
                onChange={handleFieldChange}
              />
              <FormFieldSelectWrapper
                disabled={!values.month}
                label="Day"
                name="day"
                options={daysOption}
                onChange={handleFieldChange}
              />
            </Form.Group>
          </Form>
        );
      }}
    </Formik>
  );
};

ArchivesTableFilter.defaultProps = {
  values: archivesFilterDefaultValues,
};

ArchivesTableFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    site_name: PropTypes.string,
  }),
};

export default ArchivesTableFilter;

import download from 'downloadjs';
// import PropTypes from 'prop-types';
import React from 'react';
import { useAlert } from 'react-alert';
import { Button, Card } from 'semantic-ui-react';

import { errorAPI, taxonomyAPI } from '../../../../api';
import { UserRole } from '../../../../constants';
import { RoleChecker } from '../../../layout';
import TipsAndDocsNewsfeed from '../TipsAndDocsNewsfeed/TipsAndDocsNewsfeed.component';
import styles from './TipsAndDocsSidebar.module.scss';

const DATA_CY = 'tips-and-docs-sidebar';

const TipsAndDocsSidebar = () => {
  const alert = useAlert();

  const downloadBulkOfDocs = async () => {
    taxonomyAPI.downloadAllDocs()
      .then(data => {
        const reader = new FileReader();
        reader.readAsBinaryString(data);
        reader.onload = () => {
          download(reader.result, 'documents.zip');
        };
      })
      .catch(error => {
        alert.error(`Error downloading documents: ${error?.message}`);
        errorAPI.sendError(error?.message, '');
      });
  };

  return (
    <>
      <RoleChecker allowedRoles={[UserRole.AREA_MANAGER]}>
        <Card
          fluid
          className={styles.downloadContainer}
          data-cy={`${DATA_CY}-download-card`}
        >
          <Card.Content>
            <h3>
              {'Download Audit Briefings'}
            </h3>
            <p>
              {'You can download a list of audits divided into themes and various places. They will be very useful to get an idea of ​​what we ask our audits.'}
            </p>
            <div className={styles.buttonContainer}>
              <Button
                className={styles.buttonDownload}
                data-cy={`${DATA_CY}-download-button`}
                onClick={downloadBulkOfDocs}
              >
                <span className={styles.iconDownload}/>
                {'Download All Briefings'}
              </Button>
            </div>
          </Card.Content>
        </Card>
      </RoleChecker>
      <RoleChecker
        allowedRoles={[UserRole.AUDITOR]}
      >
        <TipsAndDocsNewsfeed />
      </RoleChecker>
    </>
  );
};

export default TipsAndDocsSidebar;

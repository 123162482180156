import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { SLTable } from '../../../common';

const formattedCell = ({ cell }) => {
  const value = {
    vocabulary: (
      <>
        <Link data-cy={`taxonomy-table-link-${cell.value}`} to={`/taxonomy/${cell.row.original.id}`}>
          {cell.value}
        </Link>
      </>
    ),
    default: cell.value,
  };

  return value[cell.column.id] || value.default;
};

const columns = [
  { accessor: 'vocabulary', label: 'Vocabulary name', Cell: formattedCell, sortable: false },
];

const TaxonomyTable = ({ data }) => (
  <SLTable
    columns={columns}
    data={data}
    dataCy="taxonomy-table"
  />
);

TaxonomyTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default TaxonomyTable;

import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { useSelector } from 'react-redux';
import { Divider, Form } from 'semantic-ui-react';

import { entitiesAPI, errorAPI } from '../../../../api';
import { Entity } from '../../../../constants';
import getSitesFieldStatus from '../../../../fieldLogic/sites';
import { useUserRole } from '../../../../hooks';
import { enumOptionsSelector } from '../../../../state/constants/selectors';
import {
  FormFieldDynamicSearchWrapper,
  FormFieldSelectWrapper,
  FormFieldWrapper,
  GeographicCoordinates,
  HierarchicalTree,
} from '../../../common';
import { uuidType } from '../../../types';
import { siteDefaultValues, siteValidationSchema } from '../helpers';
import styles from './SiteDetails.module.scss';

const DATA_CY = 'site-details';

const countryTypeSelector = state => enumOptionsSelector(state, 'country_type');
const statusTypeSelector = state => enumOptionsSelector(state, 'status_type');

const SiteDetails = ({
  entityId,
  data,
  revisionId,
  submitFormRef,
  onSubmit,
}) => {
  const alert = useAlert();
  const role = useUserRole();

  const countryOptions = useSelector(countryTypeSelector);
  const statusOptions = useSelector(statusTypeSelector);

  const buildClientSearchOptions = clients => clients.map(client => ({
    text: client.name,
    value: client.id,
  }));

  const [filteredClients, setFilteredClients] = useState(entityId
    ? [{
      text: data.client_name,
      value: data.client_id,
    }]
    : []);

  const searchClients = wildcard => {
    entitiesAPI.fetchAll(Entity.CLIENTS, { name: wildcard })
      .then(clients => setFilteredClients(buildClientSearchOptions(clients.items)))
      .catch(error => {
        alert.error(`Error seaching clients: ${error.message}`);
        errorAPI.sendError(error.message, error.stack);
      });
  };

  return (
    <Formik
      initialValues={entityId !== undefined ? data : siteDefaultValues}
      validationSchema={siteValidationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, errors, isValid, values }) => {
        const submit = () => {
          if (!isValid) {
            alert.error(
              <pre>
                {JSON.stringify(errors, null, 2)}
              </pre>,
            );
          } else {
            handleSubmit();
          }
        };

        return (
          <Form data-cy={`${DATA_CY}-form`}>
            {submitFormRef ? <button ref={submitFormRef} hidden type="submit" onClick={submit} /> : null}

            <FormFieldWrapper
              inline
              required
              label="Name"
              name="name"
              onStateHandler={getSitesFieldStatus(role, entityId === undefined, revisionId)}
            />

            <FormFieldDynamicSearchWrapper
              inline
              required
              label="Client"
              name="client_id"
              options={filteredClients}
              onSearchChange={searchClients}
              onStateHandler={getSitesFieldStatus(role, entityId === undefined, revisionId)}
            />

            <FormFieldWrapper
              inline
              required
              label="Address 1"
              name="address_1"
              onStateHandler={getSitesFieldStatus(role, entityId === undefined, revisionId)}
            />

            <FormFieldWrapper
              inline
              label="Address 2"
              name="address_2"
              onStateHandler={getSitesFieldStatus(role, entityId === undefined, revisionId)}
            />

            <FormFieldWrapper
              inline
              label="Address 3"
              name="address_3"
              onStateHandler={getSitesFieldStatus(role, entityId === undefined, revisionId)}
            />

            <FormFieldWrapper
              inline
              required
              label="City"
              name="city"
              onStateHandler={getSitesFieldStatus(role, entityId === undefined, revisionId)}
            />

            <FormFieldWrapper
              inline
              label="Post Code"
              name="post_code"
              onStateHandler={getSitesFieldStatus(role, entityId === undefined, revisionId)}
            />

            <FormFieldWrapper
              inline
              label="County"
              name="county"
              onStateHandler={getSitesFieldStatus(role, entityId === undefined, revisionId)}
            />

            <FormFieldSelectWrapper
              clearable
              inline
              required
              label="Country"
              name="country"
              options={countryOptions}
              onStateHandler={getSitesFieldStatus(role, entityId === undefined, revisionId)}
            />

            <Form.Group>
              <Form.Field width={3}>
                <label className={styles.label}>
                  {'Coordinates & Patch'}
                </label>
              </Form.Field>
              <Form.Field width={9}>
                <GeographicCoordinates
                  coordinates={values.coordinates}
                  patch={values.patch_name}
                />
              </Form.Field>
            </Form.Group>

            <FormFieldWrapper
              inline
              label="Directions"
              name="directions"
              type="textarea"
              onStateHandler={getSitesFieldStatus(role, entityId === undefined, revisionId)}
            />

            <Divider />

            <FormFieldSelectWrapper
              clearable
              inline
              required
              label="Status"
              name="status"
              options={statusOptions}
              onStateHandler={getSitesFieldStatus(role, entityId === undefined, revisionId)}
            />

            <Divider />

            <FormFieldWrapper
              inline
              label="Code"
              name="code"
              onStateHandler={getSitesFieldStatus(role, entityId === undefined, revisionId)}
            />

            <Divider />

            <HierarchicalTree showFiltered data={data?.client_org_level} id={data?.client_org_level_id} />

          </Form>
        );
      }}
    </Formik>
  );
};

SiteDetails.defaultProps = {
  data: null,
  onSubmit: null,
  revisionId: null,
  submitFormRef: null,
};

SiteDetails.propTypes = {
  entityId: uuidType.isRequired,
  data: PropTypes.object, // TODO: siteType,
  revisionId: uuidType,
  submitFormRef: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default SiteDetails;

import { createAction } from 'redux-actions';

import * as actionTypes from './actionTypes';

export const updateTaxonomy = createAction(
  actionTypes.UPDATE_TAXONOMY,
  ({ payload, redirect = true }) => ({ payload, redirect }),
);
export const fetchTaxonomy = createAction(actionTypes.FETCH_TAXONOMY);
export const setTaxonomy = createAction(actionTypes.SET_TAXONOMY, taxonomy => taxonomy);

export const fetchEnums = createAction(actionTypes.FETCH_ENUMS);
export const setEnums = createAction(actionTypes.SET_ENUMS, enums => enums);

// These actions shouldn't be in this part of the store. They should have their own 'user' section
export const fetchUserInfo = createAction(actionTypes.FETCH_USER_INFO, role => (role));
export const setUserInfo = createAction(actionTypes.SET_USER_INFO, userInfo => userInfo);
export const setUserRole = createAction(actionTypes.SET_USER_ROLE, userRole => userRole);
export const setUserPrompts = createAction(actionTypes.SET_USER_PROMPTS, userPrompts => userPrompts);
export const clearAuth = createAction(actionTypes.CLEAR_AUTH);

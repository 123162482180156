import { Auth } from 'aws-amplify';

const getMfaQrCode = async (username, code, issuer) => `otpauth://totp/AWSCognito:${username}?secret=${code}&issuer=${issuer}`;

const getMFACode = async () => {
  const user = await Auth.currentAuthenticatedUser();
  const code = await Auth.setupTOTP(user);
  return getMfaQrCode(user.username, code, 'serve-legal');
};

export {
  getMFACode,
  getMfaQrCode,
};

const grammar = `
Term
  = head:Factor tail:(_ ("AND" / "OR") _ Factor)* {
      return tail.reduce(function(result, element) {
        if (element[1] === "AND") { return result && element[3]; }
        if (element[1] === "OR") { return result || element[3]; }
      }, head);
    }

  
Comparacion
  = head:(String/Array) tail:(_ ("=" / "!=") _ String)* _ {
      return tail.reduce(function(result, element) {
        if(result instanceof Array) {
            return result.some( item => item == element[3] );
        }
        if (element[1] === "=") { return result == element[3] ; }
        if (element[1] === "!=") { return result != element[3] ; }
    }, head);
  }
  
Factor
  = "(" _ expr:Term _ ")" { return expr; }
  / Comparacion
  
Array "string"
  = [\\[] str:[a-z1-9\\, \\t\\/\\_\\-\\.\\&]i* [\\]] {
    return(str.join("").split(","))
  }
String "string"
  = [\\"\\'] str:[a-z1-9\\, \\t\\/\\_\\-\\.\\&]i* [\\"\\'] {
    return str.join("");
  }

_ "whitespace"
  = [ \\t\\n\\r]*`;

export default grammar;

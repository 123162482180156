import React from 'react';

import isMobile from '../../../utils/isMobile';
import { View } from '../../layout';
import body from '../Taxonomy/TaxonomyDetails/TaxonomyDetailsDocuments/TaxonomyDetailsDocuments.component';
import TipsAndDocsMobile from './TipsAndDocsMobile/TipsAndDocsMobile.component';
import sidebar from './TipsAndDocsSidebar/TipsAndDocsSidebar.component';

const DATA_CY = 'tips-and-docs';

const TipsAndDocs = () => {
  if (isMobile) {
    return <TipsAndDocsMobile />;
  }

  return (
    <View
      condensed
      body={body}
      dataCy={DATA_CY}
      header={() => (
        <span>
          {'Tips & Docs'}
        </span>
      )}
      sidebar={sidebar}
    />
  );
};

export default TipsAndDocs;

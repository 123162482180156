import PropTypes from 'prop-types';
import React from 'react';
import { useQuery } from 'react-query';
import { Button, Form, Grid, Modal } from 'semantic-ui-react';

import { accountAPI } from '../../../../api';
import { EntityState, UserRole } from '../../../../constants';
import { useUserRole } from '../../../../hooks';
import DirectionsMap from '../../Map/DirectionsMap/DirectionsMap.component';

const AuditsRouteModal = ({ open, selectedAudits, onCancel }) => {
  const role = useUserRole();

  const { data } = useQuery(
    ['myAuditorAccount'],
    () => accountAPI.fetchAuditorAccount(),
    { enabled: role === UserRole.AUDITOR },
  );

  return (open ? (
    <Modal
      closeOnDimmerClick={true}
      open={open}
      onClose={onCancel}
    >
      <Modal.Header>
        {'Your route'}
      </Modal.Header>

      <Modal.Content>
        <Form>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <DirectionsMap data={selectedAudits} type={EntityState.TABLE} user={data} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Modal.Content>

      <Modal.Actions>
        <Button secondary onClick={onCancel}>
          {'OK'}
        </Button>
      </Modal.Actions>
    </Modal>
  ) : null);
};

AuditsRouteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  selectedAudits: PropTypes.arrayOf(PropTypes.object).isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default AuditsRouteModal;

/* eslint-disable no-console */
import { AuditStatus } from '../../../../constants';

const completeAuditsSortingOptions = [
  {
    key: 'submitted_date_oldest',
    text: 'Date completed - oldest to newest',
    value: 'submitted_date_oldest',
  },
  {
    key: 'submitted_date_newest',
    text: 'Date completed - newest to oldest',
    value: 'submitted_date_newest',
  },
];

const assignedAuditsSortingOptions = [
  {
    key: 'client_name',
    text: 'Client A-Z',
    value: 'client_name',
  },
  {
    key: 'deadline_date',
    text: 'Deadline Date - soonest to latest',
    value: 'deadline_date',
  },
  {
    key: 'site_post_code',
    text: 'Postcode A-Z',
    value: 'site_post_code',
  },
];

const finishedStatus = [
  AuditStatus.SUBMITTED,
  AuditStatus.APPROVED,
  AuditStatus.APPROVING_QUERY,
  AuditStatus.CLIENT_QUERY,
];

const isFinishedAuditsTab = status => status?.toString() === finishedStatus.toString();
const isAssignedAuditsTab = status => status === AuditStatus.ASSIGNED;

export const showSorting = (status = '') => isFinishedAuditsTab(status) || isAssignedAuditsTab(status);

export const sortingOptions = (status = '') => {
  if (isFinishedAuditsTab(status)) {
    return completeAuditsSortingOptions;
  }
  if (isAssignedAuditsTab(status)) {
    return assignedAuditsSortingOptions;
  }
  return [];
};

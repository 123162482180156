import { Formik } from 'formik';
import _debounce from 'lodash/debounce';
import _pickBy from 'lodash/pickBy';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Form, Grid } from 'semantic-ui-react';

import { DEBOUNCE_MS } from '../../../../constants';
import { useUserRole } from '../../../../hooks';
import { FieldScope, fieldVisibility } from '../../../../rolePermissionMatrix';
import { OrderFilterFields } from '../../../../rolePermissionMatrix/entity/orders';
import { enumOptionsSelector, taxonomyTermSelector } from '../../../../state/constants/selectors';
import { FormFieldSelectWrapper, FormFieldWrapper, TaxonomyTermOptions } from '../../../common';
import { orderFilterDefaultValues } from '../helpers';

const DATA_CY = 'orders-table-filter';

const orderStatusTypeSelector = state => enumOptionsSelector(state, 'order_status_type');
const scheduleTypeOptionsSelector = state => enumOptionsSelector(state, 'schedule_type');
const itemToOrderTaxonomyTermSelector = state => taxonomyTermSelector(state, 'order_item');

const OrdersTableFilter = ({ values: initialValues, onChange }) => {
  const orderStatusTypeOptions = useSelector(orderStatusTypeSelector);
  const scheduleTypeOptions = useSelector(scheduleTypeOptionsSelector);
  const itemToOrderTaxonomyTerm = useSelector(itemToOrderTaxonomyTermSelector);

  const { entity } = useParams();
  const role = useUserRole();

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        ...initialValues,
        status: initialValues.status ? [initialValues.status].flat() : [],
      }}
      onSubmit={values => onChange(_pickBy(values))}
    >
      {({ handleSubmit }) => {
        const debounceSubmit = _debounce(handleSubmit, DEBOUNCE_MS);

        const handleFieldChange = () => {
          debounceSubmit();
        };

        return (
          <Form inverted data-cy={DATA_CY}>
            <Grid columns={5}>
              {fieldVisibility({ entity, role, scope: FieldScope.FILTER_VIEW_FIELDS })(OrderFilterFields.clientName)
                ? (
                  <Grid.Column>
                    <FormFieldWrapper
                      label="Client"
                      name={OrderFilterFields.clientName}
                      onChange={handleFieldChange}
                    />
                  </Grid.Column>
                ) : null
              }
              {fieldVisibility({ entity, role, scope: FieldScope.FILTER_VIEW_FIELDS })(OrderFilterFields.scheduleType)
                ? (
                  <Grid.Column>
                    <FormFieldSelectWrapper
                      clearable
                      label="Schedule Type"
                      name={OrderFilterFields.scheduleType}
                      options={scheduleTypeOptions}
                      onChange={handleSubmit}
                    />
                  </Grid.Column>
                ) : null
              }
              {fieldVisibility({ entity, role, scope: FieldScope.FILTER_VIEW_FIELDS })(OrderFilterFields.itemToOrder)
                ? (
                  <Grid.Column>
                    <FormFieldSelectWrapper
                      clearable
                      label="Item to order"
                      name={OrderFilterFields.itemToOrder}
                      options={TaxonomyTermOptions(itemToOrderTaxonomyTerm)}
                      onChange={handleSubmit}
                    />
                  </Grid.Column>
                ) : null
              }
              {fieldVisibility({ entity, role, scope: FieldScope.FILTER_VIEW_FIELDS })(OrderFilterFields.surveyTitle)
                ? (
                  <Grid.Column>
                    <FormFieldWrapper
                      label="Survey"
                      name={OrderFilterFields.surveyTitle}
                      onChange={handleFieldChange}
                    />
                  </Grid.Column>
                ) : null
              }
              {fieldVisibility({ entity, role, scope: FieldScope.FILTER_VIEW_FIELDS })(OrderFilterFields.status)
                ? (
                  <Grid.Column>
                    <FormFieldSelectWrapper
                      clearable
                      multiple
                      label="Status"
                      name={OrderFilterFields.status}
                      options={orderStatusTypeOptions}
                      onChange={handleSubmit}
                    />
                  </Grid.Column>
                ) : null
              }
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

OrdersTableFilter.defaultProps = {
  values: orderFilterDefaultValues,
};

OrdersTableFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    client_name: PropTypes.string,
    item_to_order: PropTypes.string,
    schedule_type: PropTypes.string,
    survey_title: PropTypes.string,
  }),
};

export default OrdersTableFilter;

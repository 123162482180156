import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useMutation } from 'react-query';
import { Button, Form, Modal } from 'semantic-ui-react';

import { legalAPI } from '../../../../api';
import { FormFieldCheckboxWrapper } from '../../../common';
import styles from './DashboardGDPRModal.module.scss';

const DashboardGDPRModal = ({ onAccept }) => {
  const { mutate: updateFacialImagesConsent } = useMutation(legalAPI.updateFacialImagesConsent);
  const { mutate: updateSpecialCategoryConsent } = useMutation(legalAPI.updateSpecialCategoryConsent);
  const { mutate: updateCommunicationsConsent } = useMutation(legalAPI.updateCommunicationsConsent);

  return (
    <Modal closeOnDimmerClick={true} open={true}>
      <Modal.Header>
        {'GDPR'}
      </Modal.Header>
      <Modal.Content>
        <Formik
          enableReinitialize={true}
          initialValues={{
            facial_images_consent: false,
            special_category_consent: false,
            communication_consent: false,
          }}
          onSubmit={() => {
            onAccept();
          }}
        >
          {
            ({ setFieldValue }) => <>
              <Form>
                <div className={styles.formFieldCheckboxWrapperParent}>
                  <FormFieldCheckboxWrapper
                    inline
                    toggle
                    help="I consent to Serve Legal using my facial images (and meta data) and other personal data and sharing such data with their clients for the purpose of facial recognition verification and in relation to the services Serve Legal provides."
                    label=""
                    name="facial_images_consent"
                    onChange={(_, { checked }) => {
                      setFieldValue('facial_images_consent', checked);
                      updateFacialImagesConsent(checked);
                    }}
                  />
                </div>

                <div className={styles.formFieldCheckboxWrapperParent}>
                  <FormFieldCheckboxWrapper
                    inline
                    toggle
                    help="I consent to Serve Legal using my special category data such as my ethnicity and occupational health information and sharing such data with their clients for the purpose of facial recognition verification and in relation to the services Serve Legal provides."
                    label=""
                    name="special_category_consent"
                    onChange={(_, { checked }) => {
                      setFieldValue('special_category_consent', checked);
                      updateSpecialCategoryConsent(checked);
                    }}
                  />
                </div>

                <div className={styles.formFieldCheckboxWrapperParent}>
                  <FormFieldCheckboxWrapper
                    inline
                    toggle
                    help="Please check this box to confirm that you consent to receiving communications from us regarding your Serve Legal employment, job opportunities, and marketing initiatives."
                    label=""
                    name="communications_consent"
                    onChange={(_, { checked }) => {
                      setFieldValue('communications_consent', checked);
                      updateCommunicationsConsent(checked);
                    }}
                  />
                </div>
                <Modal.Content className={styles.content}>
You can always change these preferences in your account
                </Modal.Content>
              </Form>

            </>
          }
        </Formik>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={onAccept}>
          {'Save and close'}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

DashboardGDPRModal.propTypes = {
  onAccept: PropTypes.func.isRequired,
};

export default DashboardGDPRModal;

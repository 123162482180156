import { useParams } from 'react-router-dom';

import { useUserRole } from '../../../hooks';

const RoleAndEntityChecker = ({
  allowedRoleToEntityMatrix = null, allowedEntities = [], allowedRoles = [], children,
}) => {
  const { entity } = useParams();
  const role = useUserRole();

  if (allowedRoleToEntityMatrix) {
    return allowedRoleToEntityMatrix[role]?.includes(entity) ? children : null;
  }

  return allowedEntities.includes(entity) && allowedRoles.includes(role) ? children : null;
};

export default RoleAndEntityChecker;

import * as Yup from 'yup';

// eslint-disable-next-line import/prefer-default-export
export const automatedExportValidationSchema = Yup.object({
  name: Yup.string()
    .trim()
    .ensure()
    .min(1, 'Required'),
  client_id: Yup.string()
    .trim()
    .ensure()
    .min(1, 'Required'),
  username: Yup.string()
    .matches(/^[a-zA-Z\d -.]{3,}$/, {
      message: 'Incorrect format - at least 3 characters',
      excludeEmptyString: false,
    })
    .trim()
    .ensure()
    .min(1, 'Required'),
  password: Yup.string()
    .matches(/^(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/, {
      message: 'Incorrect format - min 8 characters, 1 uppercase, 1 lowercase and 1 symbol',
      excludeEmptyString: false,
    })
    .required('Required'),
  password_confirm: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('Required'),
});

import React from 'react';
import { useParams } from 'react-router-dom';

import SurveyBranching from './SurveyBranching/SurveyBranching.component';
import SurveyLogic from './SurveyLogic/SurveyLogic.component';
import SurveyMatchingV3 from './SurveyMatchingV3/SurveyMatching.component';
import SurveyQuestions from './SurveyQuestions/SurveyQuestions.component';
import SurveyReports from './SurveyReports/SurveyReports.component';
import SurveyValidation from './SurveyValidation/SurveyValidation.component';

const SurveyDetails = () => {
  const { surveyStep } = useParams();

  const step = {
    branching: SurveyBranching,
    logic: SurveyLogic,
    reports: SurveyReports,
    validation: SurveyValidation,
    matching: SurveyMatchingV3,
  };

  const Step = (step[surveyStep] || SurveyQuestions);
  return <Step />;
};

export default SurveyDetails;

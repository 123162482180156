import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form } from 'semantic-ui-react';
import * as Yup from 'yup';

import styles from './VerifyLoginForm.module.scss';

const DATA_CY = 'verify-login-form';

const defaultValues = {
  code: '',
};

const VerifyLoginSchema = Yup.object().shape({
  code: Yup.string().required('Please enter the code from the Authenticator.'),
});

const VerifyLoginForm = ({ verifyLogin }) => (
  <Formik
    initialValues={defaultValues}
    validationSchema={VerifyLoginSchema}
    onSubmit={({ code }, formikBag) => {
      verifyLogin(code);
      formikBag.resetForm(defaultValues);
    }}
  >
    {({ values, handleChange, errors, touched, handleSubmit }) => (
      <Form noValidate className={styles.root} data-cy={DATA_CY} onSubmit={handleSubmit}>
        <Form.Input
          className={styles.codeInput}
          data-cy={`${DATA_CY}-code-input`}
          error={errors.code && touched.code ? errors.code : null}
          id="code"
          name="code"
          placeholder="Authenticator App Code"
          type="text"
          value={values.code}
          onChange={handleChange}
        />
        <Button
          primary
          data-cy={`${DATA_CY}-submit-button`}
          size="big"
          type="submit"
        >
          {'Verify Login'}
        </Button>
      </Form>
    )}
  </Formik>
);

VerifyLoginForm.propTypes = {
  verifyLogin: PropTypes.func.isRequired,
};

export default VerifyLoginForm;

import PropTypes from 'prop-types';
import React from 'react';
import { Button, Modal } from 'semantic-ui-react';

const GenericModal = ({ headerText, children, buttons, onDismiss, open }) => (
  <Modal
    closeOnDimmerClick={true}
    open={open}
    onClose={onDismiss || null}
  >
    {headerText
      && <Modal.Header>
        {headerText}
      </Modal.Header>}
    { children
      && <Modal.Content>
        {children}
      </Modal.Content>
    }
    {buttons
    && <Modal.Actions>
      {buttons.map((button, i) => (
        <Button
          key={`generic-modal-btn-${i}`}
          disabled={button.disabled}
          loading={button.loading}
          primary={!!button.isPrimary}
          type={button.type || 'button'}
          onClick={button.func}
        >
          { button.label }
        </Button>))}
    </Modal.Actions>
    }
  </Modal>
);

GenericModal.defaultProps = {
  children: null,
  headerText: null,
  loading: false,
  onDismiss: () => {},
};

GenericModal.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.object).isRequired,
  open: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  headerText: PropTypes.string,
  onDismiss: PropTypes.func,
};

export default GenericModal;

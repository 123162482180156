import timediff from 'timediff';

// TODO: unit testing

/**
 * Parses the difference of time between a specific timestamp and now to a string.
 * @param {string | Date} timestamp - Timestamp to calculate the time difference.
 * @param {string} format - Format to print the time difference:
 *                  Y (years) |
 *                  M (months) |
 *                  W (weeks) |
 *                  D (days) |
 *                  H (hours) |
 *                  m (minutes) |
 *                  S (seconds) |
 *                  s (milliseconds)
 *
 * @see https://www.npmjs.com/package/timediff
 */
const timeDiffToString = (timestamp, format) => {
  if (!timestamp) return '';

  const result = timediff(timestamp, Date.now(), { units: format, returnZeros: false });
  const strings = [];
  Object.keys(result).forEach(key => {
    strings.push(`${result[key]} ${key}`);
  });

  return `${strings.join(', ')}`;
};

export default timeDiffToString;

import React from 'react';
import { useSelector } from 'react-redux';

import { enumOptionsSelector } from '../../../../../state/constants/selectors';
import checkGender from '../../../../../utils/filterOutGender';
import checkHonorific from '../../../../../utils/filterOutHonorific';
import { FormFieldSelectWrapper } from '../../../../common';
import FormLayout from '../../RegisterAuditorFormLayout/RegisterAuditorFormLayout.component';
import styles from './RegisterAuditorFormStepFour.module.scss';

const genderOptionsSelector = state => enumOptionsSelector(state, 'gender_type');
const maritalStatusOptionsSelector = state => enumOptionsSelector(state, 'marital_status_type');
const honorificOptionsSelector = state => enumOptionsSelector(state, 'honorific_type');

const RegisterAuditorFormStepFour = () => {
  const genderOptions = useSelector(genderOptionsSelector).filter(item => checkGender(item.value));
  const maritalStatusOptions = useSelector(maritalStatusOptionsSelector);
  const honorificOptions = useSelector(honorificOptionsSelector).filter(item => checkHonorific(item.value));

  return (
    <FormLayout subtitle="3 of 3" title="Basics details">
      <span className={styles.titleQuestion}>
        {'You must submit your gender as on your formal ID'}
      </span>
      <FormFieldSelectWrapper
        required
        name="gender"
        options={genderOptions}
        placeholder="Select one"
      />
      <span className={styles.titleQuestion}>
        {'Marital status'}
      </span>
      <FormFieldSelectWrapper
        required
        name="marital_status"
        options={maritalStatusOptions}
        placeholder="Select one"
      />
      <span className={styles.titleQuestion}>
        {'Select your title'}
      </span>
      <FormFieldSelectWrapper
        required
        name="honorific"
        options={honorificOptions}
        placeholder="Select one"
      />
    </FormLayout>
  );
};
export default RegisterAuditorFormStepFour;

import classnames from 'classnames';
import { Field, useField } from 'formik';
import _debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'semantic-ui-react';

import { DEBOUNCE_MS } from '../../../constants';
import FieldStatus from '../../../fieldLogic/fieldStatus';
import FormFieldTemplate from './Template/FormFieldTemplate.component';

const FormFieldDynamicSearchWrapper = ({
  dataCy,
  help,
  inline,
  label,
  labelStyle,
  labelTooltip,
  name,
  onChange,
  onSearchChange,
  onStateHandler,
  options,
  readOnly,
  required,
  valueStyle,
  ...rest
}) => {
  const [field, meta, helpers] = useField(name);

  let state = FieldStatus.EDITABLE;

  if (onStateHandler) {
    state = onStateHandler(name);
  }

  if (state === FieldStatus.HIDDEN) {
    return null;
  }

  const dataCyPrefix = dataCy || name;

  const printSelectedOptions = () => options.find(o => o.value === field.value)?.text || '';

  const readOnlyField = () => (
    <span className={classnames({ [valueStyle]: valueStyle })}>
      {field.value && printSelectedOptions()}
    </span>
  );

  const debounceSearch = _debounce(onSearchChange, DEBOUNCE_MS);

  const editableField = () => (
    <Field
      clearable
      as={Form.Select}
      data-cy={`${dataCyPrefix}-field`}
      error={meta.error}
      icon="search"
      name={name}
      options={options}
      required={required}
      search={ops => ops} // We're doing the search using an external query, so we show all the options
      onBlur={null}
      onChange={(_e, { value }) => {
        helpers.setValue(value);
        if (onChange) {
          onChange(value);
        }
      }}
      onSearchChange={(_event, { searchQuery }) => debounceSearch(searchQuery)}
      {...rest}
    />
  );

  return (
    <FormFieldTemplate
      dataCy={dataCyPrefix}
      help={help}
      inline={inline}
      label={label}
      labelStyle={labelStyle}
      labelTooltip={labelTooltip}
      required={required}
    >
      {state === FieldStatus.READONLY || readOnly ? readOnlyField() : editableField()}
    </FormFieldTemplate>
  );
};

FormFieldDynamicSearchWrapper.defaultProps = {
  dataCy: null,
  help: null,
  inline: false,
  labelStyle: null,
  labelTooltip: null,
  onChange: null,
  onStateHandler: null,
  readOnly: false,
  required: false,
  valueStyle: null,
};

FormFieldDynamicSearchWrapper.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSearchChange: PropTypes.func.isRequired,
  dataCy: PropTypes.string,
  help: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  inline: PropTypes.bool,
  labelStyle: PropTypes.string,
  labelTooltip: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  valueStyle: PropTypes.string,
  onChange: PropTypes.func,
  onStateHandler: PropTypes.func,
};

export default FormFieldDynamicSearchWrapper;

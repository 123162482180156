import { useAlert } from 'react-alert';
import { useMutation } from 'react-query';

import { surveyRevisionsAPI } from '../api';
import { Entity } from '../constants';
import history from '../history';

const useUpdateSurvey = ({
  currentStep,
  onSuccessStep = null,
}) => {
  const alert = useAlert();

  return useMutation(surveyRevisionsAPI.patch, {
    onSuccess: response => {
      alert.success(`Survey ${currentStep} updated`);
      if (onSuccessStep) {
        history.push(
          `/${Entity.SURVEYS}/${response.survey_id}/revisions/${response.id}/${onSuccessStep}`,
        );
      } else {
        history.push(
          `/${Entity.SURVEYS}`,
        );
      }
    },
    onError: error => {
      alert.error(`Error updating survey ${currentStep}: ${JSON.stringify(error)}`);
    },
  });
};

export default useUpdateSurvey;

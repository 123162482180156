import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useMutation } from 'react-query';
import { Button, Form, Modal } from 'semantic-ui-react';
import * as Yup from 'yup';

import { errorAPI, multipartAPI } from '../../../../api';
import { Entity } from '../../../../constants';
import { FormFieldWrapper } from '../../../common';
import styles from './DashboardTermAddressModal.module.scss';

const termAddressEditValidationSchema = Yup.object().shape({
  term_address_1: Yup.string().required('Term address is required'),
  term_address_2: Yup.string().nullable(),
  term_city: Yup.string().required('Term city is required'),
  term_county: Yup.string().required('Term country is required'),
  term_post_code: Yup.string()
    .when('country', {
      is: 'United Kingdom',
      then: Yup.string().trim().ensure().min(1, 'Postcode is required'),
    }),
});

const DashboardTermAddressModal = ({ userInfo, onAccept }) => {
  const { mutate: editAuditorAccount } = useMutation(multipartAPI.update, {
    onSuccess: () => {
      onAccept();
    },
    onError: error => {
      errorAPI.sendError(error.message, error.stack);
    },
  });

  const updateUserInfo = data => {
    const formData = new FormData();
    const { identification, image, passport, visa, ...filteredValues } = data;

    Object.entries(filteredValues).forEach(([key, value]) => formData.append(key, value));

    editAuditorAccount({
      entity: Entity.AUDITORS,
      entityId: 'me',
      payload: formData,
    });
  };

  return (
    <Formik
      initialValues={userInfo}
      validationSchema={termAddressEditValidationSchema}
      onSubmit={updateUserInfo}
    >
      {({ handleSubmit }) => (
        <Modal closeOnDimmerClick={true} open={true}>
          <Modal.Header>
            {'Term address information missing'}
          </Modal.Header>
          <Modal.Content>

            <Form>
              <Modal.Content className={styles.content}>
                Please update your term address information. This can be any other address you complete audits from, e.g. uni address/parents/family address. If you have no other address, please input your home address again
              </Modal.Content>
              <FormFieldWrapper
                required
                name="term_address_1"
                placeholder="Address 1"
              />
              <FormFieldWrapper
                required
                name="term_address_2"
                placeholder="Address 2"
              />
              <FormFieldWrapper
                required
                name="term_city"
                placeholder="City"
              />
              <FormFieldWrapper
                required
                name="term_post_code"
                placeholder="Postcode/Eircode"
              />
              <FormFieldWrapper
                required
                name="term_county"
                placeholder="County"
              />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              primary
              content="Save and close"
              type="submit"
              onClick={handleSubmit}
            />
          </Modal.Actions>
        </Modal>

      )}
    </Formik>);
};

DashboardTermAddressModal.propTypes = {
  userInfo: PropTypes.object.isRequired,
  onAccept: PropTypes.func.isRequired,
};

export default DashboardTermAddressModal;

import React, { useContext } from 'react';

import { PatchesContext } from '../../../../context';
import PatchEditSidebar from './PatchEditSidebar/PatchEditSidebar.component';
import PatchesListSidebar from './PatchesListSidebar/PatchesListSidebar.component';

const PatchesSidebar = props => {
  const {
    newPatchActive,
    selectedPatch,
  } = useContext(PatchesContext);

  if (selectedPatch || newPatchActive) {
    return <PatchEditSidebar {...props} />;
  }

  return <PatchesListSidebar {...props} />;
};

export default PatchesSidebar;

import { StatusCodes } from 'http-status-codes';

import persistence from '../persistence';
import CustomError from '../utils/customError';

const cookieStorage = persistence('cookieStorage');

export const handlingResponse = (successStatus = [StatusCodes.OK], errorMessage = 'Error') => ({
  status, data,
}) => {
  if (!successStatus.includes(status)) {
    const error = new Error(errorMessage);
    error.statusCode = status;
    throw error;
  }

  return data;
};

export const logError = async error => {
  if (error.response && error.response.data instanceof Blob) {
    try {
      error.response.data = JSON.parse(await error.response.data.text());
    } catch (err) {
      error.response.data = 'unparsable object';
    }
  }
  if (error.response && error.response.data) {
    const formatError = {
      message: error.response.data.message || error.response.data.name,
      stack: error.stack,
      status: error.response.status,
    };

    throw new CustomError(formatError);
  }

  throw error;
};

export const getAuthorization = () => (window.Cypress ? '' : `Bearer ${cookieStorage.getSessionToken()}`);

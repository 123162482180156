import PropTypes from 'prop-types';
import React from 'react';

import { UserRole } from '../../../../constants';
import { AuditFields } from '../../../../rolePermissionMatrix/entity/audits';
import {
  SLTable,
  SLTableCellDateFormatter,
  SLTableCellDateFormatterType,
  SLTableCellFormatter,
} from '../../../common';
import { RoleChecker } from '../../../layout';

const DATA_CY = 'archives-table';

const columns = () => ([
  {
    accessor: AuditFields.internalId,
    label: 'Site ID',
    Cell: SLTableCellFormatter({
      linkFormat: '/archives/#objectId',
      textFormat: '#audit_internal_id',
    }),
    sortable: false,
  },
  {
    accessor: AuditFields.siteName,
    label: 'Site Name',
    sortable: false,
  },
  {
    accessor: AuditFields.dateOfVisit,
    label: 'Visit Date',
    Cell: SLTableCellDateFormatter({ type: SLTableCellDateFormatterType.CUSTOM }),
    sortable: false,
  },
]);

const ArchivesTable = ({
  data,
}) => {
  const getTable = () => (
    <SLTable
      columns={columns()}
      data={data}
      dataCy={DATA_CY}
    />
  );

  return (
    <>
      <RoleChecker allowedRoles={[UserRole.CLIENT]}>
        {getTable()}
      </RoleChecker>
    </>
  );
};

ArchivesTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ArchivesTable;

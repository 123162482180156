import classnames from 'classnames';
import React from 'react';
import { Grid } from 'semantic-ui-react';

import { CustomEventType, getQuestionTypeMetadata, QuestionType } from '../../../../../../constants';
import { AuxiliaryPanel } from '../../../../../common';
import styles from './SurveyQuestionTypePanel.module.scss';

const DATA_CY = 'survey-question-type-panel';

const SurveyQuestionTypePanel = () => {
  const handleDragStart = type => e => {
    e.dataTransfer.setData('text/plain', JSON.stringify({
      event: CustomEventType.QUESTION_ADD_NEW,
      type,
    }));
  };

  const handleClick = type => {
    const event = new CustomEvent(CustomEventType.QUESTION_ADD_NEW, {
      detail: {
        type,
      },
    });
    window.dispatchEvent(event);
  };

  return (
    <AuxiliaryPanel
      content={
        <Grid>
          {Object.keys(QuestionType).map(key => {
            const { label, icon } = getQuestionTypeMetadata(key);
            const type = QuestionType[key];

            return (
              <Grid.Column
                key={type}
                className={styles.column}
                computer={8}
                data-cy={`${DATA_CY}-${type}`}
                mobile={16}
                tablet={16}
              >
                <div
                  draggable
                  className={styles.questionType}
                  onClick={() => handleClick(type)}
                  onDragStart={handleDragStart(type)}
                >
                  <i className={classnames(`${icon}`, styles.questionTypeIcon)} />
                  {label}
                </div>
              </Grid.Column>
            );
          })}
        </Grid>
      }
      dataCy={DATA_CY}
      header={(
        <span>
          Form Elements
        </span>
      )}
    />
  );
};

export default SurveyQuestionTypePanel;

/* eslint-disable no-console */
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Grid } from 'semantic-ui-react';

import { UserRole } from '../../../../../constants';
import getFieldStatus from '../../../../../fieldLogic/audit';
import { useUserRole } from '../../../../../hooks';
import { enumOptionsSelector, taxonomyTermSelector } from '../../../../../state/constants/selectors';
import {
  FormFieldCheckboxWrapper,
  FormFieldCurrencyWrapper,
  FormFieldDateTimeWrapper,
  FormFieldDropzoneWrapper,
  FormFieldSelectWrapper,
  FormFieldWrapper,
  ReclaimableExpensesAccordion,
} from '../../../../common';
import { uuidType } from '../../../../types';
import { calculatePays } from '../helpers';

const travelExpensesOptionsSelector = state => enumOptionsSelector(state, 'travel_expenses_type');
const reclaimableExpensesOptionsSelector = state => enumOptionsSelector(state, 'reclaimable_expenses_type');
const mileageTypeOptionsSelector = state => enumOptionsSelector(state, 'mileage_type');
const payTaxonomySelector = state => taxonomyTermSelector(state, 'pay_rate');

const AuditDetailsExpenses = ({ labelStyle, revisionId, valueStyle }) => {
  const { values, setFieldValue } = useFormikContext();
  const role = useUserRole();

  const travelExpensesOptions = useSelector(travelExpensesOptionsSelector);
  const reclaimableExpensesOptions = useSelector(reclaimableExpensesOptionsSelector);
  const mileageTypeOptions = useSelector(mileageTypeOptionsSelector);
  const payTaxonomyTerms = useSelector(payTaxonomySelector);

  const handleDropFile = fieldName => filesData => {
    values[fieldName].push(
      ...filesData.map(({ file, url }) => ({
        file,
        url,
        state: 'new',
      })),
    );
  };

  const handleDeleteFile = fieldName => fileData => {
    const currentImageIndex = values[fieldName].findIndex(({ url }) => url === fileData.url);
    if (currentImageIndex > -1) {
      values[fieldName][currentImageIndex].state = 'removed';
    }
  };

  return (

    <>
      <Grid
        relaxed
        padded={'vertically'}
      >
        <Grid.Row centered divided columns="equal">
          <Grid.Column textAlign="center" >
            <FormFieldCurrencyWrapper
              fluid
              currency={values.order_currency}
              label="Pay"
              labelStyle={labelStyle}
              name="auditor_pay_per_audit"
              valueStyle={valueStyle}
              onChange={value => {
                const { holidayPay, totalPay } = calculatePays({
                  pay: value,
                  payRateTaxonomy: payTaxonomyTerms,
                  auditorBankCountry: values.auditor_bank_country,
                  date: values.date_of_visit,
                });
                setFieldValue('auditor_pay_per_audit', value);
                setFieldValue('holiday_pay', holidayPay);
                setFieldValue('total_pay', totalPay);
              }}
              onStateHandler={getFieldStatus(role, values.status, revisionId)}
            />
          </Grid.Column>
          <Grid.Column textAlign="center">
            <FormFieldCurrencyWrapper
              fluid
              currency={values.order_currency}
              label="Holiday Pay"
              labelStyle={labelStyle}
              name="holiday_pay"
              valueStyle={valueStyle}
              onStateHandler={getFieldStatus(role, values.status, revisionId)}

            />
          </Grid.Column>
          <Grid.Column textAlign="center">
            <FormFieldCurrencyWrapper fluid
              currency={values.order_currency}
              label="Total Pay"
              labelStyle={labelStyle}
              name="total_pay"
              valueStyle={valueStyle}
              onStateHandler={getFieldStatus(role, values.status, revisionId)}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <FormFieldWrapper
        label="Mileage"
        labelStyle={labelStyle}
        name="mileage"
        type="number"
        valueStyle={valueStyle}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      <FormFieldSelectWrapper
        label="Mileage Type"
        labelStyle={labelStyle}
        name="mileage_type"
        options={mileageTypeOptions}
        valueStyle={valueStyle}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      <FormFieldWrapper
        label="Mileage description"
        labelStyle={labelStyle}
        name="mileage_description"
        type="textarea"
        valueStyle={valueStyle}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      {role === UserRole.DATA && (
        <FormFieldCurrencyWrapper
          currency={values.order_currency}
          label="Entrance Charge"
          labelStyle={labelStyle}
          name="entrance_charge"
          valueStyle={valueStyle}
          onStateHandler={getFieldStatus(role, values.status, revisionId)}
        />
      )}

      <FormFieldCurrencyWrapper
        currency={values.order_currency}
        label="Travel Expenses"
        labelStyle={labelStyle}
        name="travel_expenses"
        valueStyle={valueStyle}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      <FormFieldSelectWrapper
        label="Travel Expense type"
        labelStyle={labelStyle}
        name="travel_expenses_type"
        options={travelExpensesOptions}
        valueStyle={valueStyle}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      <FormFieldWrapper
        label="Travel Expense description"
        labelStyle={labelStyle}
        name="travel_expenses_description"
        valueStyle={valueStyle}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      <FormFieldDropzoneWrapper
        label="Travel expenses images"
        labelStyle={labelStyle}
        name="travel_expenses_images_urls"
        valueStyle={valueStyle}
        onDropFiles={handleDropFile('travel_expenses_images')}
        onRemoveFile={handleDeleteFile('travel_expenses_images')}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />
      <ReclaimableExpensesAccordion
        data={values}
        labelStyle={labelStyle}
        reclaimableExpensesOptions={reclaimableExpensesOptions
          ?.map(({ deprecated, ...rest }) => ({ ...rest, text: `${rest.text}${deprecated ? ' (deprecated)' : '' }` }))}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />
      <FormFieldDropzoneWrapper
        label="Reclaimable expenses images"
        labelStyle={labelStyle}
        name="expenses_receipts_urls"
        valueStyle={valueStyle}
        onDropFiles={handleDropFile('expenses_receipts')}
        onRemoveFile={handleDeleteFile('expenses_receipts')}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      <FormFieldCheckboxWrapper
        label="Paid"
        labelStyle={labelStyle}
        name="paid"
        valueStyle={valueStyle}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      <FormFieldCheckboxWrapper
        label="Batched"
        labelStyle={labelStyle}
        name="batched"
        valueStyle={valueStyle}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      <FormFieldDateTimeWrapper
        label="Payroll date"
        labelStyle={labelStyle}
        name="payroll_date"
        valueStyle={valueStyle}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      <FormFieldDateTimeWrapper
        label="Pay date"
        labelStyle={labelStyle}
        name="pay_date"
        valueStyle={valueStyle}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

    </>
  );
};

AuditDetailsExpenses.defaultProps = {
  labelStyle: null,
  revisionId: null,
  valueStyle: null,
};

AuditDetailsExpenses.propTypes = {
  labelStyle: PropTypes.string,
  revisionId: uuidType,
  valueStyle: PropTypes.string,
};

export default AuditDetailsExpenses;

import { Auth as AuthAmplify } from 'aws-amplify';
import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { useHistory } from 'react-router-dom';

import { Auth } from '../../layout';
import styles from './RequestPassword.module.scss';
import RequestPasswordForm from './RequestPasswordForm/RequestPasswordForm.component';

const RequestPassword = () => {
  const { push } = useHistory();
  const alert = useAlert();
  const [currentEmail, setCurrentEmail] = useState('');

  const requestPassword = async ({ email, code, newPassword }) => {
    try {
      if (code && email && newPassword) {
        await AuthAmplify.forgotPasswordSubmit(email, code, newPassword);
        await AuthAmplify.signIn(email, newPassword);
        push('/');
      } else {
        await AuthAmplify.forgotPassword(email);
        setCurrentEmail(email);
      }
    } catch (error) {
      alert.error(`Error requesting new password: ${error.message}`);
      throw error;
    }
  };

  return (
    <Auth
      form={RequestPasswordForm}
      requestPassword={authValues => requestPassword(authValues)}
      subtitle={() => (
        <>
          {'A password reset message will be sent to your e-mail address.'}
          {currentEmail && (
            <>
              <div className={styles.linkWrapper}>
                <span className={styles.link} onClick={() => requestPassword({
                  email: currentEmail,
                })}
                >
                  {'Send code again'}
                </span>
              </div>
              <div className={styles.linkWrapper}>
                <span className={styles.link} onClick={() => window.location.reload()}>
                  {'Back to enter email'}
                </span>
              </div>
            </>
          )}
          <div className={styles.linkWrapper}>
            <span className={styles.link} onClick={() => push('/login')}>
              {'Go to login'}
            </span>
          </div>
        </>
      )}
      title="Request new password"
    />
  );
};

export default RequestPassword;

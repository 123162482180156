import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Form } from 'semantic-ui-react';
import * as Yup from 'yup';

import styles from './LogInForm.module.scss';

const DATA_CY = 'login-form';

const defaultValues = {
  email: '',
  password: '',
};

const LogInSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  password: Yup.string()
    .required('Required'),
});

const LogInForm = ({ login }) => (
  <Formik
    initialValues={defaultValues}
    validationSchema={LogInSchema}
    onSubmit={async values => {
      await login(values);
    }}
  >
    {({ values, handleChange, errors, touched, handleSubmit }) => (
      <Form noValidate className={styles.root} data-cy={DATA_CY} onSubmit={handleSubmit}>
        <Form.Input
          className={styles.emailInput}
          data-cy={`${DATA_CY}-email-input`}
          error={errors.email && touched.email ? errors.email : null}
          id="email"
          name="email"
          placeholder="Email Address"
          type="email"
          value={values.email}
          onChange={handleChange}
        />
        <Form.Input
          className={styles.passwordInput}
          data-cy={`${DATA_CY}-password-input`}
          error={errors.password && touched.password
            ? errors.password : null}
          id="password"
          name="password"
          placeholder="Password"
          type="password"
          value={values.password}
          onChange={handleChange}
        />
        <Link className={styles.requestPasswordLink} to="/newPassword">
Request new password
        </Link>
        <Button
          primary
          data-cy={`${DATA_CY}-submit-button`}
          size="big"
          type="submit"
        >
          Log In
        </Button>
      </Form>
    )}
  </Formik>
);

LogInForm.propTypes = {
  login: PropTypes.func.isRequired,
};

export default LogInForm;

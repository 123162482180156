import moment from 'moment';
import * as Yup from 'yup';

const PAST_HOURS_THRESHOLD = 72;
const DEFAULT_MILAGE_TYPE = 'car_or_motorbike';

const expensesSchema = propName => ({
  [propName]: Yup.number().min(0, 'Amount cannot be negative'),
  [`${propName}_description`]: Yup.string().when(propName, {
    is: value => !!value,
    then: Yup.string().trim().ensure().min(10, 'Description must be at least 10 characters long'),
    otherwise: Yup.string().nullable(),
  }),
  [`${propName}_type`]: Yup.string().when(propName, {
    is: value => !!value,
    then: Yup.string().nullable().required('Type is required'),
    otherwise: Yup.string().nullable(),
  }),
});

// eslint-disable-next-line import/prefer-default-export
export const extraDetailsValidationSchema = Yup.object({
  auditor_pay_per_audit: Yup.number(),
  date_of_visit: Yup.date()
    .nullable()
    .test(
      'is-future',
      'You can\'t submit an audit in the future',
      value => (value ? moment(value).isBefore(moment.utc()) : true),
    )
    .test(
      'is-past',
      `You can't submit an audit that is over ${PAST_HOURS_THRESHOLD} hours old`,
      value => (value ? moment(value).isAfter(moment.utc().subtract(PAST_HOURS_THRESHOLD, 'hours')) : true),
    )
    .required('Date & time of visit is required'),
  entrance_charge: Yup.number(),
  mileage: Yup.number().min(0, 'Mileage cannot be negative'),
  purchase_cost: Yup.number().min(0, 'Purchase cost cannot be negative'),
  ...expensesSchema('reclaimable_expenses'),
  ...expensesSchema('reclaimable_expenses_2'),
  ...expensesSchema('reclaimable_expenses_3'),
  ...expensesSchema('reclaimable_expenses_4'),
  ...expensesSchema('travel_expenses'),
  mileage_type: Yup.string().default(DEFAULT_MILAGE_TYPE).nullable(false),
  mileage_description: Yup.string().nullable(),
  expenses_receipts: Yup.array().of(Yup.string()).when(['reclaimable_expenses', 'reclaimable_expenses_2', 'reclaimable_expenses_3', 'reclaimable_expenses_4'], {
    is: (expenses1, expenses2, expenses3, expenses4) => !!expenses1 || !!expenses2 || !!expenses3 || !!expenses4,
    then: Yup.array().of(Yup.string()).min(1, 'Images are required'),
    otherwise: Yup.array().of(Yup.string()).notRequired(),
  }),
  travel_expenses_images: Yup.array().of(Yup.string()).when('travel_expenses', {
    is: value => !!value,
    then: Yup.array().of(Yup.string()).min(1, 'Images are required'),
    otherwise: Yup.array().of(Yup.string()).notRequired(),
  }),
});

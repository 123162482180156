import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Accordion, Checkbox, Pagination } from 'semantic-ui-react';

import styles from './AuditsAccordion.module.scss';
import AssignedAuditsAccordion from './AuditsAccordionContent/AssignedAuditsAccordion.component';
import FinishedAuditsAccordion from './AuditsAccordionContent/FinishedAuditsAccordion.component';

const DATA_CY = 'audits-accordion';
const ROW_SELECTOR_NAME = 'row-selector';

const AuditsAccordion = ({
  currentPage,
  data,
  onPageChange,
  onRowSelectionChange,
  pages,
  rowSelector,
}) => {
  const [activePanelIndex, setActivePanelIndex] = useState(-1);
  const [selectedRows, setSelectedRows] = useState([]);

  const handleSelectFilter = (audit, checked) => {
    const newSelectedRows = checked
      ? [...selectedRows, audit]
      : selectedRows.filter(row => row.id !== audit.id);
    setSelectedRows(newSelectedRows);

    if (onRowSelectionChange) {
      onRowSelectionChange(newSelectedRows);
    }
  };

  const panels = data.map((audit, index) => ({
    key: index,
    title: {
      content: (
        <Accordion.Title className={styles.title} data-cy={`${DATA_CY}-${index}`}>
          <div>
            <span className={styles.auditIcon} />
            {audit.site_name}
          </div>
        </Accordion.Title>
      ),
      className: styles.accordionTitle,
      icon: rowSelector ? (
        <div className={styles.checkboxContainer}>
          <Checkbox
            id={index}
            name={ROW_SELECTOR_NAME}
            onChange={(_e, { checked }) => handleSelectFilter(audit, checked)}
          />
        </div>
      ) : null,
    },
    content: {
      content: audit.status === 'assigned'
        ? <AssignedAuditsAccordion data={audit} />
        : <FinishedAuditsAccordion data={audit} />,
      className: styles.accordionContent,
    },
  }));

  return (
    <div className={styles.container}>
      <Accordion
        activeIndex={activePanelIndex}
        className={styles.accordion}
        panels={panels}
        onTitleClick={(e, titleData) => {
          if (e.target.name !== ROW_SELECTOR_NAME) {
            setActivePanelIndex(oldStatus => (oldStatus === titleData.index ? -1 : titleData.index));
          }
        }}
      />
      <Pagination
        activePage={currentPage}
        totalPages={pages}
        onPageChange={(_e, { activePage }) => onPageChange(activePage)}
      />
    </div>
  );
};

AuditsAccordion.defaultProps = {
  currentPage: 1,
  data: [],
  onPageChange: null,
  onRowSelectionChange: null,
  pages: 0,
  rowSelector: false,
};

AuditsAccordion.propTypes = {
  currentPage: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.object),
  pages: PropTypes.number,
  rowSelector: PropTypes.bool,
  onPageChange: PropTypes.func,
  onRowSelectionChange: PropTypes.func,
};

export default AuditsAccordion;

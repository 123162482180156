import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Popup } from 'semantic-ui-react';

import { sitesAPI } from '../../../../api';
import { DateTimeFormat } from '../../../../constants';
import { LoadingPlaceholder, LoadingPlaceholderType } from '../../../common';
import { uuidType } from '../../../types';
import styles from './SiteTooltip.module.scss';

const SiteTooltip = ({
  id,
  position,
  trigger,
}) => {
  const [enabled, setEnabled] = useState(false);

  const { data } = useQuery(
    [id],
    () => sitesAPI.tooltip({ entityId: id }),
    { enabled: enabled && Boolean(id) },
  );

  return (
    <Popup
      content={data ? (
        (data.audit_history?.length > 0)
          ? data.audit_history.map((item, itemIndex) => (
            <div key={itemIndex}>
              <div className={styles.tooltip}>
                {`${item.auditor_name} (${item.auditor_email})`}
              </div>
              <div>
                {item.date_of_visit ? moment(item.date_of_visit).format(DateTimeFormat.DAY_MONTH_YEAR) : ''}
              </div>
              <div>
                {item.result ? item.result.toUpperCase() : ''}
              </div>
            </div>
          )) : 'No previous audits'
      ) : (
        <LoadingPlaceholder count={3} type={LoadingPlaceholderType.PARAGRAPH} />
      )}
      popperDependencies={[!!data]}
      position={position}
      trigger={trigger}
      onClose={() => setEnabled(false)}
      onOpen={() => setEnabled(true)}
    />
  );
};

SiteTooltip.defaultProps = {
  id: null,
  position: 'right center',
};

SiteTooltip.propTypes = {
  trigger: PropTypes.node.isRequired,
  id: uuidType,
  position: PropTypes.string,
};

export default SiteTooltip;

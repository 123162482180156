import { UserRole } from '../constants';
import {
  auditorBankInfoValidation, auditorNationalityValidation, mfaSetupValidation,
  profilePictureValidation,
} from './accountInfo';

const auditorValidationMessages = ({ user }) => {
  const bankValidationMessage = auditorBankInfoValidation({ values: user });
  const imageValidationMessage = profilePictureValidation({ values: user });
  const nationalityValidationMessage = auditorNationalityValidation({ values: user });

  return bankValidationMessage || imageValidationMessage || nationalityValidationMessage;
};

const _mfaValidationMessages = ({ user }) => mfaSetupValidation({ values: user });

// eslint-disable-next-line consistent-return
const _mfaValidationMessage4AMCS = ({ user, mfa4amcs }) => {
  if (mfa4amcs) {
    return _mfaValidationMessages({ user });
  }
};

const messages = {
  [UserRole.AUDITOR]: auditorValidationMessages,
  [UserRole.DATA]: _mfaValidationMessages,
  [UserRole.CLIENT_SERVICES]: _mfaValidationMessage4AMCS,
  [UserRole.AREA_MANAGER]: _mfaValidationMessage4AMCS,
  [UserRole.CLIENT]: () => null,
};

const handleValidationModal = ({
  user,
  role,
  mfa4amcs,
}) => {
  const getValidationMessage = messages[user.role || role];

  if (getValidationMessage) {
    return getValidationMessage({ user, mfa4amcs });
  }

  return null;
};

export default handleValidationModal;

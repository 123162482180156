import { Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'semantic-ui-react';

import { enumOptionsSelector } from '../../../../../../../../state/constants/selectors';

const DATA_CY = 'survey-question-config-header';

const questionCategoryOptionsSelector = state => enumOptionsSelector(state, 'question_category');

const SurveyQuestionConfigHeader = ({ index }) => {
  const questionCategoryOptions = useSelector(questionCategoryOptionsSelector);

  return (
    <>
      <Field name={`questions.${index}.title`}>
        {({
          field: { name, value },
          form: { handleChange },
          meta: { error },
        }) => (
          <Form.Input
            fluid
            required
            data-cy={`${DATA_CY}-${index}-title-input`}
            error={error}
            id={name}
            label="Title"
            value={value}
            onChange={handleChange}
          />
        )}
      </Field>
      <Field name={`questions.${index}.help`}>
        {({
          field: { name, value },
          form: { handleChange },
          meta: { error },
        }) => (
          <Form.Input
            data-cy={`${DATA_CY}-${index}-help-input`}
            error={error}
            id={name}
            label="Help text"
            value={value}
            onChange={handleChange}
          />
        )}
      </Field>
      <Field name={`questions.${index}.auditor_information`}>
        {({
          field: { name, value },
          form: { handleChange },
          meta: { error },
        }) => (
          <Form.Input
            data-cy={`${DATA_CY}-${index}-auditor-information`}
            error={error}
            id={name}
            label="Auditor Information"
            value={value}
            onChange={handleChange}
          />
        )}
      </Field>
      <Field name={`questions.${index}.approving_information`}>
        {({
          field: { name, value },
          form: { handleChange },
          meta: { error },
        }) => (
          <Form.Input
            data-cy={`${DATA_CY}-${index}-approving-information`}
            error={error}
            id={name}
            label="Approving Information"
            value={value}
            onChange={handleChange}
          />
        )}
      </Field>
      <Field name={`questions.${index}.required`}>
        {({
          field: { name, value },
          form: { handleChange },
          meta: { error },
        }) => (
          <Form.Checkbox
            checked={value}
            data-cy={`${DATA_CY}-${index}-required-checkbox`}
            error={error}
            id={name}
            label="Required"
            onChange={handleChange}
          />
        )}
      </Field>
      <Field name={`questions.${index}.category`}>
        {({
          field: { name, value },
          form: { values, setFieldValue },
          meta: { error },
        }) => (
          <Form.Select
            clearable
            data-cy={`${DATA_CY}-${index}-category-input`}
            disabled={values.questions[index].id !== undefined}
            error={error}
            id={name}
            label="Category"
            options={questionCategoryOptions}
            value={value}
            onChange={(_e, { value: selectValue }) => setFieldValue(name, selectValue.length > 0 ? selectValue : null)}
          />
        )}
      </Field>
    </>
  );
};

SurveyQuestionConfigHeader.propTypes = {
  index: PropTypes.number.isRequired,
};

export default SurveyQuestionConfigHeader;

// import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Breadcrumb } from 'semantic-ui-react';

import history from '../../../../../history';
import styles from './SurveyStepHeader.module.scss';

const steps = {
  QUESTIONS: 'questions',
  BRANCHING: 'branching',
  VALIDATION: 'validation',
  REPORTS: 'reports',
  LOGIC: 'logic',
  MATCHING: 'matching',
};

const breadcrumbSteps = (entityId, revisionId, currentStep) => {
  const activeIndex = entityId ? Object.values(steps).indexOf(currentStep) : 0;

  return Object.values(steps).map((value, index) => {
    let className;
    if (index === activeIndex) {
      className = styles.currentBreadcrumbStep;
    } else if (index < activeIndex) {
      className = styles.activeBreadcrumbStep;
    } else {
      className = entityId ? styles.activeBreadcrumbStep : styles.inactiveBreadcrumbStep;
    }

    return {
      active: index === activeIndex,
      className,
      content: value,
      key: value,
      onClick: () => (entityId ? history.push(`/surveys/${entityId}/revisions/${revisionId}/${value}`) : null),
    };
  });
};

const SurveyStepHeader = (/* { blocked } */) => {
  const { entityId, revisionId, surveyStep } = useParams();

  return (
    <>
      <span className={styles.headerLabel}>
        {revisionId ? 'Edit Revision' : 'New Revision'}
      </span>
      <Breadcrumb
        icon="right angle"
        sections={breadcrumbSteps(entityId, revisionId, surveyStep)}

      />
      {/* FIXME: [SURVEY BLOCKED] uncomment when ServeLegal agrees */}
      {/* {blocked ? (
        <>
          <Divider />
          <div className={styles.blockedWarning}>
            <Icon name="warning sign" />
            {'This revision is blocked because it has already been used in at least one audit'}
          </div>
        </>
      ) : null} */}
    </>
  );
};

// SurveyStepHeader.defaultProps = {
//   blocked: false,
// };

// SurveyStepHeader.propTypes = {
//   blocked: PropTypes.bool,
// };

export default SurveyStepHeader;

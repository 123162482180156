import { useFeatureFlag } from 'configcat-react';
import React from 'react';
import { Form } from 'semantic-ui-react';

import { FormFieldImageUploadWrapper } from '../../../../common';
import FormLayout from '../../RegisterAuditorFormLayout/RegisterAuditorFormLayout.component';
import styles from './RegisterAuditorFormStepSix.module.scss';

const RegisterAuditorFormStepSix = () => {
  const { value: auditorApplicationUpgrade } = useFeatureFlag('auditorApplicationUpgrade', false);

  return (
    <FormLayout subtitle="1 of 2" title="Upload images">
      <span className={styles.titleQuestion}>
        {'A scanned copy of your passport'}
      </span>
      <p className={styles.noteQuestion}>
        {'To successfully process your application, we need a copy of your passport to guarantee your right to work in the UK. It will only be reviewed at the time of request, and then only our payroll department will be able to access it to process you as an employee.'}
      </p>
      <Form.Field width={16}>
        {auditorApplicationUpgrade ? (
          <FormFieldImageUploadWrapper
            required
            thumbnail
            accept={['image/jpg', 'image/png']}
            name="passport"
          />)
          : (
            <FormFieldImageUploadWrapper
              required
              name="passport"
            />
          )}
      </Form.Field>
      <span className={styles.noteQuestion}>
        {'Allowed file types: .jpg, .png'}
      </span>
    </FormLayout>
  );
};

export default RegisterAuditorFormStepSix;

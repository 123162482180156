import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { Card, Checkbox, Divider, Form, Grid } from 'semantic-ui-react';

import { ReportType } from '../../../../../../constants';
import { questionType, reportsType } from '../../../../../types';
import { reportsValidationSchema } from '../helpers';
import styles from './SurveyReportsEditor.module.scss';

const SurveyReportsEditor = ({ questions, reports, submitFormRef, onSubmit }) => {
  const [globalCheckboxesState, setGlobalCheckboxesState] = useState(
    Object.values(ReportType).reduce((previousValue, currentValue) => ({
      ...previousValue,
      [currentValue]: false,
    }), {}),
  );
  const alert = useAlert();

  return (
    <Card fluid>
      <Card.Content className={styles.scroll}>
        <Formik
          initialValues={{ reports }}
          validationSchema={reportsValidationSchema}
          onSubmit={onSubmit}
        >
          {({ values, handleSubmit, setFieldValue, errors, isValid }) => {
            const submit = () => {
              if (!isValid) {
                alert.error(
                  <pre>
                    {JSON.stringify(errors, null, 2)}
                  </pre>,
                );
              } else {
                handleSubmit();
              }
            };
            const handleChange = type => (_event, data) => {
              let questionsToInclude = [...values.reports[type]];

              if (data.value) {
                // If the event has a 'value' attribute, it's the checkbox of a question
                if (data.checked) {
                  questionsToInclude.push(data.value);
                } else {
                  questionsToInclude = questionsToInclude.filter(value => value !== data.value);
                }

                setGlobalCheckboxesState(oldState => ({
                  ...oldState,
                  [type]: false,
                }));
              } else {
                // If the event doesn't have a 'value' attribute, it's a global checkbox (full or mini)
                questionsToInclude = data.checked ? questions.map(question => question.id) : [];

                setGlobalCheckboxesState(oldState => ({
                  ...oldState,
                  [type]: data.checked,
                }));
              }

              setFieldValue(`reports.${type}`, questionsToInclude);
            };

            return (
              <Form noValidate>
                <button ref={submitFormRef} hidden type="submit" onClick={submit} />
                <Grid columns={4}>
                  <Grid.Row>
                    <Grid.Column>
                      ID
                    </Grid.Column>
                    <Grid.Column>
                      Question
                    </Grid.Column>
                    <Grid.Column>
                      <Checkbox
                        checked={globalCheckboxesState.full}
                        label="Full"
                        onChange={handleChange(ReportType.FULL)}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Checkbox
                        checked={globalCheckboxesState.mini}
                        label="Mini"
                        onChange={handleChange(ReportType.MINI)}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Divider />
                  {questions.map((question, questionIndex) => (
                    <Grid.Row key={questionIndex}>
                      <Grid.Column>
                        {question.internal_id}
                      </Grid.Column>
                      <Grid.Column>
                        {question.title}
                      </Grid.Column>
                      <Grid.Column>
                        <Checkbox
                          checked={values.reports.full?.includes(question.id)}
                          value={question.id}
                          onChange={handleChange(ReportType.FULL)}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Checkbox
                          checked={values.reports.mini?.includes(question.id)}
                          value={question.id}
                          onChange={handleChange(ReportType.MINI)}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  ))}
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Card.Content>
    </Card>
  );
};

SurveyReportsEditor.defaultProps = {
  questions: [],
  reports: Object.values(ReportType).reduce((previousValue, currentValue) => ({
    ...previousValue,
    [currentValue]: [],
  }), {}),
};

SurveyReportsEditor.propTypes = {
  submitFormRef: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  questions: PropTypes.arrayOf(questionType),
  reports: reportsType,
};

export default SurveyReportsEditor;

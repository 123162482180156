import React from 'react';
import { Tab } from 'semantic-ui-react';

import TaxonomyDetailsDocuments from '../../Taxonomy/TaxonomyDetails/TaxonomyDetailsDocuments/TaxonomyDetailsDocuments.component';
import TipsAndDocsNewsfeed from '../TipsAndDocsNewsfeed/TipsAndDocsNewsfeed.component';
import TipsAndDocsDownload from './TipsAndDocsDownload/TipsAndDocsDownload.component';
import styles from './TipsAndDocsMobile.module.scss';

const DATA_CY = 'tips-and-docs';

const panes = () => [
  {
    menuItem: 'Essential info',
    render: () => <TaxonomyDetailsDocuments/> },
  {
    menuItem: 'Audit briefings',
    render: () => <TipsAndDocsDownload /> },
  {
    menuItem: 'Newsfeed',
    render: () => <TipsAndDocsNewsfeed /> },
];

const TipsAndDocsMobile = () => (
  <Tab
    data-cy={`${DATA_CY}-tabs`}
    menu={{ secondary: true, pointing: true, className: styles.tab }}
    panes={panes()}
  />
);

export default TipsAndDocsMobile;

import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';

import styles from './RegisterAuditorCongrats.module.scss';

const DATA_CY = 'register-congrats';

const RegisterAuditorCongrats = () => (
  <div className={styles.container} data-cy={DATA_CY}>
    <span className={styles.iconFirework} data-cy={`${DATA_CY}-icon`}></span>
    <h5 className={styles.title}>
      {'Thanks for applying!'}
    </h5>
    <h6 className={styles.subtitle}>
      {'We will send you an email informing you of our decision.'}
    </h6>
    <p className={styles.note}>
      {'Our aim is to respond to you within 3 working days. If successful, further instruction will be given in the email you receive.'}
    </p>
    <Link to={'/'}>
      <Button
        primary
        data-cy={`${DATA_CY}-back-button`}
        size="big"
      >
        {'Back to the website'}
        <Icon className={styles.iconArrow} name="right arrow" />
      </Button>
    </Link>
  </div>
);

export default RegisterAuditorCongrats;

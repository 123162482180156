import queryString from 'query-string';

const parseOptions = {
  arrayFormat: 'comma',
  parseNumbers: false, // if we parse numbers, those starting with 0s will remove them (for example, post codes would fail)
  parseBooleans: true,
};

const stringifyOptions = {
  arrayFormat: 'comma',
  skipNull: true,
  skipEmptyString: true,
};

export const parse = (string, options = parseOptions) => queryString.parse(string, options);

export const stringify = (object, options = stringifyOptions) => queryString.stringify(object, options);

export const removeLimitFromQuery = query => {
  const { limit, ...rest } = query;
  return rest;
};

import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import getFieldStatus from '../../../../../fieldLogic/audit';
import { useUserRole } from '../../../../../hooks';
import { taxonomyTermSelector } from '../../../../../state/constants/selectors';
import {
  FormFieldSelectWrapper,
  FormFieldWrapper,
  TaxonomyTermOptions,
} from '../../../../common';
import { uuidType } from '../../../../types';

const queryTypeTaxonomySelector = state => taxonomyTermSelector(state, 'query_type');

const AuditDetailsQuery = ({ labelStyle, revisionId, valueStyle }) => {
  const role = useUserRole();
  const { values } = useFormikContext();
  const queryTypeTaxonomyTerm = useSelector(queryTypeTaxonomySelector);

  return (
    <>
      <FormFieldWrapper
        label="Query Notes"
        labelStyle={labelStyle}
        name="query_notes"
        valueStyle={valueStyle}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      <FormFieldSelectWrapper
        label="Query Type"
        labelStyle={labelStyle}
        name="query_category"
        options={TaxonomyTermOptions(queryTypeTaxonomyTerm)}
        valueStyle={valueStyle}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      <FormFieldWrapper
        label="Email note"
        labelStyle={labelStyle}
        name="email_note"
        type="textarea"
        valueStyle={valueStyle}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

    </>
  );
};

AuditDetailsQuery.defaultProps = {
  labelStyle: null,
  revisionId: null,
  valueStyle: null,
};

AuditDetailsQuery.propTypes = {
  labelStyle: PropTypes.string,
  revisionId: uuidType,
  valueStyle: PropTypes.string,
};

export default AuditDetailsQuery;

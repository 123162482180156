import download from 'downloadjs';
import React from 'react';
import { useAlert } from 'react-alert';
import { Button } from 'semantic-ui-react';

import { errorAPI, taxonomyAPI } from '../../../../../api';
import styles from './TipsAndDocsDownload.module.scss';

const DATA_CY = 'tips-and-docs-download';

const TipsAndDocsDownload = () => {
  const alert = useAlert();

  const downloadBulkOfDocs = async () => {
    taxonomyAPI.downloadAllDocs()
      .then(data => {
        const reader = new FileReader();
        reader.readAsBinaryString(data);
        reader.onload = () => {
          download(reader.result, 'documents.zip');
        };
      })
      .catch(error => {
        alert.error(`Error downloading documents: ${error?.message}`);
        errorAPI.sendError(error?.message, '');
      });
  };

  return (
    <div className={styles.body} data-cy={`${DATA_CY}-download-card`}>
      <h3>
        {'Download Audit Briefings'}
      </h3>
      <p>
        {'You can download a list of audits divided into themes and various places. They will be very useful to get an idea of ​​what we ask our audits.'}
      </p>
      <div className={styles.buttonContainer}>
        <Button
          className={styles.buttonDownload}
          data-cy={`${DATA_CY}-download-button`}
          onClick={() => downloadBulkOfDocs()}
        >
          <span className={styles.iconDownload}/>
          {'Download All Briefings'}
        </Button>
      </div>
    </div>
  );
};

export default TipsAndDocsDownload;

import * as Sentry from '@sentry/react';
import React from 'react';
import { Redirect, Route as ReactRouterDomRoute, Router, Switch } from 'react-router-dom';

import ChangePassword from '../components/pages/ChangePassword/ChangePassword.component';
import Dashboard from '../components/pages/Dashboard/Dashboard.component';
import LogIn from '../components/pages/LogIn/LogIn.component';
import RegisterAuditor from '../components/pages/RegisterAuditor/RegisterAuditor.component';
import RequestPassword from '../components/pages/RequestPassword/RequestPassword.component';
import VerifyEmail from '../components/pages/VerifyEmail/VerifyEmail.component';
import history from '../history';
import AppRoute from './routes';

// Enable Sentry tracing on routes
const Route = Sentry.withSentryRouting(ReactRouterDomRoute);

const AppRouter = () => (
  <Router history={history}>
    <Switch>
      <Route
        exact
        component={LogIn}
        path={AppRoute.LOGIN.path}
      />
      <Route
        exact
        component={ChangePassword}
        path={AppRoute.CHANGE_PASSWORD.path}
      />
      <Route
        exact
        component={VerifyEmail}
        path={AppRoute.VERIFY_EMAIL.path}
      />
      <Route
        exact
        component={RequestPassword}
        path={AppRoute.REQUEST_NEW_PASSWORD.path}
      />
      <Route
        exact
        component={RegisterAuditor}
        path={AppRoute.REGISTER_AUDITOR.path}
      />
      <Route
        component={Dashboard}
        path={AppRoute.DASHBOARD.path}
      />
      <Redirect to={AppRoute.LOGIN.path}/>
    </Switch>
  </Router>
);

export default AppRouter;

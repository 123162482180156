import { Field, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { Button, Form, Grid, Icon, Label, Modal } from 'semantic-ui-react';
import * as Yup from 'yup';

import { entitiesAPI, errorAPI } from '../../../../api';
import { Entity } from '../../../../constants';
import {
  FormFieldCheckboxWrapper,
  FormFieldWrapper } from '../../../common';
import AuditorTooltip from '../AuditorTooltip/AuditorTooltip.component';
import styles from './AuditorsNotificationModal.module.scss';

const AuditorsNotificationModal = ({ open, onCancel, onConfirm, selectedAuditors }) => {
  const alert = useAlert();

  const buildAuditorSearchOptions = auditors => auditors.map(auditor => ({
    description: (
      <AuditorTooltip
        id={auditor.id}
        position={'bottom center'}
        trigger={<Icon circular name="info" size="small" />}
      />
    ),
    text: auditor.name,
    value: auditor.id,
  }));

  const [filteredAuditors, setFilteredAuditors] = useState([]);

  const searchAuditors = wildcard => {
    entitiesAPI.fetchAll(Entity.AUDITORS, { name: wildcard, auditor_status: 'active' })
      .then(auditors => { setFilteredAuditors(buildAuditorSearchOptions(auditors.items)); })
      .catch(error => {
        alert.error(`Error fetching active auditors: ${error.message}`);
        errorAPI.sendError(error.message, error.stack);
      });
  };

  return (
    <Modal
      closeOnDimmerClick={true}
      open={open}
      size="fullscreen"
      onClose={onCancel}
    >
      <Modal.Header>
        {'Auditors Notification'}
      </Modal.Header>
      <Formik
        initialValues={{ all_auditors: false, ids: selectedAuditors || [] }}
        validationSchema={Yup.object({
          notification_title: Yup.string().trim().ensure().min(1, 'Required'),
          notification_message: Yup.string().trim().ensure().min(1, 'Required'),
        })}
        onSubmit={values => {
          const mapIds = values.ids.map(item => item.value);
          const printIds = selectedAuditors.length === 0 ? mapIds : values.ids;
          const parsedValues = {
            ids: printIds,
            all_auditors: values.all_auditors,
            auditor_ids: true,
            notification_title: values.notification_title,
            notification_message: values.notification_message,
          };
          onConfirm(parsedValues);
        }}
      >
        {({ handleSubmit, values, setFieldValue }) => (
          <>
            <Modal.Content scrolling>
              <Grid columns={1}>
                <Grid.Column className={styles.contentColumn}>
                  <Form>
                    {selectedAuditors.length === 0 ? (
                      <>
                        <FormFieldCheckboxWrapper
                          toggle
                          label={'Notify all active auditors'}
                          name="all_auditors"
                        />
                        {values.all_auditors !== true && (
                          <div className={styles.auditorsContainer}>
                            <Field
                              as={Form.Select}
                              icon="search"
                              label="Notify specific auditors"
                              name="ids"
                              options={filteredAuditors}
                              search={ops => ops}
                              onBlur={null}
                              onChange={(_e, { value }) => {
                                const selectedAuditor = filteredAuditors.find(auditor => auditor.value === value);
                                setFieldValue('ids', [...values.ids, selectedAuditor]);
                              }}
                              onSearchChange={(_event, { searchQuery }) => searchAuditors(searchQuery)}
                            />
                            {values?.ids?.map(item => (
                              <Label key={item.value} className={styles.tagContent}>
                                {item.text}
                                <Icon
                                  name="delete"
                                  onClick={() => setFieldValue(
                                    'ids',
                                    values.ids.filter(auditor => auditor.value !== item.value),
                                  )}
                                />
                              </Label>
                            ))}
                          </div>)}
                      </>) : null
                    }

                    <FormFieldWrapper
                      required
                      label="Title"
                      name="notification_title"
                    />
                    <FormFieldWrapper
                      required
                      label="Message"
                      name="notification_message"
                      type="textarea"
                    />
                  </Form>
                </Grid.Column>
              </Grid>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={onCancel}>
                {'Cancel'}
              </Button>
              <Button primary onClick={handleSubmit}>
                {'Send'}
              </Button>
            </Modal.Actions>
          </>
        )
        }
      </Formik>
    </Modal>
  );
};

AuditorsNotificationModal.defaultProps = {
  selectedAuditors: [],
};

AuditorsNotificationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  selectedAuditors: PropTypes.arrayOf(PropTypes.string),
};

export default AuditorsNotificationModal;

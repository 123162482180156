import React from 'react';

import { FormFieldWrapper } from '../../../../common';
import FormLayout from '../../RegisterAuditorFormLayout/RegisterAuditorFormLayout.component';
import styles from './RegisterAuditorFormStepNineExtra.module.scss';

const RegisterAuditorFormStepNineExtra = () => (

  <FormLayout>
    <span className={styles.titleQuestion}>
      {'Additional address'}
    </span>
    <span className={styles.note}>
      {'If you have a third address in which you have frequent access or reside, please let us know'}
    </span>
    <FormFieldWrapper
      required
      name="tertiary_address_1"
      placeholder="Address 1"
    />
    <FormFieldWrapper
      required
      name="tertiary_address_2"
      placeholder="Address 2"
    />
    <FormFieldWrapper
      required
      name="tertiary_city"
      placeholder="City"
    />
    <FormFieldWrapper
      required
      name="tertiary_post_code"
      placeholder="Postcode/Eircode"
    />
    <FormFieldWrapper
      required
      name="tertiary_county"
      placeholder="County"
    />
  </FormLayout>
);

export default RegisterAuditorFormStepNineExtra;

/* eslint-disable no-unused-vars */
import { Formik, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import ReactAvatarEditor from 'react-avatar-editor';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { Button, Grid, Modal } from 'semantic-ui-react';

import { multipartAPI } from '../../../api';
import { Entity } from '../../../constants';
import { useUserRole } from '../../../hooks';
import { fetchUserInfo } from '../../../state/constants/actionCreators';
import FormFieldImageUploadWrapper from '../FormFieldWrappers/FormFieldImageUploadWrapper.component';
import styles from './FullScreenImageModal.module.scss';

const FullScreenImageModal = ({ imgSrc, entityId, name, onClose, refetch, data }) => {
  const alert = useAlert();

  const role = useUserRole();
  const dispatch = useDispatch();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [position, setPosition] = useState({ x: 0.5, y: 0.5 });
  const [imageSize, setImageSize] = useState(null);
  const editorRef = useRef(null);

  const handleScale = e => {
    // eslint-disable-next-line no-shadow
    const scale = parseFloat(e.target.value);
    setScale(scale);
  };

  const rotation = ({ e, grad }) => {
    e.preventDefault();
    setRotate(rotate + grad);
  };

  const { mutate: auditorMutate } = useMutation(
    multipartAPI.update,
    {
      onSuccess: () => {
        alert.success('Image updated');
        dispatch(fetchUserInfo({ role }));
        refetch();
      },
      onError: error => {
        alert.error(`Error updating auditor: ${error.message}`);
      },
    },
  );

  const onImageChange = async values => {
    const formData = new FormData();
    const { identification, image, passport, visa, ...filteredValues } = values;

    Object.entries(filteredValues).forEach(([key, value]) => formData.append(key, value));

    return auditorMutate({
      entity: Entity.AUDITORS,
      entityId,
      payload: formData,
    });
  };

  useEffect(() => {
    if (imgSrc) {
      const img = new Image();
      img.src = imgSrc;
      img.addEventListener('load', function onLoad() {
        const sizes = {
          width: this.naturalWidth,
          height: this.naturalHeight,
        };
        setImageSize(sizes);
      });
    }
  }, [imgSrc]);

  return (
    <Modal
      closeOnDimmerClick={true}
      open={imgSrc}
      onClose={onClose}
    >
      <Modal.Content className={styles.content}>
        <ReactAvatarEditor
          ref={editorRef}
          border={1}
          color={[95, 130, 152, 1]}
          height={imageSize?.height > 500 ? 500 : imageSize?.height}
          image={imgSrc}
          position={position}
          rotate={rotate}
          scale={scale}
          width={imageSize?.width > 800 ? 800 : imageSize?.width}
          onPositionChange={setPosition}
        />
        <Formik
          initialValues={data}
          onSubmit={values => onImageChange(values)}
        >
          {({ handleSubmit }) => (
            <>
              <div className={styles.imageUpload}>
                <FormFieldImageUploadWrapper
                  hideImage
                  thumbnail
                  accept={['image/jpg', 'image/png']}
                  name={name}
                  size={'large'}
                />
              </div>

              <h5>
                {'Zoom:'}
              </h5>
              <input
                defaultValue="1"
                max="2"
                min="1"
                name="scale"
                step="0.01"
                type="range"
                onChange={handleScale}
              />

              <h5>
                {'Rotate:'}
              </h5>
              <Grid columns="equal">
                <Grid.Column>
                  <Button content="Left" icon="undo" labelPosition="left" onClick={e => rotation({ e, grad: -90 })} />
                </Grid.Column>
                <Grid.Column>
                  <Button content="Right" icon="redo" labelPosition="right" onClick={e => rotation({ e, grad: 90 })} />
                </Grid.Column>
              </Grid>
              <Modal.Actions className={styles.actions}>
                <Button primary type="submit" onClick={handleSubmit}>
                  {'Save'}
                </Button>
                <Button onClick={onClose}>
                  {'Close'}
                </Button>
              </Modal.Actions>
            </>
          )}
        </Formik>

      </Modal.Content>
    </Modal>
  );
};

FullScreenImageModal.defaultProps = {
  data: null,
  entityId: null,
  imgSrc: null,
  name: null,
};

FullScreenImageModal.propTypes = {
  name: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object,
  entityId: PropTypes.number,
  imgSrc: PropTypes.string,
};

export default FullScreenImageModal;

import getCurrencySymbol from 'currency-symbols';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { taxonomyTermSelector } from '../../../../../state/constants/selectors';
import { calculatePays } from '../../AuditDetails/helpers';

const payTaxonomySelector = state => taxonomyTermSelector(state, 'pay_rate');

const PaysCellFormatter = ({ cell, type }) => {
  const values = cell.row.original;
  const payTaxonomyTerms = useSelector(payTaxonomySelector);
  const { holidayPay, totalPay } = calculatePays({
    pay: values.auditor_pay_per_audit,
    payRateTaxonomy: payTaxonomyTerms,
    auditorBankCountry: values.auditor_bank_country,
    date: new Date().toISOString(),
  });

  const withCurrency = value => `${value} ${getCurrencySymbol(cell.row.original.order_currency) || cell.row.original.order_currency}`;

  return (
    <span>
      {!type ? '' : type === 'holiday_pay' ? withCurrency(holidayPay) : withCurrency(totalPay)}
    </span>
  );
};

PaysCellFormatter.propTypes = {
  cell: PropTypes.object.isRequired,
  type: PropTypes.oneOf(['holiday_pay', 'total_pay']).isRequired,
};

export default PaysCellFormatter;

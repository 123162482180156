import PropTypes from 'prop-types';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

import { getEntityMetadataByValue, UserRole } from '../../../../constants';
import getFieldStatus from '../../../../fieldLogic/audit';
import FieldStatus from '../../../../fieldLogic/fieldStatus';
import { useUserRole } from '../../../../hooks';
import { SLHeader } from '../../../common';
import { RoleChecker } from '../../../layout';
import styles from './AuditDetailsHeader.module.scss';

const DATA_CY = 'audit-details-header';

const AuditDetailsHeader = ({ data, onCancel, onSave }) => {
  const role = useUserRole();
  const { entity, entityId } = useParams();
  const metadata = getEntityMetadataByValue(entity);

  return (
    <SLHeader
      dataCy={DATA_CY}
      title={entityId ? `Edit ${data?.internal_id || metadata?.singular || entity}` : `Add ${metadata?.singular || entity}`}
    >
      <RoleChecker
        allowedRoles={[UserRole.DATA]}
      >
        <Link data-cy={`${DATA_CY}-revisions-link`} to={`/${entity}/${entityId}/revisions`}>
          <span className={styles.revisions}>
            {'Revisions'}
          </span>
        </Link>
      </RoleChecker>

      {getFieldStatus(role, data.status)('action_save') !== FieldStatus.HIDDEN ? (
        <Button
          primary
          className={styles.save}
          data-cy={`${DATA_CY}-save-button`}
          type="submit"
          onClick={onSave}
        >
          {'Save'}
        </Button>
      ) : null}
      <Button
        data-cy={`${DATA_CY}-cancel-button`}
        onClick={onCancel}
      >
        {'Cancel'}
      </Button>
    </SLHeader>
  );
};

AuditDetailsHeader.propTypes = {
  data: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default AuditDetailsHeader;

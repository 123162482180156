import React from 'react';
import { useParams } from 'react-router-dom';
import { Form, Grid } from 'semantic-ui-react';

import { FormFieldWrapper } from '../../../../../common';

const SurveyDetailsForm = () => {
  const { revisionId } = useParams();

  return (
    <Grid columns="equal">
      <Grid.Column width={12}>
        <Form.Group widths="equal">
          <FormFieldWrapper
            required
            label="Survey title"
            name="survey_title"
            readOnly={revisionId !== undefined}
          />

          <FormFieldWrapper
            required
            label="Revision name"
            name="name"
          />
        </Form.Group>
      </Grid.Column>
    </Grid>
  );
};

export default SurveyDetailsForm;

import PropTypes from 'prop-types';
import React from 'react';
import { useQuery } from 'react-query';
import { Button, Modal } from 'semantic-ui-react';

import { legalAPI } from '../../../../api';
import styles from './DashboardPrivacyModal.module.scss';

const DashboardPrivacyModal = ({ onCancel, onAccept }) => {
  const { data: activePrivacyNotice } = useQuery(
    'privacyNotice',
    () => legalAPI.fetchActivePrivacy(),
  );

  const { data: auditorPrivacyNotice } = useQuery(
    'auditorPrivacyNotice',
    () => legalAPI.fetchAuditorPrivacy(),
  );

  return auditorPrivacyNotice && !auditorPrivacyNotice.accepted ? (
    <Modal closeOnDimmerClick={true} open={true}>
      <Modal.Header>
        {'Privacy Notice'}
      </Modal.Header>
      <Modal.Content>
        <div>
        Serve Legal will use your personal data when you act as an auditor.
        When we do so we will treat your personal data with the upmost importance.
        Our Privacy Notice sets out how we use your personal data and our notice
        can be found
          {' '}
          <a className={styles.linkButton} href={activePrivacyNotice?.url} rel="noreferrer" target="_blank">
             here
          </a>
        . I confirm that I have read and understood how SL will use my personal data
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onCancel}>
          {'Deny'}
        </Button>
        <Button primary onClick={onAccept}>
          {'Accept'}
        </Button>
      </Modal.Actions>
    </Modal>
  ) : null;
};

DashboardPrivacyModal.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default DashboardPrivacyModal;

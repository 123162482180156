import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { useMutation } from 'react-query';
import { Button, Icon, List } from 'semantic-ui-react';

import { developerAPI } from '../../../api';
import { Entity } from '../../../constants';
import { View } from '../../layout';

const DATA_CY = 'developer-panel';

const DeveloperPanel = () => {
  const alert = useAlert();

  const [queryResult, setQueryResult] = useState(null);

  const { mutate: geocodeMutation } = useMutation(
    developerAPI.geocode,
    {
      onSuccess: response => {
        alert.info('Registers geocoded!');
        setQueryResult(response);
      },
      onError: error => {
        alert.error(`Error geocoding: ${error.message}`);
      },
    },
  );

  return (
    <View
      body={() => (
        <>
          <Button
            data-cy={`${DATA_CY}-geodoce-sites-button`}
            onClick={() => geocodeMutation({ entity: Entity.SITES })}
          >
            {'Geocode sites'}
          </Button>
          <Button
            data-cy={`${DATA_CY}-geodoce-auditors-button`}
            onClick={() => geocodeMutation({ entity: Entity.AUDITORS })}
          >
            {'Geocode auditors'}
          </Button>
          {queryResult ? (
            <>
              {queryResult.success?.length === 0 ? null : (
                <div>
                  <Icon name="checkmark"/>
                  {`${queryResult.success?.length} success.`}
                </div>
              )}
              {queryResult.failed?.length === 0 ? null : (
                <div>
                  <Icon name="warning" />
                  {`${queryResult.failed?.length} failed.`}
                  <List bulleted>
                    {queryResult.failed.map((row, index) => (
                      <List.Item key={index}>
                        {`${row.internal_id} - ${row.error}`}
                      </List.Item>
                    ))}
                  </List>
                </div>
              )}
            </>
          ) : null}
        </>)
      }
      dataCy={DATA_CY}
      header={() => 'Developer Panel'}
    />
  );
};

export default DeveloperPanel;

import PropTypes from 'prop-types';
import React from 'react';

import styles from './Register.module.scss';

const Register = ({ form: Form, title }) => (
  <div className={styles.root}>
    <div className={styles.logo} />
    <div className={styles.card}>
      <div className={styles.title}>
        {title}
      </div>
      <div className={styles.formContainer}>
        <Form />
      </div>
    </div>
  </div>
);

Register.propTypes = {
  form: PropTypes.oneOfType([PropTypes.func, PropTypes.elementType]).isRequired,
  title: PropTypes.string.isRequired,
};

export default Register;

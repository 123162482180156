import React from 'react';
import { useQuery } from 'react-query';

import { automatedExportsAPI } from '../../../../api';
import { LoadingPlaceholder, SingleLoadingPlaceholder } from '../../../common';
import { View } from '../../../layout';
import ClientsAutomatedExportsTable from './ClientsAutomatedExportsTable/ClientsAutomatedExportsTable.component';
import ClientsAutomatedExportsTableHeader from './ClientsAutomatedExportsTableHeader/ClientsAutomatedExportsTableHeader.component';

const DATA_CY = 'clients-automated-exports-table-view';

const ClientsAutomatedExportsTableView = () => {
  const { data } = useQuery(
    'fetchAllAutomatedExports',
    () => automatedExportsAPI.fetchAll(),
  );

  return (
    <View
      condensed
      body={data ? ClientsAutomatedExportsTable : LoadingPlaceholder}
      bodyProps={{ data: data?.items }}
      dataCy={DATA_CY}
      header={data ? ClientsAutomatedExportsTableHeader : SingleLoadingPlaceholder}
    />
  );
};

export default ClientsAutomatedExportsTableView;

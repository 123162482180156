import { Entity, EntityState, UserRole } from '../../../../constants';
import isMobile from '../../../../utils/isMobile';
import ArchivesTable from '../../Archives/ArchivesTable/ArchivesTable.component';
import ArchivesTablePagination from '../../Archives/ArchivesTable/ArchivesTablePagination.component';
import ArchivesTableFilter from '../../Archives/ArchivesTableFilter/ArchivesTableFilter.component';
import AuditorsTable from '../../Auditors/AuditorsTable/AuditorsTable.component';
import AuditorsTableFilter from '../../Auditors/AuditorsTableFilter/AuditorsTableFilter.component';
import AuditsTable from '../../Audits/AuditsTable/AuditsTable.component';
import AuditsTableFilter from '../../Audits/AuditsTableFilter/AuditsTableFilter.component';
import AuditsTableHeader from '../../Audits/AuditsTableHeader/AuditsTableHeader.component';
import ClientsTable from '../../Clients/ClientsTable/ClientsTable.component';
import ClientsTableFilter from '../../Clients/ClientsTableFilter/ClientsTableFilter.component';
import ClientsTableHeader from '../../Clients/ClientsTableHeader/ClientsTableHeader.component';
import LogicsTable from '../../Logics/LogicsTable/LogicsTable.component';
import LogicsTableFilter from '../../Logics/LogicsTableFilter/LogicsTableFilter.component';
import MapAuxiliary from '../../Map/MapAuxiliary/MapAuxiliary.component';
import OrdersTable from '../../Orders/OrdersTable/OrdersTable.component';
import OrdersTableFilter from '../../Orders/OrdersTableFilter/OrdersTableFilter.component';
import PatchesMap from '../../Patches/PatchesMap/PatchesMap.component';
import PatchesSidebar from '../../Patches/PatchesSidebar/PatchesSidebar.component';
import SitesTable from '../../Sites/SitesTable/SitesTable.component';
import SitesTableFilter from '../../Sites/SitesTableFilter/SitesTableFilter.component';
import SurveysTable from '../../Surveys/SurveysTable/SurveysTable.component';
import SurveysTableFilter from '../../Surveys/SurveysTableFilter/SurveysTableFilter.component';
import TaxonomyTable from '../../Taxonomy/TaxonomyTable/TaxonomyTable.component';
import UsersTable from '../../Users/UsersTable/UsersTable.component';
import UsersTableFilter from '../../Users/UsersTableFilter/UsersTableFilter.component';
import UsersTableHeader from '../../Users/UsersTableHeader/UsersTableHeader.component';
import EntityTable from './EntityTable/EntityTable.component';
import EntityTableHeader from './EntityTableHeader/EntityTableHeader.component';

export const body = entity => {
  const entityBody = {
    [Entity.AUDITORS]: AuditorsTable,
    [Entity.ARCHIVES]: ArchivesTable,
    [Entity.AUDITS]: AuditsTable,
    [Entity.CLIENTS]: ClientsTable,
    [Entity.LOGICS]: LogicsTable,
    [Entity.ORDERS]: OrdersTable,
    [Entity.PATCHES]: PatchesMap,
    [Entity.SITES]: SitesTable,
    [Entity.SURVEYS]: SurveysTable,
    [Entity.TAXONOMY]: TaxonomyTable,
    [Entity.USERS]: UsersTable,
  };

  return entityBody[entity] || EntityTable;
};

export const bodyProps = ({
  currentLimit,
  currentPage,
  currentSortBy,
  data,
  entity,
  error,
  handleLimitChange,
  handlePageChange,
  handleSortByChange,
  isFetching,
  refetch,
}) => {
  const props = {
    [Entity.TAXONOMY]: {
      data: !isFetching && data ? data : [],
    },
  };

  return props[entity] || {
    currentLimit,
    currentPage,
    currentSortBy,
    data: error || isFetching || !data?.items ? [] : data.items,
    onLimitChange: handleLimitChange,
    onPageChange: handlePageChange,
    onSortByChange: handleSortByChange,
    pages: data?.pages || 1,
    refetch,
  };
};

export const filter = entity => {
  const entityFilter = {
    [Entity.ARCHIVES]: ArchivesTableFilter,
    [Entity.AUDITORS]: AuditorsTableFilter,
    [Entity.AUDITS]: AuditsTableFilter,
    [Entity.CLIENTS]: ClientsTableFilter,
    [Entity.LOGICS]: LogicsTableFilter,
    [Entity.ORDERS]: OrdersTableFilter,
    [Entity.SITES]: SitesTableFilter,
    [Entity.SURVEYS]: SurveysTableFilter,
    [Entity.USERS]: UsersTableFilter,
  };

  return entityFilter[entity] || null;
};

export const filterProps = ({ entity, handleFilterChange, handleFilterChangeArchive, values }) => {
  const props = {
    [Entity.TAXONOMY]: {},
    [Entity.ARCHIVES]: {
      values,
      onChange: handleFilterChangeArchive,
    },
  };

  return props[entity] || {
    values,
    onChange: handleFilterChange,
  };
};

export const header = entity => {
  const entityHeader = {
    [Entity.AUDITS]: AuditsTableHeader,
  };

  return entityHeader[entity] || EntityTableHeader;
};

export const headerProps = ({ data, entity, isFilterVisible, setFilterVisible }) => {
  const props = {
    [Entity.CLIENTS]: {
      count: data?.count,
      isFilterVisible,
      setFilterVisible,
      extra: ClientsTableHeader,
    },
    [Entity.TAXONOMY]: {
    },
    [Entity.USERS]: {
      count: data?.count,
      isFilterVisible,
      setFilterVisible,
      extra: UsersTableHeader,
    },
  };

  return props[entity] || {
    count: data?.count,
    isFilterVisible,
    setFilterVisible,
  };
};

export const sidebar = ({ entity }) => {
  const props = {
    [Entity.PATCHES]: PatchesSidebar,
  };

  return props[entity] || null;
};

export const sidebarProps = ({ data, entity }) => {
  const props = {
    [Entity.PATCHES]: {
      data,
    },
  };

  return props[entity] || null;
};

export const bottombar = ({ entity, location, role }) => {
  const props = {
    [Entity.ARCHIVES]: ArchivesTablePagination,
    [Entity.AUDITORS]: MapAuxiliary,
    [Entity.AUDITS]: (role === UserRole.AUDITOR && location.search === '?status=assigned' && !isMobile)
      ? MapAuxiliary
      : (role === UserRole.DATA || role === UserRole.AREA_MANAGER)
        ? MapAuxiliary
        : null,
  };

  return props[entity] || null;
};

export const bottombarProps = ({ data, entity, fetchedAuditor }) => {
  const props = {
    [Entity.ARCHIVES]: {
      data: !data?.items ? [] : data.items,
    },
    [Entity.AUDITORS]: {
      data,
      key: entity,
      initialCollapse: true,
      type: EntityState.TABLE,
    },
    [Entity.AUDITS]: {
      data,
      key: entity,
      initialCollapse: true,
      fetchedAuditor,
      type: EntityState.TABLE,
    },
  };

  return props[entity] || null;
};

export const defaultQuery = (entity, role) => {
  const entityQuery = {
    [Entity.ARCHIVES]: {},
    [Entity.AUDITS]: role === UserRole.AUDITOR ? { status: 'assigned' } : { page: '1' },
    [Entity.SITES]: { sort: 'internal_id', page: '1' },
    [Entity.TAXONOMY]: {},
  };

  return entityQuery[entity] || { page: '1' };
};

import PropTypes from 'prop-types';
import React from 'react';

import { SLTable, SLTableCellFormatter } from '../../../common';

const columns = [
  {
    accessor: 'internal_id',
    label: 'Site ID',
    Cell: SLTableCellFormatter({
      linkFormat: '/sites/#id',
    }),
  },
  { accessor: 'name', label: 'Name' },
  {
    accessor: 'client_name',
    label: 'Client',
    Cell: SLTableCellFormatter({
      linkFormat: '/clients/#client_id',
      textFormat: '#client_name',
    }),
  },
  { accessor: 'code', label: 'Code' },
  { accessor: 'city', label: 'City' },
  { accessor: 'post_code', label: 'Post Code' },
];

const SitesTable = ({
  data,
  pages,
  onLimitChange,
  onPageChange,
  onSortByChange,
  currentLimit,
  currentPage,
  currentSortBy,
}) => (
  <SLTable
    columns={columns}
    currentLimit={currentLimit}
    currentPage={currentPage}
    currentSortBy={currentSortBy}
    data={data}
    dataCy="sites-table"
    pages={pages}
    onLimitChange={onLimitChange}
    onPageChange={onPageChange}
    onSortByChange={onSortByChange}
  />
);

SitesTable.defaultProps = {
  currentSortBy: {},
};

SitesTable.propTypes = {
  currentLimit: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  pages: PropTypes.number.isRequired,
  onLimitChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onSortByChange: PropTypes.func.isRequired,
  currentSortBy: PropTypes.object,
};

export default SitesTable;

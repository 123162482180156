import { StatusCodes } from 'http-status-codes';

import { appConfig } from '../config';
import axios from './axiosInstance';
import {
  getAuthorization,
  handlingResponse,
  logError,
} from './utils';

export const sitesArchived = () => axios
  .get(`${appConfig.backendUrl}/api/v1/archives/sites`, {
    headers: { Authorization: getAuthorization() },
  })
  .then(
    handlingResponse(
      [StatusCodes.OK],
      'Error fetching archives\'s sites',
    ),
  )
  .catch(logError);

export const fetchArchive = ({ siteId, year, month, day, legacyId }) => axios
  .get(
    `${appConfig.backendUrl}/api/v1/archives/${siteId}/${year}/${month}/${day}/${legacyId}`,
    {
      headers: { Authorization: getAuthorization() },
    },
  )
  .then(handlingResponse([StatusCodes.OK], 'Error fetching archives detail'))
  .catch(logError);

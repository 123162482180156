import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import SiteTooltip from '../../../Sites/SiteTooltip/SiteTooltip.component';
import styles from './styles.module.scss';

const AuditIdCellFormatter = ({ cell }) => (
  <SiteTooltip
    id={cell.row.original.site_id}
    position="right center"
    trigger={(
      <Link
        className={styles.link}
        to={`/audits/${cell.row.original.id}`}
      >
        {cell.value}
      </Link>
    )}
  />
);

AuditIdCellFormatter.propTypes = {
  cell: PropTypes.object.isRequired,
};

export default AuditIdCellFormatter;

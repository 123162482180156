import { Environment, ROOT_ELEMENT_ID } from '.';

const ENV_SCOPE = {
  COOKIE: 'cookie',
  SERVICE: 'service',
};

const envType = {
  [ENV_SCOPE.COOKIE]: {
    [Environment.DEVELOPMENT]: 'development',
    [Environment.STAGING]: 'staging',
    [Environment.PRODUCTION]: 'servelegal',
    [Environment.LOCAL]: 'local',
    default: 'local',
  },
  [ENV_SCOPE.SERVICE]: {
    [Environment.DEVELOPMENT]: 'development',
    [Environment.STAGING]: 'staging',
    [Environment.PRODUCTION]: 'production',
    [Environment.LOCAL]: 'local',
    [Environment.TEST]: 'test',
    default: 'local',
  },
};

const getENV = scope => {
  const rootElement = document.getElementById(ROOT_ELEMENT_ID);
  if (!rootElement) return envType[scope].default;
  return envType[scope][rootElement.dataset.environment] || envType[scope].default;
};

export const cookieENV = () => getENV(ENV_SCOPE.COOKIE);

export const serviceENV = () => getENV(ENV_SCOPE.SERVICE);

import { Field, FieldArray } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Divider, Dropdown, Form, Grid, Label } from 'semantic-ui-react';
import * as Yup from 'yup';

import { QuestionType, UserRole } from '../../../../../../../../constants';
import { SurveyQuestionsContext } from '../../../../../../../../context';
import FieldStatus from '../../../../../../../../fieldLogic/fieldStatus';
import { RoleChecker } from '../../../../../../../layout';
import { questionType } from '../../../../../../../types';
import {
  calculateQuestionCurrentScore,
  calculateQuestionMaxScore,
  validateQuestionValue,
} from '../../../../questionsHelpers';
import SurveyQuestionConfigHeader from '../SurveyQuestionConfigHeader/SurveyQuestionConfigHeader.component';
import styles from './SurveySelectorQuestion.module.scss';

const DATA_CY = 'survey-selector-question';

const SurveySelectorQuestion = ({
  answer,
  auditScreen,
  data,
  index,
  onChange,
  surveyVisibility,
}) => {
  let state = FieldStatus.EDITABLE;

  if (surveyVisibility) {
    state = surveyVisibility;
  }

  const setNullScore = ({ options, fieldName, setFieldValue }) => {
    options.forEach((_item, i) => setFieldValue(`${fieldName}.${i}.score`, null));
  };

  const renderConfiguration = () => (
    <>
      <SurveyQuestionConfigHeader index={index} />
      <Divider />
      <FieldArray name={`questions.${index}.config.options`}>
        {({ name, push, remove }) => (
          <Field name={name}>
            {({
              field: { value: options },
              form: { handleChange, setFieldValue },
            }) => (
              <Grid className={styles.grid} columns={4} verticalAlign="middle">
                <Grid.Row>
                  <Grid.Column width={2}></Grid.Column>
                  <Grid.Column width={4}>
                    {'Value'}
                  </Grid.Column>
                  <Grid.Column width={4}>
                    {'Label'}
                  </Grid.Column>
                  <Grid.Column width={4}>
                    {'Score'}
                  </Grid.Column>
                  <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                {options.map((_option, optionIndex, optionsArray) => (
                  <Grid.Row key={optionIndex}>
                    <Grid.Column width={2}>
                      <Field name={`${name}.${optionIndex}.selected`}>
                        {({
                          field: { name: fieldName, value: fieldValue },
                        }) => {
                          const selector = {
                            [QuestionType.CHECKBOX]: () => (
                              <Form.Checkbox
                                checked={fieldValue}
                                data-cy={`${DATA_CY}-${index}-options-${optionIndex}-selected-checkbox`}
                                id={fieldName}
                                onChange={(_e, { checked }) => setFieldValue(fieldName, checked)}
                              />
                            ),
                            [QuestionType.RADIO]: () => (
                              <Form.Checkbox
                                checked={fieldValue}
                                data-cy={`${DATA_CY}-${index}-options-${optionIndex}-selected-radio`}
                                id={fieldName}
                                onChange={(_e, { checked }) => {
                                  optionsArray.forEach(((tempValue, tempIndex) => {
                                    if (tempIndex === optionIndex) {
                                      setFieldValue(`${name}.${tempIndex}.selected`, checked);
                                    } else {
                                      setFieldValue(
`${name}.${tempIndex}.selected`,
checked ? false : tempValue.selected,
                                      );
                                    }
                                  }));
                                }}
                              />
                            ),
                            default: () => null,
                          };

                          return (selector[data.type] || selector.default)();
                        }}
                      </Field>
                    </Grid.Column>
                    <Grid.Column width={4}>
                      <Field
                        name={`${name}.${optionIndex}.value`}
                        validate={value => {
                          const otherOptionsValues = optionsArray.map(o => o.value)
                            .slice(0, optionIndex)
                            .concat(optionsArray.slice(optionIndex + 1));

                          return validateQuestionValue([
                            Yup.string().trim().ensure().min(1, 'Required'),
                            Yup.mixed().notOneOf(otherOptionsValues, 'Duplicated'),
                          ])(value);
                        }}
                      >
                        {({
                          field: { name: fieldName, value: fieldValue },
                          meta: { error },
                        }) => (
                          <Form.Input
                            data-cy={`${DATA_CY}-${index}-options-${optionIndex}-value-input`}
                            error={error}
                            id={fieldName}
                            value={fieldValue}
                            onChange={handleChange}
                          />
                        )}
                      </Field>
                    </Grid.Column>
                    <Grid.Column width={4}>
                      <Field
                        name={`${name}.${optionIndex}.label`}
                        validate={validateQuestionValue(
                          Yup.string().trim().ensure().min(1, 'Required'),
                        )}
                      >
                        {({
                          field: { name: fieldName, value: fieldValue },
                          meta: { error },
                        }) => (
                          <Form.Input
                            data-cy={`${DATA_CY}-${index}-options-${optionIndex}-label-input`}
                            error={error}
                            id={fieldName}
                            value={fieldValue}
                            onChange={handleChange}
                          />
                        )}
                      </Field>
                    </Grid.Column>
                    <Grid.Column width={4}>
                      <Field
                        name={`${name}.${optionIndex}.score`}
                      >
                        {({
                          field: { name: fieldName, value: fieldValue },
                          meta: { error },
                        }) => (
                          <Form.Input
                            data-cy={`${DATA_CY}-${index}-options-${optionIndex}-score-input`}
                            error={error}
                            id={fieldName}
                            type="number"
                            value={fieldValue !== null ? fieldValue : ''}
                            onChange={handleChange}
                          />
                        )}
                      </Field>
                    </Grid.Column>
                    <Grid.Column width={2}>
                      {options.length > 2 ? (
                        <span
                          className={styles.delete}
                          onClick={() => remove(optionIndex)}
                        />) : null}
                    </Grid.Column>
                  </Grid.Row>
                ))}
                <Grid.Row>
                  <Grid.Column width={2}>
                    <span
                      className={styles.add}
                      onClick={() => push({ selected: false, label: '', value: '' })}
                    />
                  </Grid.Column>
                  <Grid.Column width={4}></Grid.Column>
                  <Grid.Column width={4}></Grid.Column>
                  <Grid.Column width={4}>
                    <span
                      className={styles.remove}
                      onClick={() => setNullScore({ options, fieldName: name, setFieldValue })}
                    >
                      {'remove scores'}
                    </span>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}
          </Field>
        )}
      </FieldArray>
    </>
  );

  const renderInput = () => {
    const inputComponent = {
      [QuestionType.CHECKBOX]: question => (state === FieldStatus.READONLY ? (
        <div>
          {answer ? answer.join(',') : ''}
        </div>
      ) : (
        <>
          {question.config.options.map((option, optionIndex) => (
            <Form.Field key={optionIndex}>
              <Form.Checkbox
                key={option.value}
                checked={answer ? answer.includes(option.value) : option.selected}
                data-cy={`${DATA_CY}-${index}-value-${optionIndex}`}
                label={option.label}
                value={option.value}
                onChange={(_e, { checked }) => {
                  if (onChange) {
                    if (checked) {
                      onChange(answer ? [...answer, option.value] : [option.value]);
                    } else {
                      onChange(answer ? answer.filter(value => value !== option.value) : []);
                    }
                  }
                }
                }
              />
            </Form.Field>
          ))}
        </>
      )),
      [QuestionType.RADIO]: question => (state === FieldStatus.READONLY ? (
        <div>
          {answer !== null ? answer : ''}
        </div>
      ) : (
        <>
          {question.config.options.map((option, optionIndex) => (
            <Form.Field key={optionIndex}>
              <Form.Radio
                key={option.value}
                checked={answer !== null ? answer === option.value : option.selected}
                data-cy={`${DATA_CY}-${index}-value-${optionIndex}`}
                label={option.label}
                value={option.value}
                onChange={(_e, { value }) => (onChange ? onChange(value) : null)}
              />
            </Form.Field>
          ))}
        </>
      )),
      [QuestionType.SELECT]: question => (state === FieldStatus.READONLY ? (
        <div>
          {answer !== null ? answer : ''}
        </div>
      ) : (
        <Dropdown
          selection
          data-cy={`${DATA_CY}-${index}-value`}
          options={question.config.options.map(option => ({
            value: option.value,
            text: option.label,
          }))}
          value={answer !== null ? answer : null}
          onChange={(_e, { value }) => (onChange ? onChange(value) : null)}
        />
      )),
    };

    return (
      <Field name={`questions.${index}`}>
        {({
          field: { value: question },
        }) => {
          const maxScore = calculateQuestionMaxScore(question);
          const currentScore = calculateQuestionCurrentScore(question, answer);

          return (
            <>
              <Form.Field required={question.required}>
                <label data-cy={`${DATA_CY}-${index}-title`}>
                  {question.title}
                </label>
                {!auditScreen && (
                  <span data-cy={`${DATA_CY}-${index}-auditor-information`}>
                    {question.auditor_information}
                  </span>
                )}
                {inputComponent[data.type](question)}
                {question.help.trim().length > 0 && (
                  <span data-cy={`${DATA_CY}-${index}-help`}>
                    {question.help}
                  </span>
                )}
                <RoleChecker allowedRoles={[UserRole.DATA, UserRole.CLIENT_SERVICES, UserRole.AREA_MANAGER]}>
                  <p data-cy={`${DATA_CY}-${index}-approving-information`}>
                    {question.approving_information}
                  </p>
                  {maxScore && (
                    <Label>
                      {`${currentScore}/${maxScore}`}
                    </Label>
                  )}
                </RoleChecker>
              </Form.Field>
            </>
          );
        }}
      </Field>
    );
  };

  return (
    <SurveyQuestionsContext.Consumer>
      {({ selectedQuestionIndex }) => (
        selectedQuestionIndex === index ? renderConfiguration() : renderInput()
      )}
    </SurveyQuestionsContext.Consumer>
  );
};

SurveySelectorQuestion.defaultProps = {
  answer: null,
  auditScreen: null,
  onChange: null,
  status: null,
  surveyVisibility: null,
};

SurveySelectorQuestion.propTypes = {
  data: questionType.isRequired,
  index: PropTypes.number.isRequired,
  answer: PropTypes.any,
  auditScreen: PropTypes.bool,
  surveyVisibility: PropTypes.string,
  onChange: PropTypes.func,
};

export default SurveySelectorQuestion;

import * as Yup from 'yup';

const automatic_fail_letter = {
  automatic_fail_letter_notification: Yup.boolean(),
  automatic_fail_letter_notification_email_body: Yup.string().when('automatic_fail_letter_notification', {
    is: true,
    then: Yup.string().required('Email body is required'),
  }),
  automatic_fail_letter_notification_email_subject: Yup.string().when('automatic_fail_letter_notification', {
    is: true,
    then: Yup.string().required('Email subject is required'),
  }),
  automatic_fail_letter_notification_pdf: Yup.object().when('automatic_fail_letter_notification', {
    is: true,
    then: Yup.object().shape({
      content: Yup.string().when('is_custom', {
        is: true,
        then: Yup.string().required('Content is required'),
        otherwise: Yup.string().nullable(),
      }),
      document: Yup.string().when('is_custom', {
        is: is_custom => !is_custom,
        then: Yup.string().required('Document is required'),
        otherwise: Yup.string().nullable(),
      }),
      is_custom: Yup.boolean().nullable(),
      tokens: Yup.array().of(Yup.object()),
    }),
    otherwise: Yup.object().shape({
      content: Yup.string().nullable(),
      document: Yup.string().nullable(),
      is_custom: Yup.boolean().nullable(),
      tokens: Yup.array().of(Yup.object()).nullable(),
    }),
  }),
};
export const orderValidationSchema = Yup.object().shape({
  approving_notes: Yup.string().nullable(),
  auditor_instructions: Yup.string(),
  auditor_pay_per_audit: Yup.number().min(0).required('Required'),
  ...automatic_fail_letter,
  briefing_documents: Yup.array().of(Yup.string()),
  client_charge_per_audit: Yup.number().min(0).required('Required'),
  client_id: Yup.string().trim().ensure().min(1, 'Required'),
  currency: Yup.string().trim().ensure().min(1, 'Required'),
  email_copy: Yup.array(), // TODO
  email_subject: Yup.string(), // TODO
  email_survey_result: Yup.string(), // TODO
  end_date: Yup.date().required('Required'),
  exclude_from_client_reporting: Yup.boolean(),
  info: Yup.string(),
  item_to_order: Yup.string().trim().ensure().min(1, 'Required'),
  narv_visits_only: Yup.boolean(),
  revision_id: Yup.string().trim().ensure().min(1, 'Required'),
  schedule_type: Yup.string().trim().ensure().min(1, 'Required'),
  start_date: Yup.date().required('Required'),
  status: Yup.string().trim().ensure().min(1, 'Required'),
  survey_id: Yup.string().trim().ensure().min(1, 'Required'),
  visits_to_be_billed: Yup.number().min(0).required('Required'),
});
export const orderDefaultValues = {
  approving_notes: '',
  auditor_pay_per_audit: 0,
  client_charge_per_audit: 0,
  exclude_from_client_reporting: false,
  narv_visits_only: false,
  visits_to_be_billed: 0,
  briefing_documents: [],
  automatic_fail_letter_notification: false,
  automatic_fail_letter_notification_pdf: {
    content: '',
    document: '',
    is_custom: false,
    tokens: [],
  },
};

export const orderFilterDefaultValues = {
  client_name: '',
  item_to_order: '',
  schedule_type: '',
  survey_title: '',
  status: [],
};

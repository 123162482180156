import * as Yup from 'yup';

export const defaultBranchingRule = {
  logic: '',
  hidden_questions_ids: [],
};

export const branchingValidationSchema = Yup.object({
  branching: Yup.array().of(Yup.object({
    logic: Yup.string().trim().ensure().min(1, 'Required'),
    hidden_questions_ids: Yup.array().of(Yup.string()),
  })),
});

import PropTypes from 'prop-types';
import React from 'react';
import { Button, List } from 'semantic-ui-react';

import styles from './AuditingPreview.module.scss';

const DATA_CY = 'auditing-survey-questions';

const AuditingPreview = ({ data, next }) => (
  <div className={styles.root} data-cy={DATA_CY}>
    <h3>
      {'Survey Questions'}
    </h3>

    <List ordered>
      {data.revision_questions?.map(question => (
        <List.Item key={question.id}>
          {question.title}
        </List.Item>
      ))}
    </List>

    <Button
      primary
      data-cy={`${DATA_CY}-start-button`}
      onClick={next}
    >
      {'Start Audit'}
    </Button>
  </div>
);

AuditingPreview.propTypes = {
  data: PropTypes.object.isRequired,
  next: PropTypes.func.isRequired,
};

export default AuditingPreview;

import * as Yup from 'yup';

export const patchInitialValues = {
  name: '',
  coordinates: [],
  area_managers_ids: [],
};

export const patchValidationSchema = Yup.object({
  name: Yup.string().trim().ensure().min(1, 'Required'),
  // coordinates: Yup.array().min(3, 'It needs at least 3 coordinates'),
  area_managers_ids: Yup.array().of(Yup.string().trim().ensure().min(1, 'Required')).min(1, 'Required'),
});

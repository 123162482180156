import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { taxonomyTermOptionsSelector } from '../../../../../state/constants/selectors';

const surveyResultTypeSelector = state => taxonomyTermOptionsSelector(state, 'survey_result_type');

const SurveyResultCellFormatter = ({ cell }) => {
  const surveyResultTypeOptions = useSelector(surveyResultTypeSelector);

  let result = '';

  if (cell.value) {
    result = cell.value.map(
      value => surveyResultTypeOptions.find(option => option.value === value.result)?.text || value.result,
    );
  }

  return (
    <span>
      {result}
    </span>
  );
};

SurveyResultCellFormatter.propTypes = {
  cell: PropTypes.object.isRequired,
};

export default SurveyResultCellFormatter;

import _isEmpty from 'lodash/isEmpty';
import { handleActions } from 'redux-actions';

import * as actions from './actionCreators';
import initialState from './initialState';

export default {
  audits: handleActions(
    {
      [actions.storeAuditDetails]: (state, action) => ({
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          original: { ...action.payload },
        },
      }),

      [actions.removeAudit]: (state, action) => Object.entries(state).reduce((acc, [key, value]) => {
        if (key === action.payload.id) return acc;
        acc[key] = value;
        return acc;
      }, {}),

      [actions.clearAllAudits]: () => {},

      [actions.initAudit]: (state, action) => {
        const { id, answers, currentQuestionId, extraDetails } = action.payload;

        return {
          ...state,
          [id]: {
            ...state[id],
            location: null,
            answers,
            currentQuestionId,
            extraDetails,
          },
        };
      },

      [actions.updateAuditLocationAndDate]: (state, action) => {
        const { id, auditGeolocationDate, ...rest } = action.payload;
        const hasNotCoords = _isEmpty(rest);

        return {
          ...state,
          [id]: {
            ...state[id],
            location: hasNotCoords ? null : rest,
            audit_geolocation_date: hasNotCoords ? null : auditGeolocationDate,
          },
        };
      },

      [actions.updateAuditAnswers]: (state, action) => {
        const { id, answers } = action.payload;

        return {
          ...state,
          [id]: {
            ...state[id],
            answers,
          },
        };
      },

      [actions.updateAuditCurrentQuestionId]: (state, action) => {
        const { id, currentQuestionId } = action.payload;

        return {
          ...state,
          [id]: {
            ...state[id],
            currentQuestionId,
          },
        };
      },

      [actions.updateAuditExtraDetails]: (state, action) => {
        const { id, ...rest } = action.payload;

        return {
          ...state,
          [id]: {
            ...state[id],
            extraDetails: rest,
          },
        };
      },

      [actions.updateAuditStep]: (state, action) => {
        const { id, step } = action.payload;

        return {
          ...state,
          [id]: {
            ...state[id],
            step,
          },
        };
      },
    },

    initialState.audits,
  ),
};

import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { entitiesAPI, errorAPI, surveyRevisionsAPI } from '../../../../../api';
import { Entity, SurveyEntity } from '../../../../../constants';
import { SurveyQuestionsContext } from '../../../../../context';
import history from '../../../../../history';
import { useUpdateSurvey } from '../../../../../hooks';
import { LoadingPlaceholder } from '../../../../common';
import { surveyRevisionDefaultValues, surveyRevisionValidationSchema } from '../../helpers';
import SurveyStep from '../../SurveyStep/SurveyStep.component';
import SurveyQuestionsEditor from './SurveyQuestionsEditor/SurveyQuestionsEditor.component';
import SurveyQuestionsSidebar from './SurveyQuestionSidebar/SurveyQuestionsSidebar.component';

const SurveyQuestions = () => {
  const { entityId, revisionId } = useParams();
  const alert = useAlert();

  const [revision, setRevision] = useState(surveyRevisionDefaultValues);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(null);

  const { data: revisionQuery, isFetching: isFetchingRevision } = useQuery(
    [entityId, revisionId, { include: SurveyEntity.QUESTIONS }],
    () => surveyRevisionsAPI.fetchOne(entityId, revisionId, { include: SurveyEntity.QUESTIONS }),
    { enabled: Boolean(entityId && revisionId) },
  );

  const { mutate: createSurvey } = useMutation(entitiesAPI.create, {
    onSuccess: response => {
      alert.success('Survey created');
      history.push(
        `/${Entity.SURVEYS}/${response.id}/revisions/${response.revision_id}/${SurveyEntity.BRANCHING}`,
      );
    },
    onError: error => {
      alert.error(`Error creating survey: ${JSON.stringify(error)}`);
      errorAPI.sendError(error.message, error.stack);
    },
  });

  const { mutate: updateSurvey } = useUpdateSurvey({
    currentStep: SurveyEntity.QUESTIONS,
    onSuccessStep: SurveyEntity.BRANCHING,
  });

  // const next = useCallback(() => {
  //   history.push(
  //     `/${Entity.SURVEYS}/${entityId}/revisions/${revisionId}/${SurveyEntity.BRANCHING}`,
  //   );
  // }, [entityId, revisionId]);

  useEffect(() => {
    if (revisionId && revisionQuery) {
      setRevision(revisionQuery);
    }
  }, [revisionId, revisionQuery]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={revision}
      validationSchema={surveyRevisionValidationSchema}
      onSubmit={values => {
        if (revisionId === undefined) {
          createSurvey({
            entity: Entity.SURVEYS,
            payload: {
              title: values.survey_title,
              revision_name: values.name,
              questions: values.questions,
            },
          });
        } else {
          updateSurvey({
            surveyId: values.survey_id,
            revisionId: values.id,
            payload: {
              name: values.name,
              [SurveyEntity.QUESTIONS]: values.questions,
            },
          });
        }
      }}
    >
      {({ handleSubmit, errors, values, validateForm }) => {
        const submit = () => {
          // FIXME: [SURVEY BLOCKED] uncomment when ServeLegal agrees
          // if (values.blocked) {
          //   next();
          // } else {
          validateForm().then(async validation => {
            if (Object.keys(validation).length === 0) {
              handleSubmit();
            } else {
              alert.error(
                <pre>
                  {JSON.stringify(errors, null, 2)}
                </pre>,
              );
            }
          });
          // }
        };

        return (
          <SurveyQuestionsContext.Provider
            value={{
              selectedQuestionIndex,
              setSelectedQuestionIndex,
            }}
          >
            <SurveyStep
              editor={revisionId && (isFetchingRevision || !revisionQuery)
                ? LoadingPlaceholder : SurveyQuestionsEditor}
              headerProps={{ blocked: values.blocked }}
              next={submit}
              nextContent={/* values.blocked ? 'Go to Branching' : */'Save & Continue'}
              sidebar={/* values.blocked ? null : */SurveyQuestionsSidebar}
              sidebarProps={{ sticky: true }}
            />
          </SurveyQuestionsContext.Provider>
        );
      }}
    </Formik>
  );
};

export default SurveyQuestions;

import PropTypes from 'prop-types';
import React from 'react';
import { Link, useParams } from 'react-router-dom';

import { getEntityMetadataByValue } from '../../../../../constants';
import { useUserRole } from '../../../../../hooks';
import { FieldScope, fieldVisibility, TableViewActions } from '../../../../../rolePermissionMatrix';
import { SLHeader } from '../../../../common';
import EntityExportMenu from './EntityExportMenu/EntityExportMenu.component';
import styles from './EntityTableHeader.module.scss';

const EntityTableHeader = ({
  count,
  extra: Extra,
  isFilterVisible,
  setFilterVisible,
}) => {
  const { entity } = useParams();
  const role = useUserRole();

  const DATA_CY = `${entity}-table-header`;

  return (
    <SLHeader
      dataCy={DATA_CY}
      subtitle={count ? `${count} Results Found` : ''}
      title={entity}
    >
      {fieldVisibility({ entity, role, scope: FieldScope.TABLE_VIEW_ACTIONS })(TableViewActions.ADD)
        ? (
          <Link data-cy={`${DATA_CY}-new-link`} to={`/${entity}/new`}>
            <span className={styles.add}>
              {`Add ${getEntityMetadataByValue(entity).singular}`}
            </span>
          </Link>
        )
        : null
      }
      {fieldVisibility({ entity, role, scope: FieldScope.TABLE_VIEW_ACTIONS })(TableViewActions.IMPORT)
        ? (
          <Link data-cy={`${DATA_CY}-upload-link`} to={`/${entity}/upload`}>
            <span className={styles.upload}>
              {`Upload ${entity}`}
            </span>
          </Link>
        )
        : null
      }
      {fieldVisibility({ entity, role, scope: FieldScope.TABLE_VIEW_ACTIONS })(TableViewActions.EXPORT)
        ? (
          <EntityExportMenu
            canAddAndEdit={
              fieldVisibility({
                entity, role, scope: FieldScope.TABLE_VIEW_ACTIONS,
              })(TableViewActions.EXPORT_EDIT_TEMPLATE)
            }
          />
        ) : null
      }
      {Extra ? <Extra /> : null}
      {setFilterVisible
        ? (
          <div
            className={isFilterVisible ? styles.toggleIconOpen : styles.toggleIconClosed}
            data-cy={`${DATA_CY}-filter-toggle`}
            onClick={() => setFilterVisible(!isFilterVisible)}
          />
        )
        : null
      }
    </SLHeader>
  );
};

EntityTableHeader.defaultProps = {
  count: null,
  extra: null,
  isFilterVisible: false,
  setFilterVisible: null,
};

EntityTableHeader.propTypes = {
  count: PropTypes.number,
  extra: PropTypes.object,
  isFilterVisible: PropTypes.bool,
  setFilterVisible: PropTypes.func,
};

export default EntityTableHeader;

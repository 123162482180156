import classnames from 'classnames';
import getCurrencySymbol from 'currency-symbols';
import { Field, useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form } from 'semantic-ui-react';

import FieldStatus from '../../../fieldLogic/fieldStatus';
import FormFieldTemplate from './Template/FormFieldTemplate.component';

const FormFieldCurrencyWrapper = ({
  dataCy,
  help,
  inline,
  label,
  labelStyle,
  labelTooltip,
  name,
  onChange,
  onStateHandler,
  valueStyle,
  readOnly,
  required,
  currency,
  ...rest
}) => {
  const [field, meta, helpers] = useField(name);
  const { setFieldValue } = useFormikContext();

  const dataCyPrefix = dataCy || name;

  let state = FieldStatus.EDITABLE;

  if (onStateHandler) {
    state = onStateHandler(name);
  }

  const handleBlur = e => {
    const newVal = !Number.isNaN(Number.parseFloat(e.target.value))
      ? Number.parseFloat(e.target.value)
      : 0;
    if (onChange) {
      onChange(newVal);
    } else {
      setFieldValue(field.name, newVal);
    }
  };

  const handleChange = (e, newValue) => {
    const validNumber = /^\d*\.?\d*$/;
    if (onChange && validNumber.test(newValue)) {
      onChange(String(newValue)[0] === '0'
        ? Number.parseFloat(e.target.value)
        : newValue === ''
          ? 0
          : newValue);
    }
  };

  if (state === FieldStatus.HIDDEN) {
    return null;
  }

  const readOnlyField = () => (
    <span className={classnames({ [valueStyle]: valueStyle })}>
      {`${field.value} ${getCurrencySymbol(currency) || currency}`}
    </span>);

  const editableField = () => (
    <Field
      action={
        <Button
          disabled
          data-cy={`${dataCyPrefix}-currency`}
        >
          {getCurrencySymbol(currency) || currency}
        </Button>
      }
      as={Form.Input}
      data-cy={`${dataCyPrefix}-field`}
      error={meta.touched ? meta.touched && meta.error : meta.error}
      name={name}
      readOnly={readOnly}
      required={required}
      step={'any'}
      type={'number'}
      onBlur={ev => handleBlur(ev)}
      onChange={(_e, { value }) => {
        const parsedValue = value.trimStart();
        handleChange(_e, value);
        helpers.setValue(parsedValue);
      }}
      {...rest}
    />
  );

  return (
    <FormFieldTemplate
      dataCy={dataCyPrefix}
      help={help}
      inline={inline}
      label={label}
      labelStyle={labelStyle}
      labelTooltip={labelTooltip}
      required={required}
    >
      {state === FieldStatus.READONLY || readOnly ? readOnlyField() : editableField()}
    </FormFieldTemplate>
  );
};

FormFieldCurrencyWrapper.defaultProps = {
  currency: '',
  dataCy: null,
  help: null,
  inline: false,
  label: null,
  labelStyle: null,
  labelTooltip: null,
  onChange: null,
  onStateHandler: null,
  readOnly: false,
  required: false,
  valueStyle: null,
};

FormFieldCurrencyWrapper.propTypes = {
  name: PropTypes.string.isRequired,
  currency: PropTypes.string,
  dataCy: PropTypes.string,
  help: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  inline: PropTypes.bool,
  label: PropTypes.string,
  labelStyle: PropTypes.string,
  labelTooltip: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  valueStyle: PropTypes.string,
  onChange: PropTypes.func,
  onStateHandler: PropTypes.func,
};

export default FormFieldCurrencyWrapper;

import FieldStatus from './fieldStatus';

const conditionObject = {
  client: {
    new: {
      auditor_instructions: FieldStatus.EDITABLE,
      auditor_pay_per_audit: FieldStatus.EDITABLE,
      briefing_documents: FieldStatus.EDITABLE,
      client_charge_per_audit: FieldStatus.EDITABLE,
      client_id: FieldStatus.EDITABLE,
      currency: FieldStatus.EDITABLE,
      email_copy: FieldStatus.EDITABLE,
      email_subject: FieldStatus.EDITABLE,
      email_survey_result: FieldStatus.EDITABLE,
      end_date: FieldStatus.EDITABLE,
      exclude_from_client_reporting: FieldStatus.EDITABLE,
      item_to_order: FieldStatus.EDITABLE,
      narv_visits_only: FieldStatus.EDITABLE,
      order_info: FieldStatus.EDITABLE,
      revision_id: FieldStatus.EDITABLE,
      schedule_type: FieldStatus.EDITABLE,
      start_date: FieldStatus.EDITABLE,
      status: FieldStatus.EDITABLE,
      visits_to_be_billed: FieldStatus.EDITABLE,
      audits_in_system: FieldStatus.READONLY,
      'email_copy.0.text': FieldStatus.EDITABLE,
      'email_copy.0.result': FieldStatus.EDITABLE,
      'email_copy.1.text': FieldStatus.EDITABLE,
      'email_copy.1.result': FieldStatus.EDITABLE,
      'email_copy.2.text': FieldStatus.EDITABLE,
      'email_copy.2.result': FieldStatus.EDITABLE,
    },
    existing: {
      client_id: FieldStatus.READONLY,
      end_date: FieldStatus.READONLY,
      item_to_order: FieldStatus.READONLY,
      start_date: FieldStatus.READONLY,
    },
  },
  client_services: {
    new: {
      approving_notes: FieldStatus.EDITABLE,
      auditor_instructions: FieldStatus.EDITABLE,
      auditor_pay_per_audit: FieldStatus.EDITABLE,
      briefing_documents: FieldStatus.EDITABLE,
      client_charge_per_audit: FieldStatus.EDITABLE,
      client_id: FieldStatus.EDITABLE,
      currency: FieldStatus.EDITABLE,
      email_copy: FieldStatus.EDITABLE,
      email_subject: FieldStatus.EDITABLE,
      email_survey_result: FieldStatus.EDITABLE,
      end_date: FieldStatus.EDITABLE,
      exclude_from_client_reporting: FieldStatus.EDITABLE,
      item_to_order: FieldStatus.EDITABLE,
      narv_visits_only: FieldStatus.EDITABLE,
      order_info: FieldStatus.EDITABLE,
      revision_id: FieldStatus.EDITABLE,
      schedule_type: FieldStatus.EDITABLE,
      start_date: FieldStatus.EDITABLE,
      status: FieldStatus.EDITABLE,
      visits_to_be_billed: FieldStatus.EDITABLE,
      audits_in_system: FieldStatus.READONLY,
      'email_copy.0.text': FieldStatus.EDITABLE,
      'email_copy.0.result': FieldStatus.EDITABLE,
      'email_copy.1.text': FieldStatus.EDITABLE,
      'email_copy.1.result': FieldStatus.EDITABLE,
      'email_copy.2.text': FieldStatus.EDITABLE,
      'email_copy.2.result': FieldStatus.EDITABLE,
      automatic_fail_letter_notification: FieldStatus.READONLY,
      automatic_fail_letter_notification_email_subject: FieldStatus.READONLY,
      automatic_fail_letter_notification_email_body: FieldStatus.READONLY,
      automatic_fail_letter_notification_pdf: FieldStatus.READONLY,
    },
    existing: {
      approving_notes: FieldStatus.READONLY,
      auditor_instructions: FieldStatus.READONLY,
      auditor_pay_per_audit: FieldStatus.READONLY,
      briefing_documents: FieldStatus.EDITABLE,
      client_charge_per_audit: FieldStatus.READONLY,
      client_id: FieldStatus.READONLY,
      currency: FieldStatus.READONLY,
      email_copy: FieldStatus.READONLY,
      email_subject: FieldStatus.READONLY,
      email_survey_result: FieldStatus.READONLY,
      end_date: FieldStatus.READONLY,
      exclude_from_client_reporting: FieldStatus.READONLY,
      item_to_order: FieldStatus.READONLY,
      narv_visits_only: FieldStatus.READONLY,
      order_info: FieldStatus.READONLY,
      revision_id: FieldStatus.READONLY,
      schedule_type: FieldStatus.READONLY,
      start_date: FieldStatus.READONLY,
      status: FieldStatus.READONLY,
      visits_to_be_billed: FieldStatus.READONLY,
      audits_in_system: FieldStatus.READONLY,
      'email_copy.0.text': FieldStatus.READONLY,
      'email_copy.0.result': FieldStatus.READONLY,
      'email_copy.1.text': FieldStatus.READONLY,
      'email_copy.1.result': FieldStatus.READONLY,
      'email_copy.2.text': FieldStatus.READONLY,
      'email_copy.2.result': FieldStatus.READONLY,
      automatic_fail_letter_notification: FieldStatus.READONLY,
      automatic_fail_letter_notification_email_subject: FieldStatus.READONLY,
      automatic_fail_letter_notification_email_body: FieldStatus.READONLY,
      automatic_fail_letter_notification_pdf: FieldStatus.READONLY,
    },
  },
  data: {
    new: {
      approving_notes: FieldStatus.EDITABLE,
      auditor_instructions: FieldStatus.EDITABLE,
      auditor_pay_per_audit: FieldStatus.EDITABLE,
      briefing_documents: FieldStatus.EDITABLE,
      client_charge_per_audit: FieldStatus.EDITABLE,
      client_id: FieldStatus.EDITABLE,
      currency: FieldStatus.EDITABLE,
      email_copy: FieldStatus.EDITABLE,
      email_subject: FieldStatus.EDITABLE,
      email_survey_result: FieldStatus.EDITABLE,
      end_date: FieldStatus.EDITABLE,
      exclude_from_client_reporting: FieldStatus.EDITABLE,
      item_to_order: FieldStatus.EDITABLE,
      narv_visits_only: FieldStatus.EDITABLE,
      order_info: FieldStatus.EDITABLE,
      revision_id: FieldStatus.EDITABLE,
      schedule_type: FieldStatus.EDITABLE,
      start_date: FieldStatus.EDITABLE,
      status: FieldStatus.EDITABLE,
      visits_to_be_billed: FieldStatus.EDITABLE,
      audits_in_system: FieldStatus.READONLY,
      file: FieldStatus.EDITABLE,
      'email_copy.0.text': FieldStatus.EDITABLE,
      'email_copy.0.result': FieldStatus.EDITABLE,
      'email_copy.1.text': FieldStatus.EDITABLE,
      'email_copy.1.result': FieldStatus.EDITABLE,
      'email_copy.2.text': FieldStatus.EDITABLE,
      'email_copy.2.result': FieldStatus.EDITABLE,
      automatic_fail_letter_notification: FieldStatus.EDITABLE,
      automatic_fail_letter_notification_email_subject: FieldStatus.EDITABLE,
      automatic_fail_letter_notification_email_body: FieldStatus.EDITABLE,
      automatic_fail_letter_notification_pdf: FieldStatus.EDITABLE,
    },
    existing: {
      approving_notes: FieldStatus.EDITABLE,
      auditor_instructions: FieldStatus.EDITABLE,
      auditor_pay_per_audit: FieldStatus.EDITABLE,
      briefing_documents: FieldStatus.EDITABLE,
      client_charge_per_audit: FieldStatus.EDITABLE,
      client_id: FieldStatus.EDITABLE,
      currency: FieldStatus.EDITABLE,
      email_copy: FieldStatus.EDITABLE,
      email_subject: FieldStatus.EDITABLE,
      email_survey_result: FieldStatus.EDITABLE,
      end_date: FieldStatus.EDITABLE,
      exclude_from_client_reporting: FieldStatus.EDITABLE,
      item_to_order: FieldStatus.EDITABLE,
      narv_visits_only: FieldStatus.EDITABLE,
      order_info: FieldStatus.EDITABLE,
      revision_id: FieldStatus.EDITABLE,
      schedule_type: FieldStatus.EDITABLE,
      start_date: FieldStatus.EDITABLE,
      status: FieldStatus.EDITABLE,
      visits_to_be_billed: FieldStatus.EDITABLE,
      audits_in_system: FieldStatus.READONLY,
      file: FieldStatus.EDITABLE,
      'email_copy.0.text': FieldStatus.EDITABLE,
      'email_copy.0.result': FieldStatus.EDITABLE,
      'email_copy.1.text': FieldStatus.EDITABLE,
      'email_copy.1.result': FieldStatus.EDITABLE,
      'email_copy.2.text': FieldStatus.EDITABLE,
      'email_copy.2.result': FieldStatus.EDITABLE,
      automatic_fail_letter_notification: FieldStatus.EDITABLE,
      automatic_fail_letter_notification_email_subject: FieldStatus.EDITABLE,
      automatic_fail_letter_notification_email_body: FieldStatus.EDITABLE,
      automatic_fail_letter_notification_pdf: FieldStatus.EDITABLE,
    },
  },
};

const getSitesFieldStatus = (userRole, isNew, revisionId) => fieldName => (!revisionId ? conditionObject[userRole]?.[isNew ? 'new' : 'existing']?.[fieldName] : FieldStatus.READONLY || FieldStatus.HIDDEN);

export default getSitesFieldStatus;

import React from 'react';

import { FormFieldWrapper } from '../../../../common';
import FormLayout from '../../RegisterAuditorFormLayout/RegisterAuditorFormLayout.component';
import styles from './RegisterAuditorFormStepEight.module.scss';

const RegisterFormAuditorFormStepEight = () => (
  <FormLayout subtitle="1 of 3" title="Extra information">
    <span className={styles.titleQuestion}>
      {'What\'s your primary address?'}
    </span>
    <span className={styles.titleQuestion}>
      {'For students, this should be where you are based over the summer'}
    </span>
    <FormFieldWrapper
      required
      name="home_address_1"
      placeholder="Address 1"
    />
    <FormFieldWrapper
      required
      name="home_address_2"
      placeholder="Address 2"
    />
    <FormFieldWrapper
      required
      name="home_city"
      placeholder="City"
    />
    <FormFieldWrapper
      required
      name="home_post_code"
      placeholder="Postcode/Eircode"
    />
    <FormFieldWrapper
      required
      name="home_county"
      placeholder="County"
    />
  </FormLayout>
);

export default RegisterFormAuditorFormStepEight;

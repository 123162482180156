import { all, fork, takeLatest } from 'redux-saga/effects';

const sagasFiles = require.context('.', true, /sagas.js$/);
const sagasModules = sagasFiles.keys().map(sagasFiles);
const sagas = sagasModules.reduce((acc, saga) => {
  Object.entries(saga.default).forEach(([key, value]) => {
    Object.assign(acc, { [key]: value });
  });
  return acc;
}, {});

const watchers = Object.entries(sagas).map(([key, value]) => (
  function* watchSaga() {
    yield takeLatest(key, value);
  }));

export default function* root() {
  yield all(watchers.map(watcher => fork(watcher)));
}

import { Field, FieldArray, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form } from 'semantic-ui-react';
import * as Yup from 'yup';

import { FormFieldSelectWrapper, FormFieldWrapper } from '../../../../../common';
import FormFieldLabel from '../../../../../common/FormFieldWrappers/Template/FormFieldLabel.component';
import GenericModal from '../../../../../common/GernericModal/GenericModal.component';
import styles from './TokenEditor.module.scss';

const tokenSchema = Yup.object().shape({
  display_title: Yup.string().required('Display title is required'),
  name: Yup.string().required('Token name is required'),
  // .matches(/^\[[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*\]$/, 'Token name must be in the format [text] or [text-text-...]'),
  options: Yup.array()
    .when('type', {
      is: 'select',
      then: Yup.array().of(Yup.string().required('A value for the option is required')).min(2, 'Options must have at least 2 entries'),
      otherwise: Yup.array().notRequired(),
    }),
  type: Yup.string().oneOf(['input', 'select', 'image', 'date', 'datetime', 'time'], 'Invalid token type').required('Token type is required'),
});

const TokenEditor = ({ initialValues, onSave, onClose, open }) => (
  <Formik
    enableReinitialize={true}
    initialValues={initialValues}
    validationSchema={tokenSchema}
    onSubmit={(values, { resetForm }) => {
      const valuesCopy = JSON.parse(JSON.stringify(values));
      valuesCopy.name = `[${valuesCopy.name.toLowerCase().trim().replace(/ /g, '-')}]`;
      onSave(valuesCopy);
      resetForm();
    }}
  >
    {({ values, setFieldValue, handleSubmit, errors, resetForm }) => (
      <GenericModal
        buttons={[
          { label: initialValues ? 'Save' : 'Add', func: handleSubmit, disabled: Object.keys(errors).length > 0 },
          {
            label: 'Cancel',
            func: () => {
              onClose();
              resetForm();
            },
          },
        ]}
        open={open}
        onDismiss={onClose}
      >
        <div className={styles.modalContainer}>
          <h4 className={styles.modalTitle}>
            {'Token editor'}
          </h4>
          <Form className={styles.form}>
            <FormFieldSelectWrapper
              label={'Token type'}
              labelTooltip={'Type of field that will be presented to the auditor'}
              name={'type'}
              options={[
                { value: 'input', text: 'Input' },
                { value: 'select', text: 'Select' },
                { value: 'image', text: 'Image' },
                { value: 'date', text: 'Date' },
                { value: 'datetime', text: 'Datetime' },
                { value: 'time', text: 'Time' },
              ]}
              onChange={value => {
                setFieldValue('type', value);
              }}
            />
            <FormFieldWrapper
              label={'Token name'}
              labelTooltip={'Token name to be used in the PDF template'}
              name={'name'}
              placeholder={'Enter token name'}
            />
            <FormFieldWrapper
              label={'Display title'}
              labelTooltip={'This is the text that will be presented to the auditor along with the token type. For instance, if the token type is "Select", you may want to display "Select the reason why the audit failed"'}
              name={'display_title'}
              placeholder={'Enter display title'}
            />
            {values.type === 'select' && (
              <>
                <FieldArray name={'options'}>
                  {({ name, push, remove }) => (
                    <>
                      <Button onClick={() => push('')}>
                        {'Add option'}
                      </Button>
                      {errors.options && !Array.isArray(errors.options) && (
                        <span className={styles.error}>
                          {errors.options}
                        </span>)}
                      <Field name={name}>
                        {({
                          field: { value: options },
                          form: { handleChange },
                        }) => (
                          <>
                            {options.map((option, index) => <div key={`option-${index}`} className={styles.options}>
                              <Form.Group>
                                <Form.Field width={2}>
                                  <FormFieldLabel
                                    label={`Option ${index + 1}`}
                                  >
                                  </FormFieldLabel>
                                </Form.Field>
                                <Form.Field width={14}>
                                  <Field as={Form.TextArea} name={`options.${index}`} onChange={handleChange}/>
                                </Form.Field>
                                <span className={styles.iconTrash} onClick={() => remove(index)}/>
                              </Form.Group>
                              {Array.isArray(errors.options) && (
                                <span className={styles.error}>
                                  {errors.options[index]}
                                </span>)}
                            </div>)}
                          </>
                        )}
                      </Field>
                    </>
                  )}
                </FieldArray>
              </>
            )}
          </Form>
        </div>
      </GenericModal>
    )
    }
  </Formik>
);

TokenEditor.defaultProps = {
  initialValues: null,
};

TokenEditor.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

export default TokenEditor;

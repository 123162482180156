import { Auth as AuthAmplify } from 'aws-amplify';
import React from 'react';
import { useAlert } from 'react-alert';
import { useHistory } from 'react-router-dom';

import { useLocationSearchParam } from '../../../hooks';
import { Auth } from '../../layout';
import ChangePasswordForm from './ChangePasswordForm/ChangePasswordForm.component';

const ChangePassword = () => {
  const { push } = useHistory();
  const alert = useAlert();
  const firstLogin = useLocationSearchParam('firstLogin');

  const changePassword = async authData => {
    try {
      let requiredAttributes = [];
      const { email, oldPassword, newPassword } = authData;
      const user = await AuthAmplify.signIn(email, oldPassword);

      if (user?.challengeParam?.requiredAttributes) {
        requiredAttributes = user.challengeParam.requiredAttributes;
      }

      if (firstLogin) {
        await AuthAmplify.completeNewPassword(user, newPassword, requiredAttributes);
        await AuthAmplify.verifyCurrentUserAttribute('email');
        push('/verifyEmail');
      } else {
        await AuthAmplify.changePassword(user, oldPassword, newPassword);
        push('/');
      }
    } catch (error) {
      alert.error(`Error on login process: ${error.message}`);
    }
  };
  return (
    <Auth
      changePassword={authData => changePassword(authData)}
      form={ChangePasswordForm}
      subtitle={() => (
        <>
          {'Introduce your old password and the new password you want to use'}
        </>
      )}
      title="Change password"
    />
  );
};

export default ChangePassword;

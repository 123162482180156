import PropTypes from 'prop-types';
import React from 'react';
import { useAlert } from 'react-alert';
import { useMutation } from 'react-query';
import { Button } from 'semantic-ui-react';

import { entitiesAPI } from '../../../../api';
import { Entity } from '../../../../constants';
import styles from './UsersTableBulkOperations.module.scss';

const DATA_CY = 'users-table-bulk-operations';

const UsersTableBulkOperations = ({ refetch, selectedUsers }) => {
  const alert = useAlert();

  const { mutate } = useMutation(entitiesAPI.bulk, {
    onSuccess: data => {
      if (data.failed?.length > 0) {
        const failIds = data.failed.map(user => user.id).join(', ');
        alert.error(`Error changing status of ${failIds}`);
      }
      if (data.success?.length > 0) {
        const successIds = data.success.map(user => user.id).join(', ');
        alert.success(`Changed status of ${successIds}`);
      }
      refetch();
    },
    onError: error => {
      alert.error(error.message);
    },
  });

  const handleBulkOperation = status => {
    const payload = selectedUsers.map(user => ({
      id: user,
      status,
    }));
    mutate({ entity: Entity.USERS, payload });
  };

  return (
    <div className={styles.root} data-cy={DATA_CY}>
      <Button
        secondary
        data-cy={`${DATA_CY}-activate-users`}
        disabled={selectedUsers.length === 0}
        onClick={() => handleBulkOperation('active')}
      >
        Activate Users
      </Button>

      <Button
        secondary
        data-cy={`${DATA_CY}-inactivate-users`}
        disabled={selectedUsers.length === 0}
        onClick={() => handleBulkOperation('inactive')}
      >
        Inactivate Users
      </Button>
    </div>
  );
};

UsersTableBulkOperations.propTypes = {
  refetch: PropTypes.func.isRequired,
  selectedUsers: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default UsersTableBulkOperations;

import Cookies from 'js-cookie';

import { Cookie /* , ROOT_ELEMENT_ID */ } from '../constants';
// import { cookieENV } from '../utils/getEnv';

const NOT_VALID_VALUES = [null, undefined, '', []];

// TODO: uncomment once we have clear what this configuration means
// const cookieOptions = () => {
//   const rootElement = document.getElementById(ROOT_ELEMENT_ID);
//   if (!rootElement) return {};
//   return rootElement.dataset.environment === 'production' ? {
//     domain: '.servelegal.co.uk',
//     secure: true,
//     sameSite: 'lax',
//   } : {};
// };

const cookieOptions = () => ({
  sameSite: 'None',
  secure: true,
});

const validStorageValue = (value, cb) => {
  if (NOT_VALID_VALUES.includes(value)) return;
  cb();
};

const locStorage = {
  setItem: (key, value) => {
    validStorageValue(value, () => {
      localStorage.setItem(key, value);
    });
  },
  getItem: key => localStorage.getItem(key) || '',
  removeItem: key => localStorage.removeItem(key),
};

const cookieStorage = {
  setItem: (key, value) => {
    validStorageValue(value, () => {
      Cookies.set(key, value, cookieOptions());
    });
  },
  getItem: key => Cookies.get(key, cookieOptions()) || '',
  removeItem: key => Cookies.remove(key, cookieOptions()),
  getSessionToken: () => {
    // TODO: const env = cookieENV();
    const env = 'servelegal';
    const id = `${env}-${Cookie.SESSION_TOKEN}`;
    return Cookies.get(id, cookieOptions()) || '';
  },
  getUsername: () => {
    // TODO: const env = cookieENV();
    const env = 'servelegal';
    const id = `${env}-${Cookie.USERNAME}`;
    return Cookies.get(id, cookieOptions()) || '';
  },
  getRole: () => {
    // TODO: const env = cookieENV();
    const env = 'servelegal';
    const id = `${env}-${Cookie.ROLES}`;
    const roles = Cookies.getJSON(id, cookieOptions()) || [];
    return roles[0];
  },
};

const storage = (type = 'locStorage') => {
  const types = {
    locStorage,
    cookieStorage,
  };
  if (typeof (Storage) !== 'undefined') {
    return types[type];
  }
  return type.cookieStorage;
};

export default storage;

/* eslint-disable react/prop-types */
import React from 'react';

const SLTableCellEnumFormatter = ({ enumObject }) => ({ cell }) => (
  <span>
    {enumObject.find(item => item.value === cell.value)?.text}
  </span>
);

export default SLTableCellEnumFormatter;

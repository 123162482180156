import PropTypes from 'prop-types';
import React from 'react';
import { Link, useParams } from 'react-router-dom';

import { useUserRole } from '../../../../../hooks';
import { FieldScope, fieldVisibility, TableViewActions } from '../../../../../rolePermissionMatrix';
import { SLHeader } from '../../../../common';
import EntityExportMenu from '../../../Entity/EntityTableView/EntityTableHeader/EntityExportMenu/EntityExportMenu.component';
import styles from './AuditsTableHeaderAdminRole.module.scss';

const AuditsTableHeaderAdminRole = ({
  count,
  isFilterVisible,
  setFilterVisible,
}) => {
  const { entity } = useParams();
  const role = useUserRole();

  const DATA_CY = `${entity}-table-header`;

  return (
    <SLHeader
      dataCy={DATA_CY}
      subtitle={count ? `${count} Results Found` : ''}
      title={entity}
    >
      {fieldVisibility({ entity, role, scope: FieldScope.TABLE_VIEW_ACTIONS })(TableViewActions.IMPORT)
        ? (
          <Link data-cy={`${DATA_CY}-upload-link`} to={`/${entity}/upload`}>
            <span className={styles.upload}>
              {`Upload ${entity}`}
            </span>
          </Link>
        ) : null
      }
      {fieldVisibility({ entity, role, scope: FieldScope.TABLE_VIEW_ACTIONS })(TableViewActions.EXPORT)
        ? (
          <EntityExportMenu
            canAddAndEdit={
              fieldVisibility({
                entity, role, scope: FieldScope.TABLE_VIEW_ACTIONS,
              })(TableViewActions.EXPORT_EDIT_TEMPLATE)
            }
          />
        ) : null
      }
      <div
        className={isFilterVisible ? styles.toggleIconOpen : styles.toggleIconClosed}
        data-cy={`${DATA_CY}-filter-toggle`}
        onClick={() => setFilterVisible(!isFilterVisible)}
      />
    </SLHeader>
  );
};

AuditsTableHeaderAdminRole.defaultProps = {
  count: null,
  isFilterVisible: false,
  setFilterVisible: null,
};

AuditsTableHeaderAdminRole.propTypes = {
  count: PropTypes.number,
  isFilterVisible: PropTypes.bool,
  setFilterVisible: PropTypes.func,
};

export default AuditsTableHeaderAdminRole;

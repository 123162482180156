import { Cookie } from '../constants';
// import { cookieENV } from '../utils/getEnv';
import persistence from '.';

const cookieStorage = persistence('cookieStorage');

const setAccess = session => {
  // TODO: const env = cookieENV();
  const env = 'servelegal';
  cookieStorage.setItem(`${env}-${Cookie.SESSION_TOKEN}`, session.jwtToken);
  cookieStorage.setItem(`${env}-${Cookie.USERNAME}`, session.payload['cognito:username']);
  cookieStorage.setItem(`${env}-${Cookie.ROLES}`, session.payload['cognito:groups']);
};

const removeAccess = () => {
  // TODO: const env = cookieENV();
  const env = 'servelegal';
  cookieStorage.removeItem(`${env}-${Cookie.SESSION_TOKEN}`);
  cookieStorage.removeItem(`${env}-${Cookie.USERNAME}`);
  cookieStorage.removeItem(`${env}-${Cookie.ROLES}`);
};

export {
  removeAccess,
  setAccess,
};

import PropTypes from 'prop-types';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import { SLTable } from '../../../../common';

const formattedCell = ({ cell, userData }) => {
  const value = {
    id: <Link to={`/${userData.entity}/${cell.row.original.id}`}>
      {cell.value}
    </Link>,
    default: cell.value,
  };

  return value[cell.column.id] || value.default;
};

const columns = [
  { accessor: 'id', label: 'id'.toUpperCase(), Cell: formattedCell },
];

const EntityTable = ({ data, match: { params: { entity } } }) => (
  <SLTable
    columns={columns}
    data={data}
    dataCy={`${entity}-table`}
    userData={{ entity }}
  />
);

EntityTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      entity: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default withRouter(EntityTable);

import React from 'react';

import { FormFieldWrapper } from '../../../../common';
import FormLayout from '../../RegisterAuditorFormLayout/RegisterAuditorFormLayout.component';
import styles from './RegisterAuditorFormTwelve.module.scss';

const RegisterAuditorFormTwelve = () => (
  <FormLayout subtitle="2 of 2" title="Tell us">
    <span className={styles.titleQuestion}>
      {'We want to learn a bit more about you and why you want to work with us'}
    </span>
    <FormFieldWrapper
      required
      name="why_servelegal"
      placeholder="Why do you want to work with Serve Legal?"
      rows={3}
      type="textarea"
    />
    <span className={styles.titleQuestion}>
      {'Tell us a bit about your self'}
    </span>
    <FormFieldWrapper
      required
      name="about_yourself"
      placeholder="E.g. What you study, where you work, your hobbies, interesting things about you."
      rows={3}
      type="textarea"
    />
    <span className={styles.titleQuestion}>
      {'Can you travel outside your local area?'}
    </span>
    <FormFieldWrapper
      required
      name="travel_outside"
      placeholder="E.g. Where you travel to regularty, and where you\'re willing to go (towns and cities)."
    />
  </FormLayout>
);

export default RegisterAuditorFormTwelve;

import { AuditingSteps } from '../../../../constants';
import isOnline from '../../../../utils/connection';
import AuditingExtraDetails from '../AuditingExtraDetails/AuditingExtraDetails.component';
import AuditingLocation from '../AuditingLocation/AuditingLocation.component';
import AuditingOfflineFinish from '../AuditingOfflineFinish/AuditingOfflineFinish.component';
import AuditingOnlineSender from '../AuditingOnlineSender/AuditingOnlineSender.component';
import AuditingPreview from '../AuditingPreview/AuditingPreview.component';
import AuditingQuestionnaire from '../AuditingQuestionnaire/AuditingQuestionnaire.component';
import AuditingReview from '../AuditingReview/AuditingReview.component';
import AuditingWelcome from '../AuditingWelcome/AuditingWelcome.component';

const AuditingStepsComponents = {
  [AuditingSteps.WELCOME]: AuditingWelcome,
  [AuditingSteps.PREVIEW]: AuditingPreview,
  [AuditingSteps.LOCATION]: AuditingLocation,
  [AuditingSteps.QUESTIONNAIRE]: AuditingQuestionnaire,
  [AuditingSteps.REVIEW]: AuditingReview,
  [AuditingSteps.EXTRA_DETAILS]: AuditingExtraDetails,
  [AuditingSteps.OFFLINE_FINISH]: AuditingOfflineFinish,
  [AuditingSteps.ONLINE_SENDER]: AuditingOnlineSender,
  [AuditingSteps.SUBMITTED]: AuditingOfflineFinish,
};

const calculateNextStep = (currentStep, currentStepData) => {
  const nextStep = {
    [AuditingSteps.WELCOME]: () => (currentStepData.jumpQuestions
      ? isOnline() ? AuditingSteps.LOCATION : AuditingSteps.QUESTIONNAIRE
      : AuditingSteps.PREVIEW),
    [AuditingSteps.PREVIEW]: () => (isOnline() ? AuditingSteps.LOCATION : AuditingSteps.QUESTIONNAIRE),
    [AuditingSteps.LOCATION]: () => AuditingSteps.QUESTIONNAIRE,
    [AuditingSteps.QUESTIONNAIRE]: () => AuditingSteps.REVIEW,
    [AuditingSteps.REVIEW]: () => AuditingSteps.EXTRA_DETAILS,
    [AuditingSteps.EXTRA_DETAILS]: () => (isOnline() ? AuditingSteps.ONLINE_SENDER : AuditingSteps.OFFLINE_FINISH),
    [AuditingSteps.ONLINE_SENDER]: () => null,
    [AuditingSteps.OFFLINE_FINISH]: () => null,
  };

  return nextStep[currentStep](currentStepData);
};

const calculatePreviousStep = currentStep => {
  const previousStep = {
    [AuditingSteps.WELCOME]: null,
    [AuditingSteps.PREVIEW]: () => AuditingSteps.WELCOME,
    [AuditingSteps.LOCATION]: () => AuditingSteps.PREVIEW,
    [AuditingSteps.QUESTIONNAIRE]: () => (isOnline() ? AuditingSteps.LOCATION : AuditingSteps.PREVIEW),
    [AuditingSteps.REVIEW]: () => AuditingSteps.QUESTIONNAIRE,
    [AuditingSteps.EXTRA_DETAILS]: () => AuditingSteps.REVIEW,
    [AuditingSteps.ONLINE_SENDER]: () => null,
    [AuditingSteps.OFFLINE_FINISH]: () => null,
  };

  return previousStep[currentStep]();
};

export {
  AuditingStepsComponents,
  calculateNextStep,
  calculatePreviousStep,
};

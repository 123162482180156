/* eslint-disable no-shadow */
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useField, useFormikContext } from 'formik';
import htmlToDraft from 'html-to-draftjs';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';

import FieldStatus from '../../../fieldLogic/fieldStatus';
import styles from './RichEditor.module.scss';

const RichEditor = ({ name, editorStyle, toolbarStyle, onStateHandler, readOnly }) => {
  const [field, helpers] = useField(name);
  const { setFieldValue } = useFormikContext();

  const prepareDraft = value => {
    const draft = htmlToDraft(value);
    const contentState = ContentState.createFromBlockArray(draft.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };

  const [editorState, setEditorState] = useState(
    field.value ? prepareDraft(field.value) : EditorState.createEmpty(),
  );

  let state = FieldStatus.EDITABLE;

  if (onStateHandler) {
    state = onStateHandler(name);
  }

  if (state === FieldStatus.HIDDEN) {
    return null;
  }

  const onEditorStateChange = editorState => {
    const text = editorState.getCurrentContent().getPlainText();
    if (!text) {
      setFieldValue(name, text);
    } else {
      const forFormik = draftToHtml(
        convertToRaw(editorState.getCurrentContent()),
      ).replace(/(\n|\r\n)$/, '');
      setFieldValue(name, forFormik);
    }
    setEditorState(editorState);
  };

  return (
    <>
      <Editor
        editorState={editorState}
        editorStyle={editorStyle}
        placeholder="Write you text"
        readOnly={readOnly}
        toolbar={{
          options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'history'],
          inline: {
            inDropdown: false,
            options: ['bold', 'italic', 'underline', 'strikethrough'],
          },
          list: {
            inDropdown: false,
            options: ['unordered', 'ordered'],
          },
        }
        }
        toolbarStyle={toolbarStyle}
        onEditorStateChange={onEditorStateChange}
      />
      {helpers.error && typeof helpers.error === 'string' && (
        <span className={styles.errorLabel}>
          {helpers.error}
        </span>
      )}
    </>);
};

RichEditor.propTypes = {
  name: PropTypes.string.isRequired,
  editorStyle: PropTypes.object,
  readOnly: PropTypes.bool,
  toolbarStyle: PropTypes.object,
  onStateHandler: PropTypes.func,

};

RichEditor.defaultProps = {
  editorStyle: null,
  name: null,
  onStateHandler: null,
  readOnly: false,
  toolbarStyle: null,
};

export default RichEditor;

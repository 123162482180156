import classnames from 'classnames';
import React from 'react';
import { useQuery } from 'react-query';
import { Divider } from 'semantic-ui-react';

import { entitiesAPI } from '../../../../api';
import { Entity } from '../../../../constants';
import isMobile from '../../../../utils/isMobile';
import styles from './TipsAndDocsNewsfeed.module.scss';

const TipsAndDocsNewsfeed = () => {
  const { data: newsfeed } = useQuery(
    'newsfeed',
    () => entitiesAPI.fetchAll(Entity.NEWSFEED),
  );

  return (
    <div className={classnames({ [styles.newsFeedMobile]: isMobile }, { [styles.newsFeed]: !isMobile })}>
      <h3>
        {'Newsfeed'}
      </h3>
      {newsfeed ? newsfeed?.map((item, key) => (
        <>
          <Divider />
          <div
            key={key}
            className={styles.newsItem}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: item.content }}
          />
        </>
      )) : null}
    </div>
  );
};

export default TipsAndDocsNewsfeed;

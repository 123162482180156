import React from 'react';
import { Card, Placeholder } from 'semantic-ui-react';

import styles from './Fallback.module.scss';

const Fallback = () => (
  <Card fluid className={styles.root}>
    <Card.Content>
      <Placeholder>
        <Placeholder.Image rectangular />
      </Placeholder>
    </Card.Content>
  </Card>
);

export default Fallback;

import { Formik } from 'formik';
import _debounce from 'lodash/debounce';
import _pickBy from 'lodash/pickBy';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'semantic-ui-react';

import { DEBOUNCE_MS } from '../../../../constants';
import { enumOptionsSelector } from '../../../../state/constants/selectors';
import { FormFieldSelectWrapper, FormFieldWrapper } from '../../../common';
import { userFilterDefaultValues } from '../helpers';

const DATA_CY = 'users-table-filter';

const statusTypeOptionsSelector = state => enumOptionsSelector(state, 'status_type');
const userRoleOptionsSelector = state => enumOptionsSelector(state, 'user_role_type');

const UsersTableFilter = ({ values: initialValues, onChange }) => {
  const statusTypeOptions = useSelector(statusTypeOptionsSelector);
  const userRoleOptions = useSelector(userRoleOptionsSelector);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={values => onChange(_pickBy(values))}
    >
      {({ handleSubmit, setFieldValue }) => {
        const debounceSubmit = _debounce(handleSubmit, DEBOUNCE_MS);
        const handleFieldChange = () => {
          debounceSubmit();
        };

        const handleSelectChange = (name, value) => {
          setFieldValue(name, value || null);
          handleSubmit();
        };

        return (
          <Form inverted data-cy={DATA_CY}>
            <Form.Group widths="equal">
              <FormFieldWrapper
                label="Name"
                name="name"
                onChange={handleFieldChange}
              />

              <FormFieldSelectWrapper
                clearable
                label="Status"
                name="status"
                options={statusTypeOptions}
                placeholder="Select ..."
                onChange={value => handleSelectChange('status', value)}
              />

              <FormFieldSelectWrapper
                clearable
                label="Role"
                name="role"
                options={userRoleOptions}
                placeholder="Select ..."
                onChange={value => handleSelectChange('role', value)}
              />
            </Form.Group>
          </Form>
        );
      }}
    </Formik>
  );
};

UsersTableFilter.defaultProps = {
  values: userFilterDefaultValues,
};

UsersTableFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    role: PropTypes.string,
    status: PropTypes.string,
  }),
};

export default UsersTableFilter;

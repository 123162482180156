import React, { useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';

import styles from './InstallPWA.module.scss';

const InstallPWA = () => {
  const [modalProps, setModalProps] = useState({
    open: false,
    visibleFields: [],
  });

  const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
  if (navigator.standalone || isStandalone) {
    return null;
  }

  const handleModalProps = open => {
    setModalProps({
      open,
    });
  };

  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const instructions = () => {
    if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
      return <span>
        {'Open this website from a Safari browser, tap the share button and then \'Add to Homescreen\''}
      </span>;
    }
    if (userAgent.match(/Android/i)) {
      return <span>
        {'Tap browser menu and then \'Add to Homescreen\''}
      </span>;
    }
    return <span>
      {'You must add the web manually to your Homescreen.'}
    </span>;
  };

  return (
    <>
      <Modal
        closeOnDimmerClick={true}
        open={modalProps.open}
      >
        <Modal.Content>
          {instructions()}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => handleModalProps(false)}>
            {'Close'}
          </Button>
        </Modal.Actions>
      </Modal>
      <div
        className={styles.tabMobile}
        onClick={() => handleModalProps(true)}
      >
        <span className={styles.tabIcon} />
        <span className={styles.tabLabel}>
          {'Install'}
        </span>
      </div>
    </>
  );
};

export default InstallPWA;

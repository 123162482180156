import React, { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';

import { accountAPI, entitiesAPI } from '../../../../api';
import { Entity, PageLimit, SortRule, UserRole } from '../../../../constants';
import history from '../../../../history';
import { useUserRole } from '../../../../hooks';
import isOnline from '../../../../utils/connection';
import entityFilterMap from '../../../../utils/entityFilterMap';
import { parse, stringify } from '../../../../utils/queryString';
import useEntityRenders from '../../../../utils/useEntityRenders';
import { LoadingPlaceholder, SingleLoadingPlaceholder } from '../../../common';
import { View } from '../../../layout';
import {
  body,
  bodyProps,
  bottombar,
  bottombarProps,
  defaultQuery,
  filter,
  filterProps,
  header,
  headerProps,
  sidebar,
  sidebarProps } from './helpers';

const setQueryStringWithoutPageReload = (locationPathname, queryStringValue) => {
  history.push(`${locationPathname}?${queryStringValue}`);
};

const parseQueryParams = queryStringValue => {
  const currentQuery = parse(queryStringValue);
  const { page, sort, order, limit, ...filterValues } = currentQuery;
  return { page, sort, order, limit, filterValues };
};

const EntityTableView = () => {
  const { entity } = useParams();
  const location = useLocation();
  const role = useUserRole();
  const online = isOnline();

  const [currentEntity, setCurrentEntity] = useState(null);
  const [isFilterVisible, setFilterVisible] = useState(true);
  const [query, setQuery] = useState({ entity, queryString: defaultQuery(entity, role) });

  const [currentPage, setCurrentPage] = useState(() => {
    const { page } = parseQueryParams(location.search);
    return page || 1;
  });

  const [currentLimit, setCurrentLimit] = useState(() => {
    const { limit } = parseQueryParams(location.search);
    return limit || PageLimit.SMALL;
  });

  const [currentFilter, setCurrentFilter] = useState(() => {
    const { filterValues } = parseQueryParams(location.search);
    return filterValues || {};
  });

  const [currentSortBy, setCurrentSortBy] = useState(() => {
    const { sort, order } = parseQueryParams(location.search);
    return sort ? { sort, order } : {};
  });

  const { data, error, isFetching, refetch } = useQuery(
    [query.entity, query.queryString],
    () => entitiesAPI.fetchAll(query.entity, query.queryString),
    { enabled: online },
  );

  const { data: fetchedAuditor } = useQuery(
    ['myAuditorAccount'],
    () => accountAPI.fetchAuditorAccount(),
    { enabled: role === UserRole.AUDITOR },
  );

  const { data: user } = useQuery(
    ['myAccount'],
    () => accountAPI.fetchUserAccount(),
    { enabled: Boolean(role === UserRole.AREA_MANAGER) },
  );
  const userRenders = useEntityRenders(entity);

  useEffect(() => {
    setCurrentEntity(entity);

    const currentQuery = parse(location.search);
    const { limit, page, sort, order, ...filterValues } = currentQuery;

    const parsedPage = page ? Number.parseInt(page, 10) : 1;
    setCurrentPage(parsedPage);
    const parsedLimit = limit ? Number.parseInt(limit, 10) : PageLimit.SMALL;
    setCurrentLimit(parsedLimit);
    setCurrentSortBy(sort ? { sort, order } : {});
    setCurrentFilter(entityFilterMap({ entity, filterValues, sitePatchId: user?.site_patch_id, userRenders }));

    const newQueryString = {
      ...defaultQuery(entity, role),
      ...currentQuery,
    };

    setQuery({
      entity,
      queryString: newQueryString,
    });

    setQueryStringWithoutPageReload(location.pathname, stringify(newQueryString));

    // FIXME:
    if (user?.role === UserRole.AREA_MANAGER && userRenders < 3 && entity === Entity.AUDITS) {
      history.push(`?page=1&site_patch_id=${user?.site_patch_id}`);
    }
  }, [entity, location.search, user, userRenders, role]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFilterChange = useCallback(
    filterValues => {
      const currentQuery = parse(location.search);
      const { limit, sort, order } = currentQuery;
      // If filter changes, force to first page
      const newQueryString = stringify({ page: 1, limit, sort, order, ...filterValues });
      setCurrentPage(1);
      setCurrentFilter(filterValues);
      setQueryStringWithoutPageReload(location.pathname, newQueryString);
    },
    [location],
  );

  const handleFilterChangeArchive = useCallback(
    filterValues => {
      const newQueryString = stringify({ ...filterValues });
      setCurrentFilter(filterValues);
      setQueryStringWithoutPageReload(location.pathname, newQueryString);
    },
    [location],
  );

  const handlePageChange = useCallback(
    pageValue => {
      const currentQuery = parse(location.search);
      const { page, ...rest } = currentQuery;
      const newQueryString = stringify({ page: pageValue, ...rest });
      setCurrentPage(pageValue);
      setQueryStringWithoutPageReload(location.pathname, newQueryString);
    },
    [location],
  );

  const handleLimitChange = useCallback(
    limitValue => {
      const currentQuery = parse(location.search);
      const { limit, ...rest } = currentQuery;
      const newQueryString = stringify({ limit: limitValue, ...rest });
      setCurrentLimit(limitValue);
      setQueryStringWithoutPageReload(location.pathname, newQueryString);
    },
    [location],
  );

  const handleSortByChange = useCallback(
    sortByValue => {
      const currentQuery = parse(location.search);
      const { sort, order, page, ...rest } = currentQuery;

      let newOrder = SortRule.ASCENDING;

      if (sortByValue === currentSortBy.sort) {
        if (currentSortBy.order === SortRule.DESCENDING) {
          newOrder = SortRule.ASCENDING;
        } else {
          newOrder = SortRule.DESCENDING;
        }
      }
      const newSortValue = {
        sort: sortByValue,
        order: newOrder,
      };

      // If sort by changes, force to first page
      const newQueryString = stringify({
        page: 1,
        ...newSortValue,
        ...rest,
      });
      setCurrentPage(1);
      setCurrentSortBy(newSortValue);
      setQueryStringWithoutPageReload(location.pathname, newQueryString);
    },
    [location, currentSortBy],
  );

  return (
    <View
      condensed
      body={!isFetching ? body(query.entity) : LoadingPlaceholder}
      bodyProps={bodyProps({
        currentLimit,
        currentPage,
        currentSortBy,
        data,
        entity: query.entity,
        error,
        handlePageChange,
        handleLimitChange,
        handleSortByChange,
        isFetching,
        refetch,
      })}
      bottombar={bottombar({ entity, location, role })}
      bottombarProps={bottombarProps({ entity, data, fetchedAuditor })}
      dataCy={`${query.entity}-table-view`}
      filter={filter(query.entity)}
      filterProps={filterProps({
        entity: query.entity,
        handleFilterChange,
        handleFilterChangeArchive,
        values: currentFilter })}
      header={entity !== currentEntity ? SingleLoadingPlaceholder : header(query.entity)}
      headerProps={headerProps({
        data,
        entity: query.entity,
        isFilterVisible,
        setFilterVisible,
      })}
      isFilterVisible={isFilterVisible}
      setFilterVisible={setFilterVisible}
      sidebar={sidebar({ entity })}
      sidebarProps={sidebarProps({ entity, data })}
    />
  );
};

export default EntityTableView;

import { FieldArray, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Card, Grid } from 'semantic-ui-react';

import { DefaultSurveyResultType } from '../../../../../constants';
import FieldStatus from '../../../../../fieldLogic/fieldStatus';
import getOrdersFieldStatus from '../../../../../fieldLogic/orders';
import { enumOptionsSelector } from '../../../../../state/constants/selectors';
import { FormFieldSelectWrapper, RichEditor } from '../../../../common';
import { uuidType } from '../../../../types';
import styles from './OrderDetailsCopy.module.scss';

const DATA_CY = 'order-details-copy';

const defaultSurveyResultTypeSelector = state => enumOptionsSelector(state, 'report_trigger_type');

const OrderDetailsCopy = ({ name, role, entityId, revisionId }) => {
  const { values } = useFormikContext();
  const defaultSurveyResultOptions = useSelector(defaultSurveyResultTypeSelector);

  return (
    <FieldArray name={name}>
      {({ push, remove }) => (
        <Card fluid>
          <Card.Content extra className={styles.headerContainer}>
            <Card.Header className={styles.header}>
              Email Copy
            </Card.Header>
          </Card.Content>
          <Card.Content>
            <Grid columns={3} divided="vertically" verticalAlign="top">
              {values?.[name] && values[name].map((_value, index) => (
                <Grid.Row key={index}>
                  <Grid.Column width={6}>
                    <FormFieldSelectWrapper
                      label="Result Type"
                      name={`${name}.${index}.result`}
                      options={defaultSurveyResultOptions}
                      onStateHandler={getOrdersFieldStatus(role, entityId === undefined, revisionId)}
                    />
                  </Grid.Column>
                  <Grid.Column width={9}>
                    <RichEditor
                      name={`${name}.${index}.text`}
                    />

                  </Grid.Column>
                  {getOrdersFieldStatus(role, entityId === undefined, revisionId)(name) === FieldStatus.EDITABLE ? (
                    <Grid.Column width={1}>
                      <span
                        className={styles.remove}
                        data-cy={`${DATA_CY}-${index}-remove`}
                        onClick={() => remove(index)}
                      />
                    </Grid.Column>
                  ) : null}
                </Grid.Row>
              ))}
              {getOrdersFieldStatus(role, entityId === undefined, revisionId)(name) === FieldStatus.EDITABLE ? (
                <Grid.Row>
                  <Grid.Column width={1}>
                    <span
                      className={styles.add}
                      data-cy={`${DATA_CY}-add`}
                      onClick={() => push({
                        result: DefaultSurveyResultType.PASS,
                        text: '',
                      })}
                    />
                  </Grid.Column>
                </Grid.Row>) : null}
            </Grid>
          </Card.Content>
        </Card>
      )}
    </FieldArray>
  );
};

OrderDetailsCopy.defaultProps = {
  revisionId: null,
};

OrderDetailsCopy.propTypes = {
  entityId: uuidType.isRequired,
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  revisionId: uuidType,
};

export default OrderDetailsCopy;

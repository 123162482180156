import PropTypes from 'prop-types';
import React from 'react';

import styles from './AuditingQuestionHeader.module.scss';

const DATA_CY = 'auditing-header';

const AuditingQuestionHeader = ({ question }) => (
  <div data-cy={DATA_CY}>
    <h3>
      {question.title}
      {question.required ? (
        <span className={styles.required}>
          {' *'}
        </span>
      ) : null}
    </h3>
    <span className={styles.auditorInfo}>
      {question.auditor_information}
    </span>
    <div className={styles.help}>
      {question.help}
    </div>
  </div>
);

AuditingQuestionHeader.propTypes = {
  question: PropTypes.object.isRequired,
};

export default AuditingQuestionHeader;

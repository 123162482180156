import peg from 'pegjs';

import { QuestionType } from '../../../../constants';
import grammar from './grammar';

const logicParser = peg.generate(grammar);

const replaceValuesOnQuery = ({
  answers,
  logic,
}) => {
  let parsedLogic = logic;

  const questionInternalIdTokens = logic.match(/Q\d+/g);

  if (questionInternalIdTokens) {
    questionInternalIdTokens.forEach(token => {
      const foundAnswer = answers.find(answer => answer.internal_id === token);
      if (foundAnswer) {
        const value = foundAnswer.value !== null ? foundAnswer.value : null;
        parsedLogic = parsedLogic.replace(token, value instanceof Array ? `[${value.join(',')}]` : `"${value}"`);
      }
    });
  }

  return parsedLogic;
};

const evaluateQuestionBasedOnBranching = ({
  answers,
  question,
  branching,
}) => {
  const rulesToApply = branching.filter(item => item.hidden_questions_ids.some(id => id === question.id));

  const hidden = rulesToApply.find(rule => {
    const parsedRule = replaceValuesOnQuery({ answers, logic: rule.logic });

    try {
      return logicParser.parse(parsedRule);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Parser failed for branching ${rule}:, ${error.message}`);
      return false;
    }
  }) !== undefined;

  return hidden;
};

export const answerValidationMessage = ({
  answers,
  audit,
  currentAnswerValue,
  currentQuestion,
}) => {
  // Temporally modify current answers to evaluate the rules with the new answer
  const foundAnswerIndex = answers.findIndex(a => a.id === currentQuestion.id);
  const tempAnswers = [...answers];
  tempAnswers.splice(foundAnswerIndex, 1, {
    ...answers[foundAnswerIndex],
    value: currentAnswerValue,
  });

  // Evaluate validation rules
  const rulesToApply = audit.revision_validation.filter(rule => rule.logic.match(currentQuestion.internal_id));

  const foundRuleIndex = rulesToApply.findIndex(rule => {
    const parsedRule = replaceValuesOnQuery({ logic: rule.logic, answers: tempAnswers });
    try {
      const matchesRule = logicParser.parse(parsedRule);
      return !parsedRule.match(/Q\d+/g) && matchesRule;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Parser failed for validation ${rule}:, ${error.message}`);
      return false;
    }
  });

  return foundRuleIndex !== -1 ? rulesToApply[foundRuleIndex].message : null;
};

export const submitAnswer = ({
  answers,
  audit,
  currentAnswerValue,
  currentQuestion,
}) => {
  // Temporally modify current answers to evaluate the rules with the new answer
  const foundAnswerIndex = answers.findIndex(a => a.id === currentQuestion.id);
  const tempAnswers = [...answers];
  tempAnswers.splice(foundAnswerIndex, 1, {
    ...answers[foundAnswerIndex],
    value: currentAnswerValue,
  });

  audit.revision_questions.forEach(question => {
    const answerIndex = tempAnswers.findIndex(answer => answer.id === question.id);
    const hideQuestion = evaluateQuestionBasedOnBranching({
      question, answers: tempAnswers, branching: audit.revision_branching,
    });

    tempAnswers.splice(answerIndex, 1, {
      ...tempAnswers[answerIndex],
      value: hideQuestion ? null : tempAnswers[answerIndex].value,
      hidden: hideQuestion,
    });
  });

  return tempAnswers;
};
export const defaultAnswer = question => {
  const value = {
    [QuestionType.CHECKBOX]: [],
    [QuestionType.CURRENCY]: 0,
    [QuestionType.DATE]: null,
    [QuestionType.DATETIME]: null,
    [QuestionType.IMAGE]: null,
    [QuestionType.INTERVAL]: { start: null, end: null },
    [QuestionType.NUMBER]: question.config?.defaultValue,
    [QuestionType.RADIO]: null,
    [QuestionType.SELECT]: null,
    [QuestionType.TEXT]: question.config?.defaultValue,
    [QuestionType.TEXTAREA]: question.config?.defaultValue,
    [QuestionType.TIME]: null,
    [QuestionType.TITLE]: null,
  };

  return value[question.type] || null;
};

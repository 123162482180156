import PropTypes from 'prop-types';
import React from 'react';
import { Placeholder } from 'semantic-ui-react';

import styles from './LoadingPlaceholder.module.scss';

const LoadingPlaceholderType = {
  LINES: 'lines',
  PARAGRAPH: 'paragraph',
};

const placeholder = {
  [LoadingPlaceholderType.LINES]: count => (
    <Placeholder className={styles.placeholder}>
      {[...Array(count)].map((_item, index) => <Placeholder.Line key={index} />)}
    </Placeholder>
  ),
  [LoadingPlaceholderType.PARAGRAPH]: count => (
    [...Array(count)].map((_item, index) => (
      <Placeholder key={index} className={styles.placeholder}>
        <Placeholder.Header>
          <Placeholder.Line length="full" />
        </Placeholder.Header>
        <Placeholder.Paragraph>
          <Placeholder.Line length="medium" />
          <Placeholder.Line length="short" />
        </Placeholder.Paragraph>
      </Placeholder>
    ))
  ),
  default: () => (
    <Placeholder className={styles.placeholder}>
      <Placeholder.Line length="medium" />
    </Placeholder>
  ),
};

const LoadingPlaceholder = ({ type, count }) => (placeholder[type] ? placeholder[type](count) : placeholder.default);

LoadingPlaceholder.defaultProps = {
  count: 6,
  type: LoadingPlaceholderType.LINES,
};

LoadingPlaceholder.propTypes = {
  count: PropTypes.number,
  type: PropTypes.oneOf([LoadingPlaceholderType.LINES, LoadingPlaceholderType.PARAGRAPH]),
};

export default LoadingPlaceholder;
export { LoadingPlaceholderType };

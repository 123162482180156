import React from 'react';
import { Button } from 'semantic-ui-react';

import history from '../../../../history';
import styles from './AuditingSuccess.module.scss';

const AuditingSuccess = () => (
  <div className={styles.content}>
    <i className={styles.fireworksIcon} />
    <h1>
      {'Congratulations!'}
    </h1>
    <Button
      primary
      size="large"
      onClick={() => history.push('/audits?status=assigned')}
    >
      {'Back to audits'}
    </Button>
  </div>
);

export default AuditingSuccess;

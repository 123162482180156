import React, { useCallback, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { surveyRevisionsAPI } from '../../../../../api';
import { Entity, SurveyEntity } from '../../../../../constants';
import history from '../../../../../history';
import { useUpdateSurveyV2 } from '../../../../../hooks';
import { LoadingPlaceholder } from '../../../../common';
import GenericModal from '../../../../common/GernericModal/GenericModal.component';
import SurveyStep from '../../SurveyStep/SurveyStep.component';
import { errorConditions } from './helpers';
import deflatConditions from './helpers/deflatConditions';
import flatConditions from './helpers/flatConditions';
import SurveyMatchingEditor from './SurveyMatchingEditor/SurveyMatchingEditor.component';

const SurveyMatching = () => {
  const { entityId, revisionId } = useParams();
  const submitFormRef = useRef(null);
  const [isConditionsModalOpen, setIsConditionsModalOpen] = useState(false);
  const [errorRuleName, setErrorRuleName] = useState(null);

  const { data } = useQuery(
    [entityId, revisionId],
    () => surveyRevisionsAPI.fetchOne(entityId, revisionId),
  );

  const onCloseModal = () => setIsConditionsModalOpen(false);

  const { mutate: updateSurvey } = useUpdateSurveyV2({
    currentStep: SurveyEntity.MATCHING,
  });

  const back = useCallback(() => {
    history.push(`/${Entity.SURVEYS}/${entityId}/revisions/${revisionId}/${SurveyEntity.LOGIC}`);
  }, [entityId, revisionId]);

  const addConditionIds = (matchings = []) => matchings?.map(matching => ({
    ...matching,
    conditions: matching.conditions?.map(condition => ({
      ...condition,
      id: uuidv4(),
    })),
  }));

  const handleSubmit = useCallback(values => {
    // FIXME: [SURVEY BLOCKED] uncomment when ServeLegal agrees
    // if (data?.blocked) {
    //   next();
    // } else {
    const deflatMatchings = deflatConditions(addConditionIds(values.matching));
    const errorRule = errorConditions(deflatMatchings);
    if (errorRule) {
      setErrorRuleName(values.matching[errorRule].rule_name);
      setIsConditionsModalOpen(true);
      return;
    }
    updateSurvey({
      surveyId: entityId,
      revisionId,
      payload: {
        [SurveyEntity.MATCHING]: deflatMatchings,
      },
    });
    // }
  }, [entityId, revisionId, updateSurvey]);

  return (
    <>
      <SurveyStep
        back={back}
        backContent="Back to Logic"
        editor={data === undefined ? LoadingPlaceholder : SurveyMatchingEditor}
        editorProps={{
          matching: flatConditions(data?.matching),
          completeQuestions: data?.questions,
          submitFormRef,
          onSubmit: handleSubmit }}
        headerProps={{ blocked: data?.blocked }}
        next={() => submitFormRef?.current?.click()}
        nextContent={/* data?.blocked ? 'Go to Surveys list' : */'Save & Continue'}
      />
      <GenericModal
        buttons={[
          { label: 'Close', func: onCloseModal, isPrimary: true },
        ]}
        open={isConditionsModalOpen}
        onDismiss={onCloseModal}
      >
There are invalid nesting conditions in rule
        <strong>
        &nbsp;
          {errorRuleName}
        </strong>
. Please, fix conditions (AND/OR) before saving
      </GenericModal>
    </>
  );
};

export default SurveyMatching;

import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { Button, Form } from 'semantic-ui-react';

import styles from './FileUpload.module.scss';

const DATA_CY = 'file-upload';

const FileUpload = ({
  accept,
  buttonDisabled,
  dataCy,
  error,
  help,
  label,
  onChange,
  required,
  subLabel,
}) => {
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);

  return (
    <>
      <input
        ref={fileInputRef}
        hidden
        accept={accept}
        type="file"
        onChange={async e => {
          const file = e.target?.files?.[0] || null;
          setSelectedFile(file);

          if (onChange) {
            onChange(file);
          }
        }}
      />
      <Form.Field required={required}>
        <label data-cy={`${dataCy}-title`}>
          {label}
        </label>
        {subLabel ? (
          <span data-cy={`${dataCy}-sublabel`}>
            {subLabel}
          </span>
        ) : null}
        <Form.Input
          readOnly
          action={
            <Button
              data-cy={`${dataCy}-button`}
              disabled={buttonDisabled}
              onClick={() => fileInputRef.current.click()}
            >
              {'Upload'}
            </Button>
          }
          data-cy={`${dataCy}-field`}
          placeholder="Select file..."
          required={required}
          value={selectedFile?.name}
        />
        {error || null}
        {help ? (
          <div className={styles.help} data-cy={`${dataCy}-help`}>
            {help}
          </div>
        ) : null}
      </Form.Field>
    </>
  );
};

FileUpload.defaultProps = {
  accept: null,
  buttonDisabled: false,
  dataCy: DATA_CY,
  error: null,
  help: null,
  label: '',
  onChange: null,
  required: false,
  subLabel: null,
};

FileUpload.propTypes = {
  accept: PropTypes.string,
  buttonDisabled: PropTypes.bool,
  dataCy: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  help: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  subLabel: PropTypes.string,
  onChange: PropTypes.func,
};

export default FileUpload;

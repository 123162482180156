import { Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'semantic-ui-react';

import { SurveyQuestionsContext } from '../../../../../../../../context';
import { enumOptionsSelector } from '../../../../../../../../state/constants/selectors';

const DATA_CY = 'survey-title-question';

const questionCategoryOptionsSelector = state => enumOptionsSelector(state, 'question_category');

const SurveyTitleQuestion = ({ index }) => {
  const questionCategoryOptions = useSelector(questionCategoryOptionsSelector);

  return (
    <SurveyQuestionsContext.Consumer>
      {({ selectedQuestionIndex }) => (
        <>
          <Field name={`questions.${index}.title`}>
            {({
              field: { name, value },
              form: { handleChange },
              meta: { error },
            }) => (selectedQuestionIndex === index ? (
              <Form.Input
                fluid
                required
                data-cy={`${DATA_CY}-${index}-title-input`}
                error={error}
                label="Title"
                name={name}
                value={value}
                onChange={handleChange}
              />
            ) : (
              <h1 data-cy={`${DATA_CY}-${index}-title`}>
                {value}
              </h1>
            ))}
          </Field>
          {selectedQuestionIndex === index ? (
            <Field name={`questions.${index}.category`}>
              {({
                field: { name, value },
                form: { values, setFieldValue },
                meta: { error },
              }) => (
                <Form.Select
                  clearable
                  data-cy={`${DATA_CY}-${index}-category-input`}
                  disabled={values.questions[index].id !== undefined}
                  error={error}
                  id={name}
                  label="Category"
                  options={questionCategoryOptions}
                  value={value}
                  onChange={
                    (_e, { value: selectValue }) => setFieldValue(name, selectValue.length > 0 ? selectValue : null)
                  }
                />
              )}
            </Field>
          ) : <span />
          }
        </>
      )}
    </SurveyQuestionsContext.Consumer>
  );
};

SurveyTitleQuestion.propTypes = {
  index: PropTypes.number.isRequired,
};

export default SurveyTitleQuestion;

import { Field } from 'formik';
import React from 'react';

import { QuestionType } from '../../../../constants';
import AuditingCurrencyQuestion from './AuditingCurrencyQuestion/AuditingCurrencyQuestion.component';
import AuditingDateTimeQuestion from './AuditingDateTimeQuestion/AuditingDateTimeQuestion.component';
import AuditingImageQuestion from './AuditingImageQuestion/AuditingImageQuestion.component';
import AuditingSelectorQuestion from './AuditingSelectorQuestion/AuditingSelectorQuestion.component';
import AuditingTextQuestion from './AuditingTextQuestion/AuditingTextQuestion.component';
import AuditingTitleQuestion from './AuditingTitleQuestion/AuditingTitleQuestion.component';

const questionComponent = {
  [QuestionType.CHECKBOX]: AuditingSelectorQuestion,
  [QuestionType.CURRENCY]: AuditingCurrencyQuestion,
  [QuestionType.DATE]: AuditingDateTimeQuestion,
  [QuestionType.DATETIME]: AuditingDateTimeQuestion,
  [QuestionType.IMAGE]: AuditingImageQuestion,
  [QuestionType.INTERVAL]: AuditingDateTimeQuestion,
  [QuestionType.NUMBER]: AuditingTextQuestion,
  [QuestionType.RADIO]: AuditingSelectorQuestion,
  [QuestionType.SELECT]: AuditingSelectorQuestion,
  [QuestionType.TEXT]: AuditingTextQuestion,
  [QuestionType.TEXTAREA]: AuditingTextQuestion,
  [QuestionType.TIME]: AuditingDateTimeQuestion,
  [QuestionType.TITLE]: AuditingTitleQuestion,
};

const AuditingQuestion = () => (
  <Field name="question">
    {({ field: { value: question } }) => {
      const Question = questionComponent[question.type] || null;
      return Question
        ? <Question key={question.id}/>
        : null;
    }}
  </Field>
);

export default AuditingQuestion;

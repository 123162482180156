import cs from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from 'semantic-ui-react';

import isMobile from '../../../utils/isMobile';
import styles from './AlertTemplate.module.scss';

const AlertTemplate = ({ options, message, close }) => (
  <div className={cs(styles.root, { [styles.rootMobile]: isMobile })} >
    {options.type === 'info' && <Icon name="info" size="big" />}
    {options.type === 'success' && <Icon className={styles.success} name="checkmark" size="big" />}
    {options.type === 'error' && <Icon className={styles.error} name="warning" size="big" />}
    <div className={styles.message}>
      {message}
    </div>
    <Icon circular className={styles.close} name="close" size="large" onClick={close}/>
  </div>
);

AlertTemplate.propTypes = {
  close: PropTypes.func.isRequired,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  options: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }).isRequired,
};

export default AlertTemplate;

import { UserRole } from '../../constants';
import { TableViewActions } from '../helpers';

const tableViewActions = {
  [UserRole.AREA_MANAGER]: [
    TableViewActions.EXPORT,
  ],
  [UserRole.CLIENT_SERVICES]: [
    TableViewActions.EXPORT,
  ],
  [UserRole.DATA]: [
    TableViewActions.EXPORT_EDIT_TEMPLATE,
    TableViewActions.EXPORT,
  ],
};

export default {
  auditors: {
    tableViewActions,
  },
};

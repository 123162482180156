import React from 'react';
import { useQuery } from 'react-query';

import { legalAPI } from '../../../../../api';
import { FormFieldCheckboxWrapper } from '../../../../common';
import FormLayout from '../../RegisterAuditorFormLayout/RegisterAuditorFormLayout.component';
import styles from './RegisterAuditorFormThirteen.module.scss';

const RegisterAuditorFormThirteen = () => {
  const { data: activePrivacyNotice } = useQuery(
    'privacyNotice',
    () => legalAPI.fetchActivePrivacy(),
  );

  return (
    <FormLayout subtitle="Serve Legal treats its auditor's data with the utmost respect and always considers individual privacy. By selecting options A. and B. you will be able to accept work relating to emerging facial recognition/age estimation technology. These preferences can be later changed in the account settings of your profile" title="GDPR and Privacy Notice" >

      <FormFieldCheckboxWrapper
        labelAfterCheckbox
        label="I consent to Serve Legal using my facial images (and meta data) and other personal data and sharing such data with their clients for the purpose of facial recognition verification and in relation to the services Serve Legal provides."
        name="facial_images_consent"
      />

      <FormFieldCheckboxWrapper
        labelAfterCheckbox
        label="I consent to Serve Legal using my special category data such as my ethnicity and occupational health information and sharing such data with their clients for the purpose of facial recognition verification and in relation to the services Serve Legal provides."
        name="special_category_consent"
      />

      <FormFieldCheckboxWrapper
        labelAfterCheckbox
        label="Please check this box to confirm that you consent to receiving communications from us regarding your Serve Legal employment, job opportunities, and marketing initiatives."
        name="communications_consent"
      />

      <FormFieldCheckboxWrapper
        labelAfterCheckbox
        required
        label={(
          <>
            {'I have read and accept the '}
            <a className={styles.privacyNoticeLink} href={activePrivacyNotice?.url} rel="noreferrer" target="_blank">
              {'Privacy Notice'}
            </a>
          </>
        )}
        name="privacy_notice"
      />

    </FormLayout>
  );
};
export default RegisterAuditorFormThirteen;

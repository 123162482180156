import { RedPushpinMarker } from '../assets/images/markers';
import { TravelModeTypes } from '../constants';

export const itemCoordinates = ({ coords, title, icon, item }) => ({
  position: {
    lat: Number(coords?.lat),
    lng: Number(coords?.lng),
  },
  title: title || item?.name,
  icon: icon || RedPushpinMarker,
  item,
});

export const mapOptions = ({ lat, lng, maxZoom, zoom }) => ({
  center: {
    lat: lat || 53.483959,
    lng: lng || -2.244644,
  },
  maxZoom: maxZoom || null,
  zoom: zoom || 5,
});

export const createLatLng = coordinates => new window.google.maps.LatLng(
  coordinates?.lat,
  coordinates?.lng,
);

export const createMarker = (item, map) => new window.google.maps.Marker({
  position: item.position,
  map,
  icon: item.icon,
  title: item.title,
});

export const configDirections = ({ origin, destination, travelMode, waypoints }) => ({
  origin: new window.google.maps.LatLng(origin),
  destination: new window.google.maps.LatLng(destination),
  travelMode: travelMode || TravelModeTypes.WALKING,
  optimizeWaypoints: true,
  unitSystem: window.google.maps.UnitSystem.IMPERIAL,
  waypoints,
});

let directionsService;
let directionsRenderer;

export const getDirectionsService = () => {
  if (!directionsService) {
    directionsService = new window.google.maps.DirectionsService();
  }
  return directionsService;
};

export const getDirectionsRenderer = () => {
  if (!directionsRenderer) {
    directionsRenderer = new window.google.maps.DirectionsRenderer({ suppressMarkers: true });
  }
  return directionsRenderer;
};

import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Form, Grid, Input } from 'semantic-ui-react';

import { enumOptionsSelector } from '../../../../../state/constants/selectors';
import styles from './AuditorDetailsTab.module.scss';

const maritalTypeSelector = state => enumOptionsSelector(state, 'marital_status_type');
const occupationTypeSelector = state => enumOptionsSelector(state, 'occupation_type');
const accessToCarTypeSelector = state => enumOptionsSelector(state, 'access_to_car_type');
const honorificTypeSelector = state => enumOptionsSelector(state, 'honorific_type');
const circumstancesTypeSelector = state => enumOptionsSelector(state, 'circumstances_type');
const studentLoansTypeSelector = state => enumOptionsSelector(state, 'student_loans_type');

const AuditorDetailsTab = ({ data, dataCy, display }) => {
  const optionsSelector = {
    marital_status: useSelector(maritalTypeSelector),
    occupation: useSelector(occupationTypeSelector),
    access_to_car: useSelector(accessToCarTypeSelector),
    honorific: useSelector(honorificTypeSelector),
    circumstances: useSelector(circumstancesTypeSelector),
    student_loans: useSelector(studentLoansTypeSelector),
  };

  return (
    <Form>
      <Grid columns={display.length}>
        <Grid.Row>
          {display.map((column, columnIndex) => (
            <Grid.Column key={columnIndex}>
              {column.map((row, rowIndex) => {
                const text = optionsSelector[row.value]
                  ? optionsSelector[row.value].find(item => item.value === data[row.value])?.text
                  : null;
                return (
                  <Form.Field key={rowIndex}>
                    <label className={styles.label} data-cy={`${dataCy}-${row.value}-label`}>
                      {row.label}
                    </label>
                    <Input readOnly transparent data-cy={`${dataCy}-${row.value}-value`}>
                      {row.formatter
                        ? row.formatter(row.value, data)
                        : text || data[row.value] || row.defaultValue}
                    </Input>
                  </Form.Field>
                );
              })}
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </Form>
  );
};

AuditorDetailsTab.propTypes = {
  data: PropTypes.object.isRequired, // TODO: auditorType.isRequired,
  dataCy: PropTypes.string.isRequired,
  display: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
    ),
  ).isRequired,
};

export default AuditorDetailsTab;

import PropTypes from 'prop-types';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Icon, List } from 'semantic-ui-react';

import { DocumentsConst } from '../../../constants';
import styles from './DropDownTable.module.scss';

const DropDownTable = ({
  dragEnd, dataCy, canEdit, handleListItemClick, itemsTree,
  openModalForFolder, startRemovingItem, startRenamingItem,
  startReplacingItem, defaultItemIcon, startRemovingFolder }) => {
  const getIconByMimeType = mimetype => DocumentsConst.MYME_TYPE_MAP[mimetype] || defaultItemIcon;

  const getIconName = d => (!d.subRows
    ? getIconByMimeType(d.mimetype)
    : d.expanded
      ? 'folder open'
      : 'folder');

  const displayDescription = (doc, index) => (
    <div className={styles.row}>
      {doc.subRows ? (
        <Droppable droppableId={doc.id}>
          {provided => (
            <div className={styles.folderLine} {...provided.draggableProps} ref={provided.innerRef} >
              <List.Description onClick={() => handleListItemClick(doc)}>
                {doc.text}
              </List.Description>
            </div>
          )}
        </Droppable>
      ) : (
        <Droppable isDropDisabled droppableId={doc.id}>
          {provided => (
            <div {...provided.draggableProps} ref={provided.innerRef}>
              <Draggable
                key={doc.id}
                draggableId={doc.id}
                index={index}
              >
                {draggableProvided => (
                  <div ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    {...draggableProvided.dragHandleProps}
                  >
                    <List.Description onClick={() => handleListItemClick(doc)}>
                      {doc.text}
                    </List.Description>
                  </div>)}
              </Draggable>
            </div>
          )}
        </Droppable>
      )}
      {canEdit ? (
        <List.Content floated="right">
          {doc.subRows ? (
            <>
              <List.Icon
                className={styles.addIcon}
                name="add"
                onClick={() => openModalForFolder(doc)}
              />
              {startRemovingFolder && (
                <List.Icon
                  className={styles.addIcon}
                  name="trash"
                  onClick={() => startRemovingFolder(doc)}
                />
              )}
            </>
          ) : (
            <div className={styles.iconsContainer}>
              {startRenamingItem
                && <Icon bordered name="edit outline" onClick={() => startRenamingItem(doc)} />
              }
              {startReplacingItem
                && <Icon bordered name="upload" onClick={() => startReplacingItem(doc)} />
              }
              {startRemovingItem
                && <Icon bordered name="trash alternate outline" onClick={() => startRemovingItem(doc)} />
              }
            </div>
          )}
        </List.Content>
      ) : null}
    </div>
  );

  const displayDocs = docs => docs.map((doc, docIndex) => (
    <List.Item key={doc.id} className={styles.mainListItem}>
      <List.Icon name={getIconName(doc)} />
      <List.Content>
        {displayDescription(doc, docIndex)}
        {doc.subRows && doc.expanded && doc.subRows.map((subDoc, subDocIndex) => (
          <List.List key={subDoc.id}>
            <List.Item>
              <List.Icon name={getIconByMimeType(subDoc.mimetype)} />
              <List.Content className={styles.subRowItem}>
                {displayDescription(subDoc, subDocIndex)}
              </List.Content>
            </List.Item>
          </List.List>
        ))}
      </List.Content>
    </List.Item>
  ));

  return (
    <DragDropContext onDragEnd={dragEnd}>
      <List
        key="documents-main-list"
        className={styles.mainList}
        data-cy={`${dataCy}-main-list`}
      >
        {canEdit && (
          <List.Item key="root" className={styles.mainListItem}>
            <List.Icon name="ellipsis horizontal" />
            <List.Content>
              <Droppable droppableId="root">
                {provided => (
                  <div className={styles.folderLine} {...provided.draggableProps} ref={provided.innerRef} >
                    <List.Description>
                      {'root'}
                    </List.Description>
                  </div>
                )}
              </Droppable>
            </List.Content>
          </List.Item>
        )}
        {displayDocs(itemsTree)}
      </List>
    </DragDropContext>
  );
};

DropDownTable.defaultProps = {
  canEdit: false,
  dataCy: 'dropdown-generic-table',
  defaultItemIcon: 'file',
  dragEnd: null,
  handleListItemClick: null,
  openModalForFolder: null,
  startRemovingFolder: null,
  startRemovingItem: null,
  startRenamingItem: null,
  startReplacingItem: null,

};

DropDownTable.propTypes = {
  itemsTree: PropTypes.array.isRequired,
  canEdit: PropTypes.bool,
  dataCy: PropTypes.string,
  defaultItemIcon: PropTypes.string,
  dragEnd: PropTypes.func,
  handleListItemClick: PropTypes.func,
  openModalForFolder: PropTypes.func,
  startRemovingFolder: PropTypes.func,
  startRemovingItem: PropTypes.func,
  startRenamingItem: PropTypes.func,
  startReplacingItem: PropTypes.func,

};

export default DropDownTable;

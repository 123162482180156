import React from 'react';
import { Link } from 'react-router-dom';

import styles from './ClientsTableHeader.module.scss';

const DATA_CY = 'clients-table-header';

const ClientsTableHeader = () => (
  <Link data-cy={`${DATA_CY}-automated-exports-link`} to="/clients/automated">
    <span className={styles.settings}>
      Automated Exports
    </span>
  </Link>
);

export default ClientsTableHeader;

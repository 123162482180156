const backendUrl = process.env.REACT_APP_BACKEND_URL || '';

const awsAmplify = {
  auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  },
};

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_KEY || 'googlemaps_api_key';

const configCatSdkKey = process.env.REACT_APP_CONFIGCAT_APIKEY || 'c3_00000_000000-000000/000000-000000000000_rA';

export default {
  backendUrl,
  configCatSdkKey,
  awsAmplify,
  googleMapsApiKey,
};

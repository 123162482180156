import { StatusCodes } from 'http-status-codes';

import { appConfig } from '../config';
import axios from './axiosInstance';
import {
  getAuthorization,
  handlingResponse,
  logError,
} from './utils';

export const fetchEmailSettings = () => axios.get(
  `${appConfig.backendUrl}/api/v1/emailsettings`,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], 'Error fetching email settings'))
  .catch(logError);

export const updateEmailSettings = payload => axios.put(
  `${appConfig.backendUrl}/api/v1/emailsettings`,
  payload,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], 'Error updating email settings'))
  .catch(logError);

import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { useSelector } from 'react-redux';

import { entitiesAPI, errorAPI } from '../../../../../api';
import { DateTimeDiffFormat, DateTimeFormat, Entity } from '../../../../../constants';
import getFieldStatus from '../../../../../fieldLogic/audit';
import { useUserRole } from '../../../../../hooks';
import { taxonomyTermSelector } from '../../../../../state/constants/selectors';
import {
  FormFieldDateTimeWrapper,
  FormFieldDynamicSearchWrapper,
  FormFieldSelectWrapper,
  FormFieldWrapper,
  TaxonomyTermOptions,
} from '../../../../common';
import { uuidType } from '../../../../types';

const documentsTaxonomySelector = state => taxonomyTermSelector(state, 'documents');

const AuditDetailsVisitor = ({ labelStyle, revisionId, valueStyle }) => {
  const { values } = useFormikContext();
  const role = useUserRole();
  const alert = useAlert();
  const documentsTaxonomyTerms = useSelector(documentsTaxonomySelector);

  const buildAuditorSearchOptions = auditors => auditors.map(auditor => ({
    text: auditor.name,
    value: auditor.id,
  }));

  const [filteredAuditors, setFilteredAuditors] = useState([{
    text: `${values.auditor_name}`,
    value: values.auditor_id,
  }]);

  const searchAuditors = wildcard => {
    entitiesAPI.fetchAll(Entity.AUDITORS, { name: wildcard, auditor_status: 'active' })
      .then(auditors => setFilteredAuditors(buildAuditorSearchOptions(auditors.items)))
      .catch(error => {
        alert.error(`Error fetching active auditors: ${error.message}`);
        errorAPI.sendError(error.message, error.stack);
      });
  };

  return (
    <>
      <FormFieldWrapper
        label="NARV audit count"
        labelStyle={labelStyle}
        name="auditor_narv_visit_count"
        type="number"
        valueStyle={valueStyle}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      <FormFieldWrapper
        label="SL audit count"
        labelStyle={labelStyle}
        name="auditor_sl_visit_count"
        type="number"
        valueStyle={valueStyle}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      <FormFieldWrapper
        label="All (SL or NARV) audit count"
        labelStyle={labelStyle}
        name="auditor_all_visit_count"
        type="number"
        valueStyle={valueStyle}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      <FormFieldDateTimeWrapper
        format={DateTimeFormat.DAY_MONTH_YEAR}
        label="Date of First NARV Audit"
        labelStyle={labelStyle}
        name="auditor_first_narv_visit_date"
        valueStyle={valueStyle}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      <FormFieldDateTimeWrapper
        format={DateTimeFormat.DAY_MONTH_YEAR}
        label="Date of Last NARV Audit"
        labelStyle={labelStyle}
        name="auditor_last_narv_visit_date"
        valueStyle={valueStyle}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      <FormFieldDynamicSearchWrapper
        label="Auditor"
        labelStyle={labelStyle}
        name="auditor_id"
        options={filteredAuditors}
        valueStyle={valueStyle}
        onSearchChange={searchAuditors}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      <FormFieldDateTimeWrapper
        format={DateTimeFormat.DAY_MONTH_YEAR}
        label="Age"
        labelStyle={labelStyle}
        name="auditor_date_of_birth"
        timeToStringFormat={DateTimeDiffFormat.YEAR_MONTH}
        valueStyle={valueStyle}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      <FormFieldWrapper
        label="Gender"
        labelStyle={labelStyle}
        name="auditor_gender"
        valueStyle={valueStyle}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      <FormFieldDateTimeWrapper
        format={DateTimeFormat.DAY_MONTH_YEAR}
        label="Date of First SL Audit"
        labelStyle={labelStyle}
        name="auditor_first_sl_visit_date"
        valueStyle={valueStyle}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      <FormFieldDateTimeWrapper
        format={DateTimeFormat.DAY_MONTH_YEAR}
        label="Date of Last SL Audit"
        labelStyle={labelStyle}
        name="auditor_last_sl_visit_date"
        valueStyle={valueStyle}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      <FormFieldWrapper
        label="Audit info"
        labelStyle={labelStyle}
        name="visit_info"
        valueStyle={valueStyle}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      <FormFieldWrapper
        label="Audit Instructions"
        labelStyle={labelStyle}
        name="auditor_instructions"
        type="textarea"
        valueStyle={valueStyle}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      <FormFieldWrapper
        label="Site Directions"
        labelStyle={labelStyle}
        name="site_directions"
        type="textarea"
        valueStyle={valueStyle}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      <FormFieldSelectWrapper
        multiple
        label="Briefing documents"
        labelStyle={labelStyle}
        name="order_briefing_documents_metadata"
        options={TaxonomyTermOptions(documentsTaxonomyTerms)}
        valueStyle={valueStyle}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />
    </>
  );
};

AuditDetailsVisitor.defaultProps = {
  labelStyle: null,
  revisionId: null,
  valueStyle: null,
};

AuditDetailsVisitor.propTypes = {
  labelStyle: PropTypes.string,
  revisionId: uuidType,
  valueStyle: PropTypes.string,
};

export default AuditDetailsVisitor;

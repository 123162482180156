/* eslint-disable no-console */
/* eslint-disable react/sort-prop-types */
import * as Yup from 'yup';

import { AUX_ELEMENTS, CONDITION_VALUES, ENTITIES, ENTITY_OPTS, INTERVAL_DATE_TIME_TYPES, INTERVAL_TYPES, OPERATORS, SHOW_TIME_RANGE, TYPES, VALUE_SELECTABLE_TYPES } from '../constants/constants';
import deflatConditions from './deflatConditions';
import flatConditions from './flatConditions';

export const isImageQuestion = ({ detectionRuleQuestions, questionId }) => detectionRuleQuestions.find(q => q.value === questionId)?.type === 'image';

export const isValueSelectableQuestion = (
  { detectionRuleQuestions, questionId },
) => VALUE_SELECTABLE_TYPES.includes(detectionRuleQuestions.find(q => q.value === questionId)?.type);

export const isEntityValueAndQuestionNotSelectable = ({ entity, questionId, detectionRuleQuestions }) => {
  if (entity === 'value') {
    return !isValueSelectableQuestion({ detectionRuleQuestions, questionId });
  }
  return false;
};

export const isAIEnabled = ({ questionId, detectionRuleQuestions }) => detectionRuleQuestions
  .find(q => q.value === questionId)?.ai_enabled;

const questionTypesOperations = {
  image: ({ type }) => SHOW_TIME_RANGE.image[type],
  datetime: ({ referenceType }) => SHOW_TIME_RANGE.datetime[referenceType],
  default: ({ questionType }) => SHOW_TIME_RANGE[questionType],
};

const _getQuestionType = ({ questionType, referenceType, type }) => {
  const getOperation = questionTypesOperations[questionType] || questionTypesOperations.default;
  const result = getOperation({ questionType, referenceType, type });
  return result || SHOW_TIME_RANGE.default;
};

export const isTimeQuestion = ({ detectionRuleQuestions, decoratedQuestions, question, reference, type }) => {
  const questionType = detectionRuleQuestions.find(q => q.value === question)?.type;
  const referenceType = decoratedQuestions.find(q => q.value === reference)?.type;

  return _getQuestionType({ questionType, referenceType, type });
};

// todo: refactor to avoid object mutation
export const emptyOptionalFields = ({
  detectionRuleQuestions,
  decoratedQuestions,
  condition,

}) => {
  const questionType = detectionRuleQuestions.find(q => q.value === condition.question)?.type;
  const referenceType = decoratedQuestions.find(q => q.value === condition.reference)?.type;

  if ((questionType && !INTERVAL_TYPES.has(questionType))
    || (condition.type && condition.type !== 'time')
    || ((questionType && questionType === 'datetime') && !INTERVAL_DATE_TIME_TYPES.has(referenceType))) {
    delete condition.interval;
    delete condition.intervalUnit;
  }
  if (questionType && questionType !== 'image') {
    delete condition.format;
    delete condition.type;
    delete condition.detection_rule_name;
  }
  if (questionType && !isAIEnabled({ questionId: condition.question, detectionRuleQuestions })) {
    delete condition.template;
    delete condition.template_prompt_value;
    delete condition.ai_enabled;
  }
};

export const getReferenceOptions = ({
  entity,
  questionId,
  completeQuestions,
  detectionRuleQuestions,
  decoratedQuestions,
}) => {
  if (entity === 'value' && isValueSelectableQuestion({ detectionRuleQuestions, questionId })) {
    const radioOptions = completeQuestions.find(question => question.id === questionId).config.options;
    return radioOptions.map(option => ({ value: option.value, text: option.label }));
  }
  if (entity === 'question') {
    return decoratedQuestions;
  }
  return ENTITY_OPTS;
};

export const flattenConditions = (matchings = []) => flatConditions(matchings);

export const rebuildConditions = (matchings = []) => deflatConditions(matchings);

export const firstLevelGroup = conditions => {
  const groups = conditions.reduce((acc, condition) => {
    if (condition.group) {
      if (acc[condition.group]) {
        acc[condition?.group].push(condition.condition);
      } else {
        acc[condition?.group] = [condition.condition];
      }
    }
    return acc;
  }, {});
  return Number(Object.keys(groups).find(group => !groups[group].includes('parenthesis'))) || 1;
};

const groupConditions = ({
  conditions,
  group,
}) => conditions.map((c, i) => (c.group === group
  ? { condition: c?.condition, index: i, group }
  : null)).filter(c => c !== null);

const isGroup = ({ conditions, group }) => groupConditions({ conditions, group })[0]?.condition === 'parenthesis';

const groupLength = ({ conditions, group }) => groupConditions({ conditions, group }).length;

export const disableRemove = ({
  conditions, group,
}) => (isGroup({ conditions, group })
  ? groupLength({ conditions, group }) <= 5
  : groupLength({ conditions, group }) <= 1);

export const removeButton = ({ conditions, j, removeCondition }) => {
  const removePair = (index1, index2) => {
    removeCondition(index1);
    removeCondition(index2);
  };

  if (!conditions[j - 1] || conditions[j - 1].condition === 'parenthesis') {
    removePair(j + 1, j);
  } else {
    removePair(j - 1, j - 1);
  }
};

const getConditionGroups = conditions => {
  const allGroups = [];
  conditions.forEach(c => {
    allGroups.push(c.group);
  });
  return allGroups.filter((v, i, a) => a.indexOf(v) === i);
};

export const getNewGroup = conditions => {
  const allGroups = getConditionGroups(conditions);
  return Math.max(...allGroups) + 1;
};

export const matchGroupConditions = ({ condition, conditions }) => {
  conditions.forEach(c => {
    if (Object.keys(CONDITION_VALUES).includes(c.condition) && c.group === condition.group) {
      c.condition = condition.condition;
    }
  });
};

const areConsecutive = arr => {
  arr.sort((a, b) => a - b);

  for (let i = 1; i < arr.length; i += 1) {
    if (arr[i] !== arr[i - 1] + 1) {
      return false;
    }
  }

  return true;
};

export const removeGroup = ({ conditions, j, group, removeCondition }) => {
  const conditionsIndex = groupConditions({ conditions, group });
  const hasNestedGroup = !areConsecutive(conditionsIndex.map(c => c.index));
  conditionsIndex.reverse().forEach(c => {
    removeCondition(c.index);
  });
  if (conditions[j - 1] && !hasNestedGroup) {
    removeCondition(j - 1);
  }
};

export const findGroupCondition = ({ conditions, group }) => groupConditions({ conditions, group })[0]?.condition;

export const disableConditionSelector = ({
  group,
  conditionIndex,
  conditions }) => groupConditions({ conditions, group })
  .some(c => {
    if (c.index < conditionIndex && Object.keys(CONDITION_VALUES).includes(c.condition)) {
      return true;
    }
    return false;
  });

const conditionSchema = Yup.object().shape({
  operator: Yup.string().oneOf(OPERATORS).required('Operator is required'),
  entity: Yup.string().oneOf(ENTITIES).required('Entity is required'),
  question: Yup.string().required('Question is required'),
  reference: Yup.string().required('Reference is required'),
  detection_rule_name: Yup.string(),
  type: Yup.string().oneOf(TYPES),
  ai_enabled: Yup.boolean(),
  format: Yup.string().when('type', {
    is: type => ['time', 'date', 'day'].includes(type),
    then: Yup.string().required('Format is required'),
  }),
  interval: Yup.string()
    .when('type', {
      is: 'time',
      then: Yup.string().required('Interval unit is required'),
      otherwise: Yup.string(),
    }),
  intervalUnit: Yup.string()
    .when('type', {
      is: 'time',
      then: Yup.string().required('Interval unit is required'),
      otherwise: Yup.string(),
    }),
  template: Yup.string()
    .when('ai_enabled', {
      is: true,
      then: Yup.string().required('Template is required'),
      otherwise: Yup.string(),
    }),
  template_prompt_value: Yup.string()
    .when('ai_enabled', {
      is: true,
      then: Yup.string().required('Prompt value is required'),
      otherwise: Yup.string(),
    }),
});

const matchingComparator = Yup.object().shape({
  condition: Yup.string().required('Condition is required').oneOf(AUX_ELEMENTS),
});

const operatorSchema = item => {
  if (Object.hasOwn(item, 'condition')) {
    return matchingComparator;
  }
  return conditionSchema;
};

export const matchingValidationSchema = Yup.object().shape({
  matching: Yup.array().of(Yup.object().shape({
    rule_name: Yup.string().required('Rule name is required'),
    conditions: Yup.array().of(Yup.lazy(operatorSchema)).required('Conditions are required'),
  })).required('Matching is required'),
});

const isCondition = operand => ['AND', 'OR'].some(o => o === operand);

const validateMatchingConditions = (conditions, i) => {
  const [operands] = Object.values(conditions);
  const [operator] = Object.keys(conditions);

  if (!isCondition(operator)) {
    return null;
  }
  let extractedOperand = [];

  operands.forEach(operand => { extractedOperand = [...extractedOperand, validateMatchingConditions(operand, i)]; });

  const isValid = extractedOperand.every(result => {
    if (result === null) {
      return true;
    }
    return result !== operator;
  });
  if (!isValid) {
    throw new Error(i);
  }

  return operator;
};

export const errorConditions = deflatMatchings => {
  console.log('deflatMatching', deflatMatchings);
  try {
    deflatMatchings.map((matching, i) => validateMatchingConditions(matching.conditions, i));
    return false;
  } catch (error) {
    return error.message;
  }
};

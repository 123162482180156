import React from 'react';
import { Button } from 'semantic-ui-react';

import emptyBox from '../../../../assets/images/empty-audits.svg';
import styles from './AuditsEmpty.module.scss';

const AuditsEmpty = () => (
  <div className={styles.content}>
    <img alt="no-audits" src={emptyBox}/>
    <h1>
      {'Apologies'}
    </h1>
    <p>
      {'You currently haven\'t outstanding audits assigned.'}
    </p>
    <Button primary>
      {'Request Audits'}
      <span className={styles.positionIcon}></span>
    </Button>
  </div>
);

export default AuditsEmpty;

import * as Yup from 'yup';

export const defaultValidationRule = {
  logic: '',
  message: '',
};

export const validationValidationSchema = Yup.object({
  validation: Yup.array().of(Yup.object({
    logic: Yup.string().trim().ensure().min(1, 'Required'),
    message: Yup.string().trim().ensure().min(1, 'Required'),
  })),
});

import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import AuditorTooltip from '../../../Auditors/AuditorTooltip/AuditorTooltip.component';
import styles from './styles.module.scss';

const AuditorNameCellFormatter = ({ cell }) => {
  const auditor_id = cell.row.original.auditor_id ? cell.row.original.auditor_id : cell.row.original.id;
  return (
    <AuditorTooltip
      id={auditor_id}
      trigger={(
        <Link
          className={styles.link}
          to={`/auditors/${auditor_id}/view`}
        >
          {cell.value}
        </Link>
      )}
    />
  );
};

AuditorNameCellFormatter.propTypes = {
  cell: PropTypes.object.isRequired,
};

export default AuditorNameCellFormatter;

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { parse } from '../utils/queryString';

const useLocationSearchParam = param => {
  const { search } = useLocation();
  const [value, setValue] = useState(null);

  useEffect(() => {
    const query = parse(search);
    setValue(query[param]);
  }, [param, search]);

  return value;
};

export default useLocationSearchParam;

const downloadFile = (data, defaultFileName) => {
  const input = Array.isArray(data) ? data : [data];
  const url = URL.createObjectURL(new Blob(input));
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.setAttribute('download', defaultFileName);
  anchor.setAttribute('data-cy', 'download-anchor');
  // Append to html page
  document.body.appendChild(anchor);

  if (window.Cypress) {
    return;
  }

  // Force download
  anchor.click();
  // Clean up and remove the anchor
  anchor.parentNode.removeChild(anchor);
};

export default downloadFile;

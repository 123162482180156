import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import { DateTimeFormat } from '../../../../../constants';
import getFieldStatus from '../../../../../fieldLogic/audit';
import { useUserRole } from '../../../../../hooks';
import {
  FormFieldDateTimeWrapper,
  FormFieldWrapper,
} from '../../../../common';
import { uuidType } from '../../../../types';

const AuditDetailsFailLetter = ({ labelStyle, revisionId, valueStyle }) => {
  const role = useUserRole();
  const { values } = useFormikContext();

  return (
    <>
      <FormFieldWrapper
        label="Emails"
        labelStyle={labelStyle}
        name="automatic_fail_letter_notification_email_list"
        valueStyle={valueStyle}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />
      {values.automatic_fail_letter_notification_sent && (
        <FormFieldDateTimeWrapper
          format={DateTimeFormat.DAY_MONTH_YEAR_HOUR_MINUTES_24H}
          label="Notification letter sent date"
          labelStyle={labelStyle}
          name="automatic_fail_letter_notification_date"
          valueStyle={valueStyle}
          onStateHandler={getFieldStatus(role, values.status, revisionId)}
        />
      )}
    </>
  );
};

AuditDetailsFailLetter.defaultProps = {
  labelStyle: null,
  revisionId: null,
  valueStyle: null,
};

AuditDetailsFailLetter.propTypes = {
  labelStyle: PropTypes.string,
  revisionId: uuidType,
  valueStyle: PropTypes.string,
};

export default AuditDetailsFailLetter;

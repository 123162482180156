import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Icon, Input } from 'semantic-ui-react';

import { QuestionType } from '../../../constants';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './DatePicker.module.scss';

const dateFormats = {
  [QuestionType.TIME]: 'HH:mm',
  [QuestionType.DATE]: 'DD/MM/YYYY',
  [QuestionType.DATETIME]: 'DD/MM/YYYY HH:mm',
};

const inputIcon = {
  [QuestionType.TIME]: 'clock',
  [QuestionType.DATE]: 'calendar',
  [QuestionType.DATETIME]: 'calendar',
};

const getDaysInMonth = (min, max, currentDate = moment()) => {
  const year = currentDate.year();
  const month = currentDate.month() + 1;
  const daysInMonth = moment(`${year}-${month}`, 'YYYY-MM').daysInMonth();
  const firstDayOfMonth = moment(`${year}-${month}-01`, 'YYYY-MM-DD').day();
  const daysArray = [];

  for (let i = 0; i < firstDayOfMonth; i += 1) {
    daysArray.push({ day: null, className: styles.emptyDay, selectable: false });
  }

  for (let i = 1; i <= daysInMonth; i += 1) {
    const date = moment(`${year}-${month}-${i}`, 'YYYY-MM-DD');
    const isEnabled = (!min || date.isSameOrAfter(min)) && (!max || date.isSameOrBefore(max));
    const isWeekend = [0, 6].includes(date.weekday());
    const isSelected = date.isSame(currentDate, 'day');
    daysArray.push({
      day: i,
      className: !isEnabled
        ? styles.disabledDay
        : isSelected
          ? styles.selectedDay
          : isWeekend
            ? styles.weekendDay
            : styles.weekDay,
      selectable: isEnabled,
    });
  }
  return daysArray;
};

const DatePicker = ({
  type,
  value,
  onChange,
  min,
  max,
}) => {
  const [initialDate] = useState(moment(value));
  const [calendarDate, setCalendarDate] = useState(value ? moment(value) : moment());
  const [calendarMode, setCalendarMode] = useState('day');
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [displayTime, setDisplayTime] = useState(false);
  const [displayCalendar, setDisplayCalendar] = useState(false);
  const [hoursValue, setHoursValue] = useState(calendarDate?.format('HH'));
  const [minutesValue, setMinutesValue] = useState(calendarDate?.format('mm'));

  const calendarRef = useRef(null);
  const today = moment();

  const minDate = min ? moment(min).startOf('day') : null;
  const maxDate = max ? moment(max).endOf('day') : null;

  useEffect(() => {
    const handleOutsideClick = event => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        if (calendarDate) {
          setHoursValue(calendarDate.format('HH'));
          setMinutesValue(calendarDate.format('mm'));
        }
        setIsCalendarOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    setDisplayTime(() => [QuestionType.TIME, QuestionType.DATETIME].includes(type) && calendarMode === 'day');
    setDisplayCalendar(() => [QuestionType.DATE, QuestionType.DATETIME].includes(type));

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    setDisplayTime([QuestionType.TIME, QuestionType.DATETIME].includes(type) && calendarMode === 'day');
    setDisplayCalendar(() => [QuestionType.DATE, QuestionType.DATETIME].includes(type));
  }, [calendarMode]);

  useEffect(() => {
    if (calendarDate) onChange(calendarDate.clone().utc().toISOString());
  }, [calendarDate]);

  const handleCalendarMonthChange = () => {
    setCalendarMode(prevState => {
      if (['day', 'year'].includes(prevState)) {
        return 'month';
      }
      return 'day';
    });
  };

  const handleCalendarYearChange = () => {
    setCalendarMode(prevState => {
      if (['day', 'month'].includes(prevState)) {
        return 'year';
      }
      return 'day';
    });
  };

  const handleInputBlur = mode => {
    const typeValue = mode === 'hour' ? hoursValue : minutesValue;
    const setValue = mode === 'hour' ? setHoursValue : setMinutesValue;
    const newValue = typeValue === '' ? '00' : String(Number(typeValue)).padStart(2, '0');
    setValue(newValue);
    setCalendarDate(prevValue => prevValue.clone().set(mode, Number(newValue)));
  };

  const handleInputChange = (mode, minVal, maxVal, val) => {
    if (val === '' || (Number(val) >= minVal && Number(val) <= maxVal)) {
      const setValue = mode === 'hour' ? setHoursValue : setMinutesValue;
      setValue(val);
      setCalendarDate(prevValue => prevValue.clone().set(mode, Number(val)));
    }
  };

  const handleCalendarClick = () => {
    setCalendarDate(prev => {
      const date = prev || today.clone();
      setHoursValue(date.format('HH'));
      setMinutesValue(date.format('mm'));
      return date;
    });
    setIsCalendarOpen(prevState => !prevState);
  };

  const handleTimeIconClick = (increment, mode, minValue, maxValue) => {
    const setValue = mode === 'hour' ? setHoursValue : setMinutesValue;
    if (increment) {
      setValue(prevValue => {
        const newValue = Number(prevValue) + 1;
        if (newValue > maxValue) {
          return String(minValue).padStart(2, '0');
        }
        return String(newValue).padStart(2, '0');
      });
      setCalendarDate(prevValue => {
        const previousValue = prevValue.clone();
        if (previousValue.get(mode) === maxValue) {
          return previousValue.clone().set(mode, minValue);
        }
        return previousValue.clone().add(1, mode);
      });
    } else {
      setValue(prevValue => {
        const newValue = Number(prevValue) - 1;
        if (newValue < minValue) {
          return String(maxValue).padStart(2, '0');
        }
        return String(newValue).padStart(2, '0');
      });
      setCalendarDate(prevValue => {
        if (prevValue.get(mode) === minValue) {
          return prevValue.clone().set(mode, maxValue);
        }
        return prevValue.clone().subtract(1, mode);
      });
    }
  };

  const renderCalendarContent = () => {
    const contentOptions = {
      day: () => (
        <div className={styles.calendarBodyDays}>
          <span className={styles.dayName}>
            {'Sun'}
          </span>
          <span className={styles.dayName}>
            {'Mon'}
          </span>
          <span className={styles.dayName}>
            {'Tue'}
          </span>
          <span className={styles.dayName}>
            {'Wed'}
          </span>
          <span className={styles.dayName}>
            {'Thu'}
          </span>
          <span className={styles.dayName}>
            {'Fri'}
          </span>
          <span className={styles.dayName}>
            {'Sat'}
          </span>
          {getDaysInMonth(minDate, maxDate, calendarDate).map(({ day, className, selectable }, index) => (
            <span
              key={index}
              className={className}
              onClick={() => {
                if (selectable) {
                  setCalendarDate(prevValue => prevValue.clone().set('date', day));
                  setIsCalendarOpen(false);
                }
              }}
            >
              {day}
            </span>
          ))}
        </div>
      ),
      month: () => (
        <div className={styles.calendarBodyMonths}>
          {moment.months().map((month, index) => (
            <span
              key={index}
              className={styles.month}
              onClick={() => {
                setCalendarDate(prevValue => prevValue.clone().set('month', index));
                setCalendarMode('day');
              }}
            >
              {month.substring(0, 3)}
            </span>
          ))}
        </div>
      ),
      year: () => {
        const years = [];
        const currentYear = (initialDate || today).clone().year();
        const startYear = currentYear - 8;
        const endYear = currentYear + 7;

        for (let i = startYear; i <= endYear; i += 1) {
          years.push(i);
        }

        return (
          <div className={styles.calendarBodyYears}>
            {years.map((year, index) => (
              <span
                key={index}
                className={styles.year}
                onClick={() => {
                  setCalendarDate(prevValue => prevValue.clone().set('year', year));
                  setCalendarMode('day');
                }}
              >
                {year}
              </span>
            ))}
          </div>
        );
      },
    };

    return contentOptions[calendarMode]();
  };

  return (
    <div ref={calendarRef} className={styles.datePickerContainer}>
      <Form.Input
        action={
          <Button type={'button'} onClick={handleCalendarClick}>
            <Icon name={inputIcon[type]}/>
          </Button>
        }
        className={styles.calendarInput}
        value={calendarDate ? calendarDate.format(dateFormats[type]) : ''}
        onClick={() => setIsCalendarOpen(true)}
      />
      {isCalendarOpen && (
        <div className={styles.calendarPopup}>
          {displayCalendar && <div className={styles.calendarHeader}>
            <div
              className={calendarMode === 'day' ? styles.calendarHeaderArrow : styles.calendarHeaderDisabledArrow}
              onClick={() => {
                if (calendarMode === 'day') {
                  setCalendarDate(prevValue => prevValue.clone().subtract(1, 'month'));
                }
              }}
            >
              <Icon name={'angle left'}/>
            </div>
            <div>
              <span
                className={styles.calendarHeaderClickable}
                onClick={handleCalendarMonthChange}
              >
                {(calendarDate || today).format('MMM')}
              </span>
              {', '}
              <span
                className={styles.calendarHeaderClickable}
                onClick={handleCalendarYearChange}
              >
                {(calendarDate || today).format('YYYY')}
              </span>
            </div>
            <div
              className={calendarMode === 'day' ? styles.calendarHeaderArrow : styles.calendarHeaderDisabledArrow}
              onClick={() => {
                if (calendarMode === 'day') {
                  setCalendarDate(prevValue => prevValue.clone().add(1, 'month'));
                }
              }}
            >
              <Icon name={'angle right'}/>
            </div>
          </div>
          }
          {displayCalendar && renderCalendarContent()}
          {displayTime && (
            <div className={styles.calendarTimeBody}>
              <Icon
                className={styles.calendarTimeArrow}
                name={'angle up'}
                onClick={() => handleTimeIconClick(true, 'hour', 0, 23)}
              />
              <Icon
                className={styles.calendarTimeArrow}
                name={'angle up'}
                onClick={() => handleTimeIconClick(true, 'minute', 0, 59)}
              />
              <div className={styles.calendarTimeInput}>
                <Input
                  max={23}
                  min={0}
                  type="number"
                  value={hoursValue}
                  onBlur={() => handleInputBlur('hour')}
                  onChange={(_, data) => handleInputChange('hour', 0, 23, data.value)}
                />
              </div>
              <div className={styles.calendarTimeInput}>
                <Input
                  max={59}
                  min={0}
                  type="number"
                  value={minutesValue}
                  onBlur={() => handleInputBlur('minute')}
                  onChange={(_, data) => handleInputChange('minute', 0, 59, data.value)}
                />
              </div>
              <Icon
                className={styles.calendarTimeArrow}
                name={'angle down'}
                onClick={() => handleTimeIconClick(false, 'hour', 0, 23)}
              />
              <Icon
                className={styles.calendarTimeArrow}
                name={'angle down'}
                onClick={() => handleTimeIconClick(false, 'minute', 0, 59)}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

DatePicker.defaultProps = {
  max: null,
  min: null,
  onChange: () => {},
  type: QuestionType.DATETIME,
  value: null,
};

DatePicker.propTypes = {
  max: PropTypes.instanceOf(Date),
  min: PropTypes.instanceOf(Date),
  type: PropTypes.oneOf([QuestionType.TIME, QuestionType.DATE, QuestionType.DATETIME]),
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default DatePicker;

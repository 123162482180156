import { createSelector } from 'reselect';

import createFlattenedArray from '../../utils/createFlattenedArray';
import createFlattenedArrayWithoutFirstLevel from '../../utils/createFlattenedArrayWithoutFirstLevel';

export const enumsSelector = state => state.constants.enums;

export const taxonomySelector = state => state.constants.taxonomy;

export const userInfoSelector = state => state.auth.userInfo;

export const userPromptsSelector = state => state.auth.userPrompts;

export const enumOptionsSelector = createSelector(
  enumsSelector,
  (_, key) => key,
  (enums, key) => enums[key]?.options || [],
);

export const taxonomyTermSelector = createSelector(
  taxonomySelector,
  (_, key) => key,
  (taxonomy, key) => taxonomy.find(term => term.vocabulary === key),
);

export const taxonomyTermOptionsSelector = createSelector(
  taxonomySelector,
  (_, key) => key,
  (taxonomy, key) => {
    const taxonomyTerm = taxonomy.find(term => term.vocabulary === key);
    return taxonomyTerm ? createFlattenedArray(taxonomyTerm) : [];
  },
);

export const taxonomyTermOptionsNoFirstLevelSelector = createSelector(
  taxonomySelector,
  (_, key) => key,
  (taxonomy, key) => {
    const taxonomyTerm = taxonomy.find(term => term.vocabulary === key);
    return taxonomyTerm ? createFlattenedArrayWithoutFirstLevel(taxonomyTerm) : [];
  },
);

export const userInfoTermSelector = createSelector(
  userInfoSelector,
  (_, key) => key,
  (userInfo, key) => userInfo[key],
);

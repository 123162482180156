import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { Button, Form, Modal } from 'semantic-ui-react';

import { errorAPI, multipartAPI } from '../../../../api';
import { Entity } from '../../../../constants';
import { taxonomyTermOptionsNoFirstLevelSelector } from '../../../../state/constants/selectors';
import { FormFieldSelectWrapper } from '../../../common';
import styles from './DashboardEthnicityModal.module.scss';

const ethnicityOptionsSelector = state => taxonomyTermOptionsNoFirstLevelSelector(state, 'ethnicity');

const DashboardEthnicityModal = ({ userInfo, onAccept }) => {
  const { mutate: accountUpdate } = useMutation(multipartAPI.update, {
    onSuccess: () => {
      onAccept();
    },
    onError: error => {
      errorAPI.sendError(error.message, error.stack);
    },
  });

  const ethnicityOptions = useSelector(ethnicityOptionsSelector);

  const updateEthnicitySubmit = ({ ethnicity }) => {
    const formData = new FormData();
    const { identification, image, passport, visa, ...filteredValues } = userInfo;

    Object.entries(filteredValues).forEach(([key, value]) => formData.append(key, value));
    formData.append('ethnicity', ethnicity);

    accountUpdate({ entity: Entity.AUDITORS, entityId: 'me', payload: formData });
  };

  return (
    <Formik
      initialValues={{ ethnicity: '' }}
      onSubmit={updateEthnicitySubmit}
    >
      {({ values, handleSubmit }) => (
        <Modal closeOnDimmerClick={true} open={true}>
          <Modal.Header>
            {'Select you ethnicity'}
          </Modal.Header>
          <Modal.Content>

            <Form>
              <Modal.Content className={styles.content}>
                Serve Legal is an equal opportunity company, committed to understanding the diversity of our auditor
                community and offering opportunities to everyone. As such we are requesting you to submit your
                ethnicity. This data will not be visible to any users of the platform, however, if you select on
                your GDPR options to allow for this data to be shared, it may provide new opportunities for work
                in the future. The options available are the categories that were provided by the 2021 UK census:
              </Modal.Content>
              <FormFieldSelectWrapper
                required
                name="ethnicity"
                options={ethnicityOptions}
                placeholder="Select an ethnicity"
              />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              primary
              content="Save and close"
              disabled={!values.ethnicity}
              onClick={handleSubmit}
            />
          </Modal.Actions>
        </Modal>

      )}
    </Formik>);
};

DashboardEthnicityModal.propTypes = {
  userInfo: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
};

export default DashboardEthnicityModal;

import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';

import history from '../../../../history';
import { parse, stringify } from '../../../../utils/queryString';

const parseQueryParams = queryStringValue => {
  const currentQuery = parse(queryStringValue);
  const { marker, ...filterValues } = currentQuery;
  return { marker, filterValues };
};

const setQueryStringWithoutPageReload = (locationPathname, queryStringValue) => {
  history.push(`${locationPathname}?${queryStringValue}`);
};

const ArchivesTablePagination = ({
  data,
}) => {
  const location = useLocation();
  const getLastItem = data ? data[data.length - 1] : [];
  const markerId = getLastItem?.markerId;

  // eslint-disable-next-line no-unused-vars
  const [currentPage, setCurrentPage] = useState(() => {
    const { marker } = parseQueryParams(location.search);
    return marker || '';
  });

  const next = useCallback(() => {
    const currentQuery = parse(location.search);
    const { marker, ...rest } = currentQuery;
    const newQueryString = stringify({ marker: markerId, ...rest });
    setCurrentPage(markerId);
    setQueryStringWithoutPageReload(location.pathname, newQueryString);
  }, [location, markerId]);

  return (
    <Button icon disabled={!getLastItem} labelPosition="right" onClick={next}>
      {'Next'}
      <Icon name="right arrow" />
    </Button>

  );
};

ArchivesTablePagination.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ArchivesTablePagination;

import PropTypes from 'prop-types';
import React from 'react';

import styles from './SLHeader.module.scss';

const SLHeader = ({ children, dataCy, subtitle, title, status }) => (
  <div className={styles.root} data-cy={dataCy}>
    <div className={styles.title} data-cy={`${dataCy}-title`}>
      {title}
    </div>
    {subtitle && <div className={styles.subtitle} data-cy={`${dataCy}-subtitle`}>
      {subtitle}
    </div>}
    {status && <div className={styles.status}>
      {status}
    </div>}
    <div className={styles.content}>
      {children}
    </div>
  </div>
);

SLHeader.defaultProps = {
  children: null,
  dataCy: 'sl-header',
  status: null,
  subtitle: null,
};

SLHeader.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  dataCy: PropTypes.string,
  status: PropTypes.node,
  subtitle: PropTypes.string,
};

export default SLHeader;

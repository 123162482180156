import React from 'react';
import { Card } from 'semantic-ui-react';

import { AuxiliaryPanel } from '../../../../../common';
import styles from './SurveyBranchingSidebar.module.scss';

const DATA_CY = 'survey-branching-help-panel';

const SurveyBranchingSidebar = () => (
  <AuxiliaryPanel
    content={
      <Card fluid className={styles.card}>
        <h2>
Branch Evaluation
        </h2>
        <p>
          Each branch is evaluated whenever a user performs one of the following actions whilst
          completing a survey:
        </p>
        <ul>
          <li>
Selects an entry from a drop-down list
          </li>
          <li>
Clicks on a checkbox or radio button
          </li>
        </ul>
        <p>
          Each branch&apos;s logic is a self-contained &ldquo;boolean expression&rdquo; which is
          evaluated to determine if it&apos;s true or false. If a branch evaluates to true the
          associated questions will be hidden and any existing values removed.
        </p>
        <p>
          Note that every one of a survey&apos;s branches will always be evaluated. This means that
          at any moment more than one branch may evaulate to true and thus there may be more than
          one set of questions being hidden.
        </p>
        <h2>
Logic Syntax
        </h2>
        <p>
Some example branch logic rules are explained below:
        </p>
        <p>
Q3 = &ldquo;Yes&rdquo;
        </p>
        <p>
True if the value of the question with ID &ldquo;3&rdquo; is &ldquo;Yes&rdquo;.
        </p>
        <p></p>
        <p>
Q3 = &ldquo;Yes&rdquo; OR Q4 = &ldquo;No&rdquo;
        </p>
        <p>
True if question 3 is &ldquo;Yes&rdquo; or if question 4 is &ldquo;No&rdquo;.
        </p>
        <p></p>
        <p>
Q3 = &ldquo;Yes&rdquo; OR (Q4 = &ldquo;No&rdquo; AND Q5 = &ldquo;No&rdquo;)
        </p>
        <p>
          True if question 3 is &ldquo;Yes&rdquo; or if both question 4 is &ldquo;No&rdquo; and
          question 5 is &ldquo;No&rdquo;.
        </p>
      </Card>
    }
    dataCy={DATA_CY}
    header={<span>
Help
    </span>}
  />
);

export default SurveyBranchingSidebar;

import { Entity } from '../constants';

const entityFilterMap = ({
  entity,
  filterValues,
  sitePatchId,
  userRenders,
}) => {
  const props = {
    [Entity.AUDITS]: {
      site_patch_id: sitePatchId,
      ...filterValues,
    },
  };

  if (userRenders < 3) return props[entity] || filterValues;
  return filterValues;
};

export default entityFilterMap;

import { Auth as AuthAmplify } from 'aws-amplify';
import React from 'react';
import { useAlert } from 'react-alert';
import { useHistory } from 'react-router-dom';

import { Auth } from '../../layout';
import styles from './VerifyEmail.module.scss';
import VerifyEmailForm from './VerifyEmailForm/VerifyEmailForm.component';

const VerifyEmail = () => {
  const { push } = useHistory();
  const alert = useAlert();

  const verifyEmail = async code => {
    try {
      if (!code) {
        await AuthAmplify.verifyCurrentUserAttribute('email');
        alert.info('A new verification code has been sent to your email');
      } else {
        await AuthAmplify.verifyCurrentUserAttributeSubmit('email', code);
        push('/');
      }
    } catch (error) {
      alert.error(`Error verifying email: ${error}`);
    }
  };

  return (
    <Auth
      form={VerifyEmailForm}
      subtitle={() => (
        <>
          {'Check your email and insert the code received to validate your email'}
          <div className={styles.linkWrapper}>
            <span className={styles.link} onClick={() => verifyEmail()} >
              {'Send code again'}
            </span>
          </div>
        </>
      )}
      title="Verify email"
      verifyEmail={code => verifyEmail(code)}
    />
  );
};

export default VerifyEmail;

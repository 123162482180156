import React, { useCallback, useRef } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { surveyRevisionsAPI } from '../../../../../api';
import { Entity, SurveyEntity } from '../../../../../constants';
import history from '../../../../../history';
import { useUpdateSurvey } from '../../../../../hooks';
import { LoadingPlaceholder } from '../../../../common';
import SurveyStep from '../../SurveyStep/SurveyStep.component';
import SurveyBranchingEditor from './SurveyBranchingEditor/SurveyBranchingEditor.component';
import SurveyBranchingSidebar from './SurveyBranchingSidebar/SurveyBranchingSidebar.component';

const SurveyBranching = () => {
  const { entityId, revisionId } = useParams();
  const submitFormRef = useRef(null);

  const { data } = useQuery(
    [entityId, revisionId, { include: [SurveyEntity.QUESTIONS, SurveyEntity.BRANCHING] }],
    () => surveyRevisionsAPI.fetchOne(
      entityId,
      revisionId,
      { include: [SurveyEntity.QUESTIONS, SurveyEntity.BRANCHING] },
    ),
  );

  const { mutate: updateSurvey } = useUpdateSurvey({
    currentStep: SurveyEntity.BRANCHING,
    onSuccessStep: SurveyEntity.VALIDATION,
  });

  const back = useCallback(() => {
    history.push(`/${Entity.SURVEYS}/${entityId}/revisions/${revisionId}/${SurveyEntity.QUESTIONS}`);
  }, [entityId, revisionId]);

  // const next = useCallback(() => {
  //   history.push(
  //     `/${Entity.SURVEYS}/${entityId}/revisions/${revisionId}/${SurveyEntity.VALIDATION}`,
  //   );
  // }, [entityId, revisionId]);

  const handleSubmit = useCallback(values => {
    // FIXME: [SURVEY BLOCKED] uncomment when ServeLegal agrees
    // if (data?.blocked) {
    //   next();
    // } else {
    updateSurvey({
      surveyId: entityId,
      revisionId,
      payload: {
        [SurveyEntity.BRANCHING]: values.branching,
      },
    });
    // }
  }, [entityId, revisionId, updateSurvey]);

  return (
    <SurveyStep
      back={back}
      backContent="Back to Questions"
      editor={data === undefined ? LoadingPlaceholder : SurveyBranchingEditor}
      editorProps={{ branching: data?.branching, questions: data?.questions, submitFormRef, onSubmit: handleSubmit }}
      headerProps={{ blocked: data?.blocked }}
      next={() => submitFormRef?.current?.click()}
      nextContent={/* data?.blocked ? 'Go to Validation' : */'Save & Continue'}
      sidebar={SurveyBranchingSidebar}
    />
  );
};

export default SurveyBranching;

import React from 'react';
import { useParams } from 'react-router-dom';

import { View } from '../../../layout';
import EntityUploadHeader from './EntityUploadHeader/EntityUploadHeader.component';
import { body } from './helpers';

const EntityUploadView = () => {
  const { entity } = useParams();

  return (
    <View
      body={body({ entity })}
      dataCy={`${entity}-upload-view`}
      header={EntityUploadHeader}
    />
  );
};

export default EntityUploadView;

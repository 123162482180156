import React from 'react';

import { QuestionType } from '../../../../../../../constants';
import SurveyCurrencyQuestion from './SurveyCurrencyQuestion/SurveyCurrencyQuestion.component';
import SurveyDateTimeQuestion from './SurveyDateTimeQuestion/SurveyDateTimeQuestion.component';
import SurveyMultipleFileQuestion from './SurveyFileQuestion/SurveyMultipleFileQuestion.component';
import SurveySelectorQuestion from './SurveySelectorQuestion/SurveySelectorQuestion.component';
import SurveyTextQuestion from './SurveyTextQuestion/SurveyTextQuestion.component';
import SurveyTitleQuestion from './SurveyTitleQuestion/SurveyTitleQuestion.component';

const SurveyQuestion = props => {
  const { data: { type } } = props;

  const element = {
    [QuestionType.CHECKBOX]: () => <SurveySelectorQuestion {...props} />,
    [QuestionType.CURRENCY]: () => <SurveyCurrencyQuestion {...props} />,
    [QuestionType.DATE]: () => <SurveyDateTimeQuestion time={false} {...props} />,
    [QuestionType.DATETIME]: () => <SurveyDateTimeQuestion {...props} />,
    [QuestionType.IMAGE]: () => <SurveyMultipleFileQuestion {...props} />,
    [QuestionType.INTERVAL]:
      () => <SurveyDateTimeQuestion isInterval={true} {...props} />,
    [QuestionType.NUMBER]: () => <SurveyTextQuestion {...props} />,
    [QuestionType.TEXT]: () => <SurveyTextQuestion {...props} />,
    [QuestionType.TEXTAREA]: () => <SurveyTextQuestion {...props} />,
    [QuestionType.TIME]: () => <SurveyDateTimeQuestion date={false} {...props} />,
    [QuestionType.TITLE]: () => <SurveyTitleQuestion {...props} />,
    [QuestionType.RADIO]: () => <SurveySelectorQuestion {...props} />,
    [QuestionType.SELECT]: () => <SurveySelectorQuestion {...props} />,
    default: () => (
      <div>
        This type of question is not supported yet.
      </div>
    ),
  };

  return (element[type] || element.default)();
};

export default SurveyQuestion;

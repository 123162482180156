/* eslint-disable react/prop-types */
import cn from 'classnames';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';
import moment from 'moment';
import React from 'react';

import { DateTimeFormat } from '../../../constants';
import timeDiffToString from '../../../utils/timeDiffToString';

const SLTableCellDateFormatterType = {
  CUSTOM: 'custom',
  DEFAULT: 'default',
  RELATIVE: 'relative',
  STRICT: 'strict',
};

const SLTableCellDateFormatter = ({
  type = SLTableCellDateFormatterType.DEFAULT,
  format = DateTimeFormat.DAY_MONTH_YEAR,
  classNames = [],
}) => ({ cell }) => {
  const text = {
    custom: () => (cell.value ? moment(cell.value).format(format) : ''),
    default: () => cell.value,
    relative: () => (cell.value ? timeDiffToString(cell.value, 'YM') : ''),
    strict: () => (cell.value ? formatDistanceStrict(Date.parse(cell.value), Date.now()) : ''),
  };

  return (
    <span className={cn(classNames)}>
      {text[type]()}
    </span>
  );
};

export default SLTableCellDateFormatter;
export { SLTableCellDateFormatterType };

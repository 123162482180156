import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';

import styles from './FormFieldLabel.module.scss';

const FormFieldLabel = ({
  dataCy,
  inline,
  label,
  labelStyle,
  labelTooltip,
}) => label && (
  <label
    className={classnames({ [styles.label]: inline, [labelStyle]: labelStyle })}
    data-cy={`${dataCy}-label`}
  >
    {label}
    {labelTooltip && (
      <Popup
        content={labelTooltip}
        trigger={<Icon className={styles.icon} name="info circle" />}
      />
    )}
  </label>
);

FormFieldLabel.defaultProps = {
  dataCy: 'form-field',
  inline: false,
  label: null,
  labelStyle: null,
  labelTooltip: null,
};

FormFieldLabel.propTypes = {
  dataCy: PropTypes.string,
  inline: PropTypes.bool,
  label: PropTypes.string,
  labelStyle: PropTypes.string,
  labelTooltip: PropTypes.string,
};

export default FormFieldLabel;

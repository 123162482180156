import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'semantic-ui-react';

import { QuestionType } from '../../../../../constants';
import { questionType } from '../../../../types';
import styles from './SurveyQuestionsReference.module.scss';

const SurveyQuestionsReference = ({ className, questions }) => (
  <div className={classnames(className)}>
    <div className={styles.header}>
      Survey Questions for your reference
    </div>
    <Card fluid>
      {questions.map((question, questionIndex) => {
        const fixedAnswer = [
          QuestionType.CHECKBOX,
          QuestionType.RADIO,
          QuestionType.SELECT].includes(question.type);

        if (fixedAnswer) {
          return (
            <div key={questionIndex} className={styles.fixedAnswerQuestion}>
              <div>
                {`${question.internal_id} - ${question.title}`}
              </div>
              {question.config.options.map((option, optionIndex) => (
                <div key={optionIndex} className={styles.option}>
                  {option.value}
                </div>
              ))}
            </div>
          );
        }

        return (
          <div key={questionIndex} className={styles.openAnswerQuestion}>
            {`${question.internal_id} - ${question.title}`}
          </div>
        );
      })}
    </Card>
  </div>
);

SurveyQuestionsReference.defaultProps = {
  className: '',
};

SurveyQuestionsReference.propTypes = {
  questions: PropTypes.arrayOf(questionType).isRequired,
  className: PropTypes.string,
};

export default SurveyQuestionsReference;

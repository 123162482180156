import PropTypes from 'prop-types';
import React from 'react';
import { Button, Card, Modal } from 'semantic-ui-react';

const QuestionsSelectorModal = ({ children, open, onCancel, onClose }) => (
  <Modal
    closeOnDimmerClick={false}
    open={open}
    onClose={onClose}
  >
    <Modal.Header>
      {'Select questions to hide'}
    </Modal.Header>
    <Modal.Content>
      <Modal.Description>
        {'Click on the eye icon to select/deselect the questions to hide.'}
      </Modal.Description>
    </Modal.Content>
    <Modal.Content>
      <Card fluid>
        {children}
      </Card>
    </Modal.Content>
    <Modal.Actions>
      <Button onClick={onCancel}>
        {'Cancel'}
      </Button>
      <Button primary onClick={onClose}>
        {'OK'}
      </Button>
    </Modal.Actions>
  </Modal>
);

QuestionsSelectorModal.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default QuestionsSelectorModal;

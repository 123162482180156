import { ErrorMessage, Field, FieldArray, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { Button, Card, Divider, Form, Icon } from 'semantic-ui-react';

import { FormikErrorMessage } from '../../../../../common';
import { branchingRuleType, questionType } from '../../../../../types';
import SurveyQuestionsReference from '../../SurveyQuestionsReference/SurveyQuestionsReference.component';
import { branchingValidationSchema, defaultBranchingRule } from '../helpers';
import QuestionsList from './QuestionsList/QuestionsList.component';
import QuestionsSelectorModal from './QuestionsSelectorModal/QuestionsSelectorModal.component';
import styles from './SurveyBranchingEditor.module.scss';

const DATA_CY = 'survey-branching-editor';

const SurveyBranchingEditor = ({ branching, questions, submitFormRef, onSubmit }) => {
  const defaultModalProps = {
    visible: false,
    arrayHelpers: null,
    ruleIndex: null,
    initialValues: null,
  };
  const [modalProps, setModalProps] = useState(defaultModalProps);
  const alert = useAlert();

  return (
    <div data-cy={DATA_CY}>
      <p data-cy={`${DATA_CY}-header`}>
        When a user is filling out this survey the following branching rules are automatically
        evaluated to determine which questions should be shown or hidden.
      </p>
      <Card fluid>
        <Card.Content className={styles.scroll}>
          <Formik
            initialValues={{ branching }}
            validationSchema={branchingValidationSchema}
            onSubmit={onSubmit}
          >
            {({ values, handleSubmit, setFieldValue, errors, isValid }) => {
              const handleModalCancel = () => {
                setFieldValue(
`branching.${modalProps.ruleIndex}.hidden_questions_ids`,
modalProps.initialValues,
                );
                setModalProps(defaultModalProps);
              };

              const handleModalClose = () => {
                setModalProps(defaultModalProps);
              };

              const submit = () => {
                if (!isValid) {
                  alert.error(
                    <pre>
                      {JSON.stringify(errors, null, 2)}
                    </pre>,
                  );
                } else {
                  handleSubmit();
                }
              };

              return (
                <Form noValidate>
                  <button ref={submitFormRef} hidden type="submit" onClick={submit} />
                  <FieldArray
                    name="branching"
                    render={branchingArrayHelpers => (
                      <>
                        {values.branching.map((branchingItem, branchingIndex) => (
                          <div key={branchingIndex}>
                            <Form.Group>
                              <Form.Field required width={15}>
                                <Field
                                  required
                                  as={Form.Input}
                                  data-cy={`${DATA_CY}-logic-input-${branchingIndex}`}
                                  label="Logic"
                                  name={`branching.${branchingIndex}.logic`}
                                />
                                <ErrorMessage
                                  component={FormikErrorMessage}
                                  data-cy={`${DATA_CY}-logic-input-error-${branchingIndex}`}
                                  name={`branching.${branchingIndex}.logic`}
                                />
                              </Form.Field>
                              <Form.Field width={1}>
                                <Icon
                                  className={styles.remove}
                                  data-cy={`${DATA_CY}-logic-remove-icon-${branchingIndex}`}
                                  onClick={() => branchingArrayHelpers.remove(branchingIndex)}
                                />
                              </Form.Field>
                            </Form.Group>
                            <Form.Field>
                              <label>
                                Hidden questions
                              </label>
                              <Field name={`branching.${branchingIndex}.hidden_questions_ids`}>
                                {({
                                  field: { value },
                                  form: { setFieldValue: setHiddenQuestionValue },
                                }) => (
                                  <>
                                    <div data-cy={`${DATA_CY}-questions-list-${branchingIndex}`}>
                                      <QuestionsList
                                        hiddenQuestionsIds={branchingItem.hidden_questions_ids}
                                        questions={questions}
                                        showOnlyHidden={true}
                                        onHideQuestion={questionId => {
                                          const newValue = value;
                                          newValue.push(questionId);
                                          setHiddenQuestionValue(`branching.${branchingIndex}.hidden_questions_ids`, newValue);
                                        }}
                                        onShowQuestion={questionId => {
                                          const newValue = value.filter(item => item !== questionId);
                                          setHiddenQuestionValue(`branching.${branchingIndex}.hidden_questions_ids`, newValue);
                                        }}
                                      />
                                    </div>
                                    <Button
                                      data-cy={`${DATA_CY}-select-questions-list-button-${branchingIndex}`}
                                      onClick={() => setModalProps({
                                        visible: true,
                                        ruleIndex: branchingIndex,
                                        setHiddenQuestionValue,
                                        initialValues: branchingItem.hidden_questions_ids,
                                        value,
                                      })}
                                    >
                                      Select
                                    </Button>
                                  </>
                                )}
                              </Field>
                            </Form.Field>
                            <Divider />
                          </div>
                        ))}
                        <Button
                          primary
                          data-cy={`${DATA_CY}-add-branch-button`}
                          onClick={() => branchingArrayHelpers.push(defaultBranchingRule)}
                        >
                          Add branch
                        </Button>
                      </>
                    )}
                  />
                  <QuestionsSelectorModal
                    open={modalProps.visible}
                    onCancel={handleModalCancel}
                    onClose={handleModalClose}
                  >
                    {modalProps.visible
                      ? (
                        <QuestionsList
                          hiddenQuestionsIds={
                            values.branching[modalProps.ruleIndex].hidden_questions_ids
                          }
                          questions={questions}
                          onHideQuestion={questionId => {
                            const newValue = modalProps.value;
                            newValue.push(questionId);
                            modalProps.setHiddenQuestionValue(`branching.${modalProps.ruleIndex}.hidden_questions_ids`, newValue);
                          }}
                          onShowQuestion={questionId => {
                            const newValue = modalProps.value.filter(item => item !== questionId);
                            setModalProps({ ...modalProps, value: newValue });
                            modalProps.setHiddenQuestionValue(`branching.${modalProps.ruleIndex}.hidden_questions_ids`, newValue);
                          }}
                        />
                      )
                      : <></>}
                  </QuestionsSelectorModal>
                </Form>
              );
            }}
          </Formik>
          <SurveyQuestionsReference
            className={styles.questionsReference}
            questions={questions}
          />
        </Card.Content>
      </Card>
    </div>
  );
};

SurveyBranchingEditor.defaultProps = {
  branching: [],
  questions: [],
};

SurveyBranchingEditor.propTypes = {
  submitFormRef: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  branching: PropTypes.arrayOf(branchingRuleType),
  questions: PropTypes.arrayOf(questionType),
};

export default SurveyBranchingEditor;

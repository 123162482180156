import * as Yup from 'yup';

export const clientValidationSchema = Yup.object().shape({
  client_type: Yup.string().trim().ensure().min(1, 'Required'),
  contract_expiry: Yup.date().nullable(),
  footer: Yup.string().nullable(),
  logo: Yup.string().nullable(),
  manager_id: Yup.string().trim().ensure().min(1, 'Required'),
  name: Yup.string().trim().ensure().min(1, 'Required'),
  sage_reference: Yup.string(),
  status: Yup.string().trim().ensure().min(1, 'Required'),
  store_locator: Yup.string(),
});

export const clientFilterDefaultValues = {
  client_type: '',
  name: '',
  sage_reference: '',
  manager_id: '',
};

export const clientFilterValidationSchema = Yup.object().shape({
  client_type: Yup.string(),
  manager_id: Yup.string(),
  name: Yup.string(),
  sage_reference: Yup.string(),
});

export const addReportValidationSchema = Yup.object().shape({
  report_id: Yup.string().trim().ensure().min(1, 'Required'),
  report_name: Yup.string().trim().ensure().min(1, 'Required'),
});

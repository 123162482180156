import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { useParams } from 'react-router-dom';
import { Form } from 'semantic-ui-react';

import { entitiesAPI, errorAPI } from '../../../../../api';
import { Entity } from '../../../../../constants';
import { FormFieldDynamicSearchWrapper, FormFieldWrapper } from '../../../../common';
import { automatedExportValidationSchema } from '../helpers';

const ClientsAutomatedExportsDetails = ({ data, submitFormRef, onSave }) => {
  const { exportId } = useParams();
  const alert = useAlert();

  const buildClientSearchOptions = clients => clients.map(client => ({
    text: client.name,
    value: client.id,
  }));

  const [filteredClients, setFilteredClients] = useState(exportId
    ? [{
      text: data.client_name,
      value: data.client_id,
    }]
    : []);

  const searchClients = wildcard => {
    entitiesAPI.fetchAll(Entity.CLIENTS, { name: wildcard })
      .then(clients => setFilteredClients(buildClientSearchOptions(clients.items)))
      .catch(error => {
        alert.error(`Error searching clients: ${error.message}`);
        errorAPI.sendError(error.message, error.stack);
      });
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={data || {}}
      validationSchema={automatedExportValidationSchema}
      onSubmit={onSave}
    >
      {({ handleSubmit }) => (
        <Form>
          <button ref={submitFormRef} hidden type="submit" onClick={handleSubmit} />

          <FormFieldWrapper
            required
            label="Title"
            name="name"
          />

          <FormFieldDynamicSearchWrapper
            required
            label="Client"
            name="client_id"
            options={filteredClients}
            onSearchChange={searchClients}
          />

          <FormFieldWrapper
            required
            label="Username"
            name="username"
          />

          <FormFieldWrapper
            required
            label="Password"
            name="password"
            type="password"
          />

          <FormFieldWrapper
            required
            label="Confirm Password"
            name="password_confirm"
            type="password"
          />
        </Form>
      )}
    </Formik>
  );
};

ClientsAutomatedExportsDetails.defaultProps = {
  data: null,
};

ClientsAutomatedExportsDetails.propTypes = {
  submitFormRef: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  data: PropTypes.object,
};

export default ClientsAutomatedExportsDetails;

import PropTypes from 'prop-types';
import React from 'react';

import { UserRole } from '../../../../constants';
import { useUserRole } from '../../../../hooks';
import { auditFilterDefaultValues } from '../helpers';
import AuditsTableFilterAdminRole from './AuditsTableFilterAdminRole/AuditsTableFilterAdminRole.component';
// import AuditsTableFilterAuditorRole from './AuditsTableFilterAuditorRole/AuditsTableFilterAuditorRole.component';
import AuditsTableFilterClientRole from './AuditsTableFilterClientRole/AuditsTableFilterClientRole.component';

const AuditsTableFilter = ({ values, onChange }) => {
  const role = useUserRole();

  if (role === UserRole.AUDITOR) {
    // FIXME: when newsfeed is working
    // return <AuditsTableFilterAuditorRole />;
    return null;
  }

  if (role === UserRole.CLIENT) {
    return <AuditsTableFilterClientRole values={values} onChange={onChange} />;
  }

  return <AuditsTableFilterAdminRole values={values} onChange={onChange} />;
};

AuditsTableFilter.defaultProps = {
  values: auditFilterDefaultValues,
};

AuditsTableFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    order_deadline_from: PropTypes.string,
    order_deadline_to: PropTypes.string,
  }),
};

export default AuditsTableFilter;

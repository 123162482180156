import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'semantic-ui-react';

import { SLHeader } from '../../../common';
import styles from './AccountHeader.module.scss';

const AccountHeader = ({ onSave }) => (
  <SLHeader
    dataCy="account-header"
    title="My account"
  >
    <Button
      primary
      className={styles.save}
      data-cy="account-header-save-button"
      type="submit"
      onClick={onSave}
    >
      Save
    </Button>
  </SLHeader>
);

AccountHeader.propTypes = {
  onSave: PropTypes.func.isRequired,
};

export default AccountHeader;

import classnames from 'classnames';
import { Field, useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'semantic-ui-react';

import FieldStatus from '../../../fieldLogic/fieldStatus';
import styles from './FormFieldSelectWrapper.module.scss';
import FormFieldTemplate from './Template/FormFieldTemplate.component';

const FormFieldSelectWrapper = ({
  dataCy,
  help,
  inline,
  label,
  labelStyle,
  labelTooltip,
  multiple,
  name,
  onChange,
  onStateHandler,
  options,
  readOnly,
  required,
  valueStyle,
  ...rest
}) => {
  const [field, meta, helpers] = useField(name);

  let state = FieldStatus.EDITABLE;

  if (onStateHandler) {
    state = onStateHandler(name);
  }

  if (state === FieldStatus.HIDDEN) {
    return null;
  }

  const dataCyPrefix = dataCy || name;

  const printSelectedOptions = () => {
    if (multiple) {
      const valueArray = field?.value?.map(v => options.find(o => o.value === v)?.text || v);
      return valueArray.join(', ');
    }
    return options.find(o => o.value === field.value)?.text || '';
  };

  const readOnlyField = () => {
    if (name === 'order_briefing_documents_metadata') {
      return (
        <>
          {field?.value?.length ? field?.value?.map((item, index) => {
            const filePath = item.text.split('/');
            const fileName = filePath.pop();

            return (
              <span key={index} className={classnames({ [valueStyle]: valueStyle })}>
                <a
                  className={styles.textPdf}
                  href={item.value} rel="noopener noreferrer" target="_blank"
                >
                  {fileName}
                </a>
              </span>
            );
          }) : <></> }
        </>
      );
    }
    return (
      <span className={classnames({ [valueStyle]: valueStyle })}>
        {field?.value && printSelectedOptions()}
      </span>
    );
  };

  const editableField = () => (
    <Field
      as={Form.Select}
      data-cy={`${dataCyPrefix}-field`}
      error={meta.touched ? meta.touched && meta.error : meta.error}
      multiple={multiple}
      name={name}
      options={options.map(o => {
        if (o.nested === 'true') {
          o.className = styles.nestedItem;
        }
        return o;
      })}
      readOnly={readOnly}
      required={required}
      onBlur={null}
      onChange={(_e, { value }) => {
        helpers.setValue(value);
        if (onChange) {
          onChange(value);
        }
      }}
      {...rest}
    />
  );

  return (
    <FormFieldTemplate
      dataCy={dataCyPrefix}
      help={help}
      inline={inline}
      label={label}
      labelStyle={labelStyle}
      labelTooltip={labelTooltip}
      required={required}
    >
      {state === FieldStatus.READONLY || readOnly ? readOnlyField() : editableField()}
    </FormFieldTemplate>
  );
};

FormFieldSelectWrapper.defaultProps = {
  dataCy: null,
  help: null,
  inline: false,
  label: null,
  labelStyle: null,
  labelTooltip: null,
  multiple: false,
  onChange: null,
  onStateHandler: null,
  readOnly: false,
  required: false,
  valueStyle: null,
};

FormFieldSelectWrapper.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  dataCy: PropTypes.string,
  help: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  inline: PropTypes.bool,
  label: PropTypes.string,
  labelStyle: PropTypes.string,
  labelTooltip: PropTypes.string,
  multiple: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  valueStyle: PropTypes.string,
  onChange: PropTypes.func,
  onStateHandler: PropTypes.func,
};

export default FormFieldSelectWrapper;

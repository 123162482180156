import classnames from 'classnames';
import moment from 'moment';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { Button, Divider } from 'semantic-ui-react';

import {
  GreenPushpinMarker,
  PinkMarker,
  YellowPushpinMarker,
} from '../../../../assets/images/markers';
import { AuditStatus } from '../../../../constants';
import {
  createMarker,
  itemCoordinates,
} from '../../../../utils/googleMapsHelpers';
import styles from './MapMobileHelper.module.scss';

const auditCoordinates = data => data?.items?.map(item => itemCoordinates({
  coords: item?.site_coordinates,
  title: item?.site_name,
  icon: item?.status === AuditStatus.ASSIGNED ? YellowPushpinMarker : GreenPushpinMarker,
  item })) || [];

const auditorCoordinates = auditor => itemCoordinates({
  coords: auditor?.current_coordinates,
  title: auditor?.email,
  icon: PinkMarker,
  item: auditor,
});

const content = data => {
  if (data.auditor) {
    return (
      <div className={styles.infoTitleAuditor}>
        {data.auditor.first_name}
      </div>
    );
  }

  return (
    <div className={styles.infoContainer}>
      <div className={styles.infoTitle}>
        <div>
          <span className={styles.auditIcon} />
          {data.item.site_name}
        </div>
      </div>
      <div className={styles.infoContent}>
        <div className={classnames(styles.row, { [styles.hidden]: !data.item.visit_info })}>
          {data.item.visit_info}
        </div>
        <Divider className={classnames({ [styles.hidden]: !data.item.visit_info })} />
        <div className={classnames(styles.row, { [styles.hidden]: !data.item.item_to_order })}>
          <span className={styles.iconList} />
          {' '}
          {data.item.item_to_order}
        </div>
        <Divider className={classnames({ [styles.hidden]: !data.item.item_to_order })} />
        <div className={classnames(styles.row, { [styles.hidden]: !data.item.estimated_expenses })}>
          <span className={styles.estimateIcon} />
          {' '}
          {data.item.estimated_expenses}
        </div>
        <Divider className={classnames({ [styles.hidden]: !data.item.estimated_expenses })} />
        <div className={classnames(styles.row, { [styles.hidden]: !data.item.site_post_code })}>
          <span className={styles.locationIcon} />
          {' Postcode: '}
          {data.item.site_post_code}
        </div>
        <Divider className={classnames({ [styles.hidden]: !data.item.site_post_code })} />
        <div
          className={classnames(styles.rowDouble, { [styles.hidden]: !data.item.deadline_date && !data.item.mileage })}
        >
          <div>
            <span className={styles.mileageIcon} />
            {data.item.mileage}
          </div>
          <div className={styles.deadline}>
            <span className={styles.deadlineIcon} />
            {moment(data.item.deadline_date).format('DD/MM/yyyy')}
          </div>
        </div>
        {data.item.submitted_date === null
          ? <>
            <Divider />
            <div className={styles.containerButton}>
              <Button
                primary
                className={styles.button}
                size="tiny"
              >
                <a href={`/audits/${data.item.id}`}>
                  {'More info'}
                </a>
              </Button>
            </div>
          </> : null}
      </div>
    </div>
  );
};

let infoWindow;
const getInfoWindow = () => {
  if (!infoWindow) {
    infoWindow = new window.google.maps.InfoWindow({});
  }
  return infoWindow;
};

const getMarkers = (map, coordinates) => (
  coordinates?.map(item => {
    const marker = createMarker(item, map);
    marker.addListener(
      'click',
      () => {
        const iw = getInfoWindow();
        iw.setContent(ReactDOMServer.renderToString(content(item)));
        iw.open(map, marker);
      },
      { passive: true },
    );
    return marker;
  })
);

// eslint-disable-next-line import/prefer-default-export
export const entityMapFilter = ({ map, data, fetchedAuditor }) => {
  getMarkers(map, [...auditCoordinates(data), auditorCoordinates(fetchedAuditor)]);
};

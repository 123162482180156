/* eslint-disable no-console */
import { Loader } from '@googlemaps/js-api-loader';

import appConfig from './appConfig';

const configureGoogleMaps = async () => {
  const loaderMap = new Loader({
    apiKey: window.avs?.gmk || appConfig.googleMapsApiKey,
    version: 'weekly',
    libraries: ['drawing'],
  });

  try {
    await loaderMap.load();
  } catch (error) {
    console.error(`Error loading Google Maps API: ${error}`);
  }
};

export default configureGoogleMaps;

import FieldStatus from './fieldStatus';

const conditionObject = {
  auditor: {
    edit: {
      access_to_car: FieldStatus.EDITABLE,
      account_number: FieldStatus.EDITABLE,
      account_slug: FieldStatus.EDITABLE,
      bank_country: FieldStatus.EDITABLE,
      card_name: FieldStatus.EDITABLE,
      circumstances: FieldStatus.EDITABLE,
      country: FieldStatus.EDITABLE,
      created_at: FieldStatus.READONLY,
      date_of_birth: FieldStatus.READONLY,
      email: FieldStatus.READONLY,
      email_communication: FieldStatus.EDITABLE,
      email_settings: FieldStatus.EDITABLE,
      first_name: FieldStatus.EDITABLE,
      gender: FieldStatus.EDITABLE,
      hear_about_us: FieldStatus.READONLY,
      hear_about_us_details: FieldStatus.READONLY,
      home_address_1: FieldStatus.EDITABLE,
      home_address_2: FieldStatus.EDITABLE,
      home_city: FieldStatus.EDITABLE,
      home_county: FieldStatus.EDITABLE,
      home_number: FieldStatus.EDITABLE,
      home_address_status: FieldStatus.EDITABLE,
      home_post_code: FieldStatus.EDITABLE,
      honorific: FieldStatus.EDITABLE,
      iban_number: FieldStatus.EDITABLE,
      id: FieldStatus.READONLY,
      identification: FieldStatus.READONLY,
      image: FieldStatus.EDITABLE,
      internal_id: FieldStatus.READONLY,
      last_access: FieldStatus.EDITABLE,
      last_name: FieldStatus.EDITABLE,
      marital_status: FieldStatus.EDITABLE,
      middle_name: FieldStatus.EDITABLE,
      mobile_number: FieldStatus.EDITABLE,
      ni_pps: FieldStatus.READONLY,
      occupation: FieldStatus.EDITABLE,
      passport: FieldStatus.READONLY,
      recommended_by: FieldStatus.EDITABLE,
      servelegal_community: FieldStatus.EDITABLE,
      sort_code: FieldStatus.EDITABLE,
      status: FieldStatus.EDITABLE,
      student_loans: FieldStatus.EDITABLE,
      swift_code: FieldStatus.EDITABLE,
      term_address_1: FieldStatus.EDITABLE,
      term_address_2: FieldStatus.EDITABLE,
      term_city: FieldStatus.EDITABLE,
      term_county: FieldStatus.EDITABLE,
      term_address_status: FieldStatus.EDITABLE,
      term_post_code: FieldStatus.EDITABLE,
      tertiary_address_1: FieldStatus.EDITABLE,
      tertiary_address_2: FieldStatus.EDITABLE,
      tertiary_city: FieldStatus.EDITABLE,
      tertiary_county: FieldStatus.EDITABLE,
      tertiary_post_code: FieldStatus.EDITABLE,
      travel_outside: FieldStatus.EDITABLE,
      user_id: FieldStatus.READONLY,
      why_servelegal: FieldStatus.EDITABLE,
      auto_geolocation_interval: FieldStatus.EDITABLE,
      enable_notifications: FieldStatus.EDITABLE,
      facial_images_consent: FieldStatus.EDITABLE,
      special_category_consent: FieldStatus.EDITABLE,
      communications_consent: FieldStatus.EDITABLE,
      nationality: FieldStatus.EDITABLE,
      visa: FieldStatus.EDITABLE,
      visa_expiration: FieldStatus.EDITABLE,
    },
    view: {
      access_to_car: FieldStatus.READONLY,
      account_number: FieldStatus.READONLY,
      account_slug: FieldStatus.READONLY,
      bank_country: FieldStatus.READONLY,
      card_name: FieldStatus.READONLY,
      circumstances: FieldStatus.READONLY,
      country: FieldStatus.READONLY,
      created_at: FieldStatus.READONLY,
      date_of_birth: FieldStatus.READONLY,
      email: FieldStatus.READONLY,
      email_communication: FieldStatus.READONLY,
      email_settings: FieldStatus.READONLY,
      first_name: FieldStatus.READONLY,
      gender: FieldStatus.READONLY,
      hear_about_us: FieldStatus.READONLY,
      hear_about_us_details: FieldStatus.READONLY,
      home_address_1: FieldStatus.READONLY,
      home_address_2: FieldStatus.READONLY,
      home_city: FieldStatus.READONLY,
      home_county: FieldStatus.READONLY,
      home_number: FieldStatus.READONLY,
      home_address_status: FieldStatus.READONLY,
      home_post_code: FieldStatus.READONLY,
      honorific: FieldStatus.READONLY,
      iban_number: FieldStatus.READONLY,
      id: FieldStatus.READONLY,
      identification: FieldStatus.READONLY,
      image: FieldStatus.READONLY,
      internal_id: FieldStatus.READONLY,
      last_access: FieldStatus.READONLY,
      last_name: FieldStatus.READONLY,
      marital_status: FieldStatus.READONLY,
      middle_name: FieldStatus.READONLY,
      mobile_number: FieldStatus.READONLY,
      ni_pps: FieldStatus.READONLY,
      occupation: FieldStatus.READONLY,
      passport: FieldStatus.READONLY,
      recommended_by: FieldStatus.READONLY,
      servelegal_community: FieldStatus.READONLY,
      sort_code: FieldStatus.READONLY,
      status: FieldStatus.READONLY,
      student_loans: FieldStatus.READONLY,
      swift_code: FieldStatus.READONLY,
      term_address_1: FieldStatus.READONLY,
      term_address_2: FieldStatus.READONLY,
      term_city: FieldStatus.READONLY,
      term_county: FieldStatus.READONLY,
      term_address_status: FieldStatus.READONLY,
      term_post_code: FieldStatus.READONLY,
      tertiary_address_1: FieldStatus.READONLY,
      tertiary_address_2: FieldStatus.READONLY,
      tertiary_city: FieldStatus.READONLY,
      tertiary_county: FieldStatus.READONLY,
      tertiary_post_code: FieldStatus.READONLY,
      travel_outside: FieldStatus.READONLY,
      user_id: FieldStatus.READONLY,
      why_servelegal: FieldStatus.READONLY,
      auto_geolocation_interval: FieldStatus.READONLY,
      enable_notifications: FieldStatus.READONLY,
      nationality: FieldStatus.READONLY,
      visa: FieldStatus.READONLY,
      visa_expiration: FieldStatus.READONLY,
    },
  },
  area_manager: {
    edit: {
      about_yourself: FieldStatus.EDITABLE,
      access_to_car: FieldStatus.EDITABLE,
      account_number: FieldStatus.READONLY,
      account_slug: FieldStatus.EDITABLE,
      auditor_status: FieldStatus.READONLY,
      bank_country: FieldStatus.READONLY,
      card_name: FieldStatus.READONLY,
      circumstances: FieldStatus.READONLY,
      country: FieldStatus.READONLY,
      created_at: FieldStatus.READONLY,
      date_of_birth: FieldStatus.READONLY,
      email: FieldStatus.READONLY,
      email_communication: FieldStatus.READONLY,
      email_settings: FieldStatus.READONLY,
      first_name: FieldStatus.EDITABLE,
      gender: FieldStatus.READONLY,
      hear_about_us: FieldStatus.READONLY,
      hear_about_us_details: FieldStatus.READONLY,
      home_address_1: FieldStatus.EDITABLE,
      home_address_2: FieldStatus.EDITABLE,
      home_city: FieldStatus.EDITABLE,
      home_county: FieldStatus.EDITABLE,
      home_number: FieldStatus.EDITABLE,
      home_address_status: FieldStatus.EDITABLE,
      home_post_code: FieldStatus.EDITABLE,
      honorific: FieldStatus.READONLY,
      iban_number: FieldStatus.READONLY,
      id: FieldStatus.READONLY,
      identification: FieldStatus.READONLY,
      image: FieldStatus.EDITABLE,
      internal_id: FieldStatus.READONLY,
      last_access: FieldStatus.EDITABLE,
      last_name: FieldStatus.EDITABLE,
      marital_status: FieldStatus.READONLY,
      middle_name: FieldStatus.EDITABLE,
      mobile_number: FieldStatus.EDITABLE,
      ni_pps: FieldStatus.READONLY,
      notes: FieldStatus.EDITABLE,
      occupation: FieldStatus.READONLY,
      passport: FieldStatus.READONLY,
      recommended_by: FieldStatus.READONLY,
      servelegal_community: FieldStatus.READONLY,
      sort_code: FieldStatus.READONLY,
      status: FieldStatus.EDITABLE,
      student_loans: FieldStatus.READONLY,
      swift_code: FieldStatus.READONLY,
      term_address_1: FieldStatus.EDITABLE,
      term_address_2: FieldStatus.EDITABLE,
      term_city: FieldStatus.EDITABLE,
      term_county: FieldStatus.EDITABLE,
      term_address_status: FieldStatus.EDITABLE,
      term_post_code: FieldStatus.EDITABLE,
      travel_outside: FieldStatus.EDITABLE,
      user_id: FieldStatus.READONLY,
      why_servelegal: FieldStatus.EDITABLE,
      nationality: FieldStatus.EDITABLE,
      visa: FieldStatus.EDITABLE,
      visa_expiration: FieldStatus.EDITABLE,
    },
    view: {
      about_yourself: FieldStatus.READONLY,
      access_to_car: FieldStatus.READONLY,
      account_number: FieldStatus.READONLY,
      account_slug: FieldStatus.READONLY,
      auditor_status: FieldStatus.READONLY,
      bank_country: FieldStatus.READONLY,
      card_name: FieldStatus.READONLY,
      circumstances: FieldStatus.READONLY,
      country: FieldStatus.READONLY,
      created_at: FieldStatus.READONLY,
      date_of_birth: FieldStatus.READONLY,
      email: FieldStatus.READONLY,
      email_communication: FieldStatus.READONLY,
      email_settings: FieldStatus.READONLY,
      first_name: FieldStatus.READONLY,
      gender: FieldStatus.READONLY,
      hear_about_us: FieldStatus.READONLY,
      hear_about_us_details: FieldStatus.READONLY,
      home_address_1: FieldStatus.READONLY,
      home_address_2: FieldStatus.READONLY,
      home_city: FieldStatus.READONLY,
      home_county: FieldStatus.READONLY,
      home_number: FieldStatus.READONLY,
      home_address_status: FieldStatus.READONLY,
      home_post_code: FieldStatus.READONLY,
      honorific: FieldStatus.READONLY,
      iban_number: FieldStatus.READONLY,
      id: FieldStatus.READONLY,
      identification: FieldStatus.READONLY,
      image: FieldStatus.READONLY,
      internal_id: FieldStatus.READONLY,
      last_access: FieldStatus.READONLY,
      last_name: FieldStatus.READONLY,
      marital_status: FieldStatus.READONLY,
      middle_name: FieldStatus.READONLY,
      mobile_number: FieldStatus.READONLY,
      ni_pps: FieldStatus.READONLY,
      notes: FieldStatus.READONLY,
      occupation: FieldStatus.READONLY,
      passport: FieldStatus.READONLY,
      recommended_by: FieldStatus.READONLY,
      servelegal_community: FieldStatus.READONLY,
      sort_code: FieldStatus.READONLY,
      status: FieldStatus.READONLY,
      student_loans: FieldStatus.READONLY,
      swift_code: FieldStatus.READONLY,
      term_address_1: FieldStatus.READONLY,
      term_address_2: FieldStatus.READONLY,
      term_city: FieldStatus.READONLY,
      term_county: FieldStatus.READONLY,
      term_address_status: FieldStatus.READONLY,
      term_post_code: FieldStatus.READONLY,
      travel_outside: FieldStatus.READONLY,
      user_id: FieldStatus.READONLY,
      why_servelegal: FieldStatus.READONLY,
      nationality: FieldStatus.READONLY,
      visa: FieldStatus.READONLY,
      visa_expiration: FieldStatus.READONLY,
    },
  },
  client_services: {
    edit: {
      about_yourself: FieldStatus.EDITABLE,
      access_to_car: FieldStatus.EDITABLE,
      account_number: FieldStatus.EDITABLE,
      account_slug: FieldStatus.EDITABLE,
      auditor_status: FieldStatus.READONLY,
      bank_country: FieldStatus.EDITABLE,
      card_name: FieldStatus.EDITABLE,
      circumstances: FieldStatus.READONLY,
      country: FieldStatus.READONLY,
      created_at: FieldStatus.READONLY,
      date_of_birth: FieldStatus.READONLY,
      email: FieldStatus.READONLY,
      email_communication: FieldStatus.READONLY,
      email_settings: FieldStatus.READONLY,
      first_name: FieldStatus.EDITABLE,
      gender: FieldStatus.READONLY,
      hear_about_us: FieldStatus.READONLY,
      hear_about_us_details: FieldStatus.READONLY,
      home_address_1: FieldStatus.EDITABLE,
      home_address_2: FieldStatus.EDITABLE,
      home_city: FieldStatus.EDITABLE,
      home_county: FieldStatus.EDITABLE,
      home_number: FieldStatus.EDITABLE,
      home_address_status: FieldStatus.EDITABLE,
      home_post_code: FieldStatus.EDITABLE,
      honorific: FieldStatus.READONLY,
      iban_number: FieldStatus.EDITABLE,
      id: FieldStatus.READONLY,
      identification: FieldStatus.READONLY,
      image: FieldStatus.EDITABLE,
      internal_id: FieldStatus.READONLY,
      last_access: FieldStatus.EDITABLE,
      last_name: FieldStatus.EDITABLE,
      marital_status: FieldStatus.READONLY,
      middle_name: FieldStatus.EDITABLE,
      mobile_number: FieldStatus.EDITABLE,
      ni_pps: FieldStatus.READONLY,
      notes: FieldStatus.EDITABLE,
      occupation: FieldStatus.READONLY,
      passport: FieldStatus.READONLY,
      recommended_by: FieldStatus.READONLY,
      servelegal_community: FieldStatus.READONLY,
      sort_code: FieldStatus.READONLY,
      status: FieldStatus.EDITABLE,
      student_loans: FieldStatus.READONLY,
      swift_code: FieldStatus.READONLY,
      term_address_1: FieldStatus.EDITABLE,
      term_address_2: FieldStatus.EDITABLE,
      term_city: FieldStatus.EDITABLE,
      term_county: FieldStatus.EDITABLE,
      term_address_status: FieldStatus.EDITABLE,
      term_post_code: FieldStatus.EDITABLE,
      travel_outside: FieldStatus.EDITABLE,
      user_id: FieldStatus.READONLY,
      why_servelegal: FieldStatus.EDITABLE,
      nationality: FieldStatus.EDITABLE,
      visa: FieldStatus.EDITABLE,
      visa_expiration: FieldStatus.EDITABLE,
    },
    view: {
      about_yourself: FieldStatus.READONLY,
      access_to_car: FieldStatus.READONLY,
      account_number: FieldStatus.READONLY,
      account_slug: FieldStatus.READONLY,
      auditor_status: FieldStatus.READONLY,
      bank_country: FieldStatus.READONLY,
      card_name: FieldStatus.READONLY,
      circumstances: FieldStatus.READONLY,
      country: FieldStatus.READONLY,
      created_at: FieldStatus.READONLY,
      date_of_birth: FieldStatus.READONLY,
      email: FieldStatus.READONLY,
      email_communication: FieldStatus.READONLY,
      email_settings: FieldStatus.READONLY,
      first_name: FieldStatus.READONLY,
      gender: FieldStatus.READONLY,
      hear_about_us: FieldStatus.READONLY,
      hear_about_us_details: FieldStatus.READONLY,
      home_address_1: FieldStatus.READONLY,
      home_address_2: FieldStatus.READONLY,
      home_city: FieldStatus.READONLY,
      home_county: FieldStatus.READONLY,
      home_number: FieldStatus.READONLY,
      home_address_status: FieldStatus.READONLY,
      home_post_code: FieldStatus.READONLY,
      honorific: FieldStatus.READONLY,
      iban_number: FieldStatus.READONLY,
      id: FieldStatus.READONLY,
      identification: FieldStatus.READONLY,
      image: FieldStatus.READONLY,
      internal_id: FieldStatus.READONLY,
      last_access: FieldStatus.READONLY,
      last_name: FieldStatus.READONLY,
      marital_status: FieldStatus.READONLY,
      middle_name: FieldStatus.READONLY,
      mobile_number: FieldStatus.READONLY,
      ni_pps: FieldStatus.READONLY,
      notes: FieldStatus.READONLY,
      occupation: FieldStatus.READONLY,
      passport: FieldStatus.READONLY,
      recommended_by: FieldStatus.READONLY,
      servelegal_community: FieldStatus.READONLY,
      sort_code: FieldStatus.READONLY,
      status: FieldStatus.READONLY,
      student_loans: FieldStatus.READONLY,
      swift_code: FieldStatus.READONLY,
      term_address_1: FieldStatus.READONLY,
      term_address_2: FieldStatus.READONLY,
      term_city: FieldStatus.READONLY,
      term_county: FieldStatus.READONLY,
      term_address_status: FieldStatus.READONLY,
      term_post_code: FieldStatus.READONLY,
      travel_outside: FieldStatus.READONLY,
      user_id: FieldStatus.READONLY,
      why_servelegal: FieldStatus.READONLY,
      nationality: FieldStatus.READONLY,
      visa: FieldStatus.READONLY,
      visa_expiration: FieldStatus.READONLY,
    },
  },
  data: {
    edit: {
      about_yourself: FieldStatus.EDITABLE,
      access_to_car: FieldStatus.EDITABLE,
      account_number: FieldStatus.EDITABLE,
      account_slug: FieldStatus.EDITABLE,
      auditor_status: FieldStatus.READONLY,
      bank_country: FieldStatus.EDITABLE,
      card_name: FieldStatus.EDITABLE,
      circumstances: FieldStatus.EDITABLE,
      country: FieldStatus.EDITABLE,
      created_at: FieldStatus.READONLY,
      date_of_birth: FieldStatus.EDITABLE,
      email: FieldStatus.EDITABLE,
      email_communication: FieldStatus.EDITABLE,
      email_settings: FieldStatus.EDITABLE,
      first_name: FieldStatus.EDITABLE,
      gender: FieldStatus.EDITABLE,
      hear_about_us: FieldStatus.EDITABLE,
      hear_about_us_details: FieldStatus.EDITABLE,
      home_address_1: FieldStatus.EDITABLE,
      home_address_2: FieldStatus.EDITABLE,
      home_city: FieldStatus.EDITABLE,
      home_county: FieldStatus.EDITABLE,
      home_number: FieldStatus.EDITABLE,
      home_address_status: FieldStatus.EDITABLE,
      home_post_code: FieldStatus.EDITABLE,
      honorific: FieldStatus.EDITABLE,
      iban_number: FieldStatus.EDITABLE,
      id: FieldStatus.READONLY,
      identification: FieldStatus.EDITABLE,
      image: FieldStatus.EDITABLE,
      internal_id: FieldStatus.READONLY,
      last_access: FieldStatus.EDITABLE,
      last_name: FieldStatus.EDITABLE,
      marital_status: FieldStatus.EDITABLE,
      middle_name: FieldStatus.EDITABLE,
      mobile_number: FieldStatus.EDITABLE,
      ni_pps: FieldStatus.EDITABLE,
      notes: FieldStatus.EDITABLE,
      occupation: FieldStatus.EDITABLE,
      passport: FieldStatus.EDITABLE,
      recommended_by: FieldStatus.EDITABLE,
      servelegal_community: FieldStatus.EDITABLE,
      sort_code: FieldStatus.EDITABLE,
      status: FieldStatus.EDITABLE,
      student_loans: FieldStatus.EDITABLE,
      swift_code: FieldStatus.EDITABLE,
      term_address_1: FieldStatus.EDITABLE,
      term_address_2: FieldStatus.EDITABLE,
      term_city: FieldStatus.EDITABLE,
      term_county: FieldStatus.EDITABLE,
      term_address_status: FieldStatus.EDITABLE,
      term_post_code: FieldStatus.EDITABLE,
      travel_outside: FieldStatus.EDITABLE,
      user_id: FieldStatus.READONLY,
      why_servelegal: FieldStatus.EDITABLE,
      enable_auto_geolocation: FieldStatus.READONLY,
      nationality: FieldStatus.EDITABLE,
      visa: FieldStatus.EDITABLE,
      visa_expiration: FieldStatus.EDITABLE,
    },
    view: {
      about_yourself: FieldStatus.READONLY,
      access_to_car: FieldStatus.READONLY,
      account_number: FieldStatus.READONLY,
      account_slug: FieldStatus.READONLY,
      auditor_status: FieldStatus.READONLY,
      bank_country: FieldStatus.READONLY,
      card_name: FieldStatus.READONLY,
      circumstances: FieldStatus.READONLY,
      country: FieldStatus.READONLY,
      created_at: FieldStatus.READONLY,
      date_of_birth: FieldStatus.READONLY,
      email: FieldStatus.READONLY,
      email_communication: FieldStatus.READONLY,
      email_settings: FieldStatus.READONLY,
      first_name: FieldStatus.READONLY,
      gender: FieldStatus.READONLY,
      hear_about_us: FieldStatus.READONLY,
      hear_about_us_details: FieldStatus.READONLY,
      home_address_1: FieldStatus.READONLY,
      home_address_2: FieldStatus.READONLY,
      home_city: FieldStatus.READONLY,
      home_county: FieldStatus.READONLY,
      home_number: FieldStatus.READONLY,
      home_address_status: FieldStatus.READONLY,
      home_post_code: FieldStatus.READONLY,
      honorific: FieldStatus.READONLY,
      iban_number: FieldStatus.READONLY,
      id: FieldStatus.READONLY,
      identification: FieldStatus.READONLY,
      image: FieldStatus.READONLY,
      internal_id: FieldStatus.READONLY,
      last_access: FieldStatus.READONLY,
      last_name: FieldStatus.READONLY,
      marital_status: FieldStatus.READONLY,
      middle_name: FieldStatus.READONLY,
      mobile_number: FieldStatus.READONLY,
      ni_pps: FieldStatus.READONLY,
      notes: FieldStatus.READONLY,
      occupation: FieldStatus.READONLY,
      passport: FieldStatus.READONLY,
      recommended_by: FieldStatus.READONLY,
      servelegal_community: FieldStatus.READONLY,
      sort_code: FieldStatus.READONLY,
      status: FieldStatus.READONLY,
      student_loans: FieldStatus.READONLY,
      swift_code: FieldStatus.READONLY,
      term_address_1: FieldStatus.READONLY,
      term_address_2: FieldStatus.READONLY,
      term_city: FieldStatus.READONLY,
      term_county: FieldStatus.READONLY,
      term_address_status: FieldStatus.READONLY,
      term_post_code: FieldStatus.READONLY,
      travel_outside: FieldStatus.READONLY,
      user_id: FieldStatus.READONLY,
      why_servelegal: FieldStatus.READONLY,
      enable_auto_geolocation: FieldStatus.READONLY,
      nationality: FieldStatus.READONLY,
      visa: FieldStatus.READONLY,
      visa_expiration: FieldStatus.READONLY,
    },
  },
};

const getFieldStatus = (userRole, modeStatus) => fieldName => (
  conditionObject[userRole]?.[modeStatus]?.[fieldName] || FieldStatus.HIDDEN
);

export default getFieldStatus;

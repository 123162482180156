import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useMutation, useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { Button, Checkbox, Icon } from 'semantic-ui-react';

import {
  errorAPI,
  surveyRevisionsAPI,
} from '../../../../api';
import { DateTimeFormat, Entity } from '../../../../constants';
import history from '../../../../history';
import { SLTable, SLTableCellDateFormatter, SLTableCellDateFormatterType } from '../../../common';
import styles from './SurveysTable.module.scss';

const DATA_CY = 'surveys-table';

const formattedCell = ({ cell, row, userData }) => {
  const {
    handleAddRevision,
    expandedSurveyId,
    setExpandedSurveyId,
    isFetchingRevisions,
    handleStatusChange,
  } = userData;

  const value = {
    expander: () => (row.canExpand
      ? expandedSurveyId === row.original.id && isFetchingRevisions
        ? <Icon loading name="spinner" />
        : (
          <span
            className={row.isExpanded ? styles.expanded : styles.collapsed}
            data-cy={`${DATA_CY}-expander`}
            onClick={() => setExpandedSurveyId(
              expandedSurveyId === row.original.id ? null : row.original.id,
            )}
          />
        )
      : <span />),
    title: () => (row.canExpand
      ? (
        <span>
          {cell.value}
        </span>
      )
      : isFetchingRevisions
        ? <span />
        : cell.row.original.addRevisionLink
          ? (
            <Button
              data-cy={`${DATA_CY}-add-new-revision-button`}
              onClick={handleAddRevision}
            >
              {'Add New Revision'}
            </Button>
          )
          : (
            <Link to={`/surveys/${cell.row.original.survey_id}/revisions/${cell.row.original.id}/questions`}>
              {cell.row.original.name}
            </Link>
          )),
    actions: () => (row.canExpand
      ? <span />
      : isFetchingRevisions || cell.row.original.addRevisionLink
        ? (<span />)
        : (
          <div className={styles.actions}>
            <Link to={`/surveys/${cell.row.original.survey_id}/revisions/${cell.row.original.id}/branching`}>
              Branching
            </Link>
            <Link to={`/surveys/${cell.row.original.survey_id}/revisions/${cell.row.original.id}/validation`}>
              Validation
            </Link>
            <Link to={`/surveys/${cell.row.original.survey_id}/revisions/${cell.row.original.id}/reports`}>
              Reports
            </Link>
            <Link to={`/surveys/${cell.row.original.survey_id}/revisions/${cell.row.original.id}/logic`}>
              Logic
            </Link>
          </div>
        )),
    status: () => (row.canExpand || cell.row.original.addRevisionLink
      ? <span />
      : (
        <Checkbox
          toggle
          checked={cell.value === 'active'}
          onChange={
            (_e, { checked }) => handleStatusChange(
              cell.row.original.survey_id,
              cell.row.original.id,
              checked ? 'active' : 'inactive',
            )
          }
        />
      )
    ),
    internal_id: () => (
      <span>
        {cell.value}
      </span>
    ),
  };

  return value[cell.column.id]();
};

const columns = [
  {
    accessor: 'expander',
    label: '',
    Cell: formattedCell,
    sortable: false,
  },
  {
    accessor: 'internal_id',
    label: 'ID',
    Cell: formattedCell,
  },
  {
    accessor: 'title',
    label: 'Title',
    Cell: formattedCell,
  },
  {
    accessor: 'created_at',
    label: 'Date',
    Cell: SLTableCellDateFormatter({
      type: SLTableCellDateFormatterType.CUSTOM,
      format: DateTimeFormat.DAY_MONTH_YEAR_HOUR_MINUTES_24H,
    }),
  },
  {
    accessor: 'actions',
    label: 'Actions',
    Cell: formattedCell,
    sortable: false,
  },
  {
    accessor: 'status',
    label: '',
    Cell: formattedCell,
    sortable: false,
  },
];

const SurveysTable = ({
  data,
  pages,
  onLimitChange,
  onPageChange,
  onSortByChange,
  currentLimit,
  currentPage,
  currentSortBy,
}) => {
  const alert = useAlert();
  const [expandedSurveyId, setExpandedSurveyId] = useState(null);
  const [subRows, setSubRows] = useState([{}]);

  const { data: revisions, isFetching: isFetchingRevisions, refetch } = useQuery(
    [expandedSurveyId],
    () => surveyRevisionsAPI.fetchAll(expandedSurveyId),
    { enabled: Boolean(expandedSurveyId) },
  );

  const { mutate: patchSurveyRevision } = useMutation(surveyRevisionsAPI.patch, {
    onSuccess: refetch,
  });

  const { mutate: createRevision } = useMutation(surveyRevisionsAPI.duplicateLast, {
    onSuccess: response => {
      alert.info('Survey revision created');
      history.push(
        `/${Entity.SURVEYS}/${response.survey_id}/revisions/${response.id}/questions`,
      );
    },
    onError: error => {
      alert.error(`Error creating survey revision: ${JSON.stringify(error)}`);
      errorAPI.sendError(error.message, error.stack);
    },
  });

  const handleStatusChange = (surveyId, revisionId, status) => {
    patchSurveyRevision({ surveyId, revisionId, payload: { status } });
  };

  useEffect(() => {
    if (revisions) {
      // Add a row after the revisions to add a new one
      revisions.items.push({ addRevisionLink: true, survey_id: revisions[0]?.survey_id });
      setSubRows(revisions.items);
    }
  }, [revisions]);

  const handleAddRevision = useCallback(() => {
    createRevision(expandedSurveyId);
  }, [createRevision, expandedSurveyId]);

  return (
    <SLTable
      columns={columns}
      currentLimit={currentLimit}
      currentPage={currentPage}
      currentSortBy={currentSortBy}
      data={data.map(survey => ({
        ...survey,
        expanded: expandedSurveyId === survey.id && !isFetchingRevisions,
        subRows: expandedSurveyId === survey.id && !isFetchingRevisions ? subRows : [{}],
      }))}
      dataCy={DATA_CY}
      pages={pages}
      userData={{
        handleAddRevision,
        expandedSurveyId,
        setExpandedSurveyId,
        isFetchingRevisions,
        handleStatusChange,
      }}
      onLimitChange={onLimitChange}
      onPageChange={onPageChange}
      onSortByChange={onSortByChange}
    />
  );
};

SurveysTable.defaultProps = {
  currentSortBy: {},
};

SurveysTable.propTypes = {
  currentLimit: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  pages: PropTypes.number.isRequired,
  onLimitChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onSortByChange: PropTypes.func.isRequired,
  currentSortBy: PropTypes.object,
};

export default SurveysTable;

import { Field } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import { Form } from 'semantic-ui-react';

import { AuditStatus, DateTimeFormat, UserRole } from '../../../../../../../../constants';
import { SurveyQuestionsContext } from '../../../../../../../../context';
import FieldStatus from '../../../../../../../../fieldLogic/fieldStatus';
import { RoleChecker } from '../../../../../../../layout';
import SurveyQuestionConfigHeader from '../SurveyQuestionConfigHeader/SurveyQuestionConfigHeader.component';
import styles from './SurveyDateTimeQuestion.module.scss';

const DATA_CY = 'survey-datetime-question';

const displayFormatter = (value, interval, date, time) => {
  if (interval) {
    return `${moment(value?.start || null).format(DateTimeFormat.HOUR_MINUTES_24H)} - ${moment(value?.end || null).format(DateTimeFormat.HOUR_MINUTES_24H)}`;
  }

  if (date && time) {
    return moment(value).format(DateTimeFormat.DAY_MONTH_YEAR_HOUR_MINUTES_24H);
  }

  if (date) {
    return moment(value).format(DateTimeFormat.DAY_MONTH_YEAR);
  }

  return moment(value).format(DateTimeFormat.HOUR_MINUTES_24H);
};

const SurveyDateTimeQuestion = ({
  answer,
  auditScreen,
  date,
  index,
  isInterval,
  onChange,
  status,
  surveyVisibility,
  time,
}) => {
  let state = FieldStatus.EDITABLE;

  if (surveyVisibility) {
    state = surveyVisibility;
  }

  return (
    <SurveyQuestionsContext.Consumer>
      {({ selectedQuestionIndex }) => (
        selectedQuestionIndex === index
          ? <SurveyQuestionConfigHeader index={index} />
          : (
            <Field name={`questions.${index}`}>
              {({
                field: { value: question },
              }) => (state === FieldStatus.READONLY ? (
                <>
                  <label className={styles.label} data-cy={`${DATA_CY}-${index}-title`}>
                    {question.title}
                  </label>
                  <div>
                    {displayFormatter(answer, isInterval, date, time)}
                  </div>
                </>
              ) : (
                <Form.Field required={question.required}>
                  <label data-cy={`${DATA_CY}-${index}-title`}>
                    {question.title}
                  </label>
                  {!auditScreen ? (
                    <span data-cy={`${DATA_CY}-${index}-auditor-information`}>
                      {question.auditor_information}
                    </span>
                  ) : null}
                  {isInterval
                    ? (
                      <>
                        <DateTimePicker
                          data-cy={`${DATA_CY}-${index}-value`}
                          date={false}
                          format={DateTimeFormat.HOUR_MINUTES_24H}
                          step={5}
                          time={true}
                          timeFormat={DateTimeFormat.HOUR_MINUTES_24H}
                          value={answer?.start ? new Date(answer.start) : null}
                          onChange={value => {
                            if (onChange) {
                              onChange({
                                start: value.toISOString(),
                                end: answer?.end || null,
                              });
                            }
                          }}
                        />
                        <DateTimePicker
                          data-cy={`${DATA_CY}-${index}-value`}
                          date={false}
                          format={DateTimeFormat.HOUR_MINUTES_24H}
                          step={5}
                          time={true}
                          timeFormat={DateTimeFormat.HOUR_MINUTES_24H}
                          value={answer?.end ? new Date(answer.end) : null}
                          onChange={value => {
                            if (onChange) {
                              onChange({
                                start: answer?.start || null,
                                end: value.toISOString(),
                              });
                            }
                          }}
                        />
                      </>
                    )
                    : (
                      <DateTimePicker
                        data-cy={`${DATA_CY}-${index}-value`}
                        date={date}
                        format={date && time
                          ? DateTimeFormat.DAY_MONTH_YEAR_HOUR_MINUTES_24H
                          : date
                            ? DateTimeFormat.DAY_MONTH_YEAR
                            : DateTimeFormat.HOUR_MINUTES_24H}
                        step={5}
                        time={time}
                        value={answer ? new Date(answer) : null}
                        onChange={value => (onChange ? onChange(value.toISOString()) : null)}
                      />
                    )
                  }
                  <span data-cy={`${DATA_CY}-${index}-help`}>
                    {question.help}
                  </span>
                  {status === AuditStatus.SUBMITTED || status === AuditStatus.APPROVING_QUERY ? (
                    <RoleChecker
                      allowedRoles={[UserRole.DATA,
                        UserRole.CLIENT_SERVICES,
                        UserRole.AREA_MANAGER]}
                    >
                      <p data-cy={`${DATA_CY}-${index}-approving-information`}>
                        {question.approving_information}
                      </p>
                    </RoleChecker>
                  ) : null}
                </Form.Field>
              ))}
            </Field>
          )
      )}
    </SurveyQuestionsContext.Consumer>
  );
};

SurveyDateTimeQuestion.defaultProps = {
  answer: null,
  auditScreen: null,
  date: true,
  isInterval: false,
  onChange: null,
  status: null,
  surveyVisibility: null,
  time: true,
};

SurveyDateTimeQuestion.propTypes = {
  index: PropTypes.number.isRequired,
  answer: PropTypes.any,
  auditScreen: PropTypes.bool,
  date: PropTypes.bool,
  isInterval: PropTypes.bool,
  status: PropTypes.string,
  surveyVisibility: PropTypes.string,
  time: PropTypes.bool,
  onChange: PropTypes.func,
};

export default SurveyDateTimeQuestion;

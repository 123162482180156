import { Formik } from 'formik';
import _debounce from 'lodash/debounce';
import _pickBy from 'lodash/pickBy';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'semantic-ui-react';

import { DEBOUNCE_MS } from '../../../../constants';
import { taxonomyTermSelector } from '../../../../state/constants/selectors';
import { FormFieldSelectWrapper, FormFieldWrapper, TaxonomyTermOptions } from '../../../common';
import {
  clientFilterDefaultValues,
  clientFilterValidationSchema,
} from '../helpers';

const DATA_CY = 'clients-table-filter';

const clientTypeTaxonomySelector = state => taxonomyTermSelector(state, 'client_type');

const ClientsTableFilter = ({ values: initialValues, onChange }) => {
  const clientTypeTaxonomy = useSelector(clientTypeTaxonomySelector);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={clientFilterValidationSchema}
      onSubmit={values => onChange(_pickBy(values))}
    >
      {({ handleSubmit, setFieldValue }) => {
        const debounceSubmit = _debounce(handleSubmit, DEBOUNCE_MS);
        const handleFieldChange = () => {
          debounceSubmit();
        };

        const handleSelectableChange = (name, value) => {
          setFieldValue(name, value || null);
          handleSubmit();
        };

        return (
          <Form inverted data-cy={DATA_CY}>
            <Form.Group widths="equal">
              <FormFieldWrapper
                label="Name"
                name="name"
                onChange={handleFieldChange}
              />

              <FormFieldSelectWrapper
                clearable
                data-cy={`${DATA_CY}-client-type-input`}
                label="Operator Type"
                name="client_type"
                options={TaxonomyTermOptions(clientTypeTaxonomy)}
                placeholder="Select a value"
                onChange={value => handleSelectableChange('client_type', value)}
              />

              <FormFieldWrapper
                label="SAGE Reference"
                name="sage_reference"
                onChange={handleFieldChange}
              />

              <FormFieldWrapper
                label="Manager"
                name="manager_name"
                onChange={handleFieldChange}
              />
            </Form.Group>
          </Form>
        );
      }}
    </Formik>
  );
};

ClientsTableFilter.defaultProps = {
  values: clientFilterDefaultValues,
};

ClientsTableFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    client_type: PropTypes.string,
    manager_name: PropTypes.string,
    name: PropTypes.string,
    sage_reference: PropTypes.string,
  }),
};

export default ClientsTableFilter;

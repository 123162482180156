import _uniqBy from 'lodash/uniqBy';

import { TravelModeTypes } from '../../../../constants';

const uniqueId = data => _uniqBy(data, 'site_id');

export const waypoints = data => uniqueId(data).map(
  item => ({
    location: new window.google.maps.LatLng(item.site_coordinates),
    stopover: false,
  }),
);

const sitesOptions = data => uniqueId(data).map(item => (
  { text: item.site_name,
    value: JSON.stringify(item.site_coordinates),
  }));

export const pointOptions = (coords, data) => [
  {
    disabled: !coords?.current_coordinates,
    key: 'current',
    text: 'Current Point',
    value: JSON.stringify(coords?.current_coordinates),
  },
  {
    disabled: !coords?.home_coordinates,
    key: 'home',
    text: 'Home Address',
    value: JSON.stringify(coords?.home_coordinates),
  },
  {
    disabled: !coords?.term_coordinates,
    key: 'term',
    text: 'Term Address',
    value: JSON.stringify(coords?.term_coordinates),
  },
  ...sitesOptions(data),
];

export const travelModeOptions = [
  { key: 0,
    text: 'Walking',
    value: TravelModeTypes.WALKING,
  },
  { key: 1,
    text: 'Driving',
    value: TravelModeTypes.DRIVING,
  },
  { key: 2,
    text: 'Cycling',
    value: TravelModeTypes.BICYCLING,
  },
];

import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

import { SLHeader } from '../../../../common';
import styles from './ClientsAutomatedExportsTableHeader.module.scss';

const DATA_CY = 'clients-automated-exports-table-header';

const ClientsAutomatedExportsTableHeader = () => (
  <SLHeader
    dataCy={DATA_CY}
    title="Automated Exports"
  >
    <Link data-cy={`${DATA_CY}-back`} to="/clients">
      <span className={styles.back}>
        Back to clients
      </span>
    </Link>
    <Link data-cy={`${DATA_CY}-new`} to="/clients/automated/new">
      <Button primary>
        Add New
      </Button>
    </Link>
  </SLHeader>
);

export default ClientsAutomatedExportsTableHeader;

import { createAction } from 'redux-actions';

import * as actionTypes from './actionTypes';

export const removeAudit = createAction(actionTypes.REMOVE_AUDIT, payload => payload);
export const clearAllAudits = createAction(actionTypes.CLEAR_ALL_AUDITS);

export const storeAuditDetails = createAction(actionTypes.STORE_AUDIT_DETAILS, payload => payload);

export const initAudit = createAction(actionTypes.INIT_AUDIT, payload => payload);
export const updateAuditStep = createAction(actionTypes.UPDATE_AUDIT_STEP, payload => payload);
export const updateAuditLocationAndDate = createAction(actionTypes.UPDATE_AUDIT_LOCATION_AND_DATE, payload => payload);
export const updateAuditAnswers = createAction(actionTypes.UPDATE_AUDIT_ANSWERS, payload => payload);
export const updateAuditCurrentQuestionId = createAction(
  actionTypes.UPDATE_AUDIT_CURRENT_QUESTION_ID,
  payload => payload,
);
export const updateAuditExtraDetails = createAction(actionTypes.UPDATE_AUDIT_EXTRA_DETAILS, payload => payload);

import PropTypes from 'prop-types';
import React from 'react';

import {
  SLTable,
  SLTableCellFormatter,
  // SLTableCellToggleFormatter,
} from '../../../common';

const DATA_CY = 'logics-table';

const columns = [
  {
    accessor: 'name',
    label: 'Name',
    Cell: SLTableCellFormatter({
      linkFormat: '/logics/#id',
    }),
  },
  // TODO: when PATCH /logics/{id} ready
  // {
  //   accessor: 'status',
  //   label: '',
  //   Cell: SLTableCellToggleFormatter({
  //     trueValue: 'active',
  //     // onToggle: (cell, checked) => console.log(cell),
  //   }),
  //   sortable: false,
  // },
];

const LogicsTable = ({
  currentLimit,
  currentPage,
  currentSortBy,
  data,
  onLimitChange,
  onPageChange,
  onSortByChange,
  pages,
}) => (
  <SLTable
    columns={columns}
    currentLimit={currentLimit}
    currentPage={currentPage}
    currentSortBy={currentSortBy}
    data={data}
    dataCy={DATA_CY}
    pages={pages}
    onLimitChange={onLimitChange}
    onPageChange={onPageChange}
    onSortByChange={onSortByChange}
  />
);

LogicsTable.defaultProps = {
  currentSortBy: {},
};

LogicsTable.propTypes = {
  currentLimit: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  pages: PropTypes.number.isRequired,
  onLimitChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onSortByChange: PropTypes.func.isRequired,
  currentSortBy: PropTypes.object,
};

export default LogicsTable;

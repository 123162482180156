import moment from 'moment';

import { CognitoUserChallengeNames, Country } from '../constants';

const BANK_COUNTRY_VALIDATION_MESSAGE = 'First of all you must select your bank country, and after that, the rest of bank information, including your NI/PPS number.';
const NATIONALITY_VALIDATION_MESSAGE = 'First of all you must select your nationality.';
const VISA_VALIDATION_MESSAGE = 'You must fill the visa and visa expiration date fields before you can continue.';
const BANK_FIELDS_VALIDATION_MESSAGE = 'You must fill the rest of required fields before continue.';
const PROFILE_PICTURE_VALIDATION_MESSAGE = 'To proceed, we require you to update your recent profile photo.';
const MFA_SETUP_MESSAGE = 'To proceed, please setup MFA.';

export const auditorBankInfoValidation = ({ values }) => {
  if (!values.bank_country) {
    return BANK_COUNTRY_VALIDATION_MESSAGE;
  }

  if (
    (!values.student_loans || !values.circumstances || !values.card_name)
    || (values.bank_country === Country.GBP && (!values.account_number || !values.sort_code))
    || (values.bank_country === Country.IRL && (!values.swift_code || !values.iban_number))
  ) {
    return BANK_FIELDS_VALIDATION_MESSAGE;
  }

  return null;
};

export const auditorNationalityValidation = ({ values }) => {
  if (!values.nationality) {
    return NATIONALITY_VALIDATION_MESSAGE;
  }

  if (!['GB', 'IE'].includes(values.nationality) && (!values.visa || !values.visa_expiration)) {
    return VISA_VALIDATION_MESSAGE;
  }

  return null;
};

export const profilePictureValidation = ({ values }) => {
  const auditorAge = moment().diff(values.date_of_birth, 'years');

  const now = moment();
  const profilePictureTimeDifference = values.image_modified_at ? now.diff(values.image_modified_at, 'days') : 31;

  if ([18, 19].includes(auditorAge) && profilePictureTimeDifference > 30) {
    return PROFILE_PICTURE_VALIDATION_MESSAGE;
  }

  return null;
};

export const mfaSetupValidation = ({ values }) => {
  if (values.preferred_mfa !== CognitoUserChallengeNames.SOFTWARE_TOKEN_MFA) {
    return MFA_SETUP_MESSAGE;
  }

  return null;
};

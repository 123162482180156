import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'semantic-ui-react';

import isMobile from '../../../utils/isMobile';
import styles from './View.module.scss';

const View = ({
  condensed,
  dataCy,
  isFilterVisible,
  secondary,
  body: Body,
  bodyProps,
  filter: Filter,
  filterProps,
  bottombar: Bottombar,
  bottombarProps,
  footer: Footer,
  footerProps,
  header: Header,
  headerProps,
  sidebar: Sidebar,
  sidebarProps,
}) => (
  <div className={styles.root} data-cy={dataCy}>
    {Filter ? (
      <div className={isFilterVisible ? null : styles.filterViewFalse}>
        <Filter {...filterProps} />
      </div>
    ) : null}
    <div className={styles.mainContent}>
      <div className={styles.cardContainer}>
        <Card fluid className={styles.card}>
          {Header ? (
            <Card.Content
              extra
              className={classnames({ secondary: !secondary })}
            >
              <Card.Header>
                <Header {...headerProps} />
              </Card.Header>
            </Card.Content>
          ) : null}
          <Card.Content className={classnames({ [styles.mobileCardContent]: isMobile }, { secondary }, { condensed })}>
            <Body {...bodyProps} />
          </Card.Content>
          {Footer ? (
            <Card.Content extra>
              <Card.Header>
                <Footer {...footerProps} />
              </Card.Header>
            </Card.Content>
          ) : null}
        </Card>
      </div>
      {Sidebar ? (
        sidebarProps.sticky ? (
          <div className={styles.sidebarContainer}>
            <div className={styles.sidebarFixContainer}>
              <Sidebar {...sidebarProps} />
            </div>
          </div>) : (
          <div className={styles.sidebarContainer}>
            <Sidebar {...sidebarProps} />
          </div>
        )) : null}
    </div>
    {Bottombar ? (
      <div className={styles.footContainer}>
        <Bottombar {...bottombarProps} />
      </div>
    ) : null}
  </div>
);

View.defaultProps = {
  bodyProps: {},
  bottombar: null,
  bottombarProps: {},
  condensed: false,
  dataCy: 'view',
  filter: null,
  filterProps: {},
  footer: null,
  footerProps: {},
  header: null,
  headerProps: {},
  isFilterVisible: false,
  secondary: false,
  sidebar: null,
  sidebarProps: {},
};

View.propTypes = {
  body: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  bodyProps: PropTypes.object,
  bottombar: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  bottombarProps: PropTypes.object,
  condensed: PropTypes.bool,
  dataCy: PropTypes.string,
  filter: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  filterProps: PropTypes.object,
  footer: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  footerProps: PropTypes.object,
  header: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  headerProps: PropTypes.object,
  isFilterVisible: PropTypes.bool,
  secondary: PropTypes.bool,
  sidebar: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  sidebarProps: PropTypes.object,
};

export default View;

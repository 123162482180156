import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Divider } from 'semantic-ui-react';

import getFieldAuditorStatus from '../../../../../fieldLogic/auditor';
import { taxonomyTermSelector } from '../../../../../state/constants/selectors';
import {
  FormFieldSelectWrapper,
  FormFieldWrapper,
  TaxonomyTermOptions,
} from '../../../../common';

const hearOptionsSelector = state => taxonomyTermSelector(state, 'where_did_you_here_about_us');

const EditAnswersTab = ({ role, mode }) => {
  const hearOptions = useSelector(hearOptionsSelector);

  return (
    <>
      <FormFieldSelectWrapper
        inline
        label="Where did you hear about us?"
        name="hear_about_us"
        options={TaxonomyTermOptions(hearOptions)}
        placeholder="Select an option"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />

      <FormFieldWrapper
        inline
        label="Please give details"
        name="hear_about_us_details"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />

      <FormFieldWrapper
        inline
        label="If recommended, who recommended you?"
        name="recommended_by"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />

      <Divider />

      <FormFieldWrapper
        inline
        label="Why do you want to work with Serve Legal?"
        name="why_servelegal"
        type="textarea"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />

      <FormFieldWrapper
        inline
        label="Tell us a bit about yourself"
        name="about_yourself"
        type="textarea"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />

      <FormFieldWrapper
        inline
        label="Can you travel outside your local area?"
        name="travel_outside"
        type="textarea"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />
    </>
  );
};

EditAnswersTab.propTypes = {
  mode: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
};

export default EditAnswersTab;

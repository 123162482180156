import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { entitiesAPI } from '../../../../api';
import { EntityRevisionsContext } from '../../../../context';
import { LoadingPlaceholder } from '../../../common';
import { View } from '../../../layout';
import EntityRevisions from './EntityRevisions/EntityRevisions.component';
import EntityRevisionsHeader from './EntityRevisionsHeader/EntityRevisionsHeader.component';

const EntityRevisionsView = () => {
  const { entity, entityId } = useParams();
  const [selectedRevisions, setSelectedRevisions] = useState([]);

  const { data, refetch } = useQuery(
    [{ entity, entityId }],
    () => entitiesAPI.fetchAllRevisions({ entity, entityId }),
  );

  return (
    <EntityRevisionsContext.Provider
      value={{
        selectedRevisions,
        setSelectedRevisions,
      }}
    >
      <View
        condensed
        secondary
        body={data ? EntityRevisions : LoadingPlaceholder}
        bodyProps={{ data, refetch }}
        dataCy={`${entity}-revisions-view`}
        header={EntityRevisionsHeader}
      />
    </EntityRevisionsContext.Provider>
  );
};

export default EntityRevisionsView;

import { useFormikContext } from 'formik';
import React from 'react';
import { useAlert } from 'react-alert';
import { useMutation } from 'react-query';
import { Button, Icon, Table } from 'semantic-ui-react';

import { auditingAPI } from '../../../../../api';
import getFieldStatus from '../../../../../fieldLogic/audit';
import { useUserRole } from '../../../../../hooks';
import { FormFieldWrapper } from '../../../../common';

const headers = [{
  key: 'matching_rule_name',
  title: 'Rule name',
},
{
  key: 'matching_result',
  title: 'Result',
},
{
  key: 'matching_actions',
  title: 'Actions',
}];

const getIconName = matching => (matching.result ? 'check' : 'ban');
const getIconColor = matching => (matching.result ? 'green' : 'red');

const AuditDetailsMatchings = () => {
  const role = useUserRole();
  const { values } = useFormikContext();

  const {
    matchings = [],
    id: auditId,
    revision_questions,
  } = values;

  const alert = useAlert();

  const { mutate: retryCodeValidation } = useMutation(data => (auditingAPI.formRecognizer(data)), {
    onSuccess: () => {
      alert.success('Validation process is in progress. Refresh the page in a few seconds');
    },
    onError: error => {
      alert.error(`Error retrying matching: ${error.message}`);
    },
  });

  return <>
    {
      matchings?.length === 0 ? <p>
No matchings are present
      </p>
        : <FormFieldWrapper
          name="matchings"
          onStateHandler={getFieldStatus(role, values.status)}
          >
          <Table>
            <Table.Header>
              <Table.Row>
                {headers.map(header => (<Table.HeaderCell key={header.key} singleLine>
                  {header.title}
                </Table.HeaderCell>))}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {matchings.map(matching => (
                <Table.Row key={`matching-rule-${matching.id}`}>
                  <Table.Cell>
                    {matching.rule_name}
                  </Table.Cell>
                  <Table.Cell collapsing textAlign="center">
                    <Icon
                      color={getIconColor(matching)}
                      name={getIconName(matching)}
                    />
                  </Table.Cell>
                  <Table.Cell collapsing textAlign="center">
                    <Button primary animated="vertical" onClick={() => retryCodeValidation({
                      auditId,
                      matchings: [matching],
                      questions: revision_questions,
                      action: 'matching',
                    })}
                    >
                      <Button.Content visible>
                    Retry
                      </Button.Content>
                      <Button.Content hidden>
                        <Icon name="redo alternate" />
                      </Button.Content>
                    </Button>
                  </Table.Cell>
                </Table.Row>))}
            </Table.Body>
          </Table>
        </FormFieldWrapper>
    }

  </>;
};

AuditDetailsMatchings.defaultProps = {
  matchings: [],
};

AuditDetailsMatchings.propTypes = {
};

export default AuditDetailsMatchings;

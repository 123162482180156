import { Auth, Hub } from 'aws-amplify';
import { useConfigCatClient } from 'configcat-react';
import React, { useCallback, useEffect } from 'react';
import { useAlert } from 'react-alert';
import { useDispatch, useSelector } from 'react-redux';

import { setSentryUser } from '../../../config';
import { NotificationAlertContext } from '../../../context';
import AppRouter from '../../../routes/AppRouter';
import { fetchEnums, fetchTaxonomy } from '../../../state/constants/actionCreators';
import { enumsSelector, taxonomySelector } from '../../../state/constants/selectors';
import { initialiseStoreDeviceToken } from '../../../state/device/actionCreators';
import { initialiseSystem } from '../../../state/ui/actionCreators';
import { alertSelector, isSystemInitialisedSelector, notificationSelector } from '../../../state/ui/selectors';
import SplashScreen from '../../pages/SplashScreen/SplashScreen.component';

const App = () => {
  const alertHandler = useAlert();
  const notificationHandler = useAlert(NotificationAlertContext);
  const dispatch = useDispatch();

  const alert = useSelector(alertSelector);
  const notification = useSelector(notificationSelector);
  const enums = useSelector(enumsSelector);
  const taxonomy = useSelector(taxonomySelector);
  const isSystemInitialised = useSelector(isSystemInitialisedSelector);
  const configCatClient = useConfigCatClient();

  const setUpUserListener = () => {
    const listener = () => {
      Auth.currentAuthenticatedUser()
        .then(userData => {
          const userId = userData?.attributes?.sub;
          const userMail = userData?.attributes?.email;
          const userGroups = userData?.signInUserSession?.idToken?.payload['cognito:groups'] || [];
          if (userId && userMail && userGroups.length > 0) {
            configCatClient.setDefaultUser({
              identifier: userMail,
              email: userMail,
              custom: {
                groups: userGroups[0],
              },
            });
            setSentryUser(userMail);
          } else {
            configCatClient.clearDefaultUser();
            setSentryUser(null);
          }
        }).catch(() => configCatClient.clearDefaultUser());
    };
    Hub.listen('auth', listener);
  };

  const initStoreDeviceTokenCallback = useCallback(
    () => dispatch(initialiseStoreDeviceToken()),
    [dispatch],
  );

  const fetchEnumsCallback = useCallback(
    () => dispatch(fetchEnums()),
    [dispatch],
  );

  const fetchTaxonomyCallback = useCallback(
    () => dispatch(fetchTaxonomy()),
    [dispatch],
  );

  const initialiseSystemCallback = useCallback(
    () => dispatch(initialiseSystem()),
    [dispatch],
  );

  useEffect(() => {
    if (!isSystemInitialised) {
      initialiseSystemCallback();
    }
  }, [isSystemInitialised, initialiseSystemCallback]);

  useEffect(() => {
    if (alert) {
      alertHandler.show(alert.message, { type: alert.type });
    }
  }, [alert, alertHandler]);

  useEffect(() => {
    if (notification) {
      notificationHandler.show(notification.message, { type: notification.type });
    }
  }, [notification, notificationHandler]);

  useEffect(() => {
    if (Object.keys(enums).length === 0) {
      fetchEnumsCallback();
    }
  }, [enums, fetchEnumsCallback]);

  useEffect(() => {
    if (taxonomy.length === 0) {
      fetchTaxonomyCallback();
    }
  }, [taxonomy, fetchTaxonomyCallback]);

  useEffect(() => {
    if (taxonomy.length === 0) {
      fetchTaxonomyCallback();
    }
  }, [taxonomy, fetchTaxonomyCallback]);

  useEffect(() => {
    initStoreDeviceTokenCallback();
  }, [initStoreDeviceTokenCallback]);

  setUpUserListener();

  return (enums.length === 0 || taxonomy.length === 0 || !isSystemInitialised
    ? <SplashScreen />
    : <AppRouter />
  );
};

export default App;

import * as Yup from 'yup';

import { QuestionType } from '../../../../constants';
import { convertImages } from '../../../../utils/imageConversion';

const DEFAULT_MULTIPLIER_VALUE = 0;

const handleImageAnswerChange = async (question, values, newValue, imageIndex, imageValue, answerIndex) => {
  const answer = values.answers.find(a => a.id === question.id);
  let imageAnswerId;

  if (answer.value === null) {
    imageAnswerId = `${question.id}:toAssign`;
  } else {
    const path = answer?.value?.[imageIndex]?.split('/');
    if (path && path.length > 3) {
      imageAnswerId = `${question.id}:${path.pop()}`;
    } else {
      imageAnswerId = `${question.id}:toAssign`;
    }
  }

  let field;
  let value;
  if (newValue) {
    const convertedImage = await convertImages(imageValue);

    field = 'newAnswersFiles';
    value = {
      ...values.newAnswersFiles,
      [imageAnswerId]: convertedImage,
    };
  } else {
    field = `answers.${answerIndex}.value`;
    value = newValue;
  }

  return Promise.resolve({ field, value });
};

const handleAnswerTypeChange = {
  [QuestionType.IMAGE]: handleImageAnswerChange,
};

const expensesSchema = propName => ({
  [propName]: Yup.number().min(0, 'Amount cannot be negative'),
  [`${propName}_description`]: Yup.string().when(propName, {
    is: value => !!value,
    then: Yup.string().trim().ensure().min(10, 'Description must be at least 10 characters long'),
    otherwise: Yup.string().nullable(),
  }),
  [`${propName}_type`]: Yup.string().when(propName, {
    is: value => !!value,
    then: Yup.string().nullable().required('Type is required'),
    otherwise: Yup.string().nullable(),
  }),
});

const AuditDetailsSchema = Yup.object({
  ...expensesSchema('reclaimable_expenses'),
  ...expensesSchema('reclaimable_expenses_2'),
  ...expensesSchema('reclaimable_expenses_3'),
  ...expensesSchema('reclaimable_expenses_4'),
  ...expensesSchema('travel_expenses'),
  expenses_receipts_urls: Yup.array().of(Yup.string()).when(['reclaimable_expenses', 'reclaimable_expenses_2', 'reclaimable_expenses_3', 'reclaimable_expenses_4'], {
    is: (expenses1, expenses2, expenses3, expenses4) => !!expenses1 || !!expenses2 || !!expenses3 || !!expenses4,
    then: Yup.array().of(Yup.string()).min(1, 'Images are required'),
    otherwise: Yup.array().of(Yup.string()).notRequired(),
  }),
  travel_expenses_images_urls: Yup.array().of(Yup.string()).when('travel_expenses', {
    is: value => !!value,
    then: Yup.array().of(Yup.string()).min(1, 'Images are required'),
    otherwise: Yup.array().of(Yup.string()).notRequired(),
  }),
});

const getCurrentMultiplier = (payRateTaxonomy, auditorBankCountry, date) => {
  const bankCountryOptions = payRateTaxonomy?.terms
    .find(term => (auditorBankCountry === term.value));

  const previousActiveMultipliers = bankCountryOptions?.options
    .filter(option => new Date(option.text) <= new Date(date) && !option.disabled);

  const selectedMultiplier = previousActiveMultipliers?.sort((a, b) => a.text.localeCompare(b.text)).pop();

  if (selectedMultiplier) return selectedMultiplier?.value;
  return DEFAULT_MULTIPLIER_VALUE;
};

const calculateHolidayPay = (pay, payRateTaxonomy, auditorBankCountry, date) => {
  const multiplier = getCurrentMultiplier(payRateTaxonomy, auditorBankCountry, date);
  return pay * multiplier;
};

const calculateTotalPay = (pay, holidayPay) => (pay + holidayPay);

const calculatePays = ({ pay, payRateTaxonomy, auditorBankCountry, date }) => {
  if (!date) return { holidayPay: 0, totalPay: pay };
  const holidayPay = calculateHolidayPay(parseFloat(pay), payRateTaxonomy, auditorBankCountry, date);
  const totalPay = calculateTotalPay(parseFloat(pay), holidayPay);
  return { holidayPay: holidayPay.toFixed(2), totalPay: totalPay.toFixed(2) };
};

export {
  AuditDetailsSchema,
  calculatePays,
  handleAnswerTypeChange,
};

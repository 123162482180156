import classnames from 'classnames';
import { Field, Formik } from 'formik';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { Button, Checkbox, Form, Icon } from 'semantic-ui-react';

import { FormikErrorMessage, SLTable } from '../../../../common';
import { taxonomyType } from '../../../../types';
import { taxonomyDetailSchema } from '../../helpers';
import styles from './TaxonomyDetailsSimple.module.scss';

const DATA_CY = 'taxonomy-details';

const formattedCell = ({ cell, row, userData }) => {
  const inputId = row.index;
  const isSubRow = row.depth > 0;
  const parentRow = isSubRow ? parseInt(row.id, 10) : null;
  const prefix = isSubRow ? `terms.${parentRow}.options.${inputId}` : `terms.${inputId}`;

  const value = {
    text: () => (
      <Field name={`${prefix}.text`}>
        {({ meta }) => (
          <Form.Field>
            <Form.Input
              className={classnames(styles.taxInput, { [styles.nestedRows]: row.depth !== 0 })}
              data-cy={`${DATA_CY}-text-input`}
              id={`${prefix}.text`}
              value={meta.value}
              onChange={userData.handleChange}
            />
            {meta.error ? (
              <FormikErrorMessage>
                {meta.error}
              </FormikErrorMessage>
            ) : null}
          </Form.Field>
        )}
      </Field>
    ),
    value: () => {
      let cellToReturn = (
        <span>
          {cell.value}
        </span>
      );
      if (
        userData.modifiableRows?.parentRow === parentRow
        && userData.modifiableRows?.inputId === inputId
      ) {
        cellToReturn = (
          <Field
            name={`${prefix}.value`}
            validate={() => {
              let termsToCheck = [];
              if (parentRow === null) {
                termsToCheck = userData.values.terms.map(term => term.value);
              } else {
                termsToCheck = userData.values.terms[parentRow].options.map(option => option.value);
              }
              if ([...new Set(termsToCheck)].length === termsToCheck.length) {
                return null;
              }
              return 'Not unique';
            }}
          >
            {({ meta }) => (
              <Form.Field>
                <Form.Input
                  data-cy={`${DATA_CY}-value-input`}
                  id={`${prefix}.value`}
                  value={meta.value}
                  onChange={userData.handleChange}
                />
                {meta.error ? (
                  <FormikErrorMessage>
                    {meta.error}
                  </FormikErrorMessage>
                ) : null}
              </Form.Field>
            )}
          </Field>
        );
      }
      return cellToReturn;
    },
    enabled: () => (
      <Field name={`${prefix}.disabled`} >
        {({ field, meta }) => (
          <Checkbox
            toggle
            checked={!meta.value}
            data-cy={`${DATA_CY}-status-checkbox`}
            id={field.name}
            onChange={(_event, { checked }) => {
              userData.setFieldValue(field.name, !checked);
            }}
          />
        )}
      </Field>
    ),
    new: () => (userData.allowNested
      ? (userData.modifiableRows === null ? (
        <span
          className={styles.add}
          data-cy={`${DATA_CY}-add-new-revision-button`}
          onClick={() => {
            const newTaxonomy = { ...userData.taxonomy };
            const addRow = () => {
              if (parentRow !== null) {
                userData.setModifiableRows({
                  parentRow,
                  inputId: newTaxonomy.terms[parentRow].options.length,
                });
                newTaxonomy.terms[parentRow].options.push({
                  disabled: false,
                  text: '',
                  value: '',
                });
              } else {
                if (!newTaxonomy.terms[inputId].options) {
                  newTaxonomy.terms[inputId].options = [];
                }
                userData.setModifiableRows({
                  parentRow: inputId,
                  inputId: newTaxonomy.terms[inputId]?.options?.length
                    ? newTaxonomy.terms[inputId].options.length : 0 });
                newTaxonomy.terms[inputId].options.push({
                  disabled: false,
                  text: '',
                  value: '',
                });
              }
            };
            addRow();
            userData.setTaxonomy(newTaxonomy);
          }}
        />) : (userData.modifiableRows?.parentRow === parentRow
          && userData.modifiableRows?.inputId === inputId
        ? (
          <Icon
            className={styles.save}
            color="grey"
            name="save"
            onClick={() => {
              userData.validateForm()
                .then(result => {
                  if (_isEmpty(result)) {
                    userData.setModifiableRows(null);
                    userData.setTaxonomy(userData.values);
                  }
                });
            }}
          />) : <span />
      ))
      : <span />
    ),
  };
  return value[cell.column.id]();
};

const columns = [
  { accessor: 'text', label: 'Name', Cell: formattedCell },
  { accessor: 'value', label: 'Value', Cell: formattedCell },
  { accessor: 'enabled', label: '', Cell: formattedCell },
  { accessor: 'new', label: '', Cell: formattedCell },
];

const TaxonomyDetailsSimple = ({ data, onSubmit, submitFormRef }) => {
  const [taxonomy, setTaxonomy] = useState(data);
  const [modifiableRows, setModifiableRows] = useState(null);
  const alert = useAlert();

  const addParentRow = () => {
    if (modifiableRows === null) {
      const newTaxonomy = { ...taxonomy };

      setModifiableRows({
        parentRow: null,
        inputId: newTaxonomy.terms.length,
      });

      newTaxonomy.terms.push({
        disabled: false,
        text: '',
        value: '',
      });

      setTaxonomy(newTaxonomy);
    }
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={taxonomy}
        validationSchema={taxonomyDetailSchema}
        onSubmit={onSubmit}
      >
        {({ values, handleChange, handleSubmit, setFieldValue, errors, isValid, validateForm }) => {
          const submit = () => {
            if (!isValid) {
              alert.error(
                <pre>
                  {JSON.stringify(errors, null, 2)}
                </pre>,
              );
            } else {
              handleSubmit();
            }
          };

          return (
            <Form>
              <button ref={submitFormRef} hidden type="submit" onClick={submit} />
              <SLTable
                columns={columns}
                data={taxonomy.terms.map(term => ({
                  ...term,
                  expanded: term.options,
                  subRows: term.options,
                }))}
                dataCy={DATA_CY}
                userData={{
                  handleChange,
                  values,
                  setFieldValue,
                  allowNested: data.allow_nested,
                  taxonomy,
                  setTaxonomy,
                  setModifiableRows,
                  modifiableRows,
                  validateForm,
                }}
              />
            </Form>
          );
        }}
      </Formik>
      <Button
        className={styles.newTermButton}
        disabled={modifiableRows !== null}
        onClick={addParentRow}
      >
        {data.allowNested ? 'Add new parent term' : 'Add new term'}
      </Button>
    </>
  );
};

TaxonomyDetailsSimple.propTypes = {
  data: taxonomyType.isRequired,
  submitFormRef: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default TaxonomyDetailsSimple;

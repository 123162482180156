import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Popup } from 'semantic-ui-react';

import { auditorsAPI } from '../../../../api';
import { DateTimeFormat } from '../../../../constants';
import { LoadingPlaceholder } from '../../../common';
import { uuidType } from '../../../types';
import styles from './AuditorTooltip.module.scss';

const AuditorTooltip = ({
  id,
  position,
  trigger,
}) => {
  const [enabled, setEnabled] = useState(false);

  const { data } = useQuery(
    [id],
    () => auditorsAPI.tooltip({ entityId: id }),
    { enabled: enabled && Boolean(id) },
  );

  return (
    <Popup
      content={data ? (
        <>
          <div>
            <span className={styles.tooltip}>
              {'Age: '}
            </span>
            {data?.age}
          </div>
          <div>
            <span className={styles.tooltip}>
              {'Pass Rate: '}
            </span>
            {data?.pass_rate ? `${parseFloat(data.pass_rate).toFixed(2)} %` : '-'}
          </div>
          <div>
            <span className={styles.tooltip}>
              {'Date of last visit: '}
            </span>
            {data?.date_of_visit ? moment(data.date_of_visit).format(DateTimeFormat.DAY_MONTH_YEAR) : '-'}
          </div>
          <div>
            <span className={styles.tooltip}>
              {'Number of SL visits: '}
            </span>
            {data?.sl_visit_count}
          </div>
          <div>
            <span className={styles.tooltip}>
              {'Notes: '}
            </span>
            {data?.notes}
          </div>
        </>
      ) : (
        <LoadingPlaceholder count={5}/>
      )}
      popperDependencies={[!!data]}
      position={position}
      trigger={trigger}
      onClose={() => setEnabled(false)}
      onOpen={() => setEnabled(true)}
    />
  );
};

AuditorTooltip.defaultProps = {
  id: null,
  position: 'right center',
};

AuditorTooltip.propTypes = {
  trigger: PropTypes.node.isRequired,
  id: uuidType,
  position: PropTypes.string,
};

export default AuditorTooltip;

import { put } from 'redux-saga/effects';

import { errorAPI } from '../../api';
import configureSystem from '../../config';
import * as actions from './actionCreators';
import * as actionTypes from './actionTypes';

function* initialiseSystemSaga() {
  try {
    yield configureSystem();
    yield put(actions.setSystemInitialised());
  } catch (error) {
    errorAPI.sendError(error.message, error.stack);
  }
}

export default {
  [actionTypes.INITIALISE_SYSTEM]: initialiseSystemSaga,
};

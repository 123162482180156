import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

import { entitiesAPI, errorAPI } from '../../../../../api';
import { Entity } from '../../../../../constants';
import history from '../../../../../history';
import styles from './SurveyActions.module.scss';
import SurveyCloneModal from './SurveyCloneModal.component';

const SurveyActions = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { entityId, revisionId } = useParams();

  const onOpenModal = () => {
    setModalOpen(true);
  };
  const onCloseModal = () => {
    setModalOpen(false);
  };

  const alert = useAlert();

  const { mutate: cloneSurvey } = useMutation(entitiesAPI.clone, {
    onSuccess: ({ id, revision_id }) => {
      alert.success(`Survey ${entityId} succesfully cloned`);
      history.push(`/${Entity.SURVEYS}/${id}/revisions/${revision_id}/questions`);
    },
    onError: error => {
      errorAPI.sendError(error.message, error.stack);
      alert.error(`Error cloning survey: ${JSON.stringify(error)}`);
    },
  });

  const handleClone = payload => {
    cloneSurvey({
      entity: Entity.SURVEYS,
      entityId,
      payload: {
        title: payload.title,
        revision_id: revisionId,
      },
    });
  };

  return (
    <>
      <SurveyCloneModal
        modalOpen={modalOpen}
        onCloseModal={onCloseModal}
        onSubmit={handleClone}
      />
      <div className={styles.buttonContainer}>
        <Button
          secondary
          onClick={() => onOpenModal()}
        >
          Clone
        </Button>
      </div>
    </>
  );
};

export default SurveyActions;

import { factories, models, service } from 'powerbi-client';
import React, { createRef, useCallback, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useQuery } from 'react-query';
import { Card, Icon } from 'semantic-ui-react';

import { errorAPI, powerBIAPI } from '../../../api';
import { LoadingPlaceholder, WaitPlaceholder } from '../../common';
import SLHeader from '../../common/SLHeader/SLHeader.component';
import styles from './PowerBIReports.module.scss';

const powerbi = new service.Service(factories.hpmFactory, factories.wpmpFactory, factories.routerFactory);

const PowerBIReports = () => {
  const alert = useAlert();
  const reportContainerRef = createRef();

  const [isReportLoaded, setReportLoaded] = useState(false);
  const [isExportInProgress, setExportInProgress] = useState(false);
  const [embeddedReport, setEmbeddedReport] = useState(null);

  const {
    data: embeddedReportConfig,
    error: embeddedReportConfigError,
    isLoading,
  } = useQuery(
    'powerbi',
    () => powerBIAPI.fetchReport(),
  );

  const embed = useCallback(config => {
    config.slicers[0].state.filters[0].filterType = models.FilterType.BasicFilter;
    const embedConfiguration = {
      ...config,
      tokenType: models.TokenType.Embed,
    };

    const report = powerbi.embed(reportContainerRef.current, embedConfiguration);
    setEmbeddedReport(report);

    // Clear any other loaded handler events
    report.off('loaded');
    // Triggers when a content schema is successfully loaded
    report.on('loaded', () => {
      setReportLoaded(true);
    });
  }, [reportContainerRef]);

  const openFileInTab = url => {
    window.open(url, '_blank', 'noreferrer');
  };

  useEffect(() => {
    if (embeddedReportConfigError) {
      alert.error(`Fetch embed report error: ${embeddedReportConfigError.message}`);
      errorAPI.sendError(embeddedReportConfigError.message, '');
    } else if (embeddedReport === null && embeddedReportConfig !== undefined) {
      embed(embeddedReportConfig);
    }
  }, [alert, embed, embeddedReport, embeddedReportConfig, embeddedReportConfigError]);

  const exportReport = async ({ pagesToExport, capturedBookmarkState }) => powerBIAPI
    .exportReport({ payload: { pagesToExport, capturedBookmarkState } })
    .then(async data => {
      if (data.url) openFileInTab(data.url);
      else {
        throw new Error('Your file could not be generated correctly');
      }
    });

  const handleExport = useCallback(async (onlyCurrent = true) => {
    if (reportContainerRef.current) {
      const report = powerbi.get(reportContainerRef.current);
      const capturedBookmark = await report.bookmarksManager.capture();

      const pages = await report.getPages();
      const pagesToExport = pages
        .filter(page => page.visibility === models.VisualContainerDisplayMode.Visible
          && (onlyCurrent ? page.isActive === true : true))
        .map(page => ({ name: page.name }));

      setExportInProgress(true);
      exportReport({ pagesToExport, capturedBookmarkState: capturedBookmark.state })
        .catch(error => {
          alert.error(`Error exporting to PDF: ${error.message}`);
          errorAPI.sendError(error.message, '');
        })
        .finally(() => {
          setExportInProgress(false);
        });
    }
  }, [alert, reportContainerRef]);

  return (
    <div className={styles.root}>
      {isExportInProgress ? (
        <WaitPlaceholder>
          {'Wait, please...'}
          <br />
          {'Your report is being created.'}
          <br />
          {'It may take some minutes.'}
        </WaitPlaceholder>
      ) : null}

      <Card fluid className={styles.card}>
        <Card.Content extra>
          <Card.Header>
            <SLHeader title="Reports">
              {isReportLoaded ? (
                <>
                  <span className={styles.pdf} onClick={() => handleExport(false)}>
                    <Icon className={styles.icon} name="file pdf outline" />
                    {'Export all'}
                  </span>
                  <span className={styles.pdf} onClick={() => handleExport(true)}>
                    <Icon className={styles.icon} name="file pdf outline" />
                    {'Export current page'}
                  </span>
                </>
              ) : null}
            </SLHeader>
          </Card.Header>
        </Card.Content>

        <Card.Content className="condensed">
          <>
            {isLoading ? <LoadingPlaceholder /> : null}
            <div ref={reportContainerRef} className={styles.report}>
              {embeddedReportConfigError ? 'There are no reports configured for this account' : null}
            </div>
          </>
        </Card.Content>
      </Card>
    </div>
  );
};

export default PowerBIReports;

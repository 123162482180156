import _startCase from 'lodash/startCase';
import PropTypes from 'prop-types';
import React from 'react';
import { useAlert } from 'react-alert';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

import { auditsAPI, entitiesAPI } from '../../../../../api';
import { Entity } from '../../../../../constants';
import getFieldStatus from '../../../../../fieldLogic/audit';
import FieldStatus from '../../../../../fieldLogic/fieldStatus';
import { useUserRole } from '../../../../../hooks';

const AuditActions = ({
  auditStatus,
  onSubmit,
  openResetAndReassignModal,
  refetch,
  setAuditActionType,
  setCurrentStatus,
}) => {
  const { entityId } = useParams();
  const alert = useAlert();
  const role = useUserRole();

  const { mutate: auditCloneMutation } = useMutation(entitiesAPI.clone, {
    onSuccess: () => alert.success('Audit cloned!'),
    onError: error => {
      alert.error(`Error cloning audit: ${error.message}`);
    },
  });

  const { mutate: auditResetMutation } = useMutation(auditsAPI.reset, {
    onSuccess: () => {
      alert.success('Audit reset!');
      refetch();
    },
    onError: error => {
      alert.error(`Error resetting audit: ${error.message}`);
    },
  });

  const handleClone = () => {
    auditCloneMutation({
      entity: Entity.AUDITS,
      entityId,
    });
  };

  const handleReset = () => {
    auditResetMutation({
      entityId,
    });
  };

  const handleStatusChange = status => () => {
    setCurrentStatus(status);
    setAuditActionType(status);
    return onSubmit();
  };

  const handleAction = action => {
    const fn = {
      submit: handleStatusChange('submitted'),
      approve: handleStatusChange('approved'),
      query: handleStatusChange('approving_query'),
      reset: handleReset,
      client_query: handleStatusChange('client_query'),
      delete: handleStatusChange('deleted'),
      clone: handleClone,
      reset_reassign: openResetAndReassignModal,
    };

    if (fn[action]) {
      fn[action]();
    }
  };

  return (
    <>
      {['submit', 'approve', 'query', 'reset', 'reset_reassign', 'client_query', 'delete', 'clone'].map((action, index) => (
        getFieldStatus(role, auditStatus)(`action_${action}`) !== FieldStatus.HIDDEN ? (
          <Button
            key={index}
            secondary
            onClick={() => handleAction(action)}
          >
            {_startCase(action)}
          </Button>)
          : null))
      }
    </>
  );
};

AuditActions.propTypes = {
  auditStatus: PropTypes.string.isRequired,
  openResetAndReassignModal: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  setAuditActionType: PropTypes.func.isRequired,
  setCurrentStatus: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AuditActions;

import PropTypes from 'prop-types';
import React from 'react';

import { View } from '../../../layout';
import SurveyStepFooter from './SurveyStepFooter/SurveyStepFooter.component';
import SurveyStepHeader from './SurveyStepHeader/SurveyStepHeader.component';

const SurveyStep = ({
  back,
  backContent,
  dataCy,
  editor: Editor,
  editorProps,
  headerProps,
  next,
  nextContent,
  sidebar: Sidebar,
  sidebarProps,
}) => (
  <View
    secondary
    body={Editor}
    bodyProps={editorProps}
    dataCy={dataCy}
    footer={SurveyStepFooter}
    footerProps={{ back, backContent, dataCy, next, nextContent }}
    header={SurveyStepHeader}
    headerProps={headerProps}
    sidebar={Sidebar}
    sidebarProps={sidebarProps}
  />
);

SurveyStep.defaultProps = {
  back: null,
  backContent: 'Back',
  dataCy: 'survey-step',
  editorProps: {},
  headerProps: {},
  next: null,
  nextContent: 'Next',
  sidebar: null,
  sidebarProps: {},
};

SurveyStep.propTypes = {
  editor: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]).isRequired,
  back: PropTypes.func,
  backContent: PropTypes.string,
  dataCy: PropTypes.string,
  editorProps: PropTypes.object,
  headerProps: PropTypes.object,
  next: PropTypes.func,
  nextContent: PropTypes.string,
  sidebar: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]),
  sidebarProps: PropTypes.object,
};

export default SurveyStep;

import { Formik } from 'formik';
import _debounce from 'lodash/debounce';
import _pickBy from 'lodash/pickBy';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'semantic-ui-react';

import { DEBOUNCE_MS } from '../../../../constants';
import { FormFieldWrapper } from '../../../common';
import {
  logicFilterDefaultValues,
  logicFilterValidationSchema,
} from '../helpers';

const DATA_CY = 'logics-table-filter';

const LogicsTableFilter = ({ values: initialValues, onChange }) => (
  <Formik
    enableReinitialize={true}
    initialValues={initialValues}
    validationSchema={logicFilterValidationSchema}
    onSubmit={values => onChange(_pickBy(values))}
  >
    {({ handleChange, handleSubmit }) => {
      const debounceSubmit = _debounce(handleSubmit, DEBOUNCE_MS);
      const handleFieldChange = (e, data) => {
        handleChange(e, data);
        debounceSubmit();
      };

      return (
        <Form inverted data-cy={DATA_CY}>
          <Form.Group widths="equal">
            <FormFieldWrapper
              label="Name"
              name="name"
              onChange={handleFieldChange}
            />
          </Form.Group>
        </Form>
      );
    }}
  </Formik>
);

LogicsTableFilter.defaultProps = {
  values: logicFilterDefaultValues,
};

LogicsTableFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    name: PropTypes.string,
  }),
};

export default LogicsTableFilter;

import { Field, FieldArray } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Card, Form, Grid } from 'semantic-ui-react';

import { logicRuleType } from '../../../../types';
import { newRuleValues } from '../../helpers';
import styles from './LogicDetailsRules.module.scss';

const LogicDetailsRules = ({ dataCy, options }) => (
  <FieldArray name="rules">
    {({ name, push, remove }) => (
      <Field name={name}>
        {({
          field: { value: rules },
          form: { handleChange, setFieldValue },
        }) => (
          <Card fluid>
            <Card.Content>
              <Grid columns={3} verticalAlign="middle">
                <Grid.Row>
                  <Grid.Column width={10}>
                    Rules
                  </Grid.Column>
                  <Grid.Column width={5}>
                    Result
                  </Grid.Column>
                </Grid.Row>
                {rules.map((rule, index) => (
                  <Grid.Row key={index}>
                    <Grid.Column width={10}>
                      <Field name={`rules.${index}.logic`}>
                        {({
                          field: { name: logicName, value: logicValue },
                          form: { errors },
                        }) => (
                          <Form.Input
                            data-cy={`${dataCy}-${index}-logic-input`}
                            error={errors.rules?.[index]?.logic || null}
                            id={logicName}
                            value={logicValue}
                            onChange={handleChange}
                          />
                        )}
                      </Field>
                    </Grid.Column>
                    <Grid.Column width={5}>
                      <Field name={`rules.${index}.result`}>
                        {({
                          field: { name: resultName, value: resultValue },
                          form: { errors },
                        }) => (
                          <Form.Select
                            data-cy={`${dataCy}-result-input`}
                            error={errors.rules?.[index]?.result || null}
                            id={resultName}
                            options={options}
                            value={resultValue}
                            onChange={(_e, { value }) => setFieldValue(resultName, value)}
                          />
                        )}
                      </Field>
                    </Grid.Column>
                    <Grid.Column width={1}>
                      <span
                        className={styles.remove}
                        data-cy={`${dataCy}-${index}-remove`}
                        onClick={() => remove(index)}
                      />
                    </Grid.Column>
                  </Grid.Row>
                ))}
                <Grid.Row>
                  <Grid.Column>
                    <Button
                      primary
                      data-cy={`${dataCy}-add`}
                      onClick={() => push(newRuleValues)}
                    >
                      Add New Rule
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Card.Content>
          </Card>
        )}
      </Field>
    )}
  </FieldArray>
);

LogicDetailsRules.propTypes = {
  dataCy: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(logicRuleType).isRequired,
};

export default LogicDetailsRules;

import { StatusCodes } from 'http-status-codes';

import { appConfig } from '../config';
import { stringify } from '../utils/queryString';
import axios from './axiosInstance';
import {
  getAuthorization,
  handlingResponse,
  logError,
} from './utils';

// FIXME: it seems there are some inconsistencies between entity names (i.e. 'legal' vs 'legal/terms')
const entityEndpoints = (entity, { entityId, template, revisionId }) => {
  const baseEndpoints = {
    bulk: `${appConfig.backendUrl}/api/v1/${entity}/bulk`,
    fetchAllv1: `${appConfig.backendUrl}/api/v1/${entity}`,
    fetchAll: `${appConfig.backendUrl}/api/v1/${entity}`,
    fetch: `${appConfig.backendUrl}/api/v1/${entity}/${entityId}`,
    create: `${appConfig.backendUrl}/api/v1/${entity}`,
    update: `${appConfig.backendUrl}/api/v1/${entity}/${entityId}`,
    patch: `${appConfig.backendUrl}/api/v1/${entity}/${entityId}`,
    upload: `${appConfig.backendUrl}/api/v1/${entity}/upload`,
    fetchTemplate: `${appConfig.backendUrl}/api/v1/${entity}/templates/${template}`,
    fetchAllRevisions: `${appConfig.backendUrl}/api/v1/${entity}/${entityId}/revisions`,
    fetchOneRevision: `${appConfig.backendUrl}/api/v1/${entity}/${entityId}/revisions/${revisionId}`,
    activateRevision: `${appConfig.backendUrl}/api/v1/${entity}/${entityId}/revisions/${revisionId}/activate`,
    clone: `${appConfig.backendUrl}/api/v1/${entity}/${entityId}/clone`,
  };
  const specificEndpoints = {
    audits: {
      fetch: `${appConfig.backendUrl}/api/v3/audits/${entityId}`,
      fetchAll: `${appConfig.backendUrl}/api/v3/audits`, // should call /v4/ if fetchAllAuditsV4 is activated
    },
    auditors: {
      fetchAll: `${appConfig.backendUrl}/api/v2/auditors`,
    },
    patches: {
      fetchAll: `${appConfig.backendUrl}/api/v2/patches`,
    },
    surveys: {
      clone: `${appConfig.backendUrl}/api/v2/surveys/${entityId}/clone`,
    },
  };
  return { ...baseEndpoints, ...specificEndpoints[entity] };
};

// todo: to be deleted
// eslint-disable-next-line consistent-return
export const fetchAllv1 = async (entity, queryObject = {}) => {
  try {
    let route = entityEndpoints(entity, {}).fetchAllv1;
    const query = stringify(queryObject);
    if (query.length > 0) {
      route = route.concat('?', query);
    }
    const response = await axios.get(
      route,
      { headers: { Authorization: getAuthorization() } },
    );
    return handlingResponse([StatusCodes.OK], `Error fetching all ${entity}`)(response);
  } catch (error) {
    await logError(error);
  }
};

// eslint-disable-next-line consistent-return
export const fetchAll = async (entity, queryObject = {}) => {
  try {
    let route = entityEndpoints(entity, {}).fetchAll;
    const query = stringify(queryObject);
    if (query.length > 0) {
      route = route.concat('?', query);
    }
    const response = await axios.get(
      route,
      { headers: { Authorization: getAuthorization() } },
    );
    return handlingResponse([StatusCodes.OK], `Error fetching all ${entity}`)(response);
  } catch (error) {
    await logError(error);
  }
};

// eslint-disable-next-line consistent-return
export const fetchOne = async ({ entity, entityId }) => {
  try {
    const route = entityEndpoints(entity, { entityId }).fetch;
    const response = await axios.get(
      route,
      { headers: { Authorization: getAuthorization() } },
    );
    return handlingResponse([StatusCodes.OK], `Error fetching ${entity} entity with id ${entityId}`)(response);
  } catch (error) {
    await logError(error);
  }
};

// eslint-disable-next-line consistent-return
export const create = async ({ entity, payload }) => {
  try {
    const route = (await entityEndpoints(entity, {})).create;
    const response = await axios.post(
      route,
      payload,
      { headers: { Authorization: getAuthorization() } },
    );
    return handlingResponse([StatusCodes.OK], `Error creating new ${entity} entity`)(response);
  } catch (error) {
    await logError(error);
  }
};

// eslint-disable-next-line consistent-return
export const update = async ({ entity, entityId, payload }) => {
  try {
    const route = entityEndpoints(entity, { entityId }).update;
    const response = await axios.put(
      route,
      payload,
      { headers: { Authorization: getAuthorization() } },
    );
    return handlingResponse([StatusCodes.OK], `Error updating ${entity} entity with id ${entityId}`)(response);
  } catch (error) {
    await logError(error);
  }
};

// eslint-disable-next-line consistent-return
export const patch = async ({ entity, entityId, payload }) => {
  try {
    const route = entityEndpoints(entity, { entityId }).patch;
    const response = await axios.patch(
      route,
      payload,
      { headers: { Authorization: getAuthorization() } },
    );
    return handlingResponse([StatusCodes.OK], `Error patching ${entity} entity with id ${entityId}`)(response);
  } catch (error) {
    await logError(error);
  }
};

// eslint-disable-next-line consistent-return
export const upload = async (entity, payload) => {
  try {
    const route = (await entityEndpoints(entity, {})).upload;
    const response = await axios.post(
      route,
      payload,
      { headers: { Authorization: getAuthorization() } },
    );
    return handlingResponse([StatusCodes.OK], `Error importing ${entity}`)(response);
  } catch (error) {
    await logError(error);
  }
};

// eslint-disable-next-line consistent-return
export const fetchTemplate = async ({ entity, template }) => {
  try {
    const route = (await entityEndpoints(entity, { template })).fetchTemplate;
    const response = await axios.get(
      route,
      { headers: { Authorization: getAuthorization() } },
    );
    return handlingResponse([StatusCodes.OK], `Error fetching ${entity} template`)(response);
  } catch (error) {
    await logError(error);
  }
};

// eslint-disable-next-line consistent-return
export const bulk = async ({ entity, payload }) => {
  try {
    const route = (await entityEndpoints(entity, {})).bulk;
    const response = await axios.post(
      route,
      payload,
      { headers: { Authorization: getAuthorization() } },
    );
    return handlingResponse([StatusCodes.OK], `Error bulking ${entity}`)(response);
  } catch (error) {
    await logError(error);
  }
};

// eslint-disable-next-line consistent-return
export const fetchAllRevisions = async ({ entity, entityId }) => {
  try {
    const route = entityEndpoints(entity, { entityId }).fetchAllRevisions;
    const response = await axios.get(
      route,
      { headers: { Authorization: getAuthorization() } },
    );
    return handlingResponse([StatusCodes.OK], `Error fetching ${entity} revisions`)(response);
  } catch (error) {
    await logError(error);
  }
};

// eslint-disable-next-line consistent-return
export const fetchOneRevision = async ({ entity, entityId, revisionId }) => {
  try {
    const route = (await entityEndpoints(entity, { entityId, revisionId })).fetchOneRevision;
    const response = await axios.get(
      route,
      { headers: { Authorization: getAuthorization() } },
    );
    return handlingResponse([StatusCodes.OK], `Error fetching ${entity} entity revision with id ${revisionId}`)(response);
  } catch (error) {
    await logError(error);
  }
};

// eslint-disable-next-line consistent-return
export const activateRevision = async ({ entity, entityId, revisionId }) => {
  try {
    const route = (await entityEndpoints(entity, { entityId, revisionId })).activateRevision;
    const response = await axios.put(
      route,
      {},
      { headers: { Authorization: getAuthorization() } },
    );
    return handlingResponse([StatusCodes.OK], `Error activate ${entity} entity revision with id ${revisionId}`)(response);
  } catch (error) {
    await logError(error);
  }
};

// eslint-disable-next-line consistent-return
export const clone = async ({ entity, entityId, payload }) => {
  try {
    const route = entityEndpoints(entity, { entityId }).clone;
    const response = await axios.post(
      route,
      payload,
      { headers: { Authorization: getAuthorization() } },
    );
    return handlingResponse([StatusCodes.OK], `Error cloning ${entity} with id ${entityId}`)(response);
  } catch (error) {
    await logError(error);
  }
};

import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { useSelector } from 'react-redux';
import { Divider, Form } from 'semantic-ui-react';

import { enumOptionsSelector, taxonomyTermSelector } from '../../../../state/constants/selectors';
import {
  logicDefaultValues,
  logicValidationSchema,
} from '../helpers';
import LogicDetailsRules from './LogicDetailsRules/LogicDetailsRules.component';

const DATA_CY = 'logic-details';

const surveyResultTypeSelector = state => taxonomyTermSelector(state, 'survey_result_type');
const statusTypeOptionsSelector = state => enumOptionsSelector(state, 'status_type');

const LogicDetails = ({
  data,
  submitFormRef,
  onSubmit,
}) => {
  const alert = useAlert();
  const surveyResultType = useSelector(surveyResultTypeSelector);
  const statusTypeOptions = useSelector(statusTypeOptionsSelector);

  const getResultTypeOptions = resultType => {
    const foundResultType = surveyResultType.terms.find(term => term.value === resultType);
    return foundResultType?.options || [];
  };

  const [rulesResultOptions, setRulesResultOptions] = useState(getResultTypeOptions(data?.survey_result_type || []));

  return (
    <Formik
      enableReinitialize={true}
      initialValues={data || logicDefaultValues}
      validationSchema={logicValidationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, handleChange, handleSubmit, setFieldValue, touched, values, isValid }) => {
        const submit = () => {
          if (!isValid) {
            alert.error(
              <pre>
                {JSON.stringify(errors, null, 2)}
              </pre>,
            );
          } else {
            handleSubmit();
          }
        };

        const handleSurveyResultTypeChange = value => {
          setFieldValue('survey_result_type', value);
          setRulesResultOptions(getResultTypeOptions(value));

          // Reset all the rules result
          values.rules.forEach((_value, index) => {
            setFieldValue(`rules.${index}.result`, null);
          });
        };

        return (
          <Form data-cy={`${DATA_CY}-form`}>
            <button ref={submitFormRef} hidden type="submit" onClick={submit} />
            <Form.Group widths="equal">
              <Form.Field required>
                <label>
                  Name
                </label>
                <Form.Input
                  data-cy={`${DATA_CY}-name-input`}
                  error={errors.name && touched.name ? errors.name : null}
                  id="name"
                  value={values.name}
                  onChange={handleChange}
                />
              </Form.Field>
              <Form.Field required>
                <label>
                  Survey Result Type
                </label>
                <Form.Select
                  data-cy={`${DATA_CY}-survey-result-type-input`}
                  error={errors.survey_result_type && touched.survey_result_type
                    ? errors.survey_result_type
                    : null
                  }
                  id="surveyResultType"
                  options={surveyResultType.terms}
                  value={values.survey_result_type}
                  onChange={(_e, { value }) => handleSurveyResultTypeChange(value)}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field width={16}>
                <LogicDetailsRules dataCy={`${DATA_CY}-rules-table`} options={rulesResultOptions} />
              </Form.Field>
            </Form.Group>
            <Divider />
            <Form.Group width={5}>
              <Form.Field required>
                <label>
                  Status
                </label>
                <Form.Select
                  data-cy={`${DATA_CY}-status-input`}
                  error={errors.status && touched.status
                    ? errors.status
                    : null
                  }
                  id="status"
                  options={statusTypeOptions}
                  value={values.status}
                  onChange={(_e, { value }) => setFieldValue('status', value)}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        );
      }}
    </Formik>
  );
};

LogicDetails.defaultProps = {
  data: null,
};

LogicDetails.propTypes = {
  submitFormRef: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  data: PropTypes.object, // TODO: logicType,
};

export default LogicDetails;

import React, { useCallback, useContext } from 'react';
import { Button, Divider } from 'semantic-ui-react';

import { PatchesContext } from '../../../../../context';
import history from '../../../../../history';
import { AuxiliaryPanel } from '../../../../common';
import styles from './PatchesListSidebar.module.scss';

const DATA_CY = 'patches-list-sidebar';

const PatchesListSidebar = () => {
  const uploadPatches = useCallback(() => {
    history.push('/patches/upload');
  }, []);

  const {
    patches,
    // setNewPatchActive,
    setSelectedPatchId,
  } = useContext(PatchesContext);

  return (
    <AuxiliaryPanel
      content={
        <>
          {patches.map((item, key) => (
            <div key={key} className={styles.itemList}>
              <div onClick={() => setSelectedPatchId(item.id)}>
                {item.name}
              </div>
              <Divider />
            </div>
          ))}
        </>
      }
      dataCy={DATA_CY}
      footer={
        <div className={styles.button}>
          <Button.Group>
            {/* TODO: enable adding a new patch */}
            {/* <Button
              primary
              className={styles.save}
              data-cy={'create-button'}
              onClick={() => setNewPatchActive(true)}
            >
              {'Create a new patch'}
            </Button>
            <Button.Or /> */}
            <Button
              secondary
              onClick={uploadPatches}
            >
              {'Upload a new set'}
            </Button>
          </Button.Group>
        </div>
      }
      header={(
        <span>
          {'Patches List'}
        </span>
      )}
      showCollapse={false}
    />
  );
};

export default PatchesListSidebar;

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Grid, Icon } from 'semantic-ui-react';

import { questionType, uuidType } from '../../../../../../types';
import styles from './QuestionsList.module.scss';

const QuestionsList = ({
  hiddenQuestionsIds,
  questions,
  showOnlyHidden,
  onHideQuestion,
  onShowQuestion,
}) => (
  <Grid columns={3}>
    {/** empty row added just in case the grid is empty, to avoid breaking layout */}
    <Grid.Row />
    {questions.map((question, index) => (
      <Grid.Row
        key={index}
        className={classnames({
          [styles.hidden]: showOnlyHidden && !hiddenQuestionsIds.includes(question.id),
          [styles.hiddenQuestion]: hiddenQuestionsIds.includes(question.id),
        })}
      >
        <Grid.Column width={2}>
          <div>
            {question.internal_id}
          </div>
        </Grid.Column>
        <Grid.Column width={12}>
          <div>
            {question.title}
          </div>
        </Grid.Column>
        <Grid.Column width={2}>
          {hiddenQuestionsIds.includes(question.id)
            ? (
              <Icon
                className={styles.hiddenIcon}
                onClick={() => onShowQuestion(question.id)}
              />
            )
            : (
              <Icon
                className={styles.visibleIcon}
                onClick={() => onHideQuestion(question.id)}
              />
            )
          }
        </Grid.Column>
      </Grid.Row>
    ))}
  </Grid>
);

QuestionsList.defaultProps = {
  showOnlyHidden: false,
};

QuestionsList.propTypes = {
  hiddenQuestionsIds: PropTypes.arrayOf(uuidType).isRequired,
  questions: PropTypes.arrayOf(questionType).isRequired,
  onHideQuestion: PropTypes.func.isRequired,
  onShowQuestion: PropTypes.func.isRequired,
  showOnlyHidden: PropTypes.bool,
};

export default QuestionsList;

/* eslint-disable react/prop-types */
import React from 'react';
import { Checkbox } from 'semantic-ui-react';

const SLTableCellToggleFormatter = ({ onToggle = null, trueValue = true }) => ({ cell }) => (
  <Checkbox
    toggle
    checked={cell.value === trueValue}
    onChange={onToggle ? (_e, { checked }) => onToggle(cell, checked) : null}
  />
);

export default SLTableCellToggleFormatter;

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Confirm } from 'semantic-ui-react';

import { SLHeader } from '../../../../common';

const ClientsAutomatedExportsDetailsHeader = ({ submitFormRef, onDelete }) => {
  const { exportId } = useParams();
  const [isModalOpen, setModalOpen] = useState(false);

  const handleModalDelete = () => {
    setModalOpen(false);
    onDelete();
  };

  return (
    <SLHeader title="Automated Export">
      <Button primary type="submit" onClick={() => submitFormRef?.current?.click()}>
        {'Save'}
      </Button>
      {exportId ? (
        <Button secondary onClick={() => setModalOpen(true)}>
          {'Delete'}
        </Button>
      ) : null}
      <Link to="/clients/automated">
        <Button>
          {'Cancel'}
        </Button>
      </Link>
      <Confirm
        open={isModalOpen}
        onCancel={() => setModalOpen(false)}
        onConfirm={handleModalDelete}
      />
    </SLHeader>
  );
};

ClientsAutomatedExportsDetailsHeader.propTypes = {
  submitFormRef: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ClientsAutomatedExportsDetailsHeader;

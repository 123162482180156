import { Field } from 'formik';
import React from 'react';
import { Form, Label } from 'semantic-ui-react';
import * as Yup from 'yup';

import commonStyles from '../AuditingQuestion.module.scss';
import AuditingQuestionHeader from '../AuditingQuestionHeader/AuditingQuestionHeader.component';
import styles from './AuditingCurrencyQuestion.module.scss';

const DATA_CY = 'auditing-currency-question';

const validate = question => value => {
  let error;

  const currentSchema = question.required
    ? Yup.number().typeError('Invalid value')
    : Yup.number().typeError('Invalid value').nullable();

  try {
    currentSchema.validateSync(value);
  } catch (err) {
    error = err.message;
  }

  return error;
};

const AuditingCurrencyQuestion = () => (
  <div className={commonStyles.question} data-cy={DATA_CY}>
    <Field name="question">
      {({ field: { value: question } }) => (
        <>
          <AuditingQuestionHeader question={question} />

          <Field name="answer" validate={validate(question)}>
            {({
              field: { value: answer, name: answerName },
              form: { setFieldValue },
              meta: { error },
            }) => (
              <>
                <Form.Input
                  className={styles.input}
                  labelPosition="right"
                  type="number"
                  value={answer}
                  onChange={(_e, { value }) => setFieldValue(answerName, value)}
                >
                  <input />
                  <Label>
                    {question.config.suffix}
                  </Label>
                </Form.Input>
                <div className={commonStyles.error}>
                  {error}
                </div>
              </>
            )}
          </Field>
        </>
      )}
    </Field>
  </div>
);
export default AuditingCurrencyQuestion;

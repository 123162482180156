import { useFeatureFlag } from 'configcat-react';
import { Formik, useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useAlert } from 'react-alert';
import { useMutation } from 'react-query';
import { Form } from 'semantic-ui-react';
import * as Yup from 'yup';

import { auditsAPI } from '../../../api';
import { DateTimeFormat, QuestionType } from '../../../constants';
import FormFieldDatePickerWrapper from '../FormFieldWrappers/FormFieldDatePickerWrapper.component';
import FormFieldDateTimeWrapper from '../FormFieldWrappers/FormFieldDateTimeWrapper.component';
import FormFieldSelectWrapper from '../FormFieldWrappers/FormFieldSelectWrapper.component';
import FormFieldWrapper from '../FormFieldWrappers/FormFieldWrapper.component';
import GenericModal from '../GernericModal/GenericModal.component';
import styles from './DynamicForm.module.scss';

const dynamicFormValidationSchema = Yup.array().of(
  Yup.object().shape({
    value: Yup.mixed().required('Value is required'),
  }),
);

const DynamicForm = ({
  name,
  open,
  onClose,
  emaiList,
  emailBody,
  emailSubject,
  auditId, auditorId,
  onNotificationSent,
}) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(name);
  const alert = useAlert();
  const { value: newDateTimePicker } = useFeatureFlag('newDateTimePicker', false);

  const {
    mutate: failLetterNotificationMutation, isLoading } = useMutation(auditsAPI.sendFailLetterNotification, {

    onSuccess: () => {
      onClose();
      onNotificationSent(true);
      alert.success('The notification has been sent');
    },
    onError: () => {
      onClose();
      alert.error('Error sending the notification');
    },

  });

  const handleSendNotification = tokens => {
    failLetterNotificationMutation({
      entityId: auditId,
      payload: {
        email_list: emaiList,
        email_body: emailBody,
        email_subject: emailSubject,
        is_custom: field.value.is_custom,
        document: field.value.document,
        tokens,
        content: field.value.content,
        auditor_id: auditorId,
      },
    });
  };

  const inputStrategies = {
    input: ({ _name = '', label = '' }) => <FormFieldWrapper label={label} name={_name}/>,
    select: ({ _name = '', label = '', options = [] }) => <FormFieldSelectWrapper label={label} name={_name} options={options} />,
    time: ({ _name = '', label = '' }) => (newDateTimePicker
      ? <FormFieldDatePickerWrapper label={label} name={_name} type={QuestionType.TIME}/>
      : <FormFieldDateTimeWrapper format={DateTimeFormat.HOUR_MINUTES_24H} label={label} name={_name}/>),
    date: ({ _name = '', label = '' }) => (newDateTimePicker
      ? <FormFieldDatePickerWrapper label={label} name={_name} type={QuestionType.DATE}/>
      : <FormFieldDateTimeWrapper format={DateTimeFormat.DAY_MONTH_YEAR} label={label} name={_name}/>),
    datetime: ({ _name = '', label = '' }) => (newDateTimePicker
      ? <FormFieldDatePickerWrapper label={label} name={_name} type={QuestionType.DATETIME}/>
      : <FormFieldDateTimeWrapper format={DateTimeFormat.DATETIME} label={label} name={_name}/>),
    default: () => (
      <div>
        This type of question is not supported yet.
      </div>
    ),
  };

  return (

    <Formik
      enableReinitialize={true}
      initialValues={field.value?.tokens || []}
      validationSchema={dynamicFormValidationSchema}
      onSubmit={values => {
        setFieldValue(`${name}.tokens`, values);
        handleSendNotification(values);
      }}

    >
      {({ values, handleSubmit }) => (
        <GenericModal
          buttons={[
            { label: 'Confirm', func: handleSubmit, loading: isLoading, isPrimary: true },
            { label: 'Cancel', func: onClose, disabled: isLoading },
          ]}
          headerText={'Do you want to send the fail letter notification?'}
          open={open}
          onDismiss={onClose}
        >
          <Form name className={styles.containerWrapper}>
            {values?.map((input, index) => (inputStrategies[input.type] || inputStrategies.default)(
              { _name: `${index}.value`,
                label: input.display_title,
                options: input.options?.map((option, _index) => ({ text: option, value: _index })) },
            ))}
          </Form>
        </GenericModal>
      )}

    </Formik>
  );
};

DynamicForm.propTypes = {
  auditId: PropTypes.string.isRequired,
  auditorId: PropTypes.string.isRequired,
  emailBody: PropTypes.string.isRequired,
  emaiList: PropTypes.string.isRequired,
  emailSubject: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onNotificationSent: PropTypes.func.isRequired,
};

export default DynamicForm;

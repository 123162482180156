import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'semantic-ui-react';

import FormFieldHelp from './FormFieldHelp.component';
import FormFieldLabel from './FormFieldLabel.component';

const FormFieldTemplate = ({
  children,
  dataCy,
  help,
  inline,
  label,
  labelStyle,
  labelTooltip,
  required,
}) => {
  if (inline) {
    return (
      <Form.Group>
        <Form.Field required={required} width={3}>
          <FormFieldLabel
            dataCy={dataCy}
            inline={inline}
            label={label}
            labelStyle={labelStyle}
            labelTooltip={labelTooltip}
          />
        </Form.Field>
        <Form.Field width={9}>
          {children}
          <FormFieldHelp dataCy={dataCy} help={help} />
        </Form.Field>
      </Form.Group>
    );
  }

  return (
    <Form.Field required={required}>
      <FormFieldLabel
        dataCy={dataCy}
        inline={inline}
        label={label}
        labelStyle={labelStyle}
        labelTooltip={labelTooltip}
      />
      {children}
      <FormFieldHelp dataCy={dataCy} help={help} />
    </Form.Field>
  );
};

FormFieldTemplate.defaultProps = {
  dataCy: 'form-field-template',
  help: null,
  inline: false,
  label: null,
  labelStyle: null,
  labelTooltip: null,
  required: false,
};

FormFieldTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  dataCy: PropTypes.string,
  help: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  inline: PropTypes.bool,
  label: PropTypes.string,
  labelStyle: PropTypes.string,
  labelTooltip: PropTypes.string,
  required: PropTypes.bool,
};

export default FormFieldTemplate;

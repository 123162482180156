import { Formik } from 'formik';
import _debounce from 'lodash/debounce';
import _pickBy from 'lodash/pickBy';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'semantic-ui-react';

import { DEBOUNCE_MS } from '../../../../constants';
import { FormFieldWrapper } from '../../../common';
import {
  siteFilterDefaultValues,
  siteFilterValidationSchema,
} from '../helpers';

const DATA_CY = 'clients-table-filter';

const SitesTableFilter = ({ values: initialValues, onChange }) => (
  <Formik
    initialValues={initialValues}
    validationSchema={siteFilterValidationSchema}
    onSubmit={values => onChange(_pickBy(values, value => value !== null && value !== undefined))}
  >
    {({ handleSubmit }) => {
      const debounceSubmit = _debounce(handleSubmit, DEBOUNCE_MS);

      const handleFieldChange = () => {
        debounceSubmit();
      };

      return (
        <Form inverted data-cy={DATA_CY}>
          <Form.Group widths="equal">
            <FormFieldWrapper
              label="Name"
              name="name"
              onChange={handleFieldChange}
            />
            <FormFieldWrapper
              label="Client"
              name="client_name"
              onChange={handleFieldChange}
            />
            <FormFieldWrapper
              label="Code"
              name="code"
              onChange={handleFieldChange}
            />
            <FormFieldWrapper
              label="City"
              name="city"
              onChange={handleFieldChange}
            />
            <FormFieldWrapper
              label="Post code"
              name="post_code"
              onChange={handleFieldChange}
            />
            <FormFieldWrapper
              label="Internal Id"
              name="internal_id"
              onChange={handleFieldChange}
            />
          </Form.Group>
        </Form>
      );
    }}
  </Formik>
);

SitesTableFilter.defaultProps = {
  values: siteFilterDefaultValues,
};

SitesTableFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    city: PropTypes.string,
    client_name: PropTypes.string,
    code: PropTypes.string,
    name: PropTypes.string,
    post_code: PropTypes.string,
  }),
};

export default SitesTableFilter;

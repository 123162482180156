import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { FormFieldCheckboxWrapper, FormFieldWrapper } from '../../../../common';
import FormLayout from '../../RegisterAuditorFormLayout/RegisterAuditorFormLayout.component';
import RegisterAuditorLegalModal from '../../RegisterAuditorLegalModal/RegisterAuditorLegalModal.component';
import styles from './RegisterAuditorFormStepOne.module.scss';

const RegisterAuditorFormStepOne = () => {
  const [legalModalProps, setLegalModalProps] = useState({
    open: false,
  });

  const handleLegalModalProps = open => {
    setLegalModalProps({
      open,
    });
  };

  return (
    <>
      <RegisterAuditorLegalModal
        open={legalModalProps.open}
        onCancel={() => handleLegalModalProps(false)}
      />
      <FormLayout
        subtitle={(
          <Link to="/logIn">
            {'Log In'}
          </Link>
        )}
        title="Do you already have an account?"
      >
        <FormFieldWrapper
          required
          className={styles.input}
          name="email"
          placeholder="Email Address"
        />

        <FormFieldCheckboxWrapper
          labelAfterCheckbox
          required
          label={(
            <>
              {'Accept '}
              <span className={styles.termsAndConditionsLink} onClick={() => handleLegalModalProps(true)}>
                {'Terms & Conditions'}
              </span>
              {' of Use'}
            </>
          )}
          name="accept_terms"
        />

      </FormLayout>
    </>
  );
};
export default RegisterAuditorFormStepOne;

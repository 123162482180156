import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';

import { entitiesAPI } from '../../../../../api';
import { AuditingSteps, Entity } from '../../../../../constants';
import history from '../../../../../history';
import { storeAuditDetails, updateAuditStep } from '../../../../../state/audits/actionCreators';
import { auditSelector } from '../../../../../state/audits/selectors';

const Status = {
  DOWNLOAD: 'download',
  START: 'start',
  CONTINUE: 'continue',
  UPLOAD: 'upload',
};

const content = {
  [Status.DOWNLOAD]: {
    disabled: true,
    text: 'Downloading',
    icon: 'spinner',
    loading: true,
  },
  [Status.START]: {
    disabled: false,
    text: 'View Details',
    icon: 'play',
    loading: false,
  },
  [Status.CONTINUE]: {
    disabled: false,
    text: 'Continue',
    icon: 'play',
    loading: false,
  },
  [Status.UPLOAD]: {
    disabled: true,
    text: 'Uploading',
    icon: 'cloud upload',
    loading: false,
  },
};

const StartAuditButtonCellFormatter = ({ cell }) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(Status.DOWNLOAD);

  const auditByIdSelector = state => auditSelector(state, cell.value);
  const audit = useSelector(auditByIdSelector);
  const isAuditDownloaded = audit !== undefined;

  const { data } = useQuery(
    [cell.value],
    () => entitiesAPI.fetchOne({ entity: Entity.AUDITS, entityId: cell.value }),
    { enabled: !isAuditDownloaded },
  );

  useEffect(() => {
    if (data) {
      dispatch(storeAuditDetails(data));
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (!audit) setStatus(Status.DOWNLOAD);
    else if (audit.step === undefined) setStatus(Status.START);
    else if (audit.step !== AuditingSteps.OFFLINE_FINISH) setStatus(Status.CONTINUE);
    else setStatus(Status.UPLOAD);
  }, [audit]);

  return (
    <Button
      icon
      primary
      disabled={content[status].disabled}
      labelPosition="right"
      onClick={() => {
        dispatch(updateAuditStep({
          id: cell.value,
          step: AuditingSteps.WELCOME,
        }));
        history.push(`/audits/${cell.value}`);
      }}
    >
      {content[status].text}
      <Icon
        loading={content[status].loading}
        name={content[status].icon}
        style={{ backgroundColor: 'transparent' }}
      />
    </Button>
  );
};

StartAuditButtonCellFormatter.propTypes = {
  cell: PropTypes.object.isRequired,
};

export default StartAuditButtonCellFormatter;

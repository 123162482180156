import _isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { UserRole } from '../../../../constants';
import { serviceENV } from '../../../../constants/environment';
import { useUserRole } from '../../../../hooks';
import { enumOptionsSelector } from '../../../../state/constants/selectors';
import {
  SLTable,
  SLTableCellDateFormatter,
  SLTableCellDateFormatterType,
  SLTableCellEnumFormatter,
  SLTableCellFormatter,
} from '../../../common';
import { RoleChecker } from '../../../layout';
import { userType } from '../../../types';
import UsersTableBulkOperations from '../UsersTableBulkOperations/UsersTableBulkOperations.component';
import styles from './UsersTable.module.scss';

const DATA_CY = 'users-table';

const userRoleOptionsSelector = state => enumOptionsSelector(state, 'user_role_type');
const env = serviceENV();

const UsersTable = ({
  data,
  pages,
  onLimitChange,
  onPageChange,
  onSortByChange,
  currentLimit,
  currentPage,
  currentSortBy,
  refetch,
}) => {
  const role = useUserRole();
  const userRoleOptions = useSelector(userRoleOptionsSelector);

  const [selectedUsers, setSelectedUsers] = useState([]);

  const getColums = ({ userRoleOpts }) => {
    const cols = [
      {
        accessor: 'internal_id',
        label: 'User ID',
        Cell: SLTableCellFormatter({
          linkFormat: '/users/#id',
        }),
      },
      {
        accessor: 'first_name',
        label: 'User Name',
        Cell: SLTableCellFormatter({
          avatarAttribute: 'image',
          textFormat: '#first_name #middle_name #last_name (#email)',
        }),
      },
      {
        accessor: 'status',
        label: 'Status',
        Cell: SLTableCellFormatter({ textClassName: styles.capitalize }),
      },
      {
        accessor: 'role',
        label: 'Role',
        Cell: SLTableCellEnumFormatter({ enumObject: userRoleOpts }),
      },
      {
        accessor: 'created_at',
        label: 'Member For',
        Cell: SLTableCellDateFormatter({ type: SLTableCellDateFormatterType.STRICT }),
      },
      {
        accessor: 'last_access',
        label: 'Last Access',
        Cell: SLTableCellDateFormatter({ type: SLTableCellDateFormatterType.RELATIVE }),
      },
    ];
    return cols;
  };

  const onRowSelectionChange = rows => {
    if (!_isEqual(rows.map(row => row.id), selectedUsers)) {
      setSelectedUsers(rows.map(row => row.id));
    }
  };

  const columns = getColums({ userRoleOpts: userRoleOptions, environment: env });

  return (
    <>
      <RoleChecker
        allowedRoles={[UserRole.DATA]}
      >
        <UsersTableBulkOperations
          refetch={refetch}
          selectedUsers={selectedUsers}
        />
      </RoleChecker>
      <SLTable
        columns={columns}
        currentLimit={currentLimit}
        currentPage={currentPage}
        currentSortBy={currentSortBy}
        data={data}
        dataCy={DATA_CY}
        pages={pages}
        rowSelector={role === UserRole.DATA}
        onLimitChange={onLimitChange}
        onPageChange={onPageChange}
        onRowSelectionChange={onRowSelectionChange}
        onSortByChange={onSortByChange}
      />
    </>
  );
};

UsersTable.defaultProps = {
  currentSortBy: {},
};

UsersTable.propTypes = {
  currentLimit: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(userType).isRequired,
  pages: PropTypes.number.isRequired,
  refetch: PropTypes.func.isRequired,
  onLimitChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onSortByChange: PropTypes.func.isRequired,
  currentSortBy: PropTypes.object,
};

export default UsersTable;

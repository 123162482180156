import 'firebase/messaging';

import { initializeApp } from 'firebase/app';

let initializedFirebaseApp;

const getSecret = (awsKey, envKey) => (window?.avs
  ? JSON.parse(window?.avs?.fbk)[awsKey]
  : process.env[envKey]);

const firebaseApp = () => {
  if (!initializedFirebaseApp) {
    initializedFirebaseApp = initializeApp({
      apiKey: getSecret('api_key', 'REACT_APP_FIREBASE_API_KEY'),
      authDomain: getSecret('fb_auth_domain', 'REACT_APP_FB_AUTH_DOMAIN'),
      databaseURL: getSecret('database_url', 'REACT_APP_DATABASE_URL'),
      projectId: getSecret('project_id', 'REACT_APP_PROJECT_ID'),
      storageBucket: getSecret('storage_bucket', 'REACT_APP_STORAGE_BUCKET'),
      messagingSenderId: getSecret('messaging_sender_id', 'REACT_APP_MESSAGING_SENDER_ID'),
      appId: `1:${getSecret('messaging_sender_id', 'REACT_APP_MESSAGING_SENDER_ID')}:web:${getSecret('app_id_web', 'REACT_APP_FIREBASE_APP_ID_WEB')}`,
      measurementId: getSecret('measurement_id', 'REACT_APP_FIREBASE_MEASUREMENT_ID'),
    });
  }
  return {
    messaging: initializedFirebaseApp.messaging(),
  };
};

export default firebaseApp;

import React from 'react';
import { Loader } from 'semantic-ui-react';

import styles from './AuditingLoading.module.scss';

const AuditingLoading = () => (
  <div className={styles.content}>
    <Loader active className={styles.loader} size="large"/>
    <h1 className={styles.loadingTitle}>
      {'Your audit is being sent...'}
    </h1>
  </div>
);

export default AuditingLoading;

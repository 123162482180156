import { Field, Formik } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useAlert } from 'react-alert';
import { Button, Form, Icon } from 'semantic-ui-react';

import { QuestionType } from '../../../../../constants';
import { DatePicker, SLTable } from '../../../../common';
import { taxonomyType } from '../../../../types';
import { payRateSchema } from '../../helpers';
import styles from './TaxonomyDetailsPayRates.module.scss';

const DATA_CY = 'taxonomy-details';

const formattedCell = ({ cell, row, userData }) => {
  const inputId = row.index;
  const isSubRow = row.depth > 0;
  const parentRow = isSubRow ? parseInt(row.id, 10) : null;
  const prefix = isSubRow ? `terms.${parentRow}.options.${inputId}` : `terms.${inputId}`;

  const types = {
    text: () => (
      <Field name={`${prefix}.text`}>
        {({ field, meta }) => (isSubRow
          ? (
            <div className={styles.subRowText}>
              <DatePicker
                date
                type={QuestionType.DATE}
                value={meta.value}
                onChange={value => {
                  userData.setFieldValue(field.name, value);
                }}
              />
            </div>
          )
          : (
            <Form.Field className={styles.parentRowText}>
              <Form.Input
                data-cy={`${DATA_CY}-value-input`}
                id={field.name}
                value={meta.value}
                onChange={(_, { value }) => {
                  userData.setFieldValue(field.name, value);
                  userData.setFieldValue(`${prefix}.value`, value);
                }}
              />
            </Form.Field>)
        )}
      </Field>
    ),
    value: () => (isSubRow ? (
      <Field name={`${prefix}.value`}>
        {({ field, meta }) => (
          <Form.Field className={styles.parentRowText}>
            <Form.Input
              data-cy={`${DATA_CY}-value-input`}
              id={`${prefix}.value`}
              type="number"
              value={meta.value}
              onChange={(_, { value }) => {
                userData.setFieldValue(field.name, value);
              }}
            />
          </Form.Field>
        )}
      </Field>
    ) : null),
    actions: () => (isSubRow ? (
      <div className={styles.deleteButton}>
        <Icon
          name="trash"
          onClick={() => {
            const selectedTerm = userData.values.terms[parentRow];
            const newOptions = selectedTerm.options.filter((option, index) => index !== inputId);
            userData.setFieldValue(`terms.${parentRow}.options`, newOptions);
          }}
        />
      </div>
    ) : (
      <Icon
        className={styles.addButton}
        disabled={isSubRow}
        name="add"
        onClick={() => {
          const newTerms = userData.values.terms.map((term, index) => {
            if (index === inputId) {
              term.options = [...(term.options || []), { text: '', value: '', disabled: false }];
            }
            return term;
          });
          userData.setFieldValue('terms', newTerms);
        }}
      />
    )),
  };
  return types[cell.column.id]();
};

const columns = [
  { accessor: 'text', label: 'Start Date', Cell: formattedCell },
  { accessor: 'value', label: 'Value', Cell: formattedCell },
  { accessor: 'actions', label: 'Actions', Cell: formattedCell },
];

const validateOptions = (options, parentRow) => {
  const errors = [];
  for (let i = 0; i < options.length; i += 1) {
    const currentOption = options[i];

    if (i !== 0) {
      const previousDate = options[i - 1];
      const datesOverlap = moment(currentOption.text).isBefore(previousDate.text, 'day')
        || moment(currentOption.text).isSame(previousDate.text, 'day');
      if (datesOverlap) {
        errors.push(
          `Date ${moment(currentOption.text).format('DD/MM/YYYY')} (sub row ${i + 1}, row ${parentRow}) overlaps\n with date ${moment(previousDate.text).format('DD/MM/YYYY')} (sub row ${i}, row ${parentRow})`,
        );
      }
    }
  }

  return errors;
};

const validate = values => {
  const { terms } = values;
  const errors = [];
  for (let i = 0; i < terms.length; i += 1) {
    const currentTerm = terms[i];
    const isEmptyText = !currentTerm.text;
    if (isEmptyText) {
      errors.push(`Value (row ${i + 1}) is required`);
    }
    if (!currentTerm.options?.length) {
      errors.push(`At least one rate is required for ${currentTerm.text} (row ${i + 1})`);
    }
    const optionErrors = validateOptions(currentTerm.options || [], i + 1);
    errors.push(...optionErrors);
  }

  return errors;
};

const TaxonomyDetailsPayRates = ({ data, onSubmit, submitFormRef }) => {
  const alert = useAlert();

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={data}
        validate={validate}
        validationSchema={payRateSchema}
        onSubmit={values => {
          const formattedValues = {
            ...values,
            terms: values.terms.map(term => ({
              ...term,
              options: term.options.map(option => ({
                ...option,
                value: Number(option.value),
              })),
            })),
          };
          onSubmit(formattedValues);
        }}
      >
        {({ values, handleChange, handleSubmit, setFieldValue, errors, isValid, validateForm }) => {
          const submit = () => {
            if (!isValid) {
              alert.error(
                <pre>
                  {errors.join('\n')}
                </pre>,
              );
            } else {
              handleSubmit();
            }
          };

          return (
            <>
              <Form>
                <button ref={submitFormRef} hidden type="submit" onClick={submit}/>
                <SLTable
                  columns={columns}
                  data={values.terms.map(term => ({
                    ...term,
                    expanded: term.options,
                    subRows: term.options,
                  }))}
                  dataCy={DATA_CY}
                  userData={{
                    handleChange,
                    values,
                    setFieldValue,
                    allowNested: data.allow_nested,
                    validateForm,
                  }}
                />
                <Button
                  className={styles.newTermButton}
                  onClick={() => {
                    setFieldValue('terms', [...values.terms, { text: '', value: '', disabled: false }]);
                  }}
                >
                  {'Add new term'}
                </Button>
              </Form>
            </>

          );
        }}
      </Formik>
    </>
  );
};

TaxonomyDetailsPayRates.propTypes = {
  data: taxonomyType.isRequired,
  submitFormRef: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default TaxonomyDetailsPayRates;

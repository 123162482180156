import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { taxonomyTermOptionsSelector } from '../../../../state/constants/selectors';
import {
  SLTable,
  SLTableCellEnumFormatter,
  SLTableCellFormatter,
} from '../../../common';
import { minimisedClientType } from '../../../types';

const DATA_CY = 'clients-table';

const columns = clientTypeTaxonomy => [
  {
    accessor: 'internal_id',
    label: 'Client ID',
    Cell: SLTableCellFormatter({
      linkFormat: '/clients/#id',
      textFormat: '#internal_id',
    }),
    sortable: false,
  },
  {
    accessor: 'name',
    label: 'Name',
    Cell: SLTableCellFormatter({
      avatarAttribute: 'logo',
    }),
  },
  {
    accessor: 'client_type',
    label: 'Type',
    Cell: SLTableCellEnumFormatter({ enumObject: clientTypeTaxonomy }),
  },
  {
    accessor: 'manager_name',
    label: 'Manager',
    Cell: SLTableCellFormatter({
      linkFormat: '/users/#manager_id',
      textFormat: '#manager_name (#manager_email)',
    }),
  },
];

const clientTypeTaxonomySelector = state => taxonomyTermOptionsSelector(state, 'client_type');

const ClientsTable = ({
  data,
  pages,
  onLimitChange,
  onPageChange,
  onSortByChange,
  currentLimit,
  currentPage,
  currentSortBy,
}) => {
  const clientTypeTaxonomy = useSelector(clientTypeTaxonomySelector);

  return (
    <SLTable
      columns={columns(clientTypeTaxonomy)}
      currentLimit={currentLimit}
      currentPage={currentPage}
      currentSortBy={currentSortBy}
      data={data}
      dataCy={DATA_CY}
      pages={pages}
      onLimitChange={onLimitChange}
      onPageChange={onPageChange}
      onSortByChange={onSortByChange}
    />
  );
};

ClientsTable.defaultProps = {
  currentSortBy: {},
};

ClientsTable.propTypes = {
  currentLimit: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(minimisedClientType).isRequired,
  pages: PropTypes.number.isRequired,
  onLimitChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onSortByChange: PropTypes.func.isRequired,

  currentSortBy: PropTypes.object,
};

export default ClientsTable;

import PropTypes from 'prop-types';
import React from 'react';
import { Label } from 'semantic-ui-react';

const FormikErrorMessage = ({ children }) => (
  <Label className="pointing above prompt">
    {children}
  </Label>
);

FormikErrorMessage.propTypes = {
  children: PropTypes.string.isRequired,
};

export default FormikErrorMessage;

import { useFeatureFlag } from 'configcat-react';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { entitiesAPI, errorAPI } from '../../../../api';
import { Entity } from '../../../../constants';
import history from '../../../../history';
import { LoadingPlaceholder, SingleLoadingPlaceholder } from '../../../common';
import { View } from '../../../layout';
import { body, bodyProps, header, headerProps, sidebar } from './helpers';

const EntityDetailsView = ({ cancel, edit, saveTaxonomy }) => {
  const { value: auditorApplicationUpgrade } = useFeatureFlag('auditorApplicationUpgrade', false);
  const { entity, entityId, mode } = useParams();
  const [currentEntity, setCurrentEntity] = useState(entity);
  const alert = useAlert();

  const submitFormRef = useRef(null);
  const { data, isFetching, refetch } = useQuery(
    [entity, entityId],
    () => entitiesAPI.fetchOne({ entity, entityId }),
    { enabled: Boolean(entityId) },
  );

  const { mutate: entityHandle } = useMutation(entityId ? entitiesAPI.update : entitiesAPI.create, {
    onSuccess: () => {
      alert.success(`${entity} ${entityId ? 'updated' : 'created'}`);
      history.push(`/${entity}`);
    },
    onError: error => {
      errorAPI.sendError(error.message, error.stack);
      alert.error(`Error ${entityId ? 'updating' : 'creating'} ${entity}: ${JSON.stringify(error)}`);
    },
  });

  const save = payload => entityHandle({ entity, entityId, payload });

  useEffect(() => {
    setCurrentEntity(entity);
  }, [entity]);

  return (
    <View
      secondary
      body={entityId && (isFetching || !data || entity !== currentEntity)
        ? LoadingPlaceholder
        : body({ entity, auditorApplicationUpgrade, mode })}
      bodyProps={bodyProps({ entity, entityId, data, submitFormRef, save, saveTaxonomy, mode, refetch })}
      condensed={currentEntity === Entity.AUDITS || (currentEntity === Entity.AUDITORS && mode === 'edit')}
      dataCy={`${entity}-details-view`}
      header={isFetching ? SingleLoadingPlaceholder : header({ entity, auditorApplicationUpgrade, mode })}
      headerProps={headerProps({ entity, data, submitFormRef, cancel, edit, mode, refetch })}
      sidebar={sidebar(entity)}
    />
  );
};

EntityDetailsView.propTypes = {
  cancel: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  saveTaxonomy: PropTypes.func.isRequired,
};

export default EntityDetailsView;

import React from 'react';

import { View } from '../../../layout';
import EntityRevisionsCompare from './EntityRevisionsCompare/EntityRevisionsCompare.component';
import EntityRevisionsCompareHeader from './EntityRevisionsCompareHeader/EntityRevisionsCompareHeader.component';

const EntityRevisionsCompareView = () => (
  <View
    body={EntityRevisionsCompare}
    header={EntityRevisionsCompareHeader}
  />
);

export default EntityRevisionsCompareView;

import { StatusCodes } from 'http-status-codes';

import { appConfig } from '../config';
import axios from './axiosInstance';
import {
  getAuthorization,
  handlingResponse,
  logError,
} from './utils';

export const fetch = () => axios.get(
  `${appConfig.backendUrl}/api/v1/legal/terms/current`,
)
  .then(handlingResponse([StatusCodes.OK], 'Error fetching terms and conditions'))
  .catch(logError);

export const create = payload => axios.post(
  `${appConfig.backendUrl}/api/v1/legal/terms`,
  payload,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], 'Error creating new terms and conditions version'))
  .catch(logError);

export const fetchAuditorTermsAndConditions = () => axios.get(
  `${appConfig.backendUrl}/api/v1/auditors/terms`,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], 'Error fetching terms and conditions'))
  .catch(logError);

export const acceptLegal = () => axios.put(
  `${appConfig.backendUrl}/api/v1/legal/terms/accept`,
  null,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], 'Error fetching terms and conditions'))
  .catch(logError);

export const uploadPrivacy = async ({ payload }) => axios.post(
    `${appConfig.backendUrl}/api/v1/legal/privacy`,
    payload,
    {
      headers: {
        Authorization: getAuthorization(),
        'Content-Type': 'multipart/form-data',
      },
    },
)
  .then(handlingResponse([StatusCodes.OK], 'Error uploading privacy notice'))
  .catch(logError);

export const fetchActivePrivacy = () => axios.get(
  `${appConfig.backendUrl}/api/v1/legal/privacy`,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], 'Error fetching active privacy notice'))
  .catch(logError);

export const acceptPrivacy = () => axios.put(
  `${appConfig.backendUrl}/api/v1/legal/privacy/accept`,
  null,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], 'Error accepting privacy notice'))
  .catch(logError);

export const fetchAuditorPrivacy = () => axios.get(
  `${appConfig.backendUrl}/api/v1/auditors/privacy`,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], 'Error fetching auditor privacy notice'))
  .catch(logError);

export const updateFacialImagesConsent = payload => axios.put(
  `${appConfig.backendUrl}/api/v1/auditors/me/facial-images-consent`,
  { facial_images_consent: payload },
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], 'Error updating facial images consent'))
  .catch(logError);

export const updateSpecialCategoryConsent = payload => axios.put(
  `${appConfig.backendUrl}/api/v1/auditors/me/special-category-consent`,
  { special_category_consent: payload },
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], 'Error updating special category consent'))
  .catch(logError);

export const updateCommunicationsConsent = payload => axios.put(
  `${appConfig.backendUrl}/api/v1/auditors/me/communications-consent`,
  { communications_consent: payload },
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], 'Error updating communications consent'))
  .catch(logError);

import { UserRole } from '../../constants';
import { TableViewActions } from '../helpers';

const tableViewActions = {
  [UserRole.DATA]: [
    TableViewActions.ADD,
    TableViewActions.EXPORT_EDIT_TEMPLATE,
    TableViewActions.EXPORT,
    TableViewActions.IMPORT,
  ],
};

export default {
  clients: {
    tableViewActions,
  },
};

import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';
import * as Yup from 'yup';

import { DateTimeFormat } from '../../../../../constants';
import { FormFieldDateTimeWrapper } from '../../../../common';

const OrderCloneModal = ({ modalOpen, onCloseModal, onSubmit }) => (
  <Modal closeOnDimmerClick={true} open={modalOpen} onClose={onCloseModal}>
    <Formik
      initialValues={{}}
      validationSchema={Yup.object().shape({
        end_date: Yup.date().required('Required'),
        start_date: Yup.date().required('Required'),
      })}
      onSubmit={({ start_date, end_date }) => onSubmit({ start_date, end_date })
      }
    >
      {({ handleSubmit }) => (
        <>
          <Modal.Header>
            Select new start and end dates to clone order
          </Modal.Header>
          <Modal.Content>
            <Form>
              <>
                <FormFieldDateTimeWrapper
                  required
                  format={DateTimeFormat.DAY_MONTH_YEAR}
                  label="Order date start"
                  name="start_date"
                  time={false}
                />
                <FormFieldDateTimeWrapper
                  required
                  format={DateTimeFormat.DAY_MONTH_YEAR}
                  label="Order date end"
                  name="end_date"
                  time={false}
                />
              </>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button primary type="submit" onClick={handleSubmit}>
              Clone
            </Button>
            <Button onClick={onCloseModal}>
Cancel
            </Button>
          </Modal.Actions>
        </>
      )}
    </Formik>
  </Modal>
);

OrderCloneModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default OrderCloneModal;

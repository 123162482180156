import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { UserRole } from '../../../../constants';
import { useUserRole } from '../../../../hooks';
import { enumOptionsSelector, taxonomyTermOptionsSelector } from '../../../../state/constants/selectors';
import {
  SLTable,
  SLTableCellDateFormatter,
  SLTableCellDateFormatterType,
  SLTableCellEnumFormatter,
  SLTableCellFormatter,
} from '../../../common';

const DATA_CY = 'orders-table';

const columns = ({ scheduleTypeOptions, orderStatusTypeOptions, itemToOrderOptions, role }) => [
  {
    accessor: 'internal_id',
    label: 'Order ID',
    Cell: [UserRole.CLIENT_SERVICES, UserRole.DATA].includes(role) ? SLTableCellFormatter({
      linkFormat: '/orders/#id',
    }) : SLTableCellFormatter({
      linkFormat: '/audits?order_id=#id',
    }),
  },
  { accessor: 'client_name',
    label: 'Client',
    Cell: [UserRole.CLIENT_SERVICES, UserRole.DATA].includes(role) ? SLTableCellFormatter({
      linkFormat: '/clients/#client_id',
      textFormat: '#client_name',
    }) : SLTableCellFormatter(),
  },
  { accessor: 'item_to_order',
    label: 'Item to order',
    Cell: SLTableCellEnumFormatter({ enumObject: itemToOrderOptions }),
  },
  { accessor: 'start_date',
    label: 'Start date',
    Cell: SLTableCellDateFormatter({ type: SLTableCellDateFormatterType.CUSTOM }),
  },
  { accessor: 'end_date',
    label: 'End date',
    Cell: SLTableCellDateFormatter({ type: SLTableCellDateFormatterType.CUSTOM }),
  },
  { accessor: 'schedule_type',
    label: 'Schedule',
    Cell: SLTableCellEnumFormatter({ enumObject: scheduleTypeOptions }),
  },
  { accessor: 'order_info',
    label: 'Order info',
  },
  { accessor: 'status',
    label: 'Status',
    Cell: SLTableCellEnumFormatter({ enumObject: orderStatusTypeOptions }),
  },
];

const scheduleTypeSelector = state => enumOptionsSelector(state, 'schedule_type');
const orderStatusTypeSelector = state => enumOptionsSelector(state, 'order_status_type');
const itemToOrderSelector = state => taxonomyTermOptionsSelector(state, 'order_item');

const OrdersTable = ({
  data,
  pages,
  onLimitChange,
  onPageChange,
  onSortByChange,
  currentLimit,
  currentPage,
  currentSortBy,
}) => {
  const role = useUserRole();

  const scheduleTypeOptions = useSelector(scheduleTypeSelector);
  const orderStatusTypeOptions = useSelector(orderStatusTypeSelector);
  const itemToOrderOptions = useSelector(itemToOrderSelector);

  return (
    <SLTable
      columns={columns({ scheduleTypeOptions, orderStatusTypeOptions, itemToOrderOptions, role })}
      currentLimit={currentLimit}
      currentPage={currentPage}
      currentSortBy={currentSortBy}
      data={data}
      dataCy={DATA_CY}
      pages={pages}
      onLimitChange={onLimitChange}
      onPageChange={onPageChange}
      onSortByChange={onSortByChange}
    />
  );
};

OrdersTable.defaultProps = {
  currentSortBy: {},
};

OrdersTable.propTypes = {
  currentLimit: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  pages: PropTypes.number.isRequired,
  onLimitChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onSortByChange: PropTypes.func.isRequired,
  currentSortBy: PropTypes.object,
};

export default OrdersTable;

import PropTypes from 'prop-types';
import React from 'react';
import { useQuery } from 'react-query';
import { Button, Modal } from 'semantic-ui-react';

import { legalAPI } from '../../../../api';
import styles from './DashboardLegalModal.module.scss';

const DashboardLegalModal = ({ onCancel, onAccept }) => {
  const { data: legal } = useQuery(
    'legal',
    () => legalAPI.fetch(),
  );

  const { data: termsAndConditions } = useQuery(
    'termsAndConditions',
    () => legalAPI.fetchAuditorTermsAndConditions(),
  );

  return termsAndConditions && !termsAndConditions.accepted ? (
    <Modal closeOnDimmerClick={true} open={true}>
      <Modal.Header>
        {'Terms & Conditions'}
      </Modal.Header>
      <Modal.Content>
        <div className={styles.tableContainer}>
          {legal?.description}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onCancel}>
          {'Deny'}
        </Button>
        <Button primary onClick={onAccept}>
          {'Accept'}
        </Button>
      </Modal.Actions>
    </Modal>
  ) : null;
};

DashboardLegalModal.propTypes = {
  // open: PropTypes.bool.isRequired,
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default DashboardLegalModal;

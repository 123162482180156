import React from 'react';
import { Link } from 'react-router-dom';

import { UserRole } from '../../../../constants';
import { useUserRole } from '../../../../hooks';
import styles from './UsersTableHeader.module.scss';

const DATA_CY = 'users-table-header';

const UsersTableHeader = () => {
  const role = useUserRole();

  if (role === UserRole.CLIENT_SERVICES) {
    return null;
  }
  return (
    <Link data-cy={`${DATA_CY}-account-settings-link`} to="/users/settings">
      <span className={styles.settings}>
      Account Settings
      </span>
    </Link>
  );
};

export default UsersTableHeader;

import { types } from 'react-alert';
import { cancel, put } from 'redux-saga/effects';

import { errorAPI } from '../../api';
import { Environment } from '../../constants';
import { serviceENV } from '../../constants/environment';
import firebaseApp from '../../init-fcm';
import { sagaMiddleware } from '../store';
import { showAlert, showNotification } from '../ui/actionCreators';
import * as actions from './actionCreators';
import * as actionTypes from './actionTypes';

function* showNotificationSaga({ type, message }) {
  yield put(showNotification({ type, message }));
}

function* initStoreDeviceToken() {
  try {
    const { messaging } = firebaseApp();
    const env = yield serviceENV();
    const swUrl = `./firebase-messaging-sw-${env === Environment.PRODUCTION
      ? Environment.PRODUCTION
      : Environment.DEVELOPMENT}.js`;
    const serviceWorkerRegistration = yield navigator.serviceWorker.register(swUrl);

    if (!('Notification' in window)) {
      yield put(showAlert({ type: types.ERROR, message: 'This browser does not support desktop notification' }));
      yield cancel();
    }

    const token = yield messaging.getToken({
      vapidKey: window.avs
        ? JSON.parse(window?.avs?.fbk).public_valid_key
        : process.env.REACT_APP_FIREBASE_PUBLIC_VALID_KEY,
      serviceWorkerRegistration,
    });
    yield put(actions.storeDeviceToken(token));

    navigator.serviceWorker.addEventListener('message', e => {
      const { data: { data: { title, body, click_action } } } = e;
      // Solution: https://www.debugcn.com/en/article/33115955.html
      sagaMiddleware.run(showNotificationSaga, { type: types.INFO, message: { title, body, click_action } });
    });
  } catch (error) {
    errorAPI.sendError(error.message, error.stack);
  }
}

export default {
  [actionTypes.INITIALISE_STORE_DEVICE_TOKEN]: initStoreDeviceToken,
};

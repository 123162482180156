import { Amplify, Hub } from 'aws-amplify';

import history from '../history';
import { removeAccess, setAccess } from '../persistence/access';
import appConfig from './appConfig';

const listener = data => {
  switch (data.payload.event) {
    case 'signIn':
      setAccess(data.payload.data?.signInUserSession?.idToken);
      break;
    case 'signOut':
      removeAccess();
      history.push('/login');
      break;

    default:
  }
};

const configureAmplify = () => {
  const { auth } = appConfig.awsAmplify;

  /**
   * @see https://docs.amplify.aws/lib/auth/getting-started/q/platform/js#configure-your-application
   */
  Amplify.configure({
    Auth: auth,
    // Analytics: analytics,
  });

  /**
   * @see https://docs.amplify.aws/lib/utilities/hub/q/platform/js
   */
  Hub.listen('auth', listener);
};

export default configureAmplify;

/* eslint-disable max-len */
import { useFeatureFlag } from 'configcat-react';
import { FieldArray, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Button, Card, Divider, Form, Grid } from 'semantic-ui-react';

import { surveyRevisionsAPI } from '../../../../../../api';
import { SurveyEntity } from '../../../../../../constants';
import { FormFieldSelectWrapper, FormFieldWrapper } from '../../../../../common';
import { CONDITION_VALUES, CONDITIONS, DEFAULT_CONDITION_VALUES_V2, DEFAULT_MATCHING_ROW_VALUES_V2, FORMAT_OPTIONS, INDENT_LIMIT, INTERVAL_OPTIONS, MATCHING_FORMATS, OPERATORS_OPTS, QUESTION_FIELD_OPTS } from '../constants/constants';
import { disableConditionSelector, disableRemove, emptyOptionalFields, findGroupCondition, firstLevelGroup, getNewGroup, getReferenceOptions, isAIEnabled, isEntityValueAndQuestionNotSelectable, isImageQuestion, isTimeQuestion, matchGroupConditions, matchingValidationSchema, removeButton, removeGroup } from '../helpers';
import styles from './SurveyMatchingEditor.module.scss';

const DATA_CY = 'survey-matching';

const initialFormValues = {
  matching: [
    DEFAULT_MATCHING_ROW_VALUES_V2,
  ],
};

const SurveyMatchingEditor = ({ matching, completeQuestions, submitFormRef, onSubmit }) => {
  const { entityId, revisionId } = useParams();

  const [detectionRuleQuestions, setDetectionRuleQuestions] = useState([]);
  const [decoratedQuestions, setDecoratedQuestions] = useState([]);

  const { value: chatGptPromptValues } = useFeatureFlag('chatGptPromptValues', '[]');

  let indent = 0;

  const buildMatchingSelectOptions = questions => questions?.map((question, index) => ({
    text: `${question?.internal_id} - ${question?.title}`,
    value: question?.id,
    key: index,
    type: question.type,
    ai_enabled: question.config?.ai?.toString(),
  }));

  const { data } = useQuery(
    [entityId, revisionId, { include: [SurveyEntity.QUESTIONS] }],
    () => surveyRevisionsAPI.fetchOne(
      entityId,
      revisionId,
      {
        include: [SurveyEntity.QUESTIONS],
      },
    ),
    {
      onSuccess: ({ questions }) => {
        setDetectionRuleQuestions(buildMatchingSelectOptions(questions));
        setDecoratedQuestions(buildMatchingSelectOptions(questions));
      },
    },
  );

  const getDRFromQuestion = ({ id, surveyData }) => {
    const options = surveyData?.questions
      .find(el => el.id === id)?.config.rules.map(({ label }) => ({
        text: label,
        value: label,
      }));
    return options || [];
  };

  const getChatGptTemplateFromQuestion = ({ id, surveyData }) => {
    const { template } = surveyData?.questions.find(el => el.id === id)?.config || {};
    return [{ text: template, value: template }];
  };

  const getChatGptPromptValues = ({ id, surveyData, chatGptPrompts = [] }) => {
    const { template } = surveyData?.questions.find(el => el.id === id)?.config || {};
    const options = chatGptPrompts
      .find(el => el.template === template)?.prompts
      .map(prompt => ({ text: prompt.fieldName, value: prompt.fieldName, type: prompt.type }));

    return options || [];
  };

  const setAIType = ({ match, questionId, questions }) => {
    match.ai_enabled = questions.some(q => q.value === questionId && q.ai_enabled);
  };

  const setMatchType = format => {
    const dateTypes = new Set(FORMAT_OPTIONS.filter(option => option.type === 'date').map(option => option.value));
    const timeTypes = new Set(FORMAT_OPTIONS.filter(option => option.type === 'time').map(option => option.value));
    const dayTypes = new Set(FORMAT_OPTIONS.filter(option => option.type === 'day').map(option => option.value));

    if (dateTypes.has(format)) {
      return MATCHING_FORMATS.date;
    }
    if (timeTypes.has(format)) {
      return MATCHING_FORMATS.time;
    }
    if (dayTypes.has(format)) {
      return MATCHING_FORMATS.day;
    }

    return MATCHING_FORMATS.text;
  };

  const setMatchingType = ({ match, format }) => {
    match.type = setMatchType(format);
  };

  const addButtons = ({ j, insertCondition, group, newGroup, previousCondition, init = false }) => {
    let order = j;
    return (
      <>
        <Grid>
          <Grid.Row
            columns={16}
            style={{ marginLeft: `${init ? 10 : indent + 60}px` }}
          >
            <Button
              content={'Add Condition'}
              onClick={() => {
                insertCondition(order, {
                  condition: previousCondition || CONDITION_VALUES.OR,
                  group,
                });
                insertCondition(order += 1, { ...DEFAULT_CONDITION_VALUES_V2[0], group });
              }}
            />
            <Button
              content={'Add Group'}
              disabled={indent > INDENT_LIMIT}
              onClick={() => {
                insertCondition(order, {
                  condition: previousCondition || CONDITION_VALUES.OR,
                  group,
                });
                insertCondition(order += 1, { condition: 'parenthesis', group: newGroup });
                insertCondition(order += 1, { ...DEFAULT_CONDITION_VALUES_V2[0], group: newGroup });
                insertCondition(order += 1, {
                  condition: previousCondition === CONDITION_VALUES.AND ? CONDITION_VALUES.OR : CONDITION_VALUES.AND,
                  group: newGroup,
                });
                insertCondition(order += 1, { ...DEFAULT_CONDITION_VALUES_V2[0], group: newGroup });
                insertCondition(order += 1, { condition: 'parenthesis_end', group: newGroup });
              }}
            />
          </Grid.Row>
        </Grid>
      </>);
  };

  return (
    <>
      {Boolean(data)
        && <Formik
          data-cy={DATA_CY}
          enableReinitialize={true}
          initialValues={{ matching } || initialFormValues}
          validateOnChange={false}
          validationSchema={matchingValidationSchema}
          onSubmit={onSubmit}

        // eslint-disable-next-line react/jsx-closing-bracket-location
        >
          {({ values, handleSubmit }) => (<Form noValidate>
            <button ref={submitFormRef} hidden type="submit" onClick={handleSubmit} />
            <FieldArray name="matching">
              {({ push, remove: removeRule }) => (
                <>
                  {values.matching.map((_mr, i) => (
                    <div key={`mr-${i}`}>
                      <Card fluid>
                        <Card.Content>
                          <Grid>
                            <div className={styles.header}>
                              <button
                                className={styles.removeRule}
                                title={'Remove rule'}
                                onClick={() => removeRule(i)}
                              />
                            </div>
                            <Grid.Row columns={16}>
                              <Grid.Column width={2}>
                                <p>
                                  Rule name
                                </p>
                              </Grid.Column>
                              <Grid.Column className={styles.marginBottom} width={14}>
                                <FormFieldWrapper
                                  name={`matching.${i}.rule_name`}
                                />
                              </Grid.Column>
                            </Grid.Row>

                            <Grid.Row columns={16}>
                              <Grid.Column width={2}>
                                <p>
                                  Rules
                                </p>
                              </Grid.Column>
                              <Grid.Column width={14}>
                                <FieldArray name={`matching[${i}].conditions`}>
                                  {({ insert: insertCondition, remove: removeCondition }) => (
                                    <React.Fragment>
                                      {_mr.conditions?.map((condition, j) => {
                                        if (condition.condition === 'parenthesis') {
                                          indent += 50;
                                          return (
                                            <>
                                              <Grid>
                                                <Grid.Row columns={16}>
                                                  <div className={styles.header}>
                                                    <button
                                                      className={styles.removeGroup}
                                                      hidden={_mr.conditions.length <= 1}
                                                      title={'Remove group'}
                                                      onClick={() => {
                                                        removeGroup({
                                                          conditions: _mr.conditions,
                                                          j,
                                                          group: condition.group,
                                                          removeCondition,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                </Grid.Row>
                                              </Grid>
                                            </>
                                          );
                                        } if (condition.condition === 'parenthesis_end') {
                                          indent -= 50;
                                          return addButtons({
                                            j,
                                            insertCondition,
                                            group: _mr.conditions[j].group,
                                            newGroup: getNewGroup(_mr.conditions),
                                            previousCondition: values?.matching[i]?.conditions[j - 2]?.condition,
                                          });
                                        }
                                        return (
                                          <div key={`mr-${i}-c-${j}`} style={{ marginLeft: `${indent}px` }}>
                                            {['parenthesis', 'parenthesis_end'].includes(condition.condition) ? null
                                              : Object.hasOwn(condition, 'condition') && Object.keys(CONDITION_VALUES).includes(condition.condition)
                                                ? (
                                                  <Grid>
                                                    <Grid.Row columns={16}>
                                                      <Grid.Column width={4}>
                                                        <FormFieldSelectWrapper
                                                          className={` ${styles.box} ${disableConditionSelector({
                                                            group: condition.group,
                                                            conditionIndex: j,
                                                            conditions: _mr.conditions,
                                                          }) ? styles.disabledConditionSelector : ''}`}
                                                          disabled={disableConditionSelector({
                                                            group: condition.group,
                                                            conditionIndex: j,
                                                            conditions: _mr.conditions,
                                                          })}
                                                          name={`matching.[${i}].conditions.[${j}].condition`}
                                                          options={CONDITIONS}
                                                          placeholder="Conditional"
                                                          onChange={matchGroupConditions({
                                                            condition,
                                                            conditions: values.matching[i].conditions,
                                                          })}
                                                        />
                                                      </Grid.Column>
                                                      <Grid.Column width={12} />
                                                    </Grid.Row>
                                                  </Grid>
                                                ) : (
                                                  <Card fluid
                                                    className={styles.cardMargin}
                                                  >
                                                    <Card.Content>
                                                      <div className={styles.header}>
                                                        <button
                                                          className={styles.removeCondition}
                                                          disabled={_mr.conditions.length <= 1 || disableRemove({ conditions: _mr.conditions, group: condition.group })}
                                                          title={'Remove condition'}
                                                          onClick={() => {
                                                            removeButton({ conditions: _mr.conditions, j, removeCondition });
                                                          }}
                                                        />
                                                      </div>
                                                      <Grid>
                                                        <Grid.Row columns={16}>
                                                          <Grid.Column width={4}>
                                                            <FormFieldSelectWrapper
                                                              className={styles.box}
                                                              name={`matching.[${i}].conditions.[${j}].question`}
                                                              options={detectionRuleQuestions}
                                                              placeholder="Question"
                                                              onChange={
                                                                emptyOptionalFields({
                                                                  detectionRuleQuestions,
                                                                  decoratedQuestions,
                                                                  condition,
                                                                })
                                                              }
                                                            />
                                                          </Grid.Column>
                                                          <Grid.Column width={3}>
                                                            <FormFieldSelectWrapper
                                                              className={styles.box}
                                                              disabled={isImageQuestion({
                                                                detectionRuleQuestions,
                                                                questionId: condition?.question,
                                                              })
                                                                ? Boolean(!condition?.detection_rule_name)
                                                                : Boolean(!condition?.question)}
                                                              name={`matching.[${i}].conditions.[${j}].operator`}
                                                              options={OPERATORS_OPTS}
                                                              placeholder="Operator"
                                                            />
                                                          </Grid.Column>
                                                          <Grid.Column width={3}>
                                                            <FormFieldSelectWrapper
                                                              className={styles.box}
                                                              disabled={isImageQuestion({
                                                                detectionRuleQuestions,
                                                                questionId: condition?.question,
                                                              })
                                                                ? Boolean(!condition?.detection_rule_name)
                                                                : Boolean(!condition?.question)}
                                                              name={`matching.[${i}].conditions.[${j}].entity`}
                                                              options={QUESTION_FIELD_OPTS}
                                                              placeholder="Entity"
                                                            />
                                                          </Grid.Column>
                                                          <Grid.Column width={4}>
                                                            {
                                                              isEntityValueAndQuestionNotSelectable({
                                                                entity: condition?.entity,
                                                                questionId: condition?.question,
                                                                detectionRuleQuestions,
                                                              }) ? (
                                                                  <FormFieldWrapper
                                                                    fluid
                                                                    className={styles.box}
                                                                    disabled={Boolean(!condition?.entity)}
                                                                    name={`matching.[${i}].conditions.[${j}].reference`}
                                                                    placeholder="Reference"
                                                                  />)
                                                                : (
                                                                  <FormFieldSelectWrapper
                                                                    className={styles.box}
                                                                    disabled={Boolean(!condition?.entity)}
                                                                    name={`matching.[${i}].conditions.[${j}].reference`}
                                                                    options={getReferenceOptions({
                                                                      entity: condition?.entity,
                                                                      questionId: condition?.question,
                                                                      completeQuestions,
                                                                      detectionRuleQuestions,
                                                                      decoratedQuestions,
                                                                    })}
                                                                    placeholder="Reference"
                                                                    onChange={
                                                                      emptyOptionalFields({
                                                                        detectionRuleQuestions,
                                                                        decoratedQuestions,
                                                                        condition,
                                                                      })
                                                                    }
                                                                  />)

                                                            }

                                                          </Grid.Column>
                                                        </Grid.Row>
                                                        <Grid.Row columns={16}>
                                                          {
                                                            isImageQuestion({
                                                              detectionRuleQuestions,
                                                              questionId: condition?.question,
                                                            }) || isTimeQuestion({
                                                              detectionRuleQuestions,
                                                              decoratedQuestions,
                                                              question: condition?.question,
                                                              reference: condition?.reference,
                                                              type: condition?.type,
                                                            })
                                                              ? <Grid.Column width={2}>
                                                                {'Detection options'}
                                                              </Grid.Column> : null
                                                          }
                                                          {
                                                            isAIEnabled(
                                                              { questionId: condition.question, detectionRuleQuestions },
                                                            )
                                                              ? (<>
                                                                <Grid.Column width={4}>
                                                                  <FormFieldSelectWrapper
                                                                    className={styles.box}
                                                                    name={`matching.[${i}].conditions.[${j}].template`}
                                                                    options={getChatGptTemplateFromQuestion({
                                                                      surveyData: data,
                                                                      id: condition?.question,
                                                                    })}
                                                                    placeholder="Template"
                                                                    onChange={setAIType({
                                                                      match: condition,
                                                                      questionId: values.matching[i].conditions[j].question,
                                                                      questions: detectionRuleQuestions,
                                                                    })}
                                                                  />
                                                                </Grid.Column>
                                                                <Grid.Column width={4}>
                                                                  <FormFieldSelectWrapper
                                                                    className={styles.box}
                                                                    name={`matching.[${i}].conditions.[${j}].template_prompt_value`}
                                                                    options={getChatGptPromptValues({
                                                                      surveyData: data,
                                                                      id: condition?.question,
                                                                      chatGptPrompts: JSON.parse(chatGptPromptValues),
                                                                    })}
                                                                    placeholder="Extractions"
                                                                    onChange={setAIType({
                                                                      match: condition,
                                                                      questionId: values.matching[i].conditions[j].question,
                                                                      questions: detectionRuleQuestions,
                                                                    })}
                                                                  />
                                                                </Grid.Column>
                                                              </>

                                                              ) : null
                                                          }
                                                          {
                                                            isImageQuestion({
                                                              detectionRuleQuestions,
                                                              questionId: condition?.question,
                                                            })
                                                              ? (<Grid.Column width={4}>
                                                                <FormFieldSelectWrapper
                                                                  className={styles.box}
                                                                  name={`matching.[${i}].conditions.[${j}].detection_rule_name`}
                                                                  options={getDRFromQuestion({
                                                                    surveyData: data,
                                                                    id: condition?.question,
                                                                  })}
                                                                  placeholder="Detection Rule"
                                                                />
                                                              </Grid.Column>) : null
                                                          }
                                                          {
                                                            isImageQuestion({
                                                              detectionRuleQuestions,
                                                              questionId: condition?.question,
                                                            })
                                                              ? (<Grid.Column width={5}>
                                                                <FormFieldSelectWrapper
                                                                  className={styles.box}
                                                                  name={`matching.[${i}].conditions.[${j}].format`}
                                                                  options={FORMAT_OPTIONS}
                                                                  placeholder="Detection Format"
                                                                  onChange={setMatchingType({
                                                                    match: condition,
                                                                    format: condition?.format,
                                                                  })}
                                                                />
                                                              </Grid.Column>) : null
                                                          }
                                                          {
                                                            isTimeQuestion({
                                                              detectionRuleQuestions,
                                                              decoratedQuestions,
                                                              question: condition?.question,
                                                              reference: condition?.reference,
                                                              type: condition?.type,
                                                            })
                                                              ? (<Grid.Column className={styles.flex} width={1}>
                                                                <FormFieldWrapper
                                                                  className={`${styles.box} ${styles.noBorderRadiusRight}`}
                                                                  name={`matching.[${i}].conditions.[${j}].interval`}
                                                                  placeholder="interval"
                                                                  type="number"
                                                                />
                                                                <FormFieldSelectWrapper
                                                                  className={`${styles.box} ${styles.noBorderRadiusLeft} ${styles.overflowSelect}`}
                                                                  name={`matching.[${i}].conditions.[${j}].intervalUnit`}
                                                                  options={INTERVAL_OPTIONS}
                                                                  placeholder="unit"
                                                                />
                                                              </Grid.Column>) : null
                                                          }
                                                        </Grid.Row>
                                                      </Grid>
                                                    </Card.Content>
                                                  </Card>

                                                )}
                                            {' '}
                                          </div>
                                        );
                                      })}
                                      {addButtons({
                                        j: _mr.conditions.length,
                                        insertCondition,
                                        group: _mr.conditions.length > 0 ? firstLevelGroup(_mr.conditions) : 1, // find the condition outside of all parenthesis and assign its group
                                        newGroup: getNewGroup(values.matching[i].conditions) + 1,
                                        previousCondition: _mr.conditions.length > 0 ? findGroupCondition({
                                          conditions: values.matching[i].conditions,
                                          group: firstLevelGroup(_mr.conditions),
                                        }) : null,
                                        init: true,
                                      })}
                                    </React.Fragment>
                                  )}
                                </FieldArray>
                              </Grid.Column>
                            </Grid.Row>

                          </Grid>
                        </Card.Content>
                      </Card>
                      <Divider />
                    </div>
                  ))
                  }
                  <Button
                    onClick={() => push(DEFAULT_MATCHING_ROW_VALUES_V2)}
                  >
                    Add Rule
                  </Button>

                </>

              )}
            </FieldArray>
          </Form>)}
        </Formik>
      }
    </>
  );
};

SurveyMatchingEditor.defaultProps = {
  completeQuestions: [],
  matching: [],
};

SurveyMatchingEditor.propTypes = {
  submitFormRef: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  completeQuestions: PropTypes.arrayOf(PropTypes.object),
  matching: PropTypes.arrayOf(PropTypes.object),
};

export default SurveyMatchingEditor;

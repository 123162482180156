import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { useMutation } from 'react-query';
import { Button } from 'semantic-ui-react';

import { entitiesAPI, notificationAPI } from '../../../../api';
import { Entity } from '../../../../constants';
import AuditorsNotificationModal from '../AuditorsNotificationModal/AuditorsNotificationModal.component';
import styles from './AuditorsTableBulkOperations.module.scss';

const DATA_CY = 'auditors-table-bulk-operations';

const AuditorsTableBulkOperations = ({ refetch, selectedAuditors }) => {
  const alert = useAlert();

  const [modalProps, setModalProps] = useState({ open: false });

  const { mutate } = useMutation(entitiesAPI.bulk, {
    onSuccess: data => {
      if (data.failed?.length > 0) {
        const failIds = data.failed.map(auditor => auditor.id).join(', ');
        alert.error(`Error changing status of ${failIds}`);
      }
      if (data.success?.length > 0) {
        const successIds = data.success.map(auditor => auditor.id).join(', ');
        alert.success(`Changed status of ${successIds}`);
      }
      refetch();
    },
    onError: error => {
      alert.error(error.message);
    },
  });

  const handleBulkOperation = status => {
    const payload = selectedAuditors.map(auditor => ({
      id: auditor,
      auditor_status: status,
    }));
    mutate({ entity: Entity.AUDITORS, payload });
  };

  const handleModalProps = open => {
    setModalProps({
      open,
    });
  };

  const {
    mutate: sendNotification,
  } = useMutation(notificationAPI.sendNotification, {
    onSuccess: () => alert.success('Notification sent'),
    onError: error => {
      alert.error(error.message);
    },
  });

  const sendNotificationProcess = ({ ids, all_auditors, auditor_ids, notification_title, notification_message }) => {
    sendNotification({
      payload: {
        ids,
        all_auditors,
        auditor_ids,
        notification: {
          title: notification_title,
          body: notification_message,
        },
      },
    });
  };

  return (
    <div className={styles.root} data-cy={DATA_CY}>
      <Button
        secondary
        data-cy={`${DATA_CY}-inactivate-auditors`}
        disabled={selectedAuditors.length === 0}
        onClick={() => handleBulkOperation('inactive')}
      >
        {'Inactivate Auditors'}
      </Button>
      <Button
        secondary
        data-cy={`${DATA_CY}-notificate-auditors`}
        onClick={() => handleModalProps(true)}
      >
        {'Send Notification'}
      </Button>

      <AuditorsNotificationModal
        open={modalProps.open}
        selectedAuditors={selectedAuditors}
        onCancel={() => { handleModalProps(false); }}
        onConfirm={values => {
          handleModalProps(false);
          sendNotificationProcess(values);
        }}
      />
    </div>
  );
};

AuditorsTableBulkOperations.propTypes = {
  refetch: PropTypes.func.isRequired,
  selectedAuditors: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default AuditorsTableBulkOperations;

import { useFeatureFlag } from 'configcat-react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Divider, Icon, Image } from 'semantic-ui-react';

import { UserRole } from '../../../../../../../../constants';
import FieldStatus from '../../../../../../../../fieldLogic/fieldStatus';
import { useUserRole } from '../../../../../../../../hooks';
import { FileUpload } from '../../../../../../../common';
import Dropzone from '../../../../../../../common/Dropzone/Dropzone.component';
import FormFieldLabel from '../../../../../../../common/FormFieldWrappers/Template/FormFieldLabel.component';
import styles from './SurveyFileQuestion.module.scss';

const DATA_CY = 'survey-file-question';

const SurveyFileQuestion = ({
  answers = [],
  auditScreen,
  formRecognitionValue,
  index,
  onChange,
  setModalImgSrc,
  question,
  surveyVisibility,
}) => {
  const user = useUserRole();
  const [answerFiles, setAnswerFiles] = useState([]);
  const { value: imageQuestionsDropzone } = useFeatureFlag('imageQuestionsDropzone', false);

  const handleOnDropFile = files => {
    if (onChange) {
      const newFiles = files.map(({ file }) => file);
      setAnswerFiles(prevState => [...prevState, ...newFiles]);
      return onChange([...answerFiles, ...newFiles]);
    }
    return null;
  };

  const handleOnRemoveFile = file => {
    if (onChange) {
      const indexToDelete = answers?.findIndex(answer => answer === file.url);
      if (indexToDelete !== -1) {
        return onChange(null, indexToDelete);
      }
      return null;
    }
    return null;
  };

  let state = FieldStatus.EDITABLE;

  if (surveyVisibility) {
    state = surveyVisibility;
  }

  const detectionResultErrorStatus = {
    SUCCESSFUL: { status: 'successful', message: 'Values not found' },
    UNSUCCESSFUL: { status: 'unsuccessful', message: 'Image failed to process' },
  };

  const displayImages = image => (
    <>
      <Image
        size="small"
        src={image}
        onClick={() => setModalImgSrc(image)}
      />
      <Icon name="trash" onClick={() => (onChange ? onChange(null) : null)} />
    </>
  );

  const displayFormRecognitionResults = (rule, values) => (
    <>
      <Divider />
      <div className={styles.formRecognitionValue}>
        <FormFieldLabel label={rule} />
        <span key={`${rule}`} className={styles.formRecognitionSpan}>
          {values}
        </span>
      </div>
    </>
  );

  const displayResults = (rule, values) => {
    const detectedValues = values.filter(value => value?.result?.length > 0);
    const unDetectedValues = values.filter(value => !value?.result?.length);

    if (detectedValues.length) {
      const detectedValuesFormatted = detectedValues.map(value => value?.result).join(' - ');
      return displayFormRecognitionResults(rule, detectedValuesFormatted);
    }

    if (unDetectedValues.length) {
      const noDataFound = unDetectedValues.every(value => value.status
        === detectionResultErrorStatus.SUCCESSFUL.status);
      const errorMessage = noDataFound
        ? detectionResultErrorStatus.SUCCESSFUL.message
        : detectionResultErrorStatus.UNSUCCESSFUL.message;

      return displayFormRecognitionResults(rule, errorMessage);
    }

    return null;
  };

  if ([FieldStatus.READONLY, FieldStatus.EDITABLE].includes(state) && (Array.isArray(answers) && answers[0])) {
    return (
      <>
        {imageQuestionsDropzone ? (
          <Dropzone
            accept="image/*"
            initialImages={answers || []}
            readOnly={surveyVisibility === FieldStatus.READONLY}
            onDropFiles={handleOnDropFile}
            onRemoveFile={handleOnRemoveFile}
          />
        )
          : answers.map(answer => displayImages(answer))
        }
        {(user === UserRole.DATA || user === UserRole.AREA_MANAGER) && formRecognitionValue
          ? (<div className={styles.formRecognitionForm}>
            {formRecognitionValue
              ? Object.keys(formRecognitionValue).map(rule => displayResults(rule, formRecognitionValue[rule]))
              : null
            }

            <Divider />
          </div>) : null}
      </>
    );
  }

  return imageQuestionsDropzone ? (
    <>
      {question.auditor_information && (
        <span>
          {question.auditor_information}
        </span>
      )}
      <Dropzone
        accept="image/*"
        initialImages={answers || []}
        readOnly={surveyVisibility === FieldStatus.READONLY}
        onDropFiles={handleOnDropFile}
        onRemoveFile={handleOnRemoveFile}
      />
    </>
  ) : (
    <FileUpload
      buttonDisabled={!auditScreen}
      dataCy={`${DATA_CY}-${index}`}
      subLabel={question.auditor_information}
      onChange={file => (onChange ? onChange(file?.[0] || file) : null)}
    />
  );
};

SurveyFileQuestion.defaultProps = {
  answers: [],
  auditScreen: null,
  formRecognitionValue: null,
  onChange: null,
  question: null,
  setModalImgSrc: null,
  surveyVisibility: null,
};

SurveyFileQuestion.propTypes = {
  index: PropTypes.number.isRequired,
  answers: PropTypes.array,
  auditScreen: PropTypes.bool,
  formRecognitionValue: PropTypes.object,
  question: PropTypes.object,
  setModalImgSrc: PropTypes.func,
  surveyVisibility: PropTypes.string,
  onChange: PropTypes.func,
};

export default SurveyFileQuestion;

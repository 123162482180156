import { Entity } from '../../../../constants';
//
import PatchesUpload from '../../Patches/PatchesUpload/PatchesUpload.component';
import EntityUpload from './EntityUpload/EntityUpload.component';
// const PatchesUpload = lazy(() => import('../../Patches/PatchesUpload/PatchesUpload.component'));

// eslint-disable-next-line import/prefer-default-export
export const body = ({ entity }) => {
  const entityBody = {
    [Entity.PATCHES]: PatchesUpload,
  };

  return entityBody[entity] || EntityUpload;
};

import PropTypes from 'prop-types';
import React from 'react';

const CellSumReclaimableExpensesFormatter = ({ cell }) => (<span>
  {cell.row.original.reclaimable_expenses + cell.row.original.reclaimable_expenses_2
     + cell.row.original.reclaimable_expenses_3 + cell.row.original.reclaimable_expenses_4}
</span>);

CellSumReclaimableExpensesFormatter.propTypes = {
  cell: PropTypes.object.isRequired,
};

export default CellSumReclaimableExpensesFormatter;

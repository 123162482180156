const isCondition = operand => ['AND', 'OR'].some(o => o === operand);

function joinOperands(array, operator, group) {
  const newArray = [];
  for (let i = 0; i < array.length; i += 1) {
    array[i].group = group;
    newArray.push(array[i]);

    if (i !== array.length - 1) {
      newArray.push({ ...operator, group });
    }
  }
  return newArray;
}

const flatConditions = (conditions, groups, group) => {
  const [operands] = Object.values(conditions);
  const [operator] = Object.keys(conditions);

  if (!isCondition(operator)) {
    return conditions;
  }

  let extractedOperand = [];

  operands.forEach(operand => { extractedOperand = [...extractedOperand, flatConditions(operand, groups, group)]; });
  // eslint-disable-next-line no-param-reassign
  group = groups.length ? Math.max(...groups) + 1 : 1;

  groups.push(group);
  return [
    {
      condition: 'parenthesis',
      group,
    },
    ...joinOperands(extractedOperand, { condition: operator }, group),
    {
      condition: 'parenthesis_end',
      group,
    },
  ].flat();
};

const stripOutterParenthesis = tree => tree.slice(1, tree.length - 1);

const isBaseCondition = tree => !Array.isArray(tree);

const formatConditions = tree => {
  const groups = [];
  const group = 1;
  const formattedTree = flatConditions(tree, groups, group);
  return isBaseCondition(formattedTree) ? [{ ...formattedTree, group }] : stripOutterParenthesis(formattedTree);
};

const flatTree = tree => ({
  ...tree,
  conditions: formatConditions(JSON.parse(JSON.stringify(tree.conditions))),
});

export default rules => (rules || []).map(flatTree);

import { Field, useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'semantic-ui-react';

import FormFieldTemplate from './Template/FormFieldTemplate.component';

const FormFieldSearchWrapper = ({
  dataCy,
  help,
  inline,
  label,
  labelTooltip,
  name,
  options,
  required,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(name);

  const dataCyPrefix = dataCy || name;

  const editableField = () => (
    <Field
      clearable
      search
      as={Form.Select}
      data-cy={`${dataCyPrefix}-field`}
      error={meta.error}
      icon="search"
      name={name}
      options={options}
      required={required}
      onBlur={null}
      onChange={(_e, { value }) => helpers.setValue(value)}
    />
  );

  return (
    <FormFieldTemplate
      dataCy={dataCyPrefix}
      help={help}
      inline={inline}
      label={label}
      labelTooltip={labelTooltip}
      required={required}
    >
      {editableField()}
    </FormFieldTemplate>
  );
};

FormFieldSearchWrapper.defaultProps = {
  dataCy: null,
  help: null,
  inline: false,
  labelTooltip: null,
  required: false,
};

FormFieldSearchWrapper.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  dataCy: PropTypes.string,
  help: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  inline: PropTypes.bool,
  labelTooltip: PropTypes.string,
  required: PropTypes.bool,
};

export default FormFieldSearchWrapper;

/* eslint-disable react/no-unescaped-entities */
import { Auth } from 'aws-amplify';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { Button, Divider, Modal } from 'semantic-ui-react';
import * as Yup from 'yup';

import { errorAPI } from '../../../../api';
import { CognitoUserChallengeNames, MfaMethods } from '../../../../constants';
import { getMFACode } from '../../../../utils/mfa';
import { FormFieldWrapper, LoadingPlaceholder } from '../../../common';
import styles from './UserMfaModal.module.scss';

const DATA_CY = 'user-mfa-modal';

const UserMfaModal = ({
  modalOpen,
  onAccept,
  onCancel,
  setMfaChecked,
}) => {
  const alert = useAlert();
  const [mfaCode, setMfaCode] = useState(null);
  const [mfaLoading, setMfaLoading] = useState(true);
  const defaultValues = {
    code: '',
  };

  const getCode = async () => {
    const code = await getMFACode();
    setMfaCode(code);
    setMfaLoading(false);
  };

  useEffect(() => {
    if (modalOpen) {
      getCode();
    }
  }, [modalOpen]);

  const verifyMFAModal = async mfaVerificationCode => {
    setMfaLoading(true);
    const user = await Auth.currentAuthenticatedUser();

    try {
      const response = await Auth.verifyTotpToken(user, mfaVerificationCode);
      if (response) {
        await Auth.setPreferredMFA(user, MfaMethods.TOTP);
        alert.success('MFA was successfully verified!');
        setMfaChecked(true);
        return onAccept();
      }
    } catch (e) {
      alert.error('Error Verifying MFA');
      await errorAPI.sendError(e.message, e.stack);
    } finally {
      setMfaLoading(false);
    }

    return onAccept();
  };

  const confirmOnCancel = async () => {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.setPreferredMFA(user, CognitoUserChallengeNames.NOMFA);
    return onCancel();
  };

  const verifyMfaCodeSchema = Yup.object()
    .shape({
      code: Yup.string()
        .matches(/^[0-9]{6}$/, {
          message: 'Incorrect format - code should be 6 digits',
          excludeEmptyString: false,
        })
        .required('Required'),
    });

  return (
    <Modal
      className={styles.modalCard}
      closeOnDimmerClick={true}
      data-cy={DATA_CY}
      open={modalOpen}
    >
      <Modal.Header>
        {'Verify MFA Code'}
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <div className={styles.description}>
            <p>
              Please scan the below QR code on your authenticator app.
            </p>
            <p>
              If you don't have an authenticator app, we recommend
            </p>
            <p>
              downloading 'Google Authenticator' from the app store.
            </p>
          </div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Content>
        {mfaLoading
          ? <LoadingPlaceholder/>
          : (
            <Formik
              enableReinitialize={true}
              initialValues={defaultValues}
              validationSchema={verifyMfaCodeSchema}
              onSubmit={async ({ code }) => verifyMFAModal(code)}
            >
              {({ handleSubmit }) => (
                <form
                  className={styles.form}
                  data-cy={`${DATA_CY}-form`}
                  onKeyPress={e => e.key === 'Enter' && handleSubmit}
                  onSubmit={handleSubmit}
                >
                  <QRCode value={mfaCode}/>
                  <Divider/>
                  <FormFieldWrapper
                    autoComplete="off"
                    label="Verification Code"
                    name="code"
                    type="string"
                  />
                  <div className={styles.buttonWrapper}>
                    <Button onClick={confirmOnCancel}>
                      {'Cancel'}
                    </Button>
                    <Button primary type="submit">
                      {'Verify'}
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          )}
      </Modal.Content>
    </Modal>
  );
};

UserMfaModal.defaultProps = {};

UserMfaModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  setMfaChecked: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default UserMfaModal;

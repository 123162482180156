const createFlattenedArrayWithoutFirstLevel = nestedTaxonomy => {
  const flattenedArray = [];
  nestedTaxonomy.terms.forEach(item => {
    if (item.options) {
      item.options.forEach(option => flattenedArray.push({ ...option, depth: 1 }));
    }
  });
  return flattenedArray;
};

export default createFlattenedArrayWithoutFirstLevel;

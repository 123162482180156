import PropTypes from 'prop-types';
import React, { createRef, useCallback, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Dropdown, Grid, Icon, Label } from 'semantic-ui-react';

import { CustomEventType } from '../../../../constants';
import { useEventListener } from '../../../../hooks';
import { isSystemInitialisedSelector } from '../../../../state/ui/selectors';
import { configDirections, getDirectionsRenderer, getDirectionsService, mapOptions } from '../../../../utils/googleMapsHelpers';
import { centerMap, entityMapFilter } from '../helpers';
import styles from './DirectionsMap.module.scss';
import { pointOptions, travelModeOptions, waypoints } from './helpers';

const DirectionsMap = ({ data, type, user }) => {
  const { entity } = useParams();
  const alert = useAlert();
  const mapRef = createRef();
  const [map, setMap] = useState(null);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [directionsServiceResult, setDirectionsServiceResult] = useState(null);
  const isSystemInitialised = useSelector(isSystemInitialisedSelector);
  const [travelModeOption, setTravelModeOption] = useState(travelModeOptions[0].value);

  const initMap = useCallback(() => {
    if (mapRef.current && !map) {
      const directionsRenderer = getDirectionsRenderer();
      const googleMap = new window.google.maps.Map(mapRef.current, mapOptions({ maxZoom: 15 }));
      directionsRenderer.setMap(googleMap);
      setMap(googleMap);
    }
  }, [map, mapRef]);

  useEffect(() => {
    if (start !== null && end !== null && travelModeOption !== null) {
      const directionsService = getDirectionsService();
      const directionsRenderer = getDirectionsRenderer();
      const request = configDirections({
        origin: JSON.parse(start),
        destination: JSON.parse(end),
        travelMode: travelModeOption,
        waypoints: waypoints(data),
      });

      directionsService.route(
        request,
        (result, status) => {
          if (result && status === window.google.maps.DirectionsStatus.OK) {
            directionsRenderer.setDirections(result);
            setDirectionsServiceResult(result);
          } else {
            alert.error(`Directions request failed due to ${status}`);
            setDirectionsServiceResult(null);
          }
        },
      );
    }
  }, [alert, data, start, end, travelModeOption]);

  useEffect(() => {
    if (map && data) {
      entityMapFilter({ entity, map, data, type });
      centerMap({ map, data });
    }
  }, [map, data, entity, type]);

  const deleteRoute = () => {
    if (user) {
      const directionsRenderer = getDirectionsRenderer();
      directionsRenderer.setDirections({ routes: [] });
    }
  };

  useEventListener(
    window,
    CustomEventType.MAP_ROUTE_REMOVE,
    deleteRoute,
  );

  useEffect(() => {
    if (isSystemInitialised) {
      initMap();
    }
  }, [isSystemInitialised, initMap]);

  const parseLatLng = coordinates => `${coordinates.lat()}%2C${coordinates.lng()}`;

  const openInGoogleMaps = useCallback(() => {
    if (directionsServiceResult) {
      const { request: { travelMode }, routes } = directionsServiceResult;
      const route = routes[0]?.legs[0];

      if (route) {
        const originLocation = parseLatLng(route.start_location);
        const destinationLocation = parseLatLng(route.end_location);
        const waypointListLocation = route.via_waypoints.map(wp => parseLatLng(wp)).join('%7C');

        const url = `https://www.google.com/maps/dir/?api=1&origin=${originLocation}&destination=${destinationLocation}&travelmode=${travelMode.toLowerCase()}&waypoints=${waypointListLocation}`;
        window.open(url);
      } else {
        alert.error('Error obtaining the route');
      }
    }
  }, [alert, directionsServiceResult]);

  return (
    <Grid>
      <Grid.Row columns={1}>
        <Grid.Column>
          <div className={styles.mapPanel}>
            <div ref={mapRef} className={styles.map} />
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column>
          <p className={styles.selectTitle}>
            {'Travel mode'}
          </p>
          <Dropdown
            fluid
            selection
            disabled={!user}
            options={travelModeOptions}
            placeholder="Select..."
            value={travelModeOption}
            onChange={(_event, { value }) => setTravelModeOption(value)}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column>
          <p className={styles.selectTitle}>
            {'Start point'}
          </p>
          <Dropdown
            fluid
            selection
            disabled={!user}
            options={pointOptions(user, data)}
            placeholder="Pick a point"
            value={start}
            onChange={(_event, { value }) => setStart(value)}
          />
        </Grid.Column>
        <Grid.Column>
          <p className={styles.selectTitle}>
            {'End point'}
          </p>
          <Dropdown
            fluid
            selection
            disabled={!user}
            options={pointOptions(user, data)}
            placeholder="Pick a point"
            value={end}
            onChange={(_event, { value }) => setEnd(value)}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row columns={2}>
        <Grid.Column>
          <Label basic className={styles.labelDirections}>
            {'Distance: '}
            <span>
              {directionsServiceResult?.routes[0]?.legs[0]?.distance?.text}
            </span>
          </Label>
        </Grid.Column>
        <Grid.Column>
          <Label basic className={styles.labelDirections}>
            {'Duration: '}
            <span>
              {directionsServiceResult?.routes[0]?.legs[0]?.duration?.text}
            </span>
          </Label>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row columns={1}>
        <Grid.Column>
          <Button
            fluid
            secondary
            disabled={directionsServiceResult === null}
            onClick={openInGoogleMaps}
          >
            <Icon name="map outline" />
            {'Open in Google Maps'}
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

DirectionsMap.defaultProps = {
  data: [],
  user: null,
};

DirectionsMap.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.array,
  user: PropTypes.object,
};

export default DirectionsMap;

import * as Yup from 'yup';

export const logicValidationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  rules: Yup.array().of(Yup.object().shape({
    logic: Yup.string().required('Required'),
    result: Yup.string().required('Required').nullable(),
  })),
  status: Yup.string().required('Required'),
  survey_result_type: Yup.string().required('Required').nullable(),
});

export const logicDefaultValues = {
  name: '',
  rules: [],
  survey_result_type: null,
  status: 'active',
};

export const logicFilterValidationSchema = Yup.object().shape({
  name: Yup.string(),
});

export const logicFilterDefaultValues = {
  name: '',
};

export const newRuleValues = {
  logic: '',
  result: null,
};

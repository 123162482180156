import { StatusCodes } from 'http-status-codes';

import { appConfig } from '../config';
import axios from './axiosInstance';
import {
  getAuthorization,
  handlingResponse,
  logError,
} from './utils';

export const fetchReport = () => axios.get(
  `${appConfig.backendUrl}/api/v1/powerbi/reports/embed`,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], 'Error fetching embedded report config'))
  .catch(logError);

export const exportReport = async ({ payload }) => axios({
  method: 'post',
  url: `${appConfig.backendUrl}/api/v1/powerbi/reports/exports`,
  data: payload,
  headers: { Authorization: getAuthorization() },
})
  .then(handlingResponse([StatusCodes.OK], 'Error downloading report export'))
  .catch(logError);

export const fetchUnassignedReports = async () => axios({
  method: 'get',
  url: `${appConfig.backendUrl}/api/v1/powerbi/reports`,
  headers: { Authorization: getAuthorization() },
})
  .then(handlingResponse([StatusCodes.OK], 'Error getting unassigned powerbi reports'))
  .catch(logError);

import React, { useCallback, useRef } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { surveyRevisionsAPI } from '../../../../../api';
import { Entity, SurveyEntity } from '../../../../../constants';
import history from '../../../../../history';
import { useUpdateSurvey } from '../../../../../hooks';
import { LoadingPlaceholder } from '../../../../common';
import SurveyStep from '../../SurveyStep/SurveyStep.component';
import SurveyLogicEditor from './SurveyLogicEditor/SurveyLogicEditor.component';

const SurveyLogic = () => {
  const { entityId, revisionId } = useParams();
  const submitFormRef = useRef(null);

  const { data } = useQuery(
    [entityId, revisionId, { include: SurveyEntity.LOGIC }],
    () => surveyRevisionsAPI.fetchOne(entityId, revisionId, { include: SurveyEntity.LOGIC }),
  );

  const { mutate: updateSurvey } = useUpdateSurvey({
    currentStep: SurveyEntity.LOGIC,
    onSuccessStep: SurveyEntity.MATCHING,
  });

  const back = useCallback(() => {
    history.push(`/${Entity.SURVEYS}/${entityId}/revisions/${revisionId}/${SurveyEntity.REPORTS}`);
  }, [entityId, revisionId]);

  // const next = useCallback(() => {
  //   history.push(
  //     `/${Entity.SURVEYS}`,
  //   );
  // }, [entityId, revisionId]);

  const handleSubmit = useCallback(values => {
    // FIXME: [SURVEY BLOCKED] uncomment when ServeLegal agrees
    // if (data?.blocked) {
    //   next();
    // } else {
    updateSurvey({
      surveyId: entityId,
      revisionId,
      payload: {
        [SurveyEntity.LOGIC]: values.logic,
      },
    });
    // }
  }, [entityId, revisionId, updateSurvey]);

  return (
    <SurveyStep
      back={back}
      backContent="Back to Reports"
      editor={data === undefined ? LoadingPlaceholder : SurveyLogicEditor}
      editorProps={{ logic: data?.logic, submitFormRef, onSubmit: handleSubmit }}
      headerProps={{ blocked: data?.blocked }}
      next={() => submitFormRef?.current?.click()}
      nextContent={/* data?.blocked ? 'Go to Surveys list' : */'Save & Continue'}
    />
  );
};

export default SurveyLogic;

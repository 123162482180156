import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, List, Modal } from 'semantic-ui-react';

import styles from './OrderBriefingDocs.module.scss';

const DocumentListItem = ({ doc, selected, onClick }) => (
  <List.Item
    className={classnames(styles.actionItem, { [styles.selected]: selected })}
    onClick={onClick}
  >
    <List.Icon name="file"/>
    <List.Content>
      {doc.text}
    </List.Content>
  </List.Item>
);

DocumentListItem.propTypes = {
  doc: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const OrderBriefingDocs = ({ docs, selectedDocs, open, onCancel, onConfirm }) => {
  const [tempDocs, setTempDocs] = useState([]);

  useEffect(() => {
    if (open) {
      setTempDocs(selectedDocs);
    }
  }, [open, selectedDocs]);

  const toggleFile = id => {
    if (tempDocs.includes(id)) {
      setTempDocs(oldValue => oldValue.filter(item => item !== id));
    } else {
      setTempDocs(oldValue => [...oldValue, id]);
    }
  };

  return (
    <Modal
      closeOnDimmerClick={false}
      open={open}
      size="tiny"
      onClose={onCancel}
    >
      <Modal.Header>
        {'Select the Briefing Documents'}
      </Modal.Header>
      <Modal.Content scrolling>
        <List divided className={styles.list}>
          {docs.map(value => {
            if (value.subRows) {
              return (
                <List.Item key={value.text}>
                  <List.Icon name="folder" />
                  <List.Content>
                    <List.Header>
                      {value.text}
                    </List.Header>
                    <List.List>
                      {value.subRows.map(subvalue => (
                        <DocumentListItem
                          key={subvalue.id}
                          doc={subvalue}
                          selected={tempDocs.includes(subvalue.id)}
                          onClick={() => toggleFile(subvalue.id)}
                        />
                      ))}
                    </List.List>
                  </List.Content>
                </List.Item>
              );
            }

            return (
              <DocumentListItem
                key={value.id}
                doc={value}
                selected={tempDocs.includes(value.id)}
                onClick={() => toggleFile(value.id)}
              />
            );
          })}
        </List>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onCancel}>
          {'Cancel'}
        </Button>
        <Button primary onClick={() => onConfirm(tempDocs)}>
          {'OK'}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

OrderBriefingDocs.defaultProps = {
  docs: [],
  open: false,
  selectedDocs: [],
};

OrderBriefingDocs.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  docs: PropTypes.array,
  open: PropTypes.bool,
  selectedDocs: PropTypes.array,
};

export default OrderBriefingDocs;

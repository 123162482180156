import classnames from 'classnames';
import { FieldArray, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useAlert } from 'react-alert';
import { useParams } from 'react-router-dom';
import { Divider, Form } from 'semantic-ui-react';

import { CustomEventType } from '../../../../../../constants';
import { SurveyQuestionsContext } from '../../../../../../context';
import { useEventListener } from '../../../../../../hooks';
import { questionInitialValues } from '../../questionsHelpers';
import SurveyActions from '../../SurveyActions/SurveyActions.component';
import SurveyDetailsForm from '../SurveyDetailsForm/SurveyDetailsForm.component';
import styles from './SurveyQuestionsEditor.module.scss';
import SurveyQuestionWrapper from './SurveyQuestionWrapper/SurveyQuestionWrapper.component';

const SurveyQuestionsPanel = ({ insert, move, remove }) => {
  const alert = useAlert();

  const {
    setSelectedQuestionIndex,
  } = useContext(SurveyQuestionsContext);

  const { values, validateForm } = useFormikContext();

  const isQuestionRepeated = (questions, id) => Boolean(questions.filter(q => q.id === id).length);

  const addQuestion = (question, index = null) => {
    validateForm().then(async validation => {
      if (question.id && isQuestionRepeated(values.questions, question.id)) {
        alert.info('This question already exist on the survey');
      } else if (Object.keys(validation).length === 0) {
        const position = index !== null ? index : values.questions.length;
        insert(position, question);
        setSelectedQuestionIndex(position);
      }
    });
  };

  const addNewQuestionListener = e => {
    const question = questionInitialValues(e.detail.type);
    addQuestion(question, e.detail.index);
  };

  const addPredeterminedQuestionListener = async e => {
    addQuestion(e.detail.question, e.detail.index);
  };

  const moveQuestionListener = async e => {
    move(e.detail.from, e.detail.to);
  };

  useEventListener(
    window,
    CustomEventType.QUESTION_ADD_NEW,
    addNewQuestionListener,
  );

  useEventListener(
    window,
    CustomEventType.QUESTION_ADD_PREDETERMINED,
    addPredeterminedQuestionListener,
  );

  useEventListener(
    window,
    CustomEventType.QUESTION_MOVE,
    moveQuestionListener,
  );

  const handleDragOver = e => {
    e.preventDefault();
  };

  const handleDrop = e => {
    const action = {
      [CustomEventType.QUESTION_ADD_NEW]: payload => {
        const question = questionInitialValues(payload.type);
        addQuestion(question);
      },
      [CustomEventType.QUESTION_ADD_PREDETERMINED]: async payload => {
        addQuestion(payload.question);
      },
    };

    try {
      const dragPayload = JSON.parse(e.dataTransfer.getData('text/plain'));
      if (action[dragPayload.event]) {
        action[dragPayload.event](dragPayload);
      }
    } catch (error) {
      alert.error(error.message);
    }
  };

  return (
    <div className={styles.questions} onDragOver={handleDragOver} onDrop={handleDrop}>
      {(values.questions && values.questions.length > 0)
        ? (
          values.questions.map(
            (question, index) => (
              <SurveyQuestionWrapper
                key={index}
                data={question}
                index={index}
                onDelete={() => remove(index)}
              />),
          )
        ) : (
          <div className={classnames(styles.noQuestions)}>
            <div className={styles.drawing} />
            <div className={styles.placeholder}>
              Drag and drop to add existing questions or create new ones
            </div>
          </div>
        )}
    </div>
  );
};

SurveyQuestionsPanel.propTypes = {
  insert: PropTypes.func.isRequired,
  move: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
};

const SurveyQuestionsEditor = () => {
  const { entityId } = useParams();

  return (
    <>
      { entityId ? (
        <>
          <SurveyActions />
          <Divider />
        </>
      ) : null}

      <Form noValidate className={styles.root}>
        <SurveyDetailsForm />
        <FieldArray component={SurveyQuestionsPanel} name="questions" />
      </Form>
    </>
  );
};

export default SurveyQuestionsEditor;

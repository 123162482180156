import { StatusCodes } from 'http-status-codes';

import { appConfig } from '../config';
import { stringify } from '../utils/queryString';
import axios from './axiosInstance';
import {
  getAuthorization,
  handlingResponse,
  logError,
} from './utils';

export const fetchAll = (surveyId, queryObject = {}) => {
  let route = `${appConfig.backendUrl}/api/v1/surveys/${surveyId}/revisions`;
  const query = stringify(queryObject);
  if (query.length > 0) {
    route = route.concat('?', query);
  }

  return axios.get(
    route,
    { headers: { Authorization: getAuthorization() } },
  )
    .then(handlingResponse([StatusCodes.OK], 'Error fetching all survey revisions'))
    .catch(logError);
};

export const fetchOne = (surveyId, revisionId, queryObject = {}) => {
  let route = `${appConfig.backendUrl}/api/v2/surveys/${surveyId}/revisions/${revisionId}`;
  const query = stringify(queryObject);
  if (query.length > 0) {
    route = route.concat('?', query);
  }

  return axios.get(
    route,
    { headers: { Authorization: getAuthorization() } },
  )
    .then(handlingResponse([StatusCodes.OK], `Error fetching survey revision with id ${revisionId}`))
    .catch(logError);
};

export const duplicateLast = surveyId => axios.post(
  `${appConfig.backendUrl}/api/v2/surveys/${surveyId}/revisions`,
  null,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], 'Error creating survey revision'))
  .catch(logError);

export const patch = ({ surveyId, revisionId, payload }) => axios.patch(
  `${appConfig.backendUrl}/api/v2/surveys/${surveyId}/revisions/${revisionId}`,
  payload,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], 'Error patching survey revision'))
  .catch(logError);

export const remove = (surveyId, revisionId) => axios.delete(
  `${appConfig.backendUrl}/api/v2/surveys/${surveyId}/revisions/${revisionId}`,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], 'Error deleting survey revision'))
  .catch(logError);

import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { useSelector } from 'react-redux';

import { entitiesAPI, errorAPI } from '../../../../../api';
import { DateTimeFormat, Entity } from '../../../../../constants';
import getFieldStatus from '../../../../../fieldLogic/audit';
import { useUserRole } from '../../../../../hooks';
import { enumOptionsSelector, taxonomyTermSelector } from '../../../../../state/constants/selectors';
import {
  FormFieldCurrencyWrapper,
  FormFieldDateTimeWrapper,
  FormFieldDynamicSearchWrapper,
  FormFieldSelectWrapper,
  FormFieldWrapper,
  TaxonomyTermOptions,
} from '../../../../common';
import { uuidType } from '../../../../types';

const scheduleTypeOptionsSelector = state => enumOptionsSelector(state, 'schedule_type');
const auditStatusOptionsSelector = state => enumOptionsSelector(state, 'audit_status');
const itemToOrderTaxonomyTermSelector = state => taxonomyTermSelector(state, 'order_item');

const AuditDetailsVisit = ({ revisionId, setCurrentStatus, labelStyle, valueStyle }) => {
  const { values } = useFormikContext();
  const alert = useAlert();
  const role = useUserRole();

  const scheduleTypeOptions = useSelector(scheduleTypeOptionsSelector);
  const auditStatusOptions = useSelector(auditStatusOptionsSelector);
  const itemToOrderTaxonomyTerm = useSelector(itemToOrderTaxonomyTermSelector);

  const buildOrderSearchOptions = orders => orders.map(order => ({
    text: order.internal_id,
    value: order.id,
  }));

  const [filteredOrders, setFilteredOrders] = useState([{
    text: `${values.order_internal_id}`,
    value: values.order_id,
  }]);

  const searchOrders = wildcard => {
    entitiesAPI.fetchAll(Entity.ORDERS, { internal_id: wildcard })
      .then(orders => {
        setFilteredOrders(buildOrderSearchOptions(orders.items));
      })
      .catch(error => {
        alert.error(`Error fetching orders: ${error.message}`);
        errorAPI.sendError(error.message, error.stack);
      });
  };

  return (
    <>
      <FormFieldSelectWrapper
        label="Schedule Type"
        labelStyle={labelStyle}
        name="order_schedule_type"
        options={scheduleTypeOptions}
        valueStyle={valueStyle}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      <FormFieldDynamicSearchWrapper
        label="Order"
        labelStyle={labelStyle}
        name="order_id"
        options={filteredOrders}
        valueStyle={valueStyle}
        onSearchChange={searchOrders}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      <FormFieldDateTimeWrapper
        format={DateTimeFormat.DAY_MONTH_YEAR}
        label="Order start date"
        labelStyle={labelStyle}
        name="order_start_date"
        valueStyle={valueStyle}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      <FormFieldDateTimeWrapper
        format={DateTimeFormat.DAY_MONTH_YEAR}
        label="Order end date"
        labelStyle={labelStyle}
        name="order_end_date"
        valueStyle={valueStyle}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      <FormFieldDateTimeWrapper
        label="Submitted Date"
        labelStyle={labelStyle}
        name="submitted_date"
        valueStyle={valueStyle}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      <FormFieldDateTimeWrapper
        label="Deadline Date"
        labelStyle={labelStyle}
        name="deadline_date"
        valueStyle={valueStyle}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      <FormFieldCurrencyWrapper
        currency={values.order_currency}
        label="Purchase cost"
        labelStyle={labelStyle}
        name="purchase_cost"
        valueStyle={valueStyle}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      <FormFieldSelectWrapper
        label="Status"
        labelStyle={labelStyle}
        name="status"
        options={auditStatusOptions}
        valueStyle={valueStyle}
        onChange={setCurrentStatus}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      {role !== 'area_manager' && (
        <FormFieldSelectWrapper
          label="Item to order"
          labelStyle={labelStyle}
          name="item_to_order"
          options={TaxonomyTermOptions(itemToOrderTaxonomyTerm)}
          valueStyle={valueStyle}
          onStateHandler={getFieldStatus(role, values.status, revisionId)}
        />)}

      <FormFieldWrapper
        label="Site Organisational Levels"
        labelStyle={labelStyle}
        name="site_organisation_level_code"
        valueStyle={valueStyle}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      <FormFieldDateTimeWrapper
        label="Approval Date"
        labelStyle={labelStyle}
        name="approval_date"
        valueStyle={valueStyle}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      <FormFieldWrapper
        label="Approved By"
        labelStyle={labelStyle}
        name="approved_by_name"
        valueStyle={valueStyle}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      <FormFieldWrapper
        label=""
        name="approved_by_email"
        valueStyle={valueStyle}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

    </>
  );
};

AuditDetailsVisit.defaultProps = {
  labelStyle: null,
  revisionId: null,
  setCurrentStatus: null,
  valueStyle: null,
};

AuditDetailsVisit.propTypes = {
  labelStyle: PropTypes.string,
  revisionId: uuidType,
  setCurrentStatus: PropTypes.func,
  valueStyle: PropTypes.string,
};

export default AuditDetailsVisit;

import { Entity, UserRole } from '../constants';
import FieldStatus from './fieldStatus';

const conditionObject = {
  [UserRole.CLIENT_SERVICES]: {
    client_id: FieldStatus.READONLY,
    email: FieldStatus.READONLY,
    first_name: FieldStatus.READONLY,
    image: FieldStatus.READONLY,
    last_name: FieldStatus.READONLY,
    middle_name: FieldStatus.READONLY,
    notify_user: FieldStatus.READONLY,
    role: FieldStatus.READONLY,
    status: FieldStatus.READONLY,
    allow_archive: FieldStatus.READONLY,
  },
  new: {
    client_id: FieldStatus.EDITABLE,
    email: FieldStatus.EDITABLE,
    first_name: FieldStatus.EDITABLE,
    image: FieldStatus.EDITABLE,
    last_name: FieldStatus.EDITABLE,
    middle_name: FieldStatus.EDITABLE,
    notify_user: FieldStatus.EDITABLE,
    role: FieldStatus.EDITABLE,
    status: FieldStatus.EDITABLE,
    allow_archive: FieldStatus.EDITABLE,
  },
  existing: {
    client_id: FieldStatus.EDITABLE,
    email: FieldStatus.READONLY,
    first_name: FieldStatus.EDITABLE,
    image: FieldStatus.EDITABLE,
    last_name: FieldStatus.EDITABLE,
    middle_name: FieldStatus.EDITABLE,
    notify_user: FieldStatus.EDITABLE,
    role: FieldStatus.EDITABLE,
    status: FieldStatus.EDITABLE,
    allow_archive: FieldStatus.EDITABLE,
  },
  account: {
    client_id: FieldStatus.EDITABLE,
    email: FieldStatus.READONLY,
    first_name: FieldStatus.EDITABLE,
    image: FieldStatus.EDITABLE,
    last_name: FieldStatus.EDITABLE,
    middle_name: FieldStatus.EDITABLE,
    notify_user: FieldStatus.READONLY,
    role: FieldStatus.READONLY,
    status: FieldStatus.READONLY,
    allow_archive: FieldStatus.EDITABLE,
  },
};

const getConditionObj = (userRole, entityDetails) => {
  if (userRole === UserRole.DATA && entityDetails.entityId) return conditionObject.existing;

  if (userRole === UserRole.DATA && entityDetails.entity === Entity.USERS) return conditionObject.new;

  if (
    userRole === UserRole.CLIENT_SERVICES
    && entityDetails.entity === Entity.USERS
  ) return conditionObject[userRole];

  if (!entityDetails.entity) return conditionObject.account;

  return null;
};

const getFieldStatus = (userRole, entity) => fieldName => (
  getConditionObj(userRole, entity)?.[fieldName] || FieldStatus.HIDDEN
);

export default getFieldStatus;

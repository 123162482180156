import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useAlert } from 'react-alert';

import { entitiesAPI, errorAPI } from '../../../api';
import { Entity } from '../../../constants';
import FormFieldDynamicSearchWrapper from '../FormFieldWrappers/FormFieldDynamicSearchWrapper.component';

const EntitySearchBox = ({
  defaultValue, entity, label, name, wildcardAttribute, extraFilters, buildSearchOptions, ...rest
}) => {
  const alert = useAlert();
  const [filteredItems, setFilteredItems] = useState(defaultValue ? [defaultValue] : []);

  const search = wildcard => {
    entitiesAPI.fetchAll(entity, { [wildcardAttribute]: wildcard, ...extraFilters })
      .then(list => setFilteredItems(buildSearchOptions(list.items)))
      .catch(error => {
        alert.error(`Error fetching ${entity}: ${error.message}`);
        errorAPI.sendError(error.message, error.stack);
      });
  };

  return (
    <FormFieldDynamicSearchWrapper
      label={label}
      name={name}
      options={filteredItems}
      placeholder="Search"
      onSearchChange={search}
      {...rest}
    />
  );
};

EntitySearchBox.defaultProps = {
  defaultValue: {},
  extraFilters: {},
};

EntitySearchBox.propTypes = {
  buildSearchOptions: PropTypes.func.isRequired,
  entity: PropTypes.oneOf(Object.values(Entity)).isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  wildcardAttribute: PropTypes.string.isRequired,
  defaultValue: PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  extraFilters: PropTypes.object,
};

export default EntitySearchBox;

import MarkerClusterer from '@googlemaps/markerclustererplus';
import { useFeatureFlag } from 'configcat-react';
import PropTypes from 'prop-types';
import React, { createRef, useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { auditsAPI } from '../../../../api';
import { EntityState } from '../../../../constants';
import { useUserRole } from '../../../../hooks';
import { isSystemInitialisedSelector } from '../../../../state/ui/selectors';
import { mapOptions } from '../../../../utils/googleMapsHelpers';
import { AuxiliaryPanel } from '../../../common';
import { centerMap, entityMapFilter, legendMap } from '../helpers';
import styles from './MapAuxiliary.module.scss';

const DATA_CY = 'map-auxiliary';

const MapAuxiliary = ({ data, type, fetchedAuditor, initialCollapse }) => {
  const role = useUserRole();
  const { entity } = useParams();
  const mapRef = createRef();
  const [map, setMap] = useState(null);
  const [collapsed, setCollapsed] = useState(initialCollapse);
  const [markerCluster, setMarkerCluster] = useState(null);
  const isSystemInitialised = useSelector(isSystemInitialisedSelector);
  const { value: fetchAllAuditsV4 } = useFeatureFlag('fetchAllAuditsV4', false);

  const initMap = useCallback(() => {
    if (mapRef.current && !map) {
      const googleMap = new window.google.maps.Map(mapRef.current, mapOptions({ maxZoom: 15 }));
      setMap(googleMap);
    }
  }, [map, mapRef]);

  const { data: nearByAuditors } = useQuery(
    ['nearByAuditors'],
    () => (fetchAllAuditsV4 ? auditsAPI.fetchAll('4')() : auditsAPI.fetchAll('3')()),
    { enabled: entity === 'audits' && !collapsed },
  );

  useEffect(() => {
    if (map && data) {
      data.nearby_auditors = nearByAuditors;
      const markers = entityMapFilter({ type, entity, map, data, fetchedAuditor });

      if (markerCluster) {
        markerCluster.clearMarkers();
        setMarkerCluster(null);
      }

      const cluster = new MarkerClusterer(map, markers, {
        imagePath:
          'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
        minimumClusterSize: 10,
      });
      setMarkerCluster(cluster);

      if (type === EntityState.DETAIL) {
        centerMap({ map, data });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, data, fetchedAuditor, entity, type, nearByAuditors]);

  useEffect(() => {
    if (isSystemInitialised && !map && !collapsed) {
      initMap();
    }
  }, [isSystemInitialised, map, initMap, collapsed]);

  return (
    <AuxiliaryPanel
      condensed
      content={
        <div className={styles.mapPanel}>
          <div ref={mapRef} className={styles.map} />
        </div>
      }
      dataCy={DATA_CY}
      footer={legendMap({ type, entity, role })}
      header={(
        <span>
          {'Map'}
        </span>
      )}
      initialCollapse={initialCollapse}
      showCollapse={true}
      onCollapse={newCollapsed => setCollapsed(newCollapsed)}
    />
  );
};

MapAuxiliary.defaultProps = {
  data: {},
  fetchedAuditor: {},
  initialCollapse: false,
};

MapAuxiliary.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.object,
  fetchedAuditor: PropTypes.object,
  initialCollapse: PropTypes.bool,
};

export default MapAuxiliary;

import React from 'react';

import styles from './SplashScreen.module.scss';

const SplashScreen = () => (
  <div className={styles.root}>
    <h1>
      SERVE LEGAL
    </h1>
  </div>
);

export default SplashScreen;

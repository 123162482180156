import { Formik } from 'formik';
import _debounce from 'lodash/debounce';
import _pickBy from 'lodash/pickBy';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'semantic-ui-react';

import { DEBOUNCE_MS } from '../../../../constants';
import { FormFieldWrapper } from '../../../common';
import { surveyFilterDefaultValues } from '../helpers';

const DATA_CY = 'surveys-table-filter';

const SurveysTableFilter = ({ values: initialValues, onChange }) => (
  <Formik
    enableReinitialize={true}
    initialValues={{
      ...surveyFilterDefaultValues,
      ...initialValues,
    }}
    onSubmit={values => onChange(_pickBy(values))}
  >
    {({ handleSubmit }) => {
      const debounceSubmit = _debounce(handleSubmit, DEBOUNCE_MS);

      const handleFieldChange = () => {
        debounceSubmit();
      };

      return (
        <Form inverted data-cy={DATA_CY}>
          <Form.Group widths="equal">
            <FormFieldWrapper
              label="Survey title"
              name="title"
              onChange={handleFieldChange}
            />
          </Form.Group>
        </Form>
      );
    }}
  </Formik>
);

SurveysTableFilter.propTypes = {
  values: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SurveysTableFilter;

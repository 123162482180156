import FieldStatus from './fieldStatus';

const conditionObject = {
  auditor: {},
  area_manager: {},
  client_services: {
    new: {
      address_1: FieldStatus.EDITABLE,
      address_2: FieldStatus.EDITABLE,
      address_3: FieldStatus.EDITABLE,
      city: FieldStatus.EDITABLE,
      client_id: FieldStatus.EDITABLE,
      code: FieldStatus.EDITABLE,
      country: FieldStatus.EDITABLE,
      directions: FieldStatus.EDITABLE,
      name: FieldStatus.EDITABLE,
      organisation_level_code: FieldStatus.EDITABLE,
      post_code: FieldStatus.EDITABLE,
      status: FieldStatus.EDITABLE,
    },
    existing: {
      address_1: FieldStatus.EDITABLE,
      address_2: FieldStatus.EDITABLE,
      address_3: FieldStatus.EDITABLE,
      city: FieldStatus.EDITABLE,
      client_id: FieldStatus.EDITABLE,
      code: FieldStatus.READONLY,
      country: FieldStatus.EDITABLE,
      directions: FieldStatus.EDITABLE,
      name: FieldStatus.READONLY,
      organisation_level_code: FieldStatus.READONLY,
      post_code: FieldStatus.EDITABLE,
      status: FieldStatus.READONLY,
    },
  },
  data: {
    new: {
      address_1: FieldStatus.EDITABLE,
      address_2: FieldStatus.EDITABLE,
      address_3: FieldStatus.EDITABLE,
      city: FieldStatus.EDITABLE,
      client_id: FieldStatus.EDITABLE,
      code: FieldStatus.EDITABLE,
      country: FieldStatus.EDITABLE,
      directions: FieldStatus.EDITABLE,
      name: FieldStatus.EDITABLE,
      organisation_level_code: FieldStatus.EDITABLE,
      post_code: FieldStatus.EDITABLE,
      status: FieldStatus.EDITABLE,
    },
    existing: {
      address_1: FieldStatus.EDITABLE,
      address_2: FieldStatus.EDITABLE,
      address_3: FieldStatus.EDITABLE,
      city: FieldStatus.EDITABLE,
      client_id: FieldStatus.EDITABLE,
      code: FieldStatus.EDITABLE,
      country: FieldStatus.EDITABLE,
      directions: FieldStatus.EDITABLE,
      name: FieldStatus.EDITABLE,
      organisation_level_code: FieldStatus.EDITABLE,
      post_code: FieldStatus.EDITABLE,
      status: FieldStatus.EDITABLE,
    },
  },
  client: {},
};

const getFieldStatus = (userRole, isNew, revisionId) => fieldName => (!revisionId ? conditionObject[userRole]?.[isNew ? 'new' : 'existing']?.[fieldName] : FieldStatus.READONLY || FieldStatus.HIDDEN);

export default getFieldStatus;

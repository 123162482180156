import _groupBy from 'lodash/groupBy';
import _pickBy from 'lodash/pickBy';

const parseDocument = doc => {
  const pathWithoutPrefix = doc.value.replace(/^(taxonomy\/documents\/)/, '');
  const path = pathWithoutPrefix.split('/');

  const folder = path.length > 1
    ? path[0]
    : !doc.isToken
      ? (doc.label || doc.token)
      : null;

  const parsed = {
    ...doc,
    folder,
    text: path[path.length - 1],
    name: path[path.length - 1],
  };

  return parsed;
};

const buildDocumentsTree = ({
  docs,
  docNameFilter = '',
}) => {
  let parsedDocuments = docs.map(doc => parseDocument(doc));

  if (docNameFilter.trim().length > 0) {
    parsedDocuments = parsedDocuments.filter(
      doc => doc.name.toLocaleLowerCase().includes(docNameFilter.toLocaleLowerCase()),
    );
  }

  const groups = _groupBy(parsedDocuments, 'folder');
  const groupsInFolder = _pickBy(groups, (_value, key) => key !== 'null');

  let tree = Object.entries(groupsInFolder).reduce((acc, [key, value]) => {
    // To avoid folders which contains itself;
    const valueCleaned = value.filter(val => val.name !== key);
    let newFolder;
    if (key !== 'null') {
      newFolder = {
        is_token: value[0]?.is_token,
        id: value[0]?.is_token ? value[0]?.id : key,
        folderId: (!value[0]?.type && !value[0]?.is_token) && value[0].id,
        text: key,
        name: key,
        subRows: value[0]?.is_token ? null : valueCleaned,
      };
      return [...acc, newFolder];
    }

    return acc.concat(value);
  }, []);

  // Append files without folder at the end of the tree
  if (groups.null) {
    tree = tree.concat(groups.null);
  }
  return tree;
};

export default buildDocumentsTree;

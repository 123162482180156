import moment from 'moment';
import React from 'react';
import { Image } from 'semantic-ui-react';

import { DateTimeFormat, QuestionType } from '../../../../constants';
import { getAnswerImages } from '../helpers';
import styles from './ReviewQuestion.module.scss';

const dateTimeMap = {
  [QuestionType.DATE]: DateTimeFormat.DAY_MONTH_YEAR,
  [QuestionType.DATETIME]: DateTimeFormat.DAY_MONTH_YEAR_HOUR_MINUTES_24H,
  [QuestionType.TIME]: DateTimeFormat.HOUR_MINUTES_24H,
};

const imageAnswer = async (value, imageUrls) => {
  const images = await getAnswerImages(value, imageUrls);

  if (images && images.length) {
    return Promise.resolve(
      <span>
        {images.map((i, index) => <Image key={index} className={styles.image} size="small" src={i} />)}
      </span>,
    );
  }
  return null;
};

const intervalAnswer = value => {
  if (value) {
    const start = value.start ? moment(value.start).format(dateTimeMap.time) : '';
    const end = value.end ? moment(value.end).format(dateTimeMap.time) : '';

    return (
      <span>
        {`${start} - ${end}`}
      </span>
    );
  }
  return null;
};

const genericAnswer = answer => (
  <span>
    {Array.isArray(answer) ? answer.join(', ') : answer}
  </span>
);

const currencyAnswer = (answer, currency) => (answer ? (
  <span>
    {`${answer} ${currency}`}
  </span>
) : null);

const dateAnswer = (value, type) => (
  <span>
    {value ? moment(value).format(dateTimeMap[type]) : ''}
  </span>
);

const getAnswerByType = async (answer, question, { answer_url }) => {
  const { type, value } = answer;
  let labels;

  if ([QuestionType.CHECKBOX, QuestionType.RADIO, QuestionType.SELECT].includes(type)) {
    const { config: { options } } = question;
    labels = options.filter(
      option => answer.value && answer.value.includes(option.value),
    ).map(({ label }) => label);
  }

  const parser = {
    [QuestionType.CHECKBOX]: () => genericAnswer(labels),
    [QuestionType.CURRENCY]: () => currencyAnswer(value, question.config.suffix),
    [QuestionType.DATE]: () => dateAnswer(value, QuestionType.DATE),
    [QuestionType.DATETIME]: () => dateAnswer(value, QuestionType.DATETIME),
    [QuestionType.IMAGE]: async () => imageAnswer(value, answer_url),
    [QuestionType.INTERVAL]: () => intervalAnswer(value),
    [QuestionType.NUMBER]: () => genericAnswer(value),
    [QuestionType.RADIO]: () => genericAnswer(labels),
    [QuestionType.SELECT]: () => genericAnswer(labels),
    [QuestionType.TEXT]: () => genericAnswer(value),
    [QuestionType.TEXTAREA]: () => genericAnswer(value),
    [QuestionType.TIME]: () => dateAnswer(value, QuestionType.TIME),
    [QuestionType.TITLE]: () => null,
  };

  return Promise.resolve(parser[type] ? parser[type]() : null);
};

const getAnswer = async (answer, urls, question) => {
  if (answer) {
    const { id, type } = answer;
    let answer_url;

    if ([QuestionType.IMAGE].includes(type)) {
      if (urls) {
        const { urls: imageUrls } = urls.find(url => url.id === id);
        answer_url = imageUrls;
      }
    }

    return Promise.resolve(getAnswerByType(answer, question, { answer_url }));
  }

  return Promise.resolve(null);
};

export default getAnswer;

import classnames from 'classnames';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useAlert } from 'react-alert';
import { Confirm, Grid, Icon } from 'semantic-ui-react';

import { CustomEventType, UserRole } from '../../../../../../../constants';
import { SurveyQuestionsContext } from '../../../../../../../context';
import { useUserRole } from '../../../../../../../hooks';
import { RoleChecker } from '../../../../../../layout';
import SurveyQuestion from '../SurveyQuestion/SurveyQuestion.component';
import styles from './SurveyQuestionWrapper.module.scss';

const DATA_CY = 'survey-question';

const SurveyQuestionWrapper = props => {
  const alert = useAlert();
  const { auditScreen, data, index, onDelete } = props;
  const role = useUserRole();

  const {
    selectedQuestionIndex,
    setSelectedQuestionIndex,
  } = useContext(SurveyQuestionsContext);

  const [isRemoveModalOpen, setRemoveModalOpen] = useState(false);
  const formikContext = useFormikContext();

  const toggleEditMode = () => {
    if (selectedQuestionIndex === null) {
      // No question selected, set this one as the currently selected
      setSelectedQuestionIndex(index);
    } else if (selectedQuestionIndex === index) {
      // This is the currently selected question
      // Only allow deselecting it if it doesn't have errors
      const { error } = formikContext.getFieldMeta(`questions.${index}`);
      if (!error) {
        setSelectedQuestionIndex(null);
      }
    } else {
      // Another question selected
      // Only allow selecting this question if the other doesn't have errors
      const { error } = formikContext.getFieldMeta(`questions.${selectedQuestionIndex}`);
      if (!error) {
        setSelectedQuestionIndex(index);
      }
    }
  };

  const handleDragStart = e => {
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('text/plain', JSON.stringify({
      event: CustomEventType.QUESTION_DRAG,
      index,
    }));
  };

  const handleDrop = e => {
    const action = {
      [CustomEventType.QUESTION_ADD_NEW]: payload => {
        e.stopPropagation();

        const event = new CustomEvent(CustomEventType.QUESTION_ADD_NEW, {
          detail: {
            type: payload.type,
            index,
          },
        });
        window.dispatchEvent(event);
      },
      [CustomEventType.QUESTION_ADD_PREDETERMINED]: payload => {
        e.stopPropagation();

        const event = new CustomEvent(CustomEventType.QUESTION_ADD_PREDETERMINED, {
          detail: {
            question: payload.question,
            index,
          },
        });
        window.dispatchEvent(event);
      },
      [CustomEventType.QUESTION_DRAG]: payload => {
        e.stopPropagation();

        const event = new CustomEvent(CustomEventType.QUESTION_MOVE, {
          detail: {
            from: payload.index,
            to: index,
          },
        });
        window.dispatchEvent(event);
      },
    };

    try {
      const dragPayload = JSON.parse(e.dataTransfer.getData('text/plain'));
      if (action[dragPayload.event]) {
        action[dragPayload.event](dragPayload);
      }
    } catch (error) {
      alert.error(error.message);
    }
  };

  const { values: { answers } } = useFormikContext();
  if (auditScreen) {
    const selectedQuestion = answers.find(answer => answer.internal_id === data.internal_id);

    if (selectedQuestion?.hidden) {
      return null;
    }
  }

  return (
    <div
      className={classnames(styles.root, { [styles.selected]: selectedQuestionIndex === index })}
      data-cy={`${DATA_CY}-${index}`}
      draggable={!auditScreen}
      onDragStart={auditScreen ? null : handleDragStart}
      onDrop={auditScreen ? null : handleDrop}
    >
      <Grid columns={2}>
        <RoleChecker
          allowedRoles={[UserRole.DATA, UserRole.AREA_MANAGER, UserRole.CLIENT_SERVICES]}
        >
          <Grid.Column verticalAlign="middle" width={1}>
            {data.internal_id ? data.internal_id : 'NEW'}
          </Grid.Column>
        </RoleChecker>
        <Grid.Column width={[UserRole.DATA, UserRole.CLIENT_SERVICES, UserRole.AREA_MANAGER].includes(role) ? 15 : 16}>
          <div className={styles.header} data-cy={`${DATA_CY}-${index}-header`}>
            {auditScreen ? null : (
              <>
                <Icon
                  data-cy={`${DATA_CY}-${index}-header-edit`}
                  name={selectedQuestionIndex === index ? 'angle double up' : 'edit'}
                  onClick={toggleEditMode}
                />
                {onDelete ? (
                  <span
                    className={styles.remove}
                    data-cy={`${DATA_CY}-${index}-header-remove`}
                    onClick={() => setRemoveModalOpen(true)}
                  />
                ) : null}
              </>
            )}
          </div>
          <div className={styles.content} data-cy={`${DATA_CY}-${index}-content`}>
            <div className={styles.questionContainer}>
              <SurveyQuestion {...props} />
            </div>
          </div>
          <Confirm
            data-cy={`${DATA_CY}-${index}-remove-confirm`}
            header="Warning"
            open={isRemoveModalOpen}
            onCancel={() => setRemoveModalOpen(false)}
            onConfirm={() => {
              setRemoveModalOpen(false);
              if (onDelete) onDelete();
            }}
          />
        </Grid.Column>
      </Grid>
    </div>
  );
};

SurveyQuestionWrapper.defaultProps = {
  auditScreen: false,
  onDelete: null,
  refetch: null,
};

SurveyQuestionWrapper.propTypes = {
  data: PropTypes.shape({
    internal_id: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  auditScreen: PropTypes.bool,
  refetch: PropTypes.func,
  onDelete: PropTypes.func,
};

export default SurveyQuestionWrapper;

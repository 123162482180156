export const OPERATORS_OPTS = [{
  text: 'Equal',
  value: 'eq',
}, {
  text: 'Not equal',
  value: 'neq',
}, {
  text: 'Greater',
  value: 'gt',
}, {
  text: 'Greater or equal',
  value: 'gte',
}, {
  text: 'Less than',
  value: 'lt',
}, {
  text: 'Less than or equal',
  value: 'lte',
}, {
  text: 'Contains',
  value: 'contains',
},
];

export const QUESTION_FIELD_OPTS = [{
  text: 'Field',
  value: 'field',
}, {
  text: 'Question',
  value: 'question',
}, {
  text: 'Value',
  value: 'value',
},
];

export const ENTITY_OPTS = [{
  text: 'site code',
  value: 'site_code',
},
{
  text: 'site postcode',
  value: 'site_postcode',
},
{
  text: 'date of visit',
  value: 'date_of_visit',
}];

export const DEFAULT_MATCHING_ROW_VALUES_V2 = {
  rule_name: '',
  conditions: [{
    question: '',
    detection_rule_name: '',
    operator: '',
    reference: '',
    entity: '',
    group: 1,
  }],
};

export const DEFAULT_CONDITION_VALUES_V2 = [
  {
    question: '',
    detection_rule_name: '',
    operator: '',
    reference: '',
    entity: '',
  },
];

export const FORMAT_OPTIONS = [
  {
    text: 'Text',
    value: 'text',
  },
  {
    text: 'Day',
    value: 'day',
    disabled: true,
  },
  {
    text: 'DD (eg: 07)',
    value: 'DD',
    nested: 'true',
    type: 'day',
  },
  {
    text: 'D(eg: 7)',
    value: 'D',
    nested: 'true',
    type: 'day',
  },
  {
    text: 'Date',
    value: 'date',
    disabled: true,
  },
  {
    text: 'DD/MM/YYYY (eg: 31/12/2022)',
    value: 'DD/MM/YYYY',
    nested: 'true',
    type: 'date',
  },
  {
    text: 'MM/DD/YYYY (eg: 12/31/2022)',
    value: 'MM/DD/YYYY',
    nested: 'true',
    type: 'date',
  },
  {
    text: 'DD/MM/YY (eg: 31/12/22)',
    value: 'DD/MM/YY',
    nested: 'true',
    type: 'date',
  },
  {
    text: 'DD/MM (eg: 31/12)',
    value: 'DD/MM',
    nested: 'true',
    type: 'date',
  },
  {
    text: 'DDMMMYYYY (eg: 31DEC2022)',
    value: 'DDMMMYYYY',
    nested: 'true',
    type: 'date',
  },
  {
    text: 'DD MMM YY (eg: 31 Dec 22)',
    value: 'DD MMM YY',
    nested: 'true',
    type: 'date',
  },
  {
    text: 'DDMMYY (eg: 311222)',
    value: 'DDMMYY',
    nested: 'true',
    type: 'date',
  },
  {
    text: 'Time',
    value: 'time',
    disabled: true,
  },
  {
    text: 'HH:mm (eg: 15:20)',
    value: 'HH:mm',
    nested: 'true',
    type: 'time',
  },
  {
    text: 'hh:mm AM/PM (eg: 3:20 PM)',
    value: 'hh:mm AM/PM',
    nested: 'true',
    type: 'time',
  },
];

export const MATCHING_FORMATS = {
  time: 'time',
  date: 'date',
  text: 'text',
  day: 'day',
};

export const INTERVAL_OPTIONS = [
  {
    text: 'seconds',
    value: 'seconds',
  },
  {
    text: 'minutes',
    value: 'minutes',
  },
  {
    text: 'hours',
    value: 'hours',
  },
];

export const INTERVAL_TYPES = new Set(['image', 'time', 'datetime']);

export const INTERVAL_DATE_TIME_TYPES = new Set(['time', 'datetime']);

export const SHOW_TIME_RANGE = {
  image: {
    time: true,
    date: false,
    text: false,
  },
  time: true,
  date: false,
  datetime: {
    time: true,
    date: false,
    datetime: true,
    image: false,
  },
  default: false,
};

export const VALUE_SELECTABLE_TYPES = ['radio', 'select'];

export const CONDITIONS = [
  {
    text: 'OR',
    value: 'OR',
  },
  {
    text: 'AND',
    value: 'AND',
  },
];

export const CONDITION_VALUES = {
  OR: 'OR',
  AND: 'AND',
};

export const INDENT_LIMIT = 50;

export const OPERATORS = ['eq', 'neq', 'gt', 'gte', 'lt', 'lte', 'contains'];
export const TYPES = ['text', 'date', 'time', 'day'];
export const AUX_ELEMENTS = ['AND', 'OR', 'parenthesis', 'parenthesis_end'];
export const ENTITIES = ['question', 'field', 'value'];

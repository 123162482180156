import { Field, useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { Button, Form, Icon, Label, Popup } from 'semantic-ui-react';

import FieldStatus from '../../../../../fieldLogic/fieldStatus';
import getOrdersFieldStatus from '../../../../../fieldLogic/orders';
import { FormFieldCheckboxWrapper, FormFieldSelectWrapper, FormFieldWrapper, RichEditor } from '../../../../common';
import styles from './OrderFailLetterPDF.module.scss';
import TokenEditor from './TokenEditor/TokenEditor.component';
import TokensTable from './TokensTable/TokensTable.component';

const tokenDefaultValues = {
  name: '',
  type: 'input',
  display_title: '',
  options: [],
};

const OrderFailLetterPDF = ({ name, role, isNew, revisionId, documents }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(name);

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedToken, setSelectedToken] = useState(null);
  const alert = useAlert();

  const fieldVisibility = getOrdersFieldStatus(role, isNew, revisionId)(name) || FieldStatus.HIDDEN;

  const handleAddToken = token => {
    const newTokens = [...(field.value?.tokens || []), token];
    setFieldValue(name, { ...field.value, tokens: newTokens });
    setSelectedToken(null);
    setModalOpen(false);
  };

  const handleEditToken = (index, updatedToken) => {
    const newTokens = (field.value?.tokens || []).map((token, i) => (i === index ? updatedToken : token));
    setFieldValue(name, { ...field.value, tokens: newTokens });
    setSelectedToken(null);
    setModalOpen(false);
  };

  const handleDeleteToken = index => {
    const newTokens = field.value.tokens.filter((_, i) => i !== index);
    setSelectedToken(null);
    setFieldValue(name, { ...field.value, tokens: newTokens });
  };

  const openEditModal = (token, index) => {
    setSelectedToken({ ...token, index });
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedToken(null);
  };

  const handleTokenClick = token => {
    navigator.clipboard.writeText(token);
    alert.success(`Token name copied to clipboard: ${token}`);
  };

  const getEditorContent = () => (fieldVisibility === FieldStatus.READONLY
    ? (
      <FormFieldWrapper
        inline
        readOnly
        label={'Content'}
        name={`${name}.content`}
        type={'textarea'}
      />
    )
    : (
      <Form.Group>
        <Form.Field width={3} />
        <Form.Field className={styles.form} width={9}>
          <h3 className={styles.pdfEditorTitle}>
            {'PDF Editor'}
          </h3>
          <div className={styles.tagsWrapper}>
            <Popup
              content={'Add a new token'}
              size={'small'}
              trigger={
                <Button icon size={'mini'} onClick={() => setModalOpen(true)}>
                  <Icon name={'add'}/>
                </Button>
              }
            />
            {field?.value?.tokens?.map((token, index) => (
              <Label key={`token-label-${index}`}>
                <Popup
                  content={'Copy token'}
                  size={'small'}
                  trigger={
                    <span className={styles.labelText} onClick={() => handleTokenClick(token.name)}>
                      {token.name}
                    </span>
                  }
                />
                <Popup
                  content={'Edit token'}
                  offset={[-12, 0]}
                  size={'small'}
                  trigger={
                    <span className={styles.iconEdit} onClick={() => openEditModal(token, index)}/>
                  }
                />
                <Popup
                  content={'Delete token'}
                  offset={[-12, 0]}
                  size={'small'}
                  trigger={
                    <span className={styles.iconDelete} onClick={() => handleDeleteToken(index)} />
                  }
                />
              </Label>
            ))}
          </div>
          <Field
            as={RichEditor}
            editorStyle={{ backgroundColor: 'white', borderRadius: '5px', borderColor: '#5F8298' }}
            name={`${name}.content`}
            toolbarStyle={{ borderRadius: '5px' }}
          />
          <TokenEditor
            initialValues={selectedToken || tokenDefaultValues}
            open={modalOpen}
            onClose={handleCloseModal}
            onSave={selectedToken ? token => handleEditToken(selectedToken.index, token) : handleAddToken}
          />
        </Form.Field>
        <Form.Field width={4}>
          <TokensTable onClick={handleTokenClick}/>
        </Form.Field>
      </Form.Group>
    ));

  const getDocumentSelectorContent = () => (fieldVisibility === FieldStatus.READONLY
    ? (
      <FormFieldWrapper
        inline
        readOnly
        label={'Document'}
        name={`${name}.document`}
      />
    )
    : (
      <FormFieldSelectWrapper
        inline
        label={'Document'}
        name={`${name}.document`}
        options={documents?.map(({ value }) => ({
          value, text: value,
        })) || []}
      />
    ));

  return fieldVisibility === FieldStatus.HIDDEN
    ? null
    : (
      <>
        <FormFieldCheckboxWrapper
          inline
          toggle
          defaultProps={false}
          {...(fieldVisibility === FieldStatus.EDITABLE && { help: 'Check this if you want to customise the PDF content' })}
          label="Customise PDF"
          name={`${name}.is_custom`}
          readOnly={fieldVisibility === FieldStatus.READONLY}
          onStateHandler={getOrdersFieldStatus(role, isNew, revisionId)}
        />
        {field?.value?.is_custom
          ? getEditorContent()
          : getDocumentSelectorContent()
        }
      </>
    );
};

OrderFailLetterPDF.defaultProps = {
  documents: [],
  revisionId: null,
};

OrderFailLetterPDF.propTypes = {
  isNew: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  documents: PropTypes.array,
  revisionId: PropTypes.string,
};

export default OrderFailLetterPDF;

import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import ReactAvatarEditor from 'react-avatar-editor';
import { Button, Grid, Modal } from 'semantic-ui-react';

import styles from './FullScreenImageModal.module.scss';

const FullScreenImageModal = ({ imgSrc, onClose }) => {
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [position, setPosition] = useState({ x: 0.5, y: 0.5 });
  const [imageSize, setImageSize] = useState(null);
  const editorRef = useRef(null);

  const handleScale = e => {
    // eslint-disable-next-line no-shadow
    const scale = parseFloat(e.target.value);
    setScale(scale);
  };

  const rotation = ({ e, grad }) => {
    e.preventDefault();
    setRotate(rotate + grad);
  };

  useEffect(() => {
    if (imgSrc) {
      const img = new Image();
      img.src = imgSrc;
      img.addEventListener('load', function onLoad() {
        const sizes = {
          width: this.naturalWidth,
          height: this.naturalHeight,
        };
        setImageSize(sizes);
      });
    }
  }, [imgSrc]);

  return (
    <Modal
      closeOnDimmerClick={true}
      open={imgSrc}
      onClose={onClose}
    >
      <Modal.Content className={styles.content}>
        <ReactAvatarEditor
          ref={editorRef}
          border={1}
          color={[95, 130, 152, 1]}
          height={imageSize?.height > 500 ? 500 : imageSize?.height}
          image={imgSrc}
          position={position}
          rotate={rotate}
          scale={scale}
          width={imageSize?.width > 800 ? 800 : imageSize?.width}
          onPositionChange={setPosition}
        />
        <h5>
          {'Zoom:'}
        </h5>
        <input
          defaultValue="1"
          max="2"
          min="1"
          name="scale"
          step="0.01"
          type="range"
          onChange={handleScale}
        />
        <h5>
          {'Rotate:'}
        </h5>
        <Grid columns="equal">
          <Grid.Column>
            <Button content="Left" icon="undo" labelPosition="left" onClick={e => rotation({ e, grad: -90 })} />
          </Grid.Column>
          <Grid.Column>
            <Button content="Right" icon="redo" labelPosition="right" onClick={e => rotation({ e, grad: 90 })} />
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={onClose}>
          {'Close'}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

FullScreenImageModal.defaultProps = {
  imgSrc: null,
};

FullScreenImageModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  imgSrc: PropTypes.string,
};

export default FullScreenImageModal;

import { combineReducers } from 'redux';

const reducerFiles = require.context('.', true, /reducer.js$/);
const reducerModules = reducerFiles.keys().map(reducerFiles);
const reducers = reducerModules.reduce((acc, reducer) => {
  Object.entries(reducer.default).forEach(([key, value]) => {
    Object.assign(acc, { [key]: value });
  });
  return acc;
}, {});

// TODO: where should initial state be initialized?
// const initialStateFiles = require.context('.', true, /initialState.js$/);
// const initialStateModules = initialStateFiles.keys().map(initialStateFiles);
// const initialStates = initialStateModules.reduce((acc, state) => {
//   Object.entries(state.default).forEach(([key, value]) => {
//     Object.assign(acc, { [key]: value });
//   });
//   return acc;
// }, {});

const AppReducer = combineReducers({
  ...reducers,
});

export default (state, action) => AppReducer(state, action);

import React from 'react';

import SurveyQuestionCategoryPanel from '../SurveyQuestionCategoryPanel/SurveyQuestionCategoryPanel.component';
import SurveyQuestionTypePanel from '../SurveyQuestionTypePanel/SurveyQuestionTypePanel.component';

const SurveyQuestionsSidebar = () => (
  <>
    <SurveyQuestionCategoryPanel />
    <SurveyQuestionTypePanel />
  </>
);

export default SurveyQuestionsSidebar;

import { StatusCodes } from 'http-status-codes';

import { appConfig } from '../config';
import axios from './axiosInstance';
import {
  getAuthorization,
  handlingResponse,
  logError,
} from './utils';

// TODO: This seems to be unused and no there is no implementation in the backend
// export const updateFormRecognitionRules = ({ clientId, payload }) => axios.put(
//   `/ui/clients/${clientId}/form-recognition`,
//   payload,
//   { headers: { Authorization: getAuthorization() } },
// )
//   .then(handlingResponse([StatusCodes.OK], `Error updating client ${clientId} form recognition rules.`))
//   .catch(logError);

// eslint-disable-next-line import/prefer-default-export
export const addReport = ({ clientId, payload }) => axios.post(
  `${appConfig.backendUrl}/api/v1/clients/${clientId}/report`,
  payload,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], `Error adding report to client ${clientId}.`))
  .catch(logError);

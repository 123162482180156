import { handleActions } from 'redux-actions';

import * as uiActions from './actionCreators';
import initialState from './initialState';

export default {
  ui: handleActions(
    {
      [uiActions.showAlert]: (state, { payload }) => ({
        ...state,
        alert: payload,
      }),
      [uiActions.showNotification]: (state, { payload }) => ({
        ...state,
        notification: payload,
      }),
      [uiActions.setSystemInitialised]: state => ({
        ...state,
        isSystemInitialised: true,
      }),
    },
    initialState.ui,
  ),
};

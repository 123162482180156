const ratingComp = userInfo => {
  const delightedScript = document.createElement('script');

  delightedScript.setAttribute('type', 'text/javascript');

  delightedScript.innerHTML = `!function(e,t,r,n){if(!e[n]){for(var a=e[n]=[],i=["survey","reset","config","init","set","get","event","identify","track","page","screen","group","alias"],s=0;s<i.length;s++){var c=i[s];a[c]=a[c]||function(e){return function(){var t=Array.prototype.slice.call(arguments);a.push([e,t])}}(c)}a.SNIPPET_VERSION="1.0.1";var o=t.createElement("script");o.type="text/javascript",o.async=!0,o.src="https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/"+r+"/"+n+".js";var y=t.getElementsByTagName("script")[0];y.parentNode.insertBefore(o,y)}}(window,document,"zEa7sPyzwHYyLQdO","delighted")
  delighted.survey(${JSON.stringify(userInfo)});`;

  document.body.appendChild(delightedScript);
};

export default ratingComp;

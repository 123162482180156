import PropTypes from 'prop-types';
import React from 'react';

import styles from './Auth.module.scss';

const Auth = ({
  form: Form,
  subtitle: Subtitle,
  title,
  login,
  changePassword,
  requestPassword,
  verifyEmail,
  verifyLogin,
}) => (
  <div className={styles.root}>
    <div className={styles.logo} />
    <div className={styles.card}>
      <div className={styles.title}>
        {title}
      </div>
      <div className={styles.subtitle}>
        <Subtitle />
      </div>
      <div className={styles.formContainer}>
        <Form
          changePassword={ authData => changePassword(authData)}
          login={({ email, password }) => login({ email, password })}
          requestPassword={ ({ email, code, newPassword }) => requestPassword({ email, code, newPassword })}
          verifyEmail={ code => verifyEmail(code)}
          verifyLogin={ code => verifyLogin(code)}
        />
      </div>
      <div className={styles.footer}>
        <span>
          {'Do you have questions or need help?'}
        </span>
        <a
          className={styles.contactUsLink}
          href="http://www.servelegal.co.uk/get-in-touch/"
          rel="noopener noreferrer"
          target="_blank"
        >
          {'Contact Us'}
        </a>
      </div>
    </div>
  </div>
);

Auth.defaultProps = {
  changePassword: () => {},
  login: () => {},
  requestPassword: () => {},
  verifyEmail: () => {},
  verifyLogin: () => {},
};

Auth.propTypes = {
  form: PropTypes.oneOfType([PropTypes.func, PropTypes.elementType]).isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.func, PropTypes.elementType]).isRequired,
  title: PropTypes.string.isRequired,
  changePassword: PropTypes.func,
  login: PropTypes.func,
  requestPassword: PropTypes.func,
  verifyEmail: PropTypes.func,
  verifyLogin: PropTypes.func,
};

export default Auth;

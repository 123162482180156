import { Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Divider, Form, Input, Label } from 'semantic-ui-react';
import * as Yup from 'yup';

import { AuditStatus, Currency, UserRole } from '../../../../../../../../constants';
import { SurveyQuestionsContext } from '../../../../../../../../context';
import FieldStatus from '../../../../../../../../fieldLogic/fieldStatus';
import parseEnumOptions from '../../../../../../../../utils/parseEnumOptions';
import { RoleChecker } from '../../../../../../../layout';
import { validateQuestionValue } from '../../../../questionsHelpers';
import SurveyQuestionConfigHeader from '../SurveyQuestionConfigHeader/SurveyQuestionConfigHeader.component';
import styles from './SurveyCurrencyQuestion.module.scss';

const DATA_CY = 'survey-currency-question';

const SurveyCurrencyQuestion = ({
  auditScreen,
  index,
  status,
  surveyVisibility,
  answer,
  onChange,
}) => {
  let state = FieldStatus.EDITABLE;

  if (surveyVisibility) {
    state = surveyVisibility;
  }

  return (
    <SurveyQuestionsContext.Consumer>
      {({ selectedQuestionIndex }) => (
        selectedQuestionIndex === index
          ? (
            <>
              <SurveyQuestionConfigHeader index={index} />
              <Divider />
              <Field
                name={`questions.${index}.config.suffix`}
                validate={validateQuestionValue(
                  Yup.mixed().oneOf(Object.values(Currency), 'Required'),
                )}
              >
                {({
                  field: { name, value: suffix },
                  form: { setFieldValue },
                  meta: { error },
                }) => (
                  <Form.Select
                    data-cy={`${DATA_CY}-${index}-suffix-select`}
                    error={error}
                    id={name}
                    options={parseEnumOptions(Currency)}
                    value={suffix}
                    onChange={(_e, { value: newValue }) => setFieldValue(name, newValue)}
                  />
                )}
              </Field>
            </>
          ) : (
            <Field name={`questions.${index}`}>
              {({
                field: { value: question },
              }) => (state === FieldStatus.READONLY ? (
                <>
                  <label className={styles.label} data-cy={`${DATA_CY}-${index}-title`}>
                    {question.title}
                  </label>
                  <div>
                    {`${answer || '-'} ${question.config.suffix}`}
                  </div>
                </>
              ) : (
                <>
                  <Form.Field required={question.required}>
                    <label data-cy={`${DATA_CY}-${index}-title`}>
                      {question.title}
                    </label>
                    {!auditScreen ? (
                      <span data-cy={`${DATA_CY}-${index}-auditor-information`}>
                        {question.auditor_information}
                      </span>
                    ) : null}
                    <Input
                      data-cy={`${DATA_CY}-${index}-value`}
                      defaultValue={answer}
                      labelPosition="right"
                      type="number"
                      onChange={(_e, { value }) => (onChange ? onChange(Number.parseFloat(value)) : null)}
                    >
                      <input />
                      <Label>
                        {question.config.suffix}
                      </Label>
                    </Input>
                  </Form.Field>
                  {question.help.trim().length > 0 ? (
                    <span data-cy={`${DATA_CY}-${index}-help`}>
                      {question.help}
                    </span>
                  ) : null}
                  {status === AuditStatus.SUBMITTED || status === AuditStatus.APPROVING_QUERY ? (
                    <RoleChecker allowedRoles={[UserRole.DATA, UserRole.CLIENT_SERVICES, UserRole.AREA_MANAGER]}>
                      <p data-cy={`${DATA_CY}-${index}-approving-information`}>
                        {question.approving_information}
                      </p>
                    </RoleChecker>
                  ) : null}
                </>
              ))}
            </Field>
          )
      )}
    </SurveyQuestionsContext.Consumer>
  );
};

SurveyCurrencyQuestion.defaultProps = {
  answer: null,
  auditScreen: null,
  onChange: null,
  status: null,
  surveyVisibility: null,
};

SurveyCurrencyQuestion.propTypes = {
  index: PropTypes.number.isRequired,
  answer: PropTypes.number,
  auditScreen: PropTypes.bool,
  status: PropTypes.string,
  surveyVisibility: PropTypes.string,
  onChange: PropTypes.func,
};

export default SurveyCurrencyQuestion;

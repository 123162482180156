import React from 'react';
import { Card } from 'semantic-ui-react';

import { AuxiliaryPanel } from '../../../common';
import styles from './LogicDetailsSidebar.module.scss';

const DATA_CY = 'logic-details-sidebar';

const LogicDetailsSidebar = () => (
  <AuxiliaryPanel
    content={
      <Card fluid className={styles.card}>
        <p>
        When a survey is submitted all logic entries are evaluated to determine the
        survey&apos;s result value(s).
        </p>
        <h2>
Logic Evaluation
        </h2>
        <p>
Each logic entry deals with one set of result types which is determined by the
          &quot;Survey Result Type&quot; field. The available result sets are the ones included
          in the &quot;Survey Restult Type&quot; field on the right.
        </p>
        <p>
Each rule within a logic entry is processed, in order, from top to bottom until
          a rule is found which evaluates to true. The survey result is then set to the
          &quot;Result&quot; value for the rule and any further rules within the logic entry
          are ignored. It is important to note that this result value may be overwritten
          by other logic entries that are processed later.
        </p>
        <p>
          Logic entries are processed in the order shown on the logic listing page with their
          position in this list being determined by the &quot;Weight&quot; field. Larger
          &quot;Weight&quot; values result in logic entries appearing lower in the list and
          thus being processed later. Some example &quot;Weight&quot; values are shown below
          in the order they would be processed: -5, 0, 1, 2, 10, 90210.
        </p>
        <h2>
Rule Syntax
        </h2>
        <p>
          Each rule is a self-contained &quot;boolean expression&quot; which is evaluated
          to determine if the rule is either true or false. Some example rules are explained below:
        </p>
        <p className={styles.logicExplanation}>
          <span>
• Q3 = &quot;Yes&quot;
          </span>
          <span>
            True if the value of the question with ID &quot;3&quot; is &quot;Yes&quot;.
          </span>
        </p>
        <p className={styles.logicExplanation}>
          <span>
• Q3 = &quot;Yes&quot; OR Q4 = &quot;No&quot;
          </span>
          <span>
            True if question 3 is &quot;Yes&quot; or if question 4 is &quot;No&quot;.
          </span>
        </p>
        <p className={styles.logicExplanation}>
          <span>
• Q3 = &quot;Yes&quot; OR (Q4 = &quot;No&quot; AND Q5 = &quot;No&quot;)
          </span>
          <span>
            True if question 3 is &quot;Yes&quot; or if both question 4 is
            &quot;No&quot; and question 5 is &quot;No&quot;.
          </span>
        </p>
        <p className={styles.logicExplanation}>
          <span>
• ABORT = &quot;Yes&quot; AND Q1 = &quot;No&quot;
          </span>
          <span>
            True if the visit is set to aborted and question 2 is &quot;No&quot;
          </span>
        </p>
        <p className={styles.logicExplanation}>
          <span>
• Default
          </span>
          <span>
            Always true. This is usually used as the last rule in a set in order to have
            a default result type value i.e. a value that should be set if non of the previous
            rules is true.
          </span>
        </p>
      </Card>
    }
    dataCy={DATA_CY}
    header={<span>
Help
    </span>}
  />
);

export default LogicDetailsSidebar;

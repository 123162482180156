import * as Yup from 'yup';

export const siteValidationSchema = Yup.object().shape({
  address_1: Yup.string().trim().ensure().min(1, 'Required'),
  address_2: Yup.string(),
  address_3: Yup.string(),
  city: Yup.string().trim().ensure().min(1, 'Required'),
  client_id: Yup.string().trim().ensure().min(1, 'Required'),
  code: Yup.string().nullable(),
  country: Yup.string().trim().ensure().min(1, 'Required'),
  county: Yup.string().nullable(),
  directions: Yup.string().nullable(),
  name: Yup.string().trim().ensure().min(1, 'Required'),
  organisation_level_code: Yup.array().of(Yup.object()).max(4),
  post_code: Yup.string().nullable(),
  status: Yup.string().trim().ensure().min(1, 'Required'),
});

export const siteDefaultValues = {
  address_1: '',
  address_2: '',
  address_3: '',
  city: '',
  client_name: '',
  client_id: '',
  code: '',
  country: '',
  county: '',
  directions: '',
  name: '',
  organisation_level_code: [
    { org_level_1: '' },
    { org_level_2: '' },
    { org_level_3: '' },
    { org_level_4: '' },
  ],
  post_code: '',
  status: '',
};

export const siteFilterValidationSchema = Yup.object().shape({
  city: Yup.string(),
  client_id: Yup.string(),
  code: Yup.string(),
  name: Yup.string(),
  post_code: Yup.string(),
});

export const siteFilterDefaultValues = {
  city: '',
  client_id: '',
  code: '',
  name: '',
  post_code: '',
};

import { useRef } from 'react';

import { Entity } from '../constants';

const useEntityRenders = entity => {
  const isFirst = useRef(0);

  if (entity === Entity.AUDITS && isFirst.current <= 3) {
    isFirst.current += 1;
    return 0;
  }

  return isFirst.current;
};

export default useEntityRenders;

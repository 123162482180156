import PropTypes from 'prop-types';
import React from 'react';
import { Icon, List } from 'semantic-ui-react';

import styles from './UploadResult.module.scss';

const UploadResult = ({ data }) => (
  <>
    {data.success?.length === 0 ? null : (
      <div className={styles.responseFirst}>
        <Icon className={styles.success} name="checkmark"/>
        {`${data.success?.length} registers imported succesfully.`}
      </div>
    )}
    {data.failed?.length === 0 ? null : (
      <div className={styles.responseSecond}>
        <Icon className={styles.error} name="warning" />
        {`${data.failed?.length} registers failed while importing.`}
        <List bulleted>
          {data.failed.map((row, index) => (
            <List.Item key={index}>
              {`Line ${row.line + 2} - ${row.error}`}
            </List.Item>
          ))}
        </List>
      </div>
    )}
  </>
);

UploadResult.defaultProps = {
  data: {
    failed: [],
    success: [],
  },
};

UploadResult.propTypes = {
  data: PropTypes.shape({
    failed: PropTypes.arrayOf(PropTypes.object.isRequired),
    success: PropTypes.arrayOf(PropTypes.object.isRequired),
  }),
};

export default UploadResult;

/* eslint-disable react/prop-types */
import cn from 'classnames';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';
import moment from 'moment';
import React from 'react';

import { DateTimeFormat } from '../../../constants';
import timeDiffToString from '../../../utils/timeDiffToString';
import styles from './SLTableCellFormatter.module.scss';

const SLTableCellDateFormatterType = {
  CUSTOM: 'custom',
  DEFAULT: 'default',
  RELATIVE: 'relative',
  STRICT: 'strict',
};

const PriorityThreshold = {
  HIGH: 3,
  MEDIUM: 7,
};

const SLTableCellDateFormatterWithUrgency = ({
  type = SLTableCellDateFormatterType.DEFAULT,
  format = DateTimeFormat.DAY_MONTH_YEAR,
}) => ({ cell }) => {
  const cellDate = cell.value ? moment(cell.value).startOf('day') : null;

  const daysDiff = cellDate ? moment().startOf('day').diff(cellDate, 'days') : null;

  const urgencyClass = cn({
    [styles.primary]: daysDiff !== null && daysDiff >= -PriorityThreshold.HIGH,
    [styles.warning]: daysDiff < -PriorityThreshold.HIGH && daysDiff >= -PriorityThreshold.MEDIUM,
    [styles.secondary]: daysDiff === null || daysDiff < -PriorityThreshold.MEDIUM || daysDiff > 0,
  }, [styles.bold]);

  const text = {
    custom: () => (cell.value ? moment(cell.value).format(format) : ''),
    default: () => cell.value,
    relative: () => (cell.value ? timeDiffToString(cell.value, 'YM') : ''),
    strict: () => (cell.value ? formatDistanceStrict(Date.parse(cell.value), Date.now()) : ''),
  };

  return (
    <span className={urgencyClass}>
      {text[type]()}
    </span>
  );
};

export default SLTableCellDateFormatterWithUrgency;
export { SLTableCellDateFormatterType };

import classNames from 'classnames';
import getCurrencySymbol from 'currency-symbols';
import { Formik } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Button, Form, Grid, Icon, Modal } from 'semantic-ui-react';
import * as Yup from 'yup';

import { entitiesAPI, errorAPI } from '../../../../api';
import { Entity, EntityState } from '../../../../constants';
import { taxonomyTermOptionsSelector } from '../../../../state/constants/selectors';
import {
  FormFieldDateTimeWrapper,
  FormFieldDynamicSearchWrapper,
  FormFieldWrapper,
  SLTable,
  SLTableCellEnumFormatter,
  SLTableCellInputFormatter,
} from '../../../common';
import AuditorTooltip from '../../Auditors/AuditorTooltip/AuditorTooltip.component';
import DirectionsMap from '../../Map/DirectionsMap/DirectionsMap.component';
import styles from './AssignAuditorAndPayModal.module.scss';

const Operations = {
  ADD: { value: 'add', label: '+' },
  SUBTRACT: { value: 'subtract', label: '-' },
};

const columns = ({ itemToOrderOptions }) => [
  {
    accessor: 'site_name',
    label: 'Site',
  },
  {
    accessor: 'item_to_order',
    label: 'Item to order',
    Cell: SLTableCellEnumFormatter({ enumObject: itemToOrderOptions }),
  },
  {
    accessor: 'original_auditor_pay_per_audit',
    label: 'Original pay',
    Cell: ({ cell }) => `${cell.value.toFixed(2)} ${getCurrencySymbol(cell.row.original.currency)}`,
  },
  {
    accessor: 'auditor_pay_per_audit',
    label: 'Adjusted pay',
    Cell: SLTableCellInputFormatter,
  },
];

const itemToOrderSelector = state => taxonomyTermOptionsSelector(state, 'order_item');

const AssignAuditorAndPayModal = ({ open, selectedAudits, onCancel, onConfirm }) => {
  const alert = useAlert();

  const itemToOrderOptions = useSelector(itemToOrderSelector);

  const [filteredAuditors, setFilteredAuditors] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [idAuditor, setIdAuditor] = useState(null);

  const totalAuditorPayPerAudit = selectedAudits.reduce((previousValue, currentValue) => previousValue + currentValue.auditor_pay_per_audit, 0);

  useEffect(() => {
    setInitialValues({
      total_auditor_pay_per_audit: totalAuditorPayPerAudit.toFixed(2),
      total_auditor_new_pay_per_audit: totalAuditorPayPerAudit.toFixed(2),
      adjustment: 0,
      adjustment_operation: Operations.SUBTRACT.value,
      audits: selectedAudits.map(audit => ({
        id: audit.id,
        site_name: audit.site_name,
        item_to_order: audit.item_to_order,
        auditor_pay_per_audit: audit.auditor_pay_per_audit,
        original_auditor_pay_per_audit: audit.auditor_pay_per_audit,
        currency: audit.order_currency,
      })),
    });
  }, [selectedAudits, totalAuditorPayPerAudit]);

  const getNewPay = (adjustment, operation) => (operation === Operations.ADD.value
    ? (totalAuditorPayPerAudit + adjustment).toFixed(2)
    : (totalAuditorPayPerAudit - adjustment).toFixed(2));

  const buildAuditorSearchOptions = auditors => auditors.map(auditor => ({
    description: (
      <AuditorTooltip
        id={auditor.id}
        position={'bottom center'}
        trigger={<Icon circular name="info" size="small" />}
      />
    ),
    text: auditor.name,
    value: auditor.id,
  }));

  const searchAuditors = wildcard => {
    entitiesAPI.fetchAll(Entity.AUDITORS, { name: wildcard, auditor_status: 'active' })
      .then(auditors => setFilteredAuditors(buildAuditorSearchOptions(auditors.items)))
      .catch(error => {
        alert.error(`Error fetching auditors: ${error.message}`);
        errorAPI.sendError(error.message, error.stack);
      });
  };

  const updateAuditorPayPerAudit = (audits, adjustment, operation) => audits.map(audit => ({
    ...audit,
    auditor_pay_per_audit: operation === Operations.ADD.value
      ? audit.original_auditor_pay_per_audit + (adjustment / audits.length)
      : audit.original_auditor_pay_per_audit - (adjustment / audits.length),
  }));

  const { data: auditorData } = useQuery(
    [idAuditor],
    () => entitiesAPI.fetchOne({ entity: Entity.AUDITORS, entityId: idAuditor }),
    { enabled: idAuditor !== null },
  );

  return open ? (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={Yup.object({
        auditor_id: Yup.string().trim().ensure().min(1, 'Required'),
        deadline_date: Yup.date()
          .nullable()
          .min(moment().startOf('day'), 'You can\'t set a deadline date in the past')
          .required('Required'),
      })}
      onSubmit={values => {
        const parsedValues = values.audits.map(audit => ({
          id: audit.id,
          auditor_id: values.auditor_id,
          deadline_date: values.deadline_date,
          auditor_pay_per_audit: audit.auditor_pay_per_audit,
        }));
        onConfirm(parsedValues);
      }}
    >
      {({ handleSubmit, setFieldValue, values }) => {
        const handleAdjustmentOperationChange = operation => {
          setFieldValue('adjustment_operation', operation);
          setFieldValue('audits', updateAuditorPayPerAudit(values.audits, values.adjustment, operation));
        };

        const handleAdjustmentChange = adjustment => {
          setFieldValue('total_auditor_new_pay_per_audit', getNewPay(Number(adjustment), values.adjustment_operation));
          setFieldValue('audits', updateAuditorPayPerAudit(values.audits, adjustment, values.adjustment_operation));
        };

        const handleAuditPayChange = (e, _id, rowIndex) => {
          setFieldValue('audits', values.audits.map((audit, index) => {
            if (index === rowIndex) {
              return ({
                ...audit,
                auditor_pay_per_audit: Number.parseFloat(e.target.value, 10),
              });
            }
            return audit;
          }));
        };

        return (
          <Modal
            closeOnDimmerClick={true}
            open={open}
            onClose={onCancel}
          >
            <Modal.Header>
              {'Batch Assign'}
            </Modal.Header>

            <Modal.Content scrolling>
              <Form>
                <Grid divided columns={2}>
                  <Grid.Row>
                    <Grid.Column>
                      <FormFieldDynamicSearchWrapper
                        required
                        label="Auditor"
                        name="auditor_id"
                        options={filteredAuditors}
                        onChange={e => setIdAuditor(e)}
                        onSearchChange={searchAuditors}
                      />

                      <FormFieldDateTimeWrapper
                        required
                        label="Deadline Date"
                        name="deadline_date"
                        time={false}
                      />

                      <Grid columns={2}>
                        <Grid.Row>
                          <Grid.Column>
                            <FormFieldWrapper
                              readOnly
                              label="Total Original Pay"
                              name="total_auditor_pay_per_audit"
                            />
                            <FormFieldWrapper
                              readOnly
                              label="Total New Pay"
                              name="total_auditor_new_pay_per_audit"
                            />
                          </Grid.Column>

                          <Grid.Column>
                            <>
                              <div className={styles.total}>
                                {'Adjustments'}
                              </div>
                              <div className={styles.radioToolbar}>
                                <Icon
                                  link
                                  className={classNames({
                                    [styles.selectedOperation]:
                                      values.adjustment_operation === Operations.ADD.value,
                                  })}
                                  name="add square"
                                  size="big"
                                  onClick={() => handleAdjustmentOperationChange(Operations.ADD.value)}
                                />

                                <Icon
                                  link
                                  className={classNames({
                                    [styles.selectedOperation]:
                                      values.adjustment_operation === Operations.SUBTRACT.value,
                                  })}
                                  name="minus square"
                                  size="big"
                                  onClick={() => handleAdjustmentOperationChange(Operations.SUBTRACT.value)}
                                />

                                <FormFieldWrapper
                                  className={styles.adjustmentField}
                                  min={0}
                                  name="adjustment"
                                  type="number"
                                  onChange={handleAdjustmentChange}
                                />
                              </div>
                            </>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>

                    <Grid.Column>
                      <DirectionsMap
                        data={selectedAudits}
                        type={EntityState.TABLE}
                        user={auditorData}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>

                <div className={styles.tableContainer}>
                  <SLTable
                    columns={columns({ itemToOrderOptions })}
                    data={values.audits}
                    userData={{
                      onChange: handleAuditPayChange,
                      columnsConfig: {
                        auditor_pay_per_audit: { type: 'number' },
                      },
                    }}
                  />
                </div>
              </Form>
            </Modal.Content>

            <Modal.Actions>
              <Button onClick={onCancel}>
                {'Cancel'}
              </Button>
              <Button primary type="submit" onClick={handleSubmit}>
                {'Confirm'}
              </Button>
            </Modal.Actions>
          </Modal>
        );
      }}
    </Formik>
  ) : null;
};

AssignAuditorAndPayModal.propTypes = {
  open: PropTypes.bool.isRequired,
  selectedAudits: PropTypes.arrayOf(PropTypes.object).isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default AssignAuditorAndPayModal;

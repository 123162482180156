/* eslint-disable react/prop-types */
import React from 'react';
import { Form } from 'semantic-ui-react';

const SLTableCellInputFormatter = ({ cell, userData: { onChange = null, columnsConfig } }) => {
  const config = columnsConfig[cell.column.id];
  return (
    <Form.Input
      {...config}
      name={`${cell.column.id}-${cell.row.id}`}
      value={cell.value}
      onChange={e => (onChange ? onChange(e, cell.column.id, cell.row.index) : null)}
    />
  );
};

export default SLTableCellInputFormatter;

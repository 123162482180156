import PropTypes from 'prop-types';
import React from 'react';

import { AuditsConst } from '../../../../constants';
import { taxonomyType } from '../../../types';
import TaxonomyDetailsAuditTokens from './TaxonomyDetailsAuditTokens/TaxonomyDetailsAuditTokens.component';
import TaxonomyDetailsDocuments from './TaxonomyDetailsDocuments/TaxonomyDetailsDocuments.component';
import TaxonomyDetailsPayRates from './TaxonomyDetailsPayRates/TaxonomyDetailsPayRates.component';
import TaxonomyDetailsSimple from './TaxonomyDetailsSimple/TaxonomyDetailsSimple.component';

const getTaxonomyComponent = vocabulary => {
  const options = {
    documents: TaxonomyDetailsDocuments,
    [AuditsConst.tokenVocabulary]: TaxonomyDetailsAuditTokens,
    pay_rate: TaxonomyDetailsPayRates,
    default: TaxonomyDetailsSimple,
  };

  return options[vocabulary] || options.default;
};

const TaxonomyDetails = props => {
  const TaxonomyComponentToRender = getTaxonomyComponent(props.data.vocabulary);
  return <TaxonomyComponentToRender {...props} />;
};

TaxonomyDetails.propTypes = {
  data: taxonomyType.isRequired,
  submitFormRef: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default TaxonomyDetails;

import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from 'semantic-ui-react';

import styles from './NotificationTemplate.module.scss';

const NotificationTemplate = ({ options, message, close, style }) => (
  <div className={styles.root} style={style}>
    {options.type === 'info' && <Icon name="info" size="big" />}
    <div className={styles.message}>
      <div>
        {message.title}
      </div>
      <div>
        {message.body}
      </div>
    </div>
    <Icon circular className={styles.close} name="close" size="large" onClick={close} />
  </div>
);

NotificationTemplate.propTypes = {
  close: PropTypes.func.isRequired,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  options: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }).isRequired,
  style: PropTypes.object.isRequired,
};

export default NotificationTemplate;

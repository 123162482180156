export const FieldScope = {
  FILTER_VIEW_FIELDS: 'filterViewFields',
  TABLE_VIEW_ACTIONS: 'tableViewActions',
  TABLE_VIEW_COLUMNS: 'tableViewColumns',
};

export const TableViewActions = {
  ADD: 'add',
  EXPORT_EDIT_TEMPLATE: 'exportEditTemplate',
  EXPORT: 'export',
  IMPORT: 'import',
};

import PropTypes from 'prop-types';
import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

import styles from './RegisterAuditorError.module.scss';

const DATA_CY = 'register-congrats';

const RegisterAuditorError = ({ messageFromApi, setRegisterState, setStepNumber }) => (
  <div className={styles.container} data-cy={DATA_CY}>
    <span className={styles.iconError} data-cy={`${DATA_CY}-icon`}></span>
    <h5 className={styles.title}>
      {'OOOPS!'}
    </h5>
    <h5 className={styles.title}>
      {messageFromApi
        ? `${messageFromApi}`
        : 'Looks like something went wrong.'
      }
    </h5>
    <h6 className={styles.subtitle}>
      {'We\'re working on it.'}
    </h6>
    <Button
      primary
      data-cy={`${DATA_CY}-back-button`}
      size="big"
      onClick={() => {
        setStepNumber(0);
        setRegisterState('progress');
      }}
    >
      {'Back to the website'}
      <Icon className={styles.iconArrow} name="right arrow" />
    </Button>
  </div>
);

RegisterAuditorError.defaultProps = {
  messageFromApi: '',
};

RegisterAuditorError.propTypes = {
  setRegisterState: PropTypes.func.isRequired,
  setStepNumber: PropTypes.func.isRequired,
  messageFromApi: PropTypes.string,
};

export default RegisterAuditorError;

import PropTypes from 'prop-types';
import React from 'react';
import { Card, Popup, Table } from 'semantic-ui-react';

import styles from './TokensTable.module.scss';

const DATA_CY = 'order-details-tokens';

const tokens = {
  'Site Name': '[visit-site-name]',
  'Site Address (Full)': '[visit-site-address]',
  'Site Address 1': '[visit-site-address-1]',
  'Site Address 2': '[visit-site-address-2]',
  'Site Address 3': '[visit-site-address-3]',
  'Site County': '[visit-site-county]',
  'Site City': '[visit-site-city]',
  'Site Address Post Code': '[visit-site-postcode]',
  'Site Code': '[visit-site-code]',
  'Site organisation Level Code 1': '[visit-site-org-level-1]',
  'Site organisation Level Code 2': '[visit-site-org-level-2]',
  'Site organisation Level Code 3': '[visit-site-org-level-3]',
  'Site organisation Level Code 4': '[visit-site-org-level-4]',
  'Client Name': '[visit-client-name]',
  'Item to Order': '[visit-item]',
  'Visit Date': '[visit-date]',
  'Visit Time': '[visit-time]',
  'Survey Result': '[visit-survey-result]',
  'Survey Result (Secondary)': '[visit-survey-result-2]',
  'Survey Result (Tertiary)': '[visit-survey-result-3]',
  'Auditor Gender': '[visit-visitor-gender]',
  'Auditor Age': '[visit-visitor-age]',
  'Auditor No. of Visits': '[visit-visitor-visits]',
  'Auditor Pass Rate': '[visit-visitor-pass-rate]',
  'Current Date': '[date]',
  'Survey Score': '[visit-survey-score]',
  'Survey Max Score': '[visit-survey-max-score]',
  'Survey Percentage Score': '[visit-survey-percentage-score]',
};

const sortObjectByKey = obj => Object.keys(obj)
  .sort()
  .reduce((acc, key) => {
    acc[key] = obj[key];
    return acc;
  }, {});

const TokensTable = ({ onClick }) => (
  <Card className={styles.containerTable} data-cy={DATA_CY}>
    <Card.Header className={styles.header} textAlign="center">
      {'Predefined Tokens'}
    </Card.Header>
    <Card.Content extra className={styles.bodyTable}>
      <Table celled basic="very" size="small">
        <Table.Body>
          {Object.entries(sortObjectByKey(tokens)).map(([key, value], index) => (
            <Popup
              key={`tokens-table-${index}`}
              content={'Copy token'}
              position={'top center'}
              size={'small'}
              trigger={
                <Table.Row
                  className={styles.tableRow}
                  onClick={() => onClick && onClick(value)}
                >
                  <Table.Cell key={key} className={styles.leftCell}>
                    {key}
                  </Table.Cell>
                  <Table.Cell className={styles.rightCell}>
                    {value}
                  </Table.Cell>
                </Table.Row>
              }
            />
          ))}
        </Table.Body>
      </Table>
    </Card.Content>
  </Card>
);

TokensTable.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default TokensTable;

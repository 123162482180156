const Action = {
  CREATE: 'create',
  REMOVE: 'remove',
  REMOVE_FOLDER: 'remove',
  RENAME: 'rename',
  REPLACE: 'replace',
};

const MYME_TYPE_MAP = {
  'image/jpeg': 'file',
  'image/gif': 'file',
  'text/html': 'file',
  'video/mp4': 'video',
};

module.exports = {
  Action,
  MYME_TYPE_MAP,
};

import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { entitiesAPI } from '../../../../api';
import { Entity } from '../../../../constants';
import { LoadingPlaceholder } from '../../../common';
import { View } from '../../../layout';
import EntityRevisionDetailsHeader from './EntityRevisionDetailsHeader/EntityRevisionDetailsHeader.component';
import { body, bodyProps } from './helpers';

const EntityRevisionDetailsView = () => {
  const { entity, entityId, revisionId } = useParams();
  const [currentEntity, setCurrentEntity] = useState(entity);

  const { data, isFetching } = useQuery(
    [{ entity, entityId, revisionId }],
    () => entitiesAPI.fetchOneRevision({ entity, entityId, revisionId }),
    { enabled: Boolean(revisionId) },
  );

  useEffect(() => {
    setCurrentEntity(entity);
  }, [entity]);

  return (
    <View
      secondary
      body={revisionId && (isFetching || !data || entity !== currentEntity)
        ? LoadingPlaceholder
        : body({ entity })}
      bodyProps={bodyProps({ entity, entityId, revisionId, data: data?.data })}
      condensed={entity === Entity.AUDITS}
      dataCy={`${entity}-details-view`}
      header={EntityRevisionDetailsHeader}
    />
  );
};

export default EntityRevisionDetailsView;

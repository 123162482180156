import { Field, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import Dropzone from 'react-dropzone';
import { Icon, Image, Label } from 'semantic-ui-react';

import FieldStatus from '../../../fieldLogic/fieldStatus';
import { convertImages } from '../../../utils/imageConversion';
import { getThumbnailURL } from '../../pages/Auditing/helpers';
import FormikErrorMessage from '../FormikErrorMessage/FormikErrorMessage.component';
import FullScreenImageModal from '../FullScreenImageModal/FullScreenImageModal.component';
import styles from './FormFieldDropzoneWrapper.component.module.scss';
import FormFieldTemplate from './Template/FormFieldTemplate.component';

const FormFieldDropzoneWrapper = ({
  accept,
  dataCy,
  help,
  inline,
  label,
  labelStyle,
  labelTooltip,
  name,
  onStateHandler,
  required,
  maxFiles,
  onDropFiles,
  onRemoveFile,
}) => {
  const alert = useAlert();
  const [modalImgSrc, setModalImgSrc] = useState(null);
  const { errors } = useFormikContext();

  const dataCyPrefix = dataCy || name;

  let state = FieldStatus.EDITABLE;

  if (onStateHandler) {
    state = onStateHandler(name);
  }

  if (state === FieldStatus.HIDDEN) {
    return null;
  }

  const isReadOnly = state === FieldStatus.READONLY;

  return (
    <FormFieldTemplate
      dataCy={dataCyPrefix}
      help={help}
      inline={inline}
      label={label}
      labelStyle={labelStyle}
      labelTooltip={labelTooltip}
      required={required}
    >
      <Field name={name}>
        {({
          field: { value: fieldImageUrls },
          form: { setFieldValue },
        }) => (
          <>
            {!isReadOnly && (
              <Dropzone
                multiple
                accept={accept}
                onDrop={async acceptedFiles => {
                  if (maxFiles && maxFiles === acceptedFiles.length) {
                    alert.error(`You have exceeded the maximum number of files: ${maxFiles}`);
                    return;
                  }
                  const dropFiles = await Promise.all(acceptedFiles.map(async file => ({
                    file: await convertImages(file),
                    url: await getThumbnailURL(file),
                  })));
                  const imagesUrls = [...fieldImageUrls, ...dropFiles.map(({ url }) => url)];
                  setFieldValue(name, imagesUrls);
                  if (onDropFiles) onDropFiles(dropFiles);
                }}
                onDropRejected={fileRejections => {
                  alert.error(`Error uploading file: ${fileRejections[0].file.name} - ${fileRejections[0].errors[0].message}`);
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: styles.dropZone })}>
                    <input {...getInputProps()} />
                    <span className={styles.imageIcon} />
                    <p>
                      {'Drop your images here, or '}
                      <span>
                        {'browse.'}
                      </span>
                    </p>
                  </div>

                )}
              </Dropzone>
            )}

            <div className={styles.thumb}>
              {fieldImageUrls.map((imageUrl, imageIndex) => (
                <div key={imageIndex} className="ui image">
                  {!isReadOnly && (
                    <Label
                      as="a"
                      corner="right"
                      size="small"
                      onClick={() => {
                        const newImages = fieldImageUrls.filter((_, index) => index !== imageIndex);
                        setFieldValue(name, newImages);
                        if (onRemoveFile) onRemoveFile({ index: imageIndex, url: imageUrl });
                      }}
                    >
                      <Icon className={styles.icon} name="trash"/>
                    </Label>
                  )}
                  <Image
                    className={styles.img}
                    size="small"
                    src={imageUrl}
                    onClick={() => setModalImgSrc(imageUrl)}
                  />
                  <FullScreenImageModal
                    imgSrc={modalImgSrc}
                    onClose={() => setModalImgSrc(null)}
                  />
                </div>
              ))}
            </div>
            {errors[name] && (
              <FormikErrorMessage>
                {errors[name]}
              </FormikErrorMessage>
            )}
          </>
        )}
      </Field>
    </FormFieldTemplate>
  );
};

FormFieldDropzoneWrapper.defaultProps = {
  accept: 'image/*',
  dataCy: null,
  help: null,
  inline: false,
  labelStyle: null,
  labelTooltip: null,
  maxFiles: null,
  onDropFiles: null,
  onRemoveFile: null,
  onStateHandler: null,
  readOnly: false,
  required: false,
};

FormFieldDropzoneWrapper.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  accept: PropTypes.string,
  dataCy: PropTypes.string,
  help: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  inline: PropTypes.bool,
  labelStyle: PropTypes.string,
  labelTooltip: PropTypes.string,
  maxFiles: PropTypes.number,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  onDropFiles: PropTypes.func,
  onRemoveFile: PropTypes.func,
  onStateHandler: PropTypes.func,
};

export default FormFieldDropzoneWrapper;

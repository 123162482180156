import { Auth } from 'aws-amplify';
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { jwtDecode } from 'jwt-decode';

// import { USER_INACTIVITY_MINUTES } from '../constants';
import { setAccess } from '../persistence/access';

let singleton = null;
// let timeoutId = null;

const refreshAuthLogic = async failedRequest => {
  if (failedRequest.response
    && failedRequest.response.data
    && failedRequest.response.data.extra === 'ERR_JWT_EXPIRED') {
    return Auth.currentSession()
      .then(tokens => {
        setAccess(tokens.idToken);
        failedRequest.config.headers.Authorization = `Bearer ${tokens.idToken.jwtToken}`;
        return axios.request(failedRequest.config);
      })
      .catch(async () => Auth.signOut());
  }

  return Promise.resolve();
};

const refreshTokenPreRequestInterceptor = async config => {
  const currentToken = config.headers.Authorization;

  if (currentToken) {
    try {
      const decodedToken = jwtDecode(currentToken);
      const expiration = decodedToken.exp * 1000;
      const now = new Date().getTime();

      if (expiration < now) {
        const tokens = await Auth.currentSession();
        setAccess(tokens.idToken);
        config.headers.Authorization = `Bearer ${tokens.idToken.jwtToken}`;
      }
    } catch (e) {
      // Let the response interceptor do the refresh if a 401 is received
    }
  }

  return config;
};

const addInterceptors = () => {
  // FIXME: [SL-116] this solution doesn't work for multiple tabs
  // singleton.interceptors.request.use(req => {
  //   // console.log(`[REQUEST] ${req.method} ${req.url}`);

  //   if (timeoutId) {
  //     clearTimeout(timeoutId);
  //   }
  //   timeoutId = setTimeout(Auth.signOut, USER_INACTIVITY_MINUTES * 60 * 1000);
  //   return req;
  // });

  // Instantiate the interceptor (you can chain it as it returns the axios instance)
  createAuthRefreshInterceptor(singleton, refreshAuthLogic);
  singleton.interceptors.request.use(
    refreshTokenPreRequestInterceptor,
    error =>
      // Let the response interceptor do the refresh if a 401 is received
      Promise.resolve(error.config)
    ,
  );
};

const axiosInstance = () => {
  if (!singleton) {
    singleton = axios.create({});
    addInterceptors();
  }
  return singleton;
};

export default axiosInstance();

import { FieldArray, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useQuery } from 'react-query';
import { Button, Card, Divider, Form, Grid, Icon } from 'semantic-ui-react';

import { entitiesAPI, errorAPI } from '../../../../../../api';
import { Entity } from '../../../../../../constants';
import { FormFieldDynamicSearchWrapper } from '../../../../../common';
import { logicValidationSchema } from '../helpers';
import styles from './SurveyLogicEditor.module.scss';

const DATA_CY = 'survey-logic-editor';

const SurveyLogicEditor = ({ logic, submitFormRef, onSubmit }) => {
  const alert = useAlert();

  const { data: allLogics } = useQuery(
    [Entity.LOGICS],
    () => entitiesAPI.fetchAll(Entity.LOGICS),
  );

  const buildLogicSearchOptions = logics => logics?.map(logicItem => ({
    text: logicItem.name,
    value: logicItem.id,
  }));

  const [filteredLogics, setFilteredLogics] = useState(buildLogicSearchOptions(allLogics?.items) || []);

  useEffect(() => {
    setFilteredLogics(buildLogicSearchOptions(allLogics?.items));
  }, [allLogics]);

  const searchLogics = name => {
    entitiesAPI.fetchAll(Entity.LOGICS, { name })
      .then(logics => {
        setFilteredLogics(buildLogicSearchOptions(logics.items));
      })
      .catch(error => {
        alert.error(`Error fetching logics: ${error.message}`);
        errorAPI.sendError(error.message, error.stack);
      });
  };

  return (
    <div data-cy={DATA_CY}>
      <p data-cy={`${DATA_CY}-header`}>
        {'When a survey is submitted all logic entries are evaluated to determine the survey&apos; result value(s).'}
      </p>
      <Card fluid>
        <Card.Content className={styles.scroll}>
          <Formik
            initialValues={{ logic }}
            validationSchema={logicValidationSchema}
            onSubmit={onSubmit}
          >
            {({ values, handleSubmit, isValid, errors }) => {
              const submit = () => {
                if (!isValid) {
                  alert.error(
                    <pre>
                      {JSON.stringify(errors, null, 2)}
                    </pre>,
                  );
                } else {
                  handleSubmit();
                }
              };
              return (
                <Form noValidate>
                  <button ref={submitFormRef} hidden type="submit" onClick={submit} />
                  <FieldArray name="logic">
                    {arrayHelpers => (
                      <>
                        <Grid>
                          {values.logic.map((_logicValue, logicIndex) => (
                            <Grid.Row key={logicIndex}>
                              <Grid.Column width={15}>
                                <FormFieldDynamicSearchWrapper
                                  required
                                  label="Logic"
                                  name={`logic.${logicIndex}.id`}
                                  options={filteredLogics}
                                  onSearchChange={searchLogics}
                                />
                              </Grid.Column>
                              <Grid.Column verticalAlign="bottom" width={1}>
                                <Icon
                                  className={styles.remove}
                                  data-cy={`${DATA_CY}-logic-remove-icon-${logicIndex}`}
                                  onClick={() => arrayHelpers.remove(logicIndex)}
                                />
                              </Grid.Column>
                            </Grid.Row>
                          ))}
                        </Grid>

                        <Divider />
                        <Button
                          primary
                          data-cy={`${DATA_CY}-add-logic-button`}
                          onClick={() => arrayHelpers.push({ id: '' })}
                        >
                          {'Add logic'}
                        </Button>
                      </>
                    )}
                  </FieldArray>
                </Form>
              );
            }}
          </Formik>
        </Card.Content>
      </Card>
    </div>
  );
};

SurveyLogicEditor.defaultProps = {
  logic: [],
};

SurveyLogicEditor.propTypes = {
  submitFormRef: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  logic: PropTypes.arrayOf(PropTypes.object),
};

export default SurveyLogicEditor;

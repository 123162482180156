import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { Button, Form } from 'semantic-ui-react';

import FieldStatus from '../../../fieldLogic/fieldStatus';
import FormFieldTemplate from './Template/FormFieldTemplate.component';

const FormFieldFileWrapper = ({
  accept,
  dataCy,
  help,
  inline,
  label,
  labelTooltip,
  name,
  onStateHandler,
  readOnly,
  required,
  customIcon,
  ...rest
}) => {
  const fileInputRef = useRef(null);
  const [field, meta, helpers] = useField(name);

  const dataCyPrefix = dataCy || name;

  let state = FieldStatus.EDITABLE;

  if (onStateHandler) {
    state = onStateHandler(name);
  }

  if (state === FieldStatus.HIDDEN) {
    return null;
  }

  const readOnlyField = () => (<>
    {field.value}
  </>
  );

  const editableField = () => (
    <>
      <input
        ref={fileInputRef}
        hidden
        accept={accept}
        type="file"
        onChange={e => helpers.setValue(e.target.files?.[0] || null)}
      />
      <Form.Input
        readOnly
        action={
          <Button
            data-cy={`${dataCyPrefix}-button`}
            onClick={() => (field.value
              ? helpers.setValue(null)
              : fileInputRef.current.click())
            }
          >
            { customIcon ? null : field.value ? 'Clear' : 'Upload'}
          </Button>
        }
        data-cy={`${dataCyPrefix}-field`}
        error={meta.error}
        placeholder="Select file..."
        required={required}
        value={field.value?.name || field.value || ''}
        {...rest}
      />
    </>
  );

  return (
    <FormFieldTemplate
      dataCy={dataCyPrefix}
      help={help}
      inline={inline}
      label={label}
      labelTooltip={labelTooltip}
      required={required}
    >
      {state === FieldStatus.READONLY || readOnly ? readOnlyField() : editableField()}
    </FormFieldTemplate>
  );
};

FormFieldFileWrapper.defaultProps = {
  customIcon: false,
  dataCy: null,
  help: null,
  inline: false,
  labelTooltip: null,
  onStateHandler: null,
  readOnly: false,
  required: false,
};

FormFieldFileWrapper.propTypes = {
  accept: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  customIcon: PropTypes.bool,
  dataCy: PropTypes.string,
  help: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  inline: PropTypes.bool,
  labelTooltip: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  onStateHandler: PropTypes.func,
};

export default FormFieldFileWrapper;

import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

import { SLHeader } from '../../../../common';

const EntityRevisionsCompareHeader = () => {
  const { entity, entityId } = useParams();

  const DATA_CY = `${entity}-revisions-compare-header`;

  return (
    <SLHeader
      dataCy={DATA_CY}
      title={'Compare revisions'}
    >
      <Link to={`/${entity}/${entityId}/revisions`}>
        <Button>
          {'Back'}
        </Button>
      </Link>
    </SLHeader>
  );
};

export default EntityRevisionsCompareHeader;

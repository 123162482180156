import { Auth } from 'aws-amplify';
import classnames from 'classnames';
import { useFeatureFlag } from 'configcat-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

import { legalAPI, notificationAPI, promptsAPI } from '../../../api';
import { UserRole } from '../../../constants';
import { useUserRole } from '../../../hooks';
import DashboardRouter from '../../../routes/DashboardRouter';
import DeveloperRouter from '../../../routes/DeveloperRouter';
import { clearAllAudits } from '../../../state/audits/actionCreators';
import { offlineAuditsSelector } from '../../../state/audits/selectors';
import { fetchUserInfo } from '../../../state/constants/actionCreators';
import { userInfoSelector, userPromptsSelector } from '../../../state/constants/selectors';
import { deviceSelector } from '../../../state/device/selectors';
import isMobile from '../../../utils/isMobile';
import ratingScript from '../../../utils/ratingScript';
import handleValidationModal from '../../../utils/singInValidationMessage';
import GenericModal from '../../common/GernericModal/GenericModal.component';
import { Navbar, RoleChecker } from '../../layout';
import AuditBackgroundSender from '../Audits/AuditBackgroundSender/AuditBackgroundSender.component';
import styles from './Dashboard.module.scss';
import DashboardEthnicityModal from './DashboardEthnicityModal/DashboardEthnicityModal.component';
import DashboardGDPRModal from './DashboardGDPRModal/DashboardGDPRModal.component';
import DashboardLegalModal from './DashboardLegalModal/DashboardLegalModal.component';
import DashboardPrivacyModal from './DashboardPrivacyModal/DashboardPrivacyModal.component';
import DashboardTermAddressModal from './DashboardTermAddressModal/DashboardTermAddressModal.component';
import { hasTermAddress } from './helpers';

const promptModals = {
  GDPR: DashboardGDPRModal,
  ethnicity: DashboardEthnicityModal,
};

// TODO: we should move auditor's specific logic out of the Dashboard
const Dashboard = () => {
  const alert = useAlert();
  const role = useUserRole();
  const { push } = useHistory();
  const dispatch = useDispatch();
  const { token } = useSelector(deviceSelector);
  const [logged, setLogged] = useState(false);
  const [promptModal, setPromptModal] = useState(null);
  const userInfo = useSelector(userInfoSelector);
  const userPrompts = useSelector(userPromptsSelector);
  const { value: mfa4amcs } = useFeatureFlag('mfa4amcs', false);

  const allAuditSelector = state => offlineAuditsSelector(state);
  const offlineAudits = useSelector(allAuditSelector);
  const [validationMessage, setValidationMessage] = useState(null);

  const refreshPage = () => window.location.reload();
  const location = useLocation();
  const currentPath = location.pathname;

  const fetchUserInfoCallback = useCallback(
    () => dispatch(fetchUserInfo({ role })),
    [dispatch, role],
  );

  const clearAllAuditsCallback = useCallback(
    () => {
      if (role !== UserRole.AUDITOR
        || !offlineAudits.filter(({ original }) => original?.auditor_id === userInfo.id).length) {
        dispatch(clearAllAudits());
      }
    },
    [dispatch, offlineAudits, role, userInfo],
  );

  const isLogged = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      setLogged(true);
    } catch (error) {
      setLogged(false);
    }
  };

  useEffect(() => {
    isLogged();
    const userInfoRating = {
      email: userInfo?.email,
      name: userInfo?.first_name,
      properties: { auditorId: userInfo?.id },
    };

    if (userInfo?.auditor_all_visit_count > 10) ratingScript(userInfoRating);
  }, [userInfo]);

  useEffect(() => {
    if (Object.keys(userInfo).length !== 0 && currentPath !== '/account') {
      const validationModalMessage = handleValidationModal({
        user: userInfo,
        role,
        mfa4amcs,
      });
      if (validationModalMessage) {
        setValidationMessage(validationModalMessage);
      }
    }
  }, [userInfo, role, currentPath, mfa4amcs]);

  useEffect(() => {
    fetchUserInfoCallback({ role });
  }, [fetchUserInfoCallback, role]);

  useEffect(() => {
    clearAllAuditsCallback();
  }, [clearAllAuditsCallback]);

  useEffect(() => {
    if (token && userInfo?.id && !userInfo.role) {
      notificationAPI.storeDeviceToken({
        payload: {
          token,
        },
      });
    }
  }, [token, userInfo]);

  const closeValidationModal = () => {
    setValidationMessage(null);
    push('/account');
  };

  const { mutate: acceptTermsAndConditionsMutation } = useMutation(legalAPI.acceptLegal, {
    onSuccess: () => {
      alert.success('Terms & Conditions accepted');
      refreshPage();
    },
    onError: error => {
      alert.error(`Error accepting Terms & Conditions: ${error.message}`);
      refreshPage();
    },
  });

  const { mutate: acceptPrivacyMutation } = useMutation(legalAPI.acceptPrivacy, {
    onSuccess: () => {
      alert.success('Privacy Notice accepted');
      refreshPage();
    },
    onError: error => {
      alert.error(`Error accepting Privacy Notice: ${error.message}`);
      refreshPage();
    },
  });

  const { mutate: storePromptReadMutation } = useMutation(promptsAPI.storePromptRead, {
    onSuccess: () => {
      alert.success('Storing prompt as read by user');
      refreshPage();
    },
    onError: error => {
      alert.error(`Error marking prompt as read by user: ${error.message}`);
      refreshPage();
    },
  });

  useEffect(() => {
    let nextPromptToShow = userPrompts?.find(p => promptModals[p.name]);

    if (userInfo.id && !hasTermAddress(userInfo)) {
      promptModals.termAddress = DashboardTermAddressModal;
      nextPromptToShow = userPrompts?.find(p => promptModals[p.name]);
    }

    if (!nextPromptToShow) {
      setPromptModal(null);
      return;
    }

    const Comp = promptModals[nextPromptToShow.name];
    setPromptModal(
      <Comp key={nextPromptToShow.id}
        userInfo={userInfo}
        onAccept={() => storePromptReadMutation(nextPromptToShow.id)}
      />,
    );
  }, [storePromptReadMutation, userInfo, userPrompts]);

  if (!role && logged) {
    return (
      <div className={styles.root}>
        <div className={styles.card}>
          <div className={styles.title}>
            {'Role not allowed!'}
          </div>
          <Button onClick={Auth.signOut}>
            {'Sign-Out'}
          </Button>
        </div>
      </div>
    );
  }

  if (!role && !logged) {
    return (
      <Redirect to={'/login'}/>
    );
  }

  return (
    <div className={classnames({ [styles.root]: !isMobile, [styles.rootMobile]: isMobile })} id="modal-root">
      <RoleChecker allowedRoles={[UserRole.AUDITOR]}>
        <DashboardLegalModal
          onAccept={acceptTermsAndConditionsMutation}
          onCancel={Auth.signOut}
        />
      </RoleChecker>

      <>
        {promptModal}
        <RoleChecker allowedRoles={[UserRole.AUDITOR]}>
          <DashboardPrivacyModal
            onAccept={acceptPrivacyMutation}
            onCancel={Auth.signOut}
          />
        </RoleChecker>
      </>

      <GenericModal
        buttons={[{ label: 'Accept', func: closeValidationModal }]}
        open={validationMessage !== null}
        onDismiss={closeValidationModal}
      >
        <h4>
          {validationMessage}
        </h4>
      </GenericModal>

      <Navbar />

      <div className={styles.router}>
        {role === 'developer'
          ? <DeveloperRouter />
          : (
            <>
              <RoleChecker allowedRoles={[UserRole.AUDITOR]}>
                <AuditBackgroundSender />
              </RoleChecker>
              <DashboardRouter role={role} />
            </>
          )
        }
      </div>
    </div>
  );
};

export default Dashboard;

import PropTypes from 'prop-types';
import React from 'react';

import styles from './RegisterAuditorFormLayout.module.scss';

const RegisterAuditorFormLayout = ({ title, subtitle, children }) => (
  <>
    <div className={styles.subtitle}>
      <span className={styles.title}>
        {title}
      </span>
      <span className={styles.number}>
        {subtitle}
      </span>
    </div>
    <div className={styles.form}>
      {children}
    </div>
  </>
);

RegisterAuditorFormLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  subtitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  title: PropTypes.string.isRequired,
};

export default RegisterAuditorFormLayout;

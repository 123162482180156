import _pick from 'lodash/pick';

import createFlattenedArray from '../../../utils/createFlattenedArray';

const transferredValues = ['text', 'value', 'disabled', 'nested'];

const TaxonomyTermOptions = taxonomyTerm => {
  const items = createFlattenedArray(taxonomyTerm);
  const itemsFiltered = items.filter(item => item.disabled !== true);

  return itemsFiltered.map(item => {
    if (item.depth === 0) {
      return _pick(item, transferredValues);
    }

    item.nested = 'true';
    return _pick(item, transferredValues);
  });
};

export default TaxonomyTermOptions;

import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { entitiesAPI } from '../../../../api';
import { AuditingSteps, Entity } from '../../../../constants';
import { auditSelector } from '../../../../state/audits/selectors';
import isOnline from '../../../../utils/connection';
import { View } from '../../../layout';
import AuditingHeader from '../AuditingHeader/AuditingHeader.component';
import AuditingStep from '../AuditingStep/AuditingStep.component';

const AuditingView = () => {
  const { entityId } = useParams();
  const online = isOnline();

  const storedAuditSelector = state => auditSelector(state, entityId);
  const audit = useSelector(storedAuditSelector);

  const { data, isFetching } = useQuery(
    [Entity.AUDITS, entityId],
    () => (entitiesAPI.fetchOne({ entity: Entity.AUDITS, entityId })),
    { enabled: Boolean(entityId) && online, refetchOnWindowFocus: true },
  );

  useEffect(() => {
    if (data && !isFetching && online && audit) {
      audit.original = data;
    }
  }, [data, audit, isFetching, online]);

  const getHeaderProps = fetchedAudit => {
    if (!fetchedAudit) return null;

    if (![AuditingSteps.OFFLINE_FINISH, AuditingSteps.SUBMITTED].includes(fetchedAudit.step)) return fetchedAudit;

    return null;
  };

  return (
    <View
      secondary
      body={AuditingStep}
      bodyProps={{ audit }}
      header={AuditingHeader}
      headerProps={{ audit: getHeaderProps(audit) }}
    />
  );
};

export default AuditingView;

import { UserRole } from '../../constants';
import { TableViewActions } from '../helpers';

export const OrderFilterFields = {
  clientName: 'client_name',
  scheduleType: 'schedule_type',
  itemToOrder: 'item_to_order',
  surveyTitle: 'survey_title',
  status: 'status',
};

const tableViewActions = {
  [UserRole.CLIENT_SERVICES]: [
    TableViewActions.ADD,
    TableViewActions.EXPORT,
    TableViewActions.IMPORT,
  ],
  [UserRole.DATA]: [
    TableViewActions.ADD,
    TableViewActions.EXPORT_EDIT_TEMPLATE,
    TableViewActions.EXPORT,
    TableViewActions.IMPORT,
  ],
};

const filterViewFields = {
  [UserRole.CLIENT]: [
    OrderFilterFields.scheduleType,
    OrderFilterFields.status,
  ],
  [UserRole.CLIENT_SERVICES]: [
    OrderFilterFields.clientName,
    OrderFilterFields.scheduleType,
    OrderFilterFields.itemToOrder,
    OrderFilterFields.surveyTitle,
    OrderFilterFields.status,
  ],
  [UserRole.DATA]: [
    OrderFilterFields.clientName,
    OrderFilterFields.scheduleType,
    OrderFilterFields.itemToOrder,
    OrderFilterFields.surveyTitle,
    OrderFilterFields.status,
  ],
};

export default {
  orders: {
    tableViewActions,
    filterViewFields,
  },
};

import React from 'react';
import { Link, useParams } from 'react-router-dom';

import { SLHeader } from '../../../../common';
import styles from './EntityUploadHeader.module.scss';

const EntityUploadHeader = () => {
  const { entity } = useParams();
  const DATA_CY = `${entity}-upload-header`;

  return (
    <SLHeader
      dataCy={DATA_CY}
      title={`Upload ${entity}`}
    >
      <Link data-cy={`${DATA_CY}-back-link`} to={`/${entity}`}>
        <span className={styles.back}>
          {`Back to ${entity}`}
        </span>
      </Link>
    </SLHeader>
  );
};

export default EntityUploadHeader;

import classnames from 'classnames';
import { Field, useField } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'semantic-ui-react';

import { DateTimeFormat, QuestionType } from '../../../constants';
import FieldStatus from '../../../fieldLogic/fieldStatus';
import timeDiffToString from '../../../utils/timeDiffToString';
import DatePicker from '../DatePicker/DatePicker.component';
import FormikErrorMessage from '../FormikErrorMessage/FormikErrorMessage.component';
import FormFieldTemplate from './Template/FormFieldTemplate.component';

const FormFieldDatePickerWrapper = ({
  dataCy,
  help,
  inline,
  label,
  labelStyle,
  labelTooltip,
  max,
  min,
  name,
  onChange,
  onStateHandler,
  readOnly,
  required,
  timeToStringFormat,
  valueStyle,
  type,
  ...rest
}) => {
  const [field, meta, helpers] = useField(name);

  const dataCyPrefix = dataCy || name;

  let state = FieldStatus.EDITABLE;

  if (onStateHandler) {
    state = onStateHandler(name);
  }

  if (state === FieldStatus.HIDDEN) {
    return null;
  }

  const displayFormats = {
    [QuestionType.TIME]: DateTimeFormat.HOUR_MINUTES_24H,
    [QuestionType.DATE]: DateTimeFormat.DAY_MONTH_YEAR,
    [QuestionType.DATETIME]: DateTimeFormat.DAY_MONTH_YEAR_HOUR_MINUTES_24H,
  };

  const readOnlyField = () => (
    <span className={classnames({ [valueStyle]: valueStyle })}>
      {timeToStringFormat
        ? timeDiffToString(field.value, timeToStringFormat)
        : moment(field.value).format(displayFormats[type])
      }
    </span>
  );

  const editableField = () => (
    <Form.Field required={required} {...rest}>
      <Field
        as={DatePicker}
        data-cy={`${dataCyPrefix}-field`}
        max={max}
        min={min}
        name={name}
        required={required}
        type={type}
        value={field.value ? new Date(field.value) : null}
        onChange={value => {
          const update = newValue => {
            helpers.setValue(newValue);
            if (onChange) {
              onChange(newValue);
            }
          };

          if (!value) {
            return update(null);
          }

          return update(value);
        }}
      />
      {meta.error ? (
        <FormikErrorMessage>
          {meta.error}
        </FormikErrorMessage>
      ) : null}
    </Form.Field>
  );

  return (
    <FormFieldTemplate
      dataCy={dataCyPrefix}
      help={help}
      inline={inline}
      label={label}
      labelStyle={labelStyle}
      labelTooltip={labelTooltip}
      required={required}
    >
      {state === FieldStatus.READONLY || readOnly ? readOnlyField() : editableField()}
    </FormFieldTemplate>
  );
};

FormFieldDatePickerWrapper.defaultProps = {
  dataCy: null,
  help: null,
  inline: false,
  label: null,
  labelStyle: null,
  labelTooltip: null,
  max: undefined,
  min: undefined,
  onChange: null,
  onStateHandler: null,
  readOnly: false,
  required: false,
  timeToStringFormat: null,
  type: null,
  valueStyle: null,
};

FormFieldDatePickerWrapper.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf([QuestionType.TIME, QuestionType.DATE, QuestionType.DATETIME]).isRequired,
  dataCy: PropTypes.string,
  help: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  inline: PropTypes.bool,
  label: PropTypes.string,
  labelStyle: PropTypes.string,
  labelTooltip: PropTypes.string,
  max: PropTypes.instanceOf(Date),
  min: PropTypes.instanceOf(Date),
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  timeToStringFormat: PropTypes.string,
  valueStyle: PropTypes.string,
  onChange: PropTypes.func,
  onStateHandler: PropTypes.func,
};

export default FormFieldDatePickerWrapper;

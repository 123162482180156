import PropTypes from 'prop-types';
import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

const WaitPlaceholder = ({ children }) => (
  <Dimmer active inverted>
    <Loader size="large">
      {children}
    </Loader>
  </Dimmer>
);

WaitPlaceholder.defaultProps = {
  children: 'Wait, please ...',
};

WaitPlaceholder.propTypes = {
  children: PropTypes.any,
};

export default WaitPlaceholder;

import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form } from 'semantic-ui-react';
import * as Yup from 'yup';

import styles from './ChangePasswordForm.module.scss';

const DATA_CY = 'request-password-form';

const defaultValues = {
  email: '',
  oldPassword: '',
  newPassword: '',
  newPasswordConfirmation: '',
};

const ChangePasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  newPassword: Yup.string()
    .matches(/^(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/, {
      message: 'Incorrect format - min 8 characters, 1 uppercase, 1 lowercase and 1 symbol',
      excludeEmptyString: false,
    })
    .required('Required'),
  newPasswordConfirmation: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'Passwords must match')
    .required('Required'),
  oldPassword: Yup.string()
    .matches(/^(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/, {
      message: 'Incorrect format - min 8 characters, 1 uppercase, 1 lowercase and 1 symbol',
      excludeEmptyString: false,
    })
    .required('Required'),
});

const ChangePasswordForm = ({ changePassword }) => (
  <Formik
    initialValues={defaultValues}
    validationSchema={ChangePasswordSchema}
    onSubmit={async (values, formikBag) => {
      await changePassword(values);
      formikBag.resetForm(defaultValues);
    }}
  >
    {({ values, handleChange, errors, touched, handleSubmit }) => (
      <Form noValidate className={styles.root} data-cy={DATA_CY} onSubmit={handleSubmit}>
        <Form.Input
          className={styles.emailInput}
          data-cy={`${DATA_CY}-email-input`}
          error={errors.email && touched.email ? errors.email : null}
          id="email"
          name="email"
          placeholder="Your Email"
          type="email"
          value={values.email}
          onChange={handleChange}
        />
        <Form.Input
          className={styles.emailInput}
          data-cy={`${DATA_CY}-oldPassword-input`}
          error={errors.oldPassword && touched.oldPassword ? errors.oldPassword : null}
          id="oldPassword"
          name="oldPassword"
          placeholder="Current Password"
          type="password"
          value={values.oldPassword}
          onChange={handleChange}
        />
        <Form.Input
          className={styles.emailInput}
          data-cy={`${DATA_CY}-newPassword-input`}
          error={errors.newPassword && touched.newPassword ? errors.newPassword : null}
          id="newPassword"
          name="newPassword"
          placeholder="New Password"
          type="password"
          value={values.newPassword}
          onChange={handleChange}
        />
        <Form.Input
          className={styles.emailInput}
          data-cy={`${DATA_CY}-newPassword_confirm-input`}
          error={errors.newPasswordConfirmation
            && touched.newPasswordConfirmation
            ? errors.newPasswordConfirmation : null}
          id="newPassword_confirm"
          name="newPasswordConfirmation"
          placeholder="Confirm new password"
          type="password"
          value={values.newPasswordConfirmation}
          onChange={handleChange}
        />
        <Button
          primary
          data-cy={`${DATA_CY}-submit-button`}
          size="big"
          type="submit"
        >
          {'Change Password'}
        </Button>
      </Form>
    )}
  </Formik>
);

ChangePasswordForm.propTypes = {
  changePassword: PropTypes.func.isRequired,
};

export default ChangePasswordForm;

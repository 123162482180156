import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Button, Card, Grid } from 'semantic-ui-react';

import { archivesAPI } from '../../../../api';
import { Entity } from '../../../../constants';
import history from '../../../../history';
import { SLHeader } from '../../../common';
import styles from './ArchivesDetails.module.scss';

const DATA_CY = 'archives-details';

const ArchivesDetails = () => {
  const { siteId, year, month, day, legacyId } = useParams();
  const { data } = useQuery(
    [Entity.ARCHIVES],
    () => archivesAPI.fetchArchive({ siteId, year, month, day, legacyId }),
  );

  return (
    <div className={styles.root}>
      <Card fluid className={styles.card}>
        <Card.Content
          extra
        >
          <Card.Header>
            <SLHeader title={'Archive Details'}>
              <Button
                data-cy={`${DATA_CY}-cancel-button`}
                onClick={() => { history.push('/archives'); }}
              >
                {'Cancel'}
              </Button>
            </SLHeader>
          </Card.Header>
        </Card.Content>
        <Card.Content className={styles.cardContent}>
          <Grid
            className={styles.grid}
            columns={2}
            data-cy={DATA_CY}
          >
            <Grid.Column className={styles.questionsColumn}>
              <div className={styles.header}>
                {'Survey Answers'}
              </div>
              <div className={styles.scrollable}>
                {data?.questions?.map((item, index) => (
                  <div key={index} className={styles.contentData}>
                    <span>
                      {item.question}
                    </span>
                    <span>
                      {item.answer}
                    </span>
                  </div>
                ))}
              </div>
            </Grid.Column>

            <Grid.Column className={styles.detailsColumn}>
              <div className={styles.header}>
                {'Survey Details'}
              </div>
              <div className={styles.scrollable}>
                <div className={styles.contentData}>
                  <span>
                    {'Client Name'}
                  </span>
                  <span>
                    {data?.client_name ? data.client_name : 'N/A'}
                  </span>
                </div>
                <div className={styles.contentData}>
                  <span>
                    {'Site Name'}
                  </span>
                  <span>
                    {data?.site_name ? data.site_name : 'N/A'}
                  </span>
                </div>
                <div className={styles.contentData}>
                  <span>
                    {'Survey Id'}
                  </span>
                  <span>
                    {data?.survey_id ? data.survey_id : 'N/A'}
                  </span>
                </div>
                <div className={styles.contentData}>
                  <span>
                    {'Site code'}
                  </span>
                  <span>
                    {data?.site_code ? data.site_code : 'N/A'}
                  </span>
                </div>
                <div className={styles.contentData}>
                  <span>
                    {'Date and time of visit'}
                  </span>
                  <span>
                    {data?.date_of_visit ? data.date_of_visit : 'N/A'}
                  </span>
                </div>
                <div className={styles.contentData}>
                  <span>
                    {'Site address'}
                  </span>
                  <span>
                    {data?.site_address_1}
                  </span>
                  <span>
                    {data?.site_address_2}
                  </span>
                  <span>
                    {data?.site_address_3}
                  </span>
                  <span>
                    {data?.site_city}
                  </span>
                  <span>
                    {data?.site_post_code}
                  </span>
                </div>
                <div className={styles.contentData}>
                  <span>
                    {'Item to order'}
                  </span>
                  <span>
                    {data?.item_to_order ? data.item_to_order : 'N/A'}
                  </span>
                </div>
                <div className={styles.contentData}>
                  <span>
                    {'Primary result'}
                  </span>
                  <span>
                    {data?.primary_result ? data.primary_result : 'N/A'}
                  </span>
                </div>
                <div className={styles.contentData}>
                  <span>
                    {'Secondary result'}
                  </span>
                  <span>
                    {data?.secondary_result ? data.secondary_result : 'N/A'}
                  </span>
                </div>
                <div className={styles.contentData}>
                  <span>
                    {'Abort reason'}
                  </span>
                  <span>
                    {data?.abort_reason ? data.abort_reason : 'N/A'}
                  </span>
                </div>
                <div className={styles.contentData}>
                  <span>
                    {'Org Level 2'}
                  </span>
                  <span>
                    {data?.org_level_2 ? data.org_level_2 : 'N/A'}
                  </span>
                </div>
                <div className={styles.contentData}>
                  <span>
                    {'Org Level 3'}
                  </span>
                  <span>
                    {data?.org_level_3 ? data.org_level_3 : 'N/A'}
                  </span>
                </div>
                <div className={styles.contentData}>
                  <span>
                    {'Org Level 4'}
                  </span>
                  <span>
                    {data?.org_level_4 ? data.org_level_4 : 'N/A'}
                  </span>
                </div>
              </div>
            </Grid.Column>
          </Grid>
        </Card.Content>
      </Card>
    </div>
  );
};

export default ArchivesDetails;

import PropTypes from 'prop-types';
import React from 'react';
import { Image, Label } from 'semantic-ui-react';

import timeDiffToString from '../../../../../utils/timeDiffToString';
import styles from './AuditorDetailsProfile.module.scss';

const AuditorDetailsProfile = ({ data, dataCy }) => (
  <div className={styles.basicInfo}>
    <Image rounded className={styles.image} size="small" src={data.image} />

    <div className={styles.auditor}>
      <div className={styles.name} data-cy={`${dataCy}-name`}>
        {[data.first_name, data.middle_name, data.last_name].join(' ')}
      </div>
      <div className={styles.age} data-cy={`${dataCy}-age`}>
        {`${timeDiffToString(data.date_of_birth, 'YM')} old | ${data.gender}`}
      </div>
      <div className={styles.account}>
        <div data-cy={`${dataCy}-since`}>
          {`Member for: ${timeDiffToString(data.created_at, 'YMD')}`}
        </div>
        <div data-cy={`${dataCy}-status`}>
          {'Status: '}
          <Label color="violet">
            {data.auditor_status}
          </Label>
        </div>
      </div>
    </div>

    <div className={styles.contact}>
      <Label content={data.mobile_number} data-cy={`${dataCy}-mobile-number`} icon="phone" />
      <Label as="a" content={data.email} data-cy={`${dataCy}-email`} href={`mailto:${data.email}`} icon="mail" />
    </div>
  </div>
);

AuditorDetailsProfile.propTypes = {
  data: PropTypes.object.isRequired,
  dataCy: PropTypes.string.isRequired,
};

export default AuditorDetailsProfile;

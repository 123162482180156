import React from 'react';

import { Entity } from '../../../../constants';
import AuditDetails from '../../Audits/AuditDetails/AuditDetails.component';
import OrderDetails from '../../Orders/OrderDetails/OrderDetails.component';
import SiteDetails from '../../Sites/SiteDetails/SiteDetails.component';

// const AuditDetails = lazy(() => import('../../Audits/AuditDetails/AuditDetails.component'));
// const SiteDetails = lazy(() => import('../../Sites/SiteDetails/SiteDetails.component'));
// const OrderDetails = lazy(() => import('../../Orders/OrderDetails/OrderDetails.component'));

export const body = ({ entity }) => {
  const entityBody = {
    [Entity.AUDITS]: AuditDetails,
    [Entity.ORDERS]: OrderDetails,
    [Entity.SITES]: SiteDetails,
  };

  return entityBody[entity] || (() => (
    <span>
      DETAILS
    </span>
  ));
};

export const bodyProps = ({ entity, entityId, revisionId, data }) => {
  const props = {
    [Entity.AUDITS]: { entity, entityId, revisionId, data },
    [Entity.ORDERS]: { entity, entityId, revisionId, data },
    [Entity.SITES]: { entity, entityId, revisionId, data },
  };

  return props[entity] || {};
};

import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AuditingSteps } from '../../../../constants';
import history from '../../../../history';
import { updateAuditStep } from '../../../../state/audits/actionCreators';
import { auditStepSelector } from '../../../../state/audits/selectors';
import { AuditingStepsComponents, calculateNextStep, calculatePreviousStep } from './helpers';

const AuditingStep = ({ audit }) => {
  const dispatch = useDispatch();

  const stepSelector = state => auditStepSelector(state, audit.original.id);
  const auditStep = useSelector(stepSelector);

  const [currentStep, setCurrentStep] = useState(auditStep || AuditingSteps.WELCOME);

  useEffect(() => {
    if (auditStep && auditStep !== currentStep) {
      setCurrentStep(auditStep);
    }
  }, [auditStep, currentStep]);

  const handleNext = useCallback(data => {
    const nextStep = calculateNextStep(currentStep, data);
    if (nextStep) {
      setCurrentStep(nextStep);
      dispatch(updateAuditStep({ id: audit.original.id, step: nextStep }));
    } else {
      history.push('/audits?status=assigned');
    }
  }, [currentStep, audit.original.id, dispatch]);

  const handlePrevious = useCallback(data => {
    const previousStep = calculatePreviousStep(currentStep, data);
    if (previousStep) {
      setCurrentStep(previousStep);
      dispatch(updateAuditStep({ id: audit.original.id, step: previousStep }));
    } else {
      history.push('/audits?status=assigned');
    }
  }, [currentStep, audit.original.id, dispatch]);

  const Component = AuditingStepsComponents[currentStep] || AuditingStepsComponents.WELCOME;

  return (
    <Component
      data={audit?.original}
      next={data => handleNext(data)}
      previous={handlePrevious}
    />
  );
};

AuditingStep.defaultProps = {
  audit: {
    original: {
      id: null,
    },
  },
};

AuditingStep.propTypes = {
  audit: PropTypes.object.isRequired,
};

export default AuditingStep;

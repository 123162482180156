import FieldStatus from './fieldStatus';

const conditionObject = {
  auditor: {
    unassigned: {
    },
    assigned: {
      auditor_instructions: FieldStatus.READONLY,
      auditor_pay_per_audit: FieldStatus.READONLY,
      client_name: FieldStatus.READONLY,
      date_of_visit: FieldStatus.EDITABLE,
      entrance_charge: FieldStatus.EDITABLE,
      item_to_order: FieldStatus.READONLY,
      mileage: FieldStatus.EDITABLE,
      mileage_type: FieldStatus.EDITABLE,
      mileage_description: FieldStatus.EDITABLE,
      order_briefing_documents_metadata: FieldStatus.READONLY,
      order_id: FieldStatus.READONLY,
      purchase_cost: FieldStatus.EDITABLE,
      reclaimable_expenses_description: FieldStatus.EDITABLE,
      reclaimable_expenses_type: FieldStatus.EDITABLE,
      reclaimable_expenses: FieldStatus.EDITABLE,
      revision_branching: FieldStatus.READONLY,
      revision_logic: FieldStatus.READONLY,
      revision_questions: FieldStatus.READONLY,
      revision_reports: FieldStatus.READONLY,
      revision_validation: FieldStatus.READONLY,
      site_address_1: FieldStatus.READONLY,
      site_address_2: FieldStatus.READONLY,
      site_address_3: FieldStatus.READONLY,
      site_city: FieldStatus.READONLY,
      site_code: FieldStatus.READONLY,
      site_directions: FieldStatus.READONLY,
      site_name: FieldStatus.READONLY,
      site_post_code: FieldStatus.READONLY,
      survey_answers: FieldStatus.EDITABLE,
      survey_score: FieldStatus.READONLY,
      travel_expenses_description: FieldStatus.EDITABLE,
      travel_expenses_type: FieldStatus.EDITABLE,
      travel_expenses: FieldStatus.EDITABLE,
      visit_info: FieldStatus.READONLY,
    },
    submitted: {
      auditor_instructions: FieldStatus.READONLY,
      auditor_pay_per_audit: FieldStatus.READONLY,
      client_name: FieldStatus.READONLY,
      entrance_charge: FieldStatus.READONLY,
      item_to_order: FieldStatus.READONLY,
      mileage: FieldStatus.READONLY,
      order_briefing_documents_metadata: FieldStatus.READONLY,
      purchase_cost: FieldStatus.READONLY,
      reclaimable_expenses_description: FieldStatus.READONLY,
      reclaimable_expenses_type: FieldStatus.READONLY,
      reclaimable_expenses: FieldStatus.READONLY,
      revision_branching: FieldStatus.READONLY,
      revision_logic: FieldStatus.READONLY,
      revision_questions: FieldStatus.READONLY,
      revision_reports: FieldStatus.READONLY,
      revision_validation: FieldStatus.READONLY,
      site_address_1: FieldStatus.READONLY,
      site_address_2: FieldStatus.READONLY,
      site_address_3: FieldStatus.READONLY,
      site_city: FieldStatus.READONLY,
      site_code: FieldStatus.READONLY,
      site_directions: FieldStatus.READONLY,
      site_name: FieldStatus.READONLY,
      site_post_code: FieldStatus.READONLY,
      submitted_date: FieldStatus.READONLY,
      survey_answers: FieldStatus.READONLY,
      survey_score: FieldStatus.READONLY,
      survey_result: FieldStatus.READONLY,
      travel_expenses_description: FieldStatus.READONLY,
      travel_expenses_type: FieldStatus.READONLY,
      travel_expenses: FieldStatus.READONLY,
      visit_info: FieldStatus.READONLY,
    },
    approved: {
    },
    approving_query: {
      auditor_instructions: FieldStatus.READONLY,
      auditor_pay_per_audit: FieldStatus.READONLY,
      client_name: FieldStatus.READONLY,
      entrance_charge: FieldStatus.READONLY,
      item_to_order: FieldStatus.READONLY,
      mileage: FieldStatus.READONLY,
      order_briefing_documents_metadata: FieldStatus.READONLY,
      purchase_cost: FieldStatus.READONLY,
      reclaimable_expenses_description: FieldStatus.READONLY,
      reclaimable_expenses_type: FieldStatus.READONLY,
      reclaimable_expenses: FieldStatus.READONLY,
      revision_branching: FieldStatus.READONLY,
      revision_logic: FieldStatus.READONLY,
      revision_questions: FieldStatus.READONLY,
      revision_reports: FieldStatus.READONLY,
      revision_validation: FieldStatus.READONLY,
      site_address_1: FieldStatus.READONLY,
      site_address_2: FieldStatus.READONLY,
      site_address_3: FieldStatus.READONLY,
      site_city: FieldStatus.READONLY,
      site_code: FieldStatus.READONLY,
      site_directions: FieldStatus.READONLY,
      site_name: FieldStatus.READONLY,
      site_post_code: FieldStatus.READONLY,
      submitted_date: FieldStatus.READONLY,
      survey_answers: FieldStatus.READONLY,
      survey_score: FieldStatus.READONLY,
      survey_result: FieldStatus.READONLY,
      travel_expenses_description: FieldStatus.READONLY,
      travel_expenses_type: FieldStatus.READONLY,
      travel_expenses: FieldStatus.READONLY,
      visit_info: FieldStatus.READONLY,
    },
    client_query: {
    },
    deleted: {
    },
  },
  area_manager: {
    unassigned: {
      action_save: FieldStatus.READONLY,
      approving_notes: FieldStatus.READONLY,
      auditor_id: FieldStatus.EDITABLE,
      auditor_instructions: FieldStatus.EDITABLE,
      auditor_pay_per_audit: FieldStatus.EDITABLE,
      client_name: FieldStatus.READONLY,
      deadline_date: FieldStatus.EDITABLE,
      entrance_charge: FieldStatus.READONLY,
      geolocation_date: FieldStatus.READONLY,
      item_to_order: FieldStatus.READONLY,
      matchings: FieldStatus.EDITABLE,
      mileage: FieldStatus.READONLY,
      mileage_type: FieldStatus.READONLY,
      mileage_description: FieldStatus.READONLY,
      order_briefing_documents_metadata: FieldStatus.READONLY,
      order_end_date: FieldStatus.READONLY,
      order_id: FieldStatus.READONLY,
      order_schedule_type: FieldStatus.READONLY,
      order_start_date: FieldStatus.READONLY,
      paid: FieldStatus.HIDDEN,
      pay_date: FieldStatus.HIDDEN,
      payroll_date: FieldStatus.HIDDEN,
      query_category: FieldStatus.EDITABLE,
      query_notes: FieldStatus.EDITABLE,
      automatic_fail_letter_notification_email_list: FieldStatus.HIDDEN,
      automatic_fail_letter_notification_date: FieldStatus.READONLY,
      reclaimable_expenses_description: FieldStatus.READONLY,
      reclaimable_expenses_type: FieldStatus.READONLY,
      reclaimable_expenses: FieldStatus.READONLY,
      revision_branching: FieldStatus.READONLY,
      revision_logic: FieldStatus.READONLY,
      revision_questions: FieldStatus.READONLY,
      revision_reports: FieldStatus.READONLY,
      revision_validation: FieldStatus.READONLY,
      site_address_1: FieldStatus.READONLY,
      site_address_2: FieldStatus.READONLY,
      site_address_3: FieldStatus.READONLY,
      site_city: FieldStatus.READONLY,
      site_code: FieldStatus.READONLY,
      site_directions: FieldStatus.READONLY,
      site_id: FieldStatus.HIDDEN,
      site_name: FieldStatus.READONLY,
      site_post_code: FieldStatus.READONLY,
      status: FieldStatus.READONLY,
      survey_answers: FieldStatus.EDITABLE,
      tokens: FieldStatus.HIDDEN,
      travel_expenses_description: FieldStatus.READONLY,
      travel_expenses_type: FieldStatus.READONLY,
      travel_expenses: FieldStatus.READONLY,
      visit_info: FieldStatus.EDITABLE,
      expenses_receipts_urls: FieldStatus.EDITABLE,
      travel_expenses_images_urls: FieldStatus.EDITABLE,
    },
    assigned: {
      action_save: FieldStatus.READONLY,
      action_submit: FieldStatus.READONLY,
      approving_notes: FieldStatus.READONLY,
      auditor_all_visit_count: FieldStatus.READONLY,
      auditor_date_of_birth: FieldStatus.READONLY,
      auditor_first_narv_visit_date: FieldStatus.READONLY,
      auditor_first_sl_visit_date: FieldStatus.READONLY,
      auditor_gender: FieldStatus.READONLY,
      auditor_id: FieldStatus.EDITABLE,
      auditor_instructions: FieldStatus.EDITABLE,
      auditor_last_narv_visit_date: FieldStatus.READONLY,
      auditor_last_sl_visit_date: FieldStatus.READONLY,
      auditor_mobile_number: FieldStatus.READONLY,
      auditor_narv_visit_count: FieldStatus.READONLY,
      auditor_pass_rate: FieldStatus.READONLY,
      auditor_pay_per_audit: FieldStatus.EDITABLE,
      auditor_sl_visit_count: FieldStatus.READONLY,
      client_name: FieldStatus.READONLY,
      date_of_visit: FieldStatus.EDITABLE,
      deadline_date: FieldStatus.EDITABLE,
      entrance_charge: FieldStatus.EDITABLE,
      geolocation_date: FieldStatus.READONLY,
      item_to_order: FieldStatus.READONLY,
      matchings: FieldStatus.EDITABLE,
      mileage: FieldStatus.READONLY,
      mileage_type: FieldStatus.READONLY,
      mileage_description: FieldStatus.READONLY,
      order_briefing_documents_metadata: FieldStatus.READONLY,
      order_end_date: FieldStatus.READONLY,
      order_id: FieldStatus.READONLY,
      order_schedule_type: FieldStatus.READONLY,
      order_start_date: FieldStatus.READONLY,
      paid: FieldStatus.HIDDEN,
      pay_date: FieldStatus.HIDDEN,
      payroll_date: FieldStatus.HIDDEN,
      purchase_cost: FieldStatus.EDITABLE,
      query_category: FieldStatus.EDITABLE,
      query_notes: FieldStatus.EDITABLE,
      automatic_fail_letter_notification_email_list: FieldStatus.HIDDEN,
      automatic_fail_letter_notification_date: FieldStatus.READONLY,
      reclaimable_expenses_description: FieldStatus.READONLY,
      reclaimable_expenses_type: FieldStatus.READONLY,
      reclaimable_expenses: FieldStatus.READONLY,
      revision_branching: FieldStatus.READONLY,
      revision_logic: FieldStatus.READONLY,
      revision_questions: FieldStatus.READONLY,
      revision_reports: FieldStatus.READONLY,
      revision_validation: FieldStatus.READONLY,
      site_address_1: FieldStatus.READONLY,
      site_address_2: FieldStatus.READONLY,
      site_address_3: FieldStatus.READONLY,
      site_city: FieldStatus.READONLY,
      site_code: FieldStatus.READONLY,
      site_directions: FieldStatus.READONLY,
      site_id: FieldStatus.HIDDEN,
      site_name: FieldStatus.READONLY,
      site_post_code: FieldStatus.READONLY,
      status: FieldStatus.READONLY,
      survey_answers: FieldStatus.EDITABLE,
      tokens: FieldStatus.HIDDEN,
      travel_expenses_description: FieldStatus.READONLY,
      travel_expenses_type: FieldStatus.READONLY,
      travel_expenses: FieldStatus.READONLY,
      visit_info: FieldStatus.EDITABLE,
      expenses_receipts_urls: FieldStatus.EDITABLE,
      travel_expenses_images_urls: FieldStatus.EDITABLE,
    },
    submitted: {
      action_approve: FieldStatus.READONLY,
      action_query: FieldStatus.READONLY,
      action_reset_reassign: FieldStatus.READONLY,
      action_reset: FieldStatus.READONLY,
      action_save: FieldStatus.READONLY,
      approving_notes: FieldStatus.READONLY,
      auditor_all_visit_count: FieldStatus.READONLY,
      auditor_date_of_birth: FieldStatus.READONLY,
      auditor_first_narv_visit_date: FieldStatus.READONLY,
      auditor_first_sl_visit_date: FieldStatus.READONLY,
      auditor_gender: FieldStatus.READONLY,
      auditor_id: FieldStatus.READONLY,
      auditor_instructions: FieldStatus.EDITABLE,
      auditor_last_narv_visit_date: FieldStatus.READONLY,
      auditor_last_sl_visit_date: FieldStatus.READONLY,
      auditor_mobile_number: FieldStatus.READONLY,
      auditor_narv_visit_count: FieldStatus.READONLY,
      auditor_pass_rate: FieldStatus.READONLY,
      auditor_pay_per_audit: FieldStatus.EDITABLE,
      auditor_sl_visit_count: FieldStatus.READONLY,
      client_name: FieldStatus.READONLY,
      date_of_visit: FieldStatus.EDITABLE,
      deadline_date: FieldStatus.READONLY,
      email_note: FieldStatus.EDITABLE,
      entrance_charge: FieldStatus.EDITABLE,
      geolocation_date: FieldStatus.READONLY,
      item_to_order: FieldStatus.READONLY,
      matchings: FieldStatus.EDITABLE,
      mileage: FieldStatus.EDITABLE,
      mileage_type: FieldStatus.EDITABLE,
      mileage_description: FieldStatus.EDITABLE,
      order_briefing_documents_metadata: FieldStatus.READONLY,
      order_end_date: FieldStatus.READONLY,
      order_id: FieldStatus.READONLY,
      order_schedule_type: FieldStatus.READONLY,
      order_start_date: FieldStatus.READONLY,
      paid: FieldStatus.HIDDEN,
      pay_date: FieldStatus.HIDDEN,
      payroll_date: FieldStatus.HIDDEN,
      purchase_cost: FieldStatus.EDITABLE,
      purchase_image: FieldStatus.EDITABLE,
      query_category: FieldStatus.EDITABLE,
      query_notes: FieldStatus.EDITABLE,
      automatic_fail_letter_notification_email_list: FieldStatus.HIDDEN,
      automatic_fail_letter_notification_date: FieldStatus.READONLY,
      reclaimable_expenses_description: FieldStatus.EDITABLE,
      reclaimable_expenses_type: FieldStatus.EDITABLE,
      reclaimable_expenses: FieldStatus.EDITABLE,
      revision_branching: FieldStatus.READONLY,
      revision_logic: FieldStatus.READONLY,
      revision_questions: FieldStatus.READONLY,
      revision_reports: FieldStatus.READONLY,
      revision_validation: FieldStatus.READONLY,
      site_address_1: FieldStatus.READONLY,
      site_address_2: FieldStatus.READONLY,
      site_address_3: FieldStatus.READONLY,
      site_city: FieldStatus.READONLY,
      site_code: FieldStatus.READONLY,
      site_directions: FieldStatus.READONLY,
      site_id: FieldStatus.HIDDEN,
      site_name: FieldStatus.READONLY,
      site_post_code: FieldStatus.READONLY,
      status: FieldStatus.READONLY,
      submitted_date: FieldStatus.READONLY,
      survey_answers: FieldStatus.EDITABLE,
      survey_result: FieldStatus.READONLY,
      survey_score: FieldStatus.READONLY,
      tokens: FieldStatus.HIDDEN,
      travel_expenses_description: FieldStatus.EDITABLE,
      travel_expenses_type: FieldStatus.EDITABLE,
      travel_expenses: FieldStatus.EDITABLE,
      visit_info: FieldStatus.EDITABLE,
      expenses_receipts_urls: FieldStatus.EDITABLE,
      travel_expenses_images_urls: FieldStatus.EDITABLE,
    },
    approved: {
      action_save: FieldStatus.READONLY,
      approval_date: FieldStatus.READONLY,
      approving_notes: FieldStatus.READONLY,
      auditor_all_visit_count: FieldStatus.READONLY,
      auditor_date_of_birth: FieldStatus.READONLY,
      auditor_first_narv_visit_date: FieldStatus.READONLY,
      auditor_first_sl_visit_date: FieldStatus.READONLY,
      auditor_gender: FieldStatus.READONLY,
      auditor_id: FieldStatus.READONLY,
      auditor_instructions: FieldStatus.READONLY,
      auditor_last_narv_visit_date: FieldStatus.READONLY,
      auditor_last_sl_visit_date: FieldStatus.READONLY,
      auditor_mobile_number: FieldStatus.READONLY,
      auditor_narv_visit_count: FieldStatus.READONLY,
      auditor_pass_rate: FieldStatus.READONLY,
      auditor_pay_per_audit: FieldStatus.READONLY,
      auditor_sl_visit_count: FieldStatus.READONLY,
      client_name: FieldStatus.READONLY,
      date_of_visit: FieldStatus.READONLY,
      deadline_date: FieldStatus.READONLY,
      email_note: FieldStatus.READONLY,
      entrance_charge: FieldStatus.READONLY,
      geolocation_date: FieldStatus.READONLY,
      item_to_order: FieldStatus.READONLY,
      matchings: FieldStatus.EDITABLE,
      mileage: FieldStatus.READONLY,
      mileage_type: FieldStatus.READONLY,
      mileage_description: FieldStatus.READONLY,
      order_briefing_documents_metadata: FieldStatus.READONLY,
      order_end_date: FieldStatus.READONLY,
      order_id: FieldStatus.READONLY,
      order_schedule_type: FieldStatus.READONLY,
      order_start_date: FieldStatus.READONLY,
      paid: FieldStatus.READONLY,
      pay_date: FieldStatus.HIDDEN,
      payroll_date: FieldStatus.HIDDEN,
      purchase_cost: FieldStatus.READONLY,
      purchase_image: FieldStatus.READONLY,
      query_category: FieldStatus.READONLY,
      query_notes: FieldStatus.READONLY,
      automatic_fail_letter_notification_email_list: FieldStatus.HIDDEN,
      automatic_fail_letter_notification_date: FieldStatus.READONLY,
      reclaimable_expenses_description: FieldStatus.READONLY,
      reclaimable_expenses_type: FieldStatus.READONLY,
      reclaimable_expenses: FieldStatus.READONLY,
      revision_branching: FieldStatus.READONLY,
      revision_logic: FieldStatus.READONLY,
      revision_questions: FieldStatus.READONLY,
      revision_reports: FieldStatus.READONLY,
      revision_validation: FieldStatus.READONLY,
      site_address_1: FieldStatus.READONLY,
      site_address_2: FieldStatus.READONLY,
      site_address_3: FieldStatus.READONLY,
      site_city: FieldStatus.READONLY,
      site_code: FieldStatus.READONLY,
      site_directions: FieldStatus.READONLY,
      site_id: FieldStatus.HIDDEN,
      site_name: FieldStatus.READONLY,
      site_post_code: FieldStatus.READONLY,
      status: FieldStatus.READONLY,
      submitted_date: FieldStatus.READONLY,
      survey_answers: FieldStatus.READONLY,
      survey_result: FieldStatus.READONLY,
      survey_score: FieldStatus.READONLY,
      tokens: FieldStatus.HIDDEN,
      travel_expenses_description: FieldStatus.READONLY,
      travel_expenses_type: FieldStatus.READONLY,
      travel_expenses: FieldStatus.READONLY,
      visit_info: FieldStatus.READONLY,
      expenses_receipts_urls: FieldStatus.EDITABLE,
      travel_expenses_images_urls: FieldStatus.EDITABLE,
    },
    approving_query: {
      action_approve: FieldStatus.READONLY,
      action_reset_reassign: FieldStatus.READONLY,
      action_reset: FieldStatus.READONLY,
      action_save: FieldStatus.READONLY,
      approving_notes: FieldStatus.READONLY,
      auditor_all_visit_count: FieldStatus.READONLY,
      auditor_date_of_birth: FieldStatus.READONLY,
      auditor_first_narv_visit_date: FieldStatus.READONLY,
      auditor_first_sl_visit_date: FieldStatus.READONLY,
      auditor_gender: FieldStatus.READONLY,
      auditor_id: FieldStatus.READONLY,
      auditor_instructions: FieldStatus.EDITABLE,
      auditor_last_narv_visit_date: FieldStatus.READONLY,
      auditor_last_sl_visit_date: FieldStatus.READONLY,
      auditor_mobile_number: FieldStatus.READONLY,
      auditor_narv_visit_count: FieldStatus.READONLY,
      auditor_pass_rate: FieldStatus.READONLY,
      auditor_pay_per_audit: FieldStatus.EDITABLE,
      auditor_sl_visit_count: FieldStatus.READONLY,
      client_name: FieldStatus.READONLY,
      date_of_visit: FieldStatus.EDITABLE,
      deadline_date: FieldStatus.READONLY,
      email_note: FieldStatus.EDITABLE,
      entrance_charge: FieldStatus.EDITABLE,
      geolocation_date: FieldStatus.READONLY,
      item_to_order: FieldStatus.READONLY,
      matchings: FieldStatus.EDITABLE,
      mileage: FieldStatus.EDITABLE,
      mileage_type: FieldStatus.EDITABLE,
      mileage_description: FieldStatus.EDITABLE,
      order_briefing_documents_metadata: FieldStatus.READONLY,
      order_end_date: FieldStatus.READONLY,
      order_id: FieldStatus.READONLY,
      order_schedule_type: FieldStatus.READONLY,
      order_start_date: FieldStatus.READONLY,
      paid: FieldStatus.HIDDEN,
      pay_date: FieldStatus.HIDDEN,
      payroll_date: FieldStatus.HIDDEN,
      purchase_cost: FieldStatus.EDITABLE,
      purchase_image: FieldStatus.EDITABLE,
      query_category: FieldStatus.EDITABLE,
      query_notes: FieldStatus.EDITABLE,
      automatic_fail_letter_notification_email_list: FieldStatus.HIDDEN,
      automatic_fail_letter_notification_date: FieldStatus.READONLY,
      reclaimable_expenses_description: FieldStatus.EDITABLE,
      reclaimable_expenses_type: FieldStatus.EDITABLE,
      reclaimable_expenses: FieldStatus.EDITABLE,
      revision_branching: FieldStatus.READONLY,
      revision_logic: FieldStatus.READONLY,
      revision_questions: FieldStatus.READONLY,
      revision_reports: FieldStatus.READONLY,
      revision_validation: FieldStatus.READONLY,
      site_address_1: FieldStatus.READONLY,
      site_address_2: FieldStatus.READONLY,
      site_address_3: FieldStatus.READONLY,
      site_city: FieldStatus.READONLY,
      site_code: FieldStatus.READONLY,
      site_directions: FieldStatus.READONLY,
      site_id: FieldStatus.HIDDEN,
      site_name: FieldStatus.READONLY,
      site_post_code: FieldStatus.READONLY,
      status: FieldStatus.READONLY,
      submitted_date: FieldStatus.READONLY,
      survey_answers: FieldStatus.EDITABLE,
      survey_result: FieldStatus.READONLY,
      survey_score: FieldStatus.READONLY,
      tokens: FieldStatus.HIDDEN,
      travel_expenses_description: FieldStatus.EDITABLE,
      travel_expenses_type: FieldStatus.EDITABLE,
      travel_expenses: FieldStatus.EDITABLE,
      visit_info: FieldStatus.EDITABLE,
      expenses_receipts_urls: FieldStatus.EDITABLE,
      travel_expenses_images_urls: FieldStatus.EDITABLE,
    },
    client_query: {
      action_approve: FieldStatus.READONLY,
      approval_date: FieldStatus.READONLY,
      approving_notes: FieldStatus.HIDDEN,
      auditor_all_visit_count: FieldStatus.READONLY,
      auditor_date_of_birth: FieldStatus.READONLY,
      auditor_first_narv_visit_date: FieldStatus.READONLY,
      auditor_first_sl_visit_date: FieldStatus.READONLY,
      auditor_gender: FieldStatus.READONLY,
      auditor_id: FieldStatus.READONLY,
      auditor_instructions: FieldStatus.READONLY,
      auditor_last_narv_visit_date: FieldStatus.READONLY,
      auditor_last_sl_visit_date: FieldStatus.READONLY,
      auditor_mobile_number: FieldStatus.READONLY,
      auditor_narv_visit_count: FieldStatus.READONLY,
      auditor_pass_rate: FieldStatus.READONLY,
      auditor_pay_per_audit: FieldStatus.READONLY,
      auditor_sl_visit_count: FieldStatus.READONLY,
      client_name: FieldStatus.READONLY,
      date_of_visit: FieldStatus.READONLY,
      deadline_date: FieldStatus.READONLY,
      email_note: FieldStatus.READONLY,
      entrance_charge: FieldStatus.READONLY,
      geolocation_date: FieldStatus.READONLY,
      item_to_order: FieldStatus.HIDDEN,
      matchings: FieldStatus.EDITABLE,
      mileage: FieldStatus.READONLY,
      mileage_type: FieldStatus.READONLY,
      mileage_description: FieldStatus.READONLY,
      order_briefing_documents_metadata: FieldStatus.HIDDEN,
      order_end_date: FieldStatus.READONLY,
      order_id: FieldStatus.READONLY,
      order_schedule_type: FieldStatus.READONLY,
      order_start_date: FieldStatus.READONLY,
      paid: FieldStatus.HIDDEN,
      pay_date: FieldStatus.HIDDEN,
      payroll_date: FieldStatus.HIDDEN,
      query_category: FieldStatus.READONLY,
      query_notes: FieldStatus.READONLY,
      automatic_fail_letter_notification_email_list: FieldStatus.HIDDEN,
      automatic_fail_letter_notification_date: FieldStatus.READONLY,
      reclaimable_expenses_description: FieldStatus.READONLY,
      reclaimable_expenses_type: FieldStatus.READONLY,
      reclaimable_expenses: FieldStatus.READONLY,
      revision_branching: FieldStatus.READONLY,
      revision_logic: FieldStatus.READONLY,
      revision_questions: FieldStatus.READONLY,
      revision_reports: FieldStatus.READONLY,
      revision_validation: FieldStatus.READONLY,
      site_address_1: FieldStatus.READONLY,
      site_address_2: FieldStatus.READONLY,
      site_address_3: FieldStatus.READONLY,
      site_city: FieldStatus.READONLY,
      site_code: FieldStatus.READONLY,
      site_directions: FieldStatus.READONLY,
      site_id: FieldStatus.HIDDEN,
      site_name: FieldStatus.READONLY,
      site_post_code: FieldStatus.READONLY,
      status: FieldStatus.READONLY,
      submitted_date: FieldStatus.READONLY,
      survey_answers: FieldStatus.READONLY,
      survey_result: FieldStatus.READONLY,
      survey_score: FieldStatus.READONLY,
      tokens: FieldStatus.HIDDEN,
      travel_expenses_description: FieldStatus.READONLY,
      travel_expenses_type: FieldStatus.READONLY,
      travel_expenses: FieldStatus.READONLY,
      visit_info: FieldStatus.HIDDEN,
    },
    deleted: {
    },
  },
  client: {
    unassigned: {
    },
    assigned: {
    },
    submitted: {
    },
    approved: {
      auditor_all_visit_count: FieldStatus.READONLY,
      auditor_date_of_birth: FieldStatus.READONLY,
      auditor_gender: FieldStatus.READONLY,
      auditor_last_sl_visit_date: FieldStatus.READONLY,
      auditor_pass_rate: FieldStatus.READONLY,
      date_of_visit: FieldStatus.READONLY,
      item_to_order: FieldStatus.READONLY,
      order_id: FieldStatus.READONLY,
      site_organisation_level_code: FieldStatus.READONLY,
      revision_branching: FieldStatus.READONLY,
      revision_logic: FieldStatus.READONLY,
      revision_questions: FieldStatus.READONLY,
      revision_reports: FieldStatus.READONLY,
      revision_validation: FieldStatus.READONLY,
      site_address_1: FieldStatus.READONLY,
      site_address_2: FieldStatus.READONLY,
      site_address_3: FieldStatus.READONLY,
      site_city: FieldStatus.READONLY,
      site_directions: FieldStatus.HIDDEN,
      site_name: FieldStatus.READONLY,
      site_post_code: FieldStatus.READONLY,
      survey_answers: FieldStatus.READONLY,
      survey_result: FieldStatus.READONLY,
      survey_score: FieldStatus.READONLY,
    },
    approving_query: {
    },
    client_query: {
    },
    deleted: {
    },
  },
  client_services: {
    unassigned: {
      action_save: FieldStatus.READONLY,
      approving_notes: FieldStatus.READONLY,
      auditor_id: FieldStatus.EDITABLE,
      auditor_instructions: FieldStatus.EDITABLE,
      auditor_pay_per_audit: FieldStatus.EDITABLE,
      client_name: FieldStatus.READONLY,
      deadline_date: FieldStatus.EDITABLE,
      email_report_distribution_list: FieldStatus.EDITABLE,
      entrance_charge: FieldStatus.READONLY,
      geolocation_date: FieldStatus.READONLY,
      item_to_order: FieldStatus.READONLY,
      matchings: FieldStatus.EDITABLE,
      mileage: FieldStatus.READONLY,
      mileage_type: FieldStatus.READONLY,
      mileage_description: FieldStatus.READONLY,
      order_briefing_documents_metadata: FieldStatus.READONLY,
      order_end_date: FieldStatus.READONLY,
      order_id: FieldStatus.READONLY,
      order_schedule_type: FieldStatus.READONLY,
      order_start_date: FieldStatus.READONLY,
      paid: FieldStatus.HIDDEN,
      pay_date: FieldStatus.HIDDEN,
      payroll_date: FieldStatus.HIDDEN,
      query_category: FieldStatus.EDITABLE,
      query_notes: FieldStatus.EDITABLE,
      automatic_fail_letter_notification_email_list: FieldStatus.HIDDEN,
      automatic_fail_letter_notification_date: FieldStatus.READONLY,
      reclaimable_expenses_description: FieldStatus.READONLY,
      reclaimable_expenses_type: FieldStatus.READONLY,
      reclaimable_expenses: FieldStatus.READONLY,
      reclaimable_expenses_4: FieldStatus.READONLY,
      revision_branching: FieldStatus.READONLY,
      revision_logic: FieldStatus.READONLY,
      revision_questions: FieldStatus.READONLY,
      revision_reports: FieldStatus.READONLY,
      revision_validation: FieldStatus.READONLY,
      site_address_1: FieldStatus.READONLY,
      site_address_2: FieldStatus.READONLY,
      site_address_3: FieldStatus.READONLY,
      site_city: FieldStatus.READONLY,
      site_code: FieldStatus.READONLY,
      site_directions: FieldStatus.READONLY,
      site_id: FieldStatus.HIDDEN,
      site_name: FieldStatus.READONLY,
      site_post_code: FieldStatus.READONLY,
      status: FieldStatus.READONLY,
      survey_answers: FieldStatus.EDITABLE,
      tokens: FieldStatus.HIDDEN,
      travel_expenses_description: FieldStatus.READONLY,
      travel_expenses_type: FieldStatus.READONLY,
      travel_expenses: FieldStatus.READONLY,
      visit_info: FieldStatus.EDITABLE,
      expenses_receipts_urls: FieldStatus.EDITABLE,
      travel_expenses_images_urls: FieldStatus.EDITABLE,
    },
    assigned: {
      action_save: FieldStatus.READONLY,
      action_submit: FieldStatus.READONLY,
      approving_notes: FieldStatus.READONLY,
      auditor_all_visit_count: FieldStatus.READONLY,
      auditor_date_of_birth: FieldStatus.READONLY,
      auditor_first_narv_visit_date: FieldStatus.READONLY,
      auditor_first_sl_visit_date: FieldStatus.READONLY,
      auditor_gender: FieldStatus.READONLY,
      auditor_id: FieldStatus.EDITABLE,
      auditor_instructions: FieldStatus.EDITABLE,
      auditor_last_narv_visit_date: FieldStatus.READONLY,
      auditor_last_sl_visit_date: FieldStatus.READONLY,
      auditor_mobile_number: FieldStatus.READONLY,
      auditor_narv_visit_count: FieldStatus.READONLY,
      auditor_pass_rate: FieldStatus.READONLY,
      auditor_pay_per_audit: FieldStatus.EDITABLE,
      auditor_sl_visit_count: FieldStatus.READONLY,
      client_name: FieldStatus.READONLY,
      date_of_visit: FieldStatus.EDITABLE,
      deadline_date: FieldStatus.EDITABLE,
      email_report_distribution_list: FieldStatus.EDITABLE,
      entrance_charge: FieldStatus.READONLY,
      geolocation_date: FieldStatus.READONLY,
      item_to_order: FieldStatus.READONLY,
      matchings: FieldStatus.EDITABLE,
      mileage: FieldStatus.READONLY,
      mileage_type: FieldStatus.READONLY,
      mileage_description: FieldStatus.READONLY,
      order_briefing_documents_metadata: FieldStatus.READONLY,
      order_end_date: FieldStatus.READONLY,
      order_id: FieldStatus.READONLY,
      order_schedule_type: FieldStatus.READONLY,
      order_start_date: FieldStatus.READONLY,
      paid: FieldStatus.HIDDEN,
      pay_date: FieldStatus.HIDDEN,
      payroll_date: FieldStatus.HIDDEN,
      query_category: FieldStatus.EDITABLE,
      query_notes: FieldStatus.EDITABLE,
      automatic_fail_letter_notification_email_list: FieldStatus.HIDDEN,
      automatic_fail_letter_notification_date: FieldStatus.READONLY,
      reclaimable_expenses_description: FieldStatus.READONLY,
      reclaimable_expenses_type: FieldStatus.READONLY,
      reclaimable_expenses: FieldStatus.READONLY,
      revision_branching: FieldStatus.READONLY,
      revision_logic: FieldStatus.READONLY,
      revision_questions: FieldStatus.READONLY,
      revision_reports: FieldStatus.READONLY,
      revision_validation: FieldStatus.READONLY,
      site_address_1: FieldStatus.READONLY,
      site_address_2: FieldStatus.READONLY,
      site_address_3: FieldStatus.READONLY,
      site_city: FieldStatus.READONLY,
      site_code: FieldStatus.READONLY,
      site_directions: FieldStatus.READONLY,
      site_id: FieldStatus.HIDDEN,
      site_name: FieldStatus.READONLY,
      site_post_code: FieldStatus.READONLY,
      status: FieldStatus.READONLY,
      survey_answers: FieldStatus.EDITABLE,
      tokens: FieldStatus.HIDDEN,
      travel_expenses_description: FieldStatus.READONLY,
      travel_expenses_type: FieldStatus.READONLY,
      travel_expenses: FieldStatus.READONLY,
      visit_info: FieldStatus.EDITABLE,
      expenses_receipts_urls: FieldStatus.EDITABLE,
      travel_expenses_images_urls: FieldStatus.EDITABLE,
    },
    submitted: {
      action_approve: FieldStatus.READONLY,
      action_query: FieldStatus.READONLY,
      action_reset: FieldStatus.READONLY,
      action_save: FieldStatus.READONLY,
      approving_notes: FieldStatus.READONLY,
      auditor_all_visit_count: FieldStatus.READONLY,
      auditor_date_of_birth: FieldStatus.READONLY,
      auditor_first_narv_visit_date: FieldStatus.READONLY,
      auditor_first_sl_visit_date: FieldStatus.READONLY,
      auditor_gender: FieldStatus.READONLY,
      auditor_id: FieldStatus.READONLY,
      auditor_instructions: FieldStatus.EDITABLE,
      auditor_last_narv_visit_date: FieldStatus.READONLY,
      auditor_last_sl_visit_date: FieldStatus.READONLY,
      auditor_mobile_number: FieldStatus.READONLY,
      auditor_narv_visit_count: FieldStatus.READONLY,
      auditor_pass_rate: FieldStatus.READONLY,
      auditor_pay_per_audit: FieldStatus.EDITABLE,
      auditor_sl_visit_count: FieldStatus.READONLY,
      client_name: FieldStatus.READONLY,
      date_of_visit: FieldStatus.EDITABLE,
      deadline_date: FieldStatus.READONLY,
      email_note: FieldStatus.EDITABLE,
      email_report_distribution_list: FieldStatus.EDITABLE,
      entrance_charge: FieldStatus.EDITABLE,
      geolocation_date: FieldStatus.READONLY,
      item_to_order: FieldStatus.READONLY,
      matchings: FieldStatus.EDITABLE,
      mileage: FieldStatus.EDITABLE,
      mileage_type: FieldStatus.EDITABLE,
      mileage_description: FieldStatus.EDITABLE,
      order_briefing_documents_metadata: FieldStatus.READONLY,
      order_end_date: FieldStatus.READONLY,
      order_id: FieldStatus.READONLY,
      order_schedule_type: FieldStatus.READONLY,
      order_start_date: FieldStatus.READONLY,
      paid: FieldStatus.HIDDEN,
      pay_date: FieldStatus.HIDDEN,
      payroll_date: FieldStatus.HIDDEN,
      purchase_cost: FieldStatus.EDITABLE,
      purchase_image: FieldStatus.EDITABLE,
      query_category: FieldStatus.EDITABLE,
      query_notes: FieldStatus.EDITABLE,
      automatic_fail_letter_notification_email_list: FieldStatus.HIDDEN,
      automatic_fail_letter_notification_date: FieldStatus.READONLY,
      reclaimable_expenses_description: FieldStatus.EDITABLE,
      reclaimable_expenses_type: FieldStatus.EDITABLE,
      reclaimable_expenses: FieldStatus.EDITABLE,
      revision_branching: FieldStatus.READONLY,
      revision_logic: FieldStatus.READONLY,
      revision_questions: FieldStatus.READONLY,
      revision_reports: FieldStatus.READONLY,
      revision_validation: FieldStatus.READONLY,
      site_address_1: FieldStatus.READONLY,
      site_address_2: FieldStatus.READONLY,
      site_address_3: FieldStatus.READONLY,
      site_city: FieldStatus.READONLY,
      site_code: FieldStatus.READONLY,
      site_directions: FieldStatus.READONLY,
      site_id: FieldStatus.HIDDEN,
      site_name: FieldStatus.READONLY,
      site_post_code: FieldStatus.READONLY,
      status: FieldStatus.READONLY,
      submitted_date: FieldStatus.READONLY,
      survey_answers: FieldStatus.EDITABLE,
      survey_result: FieldStatus.READONLY,
      survey_score: FieldStatus.READONLY,
      tokens: FieldStatus.HIDDEN,
      travel_expenses_description: FieldStatus.EDITABLE,
      travel_expenses_type: FieldStatus.EDITABLE,
      travel_expenses: FieldStatus.EDITABLE,
      visit_info: FieldStatus.EDITABLE,
      expenses_receipts_urls: FieldStatus.EDITABLE,
      travel_expenses_images_urls: FieldStatus.EDITABLE,
    },
    approved: {
      action_client_query: FieldStatus.READONLY,
      action_save: FieldStatus.READONLY,
      approval_date: FieldStatus.READONLY,
      approved_by_email: FieldStatus.READONLY,
      approved_by_name: FieldStatus.READONLY,
      approving_notes: FieldStatus.READONLY,
      auditor_all_visit_count: FieldStatus.READONLY,
      auditor_date_of_birth: FieldStatus.READONLY,
      auditor_first_narv_visit_date: FieldStatus.READONLY,
      auditor_first_sl_visit_date: FieldStatus.READONLY,
      auditor_gender: FieldStatus.READONLY,
      auditor_id: FieldStatus.READONLY,
      auditor_instructions: FieldStatus.READONLY,
      auditor_last_narv_visit_date: FieldStatus.READONLY,
      auditor_last_sl_visit_date: FieldStatus.READONLY,
      auditor_mobile_number: FieldStatus.READONLY,
      auditor_narv_visit_count: FieldStatus.READONLY,
      auditor_pass_rate: FieldStatus.READONLY,
      auditor_pay_per_audit: FieldStatus.READONLY,
      auditor_sl_visit_count: FieldStatus.READONLY,
      client_name: FieldStatus.READONLY,
      date_of_visit: FieldStatus.READONLY,
      deadline_date: FieldStatus.READONLY,
      email_note: FieldStatus.READONLY,
      email_report_distribution_list: FieldStatus.READONLY,
      entrance_charge: FieldStatus.READONLY,
      geolocation_date: FieldStatus.READONLY,
      item_to_order: FieldStatus.READONLY,
      matchings: FieldStatus.EDITABLE,
      mileage: FieldStatus.READONLY,
      mileage_type: FieldStatus.READONLY,
      mileage_description: FieldStatus.READONLY,
      order_briefing_documents_metadata: FieldStatus.READONLY,
      order_end_date: FieldStatus.READONLY,
      order_id: FieldStatus.READONLY,
      order_schedule_type: FieldStatus.READONLY,
      order_start_date: FieldStatus.READONLY,
      paid: FieldStatus.READONLY,
      pay_date: FieldStatus.HIDDEN,
      payroll_date: FieldStatus.HIDDEN,
      purchase_cost: FieldStatus.READONLY,
      purchase_image: FieldStatus.READONLY,
      query_category: FieldStatus.READONLY,
      query_notes: FieldStatus.READONLY,
      automatic_fail_letter_notification_email_list: FieldStatus.HIDDEN,
      automatic_fail_letter_notification_date: FieldStatus.READONLY,
      reclaimable_expenses_description: FieldStatus.READONLY,
      reclaimable_expenses_type: FieldStatus.READONLY,
      reclaimable_expenses: FieldStatus.READONLY,
      revision_branching: FieldStatus.READONLY,
      revision_logic: FieldStatus.READONLY,
      revision_questions: FieldStatus.READONLY,
      revision_reports: FieldStatus.READONLY,
      revision_validation: FieldStatus.READONLY,
      site_address_1: FieldStatus.READONLY,
      site_address_2: FieldStatus.READONLY,
      site_address_3: FieldStatus.READONLY,
      site_city: FieldStatus.READONLY,
      site_code: FieldStatus.READONLY,
      site_directions: FieldStatus.READONLY,
      site_id: FieldStatus.HIDDEN,
      site_name: FieldStatus.READONLY,
      site_post_code: FieldStatus.READONLY,
      status: FieldStatus.READONLY,
      submitted_date: FieldStatus.READONLY,
      survey_answers: FieldStatus.READONLY,
      survey_result: FieldStatus.READONLY,
      survey_score: FieldStatus.READONLY,
      tokens: FieldStatus.HIDDEN,
      travel_expenses_description: FieldStatus.READONLY,
      travel_expenses_type: FieldStatus.READONLY,
      travel_expenses: FieldStatus.READONLY,
      visit_info: FieldStatus.READONLY,
      expenses_receipts_urls: FieldStatus.EDITABLE,
      travel_expenses_images_urls: FieldStatus.EDITABLE,
    },
    approving_query: {
      action_approve: FieldStatus.READONLY,
      action_reset: FieldStatus.READONLY,
      action_save: FieldStatus.READONLY,
      approved_by_email: FieldStatus.READONLY,
      approved_by_name: FieldStatus.READONLY,
      approving_notes: FieldStatus.READONLY,
      auditor_all_visit_count: FieldStatus.READONLY,
      auditor_date_of_birth: FieldStatus.READONLY,
      auditor_first_narv_visit_date: FieldStatus.READONLY,
      auditor_first_sl_visit_date: FieldStatus.READONLY,
      auditor_gender: FieldStatus.READONLY,
      auditor_id: FieldStatus.READONLY,
      auditor_instructions: FieldStatus.EDITABLE,
      auditor_last_narv_visit_date: FieldStatus.READONLY,
      auditor_last_sl_visit_date: FieldStatus.READONLY,
      auditor_mobile_number: FieldStatus.READONLY,
      auditor_narv_visit_count: FieldStatus.READONLY,
      auditor_pass_rate: FieldStatus.READONLY,
      auditor_pay_per_audit: FieldStatus.EDITABLE,
      auditor_sl_visit_count: FieldStatus.READONLY,
      client_name: FieldStatus.READONLY,
      date_of_visit: FieldStatus.EDITABLE,
      deadline_date: FieldStatus.READONLY,
      email_note: FieldStatus.EDITABLE,
      email_report_distribution_list: FieldStatus.EDITABLE,
      entrance_charge: FieldStatus.EDITABLE,
      geolocation_date: FieldStatus.READONLY,
      item_to_order: FieldStatus.READONLY,
      matchings: FieldStatus.EDITABLE,
      mileage: FieldStatus.EDITABLE,
      mileage_type: FieldStatus.EDITABLE,
      mileage_description: FieldStatus.EDITABLE,
      order_briefing_documents_metadata: FieldStatus.READONLY,
      order_end_date: FieldStatus.READONLY,
      order_id: FieldStatus.READONLY,
      order_schedule_type: FieldStatus.READONLY,
      order_start_date: FieldStatus.READONLY,
      paid: FieldStatus.HIDDEN,
      pay_date: FieldStatus.HIDDEN,
      payroll_date: FieldStatus.HIDDEN,
      purchase_cost: FieldStatus.EDITABLE,
      purchase_image: FieldStatus.EDITABLE,
      query_category: FieldStatus.EDITABLE,
      query_notes: FieldStatus.EDITABLE,
      automatic_fail_letter_notification_email_list: FieldStatus.HIDDEN,
      automatic_fail_letter_notification_date: FieldStatus.READONLY,
      reclaimable_expenses_description: FieldStatus.EDITABLE,
      reclaimable_expenses_type: FieldStatus.EDITABLE,
      reclaimable_expenses: FieldStatus.EDITABLE,
      revision_branching: FieldStatus.READONLY,
      revision_logic: FieldStatus.READONLY,
      revision_questions: FieldStatus.READONLY,
      revision_reports: FieldStatus.READONLY,
      revision_validation: FieldStatus.READONLY,
      site_address_1: FieldStatus.READONLY,
      site_address_2: FieldStatus.READONLY,
      site_address_3: FieldStatus.READONLY,
      site_city: FieldStatus.READONLY,
      site_code: FieldStatus.READONLY,
      site_directions: FieldStatus.READONLY,
      site_id: FieldStatus.HIDDEN,
      site_name: FieldStatus.READONLY,
      site_post_code: FieldStatus.READONLY,
      status: FieldStatus.READONLY,
      submitted_date: FieldStatus.READONLY,
      survey_answers: FieldStatus.EDITABLE,
      survey_result: FieldStatus.READONLY,
      survey_score: FieldStatus.READONLY,
      tokens: FieldStatus.HIDDEN,
      travel_expenses_description: FieldStatus.EDITABLE,
      travel_expenses_type: FieldStatus.EDITABLE,
      travel_expenses: FieldStatus.EDITABLE,
      visit_info: FieldStatus.EDITABLE,
      expenses_receipts_urls: FieldStatus.EDITABLE,
      travel_expenses_images_urls: FieldStatus.EDITABLE,
    },
    client_query: {
      action_approve: FieldStatus.READONLY,
      action_clone: FieldStatus.READONLY,
      action_delete: FieldStatus.READONLY,
      action_reset: FieldStatus.HIDDEN,
      action_save: FieldStatus.READONLY,
      approval_date: FieldStatus.READONLY,
      approved_by_email: FieldStatus.READONLY,
      approved_by_name: FieldStatus.READONLY,
      approving_notes: FieldStatus.READONLY,
      auditor_all_visit_count: FieldStatus.READONLY,
      auditor_date_of_birth: FieldStatus.READONLY,
      auditor_first_narv_visit_date: FieldStatus.READONLY,
      auditor_first_sl_visit_date: FieldStatus.READONLY,
      auditor_gender: FieldStatus.READONLY,
      auditor_id: FieldStatus.READONLY,
      auditor_instructions: FieldStatus.READONLY,
      auditor_last_narv_visit_date: FieldStatus.READONLY,
      auditor_last_sl_visit_date: FieldStatus.READONLY,
      auditor_mobile_number: FieldStatus.READONLY,
      auditor_narv_visit_count: FieldStatus.READONLY,
      auditor_pass_rate: FieldStatus.READONLY,
      auditor_pay_per_audit: FieldStatus.EDITABLE,
      auditor_sl_visit_count: FieldStatus.READONLY,
      client_name: FieldStatus.READONLY,
      date_of_visit: FieldStatus.EDITABLE,
      deadline_date: FieldStatus.READONLY,
      email_note: FieldStatus.EDITABLE,
      email_report_distribution_list: FieldStatus.EDITABLE,
      entrance_charge: FieldStatus.EDITABLE,
      geolocation_date: FieldStatus.READONLY,
      item_to_order: FieldStatus.READONLY,
      matchings: FieldStatus.EDITABLE,
      mileage: FieldStatus.EDITABLE,
      mileage_type: FieldStatus.EDITABLE,
      mileage_description: FieldStatus.EDITABLE,
      order_briefing_documents_metadata: FieldStatus.READONLY,
      order_end_date: FieldStatus.READONLY,
      order_id: FieldStatus.READONLY,
      order_schedule_type: FieldStatus.READONLY,
      order_start_date: FieldStatus.READONLY,
      paid: FieldStatus.HIDDEN,
      pay_date: FieldStatus.HIDDEN,
      payroll_date: FieldStatus.HIDDEN,
      purchase_cost: FieldStatus.EDITABLE,
      purchase_image: FieldStatus.EDITABLE,
      query_category: FieldStatus.EDITABLE,
      query_notes: FieldStatus.EDITABLE,
      automatic_fail_letter_notification_email_list: FieldStatus.HIDDEN,
      automatic_fail_letter_notification_date: FieldStatus.READONLY,
      reclaimable_expenses_description: FieldStatus.EDITABLE,
      reclaimable_expenses_type: FieldStatus.EDITABLE,
      reclaimable_expenses: FieldStatus.EDITABLE,
      revision_branching: FieldStatus.READONLY,
      revision_logic: FieldStatus.READONLY,
      revision_questions: FieldStatus.READONLY,
      revision_reports: FieldStatus.READONLY,
      revision_validation: FieldStatus.READONLY,
      site_address_1: FieldStatus.READONLY,
      site_address_2: FieldStatus.READONLY,
      site_address_3: FieldStatus.READONLY,
      site_city: FieldStatus.READONLY,
      site_code: FieldStatus.READONLY,
      site_directions: FieldStatus.READONLY,
      site_id: FieldStatus.HIDDEN,
      site_name: FieldStatus.READONLY,
      site_post_code: FieldStatus.READONLY,
      status: FieldStatus.READONLY,
      submitted_date: FieldStatus.READONLY,
      survey_answers: FieldStatus.EDITABLE,
      survey_result: FieldStatus.READONLY,
      survey_score: FieldStatus.READONLY,
      tokens: FieldStatus.HIDDEN,
      travel_expenses_description: FieldStatus.EDITABLE,
      travel_expenses_type: FieldStatus.EDITABLE,
      travel_expenses: FieldStatus.EDITABLE,
      visit_info: FieldStatus.EDITABLE,
      expenses_receipts_urls: FieldStatus.EDITABLE,
      travel_expenses_images_urls: FieldStatus.EDITABLE,
    },
    deleted: {
      approval_date: FieldStatus.READONLY,
      approved_by_email: FieldStatus.READONLY,
      approved_by_name: FieldStatus.READONLY,
      approving_notes: FieldStatus.HIDDEN,
      auditor_all_visit_count: FieldStatus.READONLY,
      auditor_date_of_birth: FieldStatus.READONLY,
      auditor_first_narv_visit_date: FieldStatus.READONLY,
      auditor_first_sl_visit_date: FieldStatus.READONLY,
      auditor_gender: FieldStatus.READONLY,
      auditor_id: FieldStatus.READONLY,
      auditor_instructions: FieldStatus.HIDDEN,
      auditor_last_narv_visit_date: FieldStatus.READONLY,
      auditor_last_sl_visit_date: FieldStatus.READONLY,
      auditor_mobile_number: FieldStatus.READONLY,
      auditor_narv_visit_count: FieldStatus.READONLY,
      auditor_pass_rate: FieldStatus.READONLY,
      auditor_pay_per_audit: FieldStatus.READONLY,
      auditor_sl_visit_count: FieldStatus.READONLY,
      batched: FieldStatus.READONLY,
      client_name: FieldStatus.READONLY,
      date_of_visit: FieldStatus.READONLY,
      deadline_date: FieldStatus.READONLY,
      email_note: FieldStatus.READONLY,
      email_report_distribution_list: FieldStatus.READONLY,
      entrance_charge: FieldStatus.READONLY,
      item_to_order: FieldStatus.HIDDEN,
      matchings: FieldStatus.EDITABLE,
      mileage: FieldStatus.READONLY,
      mileage_type: FieldStatus.READONLY,
      mileage_description: FieldStatus.READONLY,
      order_briefing_documents_metadata: FieldStatus.HIDDEN,
      order_end_date: FieldStatus.READONLY,
      order_id: FieldStatus.READONLY,
      order_schedule_type: FieldStatus.READONLY,
      order_start_date: FieldStatus.READONLY,
      site_organisation_level_code: FieldStatus.READONLY,
      paid: FieldStatus.HIDDEN,
      payroll_date: FieldStatus.HIDDEN,
      pay_date: FieldStatus.HIDDEN,
      purchase_cost: FieldStatus.READONLY,
      purchase_image: FieldStatus.READONLY,
      query_category: FieldStatus.READONLY,
      query_notes: FieldStatus.READONLY,
      automatic_fail_letter_notification_email_list: FieldStatus.HIDDEN,
      automatic_fail_letter_notification_date: FieldStatus.READONLY,
      reclaimable_expenses_description: FieldStatus.READONLY,
      reclaimable_expenses_type: FieldStatus.READONLY,
      reclaimable_expenses: FieldStatus.READONLY,
      revision_branching: FieldStatus.READONLY,
      revision_logic: FieldStatus.READONLY,
      revision_questions: FieldStatus.READONLY,
      revision_reports: FieldStatus.READONLY,
      revision_validation: FieldStatus.READONLY,
      site_address_1: FieldStatus.READONLY,
      site_address_2: FieldStatus.READONLY,
      site_address_3: FieldStatus.READONLY,
      site_city: FieldStatus.READONLY,
      site_code: FieldStatus.READONLY,
      site_directions: FieldStatus.READONLY,
      site_id: FieldStatus.HIDDEN,
      site_name: FieldStatus.READONLY,
      site_post_code: FieldStatus.READONLY,
      status: FieldStatus.READONLY,
      submitted_date: FieldStatus.READONLY,
      survey_answers: FieldStatus.READONLY,
      survey_result: FieldStatus.READONLY,
      survey_score: FieldStatus.READONLY,
      tokens: FieldStatus.HIDDEN,
      travel_expenses_description: FieldStatus.READONLY,
      travel_expenses_type: FieldStatus.READONLY,
      travel_expenses: FieldStatus.READONLY,
      visit_info: FieldStatus.HIDDEN,
      expenses_receipts_urls: FieldStatus.EDITABLE,
      travel_expenses_images_urls: FieldStatus.EDITABLE,
    },
  },
  data: {
    unassigned: {
      action_delete: FieldStatus.READONLY,
      action_save: FieldStatus.READONLY,
      approving_notes: FieldStatus.EDITABLE,
      auditor_id: FieldStatus.EDITABLE,
      auditor_instructions: FieldStatus.EDITABLE,
      auditor_pay_per_audit: FieldStatus.EDITABLE,
      holiday_pay: FieldStatus.READONLY,
      total_pay: FieldStatus.READONLY,
      client_name: FieldStatus.READONLY,
      deadline_date: FieldStatus.EDITABLE,
      email_report_distribution_list: FieldStatus.EDITABLE,
      entrance_charge: FieldStatus.READONLY,
      geolocation_date: FieldStatus.READONLY,
      item_to_order: FieldStatus.EDITABLE,
      matchings: FieldStatus.EDITABLE,
      mileage: FieldStatus.READONLY,
      mileage_type: FieldStatus.EDITABLE,
      mileage_description: FieldStatus.EDITABLE,
      order_briefing_documents_metadata: FieldStatus.READONLY,
      order_end_date: FieldStatus.READONLY,
      order_id: FieldStatus.EDITABLE,
      order_schedule_type: FieldStatus.READONLY,
      order_start_date: FieldStatus.READONLY,
      paid: FieldStatus.READONLY,
      reclaimable_expenses_description: FieldStatus.READONLY,
      reclaimable_expenses_type: FieldStatus.READONLY,
      reclaimable_expenses: FieldStatus.READONLY,
      revision_branching: FieldStatus.READONLY,
      revision_logic: FieldStatus.READONLY,
      revision_questions: FieldStatus.READONLY,
      revision_reports: FieldStatus.READONLY,
      revision_validation: FieldStatus.READONLY,
      site_address_1: FieldStatus.READONLY,
      site_address_2: FieldStatus.READONLY,
      site_address_3: FieldStatus.READONLY,
      site_city: FieldStatus.READONLY,
      site_code: FieldStatus.READONLY,
      site_directions: FieldStatus.READONLY,
      site_id: FieldStatus.EDITABLE,
      site_organisation_level_code: FieldStatus.READONLY,
      site_post_code: FieldStatus.READONLY,
      status: FieldStatus.EDITABLE,
      survey_answers: FieldStatus.EDITABLE,
      tokens: FieldStatus.READONLY,
      travel_expenses_description: FieldStatus.READONLY,
      travel_expenses_type: FieldStatus.READONLY,
      travel_expenses: FieldStatus.READONLY,
      visit_info: FieldStatus.EDITABLE,
      expenses_receipts_urls: FieldStatus.EDITABLE,
      automatic_fail_letter_notification_email_list: FieldStatus.EDITABLE,
      automatic_fail_letter_notification_date: FieldStatus.READONLY,
      travel_expenses_images_urls: FieldStatus.EDITABLE,
    },
    assigned: {
      action_delete: FieldStatus.READONLY,
      action_save: FieldStatus.READONLY,
      action_submit: FieldStatus.READONLY,
      approving_notes: FieldStatus.EDITABLE,
      auditor_all_visit_count: FieldStatus.READONLY,
      auditor_date_of_birth: FieldStatus.READONLY,
      auditor_first_narv_visit_date: FieldStatus.READONLY,
      auditor_first_sl_visit_date: FieldStatus.READONLY,
      auditor_gender: FieldStatus.READONLY,
      auditor_id: FieldStatus.EDITABLE,
      auditor_instructions: FieldStatus.EDITABLE,
      auditor_last_narv_visit_date: FieldStatus.READONLY,
      auditor_last_sl_visit_date: FieldStatus.READONLY,
      auditor_mobile_number: FieldStatus.READONLY,
      auditor_narv_visit_count: FieldStatus.READONLY,
      auditor_pass_rate: FieldStatus.READONLY,
      auditor_pay_per_audit: FieldStatus.EDITABLE,
      holiday_pay: FieldStatus.READONLY,
      total_pay: FieldStatus.READONLY,
      auditor_sl_visit_count: FieldStatus.READONLY,
      batched: FieldStatus.EDITABLE,
      client_name: FieldStatus.READONLY,
      date_of_visit: FieldStatus.EDITABLE,
      deadline_date: FieldStatus.EDITABLE,
      email_note: FieldStatus.EDITABLE,
      email_report_distribution_list: FieldStatus.EDITABLE,
      entrance_charge: FieldStatus.EDITABLE,
      geolocation_date: FieldStatus.READONLY,
      item_to_order: FieldStatus.EDITABLE,
      matchings: FieldStatus.EDITABLE,
      mileage: FieldStatus.EDITABLE,
      mileage_type: FieldStatus.EDITABLE,
      mileage_description: FieldStatus.EDITABLE,
      order_briefing_documents_metadata: FieldStatus.READONLY,
      order_end_date: FieldStatus.READONLY,
      order_id: FieldStatus.EDITABLE,
      order_schedule_type: FieldStatus.READONLY,
      order_start_date: FieldStatus.READONLY,
      paid: FieldStatus.EDITABLE,
      pay_date: FieldStatus.EDITABLE,
      payroll_date: FieldStatus.EDITABLE,
      purchase_cost: FieldStatus.EDITABLE,
      purchase_image: FieldStatus.EDITABLE,
      reclaimable_expenses_description: FieldStatus.EDITABLE,
      reclaimable_expenses_type: FieldStatus.EDITABLE,
      reclaimable_expenses: FieldStatus.EDITABLE,
      revision_branching: FieldStatus.READONLY,
      revision_logic: FieldStatus.READONLY,
      revision_questions: FieldStatus.READONLY,
      revision_reports: FieldStatus.READONLY,
      revision_validation: FieldStatus.READONLY,
      site_address_1: FieldStatus.READONLY,
      site_address_2: FieldStatus.READONLY,
      site_address_3: FieldStatus.READONLY,
      site_city: FieldStatus.READONLY,
      site_code: FieldStatus.READONLY,
      site_directions: FieldStatus.READONLY,
      site_id: FieldStatus.EDITABLE,
      site_organisation_level_code: FieldStatus.READONLY,
      site_post_code: FieldStatus.READONLY,
      status: FieldStatus.EDITABLE,
      survey_answers: FieldStatus.EDITABLE,
      tokens: FieldStatus.READONLY,
      travel_expenses_description: FieldStatus.EDITABLE,
      travel_expenses_type: FieldStatus.EDITABLE,
      travel_expenses: FieldStatus.EDITABLE,
      visit_info: FieldStatus.EDITABLE,
      expenses_receipts_urls: FieldStatus.EDITABLE,
      automatic_fail_letter_notification_email_list: FieldStatus.EDITABLE,
      automatic_fail_letter_notification_date: FieldStatus.READONLY,
      travel_expenses_images_urls: FieldStatus.EDITABLE,
    },
    submitted: {
      action_approve: FieldStatus.READONLY,
      action_delete: FieldStatus.READONLY,
      action_query: FieldStatus.READONLY,
      action_reset_reassign: FieldStatus.READONLY,
      action_reset: FieldStatus.READONLY,
      action_save: FieldStatus.READONLY,
      approving_notes: FieldStatus.EDITABLE,
      auditor_all_visit_count: FieldStatus.READONLY,
      auditor_date_of_birth: FieldStatus.READONLY,
      auditor_first_narv_visit_date: FieldStatus.READONLY,
      auditor_first_sl_visit_date: FieldStatus.READONLY,
      auditor_gender: FieldStatus.READONLY,
      auditor_id: FieldStatus.EDITABLE,
      auditor_instructions: FieldStatus.EDITABLE,
      auditor_last_narv_visit_date: FieldStatus.READONLY,
      auditor_last_sl_visit_date: FieldStatus.READONLY,
      auditor_mobile_number: FieldStatus.READONLY,
      auditor_narv_visit_count: FieldStatus.READONLY,
      auditor_pass_rate: FieldStatus.READONLY,
      auditor_pay_per_audit: FieldStatus.EDITABLE,
      holiday_pay: FieldStatus.READONLY,
      total_pay: FieldStatus.READONLY,
      auditor_sl_visit_count: FieldStatus.READONLY,
      batched: FieldStatus.EDITABLE,
      client_name: FieldStatus.READONLY,
      date_of_visit: FieldStatus.EDITABLE,
      deadline_date: FieldStatus.EDITABLE,
      email_note: FieldStatus.EDITABLE,
      email_report_distribution_list: FieldStatus.EDITABLE,
      entrance_charge: FieldStatus.EDITABLE,
      geolocation_date: FieldStatus.READONLY,
      item_to_order: FieldStatus.EDITABLE,
      matchings: FieldStatus.EDITABLE,
      mileage: FieldStatus.EDITABLE,
      mileage_type: FieldStatus.EDITABLE,
      mileage_description: FieldStatus.EDITABLE,
      order_briefing_documents_metadata_metadata: FieldStatus.READONLY,
      order_end_date: FieldStatus.READONLY,
      order_id: FieldStatus.EDITABLE,
      order_schedule_type: FieldStatus.READONLY,
      order_start_date: FieldStatus.READONLY,
      paid: FieldStatus.EDITABLE,
      pay_date: FieldStatus.EDITABLE,
      payroll_date: FieldStatus.EDITABLE,
      purchase_cost: FieldStatus.EDITABLE,
      purchase_image: FieldStatus.EDITABLE,
      query_category: FieldStatus.EDITABLE,
      query_notes: FieldStatus.EDITABLE,
      automatic_fail_letter_notification_email_list: FieldStatus.EDITABLE,
      automatic_fail_letter_notification_date: FieldStatus.READONLY,
      reclaimable_expenses_description: FieldStatus.EDITABLE,
      reclaimable_expenses_type: FieldStatus.EDITABLE,
      reclaimable_expenses: FieldStatus.EDITABLE,
      revision_branching: FieldStatus.READONLY,
      revision_logic: FieldStatus.READONLY,
      revision_questions: FieldStatus.READONLY,
      revision_reports: FieldStatus.READONLY,
      revision_validation: FieldStatus.READONLY,
      site_address_1: FieldStatus.READONLY,
      site_address_2: FieldStatus.READONLY,
      site_address_3: FieldStatus.READONLY,
      site_city: FieldStatus.READONLY,
      site_code: FieldStatus.READONLY,
      site_directions: FieldStatus.READONLY,
      site_id: FieldStatus.EDITABLE,
      site_organisation_level_code: FieldStatus.READONLY,
      site_post_code: FieldStatus.READONLY,
      status: FieldStatus.EDITABLE,
      submitted_date: FieldStatus.READONLY,
      survey_answers: FieldStatus.EDITABLE,
      survey_result: FieldStatus.READONLY,
      survey_score: FieldStatus.READONLY,
      tokens: FieldStatus.READONLY,
      travel_expenses_description: FieldStatus.EDITABLE,
      travel_expenses_type: FieldStatus.EDITABLE,
      travel_expenses: FieldStatus.EDITABLE,
      visit_info: FieldStatus.EDITABLE,
      expenses_receipts_urls: FieldStatus.EDITABLE,
      travel_expenses_images_urls: FieldStatus.EDITABLE,
    },
    approved: {
      action_client_query: FieldStatus.READONLY,
      action_delete: FieldStatus.READONLY,
      action_save: FieldStatus.READONLY,
      approval_date: FieldStatus.READONLY,
      approved_by_email: FieldStatus.READONLY,
      approved_by_name: FieldStatus.READONLY,
      approving_notes: FieldStatus.EDITABLE,
      auditor_all_visit_count: FieldStatus.READONLY,
      auditor_date_of_birth: FieldStatus.READONLY,
      auditor_first_narv_visit_date: FieldStatus.READONLY,
      auditor_first_sl_visit_date: FieldStatus.READONLY,
      auditor_gender: FieldStatus.READONLY,
      auditor_id: FieldStatus.EDITABLE,
      auditor_instructions: FieldStatus.EDITABLE,
      auditor_last_narv_visit_date: FieldStatus.READONLY,
      auditor_last_sl_visit_date: FieldStatus.READONLY,
      auditor_mobile_number: FieldStatus.READONLY,
      auditor_narv_visit_count: FieldStatus.READONLY,
      auditor_pass_rate: FieldStatus.READONLY,
      auditor_pay_per_audit: FieldStatus.EDITABLE,
      holiday_pay: FieldStatus.READONLY,
      total_pay: FieldStatus.READONLY,
      auditor_sl_visit_count: FieldStatus.READONLY,
      batched: FieldStatus.EDITABLE,
      client_name: FieldStatus.READONLY,
      date_of_visit: FieldStatus.EDITABLE,
      deadline_date: FieldStatus.EDITABLE,
      email_note: FieldStatus.EDITABLE,
      email_report_distribution_list: FieldStatus.EDITABLE,
      entrance_charge: FieldStatus.EDITABLE,
      geolocation_date: FieldStatus.READONLY,
      item_to_order: FieldStatus.EDITABLE,
      matchings: FieldStatus.EDITABLE,
      mileage: FieldStatus.EDITABLE,
      mileage_type: FieldStatus.EDITABLE,
      mileage_description: FieldStatus.EDITABLE,
      order_briefing_documents_metadata: FieldStatus.READONLY,
      order_end_date: FieldStatus.READONLY,
      order_id: FieldStatus.EDITABLE,
      order_schedule_type: FieldStatus.READONLY,
      order_start_date: FieldStatus.READONLY,
      paid: FieldStatus.EDITABLE,
      pay_date: FieldStatus.EDITABLE,
      payroll_date: FieldStatus.EDITABLE,
      purchase_cost: FieldStatus.EDITABLE,
      purchase_image: FieldStatus.EDITABLE,
      query_category: FieldStatus.EDITABLE,
      query_notes: FieldStatus.EDITABLE,
      automatic_fail_letter_notification_email_list: FieldStatus.EDITABLE,
      automatic_fail_letter_notification_date: FieldStatus.READONLY,
      reclaimable_expenses_description: FieldStatus.EDITABLE,
      reclaimable_expenses_type: FieldStatus.EDITABLE,
      reclaimable_expenses: FieldStatus.EDITABLE,
      revision_branching: FieldStatus.READONLY,
      revision_logic: FieldStatus.READONLY,
      revision_questions: FieldStatus.READONLY,
      revision_reports: FieldStatus.READONLY,
      revision_validation: FieldStatus.READONLY,
      site_address_1: FieldStatus.READONLY,
      site_address_2: FieldStatus.READONLY,
      site_address_3: FieldStatus.READONLY,
      site_city: FieldStatus.READONLY,
      site_code: FieldStatus.READONLY,
      site_directions: FieldStatus.READONLY,
      site_id: FieldStatus.EDITABLE,
      site_organisation_level_code: FieldStatus.READONLY,
      site_post_code: FieldStatus.READONLY,
      status: FieldStatus.EDITABLE,
      submitted_date: FieldStatus.READONLY,
      survey_answers: FieldStatus.EDITABLE,
      survey_result: FieldStatus.READONLY,
      survey_score: FieldStatus.READONLY,
      tokens: FieldStatus.READONLY,
      travel_expenses_description: FieldStatus.EDITABLE,
      travel_expenses_type: FieldStatus.EDITABLE,
      travel_expenses: FieldStatus.EDITABLE,
      visit_info: FieldStatus.EDITABLE,
      expenses_receipts_urls: FieldStatus.EDITABLE,
      travel_expenses_images_urls: FieldStatus.EDITABLE,
    },
    approving_query: {
      action_approve: FieldStatus.READONLY,
      action_delete: FieldStatus.READONLY,
      action_reset_reassign: FieldStatus.READONLY,
      action_reset: FieldStatus.READONLY,
      action_save: FieldStatus.READONLY,
      approval_date: FieldStatus.READONLY,
      approved_by_email: FieldStatus.READONLY,
      approved_by_name: FieldStatus.READONLY,
      approving_notes: FieldStatus.EDITABLE,
      auditor_all_visit_count: FieldStatus.READONLY,
      auditor_date_of_birth: FieldStatus.READONLY,
      auditor_first_narv_visit_date: FieldStatus.READONLY,
      auditor_first_sl_visit_date: FieldStatus.READONLY,
      auditor_gender: FieldStatus.READONLY,
      auditor_id: FieldStatus.EDITABLE,
      auditor_instructions: FieldStatus.EDITABLE,
      auditor_last_narv_visit_date: FieldStatus.READONLY,
      auditor_last_sl_visit_date: FieldStatus.READONLY,
      auditor_mobile_number: FieldStatus.READONLY,
      auditor_narv_visit_count: FieldStatus.READONLY,
      auditor_pass_rate: FieldStatus.READONLY,
      auditor_pay_per_audit: FieldStatus.EDITABLE,
      auditor_sl_visit_count: FieldStatus.READONLY,
      batched: FieldStatus.EDITABLE,
      client_name: FieldStatus.READONLY,
      date_of_visit: FieldStatus.EDITABLE,
      deadline_date: FieldStatus.EDITABLE,
      email_note: FieldStatus.EDITABLE,
      email_report_distribution_list: FieldStatus.EDITABLE,
      entrance_charge: FieldStatus.EDITABLE,
      geolocation_date: FieldStatus.READONLY,
      item_to_order: FieldStatus.EDITABLE,
      matchings: FieldStatus.EDITABLE,
      mileage: FieldStatus.EDITABLE,
      mileage_type: FieldStatus.EDITABLE,
      mileage_description: FieldStatus.EDITABLE,
      order_briefing_documents_metadata: FieldStatus.READONLY,
      order_end_date: FieldStatus.READONLY,
      order_id: FieldStatus.EDITABLE,
      order_schedule_type: FieldStatus.READONLY,
      order_start_date: FieldStatus.READONLY,
      paid: FieldStatus.EDITABLE,
      pay_date: FieldStatus.EDITABLE,
      payroll_date: FieldStatus.EDITABLE,
      purchase_cost: FieldStatus.EDITABLE,
      purchase_image: FieldStatus.EDITABLE,
      query_category: FieldStatus.EDITABLE,
      query_notes: FieldStatus.EDITABLE,
      automatic_fail_letter_notification_email_list: FieldStatus.EDITABLE,
      automatic_fail_letter_notification_date: FieldStatus.READONLY,
      reclaimable_expenses_description: FieldStatus.EDITABLE,
      reclaimable_expenses_type: FieldStatus.EDITABLE,
      reclaimable_expenses: FieldStatus.EDITABLE,
      revision_branching: FieldStatus.READONLY,
      revision_logic: FieldStatus.READONLY,
      revision_questions: FieldStatus.READONLY,
      revision_reports: FieldStatus.READONLY,
      revision_validation: FieldStatus.READONLY,
      site_address_1: FieldStatus.READONLY,
      site_address_2: FieldStatus.READONLY,
      site_address_3: FieldStatus.READONLY,
      site_city: FieldStatus.READONLY,
      site_code: FieldStatus.READONLY,
      site_directions: FieldStatus.READONLY,
      site_id: FieldStatus.EDITABLE,
      site_organisation_level_code: FieldStatus.READONLY,
      site_post_code: FieldStatus.READONLY,
      status: FieldStatus.EDITABLE,
      submitted_date: FieldStatus.READONLY,
      survey_answers: FieldStatus.EDITABLE,
      survey_result: FieldStatus.READONLY,
      survey_score: FieldStatus.READONLY,
      tokens: FieldStatus.READONLY,
      travel_expenses_description: FieldStatus.EDITABLE,
      travel_expenses_type: FieldStatus.EDITABLE,
      travel_expenses: FieldStatus.EDITABLE,
      visit_info: FieldStatus.EDITABLE,
      expenses_receipts_urls: FieldStatus.EDITABLE,
      travel_expenses_images_urls: FieldStatus.EDITABLE,
    },
    client_query: {
      action_approve: FieldStatus.READONLY,
      action_clone: FieldStatus.READONLY,
      action_delete: FieldStatus.READONLY,
      action_reset_reassign: FieldStatus.READONLY,
      action_reset: FieldStatus.READONLY,
      action_save: FieldStatus.READONLY,
      approval_date: FieldStatus.READONLY,
      approved_by_email: FieldStatus.READONLY,
      approved_by_name: FieldStatus.READONLY,
      approving_notes: FieldStatus.EDITABLE,
      auditor_all_visit_count: FieldStatus.READONLY,
      auditor_date_of_birth: FieldStatus.READONLY,
      auditor_first_narv_visit_date: FieldStatus.READONLY,
      auditor_first_sl_visit_date: FieldStatus.READONLY,
      auditor_gender: FieldStatus.READONLY,
      auditor_id: FieldStatus.EDITABLE,
      auditor_instructions: FieldStatus.EDITABLE,
      auditor_last_narv_visit_date: FieldStatus.READONLY,
      auditor_last_sl_visit_date: FieldStatus.READONLY,
      auditor_mobile_number: FieldStatus.READONLY,
      auditor_narv_visit_count: FieldStatus.READONLY,
      auditor_pass_rate: FieldStatus.READONLY,
      auditor_pay_per_audit: FieldStatus.EDITABLE,
      holiday_pay: FieldStatus.READONLY,
      total_pay: FieldStatus.READONLY,
      auditor_sl_visit_count: FieldStatus.READONLY,
      batched: FieldStatus.EDITABLE,
      client_name: FieldStatus.READONLY,
      date_of_visit: FieldStatus.EDITABLE,
      deadline_date: FieldStatus.EDITABLE,
      email_note: FieldStatus.EDITABLE,
      email_report_distribution_list: FieldStatus.EDITABLE,
      entrance_charge: FieldStatus.EDITABLE,
      geolocation_date: FieldStatus.READONLY,
      item_to_order: FieldStatus.EDITABLE,
      matchings: FieldStatus.EDITABLE,
      mileage: FieldStatus.EDITABLE,
      mileage_type: FieldStatus.EDITABLE,
      mileage_description: FieldStatus.EDITABLE,
      order_briefing_documents_metadata: FieldStatus.READONLY,
      order_end_date: FieldStatus.READONLY,
      order_id: FieldStatus.EDITABLE,
      order_schedule_type: FieldStatus.READONLY,
      order_start_date: FieldStatus.READONLY,
      paid: FieldStatus.EDITABLE,
      pay_date: FieldStatus.EDITABLE,
      payroll_date: FieldStatus.EDITABLE,
      purchase_cost: FieldStatus.EDITABLE,
      purchase_image: FieldStatus.EDITABLE,
      query_category: FieldStatus.EDITABLE,
      query_notes: FieldStatus.EDITABLE,
      automatic_fail_letter_notification_email_list: FieldStatus.EDITABLE,
      automatic_fail_letter_notification_date: FieldStatus.READONLY,
      reclaimable_expenses_description: FieldStatus.EDITABLE,
      reclaimable_expenses_type: FieldStatus.EDITABLE,
      reclaimable_expenses: FieldStatus.EDITABLE,
      revision_branching: FieldStatus.READONLY,
      revision_logic: FieldStatus.READONLY,
      revision_questions: FieldStatus.READONLY,
      revision_reports: FieldStatus.READONLY,
      revision_validation: FieldStatus.READONLY,
      site_address_1: FieldStatus.READONLY,
      site_address_2: FieldStatus.READONLY,
      site_address_3: FieldStatus.READONLY,
      site_city: FieldStatus.READONLY,
      site_code: FieldStatus.READONLY,
      site_directions: FieldStatus.READONLY,
      site_id: FieldStatus.EDITABLE,
      site_organisation_level_code: FieldStatus.READONLY,
      site_post_code: FieldStatus.READONLY,
      status: FieldStatus.EDITABLE,
      submitted_date: FieldStatus.READONLY,
      survey_answers: FieldStatus.EDITABLE,
      survey_result: FieldStatus.READONLY,
      survey_score: FieldStatus.READONLY,
      tokens: FieldStatus.READONLY,
      travel_expenses_description: FieldStatus.EDITABLE,
      travel_expenses_type: FieldStatus.EDITABLE,
      travel_expenses: FieldStatus.EDITABLE,
      visit_info: FieldStatus.EDITABLE,
      expenses_receipts_urls: FieldStatus.EDITABLE,
      travel_expenses_images_urls: FieldStatus.EDITABLE,
    },
    deleted: {
      action_delete: FieldStatus.READONLY,
      action_save: FieldStatus.READONLY,
      approval_date: FieldStatus.READONLY,
      approved_by_email: FieldStatus.READONLY,
      approved_by_name: FieldStatus.READONLY,
      approving_notes: FieldStatus.READONLY,
      auditor_all_visit_count: FieldStatus.READONLY,
      auditor_date_of_birth: FieldStatus.READONLY,
      auditor_first_narv_visit_date: FieldStatus.READONLY,
      auditor_first_sl_visit_date: FieldStatus.READONLY,
      auditor_gender: FieldStatus.READONLY,
      auditor_id: FieldStatus.READONLY,
      auditor_instructions: FieldStatus.READONLY,
      auditor_last_narv_visit_date: FieldStatus.READONLY,
      auditor_last_sl_visit_date: FieldStatus.READONLY,
      auditor_mobile_number: FieldStatus.READONLY,
      auditor_narv_visit_count: FieldStatus.READONLY,
      auditor_pass_rate: FieldStatus.READONLY,
      auditor_pay_per_audit: FieldStatus.READONLY,
      holiday_pay: FieldStatus.READONLY,
      total_pay: FieldStatus.READONLY,
      auditor_sl_visit_count: FieldStatus.READONLY,
      batched: FieldStatus.READONLY,
      client_name: FieldStatus.READONLY,
      date_of_visit: FieldStatus.READONLY,
      deadline_date: FieldStatus.READONLY,
      email_note: FieldStatus.READONLY,
      email_report_distribution_list: FieldStatus.READONLY,
      entrance_charge: FieldStatus.READONLY,
      geolocation_date: FieldStatus.READONLY,
      item_to_order: FieldStatus.READONLY,
      matchings: FieldStatus.EDITABLE,
      mileage: FieldStatus.READONLY,
      mileage_type: FieldStatus.READONLY,
      mileage_description: FieldStatus.READONLY,
      order_briefing_documents_metadata: FieldStatus.READONLY,
      order_end_date: FieldStatus.READONLY,
      order_id: FieldStatus.READONLY,
      order_schedule_type: FieldStatus.READONLY,
      order_start_date: FieldStatus.READONLY,
      paid: FieldStatus.READONLY,
      pay_date: FieldStatus.READONLY,
      payroll_date: FieldStatus.READONLY,
      purchase_cost: FieldStatus.READONLY,
      purchase_image: FieldStatus.READONLY,
      query_category: FieldStatus.EDITABLE,
      query_notes: FieldStatus.EDITABLE,
      automatic_fail_letter_notification_email_list: FieldStatus.EDITABLE,
      reclaimable_expenses_description: FieldStatus.READONLY,
      reclaimable_expenses_type: FieldStatus.READONLY,
      reclaimable_expenses: FieldStatus.READONLY,
      revision_branching: FieldStatus.READONLY,
      revision_logic: FieldStatus.READONLY,
      revision_questions: FieldStatus.READONLY,
      revision_reports: FieldStatus.READONLY,
      revision_validation: FieldStatus.READONLY,
      site_address_1: FieldStatus.READONLY,
      site_address_2: FieldStatus.READONLY,
      site_address_3: FieldStatus.READONLY,
      site_city: FieldStatus.READONLY,
      site_code: FieldStatus.READONLY,
      site_directions: FieldStatus.READONLY,
      site_name: FieldStatus.READONLY,
      site_organisation_level_code: FieldStatus.READONLY,
      site_post_code: FieldStatus.READONLY,
      status: FieldStatus.EDITABLE,
      submitted_date: FieldStatus.READONLY,
      survey_answers: FieldStatus.EDITABLE,
      survey_result: FieldStatus.READONLY,
      survey_score: FieldStatus.READONLY,
      tokens: FieldStatus.READONLY,
      travel_expenses_description: FieldStatus.READONLY,
      travel_expenses_type: FieldStatus.READONLY,
      travel_expenses: FieldStatus.READONLY,
      visit_info: FieldStatus.READONLY,
      expenses_receipts_urls: FieldStatus.EDITABLE,
      travel_expenses_images_urls: FieldStatus.EDITABLE,
    },
  },
};

const getFieldStatus = (userRole, auditStatus, revisionId = null) => fieldName => {
  if (revisionId) {
    return FieldStatus.READONLY;
  }
  return conditionObject[userRole]?.[auditStatus]?.[fieldName] || FieldStatus.HIDDEN;
};

export default getFieldStatus;

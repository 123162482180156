import FieldStatus from './fieldStatus';

const statusLogic = {
  unapproved: {
    action_active: FieldStatus.READONLY,
    action_rejected: FieldStatus.READONLY,
    action_on_hold: FieldStatus.READONLY,
  },
  active: {
    action_inactive: FieldStatus.READONLY,
  },
  inactive: {
    action_active: FieldStatus.READONLY,
  },
  on_hold: {
    action_active: FieldStatus.READONLY,
    action_rejected: FieldStatus.READONLY,
  },
};

const conditionObject = {
  area_manager: statusLogic,
  client_services: statusLogic,
  data: statusLogic,
};

const getFieldStatus = (userRole, currentAuditorStatus, fieldName) => (
  conditionObject[userRole]?.[currentAuditorStatus]?.[fieldName] || FieldStatus.HIDDEN
);

export default getFieldStatus;

import PropTypes from 'prop-types';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

import { Entity, getEntityMetadataByValue, UserRole } from '../../../../../constants';
import { SLHeader } from '../../../../common';
import { RoleAndEntityChecker } from '../../../../layout';
import styles from './EntityDetailsHeader.module.scss';

const EntityDetailsHeader = ({ data, titleProp, onCancel, onSave }) => {
  const { entity, entityId } = useParams();

  const DATA_CY = `${entity}-details-header`;
  const metadata = getEntityMetadataByValue(entity);

  return (
    <SLHeader
      dataCy={DATA_CY}
      title={entityId
        ? `Edit ${data?.[titleProp] || metadata?.singular || entity}`
        : `Add ${metadata?.singular || entity}`}
    >
      {entityId ? (
        <RoleAndEntityChecker
          allowedEntities={[Entity.AUDITS, Entity.ORDERS, Entity.SITES]}
          allowedRoles={[UserRole.DATA]}
        >
          <Link data-cy={`${DATA_CY}-revisions-link`} to={`/${entity}/${entityId}/revisions`}>
            <span className={styles.revisions}>
              {'Revisions'}
            </span>
          </Link>
        </RoleAndEntityChecker>
      ) : null}

      {onSave && (
        <RoleAndEntityChecker
          allowedRoleToEntityMatrix={{
            [UserRole.AREA_MANAGER]: [Entity.AUDITORS],
            [UserRole.CLIENT_SERVICES]: [
              Entity.AUDITORS, Entity.ORDERS, Entity.SITES, Entity.TAXONOMY, Entity.USERS,
            ],
            [UserRole.DATA]: [
              Entity.AUDITORS, Entity.CLIENTS, Entity.LOGICS, Entity.ORDERS, Entity.SITES,
              Entity.SURVEYS, Entity.TAXONOMY, Entity.USERS,
            ],
          }}
        >
          <Button
            primary
            className={styles.save}
            data-cy={`${DATA_CY}-save-button`}
            type="submit"
            onClick={onSave}
          >
            {'Save'}
          </Button>
        </RoleAndEntityChecker>)}

      <Button
        data-cy={`${DATA_CY}-cancel-button`}
        onClick={onCancel}
      >
        {'Cancel'}
      </Button>
    </SLHeader>
  );
};

EntityDetailsHeader.defaultProps = {
  data: null,
  onSave: null,
  titleProp: 'internal_id',
};

EntityDetailsHeader.propTypes = {
  onCancel: PropTypes.func.isRequired,
  data: PropTypes.object,
  titleProp: PropTypes.string,
  onSave: PropTypes.func,
};

export default EntityDetailsHeader;

import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form } from 'semantic-ui-react';
import * as Yup from 'yup';

import styles from './VerifyEmailForm.module.scss';

const DATA_CY = 'request-password-form';

const defaultValues = {
  code: '',
};

const VerifyEmailSchema = Yup.object().shape({
  code: Yup.string().required('Please enter the code received in your email'),
});

const VerifyEmailForm = ({ verifyEmail }) => (
  <Formik
    initialValues={defaultValues}
    validationSchema={VerifyEmailSchema}
    onSubmit={({ code }, formikBag) => {
      verifyEmail(code);
      formikBag.resetForm(defaultValues);
    }}
  >
    {({ values, handleChange, errors, touched, handleSubmit }) => (
      <Form noValidate className={styles.root} data-cy={DATA_CY} onSubmit={handleSubmit}>
        <Form.Input
          className={styles.emailInput}
          data-cy={`${DATA_CY}-code-input`}
          error={errors.code && touched.code ? errors.code : null}
          id="code"
          name="code"
          placeholder="Code received"
          type="password"
          value={values.code}
          onChange={handleChange}
        />
        <Button
          primary
          data-cy={`${DATA_CY}-submit-button`}
          size="big"
          type="submit"
        >
          {'Validate Email'}
        </Button>
      </Form>
    )}
  </Formik>
);

VerifyEmailForm.propTypes = {
  verifyEmail: PropTypes.func.isRequired,
};

export default VerifyEmailForm;

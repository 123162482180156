import React from 'react';

import logoBsi from '../../../../../assets/images/bsi-logo.png';
import logoIso from '../../../../../assets/images/iso-logo.png';
import FormLayout from '../../RegisterAuditorFormLayout/RegisterAuditorFormLayout.component';
import styles from './RegisterAuditorFormStepFive.module.scss';

const RegisterAuditorFormStepFive = () => (
  <FormLayout subtitle="Upload images" title="Read">
    <p className={styles.textImages}>
      {'Please, read the instructions before uploading the images.'}
    </p>
    <p className={styles.textImages}>
      {'To process your application, we\'re about to request 3 images from you, which are required to prove your right to work and that you have the necessary forms of ID to carry out jobs.'}
    </p>
    <p className={styles.textImages}>
      {'Serve Legal is accredited by BSI and ISO27001 information security standards to ensure that your data is handled securely. If at any time you wish to terminate your request, we will delete all the data and images that you have provided.'}
    </p>
    <div className={styles.footer}>
      <img alt="bsi-logo" className={styles.logo} src={logoBsi} />
      <img alt="iso-logo" className={styles.logo} src={logoIso} />
    </div>
  </FormLayout>
);

export default RegisterAuditorFormStepFive;

import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

import history from '../../../../history';
import styles from './AuditingError.module.scss';

const AuditingError = () => (
  <div className={styles.content}>
    <Icon className={styles.errorIcon} name="exclamation triangle" size="huge"/>
    <h2>
      {'Error sending your audit. Try again later'}
    </h2>
    <Button
      primary
      size="large"
      onClick={() => history.push('/audits?status=assigned')}
    >
      {'Back to audits'}
    </Button>
  </div>
);

export default AuditingError;

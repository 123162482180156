import { Field } from 'formik';
import React from 'react';
import { Form, Segment } from 'semantic-ui-react';
import * as Yup from 'yup';

import { QuestionType } from '../../../../../constants';
import commonStyles from '../AuditingQuestion.module.scss';
import AuditingQuestionHeader from '../AuditingQuestionHeader/AuditingQuestionHeader.component';
import styles from './AuditingSelectorQuestion.module.scss';

const DATA_CY = 'auditing-selector-question';

const validate = question => value => {
  let error;

  let currentSchema;
  if (question.type === QuestionType.CHECKBOX) {
    currentSchema = question.required
      ? Yup.array().min(1, 'Required')
      : Yup.array().nullable();
  } else {
    currentSchema = question.required
      ? Yup.string().typeError('Required')
      : Yup.string().nullable();
  }

  try {
    currentSchema.validateSync(value);
  } catch (err) {
    error = err.message;
  }

  return error;
};

const AuditingSelectorQuestion = () => (
  <div className={commonStyles.question} data-cy={DATA_CY}>
    <Field name="question">
      {({ field: { value: question } }) => (
        <>
          <AuditingQuestionHeader question={question} />

          <Field name="answer" validate={validate(question)}>
            {({
              field: { value: answer, name: answerName },
              form: { setFieldValue },
              meta: { error },
            }) => {
              if (question.type === QuestionType.CHECKBOX) {
                return (
                  <>
                    <Form.Group className={styles.box}>
                      {question.config.options.map(option => (
                        <Segment
                          key={option.value}
                          className={styles.checkbox}
                        >
                          <Form.Checkbox
                            key={option.value}
                            checked={answer.includes(option.value) || false}
                            label={option.label}
                            value={option.value}
                            onChange={(_e, { checked }) => {
                              if (checked) {
                                setFieldValue(answerName, [...answer, option.value]);
                              } else {
                                setFieldValue(answerName, answer.filter(value => value !== option.value));
                              }
                            }}
                          />
                        </Segment>
                      ))}
                    </Form.Group>
                    <div className={commonStyles.error}>
                      {error}
                    </div>
                  </>
                );
              }

              if (question.type === QuestionType.RADIO) {
                return (
                  <>
                    <Form.Group className={styles.box}>
                      {question.config.options.map(option => (
                        <Segment
                          key={option.value}
                          className={styles.radio}
                          onClick={() => setFieldValue('answer', option.value)}
                        >
                          <Form.Radio
                            checked={answer === option.value}
                            label={option.label}
                            value={option.value}
                            onChange={(_e, { value }) => setFieldValue('answer', value)}
                          />
                        </Segment>
                      ))}
                    </Form.Group>
                    <div className={commonStyles.error}>
                      {error}
                    </div>
                  </>
                );
              }

              return (
                <>
                  <Form.Select
                    fluid
                    className={styles.dropdown}
                    options={question.config.options.map(option => ({
                      value: option.value,
                      text: option.label,
                    }))}
                    placeholder="Please select..."
                    value={answer}
                    onChange={(_e, { value }) => setFieldValue('answer', value)}
                  />
                  <div className={commonStyles.error}>
                    {error}
                  </div>
                </>
              );
            }}
          </Field>
        </>
      )}
    </Field>
  </div>
);

export default AuditingSelectorQuestion;

import { connect } from 'react-redux';

import history from '../../../../history';
import { updateTaxonomy } from '../../../../state/constants/actionCreators';
import EntityDetailsView from './EntityDetailsView.component';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch, { match: { params: { entity, entityId } } }) => ({
  cancel: () => {
    history.push(`/${entity}`);
  },
  edit: () => {
    history.push(`/${entity}/${entityId}/edit`);
  },
  saveTaxonomy: payload => {
    dispatch(updateTaxonomy({ payload }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EntityDetailsView);

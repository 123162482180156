const imageTypeOptions = [
  {
    text: 'Receipt',
    value: 'receipt',
  },
  {
    text: 'Invoice',
    value: 'invoice',
  },
  {
    text: 'Content',
    value: 'content',
  },
  {
    text: 'Other',
    value: 'other',
  }];

export {
  imageTypeOptions,
};

const AuditorAccountTabs = {
  AUDITOR_PROFILE: {
    name: 'Auditor Profile',
    component: 'editAuditorProfileTab',
  },
  AUDITOR_INFO: {
    name: 'Auditor Information',
    component: 'editAuditorInfoTab',
  },
  BANK_INFO: {
    name: 'Bank Information',
    component: 'editBankInfoTab',
  },
  SERVELEGAL_ANSWER: {
    name: 'ServeLegal answers',
    component: 'editAnswersTab',
  },
};

module.exports = {
  AuditorAccountTabs,
};

import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'semantic-ui-react';

import styles from './SurveyStepFooter.module.scss';

const SurveyStepFooter = ({ back, backContent, dataCy, next, nextContent }) => (
  <div className={styles.navigation}>
    {back && (
      <Button
        className={styles.back}
        data-cy={`${dataCy}-back-button`}
        onClick={back}
      >
        {backContent}
      </Button>
    )}
    {next && (
      <Button
        primary
        className={styles.next}
        data-cy={`${dataCy}-next-button`}
        type="submit"
        onClick={next}
      >
        {nextContent}
      </Button>
    )}
  </div>
);

SurveyStepFooter.defaultProps = {
  back: null,
  next: null,
};

SurveyStepFooter.propTypes = {
  backContent: PropTypes.string.isRequired,
  dataCy: PropTypes.string.isRequired,
  nextContent: PropTypes.string.isRequired,
  back: PropTypes.func,
  next: PropTypes.func,
};

export default SurveyStepFooter;

import { UserRole } from '../../constants';
import { TableViewActions } from '../helpers';

const tableViewActions = {
  [UserRole.DATA]: [
    TableViewActions.ADD,
  ],
};

export default {
  logics: {
    tableViewActions,
  },
};

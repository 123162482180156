import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { entitiesAPI, errorAPI } from '../../../../../api';
import { AuditsConst, Entity, EntityState, UserRole } from '../../../../../constants';
import getFieldStatus from '../../../../../fieldLogic/audit';
import FieldStatus from '../../../../../fieldLogic/fieldStatus';
import { useUserRole } from '../../../../../hooks';
import { taxonomyTermSelector } from '../../../../../state/constants/selectors';
import {
  FormFieldCurrencyWrapper,
  FormFieldDateTimeWrapper,
  FormFieldDynamicSearchWrapper,
  FormFieldSelectWrapper,
  FormFieldWrapper,
} from '../../../../common';
import { uuidType } from '../../../../types';
import MapAuxiliary from '../../../Map/MapAuxiliary/MapAuxiliary.component';
import { calculatePays } from '../helpers';
import styles from './AuditDetailsKeyInfo.module.scss';

const auditTokensTaxonomySelector = state => taxonomyTermSelector(state, AuditsConst.tokenVocabulary);
const payTaxonomySelector = state => taxonomyTermSelector(state, 'pay_rate');

const AuditDetailsKeyInfo = ({ revisionId, labelStyle, valueStyle }) => {
  const role = useUserRole();
  const { values, setFieldValue } = useFormikContext();
  const { terms } = useSelector(auditTokensTaxonomySelector);
  const payTaxonomyTerms = useSelector(payTaxonomySelector);

  const buildSiteSearchOptions = sites => sites.map(site => ({
    text: site.name,
    value: site.id,
  }));

  const [filteredSites, setFilteredSites] = useState([{
    text: `${values.site_name}`,
    value: values.site_id,
  }]);

  const searchSites = wildcard => {
    entitiesAPI.fetchAll(Entity.SITES, { name: wildcard, client_id: values.client_id })
      .then(sites => {
        setFilteredSites(buildSiteSearchOptions(sites.items));
      })
      .catch(error => {
        alert.error(`Error fetching sites: ${error.message}`);
        errorAPI.sendError(error.message, error.stack);
      });
  };

  const approvalTerms = {
    auditor_pass_rate: { value: 51, style: styles.approvalGreen },
    survey_result: { value: 'pass' || 'positive', style: styles.approvalGreen },
  };

  const getConditionalFormat = (prop, index = null) => {
    let propValue = values[prop];
    const approval = approvalTerms[prop];

    if (prop === 'survey_result') {
      propValue = propValue[index].result;
    }
    if (propValue >= approval.value) return approval.style;

    return styles.denialRed;
  };

  return (
    <>
      <FormFieldDateTimeWrapper
        label="Date and time of audit"
        labelStyle={`${labelStyle} ${styles.auditKeyInfoStyle}`}
        name="date_of_visit"
        valueStyle={`${valueStyle} ${styles.auditKeyInfoStyle}`}
        onChange={date => {
          setFieldValue('date_of_visit', date);
          const { holidayPay, totalPay } = calculatePays({
            pay: values.auditor_pay_per_audit,
            payRateTaxonomy: payTaxonomyTerms,
            auditorBankCountry: values.auditor_bank_country,
            date,
          });
          setFieldValue('holiday_pay', holidayPay);
          setFieldValue('total_pay', totalPay);
        }}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      <FormFieldDateTimeWrapper
        label="Submitted Date"
        labelStyle={`${labelStyle} ${styles.auditKeyInfoStyle}`}
        name="submitted_date"
        valueStyle={`${valueStyle} ${styles.auditKeyInfoStyle}`}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      <FormFieldWrapper
        label="Site Code"
        labelStyle={`${labelStyle} ${styles.auditKeyInfoStyle}`}
        name="site_code"
        type="number"
        valueStyle={styles.boldText}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      {getFieldStatus(role, values.status, revisionId)('survey_result') !== FieldStatus.HIDDEN
        ? values.survey_result.map((_result, index) => (
          <FormFieldWrapper
            key={index}
            label={`Survey Result ${index + 1}`}
            labelStyle={`${labelStyle} ${styles.auditKeyInfoStyle}`}
            name={`survey_result.${index}.result`}
            readOnly={getFieldStatus(role, values.status, revisionId)('survey_result') === FieldStatus.READONLY}
            valueStyle={getConditionalFormat('survey_result', index)}
          />
        )) : null}

      <FormFieldWrapper
        label="Survey Score"
        labelStyle={`${labelStyle} ${styles.auditKeyInfoStyle}`}
        name="survey_score"
        valueStyle={`${valueStyle} ${styles.auditKeyInfoStyle}`}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      <FormFieldWrapper
        label="Approving notes"
        labelStyle={`${labelStyle} ${styles.auditKeyInfoStyle}`}
        name="approving_notes"
        type="textarea"
        valueStyle={`${valueStyle} ${styles.auditKeyInfoStyle}`}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      <FormFieldWrapper
        label="Auditor Mobile Number"
        labelStyle={`${labelStyle} ${styles.auditKeyInfoStyle}`}
        name="auditor_mobile_number"
        valueStyle={`${valueStyle} ${styles.auditKeyInfoStyle}`}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      <FormFieldCurrencyWrapper
        currency="%"
        label="Auditor Pass Rate"
        labelStyle={`${labelStyle} ${styles.auditKeyInfoStyle}`}
        name="auditor_pass_rate"
        valueStyle={getConditionalFormat('auditor_pass_rate')}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />
      <FormFieldSelectWrapper
        fluid
        multiple
        search
        selection
        label="Tokens"
        labelStyle={`${labelStyle} ${styles.auditKeyInfoStyle}`}
        name={AuditsConst.tokenProperty}
        options={terms.map((token, i) => ({
          value: token.id,
          text: !token.is_token ? `${token.token}:` : token.token,
          key: i,
          nested: String(token.is_token),
          disabled: !token.is_token,
        }))}
        valueStyle={`${valueStyle} ${styles.auditKeyInfoStyle}`}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      {role === UserRole.AREA_MANAGER && (
        <FormFieldWrapper
          label="Item to order"
          labelStyle={`${labelStyle} ${styles.auditKeyInfoStyle}`}
          name="item_to_order"
          valueStyle={`${valueStyle} ${styles.auditKeyInfoStyle}`}
          onStateHandler={getFieldStatus(role, values.status, revisionId)}
        />)}

      <FormFieldWrapper
        label="Client Name"
        labelStyle={`${labelStyle} ${styles.auditKeyInfoStyle}`}
        name="client_name"
        valueStyle={`${valueStyle} ${styles.auditKeyInfoStyle}`}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      <FormFieldDynamicSearchWrapper
        label="Site"
        labelStyle={`${labelStyle} ${styles.auditKeyInfoStyle}`}
        name="site_id"
        options={filteredSites}
        valueStyle={`${valueStyle} ${styles.auditKeyInfoStyle}`}
        onSearchChange={searchSites}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      <FormFieldWrapper
        label="Site Name"
        labelStyle={`${labelStyle} ${styles.auditKeyInfoStyle}`}
        name="site_name"
        valueStyle={`${valueStyle} ${styles.auditKeyInfoStyle}`}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      <FormFieldWrapper
        label="Site Address"
        labelStyle={`${labelStyle} ${styles.auditKeyInfoStyle}`}
        name="site_address_1"
        valueStyle={`${valueStyle} ${styles.auditKeyInfoStyle}`}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      {values.site_address_2
        ? (
          <FormFieldWrapper
            label=""
            name="site_address_2"
            valueStyle={`${valueStyle} ${styles.auditKeyInfoStyle}`}
            onStateHandler={getFieldStatus(role, values.status, revisionId)}
          />
        ) : null }

      {values.site_address_3
        ? (
          <FormFieldWrapper
            label=""
            name="site_address_3"
            valueStyle={`${valueStyle} ${styles.auditKeyInfoStyle}`}
            onStateHandler={getFieldStatus(role, values.status, revisionId)}
          />
        ) : null }

      <FormFieldWrapper
        label=""
        name="site_city"
        valueStyle={`${valueStyle} ${styles.auditKeyInfoStyle}`}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      <FormFieldWrapper
        label=""
        name="site_post_code"
        valueStyle={`${valueStyle} ${styles.auditKeyInfoStyle}`}
        onStateHandler={getFieldStatus(role, values.status, revisionId)}
      />

      <MapAuxiliary data={values} type={EntityState.DETAIL} />

      { values.geolocation_date
        ? (
          <FormFieldDateTimeWrapper
            label="Date and time of the auditor geolocation"
            labelStyle={`${labelStyle} ${styles.auditKeyInfoStyle}`}
            name="geolocation_date"
            valueStyle={`${valueStyle} ${styles.auditKeyInfoStyle}`}
            onStateHandler={getFieldStatus(role, values.status, revisionId)}
          />
        ) : null}

    </>
  );
};

AuditDetailsKeyInfo.defaultProps = {
  labelStyle: null,
  revisionId: null,
  valueStyle: null,
};

AuditDetailsKeyInfo.propTypes = {
  labelStyle: PropTypes.string,
  revisionId: uuidType,
  valueStyle: PropTypes.string,
};

export default AuditDetailsKeyInfo;

export const dataTabs = [
  { name: 'Clients', path: '/clients', icon: 'icon-clients' },
  { name: 'Sites', path: '/sites', icon: 'icon-sites' },
  { name: 'Orders', path: '/orders', icon: 'icon-orders' },
  { name: 'Audits', path: '/audits', icon: 'icon-visits' },
  { name: 'Surveys', path: '/surveys', icon: 'icon-surveys' },
  { name: 'Logics', path: '/logics', icon: 'icon-logic' },
  { name: 'Auditors', path: '/auditors', icon: 'icon-visitors' },
  { name: 'Users', path: '/users', icon: 'icon-users' },
  { name: 'Patches', path: '/patches', icon: 'icon-patches' },
  { name: 'Taxonomy', path: '/taxonomy', icon: 'icon-taxonomy' },
  {},
  { name: 'Account', path: '/account', icon: 'icon-user' },
];

export const areaManagerTabs = [
  { name: 'Audits', path: '/audits', icon: 'icon-visits' },
  { name: 'Auditors', path: '/auditors?auditor_status=active&page=1', icon: 'icon-visitors' },
  { name: 'Auditor Applications', path: '/auditors?auditor_status=unapproved&page=1', icon: 'icon-auditor-application' },
  { name: 'Tips and Docs', path: '/tips-and-docs', icon: 'icon-orders' },
  {},
  { name: 'Account', path: '/account', icon: 'icon-user' },
];

export const clientTabs = user => [
  { name: 'Orders', path: '/orders', icon: 'icon-orders' },
  { name: 'Audits', path: '/audits', icon: 'icon-visits' },
  { name: 'Reports', path: '/reports', icon: 'icon-duplicate' },
  user && user.allow_archive ? { name: 'Archives', path: '/archives', icon: 'icon-archive' } : null,
  {},
  { name: 'Account', path: '/account', icon: 'icon-user' },
].filter(item => Boolean(item));

export const clientServicesTabs = [
  { name: 'Sites', path: '/sites', icon: 'icon-sites' },
  { name: 'Orders', path: '/orders', icon: 'icon-orders' },
  { name: 'Audits', path: '/audits', icon: 'icon-visits' },
  { name: 'Auditors', path: '/auditors', icon: 'icon-visitors' },
  { name: 'Users', path: '/users', icon: 'icon-users' },
  { name: 'Taxonomy', path: '/taxonomy', icon: 'icon-taxonomy' },
  {},
  { name: 'Account', path: '/account', icon: 'icon-user' },
];

export const auditorTabs = [
  { name: 'Your audits', path: '/audits?status=assigned', icon: 'icon-visits' },
  { name: 'Finished audits', path: '/audits?status=approved,approving_query,submitted,client_query', icon: 'icon-sites' },
  { name: 'Tips and Docs', path: '/tips-and-docs', icon: 'icon-orders' },
  {},
  { name: 'Account', path: '/account', icon: 'icon-user' },
];

export const auditorMobileTabs = online => (online ? [
  { name: 'Your audits', path: '/audits?status=assigned', icon: 'icon-visits' },
  { name: 'Tips and Docs', path: '/tips-and-docs', icon: 'icon-orders' },
] : [
  { name: 'Your audits', path: '/audits?status=assigned', icon: 'icon-visits' },
]);

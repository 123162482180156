import React from 'react';
import { Card, Table } from 'semantic-ui-react';

import styles from './OrderDetailsTokens.module.scss';

const DATA_CY = 'order-details-tokens';

const OrderDetailsTokens = () => (
  <Card className={styles.containerTable} data-cy={DATA_CY}>
    <Card.Header className={styles.header} textAlign="center">
      {'Tokens'}
    </Card.Header>
    <Card.Content extra className={styles.bodyTable}>
      <Table celled basic="very" size="small">
        <Table.Body>
          <Table.Row>
            <Table.Cell className={styles.leftCell}>
              {'Site Name'}
            </Table.Cell>
            <Table.Cell className={styles.rightCell}>
              {'[visit-site-name]'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.leftCell}>
              {'Site Address (Full)'}
            </Table.Cell>
            <Table.Cell className={styles.rightCell}>
              {'[visit-site-address]'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.leftCell}>
              {'Site Address 1'}
            </Table.Cell>
            <Table.Cell className={styles.rightCell}>
              {'[visit-site-address-1]'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.leftCell}>
              {'Site Address 2'}
            </Table.Cell>
            <Table.Cell className={styles.rightCell}>
              {'[visit-site-address-2]'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.leftCell}>
              {'Site Address 3'}
            </Table.Cell>
            <Table.Cell className={styles.rightCell}>
              {'[visit-site-address-3]'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.leftCell}>
              {'Site County'}
            </Table.Cell>
            <Table.Cell className={styles.rightCell}>
              {'[visit-site-county]'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.leftCell}>
              {'Site City'}
            </Table.Cell>
            <Table.Cell className={styles.rightCell}>
              {'[visit-site-city]'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.leftCell}>
              {'Site Address Post Code'}
            </Table.Cell>
            <Table.Cell className={styles.rightCell}>
              {'[visit-site-postcode]'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.leftCell}>
              {'Site Code'}
            </Table.Cell>
            <Table.Cell className={styles.rightCell}>
              {'[visit-site-code]'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.leftCell}>
              {'Site organisation Level Code 1'}
            </Table.Cell>
            <Table.Cell className={styles.rightCell}>
              {'[visit-site-org-level-1]'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.leftCell}>
              {'Site organisation Level Code 2'}
            </Table.Cell>
            <Table.Cell className={styles.rightCell}>
              {'[visit-site-org-level-2]'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.leftCell}>
              {'Site organisation Level Code 3'}
            </Table.Cell>
            <Table.Cell className={styles.rightCell}>
              {'[visit-site-org-level-3]'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.leftCell}>
              {'Site organisation Level Code 4'}
            </Table.Cell>
            <Table.Cell className={styles.rightCell}>
              {'[visit-site-org-level-4]'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.leftCell}>
              {'Client Name'}
            </Table.Cell>
            <Table.Cell className={styles.rightCell}>
              {'[visit-client-name]'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.leftCell}>
              {'Item to Order'}
            </Table.Cell>
            <Table.Cell className={styles.rightCell}>
              {'[visit-item]'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.leftCell}>
              {'Visit Date'}
            </Table.Cell>
            <Table.Cell className={styles.rightCell}>
              {'[visit-date]'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.leftCell}>
              {'Visit Time'}
            </Table.Cell>
            <Table.Cell className={styles.rightCell}>
              {'[visit-time]'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.leftCell}>
              {'Survey Result'}
            </Table.Cell>
            <Table.Cell className={styles.rightCell}>
              {'[visit-survey-result]'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.leftCell}>
              {'Survey Result (Secondary)'}
            </Table.Cell>
            <Table.Cell className={styles.rightCell}>
              {'[visit-survey-result-2]'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.leftCell}>
              {'Survey Result (Tertiary)'}
            </Table.Cell>
            <Table.Cell className={styles.rightCell}>
              {'[visit-survey-result-3]'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.leftCell}>
              {'Auditor Gender'}
            </Table.Cell>
            <Table.Cell className={styles.rightCell}>
              {'[visit-visitor-gender]'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.leftCell}>
              {'Auditor Age'}
            </Table.Cell>
            <Table.Cell className={styles.rightCell}>
              {'[visit-visitor-age]'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.leftCell}>
              {'Auditor No. of Visits'}
            </Table.Cell>
            <Table.Cell className={styles.rightCell}>
              {'[visit-visitor-visits]'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.leftCell}>
              {'Auditor Pass Rate'}
            </Table.Cell>
            <Table.Cell className={styles.rightCell}>
              {'[visit-visitor-pass-rate]'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.leftCell}>
              {'Current Date'}
            </Table.Cell>
            <Table.Cell className={styles.rightCell}>
              {'[date]'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.leftCell}>
              {'Survey Score'}
            </Table.Cell>
            <Table.Cell className={styles.rightCell}>
              {'[visit-survey-score]'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.leftCell}>
              {'Survey Max Score'}
            </Table.Cell>
            <Table.Cell className={styles.rightCell}>
              {'[visit-survey-max-score]'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.leftCell}>
              {'Survey Percentage Score'}
            </Table.Cell>
            <Table.Cell className={styles.rightCell}>
              {'[visit-survey-percentage-score]'}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </Card.Content>
  </Card>
);

export default OrderDetailsTokens;

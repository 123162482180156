import PropTypes from 'prop-types';
import React from 'react';
import * as Sentry from '@sentry/react';
import { Route as ReactRouterDomRoute, Router, Switch } from 'react-router-dom';

import ArchiveDetails from '../components/pages/Archives/ArchivesDetails/ArchivesDetails.component';
import AuditingView from '../components/pages/Auditing/AuditingView/AuditingView.component';
import ClientsAutomatedExportsDetailsView from '../components/pages/Clients/ClientsAutomatedExportsDetailsView/ClientsAutomatedExportsDetailsView.component';
import ClientsAutomatedExportsTableView from '../components/pages/Clients/ClientsAutomatedExportsTableView/ClientsAutomatedExportsTableView.component';
import EntityDetailsView from '../components/pages/Entity/EntityDetailsView/EntityDetailsView.container';
import EntityRevisionsDetailView from '../components/pages/Entity/EntityRevisionDetailsView/EntityRevisionDetailsView.component';
import EntityRevisionsCompareView from '../components/pages/Entity/EntityRevisionsCompareView/EntityRevisionsCompareView.component';
import EntityRevisionsView from '../components/pages/Entity/EntityRevisionsView/EntityRevisionsView.component';
import EntityTableView from '../components/pages/Entity/EntityTableView/EntityTableView.component';
import EntityUploadView from '../components/pages/Entity/EntityUploadView/EntityUploadView.component';
import PatchesView from '../components/pages/Patches/PatchesView.component';
import SurveyDetails from '../components/pages/Surveys/SurveyDetails/SurveyDetails.component';
import UserSettings from '../components/pages/Users/UserSettings/UserSettings.component';
import { UserRole } from '../constants';
import history from '../history';
import AppRoute from './routes';

// Enable Sentry tracing on routes
const Route = Sentry.withSentryRouting(ReactRouterDomRoute);

const EntityRouter = ({ role }) => (
  <Router history={history}>
    <Switch>
      {[UserRole.AUDITOR].includes(role)
        ? (
          <Route
            exact
            component={AuditingView}
            path={AppRoute.AUDIT_DETAILS.path}
          />
        ) : null}
      <Route
        exact
        component={ClientsAutomatedExportsTableView}
        path={AppRoute.CLIENTS_AUTOMATED_EXPORTS.path}
      />
      <Route
        exact
        component={ClientsAutomatedExportsDetailsView}
        path={AppRoute.CLIENTS_AUTOMATED_EXPORTS_NEW.path}
      />
      <Route
        exact
        component={ClientsAutomatedExportsDetailsView}
        path={AppRoute.CLIENTS_AUTOMATED_EXPORT_DETAILS.path}
      />
      <Route
        exact
        component={SurveyDetails}
        path={AppRoute.SURVEY_NEW.path}
      />
      <Route
        exact
        component={SurveyDetails}
        path={AppRoute.SURVEY_REVISION_DETAILS.path}
      />
      <Route
        exact
        component={UserSettings}
        path={AppRoute.USER_SETTINGS.path}
      />
      <Route
        exact
        component={PatchesView}
        path={AppRoute.PATCHES_LIST.path}
      />
      <Route
        exact
        component={EntityTableView}
        path={AppRoute.ENTITY_LIST.path}
      />
      <Route
        exact
        component={EntityUploadView}
        path={AppRoute.ENTITY_UPLOAD.path}
      />
      <Route
        exact
        component={EntityDetailsView}
        path={AppRoute.ENTITY_NEW.path}
      />
      <Route
        exact
        component={EntityDetailsView}
        path={AppRoute.ENTITY_DETAILS.path}
      />
      <Route
        exact
        component={EntityRevisionsView}
        path={AppRoute.ENTITY_REVISIONS_LIST.path}
      />
      <Route
        exact
        component={EntityRevisionsCompareView}
        path={AppRoute.ENTITY_REVISIONS_COMPARE.path}
      />
      <Route
        exact
        component={EntityRevisionsDetailView}
        path={AppRoute.ENTITY_REVISION_DETAILS.path}
      />
      <Route
        exact
        component={EntityDetailsView}
        path={AppRoute.ENTITY_DETAILS_MODE.path}
      />
      <Route
        exact
        component={ArchiveDetails}
        path={AppRoute.ARCHIVE_DETAILS.path}
      />
    </Switch>
  </Router>
);

EntityRouter.propTypes = {
  role: PropTypes.string.isRequired,
};

export default EntityRouter;

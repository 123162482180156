import React, { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

import { EntityRevisionsContext } from '../../../../../context';
import { SLHeader } from '../../../../common';

const EntityRevisionsHeader = () => {
  const { entity, entityId } = useParams();
  const { selectedRevisions } = useContext(EntityRevisionsContext);

  const DATA_CY = `${entity}-revisions-header`;

  return (
    <SLHeader
      dataCy={DATA_CY}
      title={'Revisions'}
    >
      {selectedRevisions.length === 2 ? (
        <Link to={`/${entity}/${entityId}/revisions/compare?rev1=${selectedRevisions[0]}&rev2=${selectedRevisions[1]}`}>
          <Button secondary>
            {'Compare'}
          </Button>
        </Link>
      ) : null}
      <Link to={`/${entity}/${entityId}`}>
        <Button>
          {'Back'}
        </Button>
      </Link>

    </SLHeader>
  );
};

export default EntityRevisionsHeader;

import { Field, useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'semantic-ui-react';

import FieldStatus from '../../../fieldLogic/fieldStatus';
import FormFieldLabel from './Template/FormFieldLabel.component';
import FormFieldTemplate from './Template/FormFieldTemplate.component';

const FormFieldRadioWrapper = ({
  dataCy,
  help,
  inline,
  label,
  labelAfterRadio,
  labelTooltip,
  name,
  onChange,
  onStateHandler,
  readOnly,
  required,
  value,
  ...rest
}) => {
  const [field, meta, helpers] = useField(name);

  const dataCyPrefix = dataCy || name;

  let state = FieldStatus.EDITABLE;

  if (onStateHandler) {
    state = onStateHandler(name);
  }

  if (state === FieldStatus.HIDDEN) {
    return null;
  }

  const readOnlyField = () => (
    <span>
      {field.value}
    </span>
  );

  const editableField = () => (
    <Field
      as={Form.Radio}
      checked={field.value === value}
      data-cy={`${dataCyPrefix}-field`}
      error={meta.error}
      label={labelAfterRadio
        ? <FormFieldLabel dataCy={dataCyPrefix} inline={inline} label={label} labelTooltip={labelTooltip} />
        : null}
      name={name}
      required={required}
      onChange={() => {
        helpers.setValue(value);
        if (onChange) {
          onChange(value);
        }
      }}
      {...rest}
    />
  );

  return (
    <FormFieldTemplate
      dataCy={dataCyPrefix}
      help={help}
      inline={inline}
      label={labelAfterRadio ? null : label}
      labelTooltip={labelTooltip}
      required={required}
    >
      {state === FieldStatus.READONLY || readOnly ? readOnlyField() : editableField()}
    </FormFieldTemplate>
  );
};

FormFieldRadioWrapper.defaultProps = {
  dataCy: null,
  help: null,
  inline: false,
  labelAfterRadio: false,
  labelTooltip: null,
  onChange: null,
  onStateHandler: null,
  readOnly: false,
  required: false,
};

FormFieldRadioWrapper.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  dataCy: PropTypes.string,
  help: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  inline: PropTypes.bool,
  labelAfterRadio: PropTypes.bool,
  labelTooltip: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  onStateHandler: PropTypes.func,
};

export default FormFieldRadioWrapper;

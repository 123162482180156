import heic2any from 'heic2any';
import Resizer from 'react-image-file-resizer';

const imageTypes = {
  JPEG: 'image/jpeg',
  HEIC: 'image/heic',
};

const convertHeicToJpeg = async file => {
  try {
    const convertedFile = await heic2any({
      blob: file,
      toType: imageTypes.JPEG,
      // HEIC IMAGES ARE GENERALLY VERY HEAVY DUE TO GREAT QUALITY. DECREASING QUALITY WILL MAKE IMAGE LIGHTER.
      quality: 0.5,
    });

    return Promise.resolve(convertedFile);
  } catch (err) {
    return Promise.reject(err.message);
  }
};

const compressImage = (quality = 100) => async image => {
  if (image.size <= 10000) return image;

  return new Promise(resolve => {
    Resizer.imageFileResizer(
      image,
      1200,
      1200,
      'JPEG',
      quality,
      0,
      uri => {
        resolve(uri);
      },
      'file',
    );
  });
};

const handleImageConversion = async image => {
  const convertTo = {
    [imageTypes.HEIC]: convertHeicToJpeg,
    [imageTypes.JPEG]: compressImage(100),
  };

  if (image && image.type) {
    const conversionFn = convertTo[image.type];
    if (conversionFn) {
      try {
        const convertedImage = await conversionFn(image);

        return Promise.resolve(convertedImage);
      } catch (err) {
        return Promise.reject(err.message);
      }
    }
  }

  return Promise.resolve(image);
};

const convertImages = async files => {
  if (Array.isArray(files)) {
    return Promise.all(files.map(async file => handleImageConversion(file)));
  }

  return handleImageConversion(files);
};

export {
  convertImages,
};

import _debounce from 'lodash/debounce';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Button, Form, Grid, Icon, Input } from 'semantic-ui-react';

import { entitiesAPI } from '../../../../../../api';
import {
  CustomEventType,
  DEBOUNCE_MS,
  Entity,
  getQuestionCategoryMetadata,
  getQuestionTypeMetadataByValue,
} from '../../../../../../constants';
import { enumOptionsSelector } from '../../../../../../state/constants/selectors';
import { AuxiliaryPanel } from '../../../../../common';
import styles from './SurveyQuestionCategoryPanel.module.scss';

const DATA_CY = 'survey-question-category-panel';

const questionCategoryOptionsSelector = state => enumOptionsSelector(state, 'question_category');

const SurveyQuestionCategoryPanel = () => {
  const questionCategoryOptions = useSelector(questionCategoryOptionsSelector);

  const [selectedPackage, setSelectedPackage] = useState(null);
  const [titleToSearch, setTitleToSearch] = useState('');
  const [questionIdToSearch, setQuestionIdToSearch] = useState('');

  const { data: questions } = useQuery(
    [Entity.QUESTIONS,
      {
        category: selectedPackage?.value,
        title: titleToSearch,
        internal_id: questionIdToSearch,
      }],
    () => entitiesAPI.fetchAll(
      Entity.QUESTIONS,
      {
        category: selectedPackage?.value,
        title: titleToSearch,
        internal_id: questionIdToSearch,
      },
    ),
    { enabled: (Boolean(selectedPackage) || Boolean(titleToSearch) || Boolean(questionIdToSearch)) },
  );

  const handleDragStart = question => e => {
    e.dataTransfer.setData('text/plain', JSON.stringify({
      event: CustomEventType.QUESTION_ADD_PREDETERMINED,
      question,
    }));
  };

  const handleClick = question => {
    const event = new CustomEvent(CustomEventType.QUESTION_ADD_PREDETERMINED, {
      detail: {
        question,
      },
    });
    window.dispatchEvent(event);
  };

  const questionCategories = ({ categories }) => (
    <div className={styles.searchResult}>
      <div className={styles.content}>
        {categories.map(category => {
          const { icon } = getQuestionCategoryMetadata(category.value);
          return (
            <div
              key={category.value}
              className={styles.questionPackage}
              data-cy={`${DATA_CY}-${category.value}`}
              onClick={() => setSelectedPackage(category)}
            >
              <i className={`${icon}`} />
              {category.text}
            </div>
          );
        })}
        <div
          key={'all'}
          className={styles.questionPackage}
          data-cy={`${DATA_CY}-all`}
          onClick={() => setSelectedPackage({ value: null, text: 'All' })}
        >
          <i className="icon-view_all" />
          {'All'}
        </div>
      </div>
    </div>
  );

  const questionsList = ({ questionsArray }) => (
    <div className={styles.searchResult}>
      {questionsArray.items.length > 0 ? questionsArray.items.map(questionValue => {
        const { icon } = getQuestionTypeMetadataByValue(questionValue.type);
        return (
          <div key={questionValue.id} className={styles.questionContainer}>
            <div
              draggable
              className={styles.question}
              onClick={() => handleClick(questionValue)}
              onDragStart={handleDragStart(questionValue)}
            >
              <Grid container columns={3}>
                <Grid.Column verticalAlign="middle" width={2}>
                  {questionValue.internal_id}
                </Grid.Column>
                <Grid.Column verticalAlign="middle" width={2}>
                  <i className={icon} />
                </Grid.Column>
                <Grid.Column verticalAlign="middle" width={12}>
                  {questionValue.title}
                </Grid.Column>
              </Grid>
            </div>
          </div>
        );
      }) : (
        <div className={styles.noResultContainer}>
          <h4>
            {'No questions found'}
          </h4>
        </div>
      )}
    </div>
  );

  const debouncedTitleSearch = _debounce(setTitleToSearch, DEBOUNCE_MS);
  const debouncedIdSearch = _debounce(setQuestionIdToSearch, DEBOUNCE_MS);

  return (
    <AuxiliaryPanel
      content={(
        <Grid>
          <Grid.Row>
            <Form className={styles.searchForm}>
              <Form.Field>
                <label>
                  {'Title'}
                </label>
                <Input
                  defaultValue={titleToSearch}
                  onChange={(_e, { value }) => debouncedTitleSearch(value)}
                />
              </Form.Field>
              <Form.Field>
                <label>
                  {'ID'}
                </label>
                <Input
                  defaultValue={questionIdToSearch}
                  label="Q"
                  onChange={(_e, { value }) => debouncedIdSearch(value)}
                />
              </Form.Field>

              {selectedPackage?.value && (
                <Form.Field>
                  <label>
                    {'Category'}
                  </label>
                  <Input
                    label={(
                      <Button onClick={() => setSelectedPackage(null)}>
                        <Icon name="close" />
                      </Button>
                    )}
                    labelPosition="right"
                    value={selectedPackage?.value}
                  />
                </Form.Field>
              )}
            </Form>
          </Grid.Row>
          <Grid.Row>
            {(questions && (selectedPackage || titleToSearch || questionIdToSearch))
              ? questionsList({ questionsArray: questions })
              : questionCategories({ categories: questionCategoryOptions })
            }
          </Grid.Row>
        </Grid>
      )}
      dataCy={DATA_CY}
      header="Questions"
    />
  );
};

export default SurveyQuestionCategoryPanel;

import PropTypes from 'prop-types';
import React from 'react';
import { useQuery } from 'react-query';
import { Modal } from 'semantic-ui-react';

import { legalAPI } from '../../../../api';
import styles from './RegisterAuditorLegalModal.module.scss';

const RegisterAuditorLegalModal = ({ open, onCancel }) => {
  const { data } = useQuery(
    'legal',
    () => legalAPI.fetch(),
  );

  return (
    <Modal
      closeIcon
      closeOnDimmerClick={true}
      open={open}
      onClose={onCancel}
    >
      <Modal.Header>
        {'Terms & Conditions'}
      </Modal.Header>
      <Modal.Content>
        <div className={styles.tableContainer}>
          {data?.description}
        </div>
      </Modal.Content>
    </Modal>
  );
};

RegisterAuditorLegalModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default RegisterAuditorLegalModal;

import localforage from 'localforage';
import _pick from 'lodash/pick';

import { convertImages } from '../../../utils/imageConversion';

const editableExtraDetailsFields = [
  'date_of_visit',
  'purchase_cost',
  'mileage',
  'entrance_charge',
  'travel_expenses',
  'travel_expenses_type',
  'travel_expenses_description',
  'reclaimable_expenses',
  'reclaimable_expenses_type',
  'reclaimable_expenses_description',
  'mileage_type',
  'mileage_description',
  'expenses_receipts',
  'reclaimable_expenses_2',
  'reclaimable_expenses_3',
  'reclaimable_expenses_4',
  'reclaimable_expenses_2_type',
  'reclaimable_expenses_3_type',
  'reclaimable_expenses_4_type',
  'reclaimable_expenses_2_description',
  'reclaimable_expenses_3_description',
  'reclaimable_expenses_4_description',
];

const extraDetailsFields = [
  ...editableExtraDetailsFields,
  'auditor_pay_per_audit',
];

const getAnswerValue = answer => {
  if (!answer) return null;
  if (answer.hidden) return null;
  return answer.value;
};

const initAuditing = audit => {
  const initialAnswers = audit.revision_questions.map(question => {
    const { id, internal_id, type } = question;
    const originalAnswer = audit.survey_answers?.find(answer => answer.id === id);

    return {
      id,
      type,
      internal_id,
      hidden: originalAnswer?.hidden || false,
      value: getAnswerValue(originalAnswer),
    };
  });

  const initialExtraDetails = _pick(audit, extraDetailsFields);

  return {
    initialAnswers,
    initialExtraDetails,
    initialLocation: null,
  };
};

const getThumbnailURL = async file => {
  const convertedFile = await convertImages(file);

  if (convertedFile) {
    return Promise.resolve(URL.createObjectURL(convertedFile));
  }
  return Promise.resolve(URL.createObjectURL(file));
};

const getAnswerImages = async (answer, answer_original_urls) => {
  if (answer) {
    if (Array.isArray(answer)) {
      if (answer_original_urls) {
        return answer_original_urls;
      }
    } else {
      // Id in the localforage for the images selected by the user
      try {
        const images = (await localforage.getItem(answer)) || [];
        return Promise.all(images.map(image => getThumbnailURL(image)));
      } catch (err) {
        return Promise.reject(err.message);
      }
    }
  }

  return null;
};

export {
  editableExtraDetailsFields,
  getAnswerImages,
  getThumbnailURL,
  initAuditing,
};

import { StatusCodes } from 'http-status-codes';

import { appConfig } from '../config';
import axios from './axiosInstance';
import { getAuthorization, handlingResponse, logError } from './utils';

export const fetchUserAccount = () => axios
  .get(`${appConfig.backendUrl}/api/v1/users/me`, { headers: { Authorization: getAuthorization() } })
  .then(handlingResponse([StatusCodes.OK], 'Error fetching user'))
  .catch(logError);

export const fetchAuditorAccount = () => axios
  .get(`${appConfig.backendUrl}/api/v1/auditors/me`, { headers: { Authorization: getAuthorization() } })
  .then(handlingResponse([StatusCodes.OK], 'Error fetching auditor'))
  .catch(logError);

export const editAuditorAccount = ({ payload }) => axios
  .put(`${appConfig.backendUrl}/api/v1/auditors/me`, payload, {
    headers: { Authorization: getAuthorization() },
  })
  .then(handlingResponse([StatusCodes.OK], 'Error fetching auditor'))
  .catch(logError);

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Card, Divider, Image } from 'semantic-ui-react';

import { FullScreenImageModal } from '../../../../common';
import styles from './AuditorDetailsImages.module.scss';

const images = [
  { label: 'Recent photography', src: 'image' },
  { label: 'Scanned Copy of Passport', src: 'passport' },
  { label: 'Scanned Copy of ID', src: 'identification' },
];

const AuditorDetailsImages = ({ data }) => {
  const [modalImgSrc, setModalImgSrc] = useState(null);

  return (
    <>
      <Card className={styles.container}>
        <Card.Content extra className={styles.headerContainer}>
          <Card.Header className={styles.headerTitle}>
            {'User Images'}
          </Card.Header>
        </Card.Content>
        <Card.Content>
          {images.filter(image => !!data[image.src])
            .map(image => (
              <div key={image.src} >
                <div className={styles.row}>
                  {image.label}
                  <Image
                    rounded
                    className={styles.image}
                    size="mini"
                    src={data[image.src]}
                    onClick={() => setModalImgSrc(data[image.src])}
                  />
                </div>
                <Divider />
              </div>
            ))}
          {data.visa
            ? <div key={data.visa} >
              <div className={styles.row}>
                {'Scanned Copy of Visa'}
                <Image
                  rounded
                  className={styles.image}
                  size="mini"
                  src={data.visa}
                  onClick={() => setModalImgSrc(data.visa)}
                />
              </div>
            </div> : null}
        </Card.Content>
      </Card>

      <FullScreenImageModal
        imgSrc={modalImgSrc}
        onClose={() => setModalImgSrc(null)}
      />
    </>
  );
};

AuditorDetailsImages.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AuditorDetailsImages;

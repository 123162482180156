// import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';

import { SLHeader } from '../../../../common';
// import styles from './AuditsTableHeaderAuditorRole.module.scss';

const DATA_CY = 'audits-table-header';

// const AuditsTableHeaderAuditorRole = ({
//   isFilterVisible,
//   setFilterVisible,
// }) => {
const AuditsTableHeaderAuditorRole = () => {
  const { entity } = useParams();

  return (
    <SLHeader dataCy={DATA_CY} subtitle="" title={entity}>
      <span />
      <span />
      <span />
      <span />
      {/* <div
        className={isFilterVisible ? styles.toggleIconOpen : styles.toggleIconClosed}
        data-cy={`${DATA_CY}-filter-toggle`}
        onClick={() => setFilterVisible(!isFilterVisible)}
      /> */}
    </SLHeader>
  );
};

// AuditsTableHeaderAuditorRole.defaultProps = {
//   isFilterVisible: false,
//   setFilterVisible: null,
// };

// AuditsTableHeaderAuditorRole.propTypes = {
//   isFilterVisible: PropTypes.bool,
//   setFilterVisible: PropTypes.func,
// };

export default AuditsTableHeaderAuditorRole;

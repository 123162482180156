import moment from 'moment';
import * as Yup from 'yup';

export const archivesFilterValidationSchema = Yup.object().shape({
  day: Yup.string(),
  month: Yup.string(),
  site_name: Yup.string(),
  year: Yup.string(),
});

export const archivesFilterDefaultValues = {
  day: '',
  month: '',
  site_name: '',
  year: '',
};

export const daysOption = [
  { key: 0, value: '', text: 'No value' },
  { key: 1, value: '1', text: '1' },
  { key: 2, value: '2', text: '2' },
  { key: 3, value: '3', text: '3' },
  { key: 4, value: '4', text: '4' },
  { key: 5, value: '5', text: '5' },
  { key: 6, value: '6', text: '6' },
  { key: 7, value: '7', text: '7' },
  { key: 8, value: '8', text: '8' },
  { key: 9, value: '9', text: '9' },
  { key: 10, value: '10', text: '10' },
  { key: 11, value: '11', text: '11' },
  { key: 12, value: '12', text: '12' },
  { key: 13, value: '13', text: '13' },
  { key: 14, value: '14', text: '14' },
  { key: 15, value: '15', text: '15' },
  { key: 16, value: '16', text: '16' },
  { key: 17, value: '17', text: '17' },
  { key: 18, value: '18', text: '18' },
  { key: 19, value: '19', text: '19' },
  { key: 20, value: '20', text: '20' },
  { key: 21, value: '21', text: '21' },
  { key: 22, value: '22', text: '22' },
  { key: 23, value: '23', text: '23' },
  { key: 24, value: '24', text: '24' },
  { key: 25, value: '25', text: '25' },
  { key: 26, value: '26', text: '26' },
  { key: 27, value: '27', text: '27' },
  { key: 28, value: '28', text: '28' },
  { key: 29, value: '29', text: '29' },
  { key: 30, value: '30', text: '30' },
  { key: 31, value: '31', text: '31' },
];

export const monthsOption = [
  { key: 0, value: '', text: 'No value' },
  { key: 1, value: '01', text: 'January' },
  { key: 2, value: '02', text: 'February' },
  { key: 3, value: '03', text: 'March' },
  { key: 4, value: '04', text: 'April' },
  { key: 5, value: '05', text: 'May' },
  { key: 6, value: '06', text: 'June' },
  { key: 7, value: '07', text: 'July' },
  { key: 8, value: '08', text: 'August' },
  { key: 9, value: '09', text: 'September' },
  { key: 10, value: '10', text: 'October' },
  { key: 11, value: '11', text: 'November' },
  { key: 12, value: '12', text: 'December' },
];

const yearsArray = () => {
  const years = [];
  const dateStart = moment('2020');
  const dateEnd = moment();
  while (dateEnd.diff(dateStart, 'years') >= 0) {
    years.push(dateStart.format('YYYY'));
    dateStart.add(1, 'year');
  }
  return years;
};
const yearsMap = () => yearsArray().map(item => (
  { text: item,
    value: item }
));

export const yearsOption = [
  { value: '', text: 'No value' },
  ...yearsMap(),
];

const createFlattenedArray = nestedTaxonomy => {
  const flattenedArray = [];
  nestedTaxonomy.terms.forEach(item => {
    flattenedArray.push({ ...item, depth: 0 });

    if (item.options) {
      item.options.forEach(option => flattenedArray.push({ ...option, depth: 1 }));
    }
  });

  return flattenedArray;
};

export default createFlattenedArray;

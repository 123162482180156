import { applyMiddleware, compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './index-reducers';
import rootSagas from './sagas-registration';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware));

const persistConfig = {
  key: 'authType',
  storage,
  whitelist: ['audits', 'auth'],
  blacklist: [''],
};

const pReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(pReducer, {}, enhancer);
const persistor = persistStore(store);
sagaMiddleware.run(rootSagas);

export {
  persistor,
  sagaMiddleware,
  store,
};

import PropTypes from 'prop-types';
import React from 'react';
import { Icon, Label } from 'semantic-ui-react';

const GeographicCoordinates = ({ coordinates, patch }) => (
  <Label.Group>
    {coordinates ? (
      <Label>
        <Icon name="world" />
        {`${coordinates.lng}°, ${coordinates.lat}°`}
        <Label.Detail>
          (lng, lat)
        </Label.Detail>
      </Label>
    ) : null}
    {patch ? (
      <Label tag color="blue">
        {patch}
      </Label>
    ) : (
      <Label tag color="red">
        <Icon name="warning sign" />
        {'No patch'}
      </Label>
    )}
  </Label.Group>
);

GeographicCoordinates.defaultProps = {
  coordinates: null,
  patch: null,
};

GeographicCoordinates.propTypes = {
  coordinates: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  patch: PropTypes.string,
};

export default GeographicCoordinates;

const addOutterParenthesis = conditions => [
  { condition: 'parenthesis' },
  ...conditions,
  { condition: 'parenthesis_end' },
];

const deflatConditions = conditions => {
  const stack = [];
  let deflattenConditions = {};

  if (conditions.length === 1) {
    if (conditions[0].group) delete conditions[0].group;
    return conditions[0];
  }

  const formattedConditios = addOutterParenthesis(conditions);

  let i = 0;
  while (i < formattedConditios.length) {
    const currentElement = formattedConditios[i];
    if (currentElement.condition === 'parenthesis') {
      const conditionObject = {
        operands: [],
        operator: null,
      };
      stack.push(conditionObject);
      if (i === 0) deflattenConditions = conditionObject;
    } else if (
      currentElement.condition === 'AND'
      || currentElement.condition === 'OR'
    ) {
      stack[stack.length - 1].operator = currentElement.condition;
    } else if (currentElement.condition === 'parenthesis_end') {
      const topStack = stack.pop();
      topStack[topStack.operator] = topStack.operands;
      delete topStack.operands;
      delete topStack.operator;
      if (stack.length - 1 !== -1) {
        stack[stack.length - 1].operands.push(topStack);
      }
    } else {
      if (currentElement.group) delete currentElement.group;
      stack[stack.length - 1].operands.push(currentElement);
    }
    i += 1;
  }
  return deflattenConditions;
};

const deflatTree = tree => ({
  ...tree,
  conditions: deflatConditions(JSON.parse(JSON.stringify(tree.conditions))),
});

export default rules => (rules || []).map(deflatTree);

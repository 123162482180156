import Moment from 'moment';
import momentLocalizer from 'react-widgets-moment';

const configureMoment = () => {
  /**
   * Used by [react-widgets](http://jquense.github.io/react-widgets/)
   * @see http://jquense.github.io/react-widgets/localization/
   */
  Moment.locale('en-GB');
  momentLocalizer();
};

export default configureMoment;

import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';
import * as Yup from 'yup';

import { FormFieldWrapper } from '../../../../common';

const SurveyCloneModal = ({ modalOpen, onCloseModal, onSubmit }) => (
  <Modal closeOnDimmerClick={true} open={modalOpen} onClose={onCloseModal}>
    <Formik
      initialValues={{}}
      validationSchema={Yup.object().shape({
        title: Yup.string().required('Required'),
      })}
      onSubmit={({ title }) => onSubmit({ title })}
    >
      {({ handleSubmit }) => (
        <>
          <Modal.Header>
Enter cloned survey title
          </Modal.Header>
          <Modal.Content>
            <Form>
              <>
                <FormFieldWrapper required label="Title" name="title" />
              </>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button primary type="submit" onClick={handleSubmit}>
              Clone
            </Button>
            <Button onClick={onCloseModal}>
Cancel
            </Button>
          </Modal.Actions>
        </>
      )}
    </Formik>
  </Modal>
);

SurveyCloneModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default SurveyCloneModal;

import { createContext } from 'react';

const PatchesContext = createContext({
  newPatchActive: false,
  patches: [],
  resetSelectedPatch: () => {},
  selectedPatch: null,
  selectedPatchId: null,
  setNewPatchActive: () => {},
  setSelectedPatchId: () => {},
});

export default PatchesContext;

import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useAlert } from 'react-alert';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid } from 'semantic-ui-react';

import { AuditingSteps } from '../../../../constants';
import { updateAuditCurrentQuestionId, updateAuditStep } from '../../../../state/audits/actionCreators';
import { auditAnswersSelector } from '../../../../state/audits/selectors';
import isMobile from '../../../../utils/isMobile';
import styles from './AuditingReview.module.scss';
import ReviewQuestion from './ReviewQuestion.component';

const DATA_CY = 'auditing-review-questions';

const AuditingReview = ({ data, next }) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const answersSelector = state => auditAnswersSelector(state, data.id);
  const auditAnswers = useSelector(answersSelector);

  const [missingQuestionsIds, setMissingQuestionsIds] = useState([]);

  const { revision_questions, survey_answers_urls } = data;

  const handleSubmit = () => {
    // Check if all the required questions have an answer
    const missingRequiredQuestions = revision_questions.filter(question => {
      if (question.required) {
        const currentAnswer = auditAnswers.find(answer => answer.id === question.id);
        return currentAnswer.hidden === false && currentAnswer.value === null;
      }
      return false;
    });

    if (missingRequiredQuestions.length > 0) {
      alert.error('You must fill the required questions before submitting the audit.');
      setMissingQuestionsIds(missingRequiredQuestions.map(question => question.id));
    } else {
      next();
    }
  };

  const handleAmendAnswer = useCallback(questionId => {
    dispatch(updateAuditCurrentQuestionId({
      id: data.id,
      currentQuestionId: questionId,
    }));

    dispatch(updateAuditStep({
      id: data.id,
      step: AuditingSteps.QUESTIONNAIRE,
    }));
  }, [data.id, dispatch]);

  return (
    <div className={styles.surveyReviewContainer} data-cy={DATA_CY}>
      <h3>
        {'Review Your Answers'}
      </h3>
      <Grid stackable columns={3} divided="vertically" textAlign="center" verticalAlign="middle">
        {!isMobile ? (
          <Grid.Row>
            <Grid.Column>
              <span className={styles.title}>
                {'Question'}
              </span>
            </Grid.Column>
            <Grid.Column>
              <span className={styles.title}>
                {'Answer'}
              </span>
            </Grid.Column>
            <Grid.Column />
          </Grid.Row>
        ) : null}

        {revision_questions.map((question, index) => {
          const foundAnswer = auditAnswers.find(answer => answer.id === question.id);

          return (foundAnswer !== -1 && foundAnswer.hidden === false) ? (
            <ReviewQuestion
              key={index}
              answer={foundAnswer}
              missing={missingQuestionsIds.includes(question.id)}
              question={question}
              urls={survey_answers_urls}
              onAmendClick={handleAmendAnswer}
            />
          ) : null;
        })}

        <Grid.Row>
          <Button
            primary
            className={styles.submitButton}
            data-cy={`${DATA_CY}-audit-next-button`}
            onClick={handleSubmit}
          >
            {'Next'}
          </Button>
        </Grid.Row>
      </Grid>
    </div>
  );
};

AuditingReview.propTypes = {
  data: PropTypes.object.isRequired,
  next: PropTypes.func.isRequired,
};

export default AuditingReview;

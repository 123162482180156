/* eslint-disable react/prop-types */
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useExpanded, useRowSelect, useTable } from 'react-table';
import { Dropdown, Icon, Pagination, Table } from 'semantic-ui-react';

import { PageLimit } from '../../../constants';
import SLTableRowSelector from '../SLTableRowSelector/SLTableRowSelector.component';
import styles from './SLTable.module.scss';

const pageLimitOptions = Object.values(PageLimit).map(value => ({ text: value, value }));

const SLTable = ({
  columns,
  currentLimit,
  currentPage,
  currentSortBy,
  data,
  dataCy,
  hiddenColumns,
  noDataMessage,
  onLimitChange,
  onPageChange,
  onRowSelectionChange,
  onSortByChange,
  pages,
  rowSelector,
  userData,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
  } = useTable(
    {
      columns: React.useMemo(() => columns, [columns]),
      data: React.useMemo(() => data, [data]),
      initialState: {
        hiddenColumns,
      },
      userData, // custom data
    },
    useExpanded,
    useRowSelect,
    hooks => (rowSelector && hooks.visibleColumns.push(visibleColumns => [
      {
        id: 'selection',
        label: ({ getToggleAllRowsSelectedProps }) => (
          rowSelector ? <SLTableRowSelector {...getToggleAllRowsSelectedProps()} /> : ''
        ),
        Cell: ({ row }) => <SLTableRowSelector {...row.getToggleRowSelectedProps()} />,
        sortable: false,
      },
      ...visibleColumns,
    ])
    ),
  );

  const getSortIcon = ({ column, sortBy }) => (
    <span className={styles.shortIconWrapper}>
      <Icon
        name={`${sortBy.sort !== column.id
          ? 'sort'
          : sortBy.order === 'asc'
            ? 'sort up'
            : 'sort down'}`}
        size="large"
      />
    </span>
  );

  useEffect(() => {
    if (rowSelector && onRowSelectionChange) {
      onRowSelectionChange(selectedFlatRows.map(d => d.original));
    }
  }, [rowSelector, selectedFlatRows, onRowSelectionChange]);

  return (
    <div className={styles.root} data-cy={dataCy}>
      <Table {...getTableProps()}>
        {headerGroups.map((headerGroup, index) => (
          <Table.Header key={index} data-cy={`${dataCy}-header-group-${index}`}>
            <Table.Row data-cy={`${dataCy}-header-group-${index}-row`}>
              {headerGroup.headers.map(column => (
                <Table.HeaderCell
                  key={column.id}
                  singleLine
                  className={
                    classnames(
                      styles.headerCell,
                      { [styles.clickableHeaderCell]: onSortByChange !== null && column.sortable !== false },
                    )}
                  onClick={() => (onSortByChange && column.sortable !== false ? onSortByChange(column.id) : null)}
                >
                  {column.render('label')}
                  {column.sortable !== false && getSortIcon({ column, sortBy: currentSortBy })}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
        ))}

        <Table.Body data-cy={`${dataCy}-body`} {...getTableBodyProps()}>
          {rows.length === 0 && noDataMessage
            ? (
              <Table.Row>
                <Table.Cell>
                  {noDataMessage}
                </Table.Cell>
              </Table.Row>)
            : rows.map(
              (row, index) => {
                prepareRow(row);

                return (
                  <Table.Row
                    key={row.id}
                    active={row.isSelected}
                    data-cy={`${dataCy}-body-row-${index}`}
                  >
                    {row.cells.map(cell => (
                      <Table.Cell
                        key={cell.column.id}
                        data-cy={`${dataCy}-body-row-${index}-cell-${cell.column.id}`}
                        singleLine={cell.column.multiLine !== true}
                      >
                        {cell.render('Cell')}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                );
              },
            )}
        </Table.Body>

        {onPageChange
          ? (
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan={rowSelector ? columns.length + 1 : columns.length}>
                  <div className={styles.pagination}>
                    <Pagination
                      activePage={currentPage}
                      totalPages={pages}
                      onPageChange={(_e, { activePage }) => onPageChange(activePage)}
                    />
                    <div className={styles.pageSizeSelector}>
                      <span className={styles.pageSizeSelectorLabel}>
                        {'Show'}
                      </span>
                      <Dropdown
                        selection
                        options={pageLimitOptions}
                        value={currentLimit}
                        onChange={(_e, { value }) => onLimitChange(value)}
                      />
                    </div>
                  </div>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          ) : null
        }
      </Table>
    </div>
  );
};

SLTable.defaultProps = {
  currentLimit: PageLimit.SMALL,
  currentPage: 1,
  currentSortBy: {},
  data: [],
  dataCy: 'sl-table',
  hiddenColumns: [],
  noDataMessage: null,
  onLimitChange: null,
  onPageChange: null,
  onRowSelectionChange: null,
  onSortByChange: null,
  pages: 0,
  rowSelector: false,
  userData: null,
};

SLTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    accessor: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    sortable: PropTypes.bool,
  })).isRequired,
  currentLimit: PropTypes.number,
  currentPage: PropTypes.number,
  currentSortBy: PropTypes.object,
  data: PropTypes.arrayOf(PropTypes.object),
  dataCy: PropTypes.string,
  hiddenColumns: PropTypes.arrayOf(PropTypes.string),
  noDataMessage: PropTypes.string,
  pages: PropTypes.number,
  rowSelector: PropTypes.bool,
  userData: PropTypes.any,
  onLimitChange: PropTypes.func,
  onPageChange: PropTypes.func,
  onRowSelectionChange: PropTypes.func,
  onSortByChange: PropTypes.func,
};

export default SLTable;

import { Field } from 'formik';
import React, { useState } from 'react';
import { Form, Label } from 'semantic-ui-react';
import * as Yup from 'yup';

import { QuestionType } from '../../../../../constants';
import commonStyles from '../AuditingQuestion.module.scss';
import AuditingQuestionHeader from '../AuditingQuestionHeader/AuditingQuestionHeader.component';
import styles from './AuditingTextQuestion.module.scss';

const DATA_CY = 'auditing-text-question';

const validate = (question, skipRegExp) => value => {
  let error;

  // First check type and required
  let currentSchema;
  if (question.type === QuestionType.NUMBER) {
    currentSchema = question.required
      ? Yup.number().typeError('Required')
      : Yup.number().nullable();
  } else {
    currentSchema = question.required
      ? Yup.string().trim().ensure().min(1, 'Required')
      : Yup.string().nullable();
  }

  try {
    currentSchema.validateSync(value);
  } catch (err) {
    return err.message;
  }

  // Then check regexp
  if (value && question.type !== QuestionType.NUMBER && question.config.regexp !== null && !skipRegExp) {
    const regexp = new RegExp(question.config.regexp, 'g');
    if (!regexp.test(value)) {
      return `Invalid format${question.config.regexpHelp ? `: ${question.config.regexpHelp}` : '.'}`;
    }
  }

  return error;
};

const AuditingTextQuestion = () => {
  const [skipRegExp, setSkipRegExp] = useState(false);

  return (
    <Field name="question">
      {({ field: { value: question } }) => (
        <>
          <div className={commonStyles.question} data-cy={DATA_CY}>
            <AuditingQuestionHeader question={question} />

            <Field name="answer" validate={validate(question, skipRegExp)}>
              {({
                field: { value: answer, name: answerName },
                form: { setFieldValue },
                meta: { error },
              }) => {
                if (question.type === QuestionType.TEXTAREA) {
                  return (
                    <>
                      <Form.TextArea
                        fluid
                        className={styles.textArea}
                        required={question.required}
                        rows={question.config.rows}
                        value={answer}
                        onChange={(_e, { value }) => setFieldValue(answerName, value)}
                      />
                      <div className={commonStyles.error}>
                        {error}
                      </div>
                    </>
                  );
                }

                if (question.type === QuestionType.NUMBER) {
                  return (
                    <>
                      <div className={styles.question}>
                        <Form.Input
                          labelPosition={
                            (question.config.prefix?.trim().length > 0)
                            || (question.config.suffix?.trim().length > 0) ? 'right' : null
                          }
                          required={question.required}
                          type="number"
                          value={answer}
                          onChange={(_e, { value }) => setFieldValue(answerName, Number.parseFloat(value))}
                        >
                          {question.config.prefix?.trim().length > 0 ? (
                            <Label>
                              {question.config.prefix}
                            </Label>
                          ) : null}
                          <input />
                          {question.config.suffix?.trim().length > 0 ? (
                            <Label>
                              {question.config.suffix}
                            </Label>
                          ) : null}
                        </Form.Input>
                      </div>
                      <div className={commonStyles.error}>
                        {error}
                      </div>
                    </>
                  );
                }

                return (
                  <>
                    <div className={styles.question}>
                      <Form.Input
                        fluid
                        className={styles.input}
                        labelPosition={
                          (question.config.prefix?.trim().length > 0)
                          || (question.config.suffix?.trim().length > 0) ? 'right' : null
                        }
                        required={question.required}
                        value={answer}
                        onChange={(_e, { value }) => setFieldValue(answerName, value)}
                      >
                        {question.config.prefix?.trim().length > 0 ? (
                          <Label>
                            {question.config.prefix}
                          </Label>
                        ) : null}
                        <input />
                        {question.config.suffix?.trim().length > 0 ? (
                          <Label>
                            {question.config.suffix}
                          </Label>
                        ) : null}
                      </Form.Input>
                    </div>
                    <div className={commonStyles.error}>
                      {error}
                    </div>
                  </>
                );
              }}
            </Field>
            {question.config.regexp && question.config.regexpHelp && (
              <Form.Checkbox
                toggle
                className={styles.skipRegExp}
                label="Your answer should match the predefined format. If it does not, press here to submit an answer that doesn't match"
                onChange={(_event, { checked }) => setSkipRegExp(checked)}
              />
            )}
          </div>
        </>
      )}
    </Field>
  );
};

export default AuditingTextQuestion;

import getCurrencySymbol from 'currency-symbols';
import PropTypes from 'prop-types';
import React from 'react';

const CurrencyFormatter = ({ cell }) => (
  <span>
    {`${cell.value?.toFixed(2)} ${getCurrencySymbol(cell.row.original.order_currency) || cell.row.original.order_currency}`}
  </span>
);

CurrencyFormatter.propTypes = {
  cell: PropTypes.object.isRequired,
};

export default CurrencyFormatter;

import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import SortableTree, { toggleExpandedForAll } from 'react-sortable-tree';
import { Button, Header, Icon, Input, Label, Segment } from 'semantic-ui-react';

import styles from './HierarchicalTree.module.scss';

const HierarchicalTree = ({ data, id, showFiltered }) => {
  const [searchString, setSearchString] = useState('');
  const [searchFocusIndex, setSearchFocusIndex] = useState(0);
  const [searchFoundCount, setSearchFoundCount] = useState(null);
  const [treeData, setTreeData] = useState(data);

  const updateTreeData = tree => {
    setTreeData(tree);
  };

  const expand = useCallback(expanded => {
    setTreeData(t => toggleExpandedForAll({
      treeData: t,
      expanded,
    }));
  }, []);

  useEffect(() => {
    if (showFiltered) {
      expand(true);
    }
  }, [showFiltered, expand]);

  const selectPrevMatch = () => {
    setSearchFocusIndex(
      searchFocusIndex !== null
        ? (searchFoundCount + searchFocusIndex - 1) % searchFoundCount
        : searchFoundCount - 1,
    );
  };

  const selectNextMatch = () => {
    setSearchFocusIndex(
      searchFocusIndex !== null ? (searchFocusIndex + 1) % searchFoundCount : 0,
    );
  };

  const printCheck = node => {
    if (Array.isArray(id)) {
      return id.find(i => i === node) ? <Icon className={styles.selectedNode} name="checkmark box" /> : null;
    }
    if (id === node) {
      return <Icon className={styles.selectedNode} name="checkmark box" />;
    }
    return null;
  };

  return (
    <Segment.Group>
      <Segment>
        <div className={styles.sortableTreeContainer}>
          {treeData ? (
            <SortableTree
              canDrag={false}
              generateNodeProps={rowInfo => ({
                title: <span>
                  {rowInfo.node.title}
                </span>,
                subtitle: rowInfo.node.subTitle,
                buttons: [<>
                  {printCheck(rowInfo.node.id)}
                </>],
              })}
              searchFinishCallback={matches => {
                setSearchFoundCount(matches.length);
                setSearchFocusIndex(
                  matches.length > 0 ? searchFocusIndex % matches.length : 0,
                );
              }}
              searchFocusOffset={searchFocusIndex}
              searchQuery={searchString}
              treeData={treeData}
              onChange={changedTreeData => updateTreeData(changedTreeData)}
            />
          ) : (
            <Header icon className={styles.emptyContainer}>
              <Icon name="inbox" />
              {'No results found'}
              <Header.Subheader>
                {'Upload new Hierarchical Levels'}
              </Header.Subheader>
            </Header>
          )}
        </div>
        <div className={styles.buttonsContainer}>
          <Button content={'Expand All'} onClick={() => expand(true)} />
          <Button content={'Collapse All'} onClick={() => expand(false)} />
        </div>
      </Segment>
      <Segment className={styles.searchContainer}>
        <div>
          <Input
            labelPosition="left"
            placeholder="Find node"
            onChange={event => setSearchString(event.target.value)}
          >
            <Label>
              {'Search:'}
            </Label>
            <input />
            <Label basic>
              <span>
                {searchFoundCount > 0 ? searchFocusIndex + 1 : 0}
                {'/'}
                {searchFoundCount || 0}
              </span>
            </Label>
          </Input>
        </div>
        <div>
          <Button.Group>
            <Button
              content="Prev"
              disabled={!searchFoundCount}
              icon="left arrow"
              labelPosition="left"
              type="button"
              onClick={selectPrevMatch}
            />
            <Button
              content="Next"
              disabled={!searchFoundCount}
              icon="right arrow"
              labelPosition="right"
              type="button"
              onClick={selectNextMatch}
            />
          </Button.Group>
        </div>
      </Segment>
    </Segment.Group>
  );
};

HierarchicalTree.defaultProps = {
  data: null,
  id: null,
  showFiltered: false,
};

HierarchicalTree.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  id: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
  ]),
  showFiltered: PropTypes.bool,
};

export default HierarchicalTree;

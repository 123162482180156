import PropTypes from 'prop-types';
import React from 'react';

import { UserRole } from '../../../../constants';
import { RoleChecker } from '../../../layout';
import AuditsTableHeaderAdminRole from './AuditsTableHeaderAdminRole/AuditsTableHeaderAdminRole.component';
import AuditsTableHeaderAuditorRole from './AuditsTableHeaderAuditorRole/AuditsTableHeaderAuditorRole.component';

const AuditsTableHeader = ({
  count,
  isFilterVisible,
  setFilterVisible,
}) => (
  <>
    <RoleChecker allowedRoles={[UserRole.DATA, UserRole.CLIENT_SERVICES, UserRole.AREA_MANAGER, UserRole.CLIENT]}>
      <AuditsTableHeaderAdminRole
        count={count}
        isFilterVisible={isFilterVisible}
        setFilterVisible={setFilterVisible}
      />
    </RoleChecker>
    <RoleChecker allowedRoles={[UserRole.AUDITOR]}>
      <AuditsTableHeaderAuditorRole
        isFilterVisible={isFilterVisible}
        setFilterVisible={setFilterVisible}
      />
    </RoleChecker>
  </>
);

AuditsTableHeader.defaultProps = {
  count: null,
  isFilterVisible: false,
  setFilterVisible: null,
};

AuditsTableHeader.propTypes = {
  count: PropTypes.number,
  isFilterVisible: PropTypes.bool,
  setFilterVisible: PropTypes.func,
};

export default AuditsTableHeader;

import * as Yup from 'yup';

export const taxonomyDetailSchema = Yup.object().shape({
  terms: Yup.array().of(Yup.object().shape({
    disabled: Yup.boolean().required('Required'),
    options: Yup.array().of(Yup.object().shape({
      disabled: Yup.boolean().required('Required'),
      text: Yup.string().trim().ensure().min(1, 'Required'),
      value: Yup.string().trim().ensure().min(1, 'Required'),
    })),
    text: Yup.string().trim().ensure().min(1, 'Required'),
    value: Yup.string().trim().ensure().min(1, 'Required'),
  })),
});

export const payRateSchema = Yup.object().shape({
  terms: Yup.array().of(Yup.object().shape({
    disabled: Yup.boolean().required('Required'),
    options: Yup.array().of(Yup.object().shape({
      disabled: Yup.boolean().required('Required'),
      text: Yup.string().trim().ensure().min(1, 'Required'),
      value: Yup.number().min(0, 'Must be greater than or equal to 0').required('Required'),
    })),
    text: Yup.string().trim().ensure().min(1, 'Required'),
    value: Yup.string().trim().ensure().min(1, 'Required'),
  })),
});

export const STORE_AUDIT_DETAILS = 'STORE_AUDIT_DETAILS';

export const REMOVE_AUDIT = 'REMOVE_AUDIT';
export const CLEAR_ALL_AUDITS = 'CLEAR_ALL_AUDITS';

export const INIT_AUDIT = 'INIT_AUDIT';
export const UPDATE_AUDIT_STEP = 'UPDATE_AUDIT_STEP';
export const UPDATE_AUDIT_LOCATION_AND_DATE = 'UPDATE_AUDIT_LOCATION_AND_DATE';
export const UPDATE_AUDIT_ANSWERS = 'UPDATE_AUDIT_ANSWERS';
export const UPDATE_AUDIT_CURRENT_QUESTION_ID = 'UPDATE_AUDIT_CURRENT_QUESTION_ID';
export const UPDATE_AUDIT_EXTRA_DETAILS = 'UPDATE_AUDIT_EXTRA_DETAILS';

import React, { useCallback, useRef } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { surveyRevisionsAPI } from '../../../../../api';
import { Entity, SurveyEntity } from '../../../../../constants';
import history from '../../../../../history';
import { useUpdateSurvey } from '../../../../../hooks';
import { LoadingPlaceholder } from '../../../../common';
import SurveyStep from '../../SurveyStep/SurveyStep.component';
import SurveyValidationEditor from './SurveyValidationEditor/SurveyValidationEditor.component';
import SurveyValidationSidebar from './SurveyValidationSidebar/SurveyValidationSidebar.component';

const SurveyValidation = () => {
  const { entityId, revisionId } = useParams();
  const submitFormRef = useRef(null);

  const { data } = useQuery(
    [entityId, revisionId, { include: [SurveyEntity.QUESTIONS, SurveyEntity.VALIDATION] }],
    () => surveyRevisionsAPI.fetchOne(
      entityId,
      revisionId,
      { include: [SurveyEntity.QUESTIONS, SurveyEntity.VALIDATION] },
    ),
  );

  const { mutate: updateSurvey } = useUpdateSurvey({
    currentStep: SurveyEntity.VALIDATION,
    onSuccessStep: SurveyEntity.REPORTS,
  });

  const back = useCallback(() => {
    history.push(`/${Entity.SURVEYS}/${entityId}/revisions/${revisionId}/${SurveyEntity.BRANCHING}`);
  }, [entityId, revisionId]);

  // const next = useCallback(() => {
  //   history.push(
  //     `/${Entity.SURVEYS}/${entityId}/revisions/${revisionId}/${SurveyEntity.REPORTS}`,
  //   );
  // }, [entityId, revisionId]);

  const handleSubmit = useCallback(values => {
    // FIXME: [SURVEY BLOCKED] uncomment when ServeLegal agrees
    // if (data?.blocked) {
    //   next();
    // } else {
    updateSurvey({
      surveyId: entityId,
      revisionId,
      payload: {
        [SurveyEntity.VALIDATION]: values.validation,
      },
    });
    // }
  }, [entityId, revisionId, updateSurvey]);

  return (
    <SurveyStep
      back={back}
      backContent="Back to Branching"
      editor={data === undefined ? LoadingPlaceholder : SurveyValidationEditor}
      editorProps={{ questions: data?.questions, validation: data?.validation, submitFormRef, onSubmit: handleSubmit }}
      headerProps={{ blocked: data?.blocked }}
      next={() => submitFormRef?.current?.click()}
      nextContent={/* data?.blocked ? 'Go to Reports' : */'Save & Continue'}
      sidebar={SurveyValidationSidebar}
    />
  );
};

export default SurveyValidation;

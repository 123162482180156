import PropTypes from 'prop-types';
import React, { createRef, useCallback, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Modal } from 'semantic-ui-react';

import { auditingAPI, errorAPI } from '../../../../../api';
import { RedPushpinMarker } from '../../../../../assets/images/markers';
import { auditSelector } from '../../../../../state/audits/selectors';
import { isSystemInitialisedSelector } from '../../../../../state/ui/selectors';
import { getGeolocation } from '../../../../../utils/auto-geolocation';
import { createLatLng, mapOptions } from '../../../../../utils/googleMapsHelpers';
import styles from '../AuditingLocation.module.scss';

const DATA_CY = 'auditing-location-modal';

const AuditingLocationModal = ({ open, onCancel, onConfirm }) => {
  const { entityId } = useParams();
  const alert = useAlert();
  const mapRef = createRef();
  const [map, setMap] = useState(null);
  const [coords, setCoords] = useState(null);
  const isSystemInitialised = useSelector(isSystemInitialisedSelector);

  const storedAuditSelector = state => auditSelector(state, entityId);
  useSelector(storedAuditSelector);

  const initMap = useCallback(() => {
    if (mapRef.current && open) {
      const googleMap = new window.google.maps.Map(mapRef.current, mapOptions({ maxZoom: 15 }));
      setMap(googleMap);
    }
  }, [open]);

  const getCoords = async pos => {
    const { latitude, longitude } = await pos.coords;
    return setCoords({ lat: latitude, lng: longitude });
  };

  const locationErrorModal = () => {
    alert.show('Please turn on the location services on your device.');
  };

  const { mutate: saveAuditVisitCoordinatesAndDate } = useMutation(auditingAPI.setStartCoordinatesAndDate, {
    onSuccess: async () => {
      alert.success('Audit visit coordinates saved successfully');
    },
    onError: async error => {
      alert.error('Error in saving audit visit coordinates.');
      await errorAPI.sendError(error.message, error.stack);
    },
  });

  const createMarker = useCallback(item => new window.google.maps.Marker({
    position: {
      lat: Number(item?.lat),
      lng: Number(item?.lng),
    },
    map,
    icon: RedPushpinMarker,
    title: 'audit',
  }), [map]);

  useEffect(() => {
    getGeolocation(getCoords, locationErrorModal);
    return () => {
      setCoords(null);
      setMap(null);
    };
  }, []);

  useEffect(() => {
    const bounds = new window.google.maps.LatLngBounds();

    if (coords) bounds.extend(createLatLng(coords));
    if (map && coords) {
      map.fitBounds(bounds);
      createMarker(coords);
    }
  }, [createMarker, map, coords]);

  const handleDateAndCoordinatesSubmit = async () => {
    const geolocatingTimestamp = new Date().toISOString();
    await saveAuditVisitCoordinatesAndDate({
      auditId: entityId,
      payload: { ...coords, audit_geolocation_date: geolocatingTimestamp },
    });
    onConfirm(coords);
  };

  useEffect(() => {
    if (isSystemInitialised && open) {
      initMap();
    }
  }, [isSystemInitialised, initMap, open]);

  return (
    <>
      <Modal
        closeOnDimmerClick={true}
        open={open}
        onClose={() => onConfirm()}
      >
        <Modal.Header>
          {'Send location to confirm audit visit'}
        </Modal.Header>
        <>
          <Modal.Content>
            <div ref={mapRef} className={styles.map} />
          </Modal.Content>
          <Modal.Actions>
            <Button data-cy={`${DATA_CY}-cancel-button`} onClick={() => onCancel()}>
              {'Cancel'}
            </Button>
            <Button primary data-cy={`${DATA_CY}-submit-button`} onClick={() => handleDateAndCoordinatesSubmit()}>
              {'Send Location'}
            </Button>
          </Modal.Actions>
        </>
      </Modal>
    </>
  );
};

AuditingLocationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default AuditingLocationModal;

import PropTypes from 'prop-types';
import React, { createRef, useCallback, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, Icon } from 'semantic-ui-react';

import { RedPushpinMarker } from '../../../../assets/images/markers';
import { updateAuditLocationAndDate } from '../../../../state/audits/actionCreators';
import { isSystemInitialisedSelector } from '../../../../state/ui/selectors';
import { getGeolocation } from '../../../../utils/auto-geolocation';
import { createLatLng, mapOptions } from '../../../../utils/googleMapsHelpers';
import styles from './AuditingLocation.module.scss';

const DATA_CY = 'auditing-location';

const AuditingLocation = ({ data, next }) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const mapRef = createRef();
  const [map, setMap] = useState(null);
  const [coords, setCoords] = useState(null);
  const isSystemInitialised = useSelector(isSystemInitialisedSelector);

  const initMap = useCallback(() => {
    if (mapRef.current && !map) {
      const googleMap = new window.google.maps.Map(mapRef.current, mapOptions({ maxZoom: 15 }));
      setMap(googleMap);
    }
  }, [map, mapRef]);

  const getCoords = async pos => {
    const { latitude, longitude } = await pos.coords;
    return setCoords({ lat: latitude, lng: longitude });
  };

  const locationErrorModal = () => {
    alert.show('Please turn on the location services on your device.');
  };

  const createMarker = useCallback(item => new window.google.maps.Marker({
    position: {
      lat: Number(item?.lat),
      lng: Number(item?.lng),
    },
    map,
    icon: RedPushpinMarker,
    title: 'audit',
  }), [map]);

  useEffect(() => {
    getGeolocation(getCoords, locationErrorModal);
  }, []);

  useEffect(() => {
    const bounds = new window.google.maps.LatLngBounds();

    if (coords) bounds.extend(createLatLng(coords));
    if (map && coords) {
      map.fitBounds(bounds);
      createMarker(coords);
    }
  }, [createMarker, map, coords]);

  useEffect(() => {
    if (isSystemInitialised) {
      initMap();
    }
  }, [isSystemInitialised, initMap]);

  const submitLocationAndDate = values => {
    const auditGeolocationDate = new Date().toISOString();
    if (values) {
      dispatch(updateAuditLocationAndDate({ id: data.id, auditGeolocationDate, ...values }));
    } else {
      dispatch(updateAuditLocationAndDate({ id: data.id }));
    }
    next();
  };

  return (
    <Grid centered>
      <Grid.Row>
        <h3>
          {'Send location to confirm audit visit'}
        </h3>
      </Grid.Row>
      <Grid.Row>
        <Button
          icon
          secondary
          data-cy={`${DATA_CY}-skip-location-button`}
          labelPosition="left"
          onClick={() => submitLocationAndDate(null)}
        >
          <Icon className="icon-no-location" />
          {'Unable to send location'}
        </Button>
        <Button
          icon
          primary
          data-cy={`${DATA_CY}-send-location-button`}
          disabled={!coords}
          labelPosition="left"
          onClick={() => submitLocationAndDate(coords)}
        >
          {coords
            ? <Icon className="icon-location" />
            : <Icon loading name="spinner" />
          }
          {'Send location'}
        </Button>
      </Grid.Row>
      <Grid.Row>
        <div ref={mapRef} className={styles.map} />
      </Grid.Row>
    </Grid>
  );
};

AuditingLocation.propTypes = {
  data: PropTypes.object.isRequired,
  next: PropTypes.func.isRequired,
};

export default AuditingLocation;

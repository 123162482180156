import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { entitiesAPI } from '../../../api';
import { Entity } from '../../../constants';
import { PatchesContext } from '../../../context';
import { View } from '../../layout';
import PatchesMap from './PatchesMap/PatchesMap.component';
import PatchesSidebar from './PatchesSidebar/PatchesSidebar.component';

const DATA_CY = 'patches-view';

const PatchesView = () => {
  const [selectedPatchId, setSelectedPatchId] = useState(null);
  const [newPatchActive, setNewPatchActive] = useState(false);

  const { data: patchesQuery, refetch: refetchPatches } = useQuery(
    [Entity.PATCHES],
    // todo: to be changed to fetchAll
    () => entitiesAPI.fetchAllv1(Entity.PATCHES),
  );

  const { data: patchDetails } = useQuery(
    [Entity.PATCHES, selectedPatchId],
    () => entitiesAPI.fetchOne({ entity: Entity.PATCHES, entityId: selectedPatchId }),
    { enabled: Boolean(selectedPatchId) },
  );

  const resetSelectedPatch = () => {
    setSelectedPatchId(null);
    setNewPatchActive(false);
    refetchPatches();
  };

  return (
    <PatchesContext.Provider
      value={{
        newPatchActive,
        patches: patchesQuery?.items || [],
        resetSelectedPatch,
        selectedPatch: selectedPatchId ? patchDetails : null,
        selectedPatchId,
        setNewPatchActive,
        setSelectedPatchId,
      }}
    >
      <View
        condensed
        body={PatchesMap}
        bodyProps={{
          patches: patchesQuery?.items || [],
        }}
        dataCy={DATA_CY}
        sidebar={PatchesSidebar}
      />
    </PatchesContext.Provider>);
};
export default PatchesView;

const AppRoute = {
  ACCOUNT: { path: '/account/:tabId?' },
  AUDIT_DETAILS: { path: '/audits/:entityId' },
  CHANGE_PASSWORD: { path: '/changePassword' },
  CLIENTS_AUTOMATED_EXPORT_DETAILS: { path: '/clients/automated/:exportId' },
  CLIENTS_AUTOMATED_EXPORTS_NEW: { path: '/clients/automated/new' },
  CLIENTS_AUTOMATED_EXPORTS: { path: '/clients/automated' },
  DASHBOARD: { path: '/' },
  ENTITY_DETAILS_MODE: { path: '/:entity/:entityId/:mode' },
  ENTITY_DETAILS: { path: '/:entity/:entityId' },
  ENTITY_LIST: { path: '/:entity' },
  ENTITY_NEW: { path: '/:entity/new' },
  ENTITY_REVISION_DETAILS: { path: '/:entity/:entityId/revisions/:revisionId' },
  ENTITY_REVISIONS_COMPARE: { path: '/:entity/:entityId/revisions/compare' },
  ENTITY_REVISIONS_LIST: { path: '/:entity/:entityId/revisions' },
  ENTITY_UPLOAD: { path: '/:entity/upload' },
  LOGIN: { path: '/login' },
  MAP: { path: '/:entity/map' },
  PATCHES_LIST: { path: '/patches' },
  REGISTER_AUDITOR: { path: '/register' },
  REPORTS: { path: '/reports' },
  REQUEST_NEW_PASSWORD: { path: '/newPassword' },
  SURVEY_NEW: { path: '/surveys/new' },
  SURVEY_REVISION_DETAILS: { path: '/surveys/:entityId/revisions/:revisionId/:surveyStep' },
  TIPS_AND_DOCS: { path: '/tips-and-docs' },
  USER_SETTINGS: { path: '/users/settings' },
  VERIFY_EMAIL: { path: '/verifyEmail' },
  ARCHIVE_DETAILS: { path: '/archives/:siteId/:year/:month/:day/:legacyId' },
};

export default AppRoute;

import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { auditStepSelector } from '../../../../state/audits/selectors';
import { SLHeader } from '../../../common';

const DATA_CY = 'auditing-header';

const AuditingHeader = ({ audit }) => {
  const stepSelector = state => (audit ? auditStepSelector(state, audit.original.id) : null);
  const auditStep = useSelector(stepSelector);

  if (audit) {
    return (
      <SLHeader
        dataCy={DATA_CY}
        title={`${audit.original.client_name}${auditStep ? ` - ${auditStep}` : ''}`}
      />
    );
  }
  return null;
};

AuditingHeader.propTypes = {
  audit: PropTypes.object.isRequired,
};

export default AuditingHeader;

import { Field, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, Tab } from 'semantic-ui-react';

import { FormFieldWrapper } from '../../../../common';
import styles from './UserSettingsEmailTemplates.module.scss';

const UserSettingsEmailTemplates = ({ dataCy }) => {
  const { values } = useFormikContext();
  return (
    <Field name="email_templates">
      {() => (
        <Form.Field width={12}>
          <Tab
            data-cy={`${dataCy}-email-templates`}
            grid={ { paneWidth: 11, tabWidth: 4 } }
            menu={ { fluid: true, vertical: true, tabular: true } }
            panes={values
              .map((item, idx) => ({
                menuItem: item.tab_text,
                render: () => (
                  <Tab.Pane key={idx}>
                    <div className={styles.subFields}>
                      <div className={styles.help}>
                        {item.help}
                      </div>
                      <Form.Field>
                        <Form.Field>
                          <label>
                          Subject
                          </label>
                        </Form.Field>
                        <Form.Field>
                          <FormFieldWrapper
                            name={`[${idx}].subject`}
                          />
                        </Form.Field>
                      </Form.Field>

                      <Form.Field>
                        <Form.Field>
                          <label>
Body
                          </label>
                        </Form.Field>
                        <Form.Field>
                          <FormFieldWrapper
                            name={`[${idx}].body`}
                            type="textarea"
                          />
                        </Form.Field>
                        <Button className={styles.tokensButton} size="mini">
Tokens
                        </Button>
                      </Form.Field>
                    </div>
                  </Tab.Pane>
                ),
              }))
            }
          />
        </Form.Field>
      )}
    </Field>
  );
};

UserSettingsEmailTemplates.propTypes = {
  dataCy: PropTypes.string.isRequired,
};

export default UserSettingsEmailTemplates;

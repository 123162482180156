import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Grid } from 'semantic-ui-react';

import { QuestionType } from '../../../../constants';
import isMobile from '../../../../utils/isMobile';
import getAnswer from './helpers';
import styles from './ReviewQuestion.module.scss';

const ReviewQuestion = ({
  answer,
  missing,
  onAmendClick,
  question,
  urls,
}) => {
  const [parsedAnswer, setParsedAnswer] = useState(null);

  useEffect(() => {
    async function fetchThumbs(a, u, q) {
      const p = await getAnswer(a, u, q);
      setParsedAnswer(p);
    }

    fetchThumbs(answer, urls, question);
  }, [answer, urls]);

  return (answer.hidden
    ? null
    : (
      <Grid.Row key={question.id} className={styles.listItem}>
        <Grid.Column
          className={classnames({ [styles.missing]: missing, [styles.question]: true })}
          textAlign="left"
        >
          {question.title}
          <span className={question.required ? styles.required : styles.hidden}>
            {'*'}
          </span>
        </Grid.Column>
        <Grid.Column textAlign={isMobile ? 'left' : 'center'}>
          {parsedAnswer}
        </Grid.Column>
        <Grid.Column textAlign={isMobile ? 'center' : 'right'}>
          {question.type !== QuestionType.TITLE && (
            <Button secondary onClick={() => onAmendClick(question.id)}>
              {'Amend'}
            </Button>
          )}
        </Grid.Column>
      </Grid.Row>
    ));
};

ReviewQuestion.defaultProps = {
  missing: false,
  urls: null,
};

ReviewQuestion.propTypes = {
  answer: PropTypes.object.isRequired,
  question: PropTypes.object.isRequired,
  onAmendClick: PropTypes.func.isRequired,
  missing: PropTypes.bool,
  urls: PropTypes.array,
};

export default ReviewQuestion;

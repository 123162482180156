import { Field } from 'formik';
import React from 'react';

import commonStyles from '../AuditingQuestion.module.scss';
import AuditingQuestionHeader from '../AuditingQuestionHeader/AuditingQuestionHeader.component';

const DATA_CY = 'auditing-title.question';

const AuditingTitleQuestion = () => (
  <div className={commonStyles.question} data-cy={DATA_CY}>
    <Field name="question">
      {({ field: { value: question } }) => (
        <AuditingQuestionHeader question={question} />
      )}
    </Field>
  </div>
);

export default AuditingTitleQuestion;

import { types } from 'react-alert';
import { createAction } from 'redux-actions';

import * as actionTypes from './actionTypes';

export const initialiseSystem = createAction(actionTypes.INITIALISE_SYSTEM);
export const setSystemInitialised = createAction(actionTypes.SET_SYSTEM_INITIALISED);

export const showAlert = createAction(
  actionTypes.SHOW_ALERT,
  ({ message, type = types.INFO }) => ({ message, type }),
);

export const showNotification = createAction(
  actionTypes.SHOW_NOTIFICATION,
  ({ message, type = types.INFO }) => ({ message, type }),
);

import * as Yup from 'yup';

import { QuestionType } from '../../../constants';

const questionValidationSchema = Yup.object({
  config: Yup.object(),
  help: Yup.string(),
  required: Yup.boolean(),
  title: Yup.string().trim().ensure().min(1, 'Required'),
  type: Yup.mixed().oneOf(Object.values(QuestionType)),
});

export const surveyRevisionValidationSchema = Yup.object({
  name: Yup.string().trim().ensure().min(1, 'Required'),
  questions: Yup.array().of(questionValidationSchema),
  survey_title: Yup.string().trim().ensure().min(1, 'Required'),
});

export const surveyRevisionDefaultValues = {
  name: '',
  questions: [],
  survey_title: '',
  blocked: false,
};

export const surveyFilterDefaultValues = {
  title: '',
};

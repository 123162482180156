import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { useMutation } from 'react-query';
import { Button } from 'semantic-ui-react';

import { entitiesAPI, errorAPI } from '../../../../../api';
import { Entity } from '../../../../../constants';
import history from '../../../../../history';
import { uuidType } from '../../../../types';
import OrderCloneModal from './OrderCloneModal.component';

const OrderActions = ({ entityId }) => {
  const alert = useAlert();
  const [modalOpen, setModalOpen] = useState(false);

  const onOpenModal = () => {
    setModalOpen(true);
  };

  const onCloseModal = () => {
    setModalOpen(false);
  };

  const { mutate: cloneOrder } = useMutation(entitiesAPI.clone, {
    onSuccess: ({ id }) => {
      alert.success(`Order ${entityId} succesfully cloned`);
      history.push(`/${Entity.ORDERS}/${id}`);
    },
    onError: error => {
      errorAPI.sendError(error.message, error.stack);
      alert.error(`Error cloning order:: ${JSON.stringify(error)}`);
    },
  });

  const handleClone = cloneDates => {
    cloneOrder({
      entity: Entity.ORDERS,
      entityId,
      payload: cloneDates,
    });
  };

  return (
    <>
      <OrderCloneModal
        modalOpen={modalOpen}
        onCloseModal={onCloseModal}
        onSubmit={handleClone}
      />
      <Button
        secondary
        onClick={() => onOpenModal()}
      >
        {'Clone'}
      </Button>
    </>
  );
};

OrderActions.propTypes = {
  entityId: uuidType.isRequired,
};

export default OrderActions;

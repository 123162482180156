import _isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { UserRole } from '../../../../constants';
import { useUserRole } from '../../../../hooks';
import { columnsVisibility, FieldScope } from '../../../../rolePermissionMatrix';
import { offlineAuditsSelector } from '../../../../state/audits/selectors';
import {
  enumOptionsSelector,
  taxonomyTermOptionsSelector,
} from '../../../../state/constants/selectors';
import isOnline from '../../../../utils/connection';
import isMobile from '../../../../utils/isMobile';
import { parse } from '../../../../utils/queryString';
import {
  SLTable,
  WaitPlaceholder,
} from '../../../common';
import { RoleChecker } from '../../../layout';
import AuditsAccordion from '../AuditsAccordion/AuditsAccordion.component';
import AuditsEmpty from '../AuditsEmpty/AuditsEmpty.component';
import AuditsTableBulkOperations from '../AuditsTableBulkOperations/AuditsTableBulkOperations.component';
import { auditorColumns, nonAuditorColumns } from './helpers';

const DATA_CY = 'audits-table';

const scheduleTypeSelector = state => enumOptionsSelector(state, 'schedule_type');
const itemToOrderSelector = state => taxonomyTermOptionsSelector(state, 'order_item');

const AuditsTable = ({
  data,
  pages,
  onPageChange,
  onSortByChange,
  currentPage,
  currentSortBy,
  refetch,
  onLimitChange,
  currentLimit,
}) => {
  const { entity } = useParams();
  const { search } = useLocation();
  const { status } = parse(search);
  const role = useUserRole();
  const online = isOnline();

  const allAuditSelector = state => offlineAuditsSelector(state);
  const offlineAudits = useSelector(allAuditSelector);
  const scheduleTypeOptions = useSelector(scheduleTypeSelector);
  const itemToOrderOptions = useSelector(itemToOrderSelector);
  const [selectedAudits, setSelectedAudits] = useState([]);
  const [bulkInProgress, setBulkInProgress] = useState(false);
  const [offlineData, setOfflineData] = useState(data);

  const filterData = queryData => {
    if (role === UserRole.AUDITOR && !online) {
      return offlineAudits.map(({ original }) => original);
    }
    return queryData;
  };

  useEffect(() => {
    setOfflineData(filterData(data));
  }, [data, online, offlineAudits, offlineData, setOfflineData]);

  const selectedColumns = () => {
    // FIXME: apply filter to auditor columns using role matrix
    if (role === UserRole.AUDITOR) {
      const auditorColumnsToDisplay = auditorColumns(status);
      return auditorColumnsToDisplay;
    }

    // FIXME: columns should be ordered depending on the result of 'columnsVisibility'
    const visibleColumns = columnsVisibility({ entity, scope: FieldScope.TABLE_VIEW_COLUMNS, role });
    const availableColumns = nonAuditorColumns({ scheduleTypeOptions, itemToOrderOptions, role });
    return availableColumns.filter(column => visibleColumns.includes(column.accessor));
  };

  const onRowSelectionChange = rows => {
    if (!_isEqual(rows.map(row => row.id), selectedAudits.map(row => row.id))) {
      setSelectedAudits(rows);
    }
  };

  const allowRowSelector = ({ checkRole, checkStatus }) => {
    if (checkRole === UserRole.AUDITOR) {
      return checkStatus === 'assigned';
    }
    return true;
  };

  const getTable = () => (
    <SLTable
      columns={selectedColumns()}
      currentLimit={currentLimit}
      currentPage={currentPage}
      currentSortBy={currentSortBy}
      data={offlineData}
      dataCy={DATA_CY}
      pages={pages}
      rowSelector={allowRowSelector({ checkRole: role, checkStatus: status })}
      onLimitChange={onLimitChange}
      onPageChange={onPageChange}
      onRowSelectionChange={onRowSelectionChange}
      onSortByChange={onSortByChange}
    />
  );

  return (
    <>
      {bulkInProgress ? <WaitPlaceholder /> : null}
      <AuditsTableBulkOperations
        audits={data}
        refetch={refetch}
        selectedAudits={selectedAudits}
        setOfflineData={setOfflineData}
        onBulkEnd={() => setBulkInProgress(false)}
        onBulkStart={() => setBulkInProgress(true)}
      />
      <RoleChecker allowedRoles={[UserRole.AUDITOR]}>
        {offlineData
          ? isMobile
            ? (
              <AuditsAccordion
                currentPage={currentPage}
                data={offlineData}
                pages={pages}
                rowSelector={allowRowSelector({ checkRole: role, checkStatus: status })}
                onPageChange={onPageChange}
                onRowSelectionChange={onRowSelectionChange}
              />
            ) : getTable()
          : <AuditsEmpty />}
      </RoleChecker>
      <RoleChecker allowedRoles={[UserRole.AREA_MANAGER, UserRole.CLIENT, UserRole.CLIENT_SERVICES, UserRole.DATA]}>
        {getTable()}
      </RoleChecker>
    </>
  );
};

AuditsTable.propTypes = {
  currentLimit: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  currentSortBy: PropTypes.object.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  pages: PropTypes.number.isRequired,
  refetch: PropTypes.func.isRequired,
  onLimitChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onSortByChange: PropTypes.func.isRequired,

};

export default AuditsTable;

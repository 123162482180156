import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';

import AuditorDetailsEdit from '../AuditorDetailsEdit/AuditorDetailsEdit.component';
import AuditorDetailsView from '../AuditorDetailsView/AuditorDetailsView.component';

const AuditorDetails = ({ data, submitFormRef, refetch }) => {
  const { mode } = useParams();

  return mode === 'view'
    ? <AuditorDetailsView data={data} />
    : <AuditorDetailsEdit data={data} refetch={refetch} submitFormRef={submitFormRef} />;
};

AuditorDetails.propTypes = {
  data: PropTypes.object.isRequired, // TODO: auditorType.isRequired,
  refetch: PropTypes.func.isRequired,
  submitFormRef: PropTypes.object.isRequired,
};

export default AuditorDetails;

import { ErrorMessage, Field, FieldArray, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useAlert } from 'react-alert';
import { Button, Card, Divider, Form, Icon } from 'semantic-ui-react';

import { FormikErrorMessage } from '../../../../../common';
import { questionType, validationRuleType } from '../../../../../types';
import SurveyQuestionsReference from '../../SurveyQuestionsReference/SurveyQuestionsReference.component';
import { defaultValidationRule, validationValidationSchema } from '../helpers';
import styles from './SurveyValidationEditor.module.scss';

const SurveyValidationEditor = ({ questions, validation, submitFormRef, onSubmit }) => {
  const alert = useAlert();

  return (
    <div>
      <p>
      When a user is filling out this survey the following validation rules are automatically
      evaluated:
      </p>
      <Card fluid>
        <Card.Content className={styles.scroll}>
          <Formik
            initialValues={{ validation }}
            validationSchema={validationValidationSchema}
            onSubmit={onSubmit}
          >
            {({ values, handleSubmit, errors, isValid }) => {
              const submit = () => {
                if (!isValid) {
                  alert.error(
                    <pre>
                      {JSON.stringify(errors, null, 2)}
                    </pre>,
                  );
                } else {
                  handleSubmit();
                }
              };
              return (
                <Form noValidate>
                  <button ref={submitFormRef} hidden type="submit" onClick={submit} />
                  <FieldArray
                    name="validation"
                    render={arrayHelpers => (
                      <>
                        {values.validation.map((_rule, index) => (
                          <div key={index}>
                            <Form.Group>
                              <Form.Field required width={15}>
                                <Field
                                  required
                                  as={Form.Input}
                                  label="Logic"
                                  name={`validation.${index}.logic`}
                                />
                                <ErrorMessage
                                  component={FormikErrorMessage}
                                  name={`validation.${index}.logic`}
                                />
                              </Form.Field>
                              <Form.Field width={1}>
                                <Icon
                                  className={styles.remove}
                                  onClick={() => arrayHelpers.remove(index)}
                                />
                              </Form.Field>
                            </Form.Group>
                            <Form.Field required>
                              <Field
                                required
                                as={Form.Input}
                                label="Message"
                                name={`validation.${index}.message`}
                              />
                              <ErrorMessage
                                component={FormikErrorMessage}
                                name={`validation.${index}.message`}
                              />
                            </Form.Field>
                            <Divider />
                          </div>
                        ))}
                        <Button
                          primary
                          onClick={() => arrayHelpers.push(defaultValidationRule)}
                        >
                      Add validation
                        </Button>
                      </>
                    )}
                  />
                </Form>
              );
            }}
          </Formik>
          <SurveyQuestionsReference
            className={styles.questionsReference}
            questions={questions}
          />
        </Card.Content>
      </Card>
    </div>
  );
};

SurveyValidationEditor.defaultProps = {
  questions: [],
  validation: [],
};

SurveyValidationEditor.propTypes = {
  submitFormRef: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  questions: PropTypes.arrayOf(questionType),
  validation: PropTypes.arrayOf(validationRuleType),
};

export default SurveyValidationEditor;

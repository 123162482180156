import moment from 'moment';
import React from 'react';
import { useAlert } from 'react-alert';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';

import { entitiesAPI } from '../../../../../api';
import { Entity } from '../../../../../constants';
import { useLocationSearchParam } from '../../../../../hooks';
import { LoadingPlaceholder } from '../../../../common';
import AuditDetails from '../../../Audits/AuditDetails/AuditDetails.component';
import OrderDetails from '../../../Orders/OrderDetails/OrderDetails.component';
import SiteDetails from '../../../Sites/SiteDetails/SiteDetails.component';

// const AuditDetails = lazy(() => import('../../../Audits/AuditDetails/AuditDetails.component'));
// const OrderDetails = lazy(() => import('../../../Orders/OrderDetails/OrderDetails.component'));
// const SiteDetails = lazy(() => import('../../../Sites/SiteDetails/SiteDetails.component'));

const EntityRevisionsCompare = () => {
  const { entity, entityId } = useParams();
  const alert = useAlert();

  const firstRevisionId = useLocationSearchParam('rev1');
  const secondRevisionId = useLocationSearchParam('rev2');

  const { data: firstRevision, error: firstRevisionError } = useQuery(
    [{ entity, entityId, revisionId: firstRevisionId }],
    () => entitiesAPI.fetchOneRevision({ entity, entityId, revisionId: firstRevisionId }),
    { enabled: Boolean(firstRevisionId) },
  );

  const { data: secondRevision, error: secondRevisionError } = useQuery(
    [{ entity, entityId, revisionId: secondRevisionId }],
    () => entitiesAPI.fetchOneRevision({ entity, entityId, revisionId: secondRevisionId }),
    { enabled: Boolean(secondRevisionId) },
  );

  const form = {
    [Entity.AUDITS]: AuditDetails,
    [Entity.ORDERS]: OrderDetails,
    [Entity.SITES]: SiteDetails,
  };

  const EntityDetailsForm = form[entity] || null;

  if (firstRevisionError) {
    alert.error(firstRevisionError.message);
  }

  if (secondRevisionError) {
    alert.error(secondRevisionError.message);
  }

  if (!firstRevision || !secondRevision) {
    return <LoadingPlaceholder />;
  }

  if (!EntityDetailsForm) {
    return (
      <div>
        {'This entity doesn\'t support revisions comparison.'}
      </div>
    );
  }

  return (
    <Grid columns={2}>
      <Grid.Row>
        <Grid.Column>
          {`${moment(firstRevision.created_at)} by ${firstRevision.user_name}`}
        </Grid.Column>
        <Grid.Column>
          {`${moment(secondRevision.created_at)} by ${secondRevision.user_name}`}
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <EntityDetailsForm
            singleColumn
            data={firstRevision.data}
            entity={entity}
            entityId={entityId}
            revisionId={firstRevisionId}
          />
        </Grid.Column>
        <Grid.Column>
          <EntityDetailsForm
            singleColumn
            data={secondRevision.data}
            entity={entity}
            entityId={entityId}
            revisionId={secondRevisionId}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default EntityRevisionsCompare;
